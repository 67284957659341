<article class="news-template-c">
  <div class="container-fluid">
    <div class="row">
      <div class="main-item">
        <div class="row">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12" [style.height.px]="225">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Upload_Image' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="navigation">
              <div class="label">{{'Library' | translate}}</div>
              <div class="arrow">
                <div class="k-icon k-i-arrow-chevron-left" (click)="lastView()"></div>
                <div class="k-icon k-i-arrow-chevron-right" (click)="nextView()"></div>
              </div>
            </div>
            <kendo-scrollview #scrollview [endless]="true" [data]="info" [width]="widthSView" [height]="heightSView"
              [activeIndex]="activeView">
              <ng-template let-item="item">
                <img *ngIf="infoFromMain != null" [src]="this.appService.apiRoot+item.ImageUrl" [alt]="item.Title"
                  class="item-image" draggable="false" (click)="getLink(item.DetailPath)" />
                <img *ngIf="infoFromMain == null" src="../../assets/images/default.png" alt="" draggable="false"
                  class="item-image" />
              </ng-template>
            </kendo-scrollview>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content dialog-info window-center">
    <div class="employe-detail">
      <div class="news-template-c-dialog close-div">
        <div class="title-info-req">
          <span *ngIf="dltFlg == false && isGallery == false" [textContent]="'Select_Images' | translate"></span>
          <span *ngIf="dltFlg == false && isGallery == true" [textContent]="'Gallery' | translate"></span>
          <span *ngIf="dltFlg == true" [textContent]="'Gallery_Scrollview_Delete' | translate"></span>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img *ngIf="isGallery == false" class="close-icon" src="../../assets/images/close-btn-black.png" alt="" (click)="onClose($event)" />
          <img *ngIf="isGallery == true" class="close-icon" src="../../assets/images/close-btn-black.png" alt="" (click)="onGoBack()" />
        </div>
      </div>
    </div>
    <app-archive *ngIf="dltFlg == false" [pageSelectedID]="pageSelectedID" [ID]="ID" [info]="info" [templateID]="templateID"
    [galleryData]="galleryData" (onSave)="onSave($event)"
     (isGallery)="isGalleryDialog($event)" [isGoBack]="isGallery">
  </app-archive>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
