<div class="opend-30-dialog ">
  <div *ngIf="srcURL.Type == 0 " class="col-md-12 col-12">
    <kendo-scrollview #scrollview [endless]="true" [data]="srcURL" *ngIf="true" [width]="widthSView"
      [height]="heightSView" [arrows]="true" [pageable]="true">
      <ng-template let-item="item">
        <div>
          <section class="video-container" [innerHTML]="
          getVideoUrl(srcURL.FileUrls) | sanitizedHtml
          ">
          </section>
        </div>
      </ng-template>
    </kendo-scrollview>
  </div>
  <div *ngIf="srcURL.Type == 1 " class="col-md-12 col-12">
    <kendo-scrollview #scrollview [endless]="true" [data]="this.b" *ngIf="true" [width]="widthSView"
      [height]="heightSView" [arrows]="true" [pageable]="true" >
      <ng-template let-item="item">
        <div>
          <img *ngIf="this.b.length>0 " src="{{ this.appService.apiRoot + item }}" alt="{{ item.Name }}"
            class="img-scrollview " draggable="false" (click)="getLink(item.DetailPath)" />
          <img *ngIf="this.b.length==0 " src="../../assets/images/default.png" alt="" draggable="false"
            class="img-scrollview" />
        </div>
      </ng-template>
    </kendo-scrollview>
  </div>
</div>