<article [ngClass]="templateType ==1 ? 'news-template-1' : 'news-template-1-without-time'">
  <div class="container-fluid">
    <div class="row space-box">
      <div class="main-item">
        <div class="row">
          <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
            <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
            <div class="menu" *ngIf="isShowMenu">
              <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{'Edit' | translate}}</div>
              </div>
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{'Delete' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title" *ngIf="templateType == 1">
            <p class="uppercase title-text">{{ postTittle }}</p>
            <!-- <div *ngIf="isMore" class="post-more">
              <div class="pointer" (click)="redirectToViewMore(item)">
                {{ "View_More" | translate }}
                <i class="fa fa-angle-right icon-right"></i>
              </div>
            </div> -->
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div class="post-list">
              <div
                [className]="
                  'col-sm-6' +
                  ' col-12' +
                  ' col-md-' +
                  bootstrapNum +
                  ' pointer top-new-item'
                "

                *ngFor="let item of  pagedTopnews"
                (click)="redirectToDetail(item.UrlPath)"
              >
                <div class="top-part">
                  <figure
                    class="img-figure"
                    [style.height.px]="innerWidth*0.14"
                  >
                    <a [href]="getDetailUrl(item.UrlPath)" class="post-image">
                      <img
                        *ngIf="item.ImageUrl && !item.DefaultFlg"
                        class="post-image"
                        [src]="appService.apiRoot + item.ImageUrl"
                        alt="post-image"
                      />

                      <img
                        *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined"
                        class="post-image-default"
                        src="../../assets/images/default.png"
                        alt="post-image"
                      />
                    </a>
                  </figure>
                  <a [href]="getDetailUrl(item.UrlPath)" class="title">
                    <p class="title" *ngIf="item.Title">
                      {{
                        item.Title.length > 100
                          ? item.Title.slice(0, 100) + "..."
                          : item.Title
                      }}
                    </p>
                  </a>
                  <p class="title" *ngIf="!item.Title"></p>
                </div>
                <div class="time-clock" *ngIf="templateType == 1">
                  <img
                    class="icon-clock"
                    src="../../assets/images/icon-clock.png"
                    alt="icon-clock"
                  />
                  <p class="time-text" *ngIf="item.PostTime">
                    {{ item.PostTime | date: "dd-MM-yyyy" }}
                  </p>
                  <p class="time-text" *ngIf="!item.PostTime"></p>
                </div>
              </div>
              <kendo-datapager
                [style.width.%]="100"
                [pageSize]="pageSize"
                [skip]="skip"
                [total]="total"
                (pageChange)="onPageChange($event)"
                *ngIf="isViewAllList"
              >
              </kendo-datapager>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
            </div> -->
    </div>
  </div>
  <kendo-dialog
    *ngIf="manageDialog"
    (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width"
  >
    <div class="news-template-1-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
        }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
        }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close-button.png"
          alt=""
          (click)="onClose($event)"
        />
      </div>
    </div>
    <app-news-template-1-dialog
      *ngIf="editFlg == true"
      [request_AccountID]="request_AccountID"
      [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)"
      [galleryData]="galleryData"
      [editFlg]="editFlg"
      [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID"
      (onSave)="this.onSaveData()"
      (onChangetemp1)="this.changeTemp1($event)"
    >
    </app-news-template-1-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
