<article class="news-template-24">
  <div class="container-fluid padding" style="
  display: flex;
  justify-content: center;
  flex-direction: column;
">
    <div class="row scroll-area">
      <div class="main-item">
        <div class="row">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <nav class="sidebar" [ngClass]="{ sidebarPushRight: isActive }">
              <ul class="list-group">
                <div class="nested-menu">
                  <div class="list-submenu">
                    <div class="submenu" *ngFor="let group of topNews" (click)="goToPost(newItem)">
                      <div *ngFor="let newItem of group.Posts">
                      <div class="sub-item" style="border-bottom: 1px dashed #848484;">
                        <div class="top-part">
                        <!-- <div class="item-title-icon"> -->
                          
                          <div class="item-title ">
                            {{ newItem.Title }}
                          </div>
                          <div class="row info-post">
                            <figure
                              class="img-figure"
                            >
                              <a [href]="getDetailUrl(newItem.UrlPath)" class="post-image post-image-radius">
                                <img
                                  *ngIf="newItem.ImageUrl != this.LINK_DEFAULT_IMAGE"
                                  class="post-image post-image-radius"
                                  [src]="appService.apiRoot + newItem.ImageUrl"
                                  alt="post-image"
                                />

                                <img
                                  *ngIf="newItem.ImageUrl == this.LINK_DEFAULT_IMAGE || newItem.ImageUrl == null || newItem.ImageUrl == undefined"
                                  class="water-icon"
                                  src="../../assets/images/water-icon.png"
                                  alt="post-image"
                                />
                              </a>
                            </figure>
                          
                            <div class="item-info">
                                <span class="item-keyword"> {{ newItem.Keywords }}</span>
                                <!-- <span class="item-time"> {{ newItem.CreateAt | date:'dd/MM/yyyy' }} </span> -->
                                <div class="item-summary"> {{ newItem.Summary }} </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>

    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-24-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
          }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-24-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-24-dialog>
    <div *ngIf="dltFlg == true">
      <button class="btn-delete" (click)="onDeleteGallery()">
        <span>{{ "Delete" | translate }}</span>
      </button>
      <button class="btn-cancel" (click)="onClose($event)">
        <span>{{ "Cancel" | translate }}</span>
      </button>
    </div>
  </kendo-dialog>
</article>