<article class="news-template-11">
  <div class="container-fluid">
    <div class="col-12 main-item">
      <div *ngIf="isAdminFlg" class="row">
        <div class="col-sm-1 col-12 col-md-1 col-xl-1 vertical-dot">
          <div class="three-dot-area" (click)="enableChoice()">
            <img class="three-dot" src="../../assets/images/three_dot_vertical.png" alt="" />
          </div>
          <div class="menu" *ngIf="isShowMenu">
            <div class="menu-row-current-border" (click)="editComponent()">
              <div class="menu-item-1">Sửa</div>
            </div>
            <div class="menu-row-current" (click)="deleteComponent()">
              <div class="menu-item-3">Xóa</div>
            </div>
          </div>
        </div>
        <div class="col-sm-11 col-12 col-md-11 col-xl-11">
          <div class="row">
            <div class="post-title">{{ postTittle }}</div>
            <div class="col-sm-12 col-12 col-md-12 pointer list-news">
              <div class="row pointer">
                <div *ngFor="let item of topNews; let i = index" [routerLink]="['post-detail/' + item.UrlPath]"
                  routerLinkActive="router-link-active">
                  <section *ngIf="i == 0" class="first-news">
                    <div class="left">
                      <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image"
                        [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                      <img *ngIf="item.DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                        alt="post-image" />
                    </div>
                    <div class="right top-news-detail">
                      <div class="title-first" *ngIf="item.Title">
                        {{ item.Title }}
                      </div>
                      <div class="title-first" *ngIf="!item.Title"></div>
                      <div class="content-detail">
                        Saigontourist Group hân hạnh là đối tác hỗ trợ của Hội
                        nghị Meet The Experts với chủ đề “Vietnam’s Hospitality
                        - Time To Rebuild” vào lúc 1:30 PM ngày 26 tháng 4 tại
                        Khách sạn InterContinental Sài Gòn.
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-12 top-new-item">
                <div *ngFor="let item of topNews; let i = index" class="pointer"
                  [routerLink]="['post-detail/' + item.UrlPath]" routerLinkActive="router-link-active">
                  <section *ngIf="i != 0" class="text-section">
                    <div class="icon-clock"></div>
                    <p class="title" *ngIf="item.Title">{{ item.Title }}</p>
                    <p class="title" *ngIf="!item.Title"></p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isAdminFlg" class="row">
        <div class="post-title">{{ postTittle }}</div>
        <div class="col-sm-12 col-12 col-md-12 col-xl-12 list-news">
          <div class="row pointer">
            <div *ngFor="let item of topNews; let i = index" [routerLink]="['post-detail/' + item.UrlPath]"
              routerLinkActive="router-link-active">
              <section *ngIf="i == 0" class="first-news">
                <div class="left">
                  <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image" [src]="getFullUrlAPI(item.ImageUrl)"
                    alt="post-image" />
                  <img *ngIf="item.DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                    alt="post-image" />
                </div>
                <div class="right top-news-detail">
                  <div class="title-first" *ngIf="item.Title">
                    {{ item.Title }}
                  </div>
                  <div class="title-first" *ngIf="!item.Title"></div>
                  <div class="content-detail">
                    Saigontourist Group hân hạnh là đối tác hỗ trợ của Hội nghị
                    Meet The Experts với chủ đề “Vietnam’s Hospitality - Time To
                    Rebuild” vào lúc 1:30 PM ngày 26 tháng 4 tại Khách sạn
                    InterContinental Sài Gòn.
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="col-12 top-new-item">
            <div *ngFor="let item of topNews; let i = index" class="pointer"
              [routerLink]="['post-detail/' + item.UrlPath]" routerLinkActive="router-link-active">
              <section *ngIf="i != 0" class="text-section">
                <div class="icon-clock"></div>
                <p class="title" *ngIf="item.Title">{{ item.Title }}</p>
                <p class="title" *ngIf="!item.Title"></p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-11-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
      </div>
      <div class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-11-dialog
      [request_AccountID]="request_AccountID"
      [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)"
      [galleryData]="galleryData"
      [editFlg]="editFlg"
      [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID"
      (onSave)="this.onSaveData()"
    >
    </app-news-template-11-dialog>
  </kendo-dialog>
</article>
