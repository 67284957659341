import { Component, OnInit, OnDestroy, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';


@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  // styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit , OnDestroy {

  @Output() newCommon = new EventEmitter();
  @Input() isAddNewOutCommon = false;
  @Input() typeCommonInput = 0;

  types: Array<{ Name: string, ID: number }>;
  typeFilter: Array<{ Name: string, ID: number }>;

  cmType: Array<{ Name: string, ID: string }>;
  cmTypeFilter: Array<{ Name: string, ID: string }>;

  allowInsertFile = true;
  filesUploadImage: "";
  filesUploadName = "";
  filesUpload: Array<FileInfo>;
  public fileSaveUrl: any;
  image = 'assets/images/image.png';
  public uploadSaveImage = "";

  user: any;
  popupClass = "popup-width";
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;
  dataGrids = [];
  dataGridSelectableSettings: SelectableSettings;
  dataGridSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataGridFocus = {
    Name: true
  };
  dataGridSortByField: SortDescriptor[] = [];

  dataGridSkip = AppConsts.pageSkip;
  dataGridPageSize = AppConsts.pageSize;
  dataGridSelection: number[] = [];
  dataGridItem: any;
  dataLangs = [];

  selectedLangID = AppConsts.defaultLangID;

  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataGridState: State = {
    skip: this.dataGridSkip,
    take: this.dataGridSkip + this.dataGridPageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataGridGridDataResult: GridDataResult;

  dataItems: Array<{ Name: string, ID: string }>;
  dataItemsFilter: Array<{ Name: string, ID: string }>;


  public disabled = false;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  infoOpened = false;

  searchOption = {
    SearchText: '',
    Type: null
  };
  dataErr: any;

  pageName = '';

  isMulti = '1';
  colNum = { ID: "1", Name: 'Hiển thị 1 Cột' };
  apiRoot = null;
  DetailMediaOpened = false;
  public DetailMedia = [];
  Position = 0;
  currentTabStrip = 0;
  pageSize = 12;
  skip = 0;
  total = 0;
  ImageListPage = [];

  thumbnailImgList = [];

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
     appConsts: AppConsts
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.apiRoot = appService.apiRoot;
    this.getTypeCM();
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.allData = this.allData.bind(this);
    // this.getPageName();
    this.GetAllLang();
  }

  onDotsString(string, maxValue) {
    if(string == null || string == undefined) {
      return "";
    }
    //var width = this.outerWidth;
    var txtLength = 0;

    var orgString = string;
    if (orgString) {
      //dem ki tu hoa thuong
      var nA = 0;
      var na = 0;
      var ascii = 0;
      var lastIdx = 0;

      for (let i = 0; i < orgString.length; i++) {
        ascii = orgString[i].charCodeAt(0);
        if (ascii >= 65 && ascii <= 90) {
          txtLength += 1.21875;
        } else {
          txtLength += 1;
        }

        lastIdx = i;

        if (txtLength >= maxValue) {
          break;
        }
      }

      if (txtLength <= maxValue && lastIdx == (orgString.length - 1)) {
        return string;
      }

      var idxSpace = orgString.lastIndexOf(" ", lastIdx);
      var newString = orgString.slice(0, idxSpace) + " ...";

      return newString;
    }

  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.images);
    this.controlDefault = false;
  }

  async getTypeCM() {
    var result = await this.appService.doGET('api/Enum/GetETypeImage', null);
    this.types = result.Data;
    this.typeFilter = this.types;
  }

  StatusHandleFilter(e: any) {
    this.typeFilter = this.types.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  }

  async StatusSelectionChange(e: any) {
    // this.dataGridItem.CvPro5ListID =e.CvPro5ListID;

  }

  getIcon(path) {
    if (path == undefined || path == null) {
      return '../../../assets/images/folder.png'
    } else {
      var extension = path.split('.').pop();
      if (extension.toLowerCase() == 'doc' || extension.toLowerCase() == 'docx') {
        return '../../../assets/images/doc.png'
      }
      if (extension.toLowerCase() == 'xls' || extension.toLowerCase() == 'xlsx') {
        return '../../../assets/images/xls.png'
      }
      if (extension.toLowerCase() == 'pdf') {
        return '../../../assets/images/pdf.png'
      }
      if (extension.toLowerCase() == 'gif') {
        return '../../../assets/images/gif.png'
      }
      if (extension.toLowerCase() == 'psd') {
        return '../../../assets/images/psd.png'
      }
      if (extension.toLowerCase() == 'png' || extension.toLowerCase() == 'jpeg' || extension.toLowerCase() == 'jpg') {
        return '../../../assets/images/image.png'
      }
    }

  }

  selectionColNumChange(e) {
    this.colNum.ID = e;
    localStorage.setItem('colNum', e);
  }

  cmHandleFilter(value) {
    this.cmTypeFilter = this.cmType.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }


  getTypeName(typeID) {
    var typeItem = this.cmType.find(item => {
      return item.ID == typeID;
    });

    return typeItem.Name;
  }

  setSelectableSettings(): void {

    //this.allowMulti = !this.allowMulti;
    this.dataGridSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextRemove(e: any) {
    this.searchOption.SearchText = '';
    this.onSearch();
  }

  dataItemsHandleFilter(value) {
    this.dataItemsFilter = this.dataItems.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async getdataItems() {
    this.loading = true;
    // this.searchOption.Type = this.currentTabStrip + 1;
    this.searchOption.Type = 1;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      langID : localStorage.getItem('currentLanguage'),
      Type: this.searchOption.Type
    };
    const result = await this.appService.doGET('api/ManageImage/Search', dataRequest);
    if (result) {
      console.log("data", result.Data)
      this.dataGrids = result.Data;
      this.skip = 0;
      this.total = result.Data.length;
      this.pageData();
      this.binddataItems();
    }
    this.loading = false;
  }

  setDefault() {
    this.dataGridItem = {
      IsAdd: true,
      ID: 0,
      Title: '',
      CreateAt: '',
      DelFlg: false,
      OrderIndex: 0,
      ImageUrl: this.image,
      Show: false,
      Link: '',
      Type: this.currentTabStrip + 1
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];

    this.dataGridSelection = [];
    this.disabled = false;
    this.enabledID = true;

    this.filesUploadImage = "";
    this.filesUploadName = "";
    // this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/personalID=${this.user.UserName}&typeData=files`;
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=${this.user.UserName}&typeData=files&acceptExtensions=[".png",".jpg",".jpeg",".gif"]`;

    this.uploadSaveImage = "";
  }

  onGridPageChange(event: PageChangeEvent) {
    this.dataGridSkip = event.skip;
    this.binddataItems();
  }

  ondataItemselectedKeysChange() {

  }

  binddataItems() {
    this.dataGridGridDataResult = {
      data: orderBy(this.dataGrids, this.dataGridSortByField),
      total: this.dataGrids.length
    };

    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  ondataItemsortChange(sort: SortDescriptor[]): void {
    this.dataGridSortByField = sort;
    this.binddataItems();
  }

  public onGridDataStateChange(state: DataStateChangeEvent): void {
    this.dataGridSelection = [];
    this.dataGridState = state;
    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.onReload();
  }

  async initDisplay() {
  }

  onSearch() {
    this.getdataItems();
  }

  onReload() {
    //this.searchOption.SearchText = '';
    this.setDefault();
    this.getdataItems();
  }

  onClearGrid() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewGrid() {
    this.setDefault();
    this.infoOpened = true;
  }

  onSaveGrid() {
    if (this.dataGridItem.IsAdd) { this.addGrid(); } else { this.updateGrid(); }
  }

  createDataRequest(data) {

    const temp = data ? data : this.dataGridItem;
    var imageUrl: any;
    var files: any[] = [];
    if (this.dataGridItem.ImageUrl != this.image) {
      /*Note: chổ này xảy ra trường hợp khi cập nhật ko đổi hình thì uploadSaveImage = "" nên cần xét để ko bị mất hình*/
      if(this.uploadSaveImage != ""){
        imageUrl = this.uploadSaveImage;
      }
      else{
        imageUrl = this.dataGridItem.ImageUrl;
      }

    }
    else {
      imageUrl = null;
    }
    // if (this.dataPersonalItemtemp.FileUrls) {
    //   for (let i = 0; i < this.dataPersonalItemtemp.FileUrls.length; i++) {
    //     files.push(this.dataPersonalItemtemp.FileUrls[i]);
    //   }
    // }
    return {
      ID: temp.ID,
      Title: temp.Title,
      CreateAt: temp.CreateAt,
      DelFlg: temp.DelFlg,
      OrderIndex: temp.OrderIndex == null ? 0 : temp.OrderIndex,
      LangID: this.selectedLangID,
      ImageUrl: imageUrl,
      Show: temp.Show,
      Link: temp.Link,
      Type: temp.Type
    };
  }

  onCloseGrid(status: any) {
    this.disabled = true;
    this.enabledID = false;
    this.infoOpened = true;
    this.setDefault();

  }

  async onEditGrid(dataItem: any) {

    await this.getDataItemByID(dataItem.ID)
  }

  async getDataItemByID(id: any) {

    const dataRequest = {
      iD: id,
      langID: this.selectedLangID
    };

    const result = await this.appService.doGET('api/ManageImage', dataRequest);
    if (result && result.Status === 1) {
      this.dataGridItem = result.Data;
      this.dataGridItem.UnitContactID = result.Data.ID;
      this.disabled = false;
      this.enabledID = false;
      this.infoOpened = true;

    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async addGrid() {
    // this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/ManageImage', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewGrid();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  async updateGrid() {
    // this.appComponent.loading = true;
    const iD = this.dataGridItem.ID;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/ManageImage', dataRequest, { iD });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onCloseInfo();
      this.onReload();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  async onDeleteGrid(dataItem: any) {
    // this.appComponent.loading = true;
    const dataRequest = {
      iD: dataItem.ID,
      langID: this.selectedLangID
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doDELETE('api/ManageImage', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onCloseInfo();
        this.onReload();
        this.dataGridSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataGrids, {}).data
    };

    return result;
  }

  onCloseInfo() {
    this.infoOpened = false;
    this.selectedLangID = AppConsts.defaultLangID;
    this.language.set(localStorage.getItem('currentLanguage'));
    this.setDefault();
    this.getTypeCM();
    //this.selectedLangID = localStorage.getItem('currentLanguage');
    //this.language.set(this.selectedLangID);
  }

  onChangeFunction(e, dataItem) {
    if (e.id == 'Edit') {
      this.onEditGrid(dataItem);
    }
    else if (e.id == 'Delete') {
      this.onDeleteGrid(dataItem)
    }
  }
  onFunctionIconClick(dataItem) {

    this.getbtnFunctionData(dataItem);


  }

  getbtnFunctionData(dataItem) {

    this.btnFunctionData = [];
    this.btnMbFunctionData = [];
    if (this.controlDefault || this.control.Edit) {
      var func1 = {
        text: this.translate.instant('Edit'),
        id: 'Edit',
        icon: 'pencil',
      };
      this.btnFunctionData.push(func1);
      this.btnMbFunctionData.push(func1);

    }

    if (this.controlDefault || this.control.Delete) {
      var func3 = {
        text: this.translate.instant('Delete'),
        id: 'Delete',
        icon: 'delete',
      };
      this.btnFunctionData.push(func3);
      this.btnMbFunctionData.push(func3);

    }
  }

  async GetAllLang() {
    this.loading = true;

    const result = await this.appService.doGET('api/Lang/GetAllLang', null);
    if (result) {
      this.dataLangs = result.Data;
      var viLang = this.dataLangs.findIndex(x => x.ID == "vi-VN");
      if (viLang != 0 && viLang != -1) {
        this.dataLangs.splice(viLang, 1);
        this.dataLangs.unshift({
          ID: "vi-VN",
          Name: "Tiếng Việt"
        });
      }
    }
    this.loading = false;
  }

  async onChangeLang(langID) {
    this.selectedLangID = langID;

    var tempItem = {
      IsAdd: true,
      ID: 0,
      Name: '',
      Type: 0,
      CreateAt: '',
      DelFlg: false,
      OrderIndex: 0
    };
    var errTemp = Object.assign({}, tempItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];

    this.getDataItemByID(this.dataGridItem.ID);
    //this.language.set(this.selectedLangID);
    var old = localStorage.getItem('currentLanguage');
    if(this.selectedLangID == "vi-VN"){
      this.language.set(localStorage.getItem('currentLanguage'));
      this.getTypeCM();
    }
    else{
      //localStorage.setItem('currentLanguage', `${this.selectedLangID}`);
      await this.language.set(this.selectedLangID);
      await this.getTypeCM();
      await this.language.set(old);
    }
    //this.language.set(localStorage.getItem('currentLanguage'));
  }

  onRemoveAvatarUpload() {
    this.dataGridItem.ImageUrl = "";
    this.filesUploadName = "";
    this.filesUploadImage = "";
  }
  //select file to upload
  async onSelectAvatarUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }
    const extension = e.files[0].extension.toLowerCase();
    this.allowInsertFile = true;

    if (
      !extension ||
      (extension.toLowerCase() !== ".png" &&
        extension.toLowerCase() !== ".jpg" &&
        extension.toLowerCase() !== ".jpeg")
    ) {
      this.allowInsertFile = false;
      this.appSwal.showWarning(
        this.translate.instant('Error_Image_Extension'),
        false
      );
      return false;
    }
  }

  onSuccessAvatarUpload(e: any) {
    if (!this.allowInsertFile) {
      return;
    }
    try {
      if (this.dataGridItem.ImageUrl == undefined) {
        this.dataGridItem.ImageUrl = this.user.FilePath;
      }
      this.uploadSaveImage = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;

      this.dataGridItem.ImageUrl = //this.appService.apiRoot +
        `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`
        ;
    } catch {
      this.appSwal.showError(e);
    }

  }

  async DetailMediaDialogOpened(ID, Position) {
    this.Position = Position;

    this.DetailMediaOpened = true;

    console.log("position", this.ImageListPage[this.Position])

    this.thumbnailImgList = this.ImageListPage.slice(this.Position + 1, this.Position + 6);

    for (let i = 0; i < this.dataGridItem.length; i++) {
      
      if (this.dataGridItem[i].ID == ID) {
        
        this.DetailMedia = this.dataGridItem[i].ListMedia;
      }
    }


  }

  onCloseDetailMediaDialog() {
    this.DetailMediaOpened = false;
    // this.appComponent.closeDialog();
  }

  handlePrevImage(){
    if (this.Position <= 0) return; 
    this.Position --;
    this.thumbnailImgList = this.ImageListPage.slice(this.Position + 1, this.Position + 6);
  }

  handleNextImage(){
    if (this.ImageListPage.length - 1 <= this.Position) return; 
    this.Position ++;
    this.thumbnailImgList = this.ImageListPage.slice(this.Position + 1, this.Position + 6);
  }

  getFileType(fileUrls) {

    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfLast = nameFile.lastIndexOf(".");
          nameFile = nameFile.substring(indexOfLast + 1);
        }
      }

    }
    return nameFile;

  }

  onTabChanged(e) {
    this.currentTabStrip = e.index;
    // if (this.currentTabStrip != 0) {
    //   this.searchOption.Type = null;
    // }
    this.onSearch();
  }

  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }
  private pageData(): void {
    if (this.dataGrids){
      this.ImageListPage = this.dataGrids.slice(

        this.skip,
        this.skip + this.pageSize
      );
    }
  }

}
