import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Notification } from '../services/app.notification';
import { AppSwal } from 'src/app/services/app.swal';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectEvent} from '@progress/kendo-angular-upload';
import { AuthenticationService } from '../services/authentication.service';
import { AppConsts } from '../services/app.consts';


@Component({
  selector: 'app-customer-care',
  templateUrl: './customer-care.component.html',
  styleUrls: ['./customer-care.component.scss']
})
export class CustomerCareComponent implements OnInit {
  public userForm: FormGroup = new FormGroup({
    AccountName: new FormControl(null, Validators.required),
    Name: new FormControl(null, Validators.required),
    AccountEmail: new FormControl(null, Validators.email),
    AccountPhone: new FormControl(null, Validators.required),
    // AccountAddress: new FormControl(null, Validators.required),
    Description: new FormControl(null, Validators.required),
    WardID: new FormControl(null, Validators.required),
    DistrictID: new FormControl(null, Validators.required),
    Capcha: new FormControl(null, Validators.required)
  });
  private queryParamsSubscription: Subscription;

  wardListFilter: Array<{ Name: string, ID: string }> = [];
  districtListFilter: Array<{ Name: string, ID: string }> = [];
  @Input() isAdminFlg: boolean = false;
  infoContact: any = {};
  currentLang: any;
  validForm: boolean = true;
  user: any;

  manageDialog = false;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any; //page thiết kế
  @Output() deleteBodyItem = new EventEmitter();
  filesUpload = [];
  allowInsertFile = true;
  fileUrls = [];
  filesUploadName = "";
  public fileSaveUrl: any;
  userName: any;
  codeCapcha = "";
  charsArray =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  inputCapcha = '';
  isSearchTab:boolean = false;
  bodyWidth = 1140;
  bodyWidthSetup = 1140;
  constructor(

    private appService: AppService,
    private translate: TranslateService,
    private language: AppLanguage,
    private notification: Notification,
    private appSwal: AppSwal,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    public appConsts: AppConsts,
  ) {
    this.userName = localStorage.getItem('userName');
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=${this.userName}&typeData=files&acceptExtensions=[".png",".jpg",".jpeg"]`;
  }
  searchOption = {
    searchText: '',
    type: 4,
    menuID:''


  };
  ngOnInit(): void {
    this.language.default();
    if(!this.isSearchTab){
      setTimeout(() => {
        this.createCaptcha();
      }, 100);
    }
    this.wardListFilter = [
      {
      ID: "P1",
      Name: "Phường 1"
      },
      {
        ID: "P2",
        Name: "Phường 2"
        },
  ]
  this.districtListFilter = [
    {
    ID: "Q1",
    Name: "Quận 1"
    },
    {
      ID: "Q2",
      Name: "Quận 2"
      },
]
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  async onSubmit() {

    this.validForm =
      this.userForm.valid &&
      this.validateContent(this.userForm.value.Description) &&
      this.validateMail(this.userForm.value.AccountEmail);

    if (this.validForm) {
      const option = await this.appSwal.showWarning(
        this.translate.instant('AreYouSure'),
        true
      );
      const validateCaptcha = this.validateCaptcha();
      if (option && validateCaptcha) {
          this.notification.showSuccess('Gửi thành công');
          this.userForm.reset();
          this.fileUrls = [];
          this.createCaptcha();
      } else {
        this.createCaptcha();
      }
    }
  }
  onResetInput(){
    this.userForm.reset();
    this.fileUrls = [];
  }

  validateContent(Description: any) {
    return Description.length < 501;
  }

  validateMail(AccountEmail) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(AccountEmail);
  }

  public onTabSelect(e: SelectEvent): void {
    let selectedIndex = JSON.parse(JSON.stringify(e)).index;
    if(selectedIndex == 1){
      this.router.navigate([AppConsts.page.quizSearch],
        // {
        //   queryParams: { categoryId: categoryID },
        // }
        );
    }
    console.log(e);
  }
   //select file to upload
   async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }
    const extension = e.files[0].extension.toLowerCase();
    this.allowInsertFile = true;
    try {
      const fileData = e.files[0]; // await this.file.readFile(e.files[0].rawFile);
      const maxMB = 30;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(
          `Kích thước tập tin không được lớn hơn ${maxMB} MB`,
          false
        );
        return;
      }
    } catch {
      this.appSwal.showError(e);
    }
    // tslint:disable-next-line: max-line-length
    if (
      !extension ||
      (extension.toLowerCase() !== ".jpeg" &&
        extension.toLowerCase() !== ".jpg" &&
        extension.toLowerCase() !== ".png")
    ) {
      this.allowInsertFile = false;
      this.appSwal.showWarning(
        "Vui lòng chọn tập tin có định dạng .jpeg, .jpg, .png",
        false
      );
      return false;
    }
  }


  onSuccessFileToUpload(e: any) {
    if (!this.allowInsertFile) {
      return;
    }
    try {
      if (this.fileUrls == undefined) {
        this.fileUrls = [];
      }
      this.fileUrls.push(
        `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`
      );
      console.log("filesUpload", this.filesUpload);
      console.log("fileUrls", this.fileUrls);
    } catch {
      this.appSwal.showError(e);
    }
  }
  onRemoveFileDocToUpload() {
    this.fileUrls = [];
    this.filesUploadName = "";
    this.filesUpload = [];
  }
  getImageFullUrl(url){
    return url;
  }
  createCaptcha() {

    document.getElementById('captcha-item').innerHTML = "";
    var lengthOtp = 5;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      var index = Math.floor(Math.random() * this.charsArray.length + 1);
      if (captcha.indexOf(this.charsArray[index]) == -1)
        captcha.push(this.charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 120;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Road Rage";
    ctx.strokeText(captcha.join(""), 5, 30);
    this.codeCapcha = captcha.join("");
    document.getElementById("captcha-item").appendChild(canv);
  }

  validateCaptcha() {
    event.preventDefault();
    if (this.userForm.value.Capcha == this.codeCapcha)
      return true;
    return false;
  }

  uploadfile(){
    event.preventDefault();
    event.stopPropagation();


  }
}
