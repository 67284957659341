import { Component, OnInit } from '@angular/core';
import { AppLanguage } from './services/app.language';
import { Location } from '@angular/common';
import { AppInfo } from 'src/app/services/app.info';
import { AppConsts } from 'src/app/services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './services/app.service';
import { Meta } from '@angular/platform-browser';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { EventEmitter, Input, Output } from '@angular/core';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Output() menuItemTittle = new EventEmitter();
  @Input() itemchild: any;
  schemaMainPage: any;
  data: { url: any; telephone: any };
  Infor: any;

  isAdminFlg: boolean = false;
  menuVl: any;
  menuTittle: any;
  link: any;
  exceptionRoutes = ['/' + AppConsts.page.templatePage, '/' + AppConsts.page.previewTemplatePage]

  meta: any = {
    description: '',
    keywords: '',
    author: '',
    copyright: '',
    locale: '',
    url: '',
    type: '',
    title: '',
    ogDescription: '',
    image: '',
    site_name: '',
  };
  menuID: any;
  isTemplatePage: boolean = false;

  selectedLangID = AppConsts.defaultLangID;
  selectedFont: any;
  fontList: Array<{ Name: string, ID: string }> = [];
  fontListFilter: Array<{ Name: string, ID: string }> = [];
  templateID: any;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appInfo: AppInfo,
    private appService: AppService,
    private router: Router,
    private location: Location,
  ) {
    this.language.setDefaultLanguage();
  }
  async ngOnInit(): Promise<void> {
    this.appInfo.setTitle('');
    this.link = this.router.url;
    this.router.events.subscribe((val) => {
      this.link = this.location.path();
      if(this.link.includes('/template-page')){
        this.isTemplatePage = true;    
      } else {
        this.isTemplatePage = false;
      }
    });
    //set default meta
    this.setMeta();

    await this.getFontListFilter();
    this.getDataTemplate();
  }
  setMeta(): void {
    this.meta.description =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    this.meta.keywords =
      'sawaco, cấp nước';
    this.meta.author = this.translate.instant('Title-Page');
    this.meta.copyright = this.translate.instant('Copy-right');
    this.meta.locale = 'vi_VN';
    this.meta.url = typeof window !== 'undefined' ? window.location.origin : '';
    this.meta.type = 'Website';
    this.meta.title = this.translate.instant('Title-Page');
    this.meta.ogDescription =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    this.meta.image = '';
    this.meta.site_name = this.translate.instant('Title-Page');
    
    this.appInfo.setMetaByObject(this.meta);
  }

  menuItem(event) {
    this.menuID = event.ID;  
    this.menuVl = event;
  }
  menuItemchild(event) {
    this.menuID = event.ID;
    this.itemchild = event;
  }
  onGetTittleItem(event) {}
  onActivate(elementRef) {
    if (elementRef.tittle) {
      this.menuTittle = elementRef.tittle;
    } else {
      this.menuTittle = '';
    }
  }
  checkIsTemplatePage(event){
    this.isTemplatePage = true;
  }

  // this.appInfo.setMetaDescription(this.meta.description, this.meta.keywords, this.meta.author, this.meta.locale, this.meta.url,
  //   this.meta.type, this.meta.title, this.meta.ogDescription, this.meta.image, this.meta.site_name);

  // <!-- <meta name="description" content="Sở Ngoại Vụ Thành Phố Hồ Chí Minh" />
  // <meta name="keywords" content="sở ngoại vụ thành phố hồ chí minh, sở ngoại vụ, department of external relations, department of external relations ho chi minh city, ministry of foreign affairs, songoaivu" />
  // <meta name="author" content="Sở Ngoại Vụ Thành Phố Hồ Chí Minh">

  // <meta property="og:locale" content="vi_VN">
  // <meta property="og:url" content="http://.........." />
  // <meta property="og:type" content="website" />
  // <meta property="og:title" content="Sở Ngoại Vụ Thành Phố Hồ Chí Minh" />
  // <meta property="og:description" content="
  // BỘ NGOẠI GIAO
  // SỞ NGOẠI VỤ THÀNH PHỐ HỒ CHÍ MINH
  // Địa chỉ: 6 Alexandre De Rhodes, Quận 1, TPHCM" />
  // <meta property="og:image" content="http://123.30.158.155:8010//Upload/Media/20210813103326536_header.png" />
  // <meta property="og:site_name" content="SỞ NGOẠI VỤ TP. HỒ CHÍ MINH"> -->
  //     <!-- <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap" rel="stylesheet"> -->
  // <!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"> -->

  async getDataTemplate() {
    const result = await this.appService.doGET('api/PortalDesign/SearchTemplate', {});
    if (result && result.Status === 1) {
      this.selectedFont = this.fontList.find((s) => s.ID == result.Data[0].FontID).ID;
      this.templateID = result.Data[0].ID;
      this.onSetFont();
    }
  }

  onSetFont() {
    const bodyElement = document.body;
    const fontName = this.fontList.find((s) => s.ID == this.selectedFont).Name;
    bodyElement.style.fontFamily = '"' + fontName + '"';
  }

  async getFontListFilter() {
    const result = await this.appService.doGET('api/PortalBody/GetFont', {});
    if (result && result.Status === 1) {
      this.fontList = result.Data;
      this.fontListFilter = this.fontList.slice();
    }
  }

  fontHandleFilter(value) {
    this.fontListFilter = this.fontList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}
