import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';

import {
  // Headers,
  Http,
  RequestOptions,
  Response,
  // HttpModule,
} from '@angular/http';
import {
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import localeVn from '@angular/common/locales/vi';
registerLocaleData(localeVn);
import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/vi/all';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JsonLdModule } from 'ngx-seo';
import { AppRouter } from './app.router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MainPageComponent } from './main-page/main-page.component';
import { PostDetailComponent } from './post/post-detail/post-detail.component';
import { MostViewedPostsComponent } from './post/most-viewed-posts/most-viewed-posts.component';
import { map } from 'rxjs/operators';
import { Subject, from, merge, Observable } from 'rxjs';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { AppLanguage } from './services/app.language';
import { TokenInterceptor } from './services/token.interceptor';
import { WebSocketService } from 'src/app/websocket.service';
import { MatSelectModule } from '@angular/material/select';
//vị trí
import { LOCALE_ID } from '@angular/core';

import { TruncatePipe } from './pipe/truncate.pipe';
import { SanitizedHtmlPipe } from './pipe/sanitized-html.pipe';
import { SearchNewsComponent } from './search-news/search-news.component';
import { ErrorComponent } from './error/error.component';
import { PostListComponent } from './post/post-list/post-list.component';
import { PostPageComponent } from './post/post-page/post-page.component';
import { EmbedPageComponent } from './embed-page/embed-page.component';
import { MatIconModule } from '@angular/material/icon';
import { VideojsComponent } from './videojs/videojs.component';
import { CustomTranslateLoader } from './services/custom.translate.loader';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ReceiveQuestionsComponent } from './question/receive-questions/receive-questions.component';
import { QuestionInfoComponent } from './question/question-info/question-info.component';
import { QuestionSearchComponent } from './question/question-search/question-search.component';
import { ContentDocumentComponent } from './document/search/content-document/content-document.component';
import { SearchDocumentComponent } from './document/search/search-document/search-document.component';
import { HorizontalTimelineComponent } from './document/search/horizontal-timeline/horizontal-timeline.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PagerModule } from '@progress/kendo-angular-pager';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateInputModule,
  DatePickerModule,
} from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { QRCodeModule } from 'angular2-qrcode';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ReceiveDocumentComponent } from './document/receive-document/receive-document.component';
import { SubmitDocumentComponent } from './document/submit-document/submit-document.component';
import { RegisterDocumentComponent } from './document/register-document/register-document.component';
import { PaymentResultComponent } from './document/payment-result/payment-result.component';
import { HeaderTemplate1Component } from './header-template1/header-template1.component';
import { ImageSliderHomeComponent } from './image-slider-home/image-slider-home.component';
import { ImageSliderCustomerServiceComponent } from './image-slider-customers-sevice/image-slider-customer-service.component';
import { ArchiveComponent } from './archive/archive.component';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { NewsTemplate1Component } from './news-template-1/news-template-1.component';
import { NewsTemplate1DialogComponent } from './news-template-1/news-template-1-dialog/news-template-1-dialog.component';
import { DataMenuComponent } from './data-menu/data-menu.component';
import { ContactComponent } from './contact/contact.component';

// kéo thả
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
// Tempalte component
import { TemplatePageComponent } from './template-page/template-page.component';
import { EventEmitterService } from './event-emitter.service';
import { Footer2Component } from './footer2/footer2.component';
import { HeaderTemplate2Component } from './header-template2/header-template2.component';
import { NewsTemplate2Component } from './news-template-2/news-template-2.component';
import { NewsTemplate2DialogComponent } from './news-template-2/news-template-2-dialog/news-template-2-dialog.component';
import { IntroPageComponent } from './intro-page/intro-page.component';
import { Layout80Component } from './layout80/layout80.component';
import { OrganizationStructureComponent } from './intro-page/organization-structure/organization-structure.component';
import { NewsTemplate3Component } from './news-template-3/news-template-3.component';
import { NewsTemplate3DialogComponent } from './news-template-3/news-template-3-dialog/news-template-3-dialog.component';
import { RewardAndAchivementComponent } from './intro-page/reward-and-achivement/reward-and-achivement.component';
import { AchivementItemComponent } from './intro-page/reward-and-achivement/achivement-item/achivement-item.component';
import { NewsTemplate4Component } from './news-template-4/news-template-4.component';
import { NewsTemplate4DialogComponent } from './news-template-4/news-template-4-dialog/news-template-4-dialog.component';
import { NewsTemplate5Component } from './news-template-5/news-template-5.component';
import { NewsTemplate5DialogComponent } from './news-template-5/news-template-5-dialog/news-template-5-dialog.component';
import { NewsTemplate6Component } from './news-template-6/news-template-6.component';
import { NewsTemplate6DialogComponent } from './news-template-6/news-template-6-dialog/news-template-6-dialog.component';
import { FieldComponent } from './field/field.component';
import { PartiesAndUnionsComponent } from './parties-and-unions/parties-and-unions.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserInfoFormComponent } from './user-info/user-info-form/user-info-form.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ForgotPasswordPageComponent } from './login-page/forgot-password-page/forgot-password-page.component';
import { NewsTemplateAComponent } from './news-template-a/news-template-a.component';
import { NewsTemplateADialogComponent } from './news-template-a/news-template-a-dialog/news-template-a-dialog.component';
import { LibraryComponent } from './library/library.component';
import { VideosComponent } from './videos/videos.component';
import { ImagesComponent } from './images/images.component';

import { SignupPageComponent } from './signup-page/signup-page.component';
import { RouterLinkComponent } from './router-link/router-link.component';

import { SettingsPageComponent } from './settings-page/settings-page.component';
import { ChangePasswordComponent } from './settings-page/change-password/change-password.component';
import { SupportComponent } from './settings-page/support/support.component';
import { ElectronicTextComponent } from './electronic-text/electronic-text.component';
import { NewsTemplateBComponent } from './news-template-b/news-template-b.component';
import { NewsTemplateBDialogComponent } from './news-template-b/news-template-b-dialog/news-template-b-dialog.component';
import { WorkingScheduleComponent } from './working-schedule/working-schedule.component';
import { MemberUnitComponent } from './member-unit/member-unit.component';
import { LibraryTextComponent } from './library-text/library-text.component';
import { NewsTemplateCComponent } from './news-template-c/news-template-c.component';
import { NewsTemplateCDialogComponent } from './news-template-c/news-template-c-dialog/news-template-c-dialog.component';
import { GovernmentPageComponent } from './government-page/government-page.component';
import { NewsTemplate10Component } from './news-template-10/news-template-10.component';
import { NewsTemplate10DialogComponent } from './news-template-10/news-template-10-dialog/news-template-10-dialog.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { ImageSliderAComponent } from './image-slider-a/image-slider-a.component';
import { NewsTemplate11Component } from './news-template-11/news-template-11.component';
import { NewsTemplate11DialogComponent } from './news-template-11/news-template-11-dialog/news-template-11-dialog.component';
import { HeaderTemplate3Component } from './header-template3/header-template3.component';
import { HistoryComponent } from './intro-page/history/history.component';
import { GeneralIntroComponent } from './intro-page/general-intro/general-intro.component';
import { VisionMissionComponent } from './intro-page/vision-mission/vision-mission.component';
import { EnterMailFormComponent } from './login-page/forgot-password-page/enter-mail-form/enter-mail-form.component';
import { ResetPasswordFormComponent } from './login-page/forgot-password-page/reset-password-form/reset-password-form.component';
import { PreviewTemplatePageComponent } from './preview-template-page/preview-template-page.component';
import { NewsTemplate12Component } from './news-template-12/news-template-12.component';
import { NewsTemplate12DialogComponent } from './news-template-12/news-template-12-dialog/news-template-12-dialog.component';
import { RewardDialogComponent } from './intro-page/reward-and-achivement/reward-dialog/reward-dialog.component';
import { IntroPageDialogComponent } from './intro-page/intro-page-dialog/intro-page-dialog.component';
import { PostDetailDialogComponent } from './post/post-detail/post-detail-dialog/post-detail-dialog.component';
import { LibraryTextDialogComponent } from './library-text/library-text-dialog/library-text-dialog.component';
import { NewsTemplate13Component } from './news-template-13/news-template-13.component';
import { NewsTemplate13DialogComponent } from './news-template-13/news-template-13-dialog/news-template-13-dialog.component';
import { NewsTemplate14Component } from './news-template-14/news-template-14.component';
import { NewsTemplate14DialogComponent } from './news-template-14/news-template-14-dialog/news-template-14-dialog.component';
import { DiagramModule } from '@syncfusion/ej2-angular-diagrams';
import { OrgTreeComponent } from './org-tree/org-tree.component';
import { OrgTreeDialogComponent } from './org-tree/org-tree-dialog/org-tree-dialog.component';
import { HeaderTemplate4Component } from './header-template4/header-template4.component';
import { NewsTemplate19Component } from './news-template-19/news-template-19.component';
import { NewsTemplate19DialogComponent } from './news-template-19/news-template-19-dialog/news-template-19-dialog.component';
import { NewsTemplate15Component } from './news-template-15/news-template-15.component';
import { NewsTemplate15DialogComponent } from './news-template-15/news-template-15-dialog/news-template-15-dialog.component';
import { NewsTemplate16Component } from './news-template-16/news-template-16.component';
import { NewsTemplate16DialogComponent } from './news-template-16/news-template-16-dialog/news-template-16-dialog.component';
import { NewsTemplate17Component } from './news-template-17/news-template-17.component';
import { NewsTemplate17DialogComponent } from './news-template-17/news-template-17-dialog/news-template-17-dialog.component';
import { NewsTemplate18Component } from './news-template-18/news-template-18.component';
import { NewsTemplate18DialogComponent } from './news-template-18/news-template-18-dialog/news-template-18-dialog.component';
import { NewsTemplate20Component } from './news-template-20/news-template-20.component';
import { NewsTemplate20DialogComponent } from './news-template-20/news-template-20-dialog/news-template-20-dialog.component';
import { ImageSliderTitleComponent } from './image-slider-title/image-slider-title.component';
import { NewsTemplate21Component } from './news-template-21/news-template-21.component';
import { NewsTemplate21DialogComponent } from './news-template-21/news-template-21-dialog/news-template-21-dialog.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizSearchComponent } from './quiz/quiz-search/quiz-search.component';
import { ImageListComponent } from './image-list/image-list.component';
import { ImagesSliderComponent } from './images-slider/images-slider.component';
import { HeaderTemplate5Component } from './header-template5/header-template5.component';
import { NewsTemplate22Component } from './news-template-22/news-template-22.component';
import { NewsTemplate22DialogComponent } from './news-template-22/news-template-22-dialog/news-template-22-dialog.component';
import { Footer3Component } from './footer3/footer3.component';
import { NewsTemplate23Component } from './news-template-23/news-template-23.component';
import { NewsTemplate23DialogComponent } from './news-template-23/news-template-23-dialog/news-template-23-dialog.component';
import { NewsTemplate24Component } from './news-template-24/news-template-24.component';
import { NewsTemplate24DialogComponent } from './news-template-24/news-template-24-dialog/news-template-24-dialog.component';
import { NewsTemplate25Component } from './news-template-25/news-template-25.component';
import { NewsTemplate25DialogComponent } from './news-template-25/news-template-25-dialog/news-template-25-dialog.component';
import { NewsTemplate26Component } from './news-template-26/news-template-26.component';
import { NewsTemplate26DialogComponent } from './news-template-26/news-template-26-dialog/news-template-26-dialog.component';
import { NewsTemplate27Component } from './news-template-27/news-template-27.component';
import { NewsTemplate27DialogComponent } from './news-template-27/news-template-27-dialog/news-template-27-dialog.component';
import { PDetailComponent } from './post/pdetail/pdetail.component';
import { NewsTemplate28Component } from './news-template-28/news-template-28.component';
import { NewsTemplate28DialogComponent } from './news-template-28/news-template-28-dialog/news-template-28-dialog.component';
import { NewsTemplate29Component } from './news-template-29/news-template-29.component';
import { NewsTemplate29DialogComponent } from './news-template-29/news-template-29-dialog/news-template-29-dialog.component';
import { NewsTemplate30Component } from './news-template-30/news-template-30.component';
import { NewsTemplate30DialogComponent } from './news-template-30/news-template-30-dialog/news-template-30-dialog.component';
import { NewsTemplate31Component } from './news-template-31/news-template-31.component';
import { NewsTemplate31DialogComponent } from './news-template-31/news-template-31-dialog/news-template-31-dialog.component';
import { PDetail2Component } from './post/pdetail2/pdetail2.component';
import { NewsTemplate32Component } from './news-template-32/news-template-32.component';
import { NewsTemplate32DialogComponent } from './news-template-32/news-template-32-dialog/news-template-32-dialog.component';
import { NewsTemplate33Component } from './news-template-33/news-template-33.component';
import { NewsTemplate33DialogComponent } from './news-template-33/news-template-33-dialog/news-template-33-dialog.component';
import { NewsTemplate34Component } from './news-template-34/news-template-34.component';
import { NewsTemplate34DialogComponent } from './news-template-34/news-template-34-dialog/news-template-34-dialog.component';
import { OpendDialogVideoComponent } from './news-template-26/opend-dialog-video/opend-dialog-video.component';
import { OpendDialogImagesComponent } from './news-template-33/opend-dialog-images/opend-dialog-images.component';
import { Opend30DialogComponent } from './news-template-30/opend-30-dialog/opend-30-dialog.component';
import { CustomerCareComponent } from './customer-care/customer-care.component';
import { WaterBillInformationComponent } from './water-bill-information/water-bill-information.component';
import { FileSettlemenComponent } from './file-settlemen/file-settlemen.component';
import { NewsTemplate35Component } from './news-template-35/news-template-35.component';
import { NewsTemplate35DialogComponent } from './news-template-35/news-template-35-dialog/news-template-35-dialog.component';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NewsTemplate36Component } from './news-template-36/news-template-36.component';
import { NewsTemplate36DialogComponent } from './news-template-36/news-template-36-dialog/news-template-36-dialog.component';
import { NewsTemplate38Component } from './news-template-38/news-template-38.component';
import { NewsTemplate37Component } from './news-template-37/news-template-37.component';
import { NewsTemplate37DialogComponent } from './news-template-37/news-template-37-dialog/news-template-37-dialog.component';
import { NewsTemplate39Component } from './news-template-39/news-template-39.component';
import { NewsTemplate39DialogComponent } from './news-template-39/news-template-39-dialog/news-template-39-dialog.component';
import { NotifyPageComponent} from './notify-page/notify-page.component';
import { NewsTemplate40Component } from './news-template-40/news-template-40.component';
import { NewsTemplate40DialogComponent } from './news-template-40/news-template-40-dialog/news-template-40-dialog.component';
import { NewsTemplate41Component } from './news-template-41/news-template-41.component';
import { NewsTemplate41DialogComponent } from './news-template-41/news-template-41-dialog/news-template-41-dialog.component';
import { NewsTemplate42Component } from './news-template-42/news-template-42.component';
import { NewsTemplate43DialogComponent } from './news-template-43/news-template-43-dialog/news-template-43-dialog.component';
import { NewsTemplate43Component } from './news-template-43/news-template-43.component';
import { NewsTemplate44Component } from './news-template-44/news-template-44.component';
import { NewsTemplate45Component } from './news-template-45/news-template-45.component';
import { NewsTemplate46Component } from './news-template-46/news-template-46.component';
import { NewsTemplate46DialogComponent } from './news-template-46/news-template-46-dialog/news-template-46-dialog.component';
import { NewsTemplate47Component } from './news-template-47/news-template-47.component';
import { NewsTemplate47DialogComponent } from './news-template-47/news-template-47-dialog/news-template-47-dialog.component';
import { NewsTemplate48Component } from './news-template-48/news-template-48.component';
import { NewsTemplate48DialogComponent } from './news-template-48/news-template-48-dialog/news-template-48-dialog.component';
import { NewsTemplate49Component } from './news-template-49/news-template-49.component';
import { NewsTemplate49DialogComponent } from './news-template-49/news-template-49-dialog/news-template-49-dialog.component';
import { NewsTemplateCtdaComponent } from './news-template-ctda/news-template-ctda.component';
import { NewsTemplate50Component } from './news-template-50/news-template-50.component';
import { NewsTemplate50DialogComponent } from './news-template-50/news-template-50-dialog/news-template-50-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HeaderComponent,
    ReceiveDocumentComponent,
    FooterComponent,
    PaymentResultComponent,
    SubmitDocumentComponent,
    MainPageComponent,
    RegisterDocumentComponent,
    PostDetailComponent,
    MostViewedPostsComponent,
    TruncatePipe,
    SanitizedHtmlPipe,
    SearchNewsComponent,
    ErrorComponent,
    PostListComponent,
    PostPageComponent,
    EmbedPageComponent,
    VideojsComponent,
    ReceiveQuestionsComponent,
    QuestionInfoComponent,
    QuestionSearchComponent,
    ContentDocumentComponent,
    SearchDocumentComponent,
    HorizontalTimelineComponent,
    HeaderTemplate1Component,
    ImageSliderHomeComponent,
    ImageSliderCustomerServiceComponent,
    ArchiveComponent,
    TemplatePageComponent,
    NewsTemplate1Component,
    NewsTemplate1DialogComponent,
    DataMenuComponent,
    Footer2Component,
    HeaderTemplate2Component,
    NewsTemplate2Component,
    NewsTemplate2DialogComponent,
    IntroPageComponent,
    Layout80Component,
    FieldComponent,
    PartiesAndUnionsComponent,
    OrganizationStructureComponent,
    NewsTemplate3Component,
    NewsTemplate3DialogComponent,
    NewsTemplate4Component,
    NewsTemplate4DialogComponent,
    ContactComponent,
    RewardAndAchivementComponent,
    AchivementItemComponent,
    NewsTemplate5Component,
    NewsTemplate5DialogComponent,
    UserInfoComponent,
    UserInfoFormComponent,
    NewsTemplate6Component,
    NewsTemplate6DialogComponent,
    ForgotPasswordPageComponent,
    NewsTemplateAComponent,
    NewsTemplateADialogComponent,
    LibraryComponent,
    VideosComponent,
    ImagesComponent,
    SignupPageComponent,

    RouterLinkComponent,
    SettingsPageComponent,
    ChangePasswordComponent,
    SupportComponent,
    ElectronicTextComponent,
    NewsTemplateBComponent,
    NewsTemplateBDialogComponent,
    WorkingScheduleComponent,
    MemberUnitComponent,
    LibraryTextComponent,
    NewsTemplateCComponent,
    NewsTemplateCDialogComponent,
    NewsTemplate10Component,
    NewsTemplate10DialogComponent,
    GovernmentPageComponent,
    SearchPageComponent,
    ImageSliderAComponent,
    NewsTemplate11Component,
    NewsTemplate11DialogComponent,
    HeaderTemplate3Component,
    HistoryComponent,
    GeneralIntroComponent,
    VisionMissionComponent,
    EnterMailFormComponent,
    ResetPasswordFormComponent,
    PreviewTemplatePageComponent,
    NewsTemplate12Component,
    NewsTemplate12DialogComponent,
    RewardDialogComponent,
    IntroPageDialogComponent,
    PostDetailDialogComponent,
    LibraryTextDialogComponent,
    NewsTemplate13Component,
    NewsTemplate13DialogComponent,
    NewsTemplate14Component,
    NewsTemplate14DialogComponent,
    OrgTreeComponent,
    OrgTreeDialogComponent,
    HeaderTemplate4Component,
    NewsTemplate19Component,
    NewsTemplate19DialogComponent,
    NewsTemplate15Component,
    NewsTemplate15DialogComponent,
    NewsTemplate16Component,
    NewsTemplate16DialogComponent,
    NewsTemplate17Component,
    NewsTemplate17DialogComponent,
    NewsTemplate18Component,
    NewsTemplate18DialogComponent,
    NewsTemplate20Component,
    NewsTemplate20DialogComponent,
    ImageSliderTitleComponent,
    NewsTemplate21Component,
    NewsTemplate21DialogComponent,
    QuizComponent,
    QuizSearchComponent,
    ImageListComponent,
    ImagesSliderComponent,
    HeaderTemplate5Component,
    NewsTemplate22Component,
    NewsTemplate22DialogComponent,
    NewsTemplate23Component,
    NewsTemplate23DialogComponent,
    Footer3Component,
    NewsTemplate24Component,
    NewsTemplate24DialogComponent,
    NewsTemplate25Component,
    NewsTemplate25DialogComponent,
    NewsTemplate26Component,
    NewsTemplate26DialogComponent,
    NewsTemplate27Component,
    NewsTemplate27DialogComponent,
    NewsTemplate28Component,
    NewsTemplate28DialogComponent,
    NewsTemplate29Component,
    NewsTemplate29DialogComponent,
    NewsTemplate30Component,
    NewsTemplate30DialogComponent,
    NewsTemplate31Component,
    NewsTemplate31DialogComponent,
    PDetailComponent,
    PDetail2Component,
    NewsTemplate32Component,
    NewsTemplate32DialogComponent,
    NewsTemplate33Component,
    NewsTemplate33DialogComponent,
    NewsTemplate34Component,
    NewsTemplate34DialogComponent,
    OpendDialogVideoComponent,
    OpendDialogImagesComponent,
    Opend30DialogComponent,
    CustomerCareComponent,
    WaterBillInformationComponent,
    FileSettlemenComponent,
    NewsTemplate35Component,
    NewsTemplate35DialogComponent,
    NewsTemplate36Component,
    NewsTemplate36DialogComponent,
    NewsTemplate38Component,
    NewsTemplate37Component,
    NewsTemplate37DialogComponent,
    NewsTemplate39Component,
    NewsTemplate39DialogComponent,
    NotifyPageComponent,
    NewsTemplate40Component,
    NewsTemplate40DialogComponent,
    NewsTemplate41Component,
    NewsTemplate41DialogComponent,
    NewsTemplate42Component,
    NewsTemplate43DialogComponent,
    NewsTemplate43Component,
    NewsTemplate44Component,
    NewsTemplate45Component,
    NewsTemplate46Component,
    NewsTemplate46DialogComponent,
    NewsTemplate47Component,
    NewsTemplate47DialogComponent,
    NewsTemplate48Component,
    NewsTemplate48DialogComponent,
    NewsTemplate49Component,
    NewsTemplate49DialogComponent,
    NewsTemplateCtdaComponent,
    NewsTemplate50Component,
    NewsTemplate50DialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ButtonsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    GridModule,
    JsonLdModule,
    RouterModule.forRoot(AppRouter.routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
    }),
    MatIconModule,
    NgbModule,
    MatDialogModule,
    TransferHttpCacheModule,
    LayoutModule,
    PagerModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationModule,
    DialogsModule,
    InputsModule,
    DateInputsModule,
    DatePickerModule,
    DateInputModule,
    QRCodeModule,
    UploadModule,
    DropDownsModule,
    IntlModule,
    ScrollViewModule,
    IntlModule,
    // kéo thả
    DragDropModule,
    NgxExtendedPdfViewerModule,
    MatSelectModule,
    DiagramModule,
    GalleryModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    AuthService,
    AppLanguage,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // {provide: LocationStrategy, useClass: PathLocationStrategy},
    WebSocketService,
    { provide: LOCALE_ID, useValue: 'vi-VN' },
    EventEmitterService,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
