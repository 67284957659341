import { Component, EventEmitter, Input, OnInit, AfterViewInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from "../services/app.consts";
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';

@Component({
  selector: 'app-news-template-c',
  templateUrl: './news-template-c.component.html',
  // styleUrls: ['./news-template-c.component.scss']
})
export class NewsTemplateCComponent implements OnInit, AfterViewInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = "";
  viewMore: string = "";
  isShowMenu = false;
  manageDialog = false;
  isGallery: boolean = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = "";
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() saveData = new EventEmitter();
  @Output() changeTemplateC = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  imageHeight: number;
  info: any;
  isImageChosen = true;
  activeView = 0;
  public widthSView = '100%';
  public heightSView = '200px';
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit(): void {

    this.bootstrapNum = 12 / this.topNumber;

    // this.infoFromMain = JSON.parse(this.infoFromMain);
    // this.infoFromMain = this.infoFromMain ? JSON.parse(this.infoFromMain.slice(1, -1)) : null;
    // this.infoFromMain = this.infoFromMain[0] == '[' ? this.infoFromMain.slice(1, -1) : this.infoFromMain
    this.infoFromMain = JSON.parse(this.infoFromMain);

    // this.infoFromMain.imageHeight = this.infoFromMain.imageHeight ? this.infoFromMain.imageHeight : 200;
    // this.imageHeight = this.infoFromMain.imageHeight;

    if (!this.infoFromMain[0]) {
      this.infoFromMain = null;
      this.isImageChosen = false;
    }

    // console.log(`this.templateID: ${this.templateID}`);
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate.GroupID,
      "Layout": this.itemTemplate.Layout,
      "GroupIndex": this.itemTemplate.GroupIndex
    }

    this.info = this.infoFromMain;
    // if (this.ID == null && this.isAdminFlg == true) {
    //   this.topNews = [
    //     {
    //       DefaultFlg: true,
    //       ImageUrl: "../../assets/images/default.png",
    //       Title: "Title",
    //       UrlPath: "",
    //       CreateAt: '2021-10-26T08:41:50.453'
    //     },
    //     {
    //       DefaultFlg: true,
    //       ImageUrl: "../../assets/images/default.png",
    //       Title: "Title",
    //       UrlPath: "",
    //       CreateAt: '2021-10-26T08:41:50.453'
    //     },
    //     {
    //       DefaultFlg: true,
    //       ImageUrl: "../../assets/images/default.png",
    //       Title: "Title",
    //       UrlPath: "",
    //       CreateAt: '2021-10-26T08:41:50.453'
    //     }

    //   ]
    // }

    // console.log(`pageID from template: ${this.pageSelectedID}`);
    // console.log('item template 2: ');
    // console.log(this.itemTemplate);
    // console.log(this.infoFromMain)
    console.log(this.infoFromMain);
  }

  ngAfterViewInit():void {

  }

  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;

  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
    // this.topNews = new Array(3).fill({key: 'value'});
    // this.postTittle = this.translate.instant('Category_Name');
    // const dataRequest = {
    //   iD: this.ID,
    //   itemID: this.ID,
    //   index: this.OrderIdx
    // };
    // const result = await this.appService.doDELETE('api/Template', dataRequest);
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    //   this.isReloadPageAfterSave.emit(true)
    //   location.reload();
    // } else {
    //   this.notification.showSuccess(result.Msg);
    // }

  }

  nextView() {
    this.activeView = this.activeView + 1 < this.info.length ? this.activeView + 1 : 0;
  }

  lastView() {
    this.activeView = this.activeView - 1 >= 0 ? this.activeView - 1 : this.info.length -1;
  }

  onSave(e) {
    if (typeof e.Info == 'string') {
      e.Info = JSON.parse(e.Info);
    }
    this.infoFromMain = e.Info;
    this.info = this.infoFromMain;
    // var infoArr = e.Info;
    // this.infoFromMain = (infoArr && infoArr.length > 0) ? infoArr[0] : null;
    this.editFlg = false;
    this.manageDialog = false;
    let newTemp = {Info: e.Info,
      Saved: 1,
      pageBodyIndex: this.pageBodyIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      index: this.newsDecorationIndex
    };
    this.changeTemplateC.emit(newTemp);
    this.editFlg = false;
    this.manageDialog = false;
  }

  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }
  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true)
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(x => x.ID === this.infoFromMain.MenuID);
  }

  onSaveData() {
    this.saveData.emit();
  }

  isGalleryDialog(e){
    this.isGallery = e;
  }
  onGoBack(){
    this.isGallery = false;

  }
  getLink(link) {
    window.open(link, '_blank');
  }
}

