<section class="videos">
  <div class="row ">
    <div *ngIf="loading" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf=" !loading && dataGrids.length === 0">Không có video nào</div>
    <div class="col-md-12 col-12" *ngIf="!loading && colNum.ID == '1' && dataGrids.length > 0">
      <div class="row image-grid">
        <div *ngFor="let item of ImageListPage; let i = index" class="col-sm-3 col-12">
          <ng-container *ngIf="item.Size != '' && item.Size != null">
            <div class="row">
              <div class="col-md-12 col-12 is-cursor">
                <div class="row">
                  <div class="col-md-12 col-12 align-center">
                    <img class="file-type" [src]="getIcon(item.Path)" alt="">
                  </div>
                  <div class="col-md-12 col-12 align-center custom-text">
                    {{item.Name}}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.Size == '' || item.Size == null">
            <div class="archive-item">
              <div class="is-cursor" (click)="DetailMediaDialogOpened(item.ID,i)">
                <div class="img-wrapper">           
                      <video
                      *ngIf="
                        getFileType(item.ImageUrl) == 'mp4' ||
                        getFileType(item.ImageUrl) == 'mkv'
                      "
                      class="file-type"
                    >
                      <source [src]="apiRoot + item.ImageUrl" type="video/mp4" />
                    </video>
                </div>
              </div>
            
                <div class="img-title">
                  {{onDotsString(item.Title, 40)}}
                  <!-- <div class="col-md-2 col-12 btn-group btn-elipsis  full-height" role="group">
                    <kendo-dropdownbutton
                      [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}"
                      class="approve-btn" [data]="btnMbFunctionData" (itemClick)="onChangeFunction($event, item)"
                      (click)="onFunctionIconClick(item)">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </kendo-dropdownbutton>

                  </div> -->
                </div>

              

            </div>
          </ng-container>
        </div>
        <kendo-datapager *ngIf= " dataGrids.length > pageSize"
          [style.width.%]="100"
          [pageSize]="pageSize"
          [pageSizeValues]="false"
          [skip]="skip"
          [total]="total"
          (pageChange)="onPageChange($event)">

            <kendo-datapager-messages
              [of]="'pagerOf' | translate"
              [items]="'Image' | translate"
            >
            </kendo-datapager-messages>
        </kendo-datapager>
      </div>
    </div>
  </div>
</section>


<kendo-dialog *ngIf="DetailMediaOpened" [ngClass]="'window-center dialog-info videos'"
  class="center-detail videos crystal-detail">
  <div class="x-close-div">
    <button kendoButton class="xbutton-dialog xbutton-detail-media" [icon]="'close'"
      [title]="'Image_Close_Box' | translate" (click)="onCloseDetailMediaDialog()">
    </button>
  </div>

  <section class="router-outlet-content">
    <!-- Slide Media -->
    <div class="carousel-container">
     
        <video
        *ngIf="
          getFileType(ImageListPage[Position].ImageUrl) == 'mp4' ||
          getFileType(ImageListPage[Position].ImageUrl) == 'mkv'
        "
        class="video-wrapper"
        controls
      >
        <source [src]="apiRoot + ImageListPage[Position].ImageUrl" type="video/mp4" />
      </video>

      
    </div>

    <!--//// Slide Media -->


  </section>

</kendo-dialog>

