import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Notification } from '../services/app.notification';
import { AppSwal } from 'src/app/services/app.swal';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  // styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public userForm: FormGroup = new FormGroup({
    AccountName: new FormControl(null, Validators.required),
    Name: new FormControl(null, Validators.required),
    AccountEmail: new FormControl(null, Validators.email),
    AccountPhone: new FormControl(null, Validators.required),
    AccountAddress: new FormControl(null, Validators.required),
    Description: new FormControl(null, Validators.required),
    LVID: new FormControl(null, Validators.required),
   
    


  });
  lvList: Array<{ Name: string, ID: string }> = [];
  private queryParamsSubscription: Subscription;

  lvListFilter: Array<{ Name: string, ID: string }> = [];
  @Input() isAdminFlg: boolean = true;
  infoContact: any = {};
  currentLang: any;
  validForm: boolean = true;
  user: any;

  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any; //page thiết kế
  @Output() deleteBodyItem = new EventEmitter();

  constructor(
 
    private appService: AppService,
    private translate: TranslateService,
    private language: AppLanguage,
    private notification: Notification,
    private appSwal: AppSwal,
    private activatedRoute: ActivatedRoute
  ) {}
  searchOption = {
    searchText: '',
    type: 4,
    menuID:''

   
  };
  ngOnInit(): void {
    // this.queryParamsSubscription = this.activatedRoute.params.subscribe(
    //   async (data) => {
    //     this.searchOption.menuID = data['submenu'] ?? '';
    //   }
    // );
    this.searchOption.menuID = this.menuID;
    this.language.default();
    this.getInfo();
    this.getlvListFilter();

  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  lvHandleFilter(value) {
   
    
    this.lvListFilter = this.lvList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
 
  async getlvListFilter() {

    const dataRequest = { 
      langID: this.language.get(),
      type: this.searchOption.type,
    };

    const resultCatolories = await this.appService.doGET('api/PortalBody/GetCommon', dataRequest);
    if (resultCatolories && resultCatolories.Status === 1) {
      this.lvList = resultCatolories.Data;
      this.lvListFilter = this.lvList.slice();
    }
  }

  async getInfo() {
    const dataRequest = {
      langID: this.language.get(),
    };
    const result = await this.appService.doGET('api/PortalBody/GetContact', dataRequest);
    if (result && result.Status == 1) {
      this.infoContact =  JSON.parse(result.Data.Info);   
    }
  }

  async postInfo() {
    var dataRequest = this.userForm.value;
    dataRequest["menuID"] = this.searchOption.menuID;
    const result = await this.appService.doPOST('api/PortalQA', dataRequest);
    return result;
  }

  async onSubmit() {
    
    this.validForm =
      this.userForm.valid &&
      this.validateContent(this.userForm.value.Description) &&
      this.validateMail(this.userForm.value.AccountEmail) &&
      this.validateName(this.userForm.value.Name);

    if (this.validForm) {
      const option = await this.appSwal.showWarning(
        this.translate.instant('AreYouSure'),
        true
      );
      if (option) {
        const result = await this.postInfo();
        if (result && result.Status == 1) {
          this.notification.showSuccess(result.Msg);
          this.userForm.reset();
        } else {
          this.appSwal.showWarning('Failed', false);
        }
      }
    }
  }

  validateContent(Description: any) {
    return Description.length < 501;
  }

  validateName(Name: any) {   
    return Name.length < 251;
  }

  validateMail(AccountEmail) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(AccountEmail);
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex, 
      "layoutGroupIndex":this.layoutGroupIndex, 
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
}
