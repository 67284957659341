import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from 'src/app/services/app.language';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-reward-and-achivement',
  templateUrl: './reward-and-achivement.component.html',
  // styleUrls: ['./reward-and-achivement.component.scss']
})
export class RewardAndAchivementComponent implements OnInit {
  achivements: Array<any>;
  summary: string;
  description: string;
  loading = false

  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any; //page thiết kế
  @Output() deleteBodyItem = new EventEmitter();
  menuSelect = '';
  topNews: any;
  textNews: any;

  @Input() infoFromMain: any;
  @Output() changeTemplate2 = new EventEmitter();
  constructor(private appService: AppService, private language: AppLanguage) { }

  ngOnInit(): void {
    this.getAchivements();
  }
  async getAchivements() {
    // this.loading = true
    // const result = await this.appService.doGET(
    //   `api/Portal/GetAllAchievement?langID=${this.language.get()}`,
    //   null
    // );
    // this.achivements = result.Data.Prizes;
    // this.summary = result.Data.Sumary;
    // this.description = result.Data.Description;
    // this.loading = false

    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    

    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        menuID: this.infoFromMain ? this.infoFromMain.MenuID : (this.menuID ? this.menuID : ''),
        topNum: 100,
      }
    );

    if (result && result.Status === 1) {
      this.topNews = result.Data.topNews;
      console.log(this.topNews);
      
      if(this.topNews && this.topNews.length > 0) {
        // tin nào ko có hình thì đưa lên đầu
        // this.textNews = this.topNews.filter((x) => !x.ImageUrl);
        // if (this.textNews && this.textNews.length > 0) {
        //   this.summary = this.textNews[0].Title;
        //   this.description = this.textNews[0].Description;
        // }

        this.achivements = this.topNews.filter(x => x.ImageUrl != null);
      }
    }
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  onLoadData(event) {
    console.log('onloadData');
    console.log(event);
    this.infoFromMain.MenuID = (event && event.Info) ? event.Info.MenuID : '';
    this.getAchivements();
  }

  changeTemp2(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    // this.getTopNews();
    // this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate2.emit(newTemp);
  }
}
