import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
import * as FileSaver from 'file-saver';

import {
  SelectableSettings,
  PageChangeEvent,
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppComponent } from '../app.component';
import { AppConsts } from 'src/app/services/app.consts';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-electronic-text',
  templateUrl: './electronic-text.component.html',
  // styleUrls: ['./electronic-text.component.scss']
})
export class ElectronicTextComponent implements OnInit, OnDestroy {
  user: any;
  folderParentage = [];
  currentFolder = {
    ID: this.guid.empty,
    Name: 'Đảng và đoàn thể',
    ParentID: this.guid.empty,
  };
  emptyGuid = this.guid.empty;
  isAdminFlg: boolean = false;
  request_RewardID: any;
  popupClass = 'popup-width';
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;
  dataGrids = [];
  dataGridSelectableSettings: SelectableSettings;
  dataGridSort = {
    allowUnsort: true,
    mode: 'multiple',
  };
  public dataGridFocus = {
    Name: true,
  };
  dataGridSortByField: SortDescriptor[] = [];

  dataGridSkip = AppConsts.pageSkip;
  dataGridPageSize = AppConsts.pageSize;
  dataGridSelection: number[] = [];
  dataGridItem: any;
  dataLangs = [];
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

  selectedLangID = AppConsts.defaultLangID;
  currentLang: any;
  customCss = 0;
  public Type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataGridState: State = {
    skip: this.dataGridSkip,
    take: this.dataGridSkip + this.dataGridPageSize,

    filter: {
      logic: 'and',
      filters: [],
    },
    // group: [{ field: 'ParentName' }]
  };
  dataGridGridDataResult: GridDataResult;

  dataItems: Array<{ Name: string; ID: string }>;
  dataItemsFilter: Array<{ Name: string; ID: string }>;
  units: Array<{ Name: string; ID: string }> = [];
  unitsFilter: Array<{ Name: string; ID: string }> = [];
  fields: Array<{ Title: string; ID: string }> = [];
  fieldsFilter: Array<{ Title: string; ID: string }> = [];
  public enabled = true;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  languageName = '';
  submenu: string
  infoOpened = false;
  ManageRewardOpened = false;
  urlDownload = this.appService.apiRoot;
  searchOption = {
    searchText: "",
    type: 2,
    UnitPublishID: "",
    FileTypeID: -1,
    OperationID: this.GUID_EMPTY
  };
  dataErr: any;

  pageName = '';
  defaultType: any;
  typeSearch: any;
  element4: any;
  tabItem: any;
  pageList: any;
  eDocumentPageList: any;
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appComponent: AppComponent,
    public appConsts: AppConsts,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getLanguageName()
  }

  ngOnDestroy(): void { }

  async ngOnInit() {
    this.submenu = this.activatedRoute.snapshot.params.submenu
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    await this.getFields()
    await this.getUnits()
    await this.getMenu()
    await this.getEDocumentSubMenu();
    setTimeout(() => {
      this.removeIcon();
      this.addIcon();
    }, 100);
    this.tabItem = history.state.item ?? null;
    await this.getdataItemsSubmenu();
    this.allData = this.allData.bind(this);
    // this.getPageName();
    this.currentLang = this.language.get();
    window.addEventListener("eDocumentStorage", this.getdataItemsSubmenu, false);
  }

  private getdataItemsSubmenu = async () => {
    if(this.submenu) {
      let eDocumentSubmenuID = localStorage.getItem("CurrentMenuID");
      if(!eDocumentSubmenuID) {
        this.router.navigate([AppConsts.page.electronicText])
        return
      }
      let itemName = this.pageList.find(page => page.ID == eDocumentSubmenuID).Name;
      let index = this.eDocumentPageList.findIndex(page => page.Name == itemName);
      this.tabItem = this.eDocumentPageList[index];
      this.searchOption.FileTypeID = this.tabItem.ID  
    }
    await this.getdataItems();
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.electronicText);
    this.controlDefault = false;
  }

  getLanguageName() {
    let lang = this.language.get();
    if (lang == null || lang == undefined) {
      lang = "vi-VN";
    }
    this.languageName = lang;
  }

  setSelectableSettings(): void {
    //this.allowMulti = !this.allowMulti;
    this.dataGridSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single',
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onValueChange() {
    this.onSearch()
  }
  async getdataItems() {
    this.loading = true;
    const dataRequest = this.createDataRequest(this.searchOption)
    this.dataGrids = [];
    const result = await this.appService.doGET(
      'api/File/Search',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.dataItemsFilter = result.Data.slice();
      this.dataGrids = result.Data.map((temp) => {
        return {
          ID: temp.ID,
          DatePublish: temp.DatePublish,
          FileTypeID: temp.FileTypeID,
          Name: temp.Name,
          OperationID: temp.OperationID,
          OperationName: temp.OperationName,
          Path: temp.Path,
          Sumary: temp.Sumary,
          TextNumber: temp.TextNumber,
          UnitName: temp.UnitName,
          UnitPublishID: temp.UnitPublishID
        };
      });
      this.binddataItems();
      this.dataItems = [];

      result.Data.forEach((item) => {
        this.dataItems.push(item);
      });
    }
    this.loading = false;
  }

  async getFields() {
    const dataRequest = {
      LangID: this.languageName
    };
    const result = await this.appService.doGET('api/Operation', dataRequest);
    if (result && result.Status === 1) {
      this.fields = this.fieldsFilter = result.Data;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async getUnits() {
    const dataRequest = {
    };
    const result = await this.appService.doGET('api/UnitDocument', dataRequest);
    if (result && result.Status === 1) {
      this.units = this.unitsFilter = result.Data;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }


  async getMenu() {
    const result = await this.appService.doGET('api/Menu/GetChildMenu', { 'parentID': '64fa377a-d45f-4a44-a5dc-179d5efb0701' });
    if (result && result.Status === 1) {
      this.pageList = result.Data;
      this.pageList.sort((l1, l2) => (l1.OrderIdx < l2.OrderIdx) ? -1 : 1);
    }
  }

  async getEDocumentSubMenu() {
    const result = await this.appService.doGET('api/CommonMenu', {});
    if (result && result.Status === 1) {
      this.eDocumentPageList = result.Data.filter(e => e.Type == 14);
    }
  }

  handleFilter(value) {
    this.units = this.units.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  setDefault() {
    this.dataGridItem = {
      IsAdd: true,
      searchText: "",
      type: 2,
      UnitPublishID: "",
      FileTypeID: -1,
      OperationID: this.GUID_EMPTY
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    this.dataGridSelection = [];
    this.enabled = true;
    this.enabledID = true;
  }

  onGridPageChange(event: PageChangeEvent) {
    this.dataGridSkip = event.skip;
    this.binddataItems();
  }

  ondataItemselectedKeysChange() { }

  binddataItems(data?) {
    let dataGrid = data ? data : this.dataGrids;
    this.dataGridGridDataResult = {
      data: orderBy(dataGrid, this.dataGridSortByField),
      total: dataGrid.length,
    };
    this.dataGridGridDataResult = process(dataGrid, this.dataGridState);
  }

  ondataItemsortChange(sort: SortDescriptor[]): void {
    this.dataGridSortByField = sort;
    this.binddataItems(this.dataGrids.filter(data => data.FileTypeID == this.tabItem.ID));
  }

  public onGridDataStateChange(state: DataStateChangeEvent): void {
    this.dataGridSelection = [];
    this.dataGridState = state;
    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  onRemoveSearchText() {
    this.searchOption.searchText = '';
    this.getdataItems();
  }

  onSearch() {
    this.getdataItems();
  }

  async onReload() {
    this.getdataItems();
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }

  download(path, filename) {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  };
  onStartedDownload(id) {
    console.log(`Started downloading: ${id}`);
  }

  onFailed(error) {
    console.log(`Download failed: ${error}`);
  }


  async downloadItem(url) {

    const dataRequest = {
      url: url
    };

    const result = await this.appService.doDownload('api/File/Download', dataRequest);
    if (result) {
      var nameFile = this.getFileName(url);
      FileSaver.saveAs(result, nameFile);
    }
  }

  getFileName(fileUrls) {
    var nameFile = "temp.txt";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataGridItem;

    return {
      searchText: temp.searchText,
      UnitPublishID: temp.UnitPublishID ?? '',
      FileTypeID: temp.FileTypeID,
      type: temp.type,
      OperationID: temp.OperationID ?? this.GUID_EMPTY
    };
  }

  onCloseGrid(status: any) {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataGrids, {}).data,
    };

    return result;
  }

  unitsHandleFilter(value) {
    this.unitsFilter = this.units.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  fieldsHandleFilter(value) {
    this.fieldsFilter = this.fields.filter(
      (s) => s.Title.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  removeIcon() {
    var element = document.querySelector(".k-pager-nav")?.firstElementChild;
    var element2 = document.querySelector(".k-pager-last")?.firstElementChild;
    var element3 = document.querySelector(".k-i-arrow-w")?.parentElement;
    var element4 = document.querySelector(".k-pager-numbers li")?.firstElementChild;

    //element3.remove();
    element?.classList.remove("k-i-seek-w");
    element2?.classList.remove("k-i-seek-e");
    //Kiểm tra nếu page1 thì ẩn
    if (element4?.innerHTML == ' 1 ') {
      element3?.classList.add("check-element");
    } else {
      element3?.classList.add("k-i-arrow-w");
    }
  }
  //Add class icon
  addIcon() {
    var element = document.querySelector(".k-pager-nav")?.firstElementChild;
    var element2 = document.querySelector(".k-pager-last")?.firstElementChild;
    element?.classList.add("k-i-arrow-seek-left");
    element2?.classList.add("k-i-arrow-seek-right");
  }
}
