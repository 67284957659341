import { Injectable } from '@angular/core';
import { log } from 'console';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyServiceService {
  private messageSource = new BehaviorSubject(undefined);
  private menuChild = new BehaviorSubject(undefined);
  currentMessage = this.messageSource.asObservable();
  getMenuChild = this.menuChild.asObservable();
  constructor() {
  }
  sendMenuChild(m){
    this.menuChild.next(m);
  }
  sendMessage(message: any) {
    this.messageSource.next(message);
  }
}
