import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppControls } from 'src/app/services/app.controls';
import { Subscription } from 'rxjs/Rx';
import { AppLanguage } from '../../services/app.language';
import { ActivatedRoute } from '@angular/router';
import { AppConsts } from 'src/app/services/app.consts';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-receive-questions',
  templateUrl: './receive-questions.component.html',
  styleUrls: ['./receive-questions.component.scss']
})
export class ReceiveQuestionsComponent implements OnInit, OnDestroy {
  pageName = '';

  private queryParamsSubscription: Subscription;
  constructor(public appControls: AppControls,
    private language: AppLanguage,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    public appConsts: AppConsts,
    private meta: Meta) {
      this.language.default();
      
   }
  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    // this.getPageName();
  
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageNamePortal();
    this.updateMeta();
   
  }
  onTabSelect(e) {
  }

  updateMeta(): void{
    this.title.setTitle(this.pageName);
    var description =   this.pageName;
    var keywords =  this.pageName;
    var url = window.location.origin + "/" +AppConsts.page.receiveQuestions;
    var title = this.pageName;
    var ogDescription = this.pageName;
    var image = '';

    this.meta.updateTag({name: 'description', content:description});
    this.meta.updateTag({name: 'keywords', content:keywords});
    this.meta.updateTag({name: 'og:url', content:url});
    this.meta.updateTag({name: 'og:title', content:title});
    this.meta.updateTag({name: 'og:image', content:image});
    this.meta.updateTag({name: 'og:description', content: ogDescription});

  }
}
