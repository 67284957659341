<section class="receive-questions router-outlet-content ">
    <div class="row admin">
        <div class="col-md-12 col-12">
            <div class="page-name">{{pageName}}</div>
        </div>
        <div class="col-md-12 col-12">
            <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                <kendo-tabstrip-tab [title]="'QUESTION_Look_Up_Question' | translate" [selected]="true">
                    <ng-template kendoTabContent>
                        <app-question-search></app-question-search>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab [title]="'QUESTION_Make_Question' | translate">
                    <ng-template kendoTabContent>
                        <app-question-info></app-question-info>
                    </ng-template>
                </kendo-tabstrip-tab>
            </kendo-tabstrip>
        </div>
    </div>
</section>