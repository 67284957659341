<div class="news-template-38" (window:resize)="onResize($event)">
  <div *ngIf="isAdminFlg" class="container-fluid p-0">
    <div class="row position-relative m-0 banner-container">
      <div class=" vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
        <div class="three-dot-area" (click)="enableChoice()">
          <img class="three-dot" src="../../assets/images/three_dot_vertical.png" alt="" />
        </div>
        <div class="menu" *ngIf="isShowMenu">
          <div class="menu-row-current-border" (click)="editComponent()">
            <div class="menu-item-2">{{'Upload_Image' | translate}}</div>
          </div>
          <div class="menu-row-current" (click)="deleteComponent()">
            <div class="menu-item-3">{{'Delete' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 banner">
        <kendo-scrollview #scrollview [endless]="true" [data]="info" *ngIf="true" [width]="widthSView" [style.height.px]="innerWidth > 770 ? innerWidth*0.235 : innerWidth*0.6"
        [arrows]="true" [pageable]="true" >

          <ng-template let-item="item">
            <!-- <div class="image-name">
              {{ item?.Title ? item.Title : '' }}
            </div> -->
            <img *ngIf="info.length>0" src="{{ this.appService.apiRoot + item.ImageUrl }}" alt="{{ item.Title }}" [style.height.px]="innerWidth > 770 ? innerWidth*0.235 : innerWidth*0.6"
              class="img-scrollview" draggable="false" (click)="getLink(item.DetailPath)" />
            <img *ngIf="info.length==0" src="../../assets/images/default.png" alt="" draggable="false"
              class="img-scrollview" />
          </ng-template>
        </kendo-scrollview>
      </div>
    </div>
  </div>
  <div *ngIf="!isAdminFlg">
    <kendo-scrollview class="img-scrollview" #scrollview [endless]="true" [data]="info" *ngIf="isLoadedBannerRightSide" [style.height.px]="innerWidth > 770 ? innerWidth*0.235 : innerWidth*0.6"
       [arrows]="true" [pageable]="true">
      <ng-template let-item="item">
        <!-- <div class="image-name">
          {{ item?.Title ? item.Title : '' }}
        </div> -->
        <img *ngIf="info.length>0" src="{{ this.appService.apiRoot + item.ImageUrl }}" alt="{{ item.Title }}" [style.height.px]="innerWidth > 770 ? innerWidth*0.235 : innerWidth*0.6"
          class="img-scrollview" draggable="false" (click)="getLink(item.DetailPath)" />
        <img *ngIf="info.length==0" src="../../assets/images/default.png" alt="" draggable="false"
          class="img-scrollview" />
      </ng-template>
    </kendo-scrollview>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    [ngClass]="isGallery == false ? 'router-outlet-content dialog-info window-center': 'router-outlet-content-gallery dialog-info window-center' ">
    <div [ngClass]="isGallery == false ? 'employe-detail-mb': 'employe-detail'">
      <div class="slider-dialog close-div">
        <div class="title-info-req">
          <span *ngIf="dltFlg == false && isGallery == false" [textContent]="'Select_Images' | translate"></span>
          <span *ngIf="dltFlg == false && isGallery == true" [textContent]="'Gallery' | translate"></span>
          <span *ngIf="dltFlg == true" [textContent]="'Gallery_Scrollview_Delete' | translate"></span>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img *ngIf="isGallery == false" class="close-icon" src="../../assets/images/close-btn-black.png" alt="" (click)="onClose($event)" />
          <img *ngIf="isGallery == true" class="close-icon" src="../../assets/images/close-btn-black.png" alt="" (click)="onGoBack()" />
        </div>
      </div>
    </div>
    <app-archive *ngIf="dltFlg == false" [pageSelectedID]="pageSelectedID" [ID]="ID" [info]="info" [templateID]="templateID"
      [galleryData]="galleryData" (onSave)="onSave($event)" (addNew)="addNewBanner($event)"
      (deleteAll)="deleteAllBanner($event)" (isGallery)="isGalleryDialog($event)" [isGoBack]="isGallery">
    </app-archive>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</div>
