<section class="template-page">
  <div class="row">
    <div class="col-sm-12 col-12 col-md-12 col-xl-9 left-block">
      <div class="page-list-background">
        <div class="menu-bar">
          <div class="nav-scroll" *ngFor="let item of pageList; let i = index"
            [ngClass]="item.Selected == true ? 'nav-scroll-selected' : ' '">
            <button [ngClass]="
                item.Selected == true ? 'nav-item-selected' : 'nav-item'
              " (click)="onClickItemPage(item, i)">
              {{ item.Name }}
            </button>
          </div>
        </div>
        <div class="page-list-container">
          <div class="page-lists">
            <div cdkDropList id="pageHeader" #headerList="cdkDropList" [cdkDropListData]="pageHeader"
              class="header-list" (cdkDropListDropped)="drop($event)">
              <div class="header-empty-container" *ngIf="!pageHeader || pageHeader.length === 0">
                <div class="header-empty">
                  <h3>HEADER</h3>
                  <div class="instruction-container">
                    <img src="../../../assets/images/AddNew.png" alt="add-icon" />

                    <div class="instruction">Kéo thả template vào đây</div>
                  </div>
                </div>
              </div>
              <div *ngFor="let item of pageHeader" class="header-box" cdkDrag [cdkDragData]="item">
                <button class="hidden-dlt k-icon k-i-delete" (click)="onDlt('header', 0)"></button>
                <!-- <app-header-template2 (navMenu)="navMenuHandle($event)" [menu]="pageList">
                </app-header-template2> -->
                <!-- <div *ngIf="headerInfo"> -->
                <app-header-template1 *ngIf="item == '1'" [menu]="pageList">
                </app-header-template1>
                <!-- <app-header-template2 *ngIf="item == '2'" (navMenu)="navMenuHandle($event)" [menu]="pageList"
                  [templateID]="TemplateID"  [pageSelectedID]="PageSelectedID" >
                </app-header-template2> -->
                <app-header-template2 *ngIf="item == '2'" [isAdminFlg]="true" (menuItem)="menuItem($event)"
                  (menuItemchild)="menuItemchild($event)" (menuSearch)="menuSearch($event)">
                </app-header-template2>
                <app-header-template3 *ngIf="item == '3'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
                </app-header-template3>
                <app-header-template4 *ngIf="item == '4'" [isAdminFlg]="true" (menuItem)="menuItem($event)"
                  (menuItemchild)="menuItemchild($event)" (menuSearch)="menuSearch($event)" [templateID]="TemplateID"
                  [pageSelectedID]="PageSelectedID">
                </app-header-template4>
                <app-header-template5 *ngIf="item == '5'" [isAdminFlg]="true" (menuItem)="menuItem($event)"
                  (menuItemchild)="menuItemchild($event)" (menuSearch)="menuSearch($event)" [templateID]="TemplateID"
                  [pageSelectedID]="PageSelectedID" [bodyWidth]="bodyWidth">
                </app-header-template5>
                <!--default-->
                <!-- <app-header-template2 *ngIf="!itemsHeader.includes(item)" (navMenu)="navMenuHandle($event)" [menu]="pageList">
                  </app-header-template2> -->
                <!-- </div> -->
              </div>
            </div>
            <div cdkDropList [cdkDropListData]="pageBody" #bodyList="cdkDropList" cdkDropListOrientation="vertical"
              (cdkDropListDropped)="dropGroup($event)" class="body-list">
              <div *ngIf="!pageBody || pageBody.length === 0" class="body-empty-container">
                <div class="body-empty">
                  <h3>BODY</h3>
                  <div class="instruction-container">
                    <img src="../../../assets/images/AddNew.png" alt="add-icon" />

                    <div class="instruction">Kéo thả template vào đây</div>
                  </div>
                </div>
              </div>
              <div cdkDropListGroup class="layout-box" cdkDrag [cdkDragData]="layouts"
                *ngFor="let layouts of pageBody; let bodyIndex = index">
                <button class="hidden-dlt k-icon k-i-delete" (click)="onDlt('body', bodyIndex)"></button>
                <div class="hidden-move k-icon k-i-move" cdkDragHandle></div>
                <!-- <app-layout80 [group]="group"></app-layout80> -->
                <div class="layout-group" *ngFor="let group of layouts; let groupIndex = index"
                  [style.width.%]="group.layout">
                  <div cdkDropList class="layout-list" #layoutList="cdkDropList" id="{{ group.id }}"
                    [cdkDropListData]="group.items" (cdkDropListDropped)="dropItem($event)"
                    [cdkDropListConnectedTo]="getConnectedList()">
                    <div class="body-box" [style.width.%]="100" *ngFor="let item of group.items; let i = index" cdkDrag
                      [cdkDragData]="item">
                      <app-image-slider-home *ngIf="item?.FrameID == 0" [ID]="item?.ID" [isAdminFlg]="true"
                        [pageBodyIndex]="bodyIndex" [templateID]="this.FrameID" [pageSelectedID]="this.PageSelectedID"
                        [itemTemplate]="item" [OrderIdx]="item?.OrderIdx" [banner]="item" [newsDecorationIndex]="i"
                        [layoutGroupIndex]="groupIndex" (changeTemplate0)="this.changeTemp0($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-image-slider-home>

                      <app-image-slider-a *ngIf="item?.FrameID == -1" [ID]="item?.ID" [isAdminFlg]="true"
                        [pageBodyIndex]="bodyIndex" [templateID]="item?.FrameID" [pageSelectedID]="this.PageSelectedID"
                        [itemTemplate]="item" [OrderIdx]="item?.OrderIdx" [banner]="item" [newsDecorationIndex]="i"
                        [layoutGroupIndex]="groupIndex" (changeTemplate_1)="this.changeTemp_1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-image-slider-a>

                      <app-news-template-1 *ngIf="item?.FrameID == 1" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-1>

                      <app-news-template-2 *ngIf="item?.FrameID == 2" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-2>

                      <app-news-template-3 *ngIf="item?.FrameID == 3" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate3)="this.changeTemp3($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-3>

                      <app-news-template-4 *ngIf="item?.FrameID == 4" [ID]="item?.ID" [isAdminFlg]="true"
                        [pageBodyIndex]="bodyIndex" [infoFromMain]="item?.Info" [categoriesList]="categoriesList"
                        [layoutGroupIndex]="groupIndex" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                        (changeTemplate4)="this.changeTemp4($event)" (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-4>

                      <app-news-template-5 *ngIf="item?.FrameID == 5" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate5)="this.changeTemp5($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-5>

                      <app-news-template-6 *ngIf="item?.FrameID == 6" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate6)="this.changeTemp6($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-6>

                      <app-news-template-a *ngIf="item?.FrameID == 7" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplateA)="this.changeTempA($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-a>

                      <app-news-template-b *ngIf="item?.FrameID == 8" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplateB)="this.changeTempB($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-b>

                      <app-news-template-c *ngIf="item?.FrameID == 9" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplateC)="this.changeTempC($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-c>

                      <app-news-template-11 *ngIf="item?.FrameID == 11" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-11>

                      <app-news-template-10 *ngIf="item?.FrameID == 10" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-10>

                      <app-news-template-12 *ngIf="item?.FrameID == -2" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-12>

                      <!-- template văn bản -->
                      <app-library-text *ngIf="item?.FrameID == 13" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-library-text>

                      <!-- template liên hệ -->
                      <app-contact *ngIf="item?.FrameID == 14" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-contact>

                      <!-- template chi tiết tin bài-->
                      <app-post-detail *ngIf="item?.FrameID == 15" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (onChangeTempPostDetail)="this.changeTempPostDetail($event)"
                        (deleteBodyItem)="onDltBodyItem($event)" [isIntroPage]="true">
                      </app-post-detail>

                      <!-- template sơ đồ tổ chức -->
                      <app-organization-structure *ngIf="item?.FrameID == 16" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-organization-structure>

                      <!-- template đơn vị thành viên-->
                      <app-member-unit *ngIf="item?.FrameID == 17" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-member-unit>

                      <!-- template giải thưởng và thành tựu-->
                      <app-reward-and-achivement *ngIf="item?.FrameID == 18" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-reward-and-achivement>

                      <!-- template giới thiệu-->
                      <app-intro-page *ngIf="item?.FrameID == 19" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-intro-page>

                      <!-- template tin tức -->
                      <app-news-template-13 *ngIf="item?.FrameID == 20" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-13>

                      <!-- template dịch vụ khách hàng -->
                      <app-news-template-14 *ngIf="item?.FrameID == 21" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-14>

                      <!-- template sơ đồ tổ chức -->
                      <app-org-tree *ngIf="item?.FrameID == 23" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-org-tree>

                      <!-- template tin mới nhiều chuyên mục -->
                      <app-news-template-19 *ngIf="item?.FrameID == NEWS_19" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-19>
                      <!-- template dịch vụ -->
                      <app-news-template-20 *ngIf="item?.FrameID == NEWS_20" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-20>
                      <!-- image slider title -->
                      <app-image-slider-title *ngIf="item?.FrameID == NEWS_21" [ID]="item?.ID" [isAdminFlg]="true"
                        [pageBodyIndex]="bodyIndex" [templateID]="this.FrameID" [pageSelectedID]="this.PageSelectedID"
                        [itemTemplate]="item" [OrderIdx]="item?.OrderIdx" [banner]="item" [newsDecorationIndex]="i"
                        [layoutGroupIndex]="groupIndex" (changeTemplate0)="this.changeTemp0($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-image-slider-title>
                      <!-- template 1 without time -->
                      <app-news-template-1 *ngIf="item?.FrameID == NEWS_22" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)" [templateType]="2">
                      </app-news-template-1>

                      <!-- template advertise -->
                      <app-news-template-21 *ngIf="item?.FrameID == NEWS_23" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-21>

                      <!-- template hỏi đáp -->
                      <app-quiz *ngIf="item?.FrameID == NEWS_24" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-quiz>
                      <!-- image list -->
                      <app-image-list *ngIf="item?.FrameID == NEWS_25" [ID]="item?.ID" [isAdminFlg]="true"
                        [pageBodyIndex]="bodyIndex" [templateID]="this.FrameID" [pageSelectedID]="this.PageSelectedID"
                        [itemTemplate]="item" [OrderIdx]="item?.OrderIdx" [banner]="item" [newsDecorationIndex]="i"
                        [layoutGroupIndex]="groupIndex" (changeTemplate0)="this.changeTemp0($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-image-list>
                      <!-- images slider -->
                      <app-images-slider *ngIf="item?.FrameID == NEWS_26" [ID]="item?.ID" [isAdminFlg]="true"
                        [pageBodyIndex]="bodyIndex" [templateID]="this.FrameID" [pageSelectedID]="this.PageSelectedID"
                        [itemTemplate]="item" [OrderIdx]="item?.OrderIdx" [banner]="item" [newsDecorationIndex]="i"
                        [layoutGroupIndex]="groupIndex" (changeTemplate0)="this.changeTemp0($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-images-slider>
                      <!-- các tin bài dạng cột -->
                      <app-news-template-22 *ngIf="item?.FrameID == NEWS_27" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-22>
                      <!-- template most view -->
                      <app-news-template-23 *ngIf="item?.FrameID == NEWS_28" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-23>
                      <!-- template news vertical -->
                      <app-news-template-24 *ngIf="item?.FrameID == NEWS_29" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-24>
                      <app-news-template-25 *ngIf="item?.FrameID == NEWS_30" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-25>
                      <app-news-template-26 *ngIf="item?.FrameID == NEWS_31" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-26>
                      <!-- template dịch vụ 2-->
                      <app-news-template-27 *ngIf="item?.FrameID == NEWS_32" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-27>
                      <app-news-template-28 *ngIf="item?.FrameID == NEWS_33" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplateB)="this.changeTempB($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-28>
                      <!-- template tin tức nhiều chuyên mục -->
                      <app-news-template-29 *ngIf="item?.FrameID == NEWS_34" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-29>
                      <!-- template tong quan thu vien -->
                      <app-news-template-30 *ngIf="item?.FrameID == NEWS_35" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-30>
                      <!-- template tin mới 1-n -->
                      <app-news-template-31 *ngIf="item?.FrameID == NEWS_36" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-31>
                      <!-- template liên kết mạng xã hội -->
                      <app-news-template-32 *ngIf="item?.FrameID == NEWS_37" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-32>
                      <app-news-template-33 *ngIf="item?.FrameID == NEWS_38" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-33>
                      <!-- chuyên mục xem nhiều -->
                      <app-news-template-34 *ngIf="item?.FrameID == NEWS_39" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-34>
                      <!-- Chăm sóc khách hàng -->
                      <app-customer-care *ngIf="item?.FrameID == NEWS_40" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-customer-care>
                      <app-image-slider-customer-service *ngIf="item?.FrameID == NEWS_41" [ID]="item?.ID"
                        [isAdminFlg]="true" [pageBodyIndex]="bodyIndex" [templateID]="this.FrameID"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [OrderIdx]="item?.OrderIdx"
                        [banner]="item" [newsDecorationIndex]="i" [layoutGroupIndex]="groupIndex"
                        (changeTemplate0)="this.changeTemp0($event)" (deleteBodyItem)="onDltBodyItem($event)">
                      </app-image-slider-customer-service>
                      <!-- Thông tin tiền nước-->
                      <app-water-bill-information *ngIf="item?.FrameID == NEWS_41" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-water-bill-information>
                      <!-- Lien he co thong tin -->
                      <app-news-template-35 *ngIf="item?.FrameID == NEWS_42" [ID]="item?.ID" [isAdminFlg]="true"
                        [pageBodyIndex]="bodyIndex" [infoFromMain]="item?.Info" [categoriesList]="categoriesList"
                        [layoutGroupIndex]="groupIndex" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                        (changeTemplate4)="this.changeTemp4($event)" (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-35>
                      <!-- Thu vien radio -->
                      <app-news-template-36 *ngIf="item?.FrameID == NEWS_43" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp1($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-36>
                       <!-- image slider banner -->
                       <app-news-template-38 *ngIf="item?.FrameID == NEWS_45" [ID]="item?.ID" [isAdminFlg]="true"
                       [pageBodyIndex]="bodyIndex" [templateID]="this.FrameID" [pageSelectedID]="this.PageSelectedID"
                       [itemTemplate]="item" [OrderIdx]="item?.OrderIdx" [banner]="item" [newsDecorationIndex]="i"
                       [layoutGroupIndex]="groupIndex" (changeTemplate0)="this.changeTemp0($event)"
                       (deleteBodyItem)="onDltBodyItem($event)">
                     </app-news-template-38>
                      <!-- template trở về đầu trang -->
                      <app-news-template-37 *ngIf="item?.FrameID == NEWS_44" [ID]="item?.ID" [isAdminFlg]="true"
                        [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                        [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                        (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                        [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                        (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                        (deleteBodyItem)="onDltBodyItem($event)">
                      </app-news-template-37>
                      <!-- Template tin tuc gom các tin con -->
                      <app-news-template-39 *ngIf="item?.FrameID == NEWS_46" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-39>
                      <!-- template chuyen de truyen thong -->
                      <app-news-template-40 *ngIf="item?.FrameID == NEWS_47" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-40>
                      <!-- template gioi thieu o trang chu -->
                      <app-news-template-41 *ngIf="item?.FrameID == NEWS_48" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-41>
                    <!-- template gioi thieu o trang chu -->
                    <app-news-template-42 *ngIf="item?.FrameID == NEWS_49" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-42>
                    <!-- template chuong trinh- du an -->
                    <app-news-template-43 *ngIf="item?.FrameID == NEWS_50" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-43>
                    <!-- template chuyen gia -->
                    <app-news-template-44 *ngIf="item?.FrameID == NEWS_51" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-44>          
                    <!-- Trang chuyen gia -->
                    <app-news-template-45 *ngIf="item?.FrameID == NEWS_52" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-45>     
                    <!-- Trang chi hoi thanh vien -->
                    <app-news-template-46 *ngIf="item?.FrameID == NEWS_53" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-46>     
                    <!-- Email và SĐT -->
                    <app-news-template-47 *ngIf="item?.FrameID == NEWS_54" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate1)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-47>          
                    <!-- Trang chuyên đề truyền thông -->
                    <app-news-template-48 *ngIf="item?.FrameID == NEWS_55" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp12($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-48>
                    <!-- Chi tiết CĐ truyền thông -->
                    <app-news-template-49 *ngIf="item?.FrameID == NEWS_56" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-49>       
                    <!-- Chi tiết chương trình dự án -->
                    <app-news-template-50 *ngIf="item?.FrameID == NEWS_57" [ID]="item?.ID" [isAdminFlg]="true"
                      [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                      [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx"
                      (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                      [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [layoutGroupIndex]="groupIndex"
                      (saveData)="this.onReload()" (changeTemplate2)="this.changeTemp2($event)"
                      (deleteBodyItem)="onDltBodyItem($event)">
                    </app-news-template-50>                                                                        
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- <div
          cdkDropList
          id="pageBody"
          #bodyList="cdkDropList"
          [cdkDropListData]="pageBody"
          class="body-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="body-box"
          *ngFor="let item of pageBody; let bodyIndex = index"
          cdkDrag
          [cdkDragData]="item"
          (cdkDragMoved)="dragMoved($event)"
          (cdkDragReleased)="dragReleased($event)"
          >
          <button
          class="hidden-dlt k-icon k-i-delete"
          (click)="dltBodyLayout(bodyIndex)"
          ></button>
            <app-layout80 [templates]="item" [idx]="bodyIndex" [categoriesList]="categoriesList"></app-layout80>
          </div>
        </div> -->

            <div cdkDropList #footerList="cdkDropList" id="pageFooter" [cdkDropListData]="pageFooter"
              class="footer-list" (cdkDropListDropped)="drop($event)">
              <div class="footer-empty-container" *ngIf="!pageFooter || pageFooter.length === 0">
                <div class="footer-empty">
                  <h3>FOOTER</h3>
                  <div class="instruction-container">
                    <img src="../../../assets/images/AddNew.png" alt="add-icon" />

                    <div class="instruction">Kéo thả template vào đây</div>
                  </div>
                </div>
              </div>
              <div *ngFor="let item of pageFooter" class="footer-box">
                <!-- <app-footer2></app-footer2> -->
                <!-- <button class="hidden-dlt k-icon k-i-delete" (click)="dltFooter()"></button> -->
                <button class="hidden-dlt k-icon k-i-delete" (click)="onDlt('footer', 0)"></button>
                <!-- <div *ngIf="footerInfo"> -->
                <app-footer *ngIf="item == '1'"></app-footer>
                <app-footer2 *ngIf="item == '2'"></app-footer2>
                <app-footer3 *ngIf="item == '3'" [bodyWidth]="bodyWidth" (isMoveToTop)="moveToTop()"></app-footer3>
                <!--default-->
                <!-- <app-footer2 *ngIf="!itemsFooter.includes(item)"></app-footer2> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-12 col-md-12 col-xl-3 p-0 right-block">
      <div class="page-save">
        <!-- <img class="page-save-icon" src="../../../assets/images/page-save.png" (click)="onSavePage()"> -->
        <button class="preview-button" (click)="onPreview()">Xem trước</button>
        <button class="page-publish" (click)="onClickPublish()">Công bố</button>
      </div>
      <kendo-tabstrip #tabstrip (tabSelect)="onTabChanged($event)" class="portal-tabstrip">
        <kendo-tabstrip-tab class="officetabstrip" [selected]="this.tabID == 0"
          [ngClass]="this.tabID == 0 ? '' : 'active'" class="active" title="Chèn">
          <ng-template kendoTabContent>
            <div class="item-list-body">
              <label class="item-list-label">HEADER</label>
              <div cdkDropList #headerItem="cdkDropList" id="itemsHeader" [cdkDropListData]="itemsHeader"
                [cdkDropListConnectedTo]="[headerList]" class="item-list-body-col" cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event)">
                <div class="item-box-header" *ngFor="let item of itemsHeader" cdkDrag>
                  <img class="header-preview" src="../../../assets/images/header-preview.png" *cdkDragPreview />
                  <img class="header-placeholder" src="../../../assets/images/header-preview.png" *cdkDragPlaceholder />
                  <img *ngIf="item == '1'" class="item-img-header" src="../../../assets/images/header.png" />
                  <img *ngIf="item == '5'" class="item-img-header" src="../../../assets/images/header.png" />
                </div>
              </div>
              <div cdkDropList #headerItem="cdkDropList" id="itemsHeader2" [cdkDropListData]="itemsHeader2"
                [cdkDropListConnectedTo]="[headerList]" class="item-list-body-col" cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event)">
                <div class="item-box-header" *ngFor="let item of itemsHeader2" cdkDrag>
                  <img class="header-preview" src="../../../assets/images/header-preview.png" *cdkDragPreview />
                  <img class="header-placeholder" src="../../../assets/images/header-preview.png" *cdkDragPlaceholder />
                  <img *ngIf="item == '2'" class="item-img-header" src="../../../assets/images/header.png" />
                  <img *ngIf="item == '4'" class="item-img-header" src="../../../assets/images/header.png" />
                </div>
              </div>
            </div>
            <div class="item-list-body">
              <label class="item-list-label">LAYOUT</label>
              <div cdkDropList #layoutItem="cdkDropList" id="itemsLayout" [cdkDropListData]="itemsLayout"
                [cdkDropListConnectedTo]="[bodyList]" class="item-list-body-col" cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event)">
                <div class="item-box-layout" *ngFor="let item of itemsLayout" cdkDrag>
                  <div *ngIf="item.LayoutID == '1'">
                    <img class="item-img-layout" src="../../../assets/images/layout_100.png" />
                    <img class="layout_100-preview" src="../../../assets/images/layout_100-preview.png"
                      *cdkDragPreview />
                    <img class="layout_100-placeholder" src="../../../assets/images/layout_100-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item.LayoutID == '3'">
                    <img class="item-img-layout" src="../../../assets/images/layout_70_30.png" />
                    <img class="layout_70_30-preview" src="../../../assets/images/layout_70_30-preview.png"
                      *cdkDragPreview />
                    <img class="layout_70_30-placeholder" src="../../../assets/images/layout_70_30-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item.LayoutID == '5'">
                    <img class="item-img-layout" src="../../../assets/images/layout_70_30.png" />
                    <img class="layout_70_30-preview" src="../../../assets/images/layout_70_30-preview.png"
                      *cdkDragPreview />
                    <img class="layout_70_30-placeholder" src="../../../assets/images/layout_70_30-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                </div>
              </div>
              <div cdkDropList #layoutItem="cdkDropList" id="itemsLayout2" [cdkDropListData]="itemsLayout2"
                [cdkDropListConnectedTo]="[bodyList]" class="item-list-body-col" cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event)">
                <div class="item-box-layout" *ngFor="let item of itemsLayout2" cdkDrag>
                  <div *ngIf="item.LayoutID == '2'">
                    <img class="item-img-layout" src="../../../assets/images/layout_80_20.png" />
                    <img class="layout_80_20-preview" src="../../../assets/images/layout_80_20-preview.png"
                      *cdkDragPreview />
                    <img class="layout_80_20-placeholder" src="../../../assets/images/layout_80_20-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <!-- Layout 50 25 25 -->
                  <div *ngIf="item.LayoutID == '4'">
                    <img class="item-img-layout" src="../../../assets/images/layout_100.png" />
                    <img class="layout_100-preview" src="../../../assets/images/layout_100-preview.png"
                      *cdkDragPreview />
                    <img class="layout_100-placeholder" src="../../../assets/images/layout_100-preview.png"
                      *cdkDragPlaceholder />
                  </div>

                  <!-- Layout 75 25 -->
                  <div *ngIf="item.LayoutID == '6'">
                    <img class="item-img-layout" src="../../../assets/images/layout_100.png" />
                    <img class="layout_100-preview" src="../../../assets/images/layout_100-preview.png"
                      *cdkDragPreview />
                    <img class="layout_100-placeholder" src="../../../assets/images/layout_100-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                </div>
              </div>
            </div>
            <div class="item-list-body">
              <label class="item-list-label">BODY</label>
              <div cdkDropList #bodyItem="cdkDropList" id="itemsBody" [cdkDropListData]="itemsBody"
                [cdkDropListConnectedTo]="getConnectedList()" class="item-list-body-col" cdkDropListSortingDisabled
                [cdkDropListEnterPredicate]="noReturnPredicate" (cdkDropListDropped)="drop($event)">
                <div class="item-box-body" *ngFor="let item of itemsBody" cdkDrag>
                  <div *ngIf="item == '1'">
                    <img class="item-img-body" src="../../../assets/images/template-1.png" />
                    <img class="template-1-preview" src="../../../assets/images/template-1-preview.png"
                      *cdkDragPreview />
                    <img class="template-1-placeholder" src="../../../assets/images/template-1-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item == '3'">
                    <img class="item-img-body" src="../../../assets/images/template-3.png" />
                    <img class="template-3-preview" src="../../../assets/images/template-3-preview.png"
                      *cdkDragPreview />
                    <img class="template-3-placeholder" src="../../../assets/images/template-3-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <img *ngIf="item == '-2'" class="item-img-body" src="../../../assets/images/template-7.png" />
                  <div *ngIf="item == '9'">
                    <img class="item-img-body" src="../../../assets/images/template-9.png" />
                    <img class="template-9-preview" src="../../../assets/images/template-9-preview.png"
                      *cdkDragPreview />
                    <img class="template-9-placeholder" src="../../../assets/images/template-9-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item == '-1'">
                    <img class="item-img-body" src="../../../assets/images/banner-2.png" />
                    <img class="banner-2-preview" src="../../../assets/images/banner-2-preview.png" *cdkDragPreview />
                    <img class="banner-2-placeholder" src="../../../assets/images/banner-2-preview.png"
                      *cdkDragPlaceholder />
                  </div>

                  <img *ngIf="item == '13'" class="item-img-body" src="../../../assets/images/template-13.png" />
                  <div *ngIf="item == '15'">Chi tiết bài</div>
                  <img *ngIf="item == '15'" class="item-img-body" src="../../../assets/images/template-15.png" />
                  <img *ngIf="item == '17'" class="item-img-body" src="../../../assets/images/template-17.png" />
                  <img *ngIf="item == '19'" class="item-img-body" src="../../../assets/images/template-19.png" />
                  <img *ngIf="item == '21'" class="item-img-body" src="../../../assets/images/banner-1.png" />
                  <div *ngIf="item == '23'">Sơ đồ tổ chức</div>
                  <img *ngIf="item == '23'" class="item-img-body" src="../../../assets/images/banner-1.png" />
                  <img *ngIf="item == NEWS_19" class="item-img-body" src="../../../assets/images/template-2.png" />
                  <img *ngIf="item == NEWS_21" class="item-img-body" src="../../../assets/images/banner-1.png" />
                  <img *ngIf="item == NEWS_23" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_25">4 banner ngang</div>
                  <img *ngIf="item == NEWS_25" class="item-img-body" src="../../../assets/images/template-18.png" />
                  <div *ngIf="item == NEWS_27">n tin bài hot</div>
                  <img *ngIf="item == NEWS_27" class="item-img-body" src="../../../assets/images/template-4.png" />
                  <div *ngIf="item == NEWS_29">n tin bài mới nhất của tất cả tin(dọc)</div>
                  <img *ngIf="item == NEWS_29" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_31">Thư viện phim</div>
                  <img *ngIf="item == NEWS_31" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <img *ngIf="item == NEWS_33" class="item-img-body" src="../../../assets/images/template-8.png" />
                  <div *ngIf="item == NEWS_35">Tổng quan thư viện</div>
                  <img *ngIf="item == NEWS_35" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_37">Liên kết mạng xã hội</div>
                  <img *ngIf="item == NEWS_37" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_39">Chuyên mục xem nhiều</div>
                  <img *ngIf="item == NEWS_39" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_41">Thông tin tiền nước</div>
                  <img *ngIf="item == NEWS_41" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_43">Thư viện radio</div>
                  <img *ngIf="item == NEWS_43" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_45">Slider banner</div>
                  <img *ngIf="item == NEWS_45" class="item-img-body" src="../../../assets/images/banner-1.png" />
                  <div *ngIf="item == NEWS_47">Chuyên đề truyền thông</div>
                  <img *ngIf="item == NEWS_47" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_49">Đơn vị thành viên</div>
                  <img *ngIf="item == NEWS_49" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_51">Chuyên gia</div>
                  <img *ngIf="item == NEWS_51" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_53">Đối tác</div>
                  <img *ngIf="item == NEWS_53" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_55">Trang Chuyên đề truyền thông</div>
                  <img *ngIf="item == NEWS_55" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_57">Chi tiết Chương trình dự án</div>
                  <img *ngIf="item == NEWS_57" class="item-img-body" src="../../../assets/images/template-1.png" />
                </div>
              </div>
              <div cdkDropList #bodyItem="cdkDropList" id="itemsBody2" [cdkDropListData]="itemsBody2"
                [cdkDropListConnectedTo]="getConnectedList()" class="item-list-body-col" cdkDropListSortingDisabled
                [cdkDropListEnterPredicate]="noReturnPredicate" (cdkDropListDropped)="drop($event)">
                <div class="item-box-body" *ngFor="let item of itemsBody2" cdkDrag>
                  <div *ngIf="item == '2'">
                    <img class="item-img-body" src="../../../assets/images/template-2.png" />
                    <img class="template-2-preview" src="../../../assets/images/template-2-preview.png"
                      *cdkDragPreview />
                    <img class="template-2-placeholder" src="../../../assets/images/template-2-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item == '4'">
                    <img class="item-img-body" src="../../../assets/images/template-4.png" />
                    <img class="template-4-preview" src="../../../assets/images/template-4-preview.png"
                      *cdkDragPreview />
                    <img class="template-4-placeholder" src="../../../assets/images/template-4-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item == '6'">
                    <img class="item-img-body" src="../../../assets/images/template-6.png" />
                    <img class="template-6-preview" src="../../../assets/images/template-6-preview.png"
                      *cdkDragPreview />
                    <img class="template-6-placeholder" src="../../../assets/images/template-6-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item == '8'">
                    <img class="item-img-body" src="../../../assets/images/template-8.png" />
                    <img class="template-8-preview" src="../../../assets/images/template-8-preview.png"
                      *cdkDragPreview />
                    <img class="template-8-placeholder" src="../../../assets/images/template-8-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <div *ngIf="item == '0'">
                    <img class="item-img-body" src="../../../assets/images/banner-1.png" />
                    <img class="banner-1-preview" src="../../../assets/images/banner-1-preview.png" *cdkDragPreview />
                    <img class="banner-1-placeholder" src="../../../assets/images/banner-1-preview.png"
                      *cdkDragPlaceholder />
                  </div>
                  <img *ngIf="item == '14'" class="item-img-body" src="../../../assets/images/template-14.png" />
                  <img *ngIf="item == '16'" class="item-img-body" src="../../../assets/images/template-16.png" />
                  <img *ngIf="item == '18'" class="item-img-body" src="../../../assets/images/template-18.png" />
                  <!-- <img *ngIf="item == '20'" class="item-img-body" src="../../../assets/images/template-2.png" /> -->
                  <!-- <img *ngIf="item == '24'" class="item-img-body" src="../../../assets/images/template-2.png" /> -->
                  <img *ngIf="item == NEWS_20" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <img *ngIf="item == NEWS_22" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <img *ngIf="item == NEWS_24" class="item-img-body" src="../../../assets/images/template-14.png" />
                  <!-- <img *ngIf="item == NEWS_26" class="item-img-body" src="../../../assets/images/banner-1.png" />  -->
                  <div *ngIf="item == NEWS_28">Tin xem nhiều</div>
                  <img *ngIf="item == NEWS_28" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_30">Tin bài (1-1)/3</div>
                  <img *ngIf="item == NEWS_30" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_32">Dịch vụ</div>
                  <img *ngIf="item == NEWS_32" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_34">Tin nhiều chuyên mục</div>
                  <img *ngIf="item == NEWS_34" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_36">Tin mới 1-n</div>
                  <img *ngIf="item == NEWS_36" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_38">Thư viện hình ảnh</div>
                  <img *ngIf="item == NEWS_38" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_40">Chăm sóc khách hàng</div>
                  <img *ngIf="item == NEWS_40" class="item-img-body" src="../../../assets/images/template-6.png" />
                  <div *ngIf="item == NEWS_42">Liên hệ có thông tin</div>
                  <img *ngIf="item == NEWS_42" class="item-img-body" src="../../../assets/images/template-4.png" />
                  <div *ngIf="item == NEWS_44">Trở về đầu trang</div>
                  <img *ngIf="item == NEWS_44" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_46">Tin tức gồm các tin con</div>
                  <img *ngIf="item == NEWS_46" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_48">Giới thiệu ở trang chủ</div>
                  <img *ngIf="item == NEWS_48" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_50">Chương trình - dự án</div>
                  <img *ngIf="item == NEWS_50" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_52">Trang chuyên gia</div>
                  <img *ngIf="item == NEWS_52" class="item-img-body" src="../../../assets/images/template-7.png" />
                  <div *ngIf="item == NEWS_54">Email và SĐT</div>
                  <img *ngIf="item == NEWS_54" class="item-img-body" src="../../../assets/images/template-1.png" />
                  <div *ngIf="item == NEWS_56">Chi tiết CĐ truyền thông</div>
                  <img *ngIf="item == NEWS_56" class="item-img-body" src="../../../assets/images/template-1.png" />
                  
                </div>
              </div>
            </div>
            <div class="item-list-body">
              <label class="item-list-label">FOOTER</label>
              <div cdkDropList #footerItem="cdkDropList" id="itemsFooter" [cdkDropListData]="itemsFooter"
                [cdkDropListConnectedTo]="[footerList]" class="item-list-body-col" cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event)">
                <div class="item-box-footer" *ngFor="let item of itemsFooter" cdkDrag>
                  <!-- <img *ngIf="item == '1'" class="item-img-footer" src="../../../assets/images/footer-1.png"/> -->
                  <img *ngIf="item == '2'" class="item-img-footer" src="../../../assets/images/footer.png" />
                  <img *ngIf="item == '3'" class="item-img-footer" src="../../../assets/images/footer.png" />
                  <img class="footer-preview" src="../../../assets/images/footer-preview.png" *cdkDragPreview />
                  <img class="footer-placeholder" src="../../../assets/images/footer-preview.png" *cdkDragPlaceholder />
                </div>
              </div>
              <div cdkDropList #footerItem="cdkDropList" id="itemsFooter" [cdkDropListData]="itemsFooter2"
                [cdkDropListConnectedTo]="[footerList]" class="item-list-body-col" cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event)">
                <div class="item-box-footer" *ngFor="let item of itemsFooter2" cdkDrag>
                  <!-- <img *ngIf="item == '1'" class="item-img-footer" src="../../../assets/images/footer-1.png"/> -->
                  <img *ngIf="item == '2'" class="item-img-footer" src="../../../assets/images/footer.png" />
                  <img class="footer-preview" src="../../../assets/images/footer-preview.png" *cdkDragPreview />
                  <img class="footer-placeholder" src="../../../assets/images/footer-preview.png" *cdkDragPlaceholder />
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab class="officetabstrip" [ngClass]="this.tabID == 1 ? '' : 'active'" class="active"
          [selected]="this.tabID == 1" title="Trang">
          <ng-template kendoTabContent>
            <app-data-menu [navMenu]="headerNavMenu" (closeDialogAddExcel)="closeDialogAddExcel()"
              (onCheckChange)="onCheckMenuChange($event)"></app-data-menu>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab class="officetabstrip" [ngClass]="this.tabID == 2 ? '' : 'active'" class="active"
        [selected]="this.tabID == 2" title="Font chữ">
        <ng-template kendoTabContent>
          <div class="row">
            <div class="col-md-12 col-12">
              <label class="field-name">
                <span [textContent]="'Chọn font chữ'"></span>
              </label>
              <div [ngClass]="'control control-search'">
                <div class="search-place dropdown">
                  <kendo-combobox [data]="fontListFilter" [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true"
                    [filterable]="true" [(ngModel)]="selectedFont"
                    (filterChange)="fontHandleFilter($event)" (valueChange)="onValueChange()">
                    <ng-template kendoDropDownListNoDataTemplate>{{
                      "NoRecordsAvailable" | translate
                      }}</ng-template>
                  </kendo-combobox>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>
  <kendo-dialog *ngIf="dltFlg" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content dialog-info window-center">
    <div class="title-info-req">
      <strong>{{ "Gallery_Scrollview_Delete" | translate }}</strong>
    </div>
    <div>
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"></span>
      </div>
      <div>
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</section>