<div class="description mb-4">
  <h6>{{ "Forgot_Pass" | translate }}</h6>
</div>
<div [ngClass]="{'mb-3': true, 'login-failed-error': !resetFailed.isHide}">
  <div *ngIf="!resetFailed.isHide">
    {{ resetFailed.message }}
  </div>
</div>
<div class="sub-item">
  <div class="mb-2">
    <div
      class="text-input"
      [ngClass]="{'invalid': invalidField.Password, 'focus': focus===0}"
    >
      <label class="input">
        <input
          class="input__field"
          [type]="type.inputPassword"
          [(ngModel)]="resetPasswordInfo.Password"
          autocomplete="new-password"
          id="Password"
          (focus)="onFocus(0)" (focusout)="onFocus(-1)"
          (keypress)="onKeyPress($event)"
          placeholder=" "
        />
        <span class="input__label">{{ "New_Password" | translate }}</span>
      </label>
      <div class="action">
        <button (click)="onShowPass(0)" class="btn-showpass">
          <i
            class="fa fa-eye-slash"
            aria-hidden="true"
            *ngIf="type.inputPassword == 'password'"
          ></i>
          <i
            class="fa fa-eye"
            aria-hidden="true"
            *ngIf="type.inputPassword == 'text'"
          ></i>
        </button>
      </div>
    </div>
    <div class="error-text">
      {{ invalidField.Password ? ("New_Password_Required" | translate) : ""}}
    </div>
  </div>
  <div class="mb-2">
    <div
      class="text-input"
      [ngClass]="{'invalid': invalidField.CfPassword, 'focus': focus===1}"
    >
      <label class="input">
        <input
          class="input__field"
          [type]="type.inputCfPassword"
          [(ngModel)]="resetPasswordInfo.CfPassword"
          autocomplete="new-password"
          id="CfPassword"
          (focus)="onFocus(1)" (focusout)="onFocus(-1)"
          (keypress)="onKeyPress($event)"
          placeholder=" "
        />
        <span class="input__label">{{ "Confirm_New_Password" | translate }}</span>
      </label>
      <div class="action">
        <button (click)="onShowPass(1)" class="btn-showpass">
          <i
            class="fa fa-eye-slash"
            aria-hidden="true"
            *ngIf="type.inputCfPassword == 'password'"
          ></i>
          <i
            class="fa fa-eye"
            aria-hidden="true"
            *ngIf="type.inputCfPassword == 'text'"
          ></i>
        </button>
      </div>
    </div>
    <div class="error-text">
      {{ invalidField.CfPassword ? ("Password_Confirm_Required" | translate) : ""}}
    </div>
  </div>
  <div class="mb-2">
    <div
      class="text-input"
      [ngClass]="{'invalid': invalidField.SecureCode, 'focus': focus===2}"
    >
      <label class="input">
        <input
          class="input__field"
          type="text"
          [(ngModel)]="resetPasswordInfo.SecureCode"
          autocomplete="new-password"
          id="SecureCode"
          (focus)="onFocus(2)" (focusout)="onFocus(-1)"
          (keypress)="onKeyPress($event)"
          placeholder=" "
        />
        <span class="input__label">{{ "Secure_Code" | translate }}</span>
      </label>
    </div>
    <div class="error-text">
      {{ invalidField.SecureCode ? ("Secure_Code_Required" | translate) : ""}}
    </div>
  </div>
</div>
<div class="sub-item">
  <button (click)="onSubmit()" class="change-password-button">
    <span *ngIf="!loading">{{ "Change_Password_Button" | translate }}</span>
    <div *ngIf="loading" class="spinner-border spinner-border-sm text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</div>