import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { log } from 'console';
// import { AccessibilityConfig, CarouselLibConfig, Image, ImageEvent, ModalGalleryConfig, ModalGalleryRef, ModalGalleryService, ModalLibConfig } from '@ks89/angular-modal-gallery';
import { Description, DescriptionStrategy, Image, PreviewConfig } from '@ks89/angular-modal-gallery';
@Component({
  selector: 'app-opend-dialog-images',
  templateUrl: './opend-dialog-images.component.html',
  // styleUrls: ['./opend-dialog-images.component.scss']
})
export class OpendDialogImagesComponent implements OnInit {
  slideIndex = 1;
  scrollPercent: number = 0;
  previousIndex: number = 0;
  imgHeight: number;
  innerWidth: any;
  innerHeight: any;
  selectedCategoryId: string;
  @Input() srcURL: any;
  @ViewChild('scrollview') scrollview: any;
  @Input() typeFile: number = 0;
  imageWidth: number;

  imageIndex = 1;
  galleryId = 1;
  autoPlay = true;
  showArrows = true;
  showDots = true;
  imagesRect: Image[] = [
    // new Image(
    //   0,
    //   {
    //     img: 'https://portalapi.sawaco.com.vn//Upload/Post/8fbb9b16-3ba5-4bdc-bd8e-497062b18533/20230615001318926_nganh-cap-nuoc-tphcm-doi-_361686622737.jpg',
    //   },
    //   {
    //     img: 'https://portalapi.sawaco.com.vn//Upload/Post/8fbb9b16-3ba5-4bdc-bd8e-497062b18533/20230615001318926_nganh-cap-nuoc-tphcm-doi-_361686622737.jpg',
    //   }
    // ),
    // new Image(1, 
    //   { img: 'https://stackblitz.com/files/angular-modal-gallery-v8/github/Ks89/stackblitz-angular-modal-gallery-v8/master/src/assets/images/gallery/milan-pegasus-gallery-statue.jpg' }, 
    //   { img: 'https://stackblitz.com/files/angular-modal-gallery-v8/github/Ks89/stackblitz-angular-modal-gallery-v8/master/src/assets/images/gallery/milan-pegasus-gallery-statue.jpg' }
    //   ),
    // new Image(
    //   2,
    //   {
    //     img: 'https://portalapi.sawaco.com.vn//Upload/Post/8fbb9b16-3ba5-4bdc-bd8e-497062b18533/20230615001318926_nganh-cap-nuoc-tphcm-doi-_361686622737.jpg',
    //     description: 'Description 3',
    //     title: 'Third image title',
    //     alt: 'Third image alt',
    //     ariaLabel: 'Third image aria-label'
    //   },
    //   {
    //     img: 'https://portalapi.sawaco.com.vn//Upload/Post/8fbb9b16-3ba5-4bdc-bd8e-497062b18533/20230615001318926_nganh-cap-nuoc-tphcm-doi-_361686622737.jpg',
    //     description: 'Description 3'
    //   }
    // ),
    // new Image(
    //   3,
    //   {
    //     img: '/assets/images/gallery/pexels-photo-66943.jpeg',
    //     description: 'Description 4',
    //     title: 'Fourth image title (modal obj)',
    //     alt: 'Fourth image alt (modal obj)',
    //     ariaLabel: 'Fourth image aria-label (modal obj)'
    //   },
    //   {
    //     img: '/assets/images/gallery/thumbs/t-pexels-photo-66943.jpg',
    //     title: 'Fourth image title (plain obj)',
    //     alt: 'Fourth image alt (plain obj)',
    //     ariaLabel: 'Fourth image aria-label (plain obj)'
    //   }
    // ),
    // new Image(4, { img: '/assets/images/gallery/pexels-photo-93750.jpeg' }, { img: '/assets/images/gallery/thumbs/t-pexels-photo-93750.jpg' }),
    // new Image(
    //   5,
    //   {
    //     img: '/assets/images/gallery/pexels-photo-94420.jpeg',
    //     description: 'Description 6'
    //   },
    //   { img: '/assets/images/gallery/thumbs/t-pexels-photo-94420.jpg' }
    // ),
    // new Image(6, { img: '/assets/images/gallery/pexels-photo-96947.jpeg' }, { img: '/assets/images/gallery/thumbs/t-pexels-photo-96947.jpg' })
  ];
  previewConfigFiveImages: PreviewConfig = {
    visible: true,
    number: 6
  };
  customFullDescription: Description = {
    // ALWAYS_VISIBLE is mandatory to use customFullDescription
    strategy: DescriptionStrategy.ALWAYS_VISIBLE,
    imageText: '',
    customFullDescription: '',
    beforeTextDescription: '',
    style: {
      textColor: 'rgba(255,255,255)',
      marginTop: '3px',
      marginBottom: '0px',
      marginLeft: '5px',
      marginRight: '5px',
      position: 'absolute',
      bottom: '0px',
      height: 'fit-content',
    }
  };
  constructor(
    private appService: AppService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    let img = document.getElementById("BannerBackGround");
    
      this.imgHeight = img.clientHeight;

    setTimeout(() => {
      this.showSlides();
      setTimeout(() => {
        let imageContainer = document.getElementById("imageContainer").offsetWidth;
        if(imageContainer != null){
          console.log("imageContainer", imageContainer);
          
          this.imageWidth = (imageContainer / 5);
        }
      }, 100);
    }, 100);
    this.imagesRect= [];
    if(this.srcURL != null){
      for(let i = 0; i < this.srcURL.length; i++){
        this.imagesRect.push(
          new Image(
            i, 
            {
              img: this.appService.apiRoot + (this.srcURL[i].Url != undefined ? this.srcURL[i].Url : this.srcURL[i]),
              description: this.srcURL[i].Title
            }, 
            {
              img: this.appService.apiRoot + (this.srcURL[i].Url != undefined ? this.srcURL[i].Url : this.srcURL[i]),
              description: this.srcURL[i].Title
            }
          )
        )
      }
    }
    
    // this.imagesRect =  [
    //   new Image(
    //     0,
    //     {
    //       img: 'https://portalapi.sawaco.com.vn//Upload/Post/8fbb9b16-3ba5-4bdc-bd8e-497062b18533/20230615001318926_nganh-cap-nuoc-tphcm-doi-_361686622737.jpg',
    //     },
    //     {
    //       img: 'https://portalapi.sawaco.com.vn//Upload/Post/8fbb9b16-3ba5-4bdc-bd8e-497062b18533/20230615001318926_nganh-cap-nuoc-tphcm-doi-_361686622737.jpg',
    //     }
    //   ),
    //   new Image(1, 
    //     { img: 'https://stackblitz.com/files/angular-modal-gallery-v8/github/Ks89/stackblitz-angular-modal-gallery-v8/master/src/assets/images/gallery/milan-pegasus-gallery-statue.jpg' }, 
    //     { img: 'https://stackblitz.com/files/angular-modal-gallery-v8/github/Ks89/stackblitz-angular-modal-gallery-v8/master/src/assets/images/gallery/milan-pegasus-gallery-statue.jpg' }
    //     ),
    //   ]

    // if(this.categoriesListFiltered){
    //   await this.getTopNews(this.categoriesListFiltered[0].ID);
    // }
  }



  getFullUrl(url: string) {
    return this.appService.apiRoot + url;
  }

  showSlides() {
    const slides = document.getElementsByClassName("mySlides");
    const dots = document.getElementsByClassName("demo");
    if (slides.length > 0 && dots.length > 0) {
      for (let i = 0; i < slides.length; i++) {
        (slides[i] as HTMLElement).style.display = "none";
      }

      if (this.slideIndex > slides.length) {
        this.slideIndex = 1;
      } else if (this.slideIndex < 1) {
        this.slideIndex = slides.length;
      }

      for (let i = 0; i < dots.length; i++) {
        (dots[i] as HTMLElement).classList.remove("active");
      }

      console.log('slides', slides);
      console.log('dots', dots);
      console.log('slideIndex', this.slideIndex);

      (slides[this.slideIndex - 1] as HTMLElement).style.display = "flex";
      (slides[this.slideIndex - 1] as HTMLElement).style.height = "-webkit-fill-available";
      (dots[this.slideIndex - 1] as HTMLElement).classList.add("active");
    }
  }



  plusSlides(n) {
    if (n === 1) {
      this.rightClick()
    }
    else {
      this.leftClick()
    }
    this.slideIndex += n;
    this.showSlides();
    this.onSelectedCategory(n);
  }

  currentSlide(n) {
    this.slideIndex = n + 1;
    this.showSlides();
    this.onSelectedCategory(n);
  }
  async onSelectedCategory(index) {
    //set color
    let categoryId = "category" + index;
    let previousCategoryId = "category" + this.previousIndex;
    if (this.previousIndex != -1 && document.getElementById(previousCategoryId) != null) {
      document.getElementById(previousCategoryId).style.color = "#3E3E3E";
    }

    this.previousIndex = index;
    let item = document.getElementById(categoryId);
    if (item) {
      item.style.color = "#D42929";
    }
    //get category id
    // this.selectedCategoryId = this.categoriesListFiltered[index].ID;
    // await this.getTopNews(this.selectedCategoryId);
  }
  // leftClick() {
  //   let category = document.getElementById("category");
  //   let titleRight = document.getElementById("title-right");
  //   const offsetWidth = category.offsetWidth;
  //   const scrollLimit = titleRight.scrollWidth;
  //   console.log('scrollLimit', scrollLimit);
  //   console.log('offsetWidth', offsetWidth);
  //   console.log('category.style.transform', category.style.transform);
  //   if (this.innerWidth < 600) {
  //     titleRight.style.width = (this.innerWidth - 10) + "px";
  //   } else {
  //     // titleRight.style.width = "50%";
  //     titleRight.style.width = "";
  //   }

  //   if (category != null && this.scrollPercent != 0) {
  //     this.scrollPercent = this.scrollPercent - 50;
  //     let translate = "translateX(" + this.scrollPercent.toString() + "%)";
  //     category.style.transform = translate;

  //   }
  // }
  leftClick() {
    const category = document.getElementById("category");
    const titleRight = document.getElementById("title-right");
    const offsetWidth = category.offsetWidth;
    const scrollLimit = titleRight.scrollWidth;
    console.log("category", category, offsetWidth, scrollLimit);
    if (this.slideIndex > 1) {
      // Kiểm tra nếu đã đi đến cuối
      // if (this.innerWidth < 600) {
      //   titleRight.style.width = (this.innerWidth - 10) + "px";
      // } else {
      //   titleRight.style.width = "";
      // }
      if (category !== null && offsetWidth !== scrollLimit) {
        this.scrollPercent = this.scrollPercent + 50;
        const translate = "translateX(" + this.scrollPercent.toString() + "%)";
        console.log("translate", translate);

        category.style.transform = translate;
      }
    }

  }
  rightClick() {
    const category = document.getElementById("category");
    const titleRight = document.getElementById("title-right");
    // Kiểm tra nếu đã đi đến cuối
    // console.log('category.offsetWidth', category.offsetWidth);
    // console.log('category.scrollLeft', category.scrollLeft);
    // console.log('this.innerWidth', this.innerWidth);

    // if (this.innerWidth < 600) {
    //   titleRight.style.width = (this.innerWidth - 10) + "px";
    // } else {
    //   titleRight.style.width = "";
    // }

    const offsetWidth = category.offsetWidth;
    const scrollLimit = titleRight.scrollWidth;
    console.log("category", category, offsetWidth, scrollLimit);
    

    console.log('827', scrollLimit);
    if (category !== null && offsetWidth !== scrollLimit) {
      this.scrollPercent = this.scrollPercent - 50;
      const translate = "translateX(" + this.scrollPercent.toString() + "%)";
      category.style.transform = translate;
      console.log("translate", translate);
      
      // Kiểm tra nếu scrollLimit bằng 827
      if (scrollLimit == 827) {
        // Đặt lại vị trí ban đầu
        this.scrollPercent = 0;
        category.style.transform = "translateX(0%)";
      }
    }


  }
  getVideoUrl(FileUrls) {
    console.log("FileUrls", FileUrls);
    
    let video = "";
    let videos = FileUrls;
    if (videos && videos.length !== 0) {
      video = videos[0];
      let videoUrl = '<figure style=" height: 100%;"><iframe src="https://www.youtube.com/embed/' + video + '" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></figure>';
      return videoUrl;
    }
    return this.appService.apiRoot + video;

  }
}
