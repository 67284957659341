<section class="router-outlet-content field grid-row grid-row--center">
  <div class="container grid-row grid-row--center">
    <!-- Lưu trú -->
    <div
      class="grid-column span-half pt3 pb3 mobile-m reveal-on-scroll is-revealing"
    >
      <div class="relative">
        <img
          class="info-image relative z2"
          src="../../../assets/images/Luutru.png"
          alt="Lưu trú"
        />
        <div class="pattern pattern--left-down absolute z1"></div>
      </div>
    </div>
    <div class="grid-column span-half pt3 pb3 mobile-m">
      <h3>Lưu trú</h3>
      <p class="letter">
        Saigontourist Group hiện đang sở hữu và quản lý hơn 50 khách sạn và khu
        nghỉ dưỡng trải dài trên ba miền Bắc – Trung – Nam, với trên 8000 phòng
        ngủ. Nhiều khách sạn và khu nghỉ dưỡng như là các nhân chứng lịch sử gắn
        liền với nhiều câu chuyện độc đáo, các cột mốc lịch sử cũng như quá
        trình phát triển kinh tế – xã hội của đất nước như khách sạn
        Continental, Majestic, Grand, Rex, Caravell Saigon, New World, Sài Gòn -
        Morin Huế, …. Các khách sạn, khu nghỉ dưỡng của Saigontourist Group tại
        các địa phương gắn liền với "Sài Gòn": Sài Gòn - Phú Quốc, Sài Gòn - Hạ
        Long, Sài Gòn - Mũi Né, Sài Gòn - Ban Mê, Sài Gòn - Bản Giốc, Sài Gòn -
        Côn Đảo,... Các đơn vị đều áp dụng Bộ Quy trình Quản lý 5 sao tiêu chuẩn
        Saigontourist Group.
      </p>
    </div>
    <!-- Lữ hành -->
    <div class="grid-column span-half pt3 pb3 mobile-m">
      <h3>Lữ hành</h3>
      <p class="letter">
        Công ty TNHH MTV Dịch vụ Lữ hành Saigontourist (gọi tắt Lữ hành
        Saigontourist) là một đơn vị thành viên trong hệ thống Saigontourist
        Group, hiện là đơn vị lữ hành hàng đầu và duy nhất tại Việt Nam kinh
        doanh hiệu quả trên cả 3 lĩnh vực du lịch quốc tế, du lịch nước ngoài,
        du lịch trong nước. Lữ hành Saigontourist có trụ sở chính tại TP.HCM và
        18 chi nhánh trải dài khắp Việt Nam. Vị thế, uy tín thương hiệu của Lữ
        hành Saigontourist tại thị trường du lịch Việt Nam đã được vinh danh
        bằng nhiều giải thưởng, danh hiệu cao quý từ Bộ Văn hóa - Thể thao & Du
        lịch, Tổng cục Du lịch, Hiệp hội du lịch Việt Nam và nhiều tổ chức du
        lịch quốc tế uy tín khác.
      </p>
    </div>
    <div
      class="grid-column span-half pt3 pb3 mobile-m reveal-on-scroll is-revealing"
    >
      <div class="relative">
        <div class="pattern pattern--right-middle absolute z1"></div>
        <img
          class="info-image relative z2"
          src="../../../assets/images/Luhanh.png"
          alt="Lữ hành"
        />
      </div>
    </div>
    <!-- Ẩn thực -->
    <div
      class="grid-column span-half pt3 pb3 mobile-m reveal-on-scroll is-revealing"
    >
      <div class="relative">
        <img
          class="info-image relative z2"
          src="../../../assets/images/Amthuc.png"
          alt="Ẩm thực"
        />
        <div class="pattern pattern--left-down absolute z1"></div>
      </div>
    </div>
    <div class="grid-column span-half pt3 pb3 mobile-m">
      <h3>Ẩm thực</h3>
      <p class="letter">
        Saigontourist Group hiện quản lý trên 70 nhà hàng thuộc các khách sạn,
        khu nghỉ dưỡng, khu du lịch và một số nhà hàng, trung tâm ẩm thực độc
        lập. Các nhà hàng chuyên cung cấp nhiều loại hình ẩm thực, tiệc, tiệc
        cưới, hội nghị...đa dạng, phong phú, luôn tiên phong tạo ra các xu hướng
        ẩm thực, các sự kiện ẩm thực gắn liền với văn hóa, giải trí độc đáo, như
        Ẩm thực Khẩn Hoang Nam Bộ, Ngày hội quê tôi, Hạt lúa quê tôi tại Làng Du
        lịch Bình Quới, Ẩm thực Đất Phương Nam tại Công viên Văn hóa Đầm Sen...,
        tham gia tổ chức các Lễ hội ẩm thực Việt Nam tại các nước, góp phần
        quảng bá ẩm thực Việt Nam đến với thế giới.
      </p>
    </div>
    <!-- Giải trí -->
    <div class="grid-column span-half pt3 pb3 mobile-m">
      <h3>Giải trí</h3>
      <p class="letter">
        Với những lợi thế sẵn có về nguồn lực cũng như nỗ lực không ngừng trong
        việc giới thiệu tới khách hàng những dịch vụ du lịch, giải trí hiện đại
        hàng đầu, Saigontourist Group đã và đang phát triển nhiều hạng mục dịch
        vụ giải trí bao gồm hệ thống dịch vụ truyền hình cáp SCTV, các khu vui
        chơi giải trí (Công viên văn hóa Đầm Sen, Làng du lịch Bình Quới, Khu du
        lịch Sinh thái…), sân golf Thủ Đức, Trung tâm Hội chợ Triển lãm Sài Gòn
        (SECC) và hệ thống các câu lạc bộ trò chơi điện tử có thưởng.
      </p>
    </div>
    <div
      class="grid-column span-half pt3 pb3 mobile-m reveal-on-scroll is-revealing"
    >
      <div class="relative">
        <div class="pattern pattern--right-middle absolute z1"></div>
        <img
          class="info-image relative z2"
          src="../../../assets/images/Giaitri.png"
          alt="Giải trí"
        />
      </div>
    </div>
    <!-- Đào tạo -->
    <div
      class="grid-column span-half pt3 pb3 mobile-m reveal-on-scroll is-revealing"
    >
      <div class="relative">
        <img
          class="info-image relative z2"
          src="../../../assets/images/Daotao.png"
          alt="Đào tạo"
        />
        <div class="pattern pattern--left-down absolute z1"></div>
      </div>
    </div>
    <div class="grid-column span-half pt3 pb3 mobile-m">
      <h3>Đào tạo</h3>
      <p class="letter">
        Trường Trung cấp Du lịch & Khách sạn Saigontourist (STHC), là cơ sở đào
        tạo nghề du lịch uy tín hàng đầu tại Việt Nam, không chỉ đào tạo cho hệ
        thống Saigontourist Group mà còn cho cả nguồn nhân lực du lịch Tp. HCM
        và cả nước, thông qua các chương trình đào tạo tại Trường, theo nhu cầu
        của các doanh nghiệp, các địa phương... Trường luôn liên kết với các
        khách sạn 4-5 sao trong hệ thống Saigontourist Group và các đối tác nhằm
        tạo điều kiện thuận lợi nhất cho các học viên nâng cao chất lượng thực
        hành tại môi trường thực tế theo tiêu chuẩn ngành. Bên cạnh đó, mở rộng
        quan hệ hợp tác với các đối tác uy tín chuyên ngành đào tạo du lịch hàng
        đầu thế giới.
      </p>
    </div>
  </div>
</section>
