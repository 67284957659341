import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { TranslateService } from '@ngx-translate/core';
import { AppSwal } from 'src/app/services/app.swal';
import { AppConsts } from 'src/app/services/app.consts';
import { AppGuid } from 'src/app/services/app.guid';
import { AppService } from '../services/app.service';
import { FileRestrictions, UploadsModule, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Router } from '@angular/router'
import { Notification } from '../services/app.notification';
import { Console, log } from 'console';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  //styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit, OnChanges  {
  @Input() pageSelectedID = "";
  @Input() ID;
  @Input() templateID: any;
  @Input() info;
  @Input() galleryData: any;
  @Input() isCheckMaxImg: boolean = false;
  @Input() maxImg: number = 0;
  imageHeight: number;
  @Output() onSave = new EventEmitter();
  @Output() addNew = new EventEmitter();
  @Output() deleteAll = new EventEmitter();
  dataFiles = [];
  folderParentage = [];
  infoFileOpened = false;
  currentFolder = {
    ID: this.guid.empty,
    Name: 'Root',
    ParentID: this.guid.empty
  };
  dataGalleryItem: any;
  dataGalleryItemtemp: any;
  galleryList: any;
  loading = false;
  isFolderPopup = false;
  @Input() isImageChosen = false;
  isChoosingImage = false;
  @Output() isGallery = new EventEmitter();
  index = -1;
  @Input() isGoBack = true;

  public NUM_PAGING_SKIP = 0;
  public NUM_PAGING_TAKE = 7;
  dataFileSkip = this.NUM_PAGING_SKIP;
  dataFilePageSize = this.NUM_PAGING_TAKE;
  public dataFileState: State = {
    skip: this.dataFileSkip,
    take: this.dataFileSkip + this.dataFilePageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  isShowMenu = false;
  manageDialog = false;
  imgSizeList: Array<string> = [
    AppConsts.SIZE_COVER,
    AppConsts.SIZE_FILL,
    AppConsts.SIZE_ADJUST
  ];
  dataErr: any;
  selectedImgIndex: number;
  selectedItem: any;
  SIZE_ADJUST = AppConsts.SIZE_ADJUST;
  isAddNew: boolean = true;

  constructor(
    private router: Router,
    private appService: AppService,
    private authenticationService: AuthenticationService,
    private appSwal: AppSwal,
    private guid: AppGuid,
    public appControls: AppControls,
    public appUtils: AppUtils,
    private notification: Notification,
    private translate: TranslateService,
  ) {
    // this.authenticationService.getUser();
    // this.user = this.authenticationService.user;
    // this.getControl();
    // this.getPageName();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isGoBack && changes.isGoBack.currentValue == false) {
      let textChange = changes.isGoBack.currentValue;
      if(textChange == false){
        this.onGoBack();
      }
    }

  }

  ngOnInit() {
    // console.log(this.info);
    this.setDefault();
    this.getFolderData();
    if (this.templateID == 4) {
      if (this.info) {
        this.dataGalleryItemtemp.Info = this.info;
        
        if (this.info[0]) {
          this.info[0].imageHeight = this.info[0].imageHeight ? this.info[0].imageHeight : 200;
          this.imageHeight = this.info[0].imageHeight;
          if(!this.info[0].ImageUrl){
            this.dataGalleryItemtemp.Info = [];
          }
        }
        else {
          this.dataGalleryItemtemp.Info = [];
          this.imageHeight = 200;
        }
      }
      else {
        this.dataGalleryItemtemp.Info = [];
      }
    }
    else {
      this.dataGalleryItemtemp.Info = this.info==null ? [] : this.info;
    }
    this.dataGalleryItemtemp.ID = this.ID ? this.ID.toUpperCase() : null;
    this.dataGalleryItemtemp.TemplateID = this.templateID;
    // this.dataGalleryItemtemp.Info = this.info ? this.info : [];
    this.dataGalleryItemtemp.OrderIdx = this.galleryData?.OrderIdx;
    this.dataGalleryItemtemp.PageID = this.pageSelectedID;
    this.dataGalleryItemtemp.Layout = this.galleryData?.Layout;
    this.dataGalleryItemtemp.GroupID = this.galleryData?.GroupID;
    this.dataGalleryItemtemp.GroupIndex = this.galleryData?.GroupIndex;
    // console.log(this.dataGalleryItemtemp.Info)
    this.galleryList = this.dataGalleryItemtemp;
    if(this.dataGalleryItemtemp.Info.length >= 4)
      this.isAddNew = false;
  }

  // async getPageName() {
  //   this.pageName = await this.appControls.getPageName();
  // }

  // async getControl() {
  //   this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.archives);
  //   this.controlDefault = false;
  // }

  setDefault() {
    this.dataGalleryItem = {
      ID: this.guid.empty,
      TemplateID: '0',
      PageID: this.pageSelectedID,
      // Name: null,
      OrderIdx: null,
      Info: {
        ImageUrl: null,
        Link: null,
        Title: null,
        DetailPath: null,
        Size: null,
        Width: null,
        Height: null
      },
      Width: 100
    };

    this.dataGalleryItemtemp = Object.assign({}, this.dataGalleryItem);
    var errTemp = Object.assign({}, this.dataGalleryItemtemp);
    this.dataErr = [errTemp];
    // this.filesUploadAvatar = "";
    // this.filesUploadName = "";
    // this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=${this.user.UserName}&typeData=files&acceptExtensions=[".png",".jpg",".jpeg",".gif"]`;

  }

  async getFolderData() {
    const dataRequest = {
      archiveItemId: this.currentFolder.ID,
      // userId: 'admin',
      accId: localStorage.getItem("AccountID")
    };
    const result = await this.appService.doGET('api/Archive/SearchAll', dataRequest);
    if (result && result.Status === 1) {
      this.dataFiles = result.Data.Folder;
      result.Data.Files.forEach(element => {
        this.dataFiles.push(element);
      });

      if (result.Data.CurrentFolderID == this.guid.empty) {
        this.currentFolder.ID = this.guid.empty;
        this.currentFolder.Name = 'Kho hình ảnh';
        this.currentFolder.ParentID = this.guid.empty;
      }
      if (this.dataFileSkip == this.dataFiles.length) {
        this.dataFileSkip = 0;
        this.dataFileState.skip = 0;
      }

    } else {
      if (result && result.Msg) {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  async setFolder(folder) {
    if (folder != null && folder != undefined) {
      this.currentFolder.ID = folder.ID;
      this.currentFolder.Name = folder.Name;
      this.currentFolder.ParentID = folder.ParentID;
      if (folder.ID == this.guid.empty) {
        this.folderParentage = [];
      }
      else {
        var index = this.folderParentage.findIndex(x => x.ID == folder.ID);
        if (index == -1) {
          this.folderParentage.push({
            ID: folder.ID,
            Name: folder.Name,
            ParentID: folder.ParentID
          })
        } else {
          var numbToRemove = this.folderParentage.length - index;
          for (let i = 0; i < numbToRemove; i++) {
            this.folderParentage.splice(index + 1, 1);
          }
        }
      }
    }

    this.getFolderData();
  }

  // onReload() {
  //   window.location.reload();
  // }

  createDataRequest(data) {
    const temp = data ? data : this.dataGalleryItemtemp;
    let info = temp.Info.findIndex(e => e.Link == '');
    if (info != -1) {
      temp.Info.splice(temp.Info[info], 1);
    }

    return {
      ID: temp.ID ? temp.ID : this.guid.empty,
      TemplateID: temp.TemplateID ? temp.TemplateID : null,
      PageID: temp.PageID ? temp.PageID : null,
      //Name: temp.Name ? temp.Name : null,
      OrderIdx: temp.OrderIdx ? temp.OrderIdx : null,
      Info: temp.Info ? JSON.stringify(temp.Info) : null,
      Width: temp.Width ? temp.Width : 100,
      GroupID: temp.GroupID ? temp.GroupID : 0,
      GroupIndex: temp.GroupIndex ? temp.GroupIndex : 0,
      Layout: temp.Layout ? temp.Layout : 100,
      Link:temp.lin
    };
  }

  getImage(item) {
  
    
    
    if (this.index == -1) {
      let chosenImage = {
        Title: item.Name,
        Link: item.Link,
        ImageUrl: item.Path
      };
      
      if (this.dataGalleryItemtemp.Info.length == 0) {
        this.addNew.emit(true);
      }
      if (this.dataGalleryItemtemp.Info.length != 0 && this.templateID == 4) {
        this.dataGalleryItemtemp.Info[0].Title = item.Name;
        this.dataGalleryItemtemp.Info[0].Link = item.Link;
        this.dataGalleryItemtemp.Info[0].ImageUrl = item.Path;
        if(this.dataGalleryItemtemp.Info.length == 4){
          this.isAddNew = false;
        }
      }
      else {
        this.dataGalleryItemtemp.Info.push(chosenImage)
      }
    }
    else {
      
      this.dataGalleryItemtemp.Info[this.index].Title = item.Name;
      this.dataGalleryItemtemp.Info[this.index].Link = item.Link;
      this.dataGalleryItemtemp.Info[this.index].ImageUrl = item.Path;
      
    }
    if (this.dataGalleryItemtemp.Info.length == 4) {
      this.isAddNew = false
    }
    this.isGallery.emit(false);
    this.isChoosingImage = false;
    this.isImageChosen = true;
  }

  chooseImage(item?) {
    this.index = -1;

    


    if (item) {
      this.index = this.dataGalleryItemtemp.Info.findIndex(e => e == item);
    }
    this.isChoosingImage = true;  
    this.isGallery.emit(true);
    this.isImageChosen = false;
console.log("this.dataGalleryItemtemp.Info", this.dataGalleryItemtemp.Info);

  } 

  deleteImg(item) {
    let idx = this.dataGalleryItemtemp.Info.findIndex(e => e == item);
    this.dataGalleryItemtemp.Info.splice(idx, 1);
    if (this.dataGalleryItemtemp.Info.length == 0) {
      this.deleteAll.emit(false);
    }
    this.isImageChosen = true;
    if(this.dataGalleryItemtemp.Info.length < 4)
    this.isAddNew = true;
  }

  goRoot() {
    var tempFolder = {
      ID: this.guid.empty,
      Name: 'Root',
      ParentID: this.guid.empty
    };
    this.setFolder(tempFolder);
  }

  getIcon(path) {
    if (path == undefined || path == null) {
      return '../../assets/images/folder-icon.png'
    } else {
      var extension = path.split('.').pop();
      if (extension.toLowerCase() == 'png' || extension.toLowerCase() == 'jpeg' || extension.toLowerCase() == 'jpg') {
        return `${this.appService.apiRoot}${path}`
      }
    }

  }

  onImgError(event) {
    event.target.src = AppConsts.DEFAULT_IMAGE;
  }

  async editGallery() {
    // this.appComponent.loading = true;
    const temp = this.dataGalleryItemtemp;
    if (!temp.Info) {
      return;
    }
    const dataRequest = this.createDataRequest(null);
    // const result = dataRequest.ID == this.guid.empty ? await this.appService.doPOST('api/Template', dataRequest)
    //   : await this.appService.doPUT('api/Template', dataRequest, { iD });
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    //   // this.onReload();
    //   this.setDefault();

    this.onSave.emit(dataRequest);

    // }
    // this.appComponent.loading = false;
  }

  onSaveBanner() {
    this.isChoosingImage = false;
    this.isGallery.emit(false);
    this.isImageChosen = false;
    if (this.templateID == 4) {
      this.dataGalleryItemtemp.Info[0].imageHeight = this.imageHeight;
    }
    this.editGallery();
    
  }

  onGoBack() {
    this.isGallery.emit(false);
    this.isChoosingImage = false;
  }
  enableChoice(i) {
    this.isShowMenu = !this.isShowMenu;
    let id = "menu" +i;

    let menuId = document.getElementById(id);
    if(menuId.style.display == "block"){
      menuId.style.display = "none";
    } else {
      menuId.style.display = "block";
    }
  }
  openSizeDialog(imgIndex){
    this.manageDialog = true;
    this.selectedImgIndex = imgIndex;
    this.selectedItem = Object.assign({}, this.dataGalleryItemtemp.Info[this.selectedImgIndex]);
    if(!this.selectedItem.DetailPath){
      this.selectedItem.DetailPath = null;
    }
    if(!this.selectedItem.Size){
      this.selectedItem.Size = null;
    }
  }
  onClose(e){
    this.manageDialog = false;
  }
  onSaveImageInfo(){
    if(this.selectedItem.DetailPath !== ''
      && this.selectedItem.DetailPath !== null  
      && this.selectedItem.Size !== ''
      && this.selectedItem.Size !== null)        
    {
      this.dataGalleryItemtemp.Info[this.selectedImgIndex].DetailPath =  this.selectedItem.DetailPath;
      if(this.selectedItem.Size != this.SIZE_ADJUST){
        this.dataGalleryItemtemp.Info[this.selectedImgIndex].Size = this.selectedItem.Size;
      }     
      if(this.selectedItem.Size == this.SIZE_ADJUST){
        if(
          this.selectedItem.Width !== ''
          && this.selectedItem.Width !== undefined
          && this.selectedItem.Width !== null
          && this.selectedItem.Height !== ''
          && this.selectedItem.Height !== undefined
          && this.selectedItem.Height !== null
        )
        {
          this.dataGalleryItemtemp.Info[this.selectedImgIndex].Width =  this.selectedItem.Width;
          this.dataGalleryItemtemp.Info[this.selectedImgIndex].Height = this.selectedItem.Height;
          this.dataGalleryItemtemp.Info[this.selectedImgIndex].Size = this.selectedItem.Size;
        } else {
          return;
        }
      } else {
        this.dataGalleryItemtemp.Info[this.selectedImgIndex].Width =  null;
        this.dataGalleryItemtemp.Info[this.selectedImgIndex].Height = null;
      }
      
      this.manageDialog = false;
      this.isImageChosen = true;
    }
  }
}
