<div class="login-page">
  <div class="panel-login col-xl-4 col-lg-5 col-md-6 col-sm-10 col-10">
    <div class="sub-item">
      <img src="/assets/images/logo-sg-tourist.png" class="logo" alt="logo" />
    </div>
    <div class="form">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
