
import { Component, OnInit, OnDestroy,Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
@Component({
  selector: 'app-horizontal-timeline',
  templateUrl: './horizontal-timeline.component.html',
  // styleUrls: ['./horizontal-timeline.component.css']
})
export class HorizontalTimelineComponent implements OnInit, OnChanges, OnDestroy {
  // name = 'Angular';
  a = false;
  b = false;

  c = false;
  d = false;
  @Input() DocumentStatus: number = 6;
  constructor() { }

  ngOnInit() {
  }
  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  
  // this.onReload();
}

  // getColor(): string{
  //   if(this.d || this.c) {
  //     return 'green';
  //   }
  //   if(this.b || this.a) {
  //     return 'orange';
  //   }
  // }
  
  // custom thanh ngang
  setClass(): any {
    return {
      'timeline': true
    }
    

    }
  

}
