<article class="news-template-48">
  <div class="row">
    <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
      <div
        class="three-dot-area k-icon k-i-more-vertical"
        (click)="enableChoice()"
      ></div>
      <div class="menu" *ngIf="isShowMenu">
        <div class="menu-row-current-border" (click)="editComponent()">
          <div class="menu-item-1">{{ "Edit" | translate }}</div>
        </div>
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{ "Delete" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-12 col-md-6 news-title">
      <p class="uppercase">{{ categoryParent?.Name }}</p>
    </div>
    <div class="col-sm-12 col-md-12 col-xl-12 col-12 content">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-4 col-xl-3" *ngFor="let item of pageList; let i = index">
            <div class="tab-container" (click)="onClickItemTab(item, i)" routerLinkActive="active">
                <img [src]="getImageFullUrl(item.ImageUrl)" alt="" class="item-image">
                <p class="item-name">{{ item.Name }}</p>
            </div>
            </div>
        </div>
    </div>
  </div>
  <kendo-dialog
    *ngIf="manageDialog"
    (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width"
  >
    <div class="reward-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
        }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
        }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close-button.png"
          alt=""
          (click)="onClose($event)"
        />
      </div>
    </div>
    <app-news-template-48-dialog
      *ngIf="editFlg == true"
      [request_AccountID]="request_AccountID"
      [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)"
      [galleryData]="galleryData"
      [editFlg]="editFlg"
      (onSave)="this.onLoadData($event)"
      (onChangetemp2)="this.changeTemp2($event)"
    >
    </app-news-template-48-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span
          [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"
        ></span>
      </div>
      <div>
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
