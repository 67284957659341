import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import { AppConsts } from '../services/app.consts';
import { AppService } from '../services/app.service';
@Component({
  selector: 'app-preview-template-page',
  templateUrl: './preview-template-page.component.html',
  styleUrls: ['./preview-template-page.component.scss'],
})
export class PreviewTemplatePageComponent implements OnInit {
  @Input() itemchild: any;
  menuVl: any;
  menuTittle: any;
  isAdminFlg: boolean = false;
  pagePreview: any;
  pageHeader: any;
  pageBody: any;
  pageFooter: any;
  menuID: any;
  isPreview = true; //hien thi du lieu mau

  categoriesList: Array<any> = [];
  bodyParam : any;
  NEWS_19 = AppConsts.NewsTemplateNumber.NEWS_19;
  NEWS_20 = AppConsts.NewsTemplateNumber.NEWS_20;
  NEWS_21 = AppConsts.NewsTemplateNumber.NEWS_21;
  NEWS_22 = AppConsts.NewsTemplateNumber.NEWS_22;
  NEWS_23 = AppConsts.NewsTemplateNumber.NEWS_23;
  NEWS_24 = AppConsts.NewsTemplateNumber.NEWS_24;
  NEWS_25 = AppConsts.NewsTemplateNumber.NEWS_25;
  NEWS_26 = AppConsts.NewsTemplateNumber.NEWS_26;
  NEWS_27 = AppConsts.NewsTemplateNumber.NEWS_27;
  NEWS_28 = AppConsts.NewsTemplateNumber.NEWS_28;
  NEWS_29 = AppConsts.NewsTemplateNumber.NEWS_29;
  NEWS_30 = AppConsts.NewsTemplateNumber.NEWS_30;
  NEWS_31 = AppConsts.NewsTemplateNumber.NEWS_31;
  NEWS_32 = AppConsts.NewsTemplateNumber.NEWS_32;
  NEWS_33 = AppConsts.NewsTemplateNumber.NEWS_33;
  NEWS_34 = AppConsts.NewsTemplateNumber.NEWS_34;
  NEWS_35 = AppConsts.NewsTemplateNumber.NEWS_35;
  NEWS_36 = AppConsts.NewsTemplateNumber.NEWS_36;
  NEWS_37 = AppConsts.NewsTemplateNumber.NEWS_37;
  NEWS_38 = AppConsts.NewsTemplateNumber.NEWS_38;
  NEWS_39 = AppConsts.NewsTemplateNumber.NEWS_39;
  NEWS_40 = AppConsts.NewsTemplateNumber.NEWS_40;
  NEWS_41 = AppConsts.NewsTemplateNumber.NEWS_41;
  NEWS_42 = AppConsts.NewsTemplateNumber.NEWS_42;
  NEWS_43 = AppConsts.NewsTemplateNumber.NEWS_43;
  NEWS_44 = AppConsts.NewsTemplateNumber.NEWS_44;
  NEWS_45 = AppConsts.NewsTemplateNumber.NEWS_45;
  NEWS_46 = AppConsts.NewsTemplateNumber.NEWS_46;
  NEWS_47 = AppConsts.NewsTemplateNumber.NEWS_47;
  NEWS_48 = AppConsts.NewsTemplateNumber.NEWS_48;
  NEWS_49 = AppConsts.NewsTemplateNumber.NEWS_49;
  NEWS_50 = AppConsts.NewsTemplateNumber.NEWS_50;
  NEWS_51 = AppConsts.NewsTemplateNumber.NEWS_51;
  NEWS_52 = AppConsts.NewsTemplateNumber.NEWS_52;
  NEWS_53 = AppConsts.NewsTemplateNumber.NEWS_53;
  NEWS_54 = AppConsts.NewsTemplateNumber.NEWS_54;
  NEWS_55 = AppConsts.NewsTemplateNumber.NEWS_55;
  NEWS_56 = AppConsts.NewsTemplateNumber.NEWS_56;
  bodyWidth = 1140;
  bodyWidthSetup = 1140;
  innerWidth: any;
  innerHeight: any;
  fontName: string;

  constructor(private title: Title,
     private translate: TranslateService,
    private appService: AppService) {}


  ngOnInit(): void {
    if (typeof window !== 'undefined'){
      this.innerWidth = window.innerWidth;
      console.log("this.innerWidth", this.innerWidth);
      this.getContentWidth();
  
  
  
      this.innerHeight = window.innerHeight;
      this.bodyParam = decodeURIComponent(window.location.search);
      // this.pagePreview = JSON.parse(localStorage.getItem('pagePreview'));
      this.pagePreview = JSON.parse(this.bodyParam.replace('?bodyParam=',''));
      console.log("this.pagePreview", this.pagePreview);
      window.history.replaceState({}, document.title, "/preview-template-page");
      this.title.setTitle(this.translate.instant('Preview template'));
      this.pageHeader = this.pagePreview.pageHeader;
      this.pageBody = this.pagePreview.pageBody;
      this.fontName = this.pagePreview.fontName;
      console.log("this.pageBody", this.pageBody[0]);
  
      this.pageFooter = this.pagePreview.pageFooter;

      this.onSetFont();
    }
  }
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;

    if(this.bodyWidth > window.innerWidth){
      this.bodyWidth = ((window.innerWidth * 80)/100);
    }
    if(window.innerWidth >= this.bodyWidth){
      if(window.innerWidth > this.bodyWidthSetup){
        this.bodyWidth = this.bodyWidthSetup;
      }else {
        this.bodyWidth = ((window.innerWidth * 80)/100);
      }
    }
  }
  async getContentWidth(){
    const result = await this.appService.doGET('api/PortalDesign/GetWidth', {});
    if(result && result.Status === 1 && typeof window !== 'undefined'){
      this.bodyWidthSetup = Number(result.Data[0]);
      this.bodyWidth = this.bodyWidthSetup;
      if(this.bodyWidth > window.innerWidth){
        this.bodyWidth = ((window.innerWidth * 80)/100);
      }
    }
  }
  onActivate(elementRef) {
    if (elementRef.tittle) {
      this.menuTittle = elementRef.tittle;
    } else {
      this.menuTittle = '';
    }
  }
  menuItemchild(event) {
    this.menuID = event.ID;
    this.itemchild = event;
  }
  menuItem(event) {
    this.menuID = event.ID;
    this.menuVl = event;
  }
  onSetFont() {
    const bodyElement = document.body;
    bodyElement.style.fontFamily = '"' + this.fontName + '"';
  }
}
