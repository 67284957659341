import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AppConsts } from '../../services/app.consts';
import { AppLanguage } from '../../services/app.language';
import { AppService } from '../../services/app.service';
import videojs from 'video.js';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-page',
  templateUrl: './post-page.component.html',
  // styleUrls: ['./main-page.component.scss'],
})
export class PostPageComponent implements OnInit {
  domain: string;
  extension: string[] = ['.mp4', '.ogg'];
  player: videojs.Player;
  isBrowser: any;
  Info: any;
  templates: any;
  pageBody = [];
  menuID: any;
  categoriesList: Array<any> = [];
  isAdminFlg: boolean = false;
  @HostListener('window:resize', ['$event'])
  innerWidth: any;
  innerHeight: any;


  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  async ngOnInit(): Promise<void> {
    // this.getBannerRightSide();
    // this.getTopNews();
    this.domain = this.appService.apiRoot;
    // this.postDetail = AppConsts.page.postDetail;
    this.title.setTitle(this.translate.instant('Title-Page'));
    this.getInfo();
    await this.onGetCategories();
    await this.getTemplate();
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  onReload(e) {
    this.getTemplate();
  }

  async getInfo() {
    const result = await this.appService.doGET('api/Portal/GetInfo', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1) {
      this.Info = result.Data;
    }
    this.updateMeta();
  }

  updateMeta(): void {
    var description =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    var keywords =
      'department of external relations, department of external relations ho chi minh city, ministry of foreign affairs, songoaivu, so ngoai vu, ngoai vu, mofahcm, songoaivutphcm, songoaivutphcm.com, Sở Ngoại Vụ TP.HCM, hợp pháp hóa lãnh sự, chứng nhận lãnh sự, hợp pháp hóa giấy tờ, lãnh sự, hợp pháp hóa';
    var url = window.location.origin;
    var title = this.translate.instant('Title-Page');
    var ogDescription =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    var image = '';
    if (this.Info != null) {
      description =
        this.translate.instant('ogDescription') +
        '\n' +
        this.translate.instant('Address') +
        this.Info.Info.Address;
      ogDescription =
        this.translate.instant('ogDescription') +
        '\n' +
        this.translate.instant('Address') +
        this.Info.Info.Address;
      image = this.appService.apiRoot + this.Info.Image[0]?.ImageUrl;
    }
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'og:url', content: url });
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:image', content: image });
    this.meta.updateTag({ name: 'og:description', content: ogDescription });
  }

  onImgError(event) {
    event.target.src = AppConsts.DEFAULT_IMAGE;
  }

  async getTemplate() {
    const result = await this.appService.doGET('api/PortalDesign/GetDesign', {
      menuID: this.menuID
    });
    if (result && result.Status === 1) {
      this.templates = result.Data;
      var bodys = [];
      for (let i = 0; i < this.templates.length; i++) {
        var item = this.templates[i];
        var groupId = item.GroupID;
        var layout = item.Layout;
        let index = bodys.findIndex(x => x.id == groupId && x.layout == layout);
        if (index != -1) {
          bodys[index].items.push(item)
        } else {
          var itemBody = {
            id: groupId,
            layout: layout,
            items: [item]
          }

          // bodys.push(itemBody);
          if (layout < 100 && this.templates.findIndex(x => x.Layout == (100 - layout)) == -1) { // không tồn tại layout nào chung hàng thì tự add thêm
            if (layout < 50) {
              bodys.push({
                id: groupId + 100,
                layout: 100 - layout,
                items: []
              });
              bodys.push(itemBody);

            } else {
              bodys.push(itemBody);
              bodys.push({
                id: groupId + 100,
                layout: 100 - layout,
                items: []
              });

            }

          } else {
            bodys.push(itemBody);
          }
        }
      }
      this.pageBody = bodys;


    } else {
      // show popup error
    }
  }
  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/Search', {
      searchText: '',
      langID: this.language.get()
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data;
    }
  }

  getConnectedList(): any[] {
    return this.pageBody.map(x => `${x.id}`);
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }
}
