import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { Router } from '@angular/router';
import { Console } from 'console';

@Component({
  selector: 'app-news-template-29',
  templateUrl: './news-template-29.component.html',
  // styleUrls: ['./news-template-29.component.scss']
})
export class NewsTemplate29Component implements OnInit {
  topNews: any;
  // topNumber: number = 3;
  bootstrapNum: number = 4;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @HostListener('window:resize', ['$event'])
  innerWidth: any;
  innerHeight: any;
  @Input() OrderIdx: any;
  @Input() isPreview;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate2 = new EventEmitter();
  @Output() saveData = new EventEmitter();
  @Output() tittleItem = new EventEmitter();
  // @Output() urlItem = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  postDetailRoute = AppConsts.POST_DETAIL_ROUTE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  categoriesListFiltered: any;
  categoryParent: any;
  widthScroll = "100%";
  heightScroll = "25px";
  activeIndex = 0;
  scrollPercent: number = 0;
  previousIndex: number = 0;
  selectedCategoryId: string;
  PageSelectedID: any;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    if(typeof this.infoFromMain == 'string'){
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    await this.onGetCategories();
    this.getPostTittle();   
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate ? this.itemTemplate.GroupID : 0,
      "Layout": this.itemTemplate ? this.itemTemplate.Layout : 100,
      "GroupIndex": this.itemTemplate ? this.itemTemplate.GroupIndex : 0
    }
    if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
       
    }
    
    
    if(this.categoriesListFiltered){
      this.categoriesListFiltered[0].Selected = true;
      await this.getTopNews(this.categoriesListFiltered[0].ID);
    }
    //document.getElementById("category0").style.color = "#D42929";

    // console.log(`pageID from template: ${this.pageSelectedID}`);
    // console.log('item template 2: ');
    // console.log(this.itemTemplate);
  }
  async getTopNews(ID?) {
    if(typeof this.infoFromMain == 'string'){
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        // menuID: '038490DF-8F35-4B08-B5F2-942367AE77B3',
        menuID: ID ? ID : this.infoFromMain.MenuID,
        topNum: this.infoFromMain.TopNumber,
      }
    );

    if (result && result.Status === 1) {
      this.postTittle = result.Data.dataMenu.Name;
      this.viewMore = result.Data.dataMenu.UrlPath;
      this.topNews = result.Data.topNews;
    }
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }

  onGetTittleItem(e) {
    this.tittleItem.emit(e);
  }

  // redirectToItem(url: string, item) {
  //   this.onGetTittleItem(item)
  //   if (url != null && url != undefined) {
  //     this.urlItem.emit(item);
  //   }
  // }


  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
    // this.topNews = new Array(3).fill({key: 'value'});
    // this.postTittle = this.translate.instant('Category_Name');
    // const dataRequest = {
    //   iD: this.templateID,
    //   itemID: this.ID,
    //   index: this.OrderIdx,
    // };
    // const result = await this.appService.doDELETE('api/Template', dataRequest);
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    //   this.isReloadPageAfterSave.emit(true);
    //   location.reload();
    // } else {
    //   this.notification.showSuccess(result.Msg);
    // }
  }



  onDeleteGallery() {
    //console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  changeTemp2(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    // this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.onGetCategories();
    this.getTopNews(this.infoFromMain.MenuID);
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate2.emit(newTemp);
  }

  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }

  onSaveData() {
    this.saveData.emit();
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    //reset scroll
    // let category = document.getElementById("category");
    // this.scrollPercent = 0;
    // let translate = "translateX(" + this.scrollPercent.toString() + "%)";
    // category.style.transform = translate;
    // console.log("category.style.transform", category.style.transform);
    
    // let titleRight = document.getElementById("title-right");
    
    // if(this.innerWidth<600){
    //   titleRight.style.width = (this.innerWidth - 10) +"px";
    // } else {
    //   titleRight.style.width = "50%";
    // }
  }
  setH(){
    var a = document.querySelector('.post-list') as HTMLElement;
    if(a.getBoundingClientRect().width <= 808){
      return 'img-figure-2'
    }
    return ;
  }
  // async onGetCategories() {
  //   const result = await this.appService.doGET('api/Menu/SearchMenuPost', {
  //     searchText: '',
  //     langID: this.language.get()
  //   });
  //   if (result && result.Status === 1) {
  //     this.categoriesList = result.Data;
  //     this.categoriesListFiltered = result.Data;
    
      
  //   } 
  // }
  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/SearchMenuByParent', {
      menuID: this.infoFromMain.MenuID
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data?.Menus;
      this.categoriesListFiltered = result.Data?.Menus;
      this.categoryParent = result.Data?.MenuItem;
      
    } 
  }
  // leftClick(){
  //   let category = document.getElementById("category");
  //   let titleRight = document.getElementById("title-right");
    
  //   if(this.innerWidth<600){
  //     titleRight.style.width = (this.innerWidth - 10)+"px";
  //   } else {
  //     titleRight.style.width = "50%";
  //   }
  //   if(category != null && this.scrollPercent != 0){
  //     this.scrollPercent = this.scrollPercent + 50;
  //     let translate = "translateX(" + this.scrollPercent.toString() + "%)";
  //     category.style.transform = translate;
  //   } 
  // }
  // rightClick(){
    
  //   let category = document.getElementById("category");
  //   let titleRight = document.getElementById("title-right");

  //   if(this.innerWidth<600){
  //     titleRight.style.width = (this.innerWidth - 10)+"px";
  //   } else {
  //     titleRight.style.width = "50%";
  //   }
  //   let offsetWidth = category.offsetWidth;
  //   let scrollLimit = titleRight.scrollWidth;   
    
  //   if(category != null && offsetWidth != scrollLimit){
  //     this.scrollPercent = this.scrollPercent - 50;
  //     let translate = "translateX(" + this.scrollPercent.toString() + "%)";
  //     category.style.transform = translate;
  //   }
  // }

  async onSelectedCategory(item?, index?){
    //set color
    let categoryId = "category"+ index;
    let previousCategoryId = "category" + this.previousIndex;
    if(this.previousIndex != -1 && document.getElementById(previousCategoryId) != null){
      document.getElementById(previousCategoryId).style.color = "#3E3E3E";
    }
    this.categoriesListFiltered.forEach((element) => {
      if (element.ID == item.ID) {
        element.Selected = true;
      } else {
        element.Selected = false;
      }
    });
    this.PageSelectedID = item.ID;
    
    this.previousIndex = index;
    // let item = document.getElementById(categoryId);
    // if(item){
    //   this.categoriesListFiltered.Selected = true
    //   item.style.color = "#D42929";
    // }
    //get category id
    this.selectedCategoryId = this.categoriesListFiltered[index].ID;
    await this.getTopNews(this.selectedCategoryId);
  }


  onClickItemPage(item?, index?) {
    this.categoriesListFiltered.forEach((element) => {
      if (element.ID == item.ID) {
        element.Selected = true;
      } else {
        element.Selected = false;
      }
    });
    this.PageSelectedID = item.ID;
  }
}
