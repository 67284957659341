import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DomSanitizer } from '@angular/platform-browser';

//import { Http, Response, Headers, RequestOptions, URLSearchParams, ResponseContentType } from '@angular/http';
import { Injectable, OnInit } from '@angular/core';
//import { resolve, reject } from 'q';
//import 'rxjs/add/operator/map';
//import { HttpHeaders } from '@angular/common/http';
import { AppLanguage } from './app.language';
import { AppSwal } from './app.swal';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppConsts } from './app.consts';
@Injectable({
  providedIn: 'root',
})
export class AppService implements OnInit {
  // apiRoot = 'https://api-saigontourist.mallfinder.vn/';
  // clientRoot = 'http://123.30.158.155:8006/';

  // portal Saigontourist
  // apiRoot = 'https://api-saigontourist.mallfinder.vn/';
  // clientRoot = 'https://admin-saigontourist.mallfinder.vn/';
  // portalRoot = 'https://saigontourist.mallfinder.vn/';

  // portal Sawaco site test
  // apiRoot = 'https://api-sawaco.mallfinder.vn/';
  // clientRoot = 'https://admin-sawaco.mallfinder.vn/';
  // portalRoot = 'https://sawaco.mallfinder.vn/';

   // portal Sawaco site chính
  // apiRoot = 'https://portalapi.sawaco.com.vn/';
  // clientRoot = 'https://portaladmin.sawaco.com.vn/';
  // portalRoot = 'https://sawaco.com.vn/';

  // portal Tây Bắc
  // apiRoot = 'https://api-taybac.mallfinder.vn/';
  // clientRoot = 'https://admin-taybac.mallfinder.vn/';
  // portalRoot = 'https://taybac.mallfinder.vn/';

  // portal Y tế cộng đồng
  apiRoot = 'https://api-ytcc.hcmtelecom.vn/';
  clientRoot = 'https://admin-ytcc.hcmtelecom.vn/';
  portalRoot = 'https://ytcc.hcmtelecom.vn/';

  // portal  test
  // apiRoot = 'https://api-portal-test.hcmtelecom.vn/';
  // clientRoot = 'https://admin-portal-test.hcmtelecom.vn/';
  // portalRoot = 'https://portal-test.hcmtelecom.vn/';

  // portal Nội bộ VTTP
  // apiRoot = 'https://api-portal-vttp.mallfinder.vn/';
  // clientRoot = 'https://admin-portal-vttp.mallfinder.vn/';
  // portalRoot = 'https://portal-vttp.mallfinder.vn/';


  // apiRoot = 'http://localhost:8080/';
  // clientRoot = 'http://localhost:4401/';
  // portalRoot = 'http://localhost:4200/';

  //headers: any;

  options: any;
  timeInterval = 1000 * 30;
  user: any;
  domainAPI = '';
  ngOnInit(): void {}
  constructor(
    private http: HttpClient,
    private language: AppLanguage,
    private appSwal: AppSwal,
    private location: Location,
    private router: Router
  ) {
    // tslint:disable-next-line:no-string-literal
    //this.apiRoot = `${this.location['_platformStrategy']._platformLocation.location.origin}/`;

    this.domainAPI = this.apiRoot;
    var startIdx = this.domainAPI.indexOf('//');
    this.domainAPI = 'ws:' + this.domainAPI.substring(startIdx);
  }

  getUser(): any {
    const result = localStorage.getItem('userInfoPortal');
    if (result) {
      this.user = JSON.parse(result);
    }
    return this.user;
  }

  getUserName() {
    this.getUser();
    return this.user && this.user.UserName ? this.user.UserName : '';
  }

  createHeaders(): HttpHeaders {
    // const token = "";
    const token = localStorage.getItem('tokenPortal');

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': this.language.get(),
      UserName: this.getUserName(),
    });

    // tslint:disable-next-line:max-line-length
    // this.headers.set('Content-Type', 'application/json');
    // this.headers.set('Authorization', `Bearer ${token}`);
    // this.headers.set('Accept-Language', this.language.get());
    // this.headers.set('UserName', this.getUserName());
    // this.headers.set('rejectUnauthorized','false')
    return headers;
  }

  async doGET(methodUrl: any, params: any) {
    //   this.createHeaders();
    const apiURL = `${this.apiRoot}${methodUrl}`;
    try {
      const data = await this.http
        .get(apiURL, { headers: this.createHeaders(), params })
        .toPromise()
        .then(
          (res) => res,
          (err) => {
            if (err.statusText === 'Unauthorized') {
              //this.appSwal.showWarning(this.language.translate.instant('MsgUnauthorized'), false);
              //  this.router.navigate([AppConsts.page.login]);
            }
            return null;
          }
        );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async doPOST(methodUrl: any, dataRequest: any) {
    //  this.createHeaders();
    const apiURL = `${this.apiRoot}${methodUrl}`;
    try {
      const data = await this.http
        .post(apiURL, dataRequest, { headers: this.createHeaders() })
        .toPromise()
        .then(
          (res) => res,
          (err) => {
            console.log(err);
            return data;
          }
        );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async doPOSTOPTION(methodUrl: any, dataRequest: any, options: any) {
    const apiURL = `${this.apiRoot}${methodUrl}`;
    try {
      const data = await this.http
        .post(apiURL, dataRequest, { headers: this.createHeaders() })
        .toPromise()
        .then(
          (res) => res,
          (err) => err.error
        );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async doPUT(methodUrl: any, dataRequest: any, params: any) {
    //  this.createHeaders();
    const apiURL = `${this.apiRoot}${methodUrl}`;
    try {
      const data = await this.http
        .put(apiURL, dataRequest, { headers: this.createHeaders(), params })
        .toPromise()
        .then(
          (res) => res,
          (err) => {
            console.log(err);
            return data;
          }
        );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async doDELETE(methodUrl: any, params: any) {
    // this.createHeaders();
    const apiURL = `${this.apiRoot}${methodUrl}`;
    try {
      const data = await this.http
        .delete(apiURL, { headers: this.createHeaders(), params })
        .toPromise()
        .then(
          (res) => res,
          (err) => {
            console.log(err);
            return data;
          }
        );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async doDownload(methodUrl: any, params: any) {
    //this.createHeaders();
    const apiURL = `${this.apiRoot}${methodUrl}`;
    try {
      const data = await this.http
        .get(apiURL, {
          headers: this.createHeaders(),
          params,
          responseType: 'blob' as 'json',
        })
        .toPromise()
        .then(
          (res) => res,
          (err) => {
            if (err.statusText === 'Unauthorized') {
              //  this.router.navigate([AppConsts.page.login]);
            }
            return null;
          }
        );
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  encodeParams(params: any): string {
    let body = '';
    for (const param in params) {
      if (body.length) {
        body += '&';
      }
      body = `${body}${param}=`;
      body = `${body}${encodeURIComponent(params[param])}`;
    }

    return body;
  }
}
