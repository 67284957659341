<article class="news-template-45" (window:resize)="onResize($event)">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-12 search">
          <div class="row search-container">
            <!-- <div class="col-md-1 col-12">
            </div> -->
            <div class="col-md-3 col-12">
              <div class="group">
                <div class="group-search">
                  <div class="row" [ngClass]="'control'">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control control-search'">
                        <label>
                          <span [textContent]="'Tìm kiếm theo tên'"></span>
                        </label>
                        <input kendoTextBox  [(ngModel)]="searchOption.searchName"
                          [placeholder]="'Nhập để tìm kiếm'" (keypress)="onSearchKeyPress($event)" />
                        <button kendoButton (click)="onRemovesearchName();" [primary]="true" [look]="'outline'"
                          [icon]="'close-outline'" class="btn-remove btn-remove-replace-btn-search"
                          *ngIf="searchOption.searchName !== ''">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="group">
                <div class="group-search">
                  <div class="row" [ngClass]="'control'">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control control-search'">
                        <label>
                          <span [textContent]="'Tìm kiếm theo chức vụ'"></span>
                        </label>
                        <input kendoTextBox  [(ngModel)]="searchOption.searchPosition"
                          [placeholder]="'Nhập để tìm kiếm'" (keypress)="onSearchKeyPress($event)" />
                        <button kendoButton (click)="onRemovesearchPosition();" [primary]="true" [look]="'outline'"
                          [icon]="'close-outline'" class="btn-remove btn-remove-replace-btn-search"
                          *ngIf="searchOption.searchPosition !== ''">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="group">
                <div class="group-search">
                  <div class="row" [ngClass]="'control'">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control control-search'">
                        <label>
                          <span [textContent]="'Tìm kiếm theo đơn vị'"></span>
                        </label>
                        <input kendoTextBox  [(ngModel)]="searchOption.searchUnit"
                          [placeholder]="'Nhập để tìm kiếm'" (keypress)="onSearchKeyPress($event)" />
                        <button kendoButton (click)="onRemovesearchUnit();" [primary]="true" [look]="'outline'"
                          [icon]="'close-outline'" class="btn-remove btn-remove-replace-btn-search"
                          *ngIf="searchOption.searchUnit !== ''">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn-dialog-cus btn-dialog btn-search" (click)="onSearchManagePosts()">
                  <span>Tìm kiếm</span>
                </button>
            </div>
            <!-- <div class="col-md-1 col-12">
            </div> -->
          </div>
        </div>
        <div class="main-item">
          <div class="row" *ngIf="topNews.length > 0">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
  
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">           
              <div class="row">
                <div *ngFor="let item of topNews; let i = index"
                 [ngClass]="checkIsRoundNum(i) == true ? 'intro-des': 'intro-des-reserve' ">
                  <div class="center-img pointer">
                    <div
                      [ngClass]="isErrImg == false 
                      ? 'col-sm-12 col-12 col-md-3 col-xl-3 image-circle'
                      :'col-sm-12 col-12 col-md-3 col-xl-3 image-circle-no-border' ">
                      <img [ngClass]="isErrImg == false 
                        ? 'col-sm-12 col-12 col-md-3 col-xl-3 image-circle'
                        :'col-sm-12 col-12 col-md-3 col-xl-3 image-circle-no-border' "
                        *ngIf="item.ImageUrl != '../../assets/images/default.png'" 
                        [src]="getFullUrlAPI(item.ImageUrl)" [alt]="" 
                        (error)="onImgError($event)"
                      />
                  </div>
                    
                    <img class="col-sm-12 col-12 col-md-3 col-xl-3 image-circle-no-border" *ngIf="item.ImageUrl == '../../assets/images/default.png'" [src]="item.ImageUrl" />
                  </div>
                  
                  <div class="col-sm-12 col-12 col-md-9 col-xl-9"
                  [ngClass]="checkIsRoundNum(i) == true ? 'intro-text': 'intro-text-reverse'">
                    <div class="field-detail">
                      <div class="field-name pointer">{{ item.Name }}</div>
                      <div class="letter pointer">
                        <p><b>Chức vụ: </b>{{ item.Position }}</p>
                        <p><b>Đơn vị công tác: </b>{{ item.UnitName }}</p>
                        <p><b>Khen thưởng: </b></p>
                        <div [innerHTML]="item.Reward"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
          <div class="row" *ngIf="topNews.length == 0">
            <div class="col-sm-12 col-12 col-md-9 col-xl-9 intro-text">
              <a class="field-detail">
                <div class="field-name pointer">Không tìm thấy dữ liệu</div>

              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
      class="router-outlet-content window-center dialog-info dialog-width">
      <div class="news-template-45-dialog close-div">
        <div class="title-info-req">
          <strong *ngIf="editFlg == true && dltFlg == false">{{
            "Gallery_Scrollview_Edit" | translate
            }}</strong>
          <strong *ngIf="editFlg == false && dltFlg == false">{{
            "Gallery_Scrollview_Add" | translate
            }}</strong>
          <strong *ngIf="dltFlg == true">{{
            "Gallery_Scrollview_Delete" | translate
          }}</strong>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
        </div>
      </div>
      <div *ngIf="dltFlg == true">
        <div class="input-label">
          <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
        </div>
        <div>
          <button
            class="btn-delete"
            (click)="onDeleteGallery()"
          >
            <span>{{ "Delete" | translate }}</span>
          </button>
          <button
            class="btn-cancel"
            (click)="onClose($event)"
          >
            <span>{{ "Cancel" | translate }}</span>
          </button>
        </div>
      </div>
    </kendo-dialog>
  </article>
  