<section class="routerLink row" *ngIf="!checkRemove() && checkPage(currentFolder?.Name) " >
  <div class="row">

    <div class="col-md-12 col-12">
      <div class="row">
        <div class="col-md-10 col-12 btn-fl">
          <!-- <span *ngIf="!checkSearchPage()"> -->
          <span>
            <span *ngIf=" checkPage(currentFolder?.Name)" class="is-current-folder1 margin">TRANG CHỦ</span>
            <span *ngIf="this.itemmenuDad != 'Trang chủ'">
              <img class="img" *ngIf="this.itemmenuDad" src="../../../assets/images/images-titile.png" />
              <span [ngClass]="this.itemmenuDad != itemmenu?.Name ? 'is-current-folder1' : 'is-current-folder'
            " *ngIf="this.itemmenuDad">{{ this.itemmenuDad }}</span>
            </span>
          </span>
          <span *ngIf="this.itemmenuDad != itemmenu?.Name">
            <img class="img" *ngIf="itemmenu?.Name" src="../../../assets/images/images-titile.png" />
            <span class="is-current-folder" *ngIf="itemmenu?.Name">
              {{ itemmenu?.Name }}</span>

          </span>
        </div>

      </div>
    </div>
  </div>
</section>
