<footer class="ver3" *ngIf="isLoaded">
  <div class="footer2" *ngIf="footer && footerInfo">
    <div class="row">
      <div class="col-md-1 col-12"></div>
      <div class="col-md-4 col-12 info">
        <div class="title">
          <span>
            {{ footerInfo.Title }}
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(footerInfo)"></span>
              </kendo-dropdownbutton>
            </span>
          </span>
        </div>
        <div class="description">
          {{ footerInfo.Description }}
        </div>
        <ul>
          <li>
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            &nbsp; {{ footerInfo.Address }}
          </li>
          <li>
            <i class="fa fa-phone" aria-hidden="true"></i>
            &nbsp; {{ footerInfo.Phone }}
          </li>
          <li>
            <i class="fa fa-envelope" aria-hidden="true"></i>
            &nbsp; {{ footerInfo.Email }}
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-12 menu">
        <ul>
          <li *ngFor="let item of footerInfo.MenuIDs">
            <a (click)="goToMenu(item)" class="pointer">
              {{ appUtils.getNameByList(categoriesList, item) }}
            </a>
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(item)"></span>
              </kendo-dropdownbutton>
            </span>
          </li>
          <button *ngIf="isAdminFlg && dataMenu.length<8" class="btn-add" (click)="onOpenAddMenu()">+</button>
        </ul>
      </div>
      <div class="col-md-2 col-12 contact">
        <div class="title">
          <span [textContent]="'Follow_Saigontourist' | translate"></span>
        </div>
        <div class="social-icon">
          <a *ngFor="let link of socialLinks" [href]="link.LinkUrl" target="_blank">
            <img [src]="appService.apiRoot + link.ImageUrl" [alt]="link.Name">
          </a>
        </div>
      </div>
      <div class="col-md-1 col-12"></div>
    </div>
  </div>
  <div class="copyright">
    <div class="row">
      <div class="col-md-1 col-12"></div>
      <div class="col-md-11 col-12">
        <span>{{ footerInfo?.CopyRight }}</span>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="isAddMenu" [draggable]="false" class="add-menu x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span [textContent]="'Menu_List'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label [textContent]="'Menu' | translate"></label>
                <kendo-dropdownlist [data]="menuFilter" [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true"
                  [filterable]="true" (filterChange)="menuHandleFilter($event)" (valueChange)="onChangeMenu($event)"
                  [popupSettings]="{popupClass: popupClass, animate: true}">
                  <ng-template kendoComboBoxNoDataTemplate>{{'NoRecordsAvailable' | translate}}</ng-template>
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
                  <span aria-hidden="true">{{'Save' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
  <!-- <kendo-dialog *ngIf="menuOpened" [draggable]="false" class="edit x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span *ngIf="menuOpened" [textContent]="'Edit_Menu'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="edit-menu">
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Menu_Name' | translate"></span>
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Name" [ngClass]="''" />
                </div>
              </div>
              <div class="col-md-12 col-12" *ngIf="isParentUrl">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Menu_Parent_Url' | translate"></span>
                  </label>
                  <input kendoTextBox [(ngModel)]="parentUrl" [ngClass]="''" />
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Menu_Url' | translate"></span>
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Url" [ngClass]="''" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="btn-dialog-wrapper">
            <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
              <span aria-hidden="true">{{'Save' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog> -->
  <kendo-dialog *ngIf="infoOpened" [draggable]="false" class="edit x-dialog window-center router-outlet-content">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span *ngIf="infoOpened" [textContent]="'Footer_Info_List'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <kendo-tabstrip #tabstrip (tabSelect)="onTabChanged($event)" class="portal-tabstrip">
            <kendo-tabstrip-tab class="officetabstrip" [selected]="tabID == 0" [ngClass]="tabID == 0 ? '' : 'active'"
              class="active" [title]="language.get() == 'vi-VN' ? 'Tiếng việt' : 'Vietnamese'">
              <ng-template kendoTabContent>
                <div class="row">
                  <div *ngIf="viFooterInfo" class="edit-info">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Title' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Title" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Description' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Description" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Address' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Address" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Phone' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Phone" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Email' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Email" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'CopyRight' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.CopyRight" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Template ID' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.TemplateID" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="btn-dialog-wrapper">
                        <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog('vi-VN')">
                          <span aria-hidden="true">{{'Save' | translate}}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab class="officetabstrip" [selected]="tabID == 1" [ngClass]="tabID == 1 ? '' : 'active'"
              class="active" [title]="language.get() == 'vi-VN' ? 'Tiếng anh' : 'English'">
              <ng-template kendoTabContent>
                <div class="row">
                  <div *ngIf="enFooterInfo" class="edit-info">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Title' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Title" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Description' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Description" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Address' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Address" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Phone' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Phone" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Email' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Email" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'CopyRight' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.CopyRight" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Template ID' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.TemplateID" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="btn-dialog-wrapper">
                        <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog('en-US')">
                          <span aria-hidden="true">{{'Save' | translate}}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </kendo-dialog>
</footer>
