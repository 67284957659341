<article class="news-template-d">
  <div class="container-fluid">
    <div class="row">
      <div class="main-item">
        <div *ngIf="isAdminFlg" class="row">
          <div class="col-sm-1 col-12 col-md-1 col-xl-1 vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
            <div class="three-dot-area" (click)="enableChoice()">
              <img class="three-dot" src="../../assets/images/three_dot_vertical.png" alt="" />
            </div>
            <div class="menu" *ngIf="isShowMenu">
              <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">Sửa</div>
              </div>
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">Xóa</div>
              </div>
            </div>
          </div>
          <!-- kkkkkkk -->
          <div class="col-sm-11 col-12 col-md-11 col-xl-11">
            <div class="news-d-title">
              <img class="news-d-bar-title" src="../../assets/images/title-bar.png" />
              <h3>{{ postTittle }}</h3>
            </div>

            <div class="news-d-content">
              <a *ngIf="topNews[0]" (click)="redirectToItem(topNews[0].UrlPath)" class="news-d-large-item">
                <div class="news-d-large-item-img">
                  <img *ngIf="topNews[0].ImageUrl && !topNews[0].DefaultFlg" alt="post-image"
                    [src]="getFullUrlAPI(topNews[0].ImageUrl)" />
                  <img *ngIf="topNews[0].DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                    alt="post-image" />
                </div>
                <div class="news-d-large-item-info">
                  <h4 class="news-d-info-title">
                    {{ topNews[0].Title }}
                  </h4>
                  <p class="news-d-details">
                    Saigontourist Group hân hạnh là đối tác hỗ trợ của Hội nghị
                    Meet The Experts với chủ đề “Vietnam’s Hospitality - Time To
                    Rebuild” vào lúc 1:30 PM ngày 26 tháng 4 tại Khách sạn
                    InterContinental Sài Gòn
                  </p>
                </div>
              </a>

              <div class="news-d-list-item">
                <a class="news-d-small-item" *ngFor="let item of topNews.slice(1); let i = index"
                  (click)="redirectToItem(item.UrlPath)">
                  <div class="news-d-small-item-img">
                    <img src="../../assets/images/default.png" alt="avatar" />
                  </div>
                  <div class="news-d-small-title">
                    {{ item.Title }}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- kkkkkkk -->
        </div>

        <!-- Client -->
        <div *ngIf="!isAdminFlg" class="news-d-container">
          <div class="news-d-title">
            <img class="news-d-bar-title" src="../../assets/images/title-bar.png" />
            <h3>{{ postTittle }}</h3>
          </div>

          <div class="news-d-content">
            <div *ngIf="topNews[0]" class="news-d-large-item">
              <div class="news-d-large-item-img" (click)="redirectToItem(topNews[0].UrlPath)">
                <img *ngIf="topNews[0].ImageUrl && !topNews[0].DefaultFlg" alt="post-image"
                  [src]="getFullUrlAPI(topNews[0].ImageUrl)" />
                <img *ngIf="topNews[0].DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                  alt="post-image" />
              </div>
              <div class="news-d-large-item-info">
                <h4 class="news-d-info-title" (click)="redirectToItem(topNews[0].UrlPath)">
                  {{ topNews[0].Title }}
                </h4>
                <p class="news-d-details">
                  Saigontourist Group hân hạnh là đối tác hỗ trợ của Hội nghị
                  Meet The Experts với chủ đề “Vietnam’s Hospitality - Time To
                  Rebuild” vào lúc 1:30 PM ngày 26 tháng 4 tại Khách sạn
                  InterContinental Sài Gòn.
                </p>
              </div>
            </div>

            <div class="news-d-list-item">
              <div class="news-d-small-item" *ngFor="let item of topNews.slice(1); let i = index">
                <div class="news-d-small-item-img" (click)="redirectToItem(item.UrlPath)">
                  <img *ngIf="item.ImageUrl && !item.DefaultFlg" [src]="getFullUrlAPI(item.ImageUrl)" alt="image" />
                  <img *ngIf="item.DefaultFlg == true" src="../../assets/images/default.png" alt="post-image" />
                </div>
                <div class="news-d-small-title" (click)="redirectToItem(item.UrlPath)">
                  {{ item.Title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End client -->
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-d-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
      </div>
      <div class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-10-dialog [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()">
    </app-news-template-10-dialog>
  </kendo-dialog>
</article>
