<div class="settings-container">
  <div class="setting-navbar">
    <div class="setting-item" routerLink="change-password">
      <span class="setting-item-icon">
        <img src="assets/images/lock.png" />
      </span>
      <span class="setting-item-title">{{
        "Change_Password" | translate
      }}</span>
    </div>
    <div class="setting-item" routerLink="support">
      <span class="setting-item-icon">
        <img src="assets/images/life-buoy.png" />
      </span>
      <span class="setting-item-title">{{ "Support" | translate }}</span>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
