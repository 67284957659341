<div class="news-template-35-dialog row">
  <div class="col-md-12 col-12">
    <div class="row">
      <div class="col-md-12 col-12">
        <div [ngClass]="'control'" >
          <label>
            <span [textContent]="'Gallery_Dialog_Name' | translate"></span>
            <span class="required">(*)</span>
          </label>
          <kendo-combobox
            [ngClass]="dataErr[0]['Name'] ? 'err' : ''"
            class="font-size"
            [data]="categoriesListFiltered"
            [textField]="'Name'"
            [valueField]="'ID'"
            [filterable]="true"
            (filterChange)="handleFilter($event)"
            [(ngModel)]="dataGalleryItemtemp.Info.MenuID"
            [valuePrimitive]="true"
          >
            <ng-template kendoDropDownListNoDataTemplate>{{'NoRecordsAvailable' | translate}}</ng-template>
          </kendo-combobox>
          <span class="err-text " *ngIf="dataErr[0]['MenuID'] ">{{dataErr[0]['MenuID']}}</span>
        </div>
      </div>

      <div class="col-md-12 col-12" >
        <div [ngClass]="'control'" >
          <label>
            <span  [textContent]="'Gallery_Dialog_Top' | translate"></span>
            <span class="required">(*)</span>
          </label>
          <kendo-numerictextbox [(ngModel)]="dataGalleryItemtemp.Info.TopNumber"
              [min]="0" [max]="1000" [format]="'n0'" [autoCorrect]="true" [ngClass]="dataErr[0]['Info.TopNumber'] ? 'err' : ''">
          </kendo-numerictextbox>
          <span class="err-text " *ngIf="dataErr[0]['Info.TopNumber'] ">{{dataErr[0]['Info.TopNumber']}}</span>
        </div>
      </div>

      <div class="col-md-12 col-12">
          <div class="btn-dialog-wrapper">
          <button *ngIf="controlDefault || control.Save " class="btn-dialog btn-save" (click)="onSaveGallery()">
              <span>{{'Save' | translate}}</span>
          </button>
      </div>
    </div>
  </div>
</div>
</div>
