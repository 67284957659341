<article class="news-template-50" (window:resize)="onResize($event)">
  <!-- <div class="container-fluid">
  </div> -->
  <div class="row full-width">
    <div class="main-item">
      <div class="row">
        <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
          <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
          <div class="menu" *ngIf="isShowMenu">
            <div class="menu-row-current-border" (click)="editComponent()">
              <div class="menu-item-1">{{'Edit' | translate}}</div>
            </div>
            <div class="menu-row-current" (click)="deleteComponent()">
              <div class="menu-item-3">{{'Delete' | translate}}</div>
            </div>
          </div>
        </div>
        <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">
          <img src="../../assets/images/flower-icon.png" alt="" class="flower-img" />
          <p class="uppercase">{{ postTittle }}</p>
          <img *ngIf="newsDecorationIndex == 0" src="../../assets/images/first-news-deco.png" alt=""
            class="first-news-deco" />
        </div> -->
        <div class="col-sm-12 col-12 col-md-12 col-xl-12">
          <div class="row">
            <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 menu-title">
              <span class="uppercase"> {{ menuTitle }} </span>
              <hr />
            </div> -->

            <!-- giới thiệu -->
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 description" *ngIf="description">
              <div class="row">
                <div class="col-sm-12 col-12 col-md-6 col-xl-6 image web-view">
                  <img class="main-img" [src]="description?.ImageUrls[0] ? description?.ImageUrls[0] : defaultImg" alt="img" [style.height.px]="innerWidth*0.25">
                  <img class="sub-img-1" [src]="description?.ImageUrls[1] ? description?.ImageUrls[1] : defaultImg" alt="img" [style.height.px]="innerWidth*0.12">
                  <img class="sub-img-2" [src]="description?.ImageUrls[2] ? description?.ImageUrls[2] : defaultImg" alt="img" [style.height.px]="innerWidth*0.12">
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-xl-6 image mobile-view">
                  <kendo-scrollview #scrollview  *ngIf="description?.ImageUrls.length>0" [data]="description?.ImageUrls" [arrows]="false" [pageable]="true" [endless]="true"
                  [style.height.px]="innerWidth*0.6">
                    <ng-template let-item="item">
                      <img [src]="item" alt="{{ item.Name }}" class="img-scrollview" draggable="false" [style.height.px]="innerWidth*0.6" />
                    </ng-template>
                  </kendo-scrollview>
                  <img *ngIf="description?.ImageUrls.length==0" src="../../assets/images/default.png" alt="" draggable="false"
                    class="img-scrollview" [style.height.px]="innerWidth*0.6" />
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-xl-6 content">
                  <p [innerHTML]="description?.Description">
                    {{ description?.Description }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Chương trình -->
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 process" *ngIf="process">
              <div class="title">
                <span class="uppercase">{{process?.TypeName}}</span>
              </div>
              <p class="content" [innerHTML]="process?.Description">
                {{ process?.Description }}
              </p>
            </div>

            <!-- Timeline -->
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 student-list" *ngIf="timelineList?.length > 0">
              <div class="title">
                <span class="uppercase">{{timelineList[0].TypeName}}</span>
              </div>
              <div class="content">
                <div *ngFor="let student of timelineList; let i = index"
                  class="student-content" [ngClass]="{ 'content-border': i != timelineList?.length-1 }">
                  <div class="student-title" (click)="studentInfo(i)">
                    <div class="arrow">
                      <i class="fa fa-angle-up" *ngIf="timelineIndex.includes(i)" aria-hidden="true"></i>
                      <i class="fa fa-angle-down" *ngIf="!timelineIndex.includes(i)" aria-hidden="true"></i>
                    </div>
                    <div class="name">
                      {{ student?.Title }}
                    </div>
                  </div>
                  <div class="student-info row" *ngIf="timelineIndex.includes(i)">
                    <div class="col-sm-12 col-12 col-md-4 col-xl-4 info-image">
                      <img [src]="student?.ImageUrls[0] ? student?.ImageUrls[0] : ''" alt="img" [style.height.px]="innerWidth > 768 ? innerWidth*0.15 : innerWidth*0.45">
                    </div>
                    <div class="col-sm-12 col-12 col-md-8 col-xl-8 info-content">
                      <p [innerHTML]="student?.Description">
                        {{ student?.Description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Báo cáo -->
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 report" *ngIf="reportList?.length > 0">
              <div class="title">
                <span class="uppercase">{{reportList[0].TypeName}}</span>
              </div>
              <div class="content row">
                <a *ngFor="let report of reportList; let i = index"
                  class="col-sm-12 col-12 col-md-3 col-xl-3 report-content" [href]="report?.FileUrls[0]" target="_blank">
                  <div class="row">
                    <div class="col-sm-6 col-6 col-md-12 col-xl-12 image">
                      <img [src]="report?.ImageUrls[0] ? report?.ImageUrls[0] : ''" alt="img" [style.height.px]="innerWidth > 768 ? innerWidth*0.15 : innerWidth*0.3">
                    </div>
                    <div class="col-sm-6 col-6 col-md-12 col-xl-12 item-title">
                      <span>
                        {{ report?.Title }}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- Hoạt động -->
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 activities" *ngIf="activityList?.length > 0">
              <div class="title">
                <span class="uppercase">TIN TỨC HOẠT ĐỘNG</span>
              </div>
              <div class="content row">
                <a *ngFor="let activity of activityList; let i = index"
                  class="col-sm-12 col-12 col-md-3 col-xl-3 activity-content"
                  [href]="this.postDetailRoute + activity?.UrlPath">
                  <div class="row">
                    <div class="col-sm-6 col-6 col-md-12 col-xl-12 image">
                      <img [src]="activity?.ImageUrl" [alt]="activity?.Title" [style.height.px]="innerWidth > 768 ? innerWidth*0.15 : innerWidth*0.3">
                    </div>
                    <div class="col-sm-6 col-6 col-md-12 col-xl-12">
                      <div class="item-title">
                        <span>
                          {{ activity?.Title }}
                        </span>
                      </div>
                      <div class="time-clock">
                        <img class="clock" src="../../assets/images/clock-circle.svg" alt="">
                        <p class="time-text" *ngIf="activity?.CreateAt">{{ activity?.CreateAt | date:'dd-MM-yyyy' }}</p>
                        <p class="time-text" *ngIf="!activity?.CreateAt"></p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-50-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-50-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-50-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div class="button-group">
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
