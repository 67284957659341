<footer class="ver4" *ngIf="isLoaded">
  <div class="footer3" *ngIf="footer && footerInfo">
    <div class="row menu-width" id="menu-width1">
      <div class="" *ngFor="let item of menus; let i = index">
        <div class="break-line" *ngIf="i % 4 ==0"></div>
        <div class="row margin-box" *ngIf="i % 4 ==0">
          <div class="col-12 col-md-3" *ngFor="let item of menus; let j = index">
            <div *ngIf="j >= i && j < i + 4 " class="item-box">
              <div class="menu-title">
                <a (click)="goToMenuParent(item)">{{ item.Name}}</a>
              </div>
              <div *ngFor="let childItem of item.Childrens; let m = index">
                <div class="childmenu-title">
                  <a (click)="goToSubMenu(childItem, m,item)">{{ childItem.Name }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-background " >
      <!-- <div class="col-md-1 col-12"></div> -->
      <div id="menu-width1" class="row margin-background menu-width">
        <div class="col-sm-12 col-12 col-md-2 col-xl-2 info image-block px-0" >
          <img [src]="appService.apiRoot + this.footerInfo.BackGroundUrl" alt="" class="icon-footer">
        </div>
        <div class="col-sm-12 col-12 col-md-10 col-xl-10 info " >
            <div class="title-info">
              <span>
                {{ footerInfo.Title }}
                <span *ngIf="isAdminFlg" class="btn-custom">
                  <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                    [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                    <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(footerInfo)"></span>
                  </kendo-dropdownbutton>
                </span>
              </span>
            </div>
            <div class="row col-sm-12 col-12 col-md-12 col-xl-12">
              <div class="col-sm-12 col-12 col-md-9 col-xl-9 operator-info mx-0">
                <div class="row col-sm-12 col-12 col-md-12 col-xl-12 px-0 mx-0">
                  <div class ="col-sm-12 col-12 col-md-8 col-xl-8 px-0">
                    <ul>
                      <li class="title-text">
                        <span class="bold-title">{{'Address' | translate}}</span>
                        {{ footerInfo.Address }}
                      </li>
                      <li class="title-text">
                        <span class="bold-title">{{'Phone' | translate}}</span>
                        {{ footerInfo.Phone }}
                        <span style="margin-left: 5px; margin-right: 5px;"> | </span>
                        <span class="bold-title">{{'Fax' | translate}}</span>
                        {{ footerInfo.Fax }}
                      </li>
                      <!-- <li class="title-text">
                        <span class="bold-title">{{'Fax' | translate}}</span>
                        {{ footerInfo.Fax }}
                      </li> -->
                      <li class="title-text">
                        <span class="bold-title">{{'Email' | translate}}</span>
                        {{ footerInfo.Email }}
                      </li>
                    </ul>
                    <div class="description">
                      <div> {{ footerInfo.Description }}</div>
                      <!-- <div class="description">Trưởng ban điều hành: Nguyễn Văn Đắng</div> -->
                    </div>
                  </div>
                  <!-- Thông tin truy nhập -->
                  <!-- <div class ="col-sm-12 col-12 col-md-4 col-xl-4 px-0">
                    <div class="content-box online-box">
                      <div class="access-title">{{'AccessCnt' | translate}}</div>
                      <div class="number-arr">
                        <div class="number-box" *ngFor="let accessNum of AccessCntArr">{{ accessNum}}</div>
                      </div>
                      <div class="access-title margin-title">{{'OnlineCnt' | translate}}</div>
                      <div class="number-arr">
                        <div class="number-box" *ngFor="let onlineNum of OnlineCntArr">{{ onlineNum}}</div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- <div class="col-sm-12 col-12 col-md-3 col-xl-3 applink ">
                <div class="applink-holder">
                  <div class="textapplink">{{'Use_App_At' | translate}}</div>
                  <div class="link">
                    <div class="barcode">
                      <div class="barcodeholder">
                        <img class="barcodes" src="../../assets/images/sawaco_barcode.png" alt="">
                      </div>
      
                    </div>
                    <div class="download-box">
                      <div class="col-sm-12 col-12 col-md-12 col-xl-12 appstore " >
                        <a href="https://apps.apple.com/vn/app/cskh-sawaco/id1560097881">
                        <img class="appimg" src="../../assets/images/App_Store_sawaco.png" alt="">
                        </a>
                      </div>
                      <div class="col-sm-12 col-12 col-md-12 col-xl-12 googleplay" >
                        <a href="https://play.google.com/store/apps/details?id=vn.com.sawaco.cskh">
                        <img class="appimg" src="../../assets/images/Google_Play_Store_sawaco.png" alt="">
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
      
              </div> -->
            </div>



        </div>

      </div>

      <!-- <div class="col-md-2 col-12 contact">
        <div class="title">
          <span [textContent]="'Follow_Saigontourist' | translate"></span>
        </div>

      </div> -->

    </div>
  </div>
  <div class="copyright">
    <div class="row mx-0 justify-content-center position-relative">
      <div class="col-sm-10 col-8 col-md-10 col-xl-10 copyright-row p-0" id="menu-width3">
        
        <div class="footertext">
          <div class="icon-copyrightholder">
            <img src="../../assets/images/icon-copyright.png" alt="" class="icon-copyright">
          </div>
          <div class="c-text">{{ footerInfo?.CopyRight }}</div>

          <!-- <img src="../../assets/images/logo_footer.png" alt="" class="logofooter"> -->
        </div>
      </div>
      <!-- <div class="logofooterholder col-sm-1 col-1 col-md-1 col-xl-1 p-0" >
        <img src="../../assets/images/logo_footer.png" alt="" class="logofooter">
      </div> -->
    </div>

  </div>


  <kendo-dialog *ngIf="isAddMenu" [draggable]="false" class="add-menu x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span [textContent]="'Menu_List'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label [textContent]="'Menu' | translate"></label>
                <kendo-dropdownlist [data]="menuFilter" [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true"
                  [filterable]="true" (filterChange)="menuHandleFilter($event)" (valueChange)="onChangeMenu($event)"
                  [popupSettings]="{popupClass: popupClass, animate: true}">
                  <ng-template kendoComboBoxNoDataTemplate>{{'NoRecordsAvailable' | translate}}</ng-template>
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
                  <span aria-hidden="true">{{'Save' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
  <!-- <kendo-dialog *ngIf="menuOpened" [draggable]="false" class="edit x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span *ngIf="menuOpened" [textContent]="'Edit_Menu'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="edit-menu">
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Menu_Name' | translate"></span>
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Name" [ngClass]="''" />
                </div>
              </div>
              <div class="col-md-12 col-12" *ngIf="isParentUrl">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Menu_Parent_Url' | translate"></span>
                  </label>
                  <input kendoTextBox [(ngModel)]="parentUrl" [ngClass]="''" />
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Menu_Url' | translate"></span>
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Url" [ngClass]="''" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="btn-dialog-wrapper">
            <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
              <span aria-hidden="true">{{'Save' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog> -->
  <kendo-dialog *ngIf="infoOpened" [draggable]="false" class="edit x-dialog window-center router-outlet-content">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span *ngIf="infoOpened" [textContent]="'Footer_Info_List'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <kendo-tabstrip #tabstrip (tabSelect)="onTabChanged($event)" class="portal-tabstrip">
            <kendo-tabstrip-tab class="officetabstrip" [selected]="tabID == 0" [ngClass]="tabID == 0 ? '' : 'active'"
              class="active" [title]="language.get() == 'vi-VN' ? 'Tiếng việt' : 'Vietnamese'">
              <ng-template kendoTabContent>
                <div class="row">
                  <div *ngIf="viFooterInfo" class="edit-info">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Title' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Title" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Description' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Description" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Address' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Address" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Phone' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Phone" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Email' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.Email" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'CopyRight' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.CopyRight" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Template ID' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viFooterInfo.TemplateID" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="btn-dialog-wrapper">
                        <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog('vi-VN')">
                          <span aria-hidden="true">{{'Save' | translate}}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab class="officetabstrip" [selected]="tabID == 1" [ngClass]="tabID == 1 ? '' : 'active'"
              class="active" [title]="language.get() == 'vi-VN' ? 'Tiếng anh' : 'English'">
              <ng-template kendoTabContent>
                <div class="row">
                  <div *ngIf="enFooterInfo" class="edit-info">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Title' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Title" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Description' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Description" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Address' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Address" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Phone' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Phone" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Email' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.Email" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'CopyRight' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.CopyRight" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Template ID' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enFooterInfo.TemplateID" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="btn-dialog-wrapper">
                        <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog('en-US')">
                          <span aria-hidden="true">{{'Save' | translate}}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </kendo-dialog>
</footer>
