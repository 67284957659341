import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from "../services/app.consts";
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { AppGuid } from '../services/app.guid';
import { AppSwal } from '../services/app.swal';

@Component({
  selector: 'app-news-template-b',
  templateUrl: './news-template-b.component.html',
  // styleUrls: ['./news-template-b.component.scss']
})
export class NewsTemplateBComponent implements OnInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = "";
  viewMore: string = "";
  isShowMenu = false;
  manageDialog = false;
  curChoice = this.translate.instant('Select');
  webData: any;
  websiteLink: any;
  SearchText: any;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = "";
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplateB = new EventEmitter();

  @Output() saveData = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Input() isPreview: boolean;
  @Output() deleteBodyItem = new EventEmitter();

  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
    private guid: AppGuid,
    private appSwal: AppSwal,
  ) { }

  ngOnInit(): void {

    this.bootstrapNum = 12 / this.topNumber;
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.getPostTittle();
    this.getWebsiteLink();
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate ? this.itemTemplate.GroupID : 0,
      "Layout": this.itemTemplate ? this.itemTemplate.Layout : 100,
      "GroupIndex": this.itemTemplate ? this.itemTemplate.GroupIndex : 0
    }
    if (this.ID == null && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: "../../assets/images/default.png",
          Title: "Title",
          UrlPath: "",
          CreateAt: '2021-10-26T08:41:50.453'
        },
        {
          DefaultFlg: true,
          ImageUrl: "../../assets/images/default.png",
          Title: "Title",
          UrlPath: "",
          CreateAt: '2021-10-26T08:41:50.453'
        },
        {
          DefaultFlg: true,
          ImageUrl: "../../assets/images/default.png",
          Title: "Title",
          UrlPath: "",
          CreateAt: '2021-10-26T08:41:50.453'
        }

      ]
    }

    // console.log(`pageID from template: ${this.pageSelectedID}`);
    // console.log('item template 2: ');
    // console.log(this.itemTemplate);
    this.addGallery();
  }

  async addGallery() {
    const dataRequest = this.createDataRequest(null);
    let newTemp = {Info: {MenuID: this.pageSelectedID},
      pageBodyIndex: this.pageBodyIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      index: this.newsDecorationIndex};
    this.changeTemplateB.emit(newTemp);

    // const result = await this.appService.doPOST('api/Template', dataRequest);
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    // } else {
    //   if (result.Msg) {
    //     this.appSwal.showWarning(result.Msg, false);
    //   }
    // }
    // this.appComponent.loading = false;
  }

  createDataRequest(data) {

    return {
      ID: this.guid.empty,
      TemplateID: 3,
      PageID: this.pageSelectedID,
      //Name: temp.Name ? temp.Name : null,
      OrderIdx: this.OrderIdx,
      Info: null,
      GroupID: this.itemTemplate?.GroupID,
      Layout: this.itemTemplate?.Layout,
      GroupIndex: this.itemTemplate?.GroupIndex
    };
  }

  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;

  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  saveComponent() {
    this.addGallery();
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
    // this.topNews = new Array(3).fill({key: 'value'});
    // this.postTittle = this.translate.instant('Category_Name');
    // const dataRequest = {
    //   iD: this.templateID,
    //   itemID: this.ID,
    //   index: this.OrderIdx
    // };
    // const result = await this.appService.doDELETE('api/Template', dataRequest);
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    //   this.isReloadPageAfterSave.emit(true)
    //   location.reload();
    // } else {
    //   this.notification.showSuccess(result.Msg);
    // }

  }
  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex, 
      "layoutGroupIndex":this.layoutGroupIndex, 
      "pageBodyIndex":this.pageBodyIndex
    });
  }
  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true)
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(x => x.ID === this.infoFromMain.MenuID);
  }

  onSaveData() {
    this.saveData.emit();
  }

  async getWebsiteLink() {
    let params = {langID: this.language.get(), type: 0}
    const result = await this.appService.doGET('api/PortalBody/GetCommon', params);
    if (result && result.Status === 1) {
      this.websiteLink = result.Data;
    }
    // console.log(`this.websiteLink: ${this.websiteLink}`);
  }

  myFunction() {
    this.webData = this.websiteLink;
    // this.webData.unshift({"ID": null,
    //                       "Name": "Chọn",
    //                       "Url": null,
    //                       "CreateAt": null,
    //                       "CreateBy": null,
    //                       "UpdateAt": null,
    //                       "UpdateBy": null,
    //                       "DeleteAt": null,
    //                       "DeleteBy": null,
    //                       "DelFlg": null,
    //                       "OrderIdx": null,
    //                       "Path": null,
    //                       "Type": null});
    (<HTMLInputElement>document.getElementById("search-box")).value = '';
    document.getElementById("myDropdown").classList.toggle("show");
  }

  // onClickItem(item, index) {
  //   this.curChoice = item.Name;
  //   let itemList = document.getElementsByClassName("drop-item");
  //   for (let i = 0; i < itemList.length; i++) {
  //     if (i != index) {
  //       itemList[i].classList.remove("active");
  //     }
  //     else {
  //       itemList[i].classList.add("active"); 
  //     }
  //   }
  //   document.getElementById("myDropdown").classList.toggle("show");
  //   window.open(item.Url, '_blank').focus();
  // }

  onClickItem(value) {
    // this.curChoice = item.Name;
    // let itemList = document.getElementsByClassName("drop-item");
    // for (let i = 0; i < itemList.length; i++) {
    //   if (i != index) {
    //     itemList[i].classList.remove("active");
    //   }
    //   else {
    //     itemList[i].classList.add("active");
    //   }
    // }
    // document.getElementById("myDropdown28").classList.toggle("show");
    window.open(value.LinkUrl, '_blank').focus();
  }

  onSearchKeyPress() {
    this.SearchText = (<HTMLInputElement>document.getElementById("search-box")).value;
    if (this.SearchText == '') {
      this.webData = this.websiteLink;
      // this.webData.unshift({"ID": null,
      //                       "Name": "Chọn",
      //                       "Url": null,
      //                       "CreateAt": null,
      //                       "CreateBy": null,
      //                       "UpdateAt": null,
      //                       "UpdateBy": null,
      //                       "DeleteAt": null,
      //                       "DeleteBy": null,
      //                       "DelFlg": null,
      //                       "OrderIdx": null,
      //                       "Path": null,
      //                       "Type": null});
    }
    else {
      let searchResult = [], i = 0;
      for (let item of this.websiteLink) {
        // console.log(`item.Name: ${item.Name}`);
        // console.log(`this.SearchText: ${this.SearchText}`);
        if (item.Name.includes(this.SearchText)) {
          searchResult[i] = item;
          i++;
        }
      }
      this.webData = searchResult;
    }
  }

}

