import { environment } from '../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { AppService } from '../../services/app.service';
import { AppConsts } from '../../services/app.consts';
import { Router } from '@angular/router';
import { Notification } from '../../services/app.notification';
import { EventEmitterService } from '../../event-emitter.service';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { SelectEvent } from '@progress/kendo-angular-upload';
import { AppSwal } from '../../services/app.swal';
import { DomSanitizer } from '@angular/platform-browser';
import { log } from 'console';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';

@Component({
  selector: 'app-opend-30-dialog',
  templateUrl: './opend-30-dialog.component.html',
  // styleUrls: ['./opend-30-dialog-images.component.scss']
})


export class Opend30DialogComponent implements OnInit {
  dltFlg = false;
  isGallery = false;
  public widthSView = '100%';
  public heightSView = '580px';
  @Input() srcURL: any;
  @ViewChild('scrollview') scrollview;
  private interval;
  b = [];

  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private appSwal: AppSwal,
    private sanitizer: DomSanitizer,
  ) { }
  ngOnInit(): void {
    if (this.srcURL.Type == 0) {
    }
    else {
      {
        this.b = JSON.parse(this.srcURL.FileUrls);
      }
    }
  }
  onGoBack() {
    this.isGallery = false;
  }
  onClose(e) {
    this.dltFlg = false;
  }
  getFullUrl(url: string) {
    return this.appService.apiRoot + url;
  }
  getVideoUrl(FileUrls) {
    let video = "";
    let videos = JSON.parse(FileUrls);
    if (videos && videos.length !== 0) {
      video = videos[0];
      let videoUrl = '<figure style=" height: 100%; "><iframe src="https://www.youtube.com/embed/' + video + '" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></figure>';
      return videoUrl;
    }
    return this.appService.apiRoot + video;

  }
}
