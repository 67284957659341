<div class="reward-and-achivement">
  <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
    <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
    <div class="menu" *ngIf="isShowMenu">
      <div class="menu-row-current-border" (click)="editComponent()">
        <div class="menu-item-1">{{'Edit' | translate}}</div>
      </div>
      <div class="menu-row-current" (click)="deleteComponent()">
        <div class="menu-item-3">{{'Delete' | translate}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="loading">Loading...</div>
  <p>
    {{summary}}
  </p>
  <div [innerHTML]="description" class="description"></div>
  <div class="row rGaps item">
    <div class="col-12 col-md-3 col-sm-6" *ngFor="let achivement of achivements">
      <app-achivement-item
        [ImageUrl]="achivement.ImageUrl"
        [Name]="achivement.Title"
      ></app-achivement-item>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="reward-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-reward-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg"
      (onSave)="this.onLoadData($event)" (onChangetemp2)="this.changeTemp2($event)">
    </app-reward-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</div>
