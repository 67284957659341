<section *ngIf="Posts" class="most-view col-12 col-md-12">
  <div class="title-view ">
    <!-- <hr *ngIf="i != Posts.length - 1" /> -->
    <header>
      <hgroup>
        <div class="header">
          {{
          (type != "related_Post" ? "Related_Posts" : "Related_Posts") | translate
          }}
        </div>
      </hgroup>
    </header>
  </div>
  <div class="margin" *ngIf="Posts.length > 0">
    <ng-container *ngFor="let post of Posts; index as i">
      <figure>
        <div class="post-fra row">
          <div *ngIf="i==0" class="col-12 col-md-12">
            <a [href]="getDetailUrl(post.UrlPath)" class="caption-view  row ">
              <div class="col-12 col-md-12 " *ngIf="Posts[0].Title != 'Title'">
                <img src="{{ this.appService.apiRoot + Posts[0].ImageUrl }}" [style.height.px]="innerWidth*0.15"
                  alt="{{ Posts[0].Title }}" class="img-first" draggable="false" (click)="getLink(Posts[0].DetailPath)"
                  (error)="onImgError($event)" />
              </div>
              <figcaption class="caption-first col-12 col-md-12 "> {{ post.Title }}</figcaption>
            </a>
          </div>

          <div class=" content-box col-12 col-md-12" *ngIf="i!=0">
            <a [href]="getDetailUrl(post.UrlPath)" class="caption-view-next  row">
              <div class="img-next col-12 col-md-6 " *ngIf="Posts[i].Title != 'Title'">
                <img src="{{ this.appService.apiRoot + Posts[i].ImageUrl }}" [style.height.px]="innerWidth*0.07" alt=""
                  class="img-next " draggable="false" (click)="getLink(Posts[i].DetailPath)"
                  (error)="onImgError($event)" />
              </div>
              <figcaption class="caption-next col-12 col-md-6"> {{ post.Title }}</figcaption>
            </a>
          </div>

        </div>
      </figure>
    </ng-container>
  </div>
  <div class="margin " *ngIf="Posts.length == 0">
    <div class="text-no-post">Không có tin liên quan</div>
  </div>
</section>