<section class="field">

  <!-- <div class="row router-outlet-content">
    <div class="row">
      <div class="col-md-6 col-12 btn-fl">
        <span
          *ngIf="folderParentage.length > 1 || (currentFolder.ID != emptyGuid && currentFolder.ParentID == emptyGuid)"
          class="is-folder-link" (click)="  ()">Đảng và đoàn thể</span>
        <span
          *ngIf="folderParentage.length > 1 || (currentFolder.ID != emptyGuid && currentFolder.ParentID == emptyGuid)">
          / </span>
        <span *ngFor="let item of folderParentage; first as isFirst">
          <span class="is-folder-link" (click)="setFolder(item)" *ngIf="item.ID != currentFolder.ID && item.ID != emptyGuid">{{item.Name}}</span>
          <span *ngIf="item.ID != currentFolder.ID && item.ID != emptyGuid"> / </span>
        </span>
        <span class="is-current-folder">{{currentFolder.Name}}</span>
      </div>

    </div>
  </div> -->
  <!-- <div class="row router-outlet-content router">
    <div class="col-md-9 col-12">
      <div class="group">
        <div class="group-search">
          <div class="row" [ngClass]="'control'">
            <div class="col-md-6 col-12">
              <label>
                <span [textContent]="'Từ khóa' | translate"></span>
              </label>
              <div [ngClass]="'control control-search'">
                <input kendoTextBox class="search-place" [(ngModel)]="searchOption.SearchText" (keypress)="onSearchKeyPress($event)"/>
                <button kendoButton  (click)="onRemoveSearchText();"  [primary]="true" [look]="'outline'"
                [icon]="'close-outline'" class="btn-remove btn-remove-replace-btn-search"
                *ngIf="searchOption.SearchText !== ''">
              </button>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <label>
                <span [textContent]="'Thể loại' | translate"></span>
              </label>
              <div [ngClass]="'control control-search'">
                <kendo-combobox
                  class="search-place"
                  [data]=""
                  [textField]="'Name'"
                  [valueField]="'ID'"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="($event)"
                >
                  <ng-template kendoDropDownListNoDataTemplate>{{
                    "NoRecords" | translate
                  }}</ng-template>
                </kendo-combobox>
              </div>
            </div>

            <div class="col-md-3 col-12">
              <label>
                <span [textContent]="'Cơ quan ban hành' | translate"></span>
              </label>
              <div [ngClass]="'control control-search'">
                <kendo-combobox
                  class="search-place"
                  [data]=""
                  [textField]="'Name'"
                  [valueField]="'ID'"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="($event)"
                >
                  <ng-template kendoDropDownListNoDataTemplate>{{
                    "NoRecords" | translate
                  }}</ng-template>
                </kendo-combobox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="btn-dialog-wrapper">
        <button class="btn-dialog-cus btn-dialog btn-search">
          <span>{{ "Tìm kiếm" | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="router-outlet-content">
    <kendo-grid
      [data]="dataGrids"
      [selectable]="dataGridSelectableSettings"
      (pageChange)="onGridPageChange($event)"
      (selectedKeysChange)="ondataItemselectedKeysChange()"
      [pageSize]="dataGridPageSize"
      [skip]="dataGridSkip"
      [pageable]="true"
      [kendoGridSelectBy]="'ID'"
      [selectedKeys]="dataGridSelection"
      [loading]="loading"
      [sortable]="dataGridSort"
      [sort]="dataGridSortByField"
      (sortChange)="ondataItemsortChange($event)"
      [filterable]="false"
      [groupable]="false"
      [group]="dataGridState.group"
      [filter]="dataGridState.filter"
      (dataStateChange)="onGridDataStateChange($event)"
    >
      <kendo-grid-column
        [width]="30"
        [field]="'STT'"
        [title]="'STT' | translate"
        [headerClass]="{ headerCommon: true }"
      >
        <ng-template kendoGridCellTemplate let-dataItem class="text-name">
          <label>
            {{ dataItem.STT }}
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="60"
        [field]="'theLoai'"
        [title]="'Thể loại' | translate"
        [headerClass]="{ headerCommon: true }"
      >
        <ng-template kendoGridCellTemplate let-dataItem class="text-name">
          <label>
            {{ dataItem.theLoai }}
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="80"
        [field]="'soVanBan'"
        [title]="'Số văn bản' | translate"
        [headerClass]="{ headerCommon: true }"
      >
        <ng-template kendoGridCellTemplate let-dataItem class="text-name">
          <label>
            {{ dataItem.soVanBan }}
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="150"
        [field]="'trichYeu'"
        [title]="'Trích yếu' | translate"
        [headerClass]="{ headerCommon: true }"
      >
        <ng-template kendoGridCellTemplate let-dataItem class="text-name">
          <label>
            {{ dataItem.trichYeu }}
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="100"
        [field]="'ngayBanHanh'"
        [title]="'Ngày ban hành' | translate"
        [headerClass]="{ headerCommon: true }"
      >
        <ng-template kendoGridCellTemplate let-dataItem class="text-name">
          <label>
            {{ dataItem.ngayBanHanh }}
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="80"
        [field]="'coQuanBanHanh'"
        [title]="'Cơ quan ban hành' | translate"
        [headerClass]="{ headerCommon: true }"
      >
        <ng-template kendoGridCellTemplate let-dataItem class="text-name">
          <label>
            {{ dataItem.coQuanBanHanh }}
          </label>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="30"
        [title]="'Tải' | translate"
        [headerClass]="{ headerCommon: true }"
      >
        <ng-template kendoGridCellTemplate>
          <img src="../../assets/images/Download.png" class="img" />
        </ng-template>
      </kendo-grid-column>
      <br />
    </kendo-grid>
  </div> -->
  <div class="body-list">
    <div class="layout-box" *ngFor="let group of pageBody, let bodyIndex = index" [style.width.%]="group.layout">
      <div class="layout-list" id={{group.id}}>
        <div class="body-box" [style.width.%]="100" *ngFor="let item of group.items, let i = index">

          <!-- <app-image-slider-home *ngIf="item?.FrameID == 0" [isAdminFlg]="isAdminFlg" [banner]="item">
          </app-image-slider-home>

          <app-news-template-1 *ngIf="item?.FrameID == 1" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-1>

          <app-news-template-2 *ngIf="item?.FrameID == 2" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-2>

          <app-news-template-3 *ngIf="item?.FrameID == 3" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-3>

          <app-news-template-4 *ngIf="item?.FrameID == 4" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-4> -->

          <app-news-template-5 *ngIf="item?.FrameID == 5" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-5>

          <!-- <app-news-template-6 *ngIf="item?.FrameID == 6" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-6> -->

          <app-news-template-a *ngIf="item?.FrameID == 7" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-a>

          <!-- <app-news-template-b *ngIf="item?.FrameID == 8" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-b>

          <app-news-template-c *ngIf="item?.FrameID == 9" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-c> -->
          <app-news-template-12
            *ngIf="item?.FrameID == -2"
            [ID]="item.ID"
            [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info"
            [categoriesList]="categoriesList"
            [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx"
            (isReloadPageAfterSave)="onReload($event)"
            [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID"
            [itemTemplate]="item"
            (saveData)="this.onReload()"
          >
          </app-news-template-12>
        </div>
      </div>
    </div>
  </div>


</section>
