import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parties-and-unions',
  templateUrl: './parties-and-unions.component.html',
  styleUrls: ['./parties-and-unions.component.scss']
})
export class PartiesAndUnionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
