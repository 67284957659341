<article class="library-page row">
  <div class="background-img">
    <img src="../../assets/images/banner-library-page.png" />
  </div>
  <div class="col-1"></div>
  <div class="col-10">
    <div class="menu-bar">
      <div class="tab-scroll" *ngFor="let item of pageList; let i = index">
        <button
          [ngClass]="tabSelected === i ? 'active' : ''"
          class="tab-item"
          (click)="onClickItemTab(i)"
          [routerLink]="item.route"
        >
          {{ item.title }}
        </button>
      </div>
    </div>
    <div class="tab-des"><router-outlet></router-outlet></div>
  </div>
  <div class="col-1"></div>
</article>
