<article [ngClass]="templateType ==1 ? 'news-template-41' : 'news-template-41-without-time'">
    <div class="container-fluid">
      <div class="row space-box">
        <div class="main-item">
          <div class="row">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12">
              <div *ngIf="!Data_Post">Giới thiệu ở trang chủ</div>
              <section class="description" [innerHTML]="
              (Data_Post ? Data_Post : '') | sanitizedHtml
              "></section>
            </div>
          </div>
        </div>
  
        <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                  <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
              </div> -->
      </div>
    </div>
    <kendo-dialog
      *ngIf="manageDialog"
      (mousedown)="$event.stopPropagation()"
      class="router-outlet-content window-center dialog-info dialog-width"
    >
      <div class="news-template-41-dialog close-div">
        <div class="title-info-req">
          <strong *ngIf="editFlg == true && dltFlg == false">{{
            "Gallery_Scrollview_Edit" | translate
          }}</strong>
          <strong *ngIf="editFlg == false && dltFlg == false">{{
            "Gallery_Scrollview_Add" | translate
          }}</strong>
          <strong *ngIf="dltFlg == true">{{
            "Gallery_Scrollview_Delete" | translate
          }}</strong>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img
            class="close-icon"
            src="../../assets/images/close-button.png"
            alt=""
            (click)="onClose($event)"
          />
        </div>
      </div>
      <app-news-template-41-dialog
        *ngIf="editFlg == true"
        [request_AccountID]="request_AccountID"
        [HiddenTab]="HiddenTab"
        (galleryManageOpened)="onClose($event)"
        [galleryData]="galleryData"
        [editFlg]="editFlg"
        [templateID]="templateID"
        [pageSelectedID]="this.pageSelectedID"
        (onSave)="this.onSaveData()"
        (onChangetemp1)="this.changeTemp1($event)"
      >
      </app-news-template-41-dialog>
      <div *ngIf="dltFlg == true">
        <div class="input-label">
          <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
        </div>
        <div>
          <button
            class="btn-delete"
            (click)="onDeleteGallery()"
          >
            <span>{{ "Delete" | translate }}</span>
          </button>
          <button
            class="btn-cancel"
            (click)="onClose($event)"
          >
            <span>{{ "Cancel" | translate }}</span>
          </button>
        </div>
      </div>
    </kendo-dialog>
  </article>
  