<section [ngClass]="isGallery ? 'archive row' : 'archive mt-title row'">
  <!-- <div *ngIf="templateID == 4" class="col-md-12 col-12">
    <label class="input-label">Height</label>
  </div> -->
  <!-- <div *ngIf="templateID == 4" class="col-md-12 col-12">
    <kendo-numerictextbox [(ngModel)]="imageHeight" [min]="0" [max]="1000" [format]="'n0'" class="input-box">
    </kendo-numerictextbox>
  </div> -->
  <div class="col-md-12 col-12 image-area">
    <div *ngIf="!isChoosingImage" class="header">
      <div class="row">
        <div *ngFor="let item of dataGalleryItemtemp.Info; let i = index" class="col-sm-12 col-md-6 col-12 gallery align-center-webkit">
          <div class="vertical-dot-menu" [ngClass]="d-flex">
            <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice(i)"></div>
            <div class="menu" [attr.id]="'menu'+i">
              <!-- <div class="menu-row-current-border" (click)="addComponent()">
                <div class="menu-item-1">Thêm</div>
              </div> -->
              <div class="menu-row-current-border" (click)="openSizeDialog(i)">
                <div class="menu-item-2">{{'Attribute' | translate }}</div>
              </div>
              <div class="menu-row-current" (click)="deleteImg(item)">
                <div class="menu-item-3">{{'Delete' | translate }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="dlt-img" (click)="deleteImg(item)">
            <img src="../../assets/images/close-btn-white.png" alt="close-btn-white" class="close-btn-white">
          </div> -->
          <img [src]="this.appService.apiRoot+item.ImageUrl" [alt]="item.Title" class="pointer picked-img"
            (click)="chooseImage(item)">
        </div>

        <div class="col-sm-12 col-md-6 col-12 add-new  align-center-webkit" (click)="chooseImage()" *ngIf="isAddNew">
          <div class="border">
            <div class="row border-height">
              <div class="col-md-12 col-12 align-center-top">
                <img src="../../assets/images/default-image-gray.png">
              </div>
              <div class="col-md-12 col-12 align-center custom-text">
                {{'Pick_Image' | translate }}<br> {{'From_Gallery' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isChoosingImage" class="row display-content">
      <div class="col-md-12 col-12">
        <div class="row">
          <!-- <div class="col-md-1 col-12 go-back" (click)="onGoBack()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </div> -->
          <div class="col-md-12 col-12 btn-fl">
            <span
              *ngIf="folderParentage.length > 0 || (currentFolder.ID != emptyGuid && currentFolder.ParentID == emptyGuid)"
              class="is-folder-link" (click)="goRoot()">{{'Gallery' | translate}}</span>
            <span
              *ngIf="folderParentage.length > 0 || (currentFolder.ID != emptyGuid && currentFolder.ParentID == emptyGuid)">
              / </span>
            <span *ngFor="let item of folderParentage; first as isFirst">
              <span class="is-folder-link" (click)="setFolder(item)"
                *ngIf="item.ID != currentFolder.ID && item.ID != emptyGuid">{{item.Name}}</span>
              <span *ngIf="item.ID != currentFolder.ID && item.ID != emptyGuid"> / </span>
            </span>
            <span class="is-current-folder">{{currentFolder.Name}}</span>
          </div>

        </div>
      </div>

      <div class="col-md-12 col-12">
        <div class="row">
          <div *ngFor="let item of dataFiles" class="col-md-3 col-sm-6 col-6 align-center-webkit">
            <ng-container *ngIf="item.Size != '' && item.Size != null && item.Size != undefined">
              <div class="row archive-item image">
                <div class="col-md-12 col-12 align-center is-cursor" (click)="getImage(item)">
                  <img [ngClass]="item.Path == undefined || item.Path == null ? 'file-type-folder': 'file-type' " [src]="getIcon(item.Path)" [alt]="item.Name">
                </div>
                <!-- <div class="col-md-12 col-12 align-center custom-text">
                      {{item.Name}}
                    </div>
                    <div class="col-md-12 col-12 align-center">
                      {{item.Size}}
                    </div> -->

              </div>
            </ng-container>
            <ng-container *ngIf="item.Size == '' || item.Size == null">
              <div class="row archive-item folder">
                <div class="col-md-12 col-12 is-cursor" (click)="setFolder(item)">
                  <div class="row">
                    <div class="col-md-12 col-12 align-center">
                      <img class="file-type-folder" [src]="getIcon(item.Path)" alt="">
                    </div>
                    <div class="col-md-12 col-12 align-center custom-text">
                      {{item.Name}}
                    </div>
                  </div>
                </div>

              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-12" *ngIf="dataFiles.length == 0">
        <div class="row">
          <div class="col-md-12 col-12 align-center">
            Không tìm thấy dữ liệu
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isChoosingImage" class="col-md-12 col-12 btn-dialog-wrapper btn-custom">
    <button type="button" class="btn-dialog btn-save" [ngClass]="!isImageChosen ? 'btn-secondary' : ''"
      (click)="onSaveBanner()" [disabled]="!isImageChosen">
      <span aria-hidden="true">{{'Save_Change' | translate}}</span>
    </button>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info size-dialog">
    <div class="reward-dialog close-div">
      <div class="title-info-req">
        <strong >
          {{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        
      </div>
      <div class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    
    <div class="col-md-12 col-12">
      <div class="row">
        <div class="col-md-12 col-12">
          <div [ngClass]="'control'">
            <label>
              <span [textContent]="'Image_Path' | translate" class="input-label"></span>
              <span class="required">(*)</span>
            </label>
            <input kendoTextBox [ngClass]="(this.selectedItem.DetailPath == null 
            || this.selectedItem.DetailPath =='' ) ? 'err' : ''" 
              [(ngModel)]="this.selectedItem.DetailPath" />
            <!-- <span class="err-text " *ngIf="dataErr[0]['Info'][selectedImgIndex]['DetailPath'] ">{{dataErr[0]['UrlPath']}}</span> -->
            <span class="err-text" 
              *ngIf="this.selectedItem.DetailPath == null 
              || this.selectedItem.DetailPath =='' ">Không được bỏ trống trường này</span>
          </div>
        </div>
  
        <div class="col-md-12 col-12">
          <div [ngClass]="'control'">
            <label>
              <span [textContent]="'Image_Size' | translate" class="input-label"></span>
              <span class="required">(*)</span>
            </label>
            <kendo-dropdownlist [data]="imgSizeList" 
              [textField]="'Name'" [valueField]="'ID'"
              [(ngModel)]="this.selectedItem.Size" [valuePrimitive]="true" [filterable]="false" 
              [ngClass]="(this.selectedItem.Size == null 
              || this.selectedItem.Size =='') ? 'err' : ''">
            </kendo-dropdownlist>
            <span class="err-text" *ngIf="this.selectedItem.Size == null 
            || this.selectedItem.Size =='' ">Không được bỏ trống trường này</span>
          </div>
        </div>

        <div class="col-md-12 col-12" *ngIf="this.selectedItem.Size == SIZE_ADJUST">
          <div [ngClass]="'control'">
            <label>
              <span [textContent]="'Image_Width' | translate" class="input-label"></span>
              <span class="required">(*)</span>
            </label>
            <kendo-numerictextbox
              [min]="1"
              [format]="'n0'"
              [restrictDecimals]="true"
              [(ngModel)]="this.selectedItem.Width"
              [autoCorrect]="true"
              [ngClassHeight]="(this.selectedItem.Width == null 
              || this.selectedItem.Width =='' ) ? 'err' : ''" 
            >
            </kendo-numerictextbox>
            <!-- <input kendoTextBox [ngClass]="(this.selectedItem.Width == null 
            || this.selectedItem.Width =='' ) ? 'err' : ''" 
              [(ngModel)]="this.selectedItem.Width" /> -->
            
            <span class="err-text" 
              *ngIf="this.selectedItem.Width == null 
              || this.selectedItem.Width =='' ">Không được bỏ trống trường này</span>
          </div>
        </div>
        
        <div class="col-md-12 col-12" *ngIf="this.selectedItem.Size == SIZE_ADJUST">
          <div [ngClass]="'control'">
            <label>
              <span [textContent]="'Image_Height' | translate" class="input-label"></span>
              <span class="required">(*)</span>
            </label>
            <kendo-numerictextbox
              [min]="1"
              [format]="'n0'"
              [autoCorrect]="true"
              [restrictDecimals]="true"
              [(ngModel)]="this.selectedItem.Height"
              [ngClassHeight]="(this.selectedItem.Height == null 
              || this.selectedItem.Height =='' ) ? 'err' : ''" 
            >
            </kendo-numerictextbox>
            <!-- <input kendoTextBox [ngClassHeight]="(this.selectedItem.Height == null 
            || this.selectedItem.Height =='' ) ? 'err' : ''" 
              [(ngModel)]="this.selectedItem.Height" /> -->
            <span class="err-text" 
              *ngIf="this.selectedItem.Height == null 
              || this.selectedItem.Height =='' ">Không được bỏ trống trường này</span>
          </div>
        </div>
  
        <div class="col-md-12 col-12">
          <div class="btn-dialog-wrapper">
            <button
              class="btn-dialog btn-save"
              (click)="onSaveImageInfo()"
            >
              <span>{{ "Save" | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </kendo-dialog>
</section>
