<footer class="ver1" *ngIf="isLoaded">
  <div class="footer" *ngIf="footer && footer.Info">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="info">
          <span class="title">
            {{ footer.Info.Title }} &nbsp;
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(footer.Info)"></span>
              </kendo-dropdownbutton>
            </span>
          </span>
          <ul>
            <li>
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              &nbsp; {{ footer.Info.Address }}
              <!-- &nbsp;
              <span *ngIf="isAdminFlg" class="btn-custom">
                <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                  [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                  <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(footer.Info)"></span>
                </kendo-dropdownbutton>
              </span> -->
            </li>
            <li>
              <i class="fa fa-phone" aria-hidden="true"></i>
              &nbsp; {{ footer.Info.Phone }} - {{ 'Fax' | translate }}{{ footer.Info.Fax }}
              <!-- &nbsp;
              <span *ngIf="isAdminFlg" class="btn-custom">
                <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                  [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                  <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(footer.Info)"></span>
                </kendo-dropdownbutton>
              </span> -->
            </li>
            <li>
              <i class="fa fa-envelope" aria-hidden="true"></i>
              &nbsp; {{ footer.Info.Email }}
              <!-- &nbsp;
              <span *ngIf="isAdminFlg" class="btn-custom">
                <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                  [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                  <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(footer.Info)"></span>
                </kendo-dropdownbutton>
              </span> -->
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="menu">
          <ul>
            <li *ngFor="let item of dataMenu">
              <a [routerLink]="[item.PageID? parseURL(item.PageID): (postList +'/'+ item.Url)]">{{item.Name}}</a>
              &nbsp;
              <span *ngIf="isAdminFlg" class="btn-custom">
                <kendo-dropdownbutton class="btn-func btn-custom" [data]="btnDataMenu"
                  [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                  <span class="handle k-icon k-i-more-vertical" (click)="onDataMenuClick(item)"></span>
                </kendo-dropdownbutton>
              </span>
            </li>
            <button *ngIf="isAdminFlg && dataMenu.length<10" class="btn-add" (click)="onOpenAddMenu()">+</button>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div>
      <span>{{ footer.Info.CopyRight }}</span>
    </div>
  </div>
  <kendo-dialog *ngIf="isAddMenu" [draggable]="false" class="add-menu x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span [textContent]="'Menu_List'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label [textContent]="'Menu' | translate"></label>
                <kendo-dropdownlist [data]="menuFilter" [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true"
                  [filterable]="true" (filterChange)="menuHandleFilter($event)" (valueChange)="onChangeMenu($event)"
                  [popupSettings]="{popupClass: popupClass, animate: true}">
                  <ng-template kendoComboBoxNoDataTemplate>{{'NoRecordsAvailable' | translate}}</ng-template>
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
                  <span aria-hidden="true">{{'Save' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
  <kendo-dialog *ngIf="menuOpened || infoOpened" [draggable]="false" class="edit x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span *ngIf="menuOpened" [textContent]="'Menu_List'  | translate"></span>
          <span *ngIf="infoOpened" [textContent]="'Footer_Info_List'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div *ngIf="menuOpened" class="edit-menu">
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Name' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Name" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12" *ngIf="isParentUrl">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Parent_Url' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="parentUrl" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Url">{{dataMenuItem.Url}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Url' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Url" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Url">{{dataMenuItem.Url}}</span> -->
                </div>
              </div>
            </div>
            <div *ngIf="infoOpened" class="edit-info">
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Title' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="footer.Info.Title" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Address' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="footer.Info.Address" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Phone' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="footer.Info.Phone" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Fax' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="footer.Info.Fax" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Email' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="footer.Info.Email" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog('vi-VN')">
                  <span aria-hidden="true">{{'Save' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
</footer>
