import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeDeleteBodyItem = new EventEmitter();
  invokeDropItem = new EventEmitter();
  invokeGetConnectedList = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  onDltBodyItem(index: number, layoutGroupIndex:number, pageBodyIndex: number) {
    this.invokeDeleteBodyItem.emit({index: index, layoutGroupIndex: layoutGroupIndex, pageBodyIndex: pageBodyIndex});
  }

  onDropItem(event: CdkDragDrop<string[]>) {
    this.invokeDropItem.emit(event);
  }

  onGetConnectedList() {
    this.invokeGetConnectedList.emit();
  }
}
