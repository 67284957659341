import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from "../services/app.consts";
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { AppGuid } from '../services/app.guid';
import { AppSwal } from '../services/app.swal';
import { Router } from '@angular/router';


@Component({
  selector: 'app-news-template-16',
  templateUrl: './news-template-16.component.html',
  // styleUrls: ['./news-template-16.component.scss']
})
export class NewsTemplate16Component implements OnInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = "";
  viewMore: string = "";
  isShowMenu = false;
  manageDialog = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = "";
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate3 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  @Output() urlItem = new EventEmitter();
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
    private guid: AppGuid,
    private appSwal: AppSwal,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.bootstrapNum = 12 / this.topNumber;
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.getTopNews();
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate?.GroupID,
      "Layout": this.itemTemplate?.Layout,
      "GroupIndex": this.itemTemplate?.GroupIndex
    }

    this.addGallery();

  }

  async addGallery() {
    const dataRequest = this.createDataRequest(null);
    let newTemp = {Info: {MenuID: this.pageSelectedID},
      pageBodyIndex: this.pageBodyIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      index: this.newsDecorationIndex};
    // this.changeTemplate3.emit(newTemp);
    // const result = await this.appService.doPOST('api/Template', dataRequest);
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    // } else {
    //   if (result.Msg) {
    //     this.appSwal.showWarning(result.Msg, false);
    //   }
    // }
    // this.appComponent.loading = false;
  }

  createDataRequest(data) {

    return {
      ID: this.guid.empty,
      TemplateID: 3,
      PageID: this.pageSelectedID,
      //Name: temp.Name ? temp.Name : null,
      OrderIdx: this.OrderIdx,
      Info: null,
      GroupID: this.itemTemplate?.GroupID,
      Layout: this.itemTemplate?.Layout,
      GroupIndex: this.itemTemplate?.GroupIndex
    };
  }

  async getTopNews() {
    // const result = await this.appService.doGET('api/Portal/GetTopNews', {
    //   langID: this.language.get(),
    // });
    const result = await this.appService.doGET('api/PortalPost/GetByMenuID', {
      langID: this.language.get(),
      menuID: this.infoFromMain ? this.infoFromMain.MenuID : '',
      topNum: this.infoFromMain ? this.infoFromMain.TopNumber : 10,
    });
    if (result && result.Status === 1) {
      // this.postTittle = result.Data.dataMenu.Name;
      // this.viewMore = result.Data.dataMenu.UrlPath;
      this.topNews = result.Data?.topNews;
    }
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;

  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }
  redirectToItem(url: string,item) {
    if (url != null && url != undefined) {
      // this.router.navigate([AppConsts.page.postDetail + "/" + url])
      this.urlItem.emit(item);
    }
  }
  saveComponent() {
    this.addGallery();
    this.isShowMenu = false;
  }

  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(x => x.ID === this.infoFromMain.MenuID);
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  onLoadData(event) {
    console.log('template 3 onloadData');
    console.log(event);
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.MenuID = (event && event.Info) ? event.Info.MenuID : '';
    this.infoFromMain.TopNumber = (event && event.Info) ? event.Info.TopNumber : 10;
    this.getTopNews();
  }

  changeTemp3(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    // this.getTopNews();
    // this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate3.emit(newTemp);
  }
}

