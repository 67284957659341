<header-template5 *ngIf="isLoaded" (window:resize)="onResize($event)">
  <div id="BannerBackGround" class="display-for-background">
    <img [src]="this.appService.apiRoot + this.TemplateDetailsInfo.BackGroundUrl"
      *ngIf="this.TemplateDetailsInfo.BackGroundUrl" alt="" class="background-img" id="HeaderBackgroundImg">
  </div>
  <!-- Thanh Menu -->
  <nav class="nav-menu-box fixed-top" id="nav-menu-box" [ngClass]="openFlg ? 'navbar-display' : 'navbar-hidden'">
    <div class="row nav-bar">
      <div class="padding-0">
        <ul class="nav-content">
          <!-- <div class="navbar-cover-shadow" (click)="open()" *ngIf="openFlg"></div> -->
          <div class="nav-menu" #mother0>
            <div class="close-button-box mobile">
              <mat-icon (click)="open()" class="close-button">close</mat-icon>
            </div>
            <li class="menu-obj level0 dropdown" *ngFor="let item of menus; let i = index" #level0
              (click)="selectMenu($event, mother0, level0, 'level0',item, i)" [id]="item.ID">
              <div *ngIf="(!item.Childrens || item.Childrens.length == 0) && item.Url.toLowerCase() != '/' "
                class="item-link pading-0" [title]="item.Name" (click)="goToMenu(item)" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" [style]="'font-size: ' + innerWidth * 0.009 + 'px'">
                {{ item.Name }}
              </div>
              <div *ngIf="item.Url.toLowerCase() == '/'" class="item-link home-icon-box" [id]="item.ID" (click)="goToMenu(item)">
                <img src="../../assets/images/home-icon-blue.png" alt="" class="home-icon-blue"
                  >
              </div>
              <div *ngIf="item.Childrens?.length > 0" class="item-link pading-0 dropdown-menu"
                [style]="'font-size: ' + innerWidth * 0.009 + 'px'" (click)="goToMenu(item)">
                <div class="menu-name">
                  {{ item.Name }}
                  <!-- &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i> -->
                </div>
                <!-- <div class="menu-name mobile">
                  {{ item.Name }}
                  &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div> -->
                <div class="dropdown-submenu menu-obj level1" [id]="i">
                  <a *ngFor="let child of item.Childrens, let j = index;" class="dropdown-item"
                    (click)="goToSubMenu(child, i,item)" [style]="'font-size: ' + innerWidth * 0.009 + 'px'">
                    {{ child.Name }}
                  </a>
                </div>
              </div>
            </li>
            <div class="search-box">
              <img src="../../assets/images/search-icon.png" alt="" class="icon-search" (click)="showSearchBox()">
            </div>
            <div *ngIf="isShowSearchBox" class="search-box-dropdown">
              <div class=" search">
                <input type="text" class="form-control" [placeholder]="'Search-Text' | translate"
                  [(ngModel)]="searchText" (keypress)="onSearchKeyPress($event)" />
              </div>
            </div>
            <!-- <div class="language">
              <mat-form-field>
                <mat-select [(value)]="languages[0].id">
                  <mat-select-trigger>
                    <span *ngIf="isVi" class="lang-name" [style]="'font-size: ' + innerWidth * 0.009 + 'px'">VI</span>
                    <span *ngIf="!isVi" class="lang-name" [style]="'font-size: ' + innerWidth * 0.009 + 'px'">EN</span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let lang of languages" [value]="lang.id" (click)="changeLanguage(lang.culture)">
                    <img src="{{ lang.img }}" /> &nbsp;
                    <span *ngIf="language.get() == 'vi-VN'">{{lang.viText}}</span>
                    <span *ngIf="language.get() == 'en-US'">{{lang.enText}}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>




        </ul>
      </div>
      <!-- <div class="col-md-1 col-12"></div> -->
    </div>
  </nav>

  <nav class="navbar navbar-expand-lg navbar-light display-for-navbar">
    <img src="../../assets/images/toggle-icon-mobile.svg" class="pointer ic-menu-mb" (click)="isCollapsed = !isCollapsed">
    <img src="{{urlLogo}}" class="img-logo" />
    <div class="collapse navbar-collapse navbar-collapse-custom" [ngbCollapse]="!isCollapsed">
      <ul class="navbar-nav mr-auto">
        <ng-container *ngFor="let item of menus">
          <li class="nav-item" *ngIf="item.Childrens?.length > 0">
            <a [id]="'menu-' + item.ID" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" (click)="toggleChildVisibility(item.ID)">
              {{ item.Name }}
            </a>
            <ul class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'menu-' + item.ID"
              [class.show]="activeMenuId === item.ID">
              <li *ngFor="let child of item.Childrens">
                <a class="dropdown-item pointer" (click)="goToSubMenu(child, i,item)">{{ child.Name }}</a>
              </li>
            </ul>
          </li>
          <li class="nav-item pointer" *ngIf="item.Childrens?.length === 0">
            <a class="nav-link" (click)="goToMenu(item)">{{ item.Name }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>

  <kendo-dialog *ngIf="editFlg && infoOpened" [draggable]="false"
    class="edit x-dialog window-center router-outlet-content">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()"></button>
        <div class="title-info-req">
          <span [textContent]="'Header_Info_List' | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">

          <kendo-tabstrip #tabstrip (tabSelect)="onTabChanged($event)" class="portal-tabstrip">
            <kendo-tabstrip-tab *ngFor="let item of dataLangs ; let i = index " [title]="item.Name"
              [selected]="i == 0 ">
            </kendo-tabstrip-tab>
          </kendo-tabstrip>

          <div class="row">
            <div class="edit-info">
              <div class="col-md-12 col-12 upload-image">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Upload_Avatar_Image' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <kendo-upload [multiple]="false" [showFileList]="true" [saveUrl]="fileSaveUrl"
                    (success)="onSuccessAvatarUpload($event)" [accept]="['.png', '.jpg', '.jpeg']">
                    <kendo-upload-messages [headerStatusUploaded]="'FileUploaded' | translate"
                      [dropFilesHere]="'dropFilesHere' | translate" [select]="'UploadFile' | translate">
                    </kendo-upload-messages>
                  </kendo-upload>
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Title' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="TemplateDetailsInfo.Title" [ngClass]="''" />

                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'SubTitle' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="TemplateDetailsInfo.SubTitle" [ngClass]="''" />

                </div>
              </div>
              <!-- <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Template ID' | translate"></span>

                  </label>
                  <input kendoTextBox [ngClass]="''" />

                </div>
              </div> -->
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveHeader()">
                  <span aria-hidden="true">{{ "Save" | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
</header-template5>
