
<article class="main-page">
  <div class="row">
    <!-- header -->
    <div *ngFor="let item of pageHeader" class="header-box">
      <app-header-template1 *ngIf="item == '1'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
      </app-header-template1>
      <app-header-template2  *ngIf="item == '2'"
        (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
        (menuSearch)="menuSearch($event)">
      </app-header-template2>
      <app-header-template3 *ngIf="item == '3'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
      </app-header-template3>
      <app-header-template4  *ngIf="item == '4'"
        (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
        (menuSearch)="menuSearch($event)"  [templateID]="TemplateID" [pageSelectedID]="PageSelectedID">
      </app-header-template4>
      <app-header-template5  *ngIf="item == '5'"
        (menuItem)="menuItem1($event)" (menuItemchild)="menuItemchild($event)"
        (menuSearch)="menuSearch($event)"  [templateID]="TemplateID" [pageSelectedID]="PageSelectedID"  [bodyWidth]="bodyWidth">
      </app-header-template5>
    </div>
    <!-- header -->
    <!-- <div class="col-md-1 col-12"></div> -->
    <div class="col-md-12 col-12">
      <div class="container-fluid">
        <div class="body-list">
          <div
            class="layout-box"
            *ngFor="let group of pageBody; let bodyIndex = index"
            [style.width.%]="group.layout"
          >
            <div class="layout-list" id="{{ group.id }}">
              <div
                class="body-box"
                [style.width.%]="100"
                *ngFor="let item of group.items; let i = index"
              >
                <app-news-template-1
                  *ngIf="item?.FrameID == 1"
                  [isAdminFlg]="isAdminFlg"
                  [isMore]="isMore"
                  [infoFromMain]="item.Info"
                  [categoriesList]="categoriesList"
                  [templateID]="item.TemplateID"
                  [OrderIdx]="i"
                  (isReloadPageAfterSave)="onReload($event)"
                  [width]="item.Width"
                >
                </app-news-template-1>
                <app-news-template-2
                  *ngIf="item?.FrameID == 2"
                  [isAdminFlg]="false"
                  [infoFromMain]="item.Info"
                  [categoriesList]="categoriesList"
                  [templateID]="item.TemplateID"
                  [OrderIdx]="i"
                  (isReloadPageAfterSave)="onReload($event)"
                  [newsDecorationIndex]="i"
                  [width]="item.Width"
                >
                </app-news-template-2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-12"></div>
    <!-- footer -->
 <!-- footer -->
 <div  style="width: 100%;">
  <div *ngFor="let item of pageFooter" class="header-box">
    <app-footer *ngIf="item == '1'"></app-footer>
    <app-footer2 *ngIf="item == '2'"></app-footer2>
    <app-footer3 *ngIf="item == '3'" (isMoveToTop)="moveToTop()" [isAdminFlg]="false"></app-footer3>
  </div>
</div>
<!-- footer -->
  </div>
</article>
