<article [ngClass]="templateType ==1 ? 'news-template-43' : 'news-template-43-without-time'">
    <div class="container-fluid">
      <div class="row space-box">
        <div class="main-item">
          <div class="row">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title" *ngIf="templateType == 1">
              <p class="uppercase title-text">{{ postTittle }}</p>
              <!-- <div *ngIf="isMore" class="post-more">
                <div class="pointer" (click)="redirectToViewMore()">
                  {{ "View_More" | translate }}
                </div>
              </div> -->
            </div>

            <div class="col-sm-12 col-12 col-md-12 col-xl-12 p-0">
              <div *ngIf="allDescription.length==0" class="no-data">
                Chưa có dữ liệu
              </div>
              <kendo-scrollview #scrollview [endless]="true" [data]="allDescription" [width]="widthSView" [style.height.px]="innerWidth > 768 ? innerWidth*0.31 : innerWidth*1.22"
              [arrows]="true" [pageable]="false" [endless]="true" *ngIf="allDescription.length>0">
                <ng-template let-item="item">
                  <div class="banner row">
                    <div class="col-sm-12 col-12 col-md-7 col-xl-7">
                      <div class="row">
                        <img [src]="item.ImageUrls[1] ? item.ImageUrls[1] : defaultImg" alt="{{ item.Name }}" [style.height.px]="innerWidth > 768 ? innerWidth*0.175 : 0"
                          class="img-scrollview col-sm-12 col-12 col-md-6 col-xl-6 img-1" draggable="false" />

                        <img [src]="item.ImageUrls[2] ? item.ImageUrls[2] : defaultImg" alt="{{ item.Name }}" [style.height.px]="innerWidth > 768 ? innerWidth*0.175 : 0"
                          class="img-scrollview col-sm-12 col-12 col-md-6 col-xl-6 img-2" draggable="false" />

                        <div class="col-sm-12 col-12 col-md-12 col-xl-12 info"
                          [style.height.px]="innerWidth > 768 ? innerWidth*0.13 : innerWidth*0.65" [style.margin-top.px]="innerWidth > 768 ? innerWidth*0.005 : innerWidth*0.01">
                          <img class="info-bg" src="../../assets/images/program_background.png" >
                          <div class="info-content">
                            <div class="menu-title">
                              <span class="uppercase"> {{ item.Name }} </span>
                            </div>
                            <div class="description">
                              <p class="inner-des" [innerHTML]="item.Description">
                                {{ item.Description }}
                              </p>
                            </div>
                            <div class="pointer view-detail" (click)="viewMenu(item)"
                              [style.margin-top.px]="innerWidth > 768 ? innerWidth*0.01 : innerWidth*0.05">
                              {{ "View_Detail" | translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-12 col-md-5 col-xl-5">
                      <img [src]="item.ImageUrls[0] ? item.ImageUrls[0] : defaultImg" alt="{{ item.Name }}" [style.height.px]="innerWidth > 768 ? innerWidth*0.31 : innerWidth*0.5"
                        class="img-scrollview main-img" [style.margin-top.px]="innerWidth > 768 ? 0 : innerWidth*0.01" draggable="false" />
                    </div>
                  </div>
                </ng-template>
              </kendo-scrollview>
            </div>
          </div>
        </div>

        <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                  <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
              </div> -->
      </div>
    </div>
    <kendo-dialog
      *ngIf="manageDialog"
      (mousedown)="$event.stopPropagation()"
      class="router-outlet-content window-center dialog-info dialog-width"
    >
      <div class="news-template-43-dialog close-div">
        <div class="title-info-req">
          <strong *ngIf="editFlg == true && dltFlg == false">{{
            "Gallery_Scrollview_Edit" | translate
          }}</strong>
          <strong *ngIf="editFlg == false && dltFlg == false">{{
            "Gallery_Scrollview_Add" | translate
          }}</strong>
          <strong *ngIf="dltFlg == true">{{
            "Gallery_Scrollview_Delete" | translate
          }}</strong>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img
            class="close-icon"
            src="../../assets/images/close-button.png"
            alt=""
            (click)="onClose($event)"
          />
        </div>
      </div>
      <app-news-template-43-dialog
        *ngIf="editFlg == true"
        [request_AccountID]="request_AccountID"
        [HiddenTab]="HiddenTab"
        (galleryManageOpened)="onClose($event)"
        [galleryData]="galleryData"
        [editFlg]="editFlg"
        [templateID]="templateID"
        [pageSelectedID]="this.pageSelectedID"
        (onSave)="this.onSaveData()"
        (onChangetemp1)="this.changeTemp1($event)"
      >
      </app-news-template-43-dialog>
      <div *ngIf="dltFlg == true">
        <div class="input-label">
          <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
        </div>
        <div>
          <button
            class="btn-delete"
            (click)="onDeleteGallery()"
          >
            <span>{{ "Delete" | translate }}</span>
          </button>
          <button
            class="btn-cancel"
            (click)="onClose($event)"
          >
            <span>{{ "Cancel" | translate }}</span>
          </button>
        </div>
      </div>
    </kendo-dialog>
  </article>
