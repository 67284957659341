import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-template-17',
  templateUrl: './news-template-17.component.html',
  // styleUrls: ['./news-template-17.component.scss']
})
export class NewsTemplate17Component implements OnInit {
  topNews: any;
  // topNumber: number = 3;
  // bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @HostListener('window:resize', ['$event'])
  innerWidth: any;
  innerHeight: any;
  @Input() OrderIdx: any;
  @Input() isPreview;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate2 = new EventEmitter();
  @Output() saveData = new EventEmitter();
  @Output() tittleItem = new EventEmitter();
  // @Output() urlItem = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  postDetailRoute = AppConsts.POST_DETAIL_ROUTE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.bootstrapNum = 12 / this.topNumber;
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.getPostTittle();
    this.getTopNews();
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate ? this.itemTemplate.GroupID : 0,
      "Layout": this.itemTemplate ? this.itemTemplate.Layout : 100,
      "GroupIndex": this.itemTemplate ? this.itemTemplate.GroupIndex : 0
    }
    if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
    }

    // console.log(`pageID from template: ${this.pageSelectedID}`);
    // console.log('item template 2: ');
    // console.log(this.itemTemplate);
  }
  async getTopNews() {
    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        // menuID: '038490DF-8F35-4B08-B5F2-942367AE77B3',
        menuID: this.infoFromMain.MenuID,
        topNum: this.infoFromMain.TopNumber,
      }
    );

    if (result && result.Status === 1) {
      this.postTittle = result.Data.dataMenu.Name;
      this.viewMore = result.Data.dataMenu.UrlPath;
      this.topNews = result.Data.topNews;
    }
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }

  onGetTittleItem(e) {
    this.tittleItem.emit(e);
  }

  // redirectToItem(url: string, item) {
  //   this.onGetTittleItem(item)
  //   if (url != null && url != undefined) {
  //     this.urlItem.emit(item);
  //   }
  // }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
    // this.topNews = new Array(3).fill({key: 'value'});
    // this.postTittle = this.translate.instant('Category_Name');
    // const dataRequest = {
    //   iD: this.templateID,
    //   itemID: this.ID,
    //   index: this.OrderIdx,
    // };
    // const result = await this.appService.doDELETE('api/Template', dataRequest);
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    //   this.isReloadPageAfterSave.emit(true);
    //   location.reload();
    // } else {
    //   this.notification.showSuccess(result.Msg);
    // }
  }

  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  changeTemp2(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    // this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.getTopNews();
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate2.emit(newTemp);
  }

  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }

  onSaveData() {
    this.saveData.emit();
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }
}
