<header-template2 *ngIf="isLoaded">
  <div class="nav-top">
    <div class="contact">
      <div class="row row-height">
        <div class="col-sm-12 col-md-8 col-12"></div>
        <div class="close-button-box col-1 mobile">
          <!-- <i class="fa fa-bars close-button" aria-hidden="true" (click)="open()"></i> -->
          <mat-icon (click)="open()" class="close-button">subject</mat-icon>
        </div>
        <div class="col-sm-10 col-md-2 col-8 search">
          <input type="text" class="form-control" [placeholder]="'Search-Text' | translate" [(ngModel)]="searchText"
            (keypress)="onSearchKeyPress($event)" />
        </div>
        <div class="col-sm-2 col-md-2 col-3 language">
          <mat-form-field>
            <mat-select [(value)]="languages[0].id">
              <mat-select-trigger>
                <img *ngIf="language.get() == 'vi-VN'" [src]="languages[0].img" />
                <img *ngIf="language.get() == 'en-US'" [src]="languages[1].img" />
              </mat-select-trigger>
              <mat-option *ngFor="let lang of languages" [value]="lang.id" (click)="changeLanguage(lang.culture)">
                <img src="{{ lang.img }}" /> &nbsp;
                <span *ngIf="language.get() == 'vi-VN'">{{lang.viText}}</span>
                <span *ngIf="language.get() == 'en-US'">{{lang.enText}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-1 col-12"></div>
      </div>
    </div>
  </div>
  <!-- Banner Top -->
  <div id="BannerBackGround" class="">
    <div class="banner">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 desktop">
          <div class="row">
            <!-- <div class="col-md-1 col-12"></div> -->
            <div class="col-sm-2 col-md-1 col-1 logo-container">
              <img class="img1" [src]="appService.apiRoot + TemplateDetailsInfo.ImageUrl" />
            </div>
            <div class="col-sm-8 col-md-9 col-8 name">
              <hgroup>
                <div class="name-main">
                  <a [routerLink]="['/']" (click)="goToRoot()"> {{ TemplateDetailsInfo?.Title }}</a>
                  <span *ngIf="isAdminFlg" class="btn-custom">
                    <kendo-dropdownbutton class="btn-custom edit-logo" [data]="btnHeader" [popupSettings]="{
                        popupClass: popupClass,
                        animate: true,
                        appendTo: 'component'
                      }">
                      <span class="material-icons" (click)="onClickFunction(TemplateDetailsInfo)">more_vert</span>
                    </kendo-dropdownbutton>
                  </span>
                </div>
                <br />
                <div class="name-detail">
                  <a [routerLink]="['/']" (click)="goToRoot()"> {{ TemplateDetailsInfo?.SubTitle }}</a>
                </div>
              </hgroup>
            </div>
            <div *ngIf="isLogged" class="col-sm-2 col-md-2 col-1 user">
              <!-- <button class="login-btn">Đăng nhập</button> -->
              <div class="change-btn">
                <img src="../../assets/images/flower-icon.png" />
              </div>
              <div class="dropdown">
                <div class="avatar">
                  <img [src]="appService.apiRoot + user.FilePath" />
                </div>
                <div class="dropdown-bg">
                  <div class="dropdown-content">
                    <a [routerLink]="['/user-info']" routerLinkActive="active" class="user-info">
                      <div class="avatar">
                        <img [src]="appService.apiRoot + user.FilePath" />
                      </div>
                      <div class="col">
                        <div class="role">{{user.FullName}}</div>
                        <button class="btn-edit-info">
                          {{ "Edit" | translate }}
                        </button>
                      </div>
                    </a>
                    <a [routerLink]="['/settings']" routerLinkActive="active"><i class="fa fa-cog"></i> {{ "Settings" |
                      translate }}</a>
                    <a (click)="onSignout()"><i class="fa fa-sign-out"></i> {{ "Logout" | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isLogged" class="col-sm-2 col-md-1 col-1 login">
              <!-- <button class="login-btn">Đăng nhập</button> -->
              <button class="login-btn" [routerLink]="['/login']" routerLinkActive="active">
                {{ "Login" | translate }}
              </button>
            </div>
            <div class="col-md-1 col-12"></div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 mobile">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-sm-2 col-md-1 col-12 logo-container">
              <img class="img1" [src]="appService.apiRoot + TemplateDetailsInfo.ImageUrl" />
            </div>
            <div class="col-sm-8 col-md-8 col-11 name">
              <hgroup>
                <div class="name-main">
                  <a [routerLink]="['/']" (click)="goToRoot()"> {{ TemplateDetailsInfo?.Title }}</a>
                  <span *ngIf="isAdminFlg" class="btn-custom">
                    <kendo-dropdownbutton class="btn-custom edit-logo" [data]="btnHeader" [popupSettings]="{
                      popupClass: popupClass,
                      animate: true,
                      appendTo: 'component'
                    }">
                      <span class="material-icons" (click)="onClickFunction(TemplateDetailsInfo)">more_vert</span>
                    </kendo-dropdownbutton>
                  </span>
                  <br />
                </div>

                <div class="name-detail">
                  <a [routerLink]="['/']" (click)="goToRoot()"> {{ TemplateDetailsInfo?.SubTitle }}</a>
                </div>
              </hgroup>
            </div>
            <div *ngIf="isLogged" class="col-sm-2 col-md-1 col-12 user">
              <!-- <button class="login-btn">Đăng nhập</button> -->
              <div class="change-btn">
                <img src="../../assets/images/flower-icon.png" />
              </div>
              <div class="dropdown">
                <div class="avatar">
                  <img [src]="user.FilePath" />
                </div>
                <div class="dropdown-bg">
                  <div class="dropdown-content">
                    <a [routerLink]="['/user-info']" routerLinkActive="active" class="user-info">
                      <div class="avatar">
                        <img [src]="user.FilePath" />
                      </div>
                      <div class="col">
                        <div class="role">{{user.FullName}}</div>
                        <button class="btn-edit-info">
                          {{ "Edit" | translate }}
                        </button>
                      </div>
                    </a>
                    <a [routerLink]="['/settings']" routerLinkActive="active"><i class="fa fa-cog"></i> {{ "Settings" |
                      translate }}</a>
                    <a (click)="onSignout()"><i class="fa fa-sign-out"></i> {{ "Logout" | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isLogged" class="col-md-1 col-12 login">
              <!-- <button class="login-btn">Đăng nhập</button> -->
              <button class="login-btn" [routerLink]="['/login']" routerLinkActive="active">
                {{ "Login" | translate }}
              </button>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- Thanh Menu -->
  <nav class="nav-menu-box">
    <div class="row nav-bar">
      <!-- <div class="col-md-1 col-12"></div>
      <div class="col-md-10 col-12"> -->
        <ul class="nav-content padding-0">
          <!-- <div class="navbar-cover-shadow" (click)="open()" *ngIf="openFlg"></div> -->
          <div class="nav-menu" [ngClass]="openFlg ? 'navbar-display' : 'navbar-hidden'" #mother0>
            <div class="close-button-box mobile">
              <mat-icon (click)="open()" class="close-button">close</mat-icon>
            </div>
            <li class="menu-obj level0 dropdown" *ngFor="let item of menus; let i = index" #level0
              (click)="selectMenu($event, mother0, level0, 'level0',item, i)" [id]="item.ID">
              <a *ngIf="!item.Childrens || item.Childrens.length == 0" class="item-link pading-0" [title]="item.Name"
                (click)="goToMenu(item)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ item.Name }}
              </a>
              <div *ngIf="item.Childrens?.length > 0" class="item-link pading-0 dropdown-menu" (click)="goToMenu(item)">
                <div  class="menu-name">
                  {{ item.Name }}
                  &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
                <!-- <div class="menu-name mobile">
                  {{ item.Name }}
                  &nbsp; <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div> -->
                <div class="dropdown-submenu menu-obj level1" [id]="i">
                  <a *ngFor="let child of item.Childrens, let i = index;" class="dropdown-item"
                    (click)="goToSubMenu(child, i,item)">
                    {{ child.Name }}
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
      <!-- </div>
      <div class="col-md-1 col-12"></div> -->
    </div>
  </nav>
  <kendo-dialog *ngIf="editFlg && infoOpened" [draggable]="false"
    class="edit x-dialog window-center router-outlet-content">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()"></button>
        <div class="title-info-req">
          <span [textContent]="'Header_Info_List' | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">

          <kendo-tabstrip #tabstrip (tabSelect)="onTabChanged($event)" class="portal-tabstrip">
            <kendo-tabstrip-tab *ngFor="let item of dataLangs ; let i = index " [title]="item.Name"
              [selected]="i == 0 ">
            </kendo-tabstrip-tab>
          </kendo-tabstrip>

          <div class="row">
            <div class="edit-info">
              <div class="col-md-12 col-12 upload-image">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Upload_Avatar_Image' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <kendo-upload [multiple]="false" [showFileList]="true" [saveUrl]="fileSaveUrl"
                    (success)="onSuccessAvatarUpload($event)" [accept]="['.png', '.jpg', '.jpeg']">
                    <kendo-upload-messages [headerStatusUploaded]="'FileUploaded' | translate"
                      [dropFilesHere]="'dropFilesHere' | translate" [select]="'UploadFile' | translate">
                    </kendo-upload-messages>
                  </kendo-upload>
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Title' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="TemplateDetailsInfo.Title" [ngClass]="''" />

                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'SubTitle' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="TemplateDetailsInfo.SubTitle" [ngClass]="''" />

                </div>
              </div>
              <!-- <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Template ID' | translate"></span>

                  </label>
                  <input kendoTextBox [ngClass]="''" />

                </div>
              </div> -->
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveHeader()">
                  <span aria-hidden="true">{{ "Save" | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
</header-template2>