<article
  class="news-template-47"
  (window:resize)="onResize($event)"
  *ngIf="isShowButton"
>
  <div class="container-fluid">
    <div class="row fixed-item" #scrollMe>
      <div class="main-item">
        <div class="row">
          <div
            *ngIf="isAdminFlg"
            class="vertical-dot"
            [ngClass]="isShowMenu ? 'd-flex' : ''"
          >
            <div
              class="three-dot-area k-icon k-i-more-vertical"
              (click)="enableChoice()"
            ></div>
            <div class="menu" *ngIf="isShowMenu">
              <!-- <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{'Edit' | translate}}</div>
              </div> -->
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{ "Delete" | translate }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div class="row">
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 top-new-item circles">
                <div class="i circle1"></div>

                <button (blur)="onBlur()" class="button" (click)="handleHotline()">
                  <img
                    class="post-image"
                    src="../../assets/images/phone-1.svg"
                    alt=""
                  />
                </button>
                <div class="pop-up pop-up-hotline" *ngIf="openedHotline == true">
                  <div class="pop-up-title">
                    <span>Gọi ngay cho chúng tôi!</span>
                    <span class="close-button" (click)="handleHotline()">
                      <img src="../../assets/images/close-btn-white.png" alt="">
                    </span>
                  </div>
                  <div class="pop-up-content">
                    <img src="../../assets/images/hotline.svg" alt="" class="pop-up-image">
                    <div class="hotline">
                      <div>Hotline</div>
                      <div class="gray-text">08 39225666</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 top-new-item circles-1">
                <div class="i circle1-1"></div>
                <button (blur)="onBlur()" class="button" (click)="handleEmail()">
                  <img
                    class="post-image"
                    src="../../assets/images/email-1.svg"
                    alt=""
                  />
                </button>
                <div class="pop-up pop-up-email" *ngIf="openedEmail == true">
                  <div class="pop-up-title">
                    <span>Liên hệ ngay với chúng tôi!</span>
                    <span class="close-button" (click)="handleEmail()">
                      <img src="../../assets/images/close-btn-white.png" alt="">
                    </span>
                  </div>
                  <div class="pop-up-content">
                    <img src="../../assets/images/email.svg" alt="" class="pop-up-image">
                    <div class="hotline">
                      <div>Email</div>
                      <div class="gray-text">hoiytecongcongtphcm@gmail.com</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog
    *ngIf="manageDialog"
    (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info"
  >
    <div class="news-template-47-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
        }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
        }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close-button.png"
          alt=""
          (click)="onClose($event)"
        />
      </div>
    </div>
    <app-news-template-47-dialog
      *ngIf="editFlg == true"
      [request_AccountID]="request_AccountID"
      [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)"
      [galleryData]="galleryData"
      [editFlg]="editFlg"
      [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID"
      (onSave)="this.onSaveData()"
      (onChangetemp2)="this.changeTemp2($event)"
    >
    </app-news-template-47-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span
          [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"
        ></span>
      </div>
      <div class="button-group">
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
