<section class="content-document router-outlet-content">
    <br>
    <div class="col-md-9 col-12 col-sm-12">
        <div class="">
            <kendo-panelbar [expandMode]="kendoPanelBarExpandMode.Single">
                <kendo-panelbar-item [title]="('SearchDocument_DocumentInfo' | translate) + ' ' + (tempCode? tempCode: '')" [expanded]="true">
                    <ng-template kendoPanelBarContent>
                        <!-- <div class="template-info">
                        <div class="div-info">
                            <br> -->
                        <div class="col-md-12 col-xs-12 col-sm-12  col-lg-12">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_CvPro5Group' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <input kendoTextBox [(ngModel)]="dataGridItem.CvPro5GroupName" [disabled]="true" />
                                        <!-- <span class="err-text " *ngIf="dataErr[0]['Document.CvPro5GroupName'] ">{{dataErr[0]['Document.CvPro5GroupName']
                                                    }}</span> -->
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_FullName' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <input [ngClass]="dataErr[0]['Document.FullName'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.FullName" [disabled]="!UpdateFlg" />
                                        <span class="err-text " *ngIf="dataErr[0]['Document.FullName'] ">{{dataErr[0]['Document.FullName']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 col-sm-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_DateOfBirth' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <kendo-datepicker [max]="maxBirthDay" #dateModel="ngModel" [placeholder]="''" [(ngModel)]="dataGridItem.DateOfBirth" [format]="'FormatDate' | translate" [ngClass]="dataErr[0]['Document.DateOfBirth'] ? 'err' : ''" [disabled]="!UpdateFlg" [formatPlaceholder]="{
                                            year: 'year' | translate, month: 'month'| translate , day: 'day' | translate,
                                            hour: 'hour' | translate, minute: 'minute' | translate, second: 'second'
                                            | translate }">
                                            <kendo-datepicker-messages [today]="'today' | translate" [toggle]="'toggle' | translate">
                                            </kendo-datepicker-messages>
                                        </kendo-datepicker>
                                        <span class="err-text " *ngIf="dataErr[0]['Document.DateOfBirth'] ">{{dataErr[0]['Document.DateOfBirth']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 col-sm-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_PlaceOfBirth' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <input [ngClass]="dataErr[0]['Document.PlaceOfBirth'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.PlaceOfBirth" [disabled]="!UpdateFlg" />
                                        <span class="err-text " *ngIf="dataErr[0]['Document.PlaceOfBirth'] ">{{dataErr[0]['Document.PlaceOfBirth']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 col-sm-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_Gender' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <kendo-combobox [ngClass]="dataErr[0]['Document.Gender'] ? 'err' : ''" [data]="genderFilter" [textField]="'Name'" [valueField]="'ID'" [(ngModel)]="dataGridItem.Gender" [valuePrimitive]="true" [filterable]="true" (filterChange)="genderHandleFilter($event)"
                                            [disabled]="!UpdateFlg">
                                            <ng-template kendoDropDownListNoDataTemplate>{{'NoRecords' | translate}}</ng-template>
                                        </kendo-combobox>
                                        <span class="err-text " *ngIf="dataErr[0]['Document.Gender'] ">{{dataErr[0]['Document.Gender']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 col-sm-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_IDCardNumber' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <input [ngClass]="dataErr[0]['Document.IDCardNumber'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.IDCardNumber" [disabled]="!UpdateFlg" />
                                        <span class="err-text " *ngIf="dataErr[0]['Document.IDCardNumber'] ">{{dataErr[0]['Document.IDCardNumber']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 col-sm-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_Nationality' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <kendo-combobox [ngClass]="dataErr[0]['Document.NationalID'] ? 'err' : ''" [data]="nationalityFilter" [textField]="'Name'" [valueField]="'ID'" [(ngModel)]="dataGridItem.NationalID" [valuePrimitive]="true" [filterable]="true" (filterChange)="nationalityHandleFilter($event)"
                                            [disabled]="!UpdateFlg">
                                            <ng-template kendoDropDownListNoDataTemplate>{{'NoRecords' | translate}}</ng-template>
                                        </kendo-combobox>
                                        <span class="err-text " *ngIf="dataErr[0]['Document.NationalID'] ">{{dataErr[0]['Document.NationalID']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12 col-sm-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_Job' | translate"></span>
                                                    <span> :</span>
                                                </label>
                                        <input [ngClass]="dataErr[0]['Document.Job'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Job" [disabled]="!UpdateFlg" />
                                        <span class="err-text " *ngIf="dataErr[0]['Document.Job'] ">{{dataErr[0]['Document.Job']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_Address' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <textarea kendoTextArea [ngClass]="dataErr[0]['Document.Address'] ? 'err' : ''" rows="2" [(ngModel)]="dataGridItem.Address" [disabled]="!UpdateFlg"></textarea>
                                        <span class="err-text " *ngIf="dataErr[0]['Document.Address'] ">{{dataErr[0]['Document.Address']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_Phone' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <input (keypress)="numberOnly($event)" maxlength="12" [ngClass]="dataErr[0]['Document.Phone'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Phone" [disabled]="!UpdateFlg" />
                                        <span class="err-text " *ngIf="dataErr[0]['Document.Phone'] ">{{dataErr[0]['Document.Phone']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_Email' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> :</span>
                                                </label>
                                        <input [ngClass]="dataErr[0]['Document.Email'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Email" [disabled]="!UpdateFlg" />
                                        <span class="err-text " *ngIf="dataErr[0]['Document.Email'] ">{{dataErr[0]['Document.Email']}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12  PayButton-div">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_TypesOfPaper' | translate"></span>
                                                    <span class="required">*</span>
                                                    <span> : </span>
                                                    <span class="red-status">{{' ' + ('UnprocessedDocument_NoteFile' | translate)}}</span>
                                                </label>
                                                <figure>
                                                <table width="100%" >
                                                    <thead>
                                                        <tr>
                                                            <th>{{'UnprocessedDocument_OrderIdx' | translate}}</th>
                                                            <th>{{'UnprocessedDocument_Paper_Type_Name' | translate}}</th>
                                                            <th>{{'UnprocessedDocument_Attachments' | translate}}</th>
                                                            <th>{{'UnprocessedDocument_Status' | translate}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let dataItem of dataCvPro5s ; let i = index">
                                                            <td>{{i +1}}</td>
                                                            <td>{{dataItem.Name}}</td>
                                                            <td >
                                                                <div class="button-div" >
                                                                    <label>
                                                                                <kendo-upload hidden="true" [accept]="['.png','.jpg','.jpeg', '.pdf']"
                                                                                    [(ngModel)]="filesUpload" (select)="onSelectFileToUpload($event,i)"
                                                                                    (remove)="onRemoveFileDocToUpload()" [multiple]="true"
                                                                                    [showFileList]="false" [saveUrl]="fileSaveUrl"
                                                                                    (success)="onSuccessFileToUpload($event)">
                                                                                    <kendo-upload-messages [headerStatusUploaded]="'FileUploaded' | translate"
                                                                                        [dropFilesHere]="'dropFilesHere' | translate"
                                                                                        [select]="'UploadFile' | translate">
                                                                                    </kendo-upload-messages>
                                                                                </kendo-upload>
                                                            
                                                                               
                                                                                    <ng-container *ngIf="dataItem.UploadUrl && (!UpdateFlg || (flgSave >= 1))">
                                                                                        <a [href]="getUrlDownload(dataItem.UploadUrl)" [target]="_blank">
                                                                                            <span class="link-up" >{{ getFileName(dataItem.UploadUrl) }}</span>
                                                                                        </a>
                                                                                    </ng-container>
                                                                                    <span class="link-up" *ngIf="UpdateFlg && (1 > flgSave)"  > {{ dataItem.UploadUrl ? getFileName(dataItem.UploadUrl) :('Upload_File' | translate) }}</span>
                                                                               
                                                                            </label>
                                                                        </div>
                
                
                    
                                                            </td>
                                                            <td>

                                                                    <ng-container *ngIf="dataItem.Status == 1; else elseBlock">
                                                                        <div class="icon-format">
                                                                            <img class="icon-format" src="../../../../assets/images/check2.png" alt="ImageChecked">
                
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #elseBlock>
                                                                        <div class="icon-format">
                                                                            <img class="icon-format" src="../../../../assets/images/remove.png" alt="ImageUnChecked">
                                                                        </div>
                                                                    </ng-template>
                                              
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="!dataCvPro5s"> <td class="no-record"  colspan="4"> {{'NoRecordsAvailable' | translate}}</td></tr>
                                                    </tbody>
                                                </table>
                                            </figure>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12" *ngIf="flgSave == 1 ||flgSave == 2 || dataGridItem.Status == 1 ||  dataGridItem.Status == 2 || dataGridItem.Status == 3  || dataGridItem.Status == 7">
                                    <div [ngClass]="'control'">
                                        <label>
                                                    <span [textContent]="'UnprocessedDocument_ReturnDate' | translate"  ></span>
                                                    <span> :</span>
                                                </label>
                                        <kendo-datetimepicker #dateModel="ngModel" [placeholder]="''" [(ngModel)]="dataGridItem.ReturnDate" [format]="'dateTimeFormat' | translate" [disabled]="true" [ngClass]="dataErr[0]['Document.ReturnDate'] ? 'err' : ''" [formatPlaceholder]="{
                                            year: 'year' | translate, month: 'month'| translate , day: 'day' | translate,
                                            hour: 'hour' | translate, minute: 'minute' | translate, second: 'second'
                                            | translate }">
                                            <kendo-datetimepicker-messages [today]="'today' | translate" [toggle]="'toggle' | translate" [dateTab]="'date' | translate" [dateTabLabel]="'dateView' | translate" [timeTab]="'time' | translate" [timeTabLabel]="'timeView' | translate" [now]="'now' | translate"
                                                [nowLabel]="'now' | translate" [accept]="'accept' | translate" [acceptLabel]="'selectTime' | translate" [cancel]="'cancel' | translate" [cancelLabel]="'cancelTime' | translate">
                                            </kendo-datetimepicker-messages>
                                        </kendo-datetimepicker>
                                        <span class="err-text " *ngIf="dataErr[0]['Document.ReturnDate'] ">{{dataErr[0]['Document.ReturnDate']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12" *ngIf="flgSave == 1 ||flgSave == 2 || dataGridItem.Status == 1 ||  dataGridItem.Status == 2 || dataGridItem.Status == 3  || dataGridItem.Status == 7">
                                    <div [ngClass]="'control'">
                                        <label>
                                                  <span [textContent]="'UnprocessedDocument_Fee' | translate"></span>
                                                  <span> : </span>
                                                  <span class="red-status">{{' ' +('UnprocessedDocument_NoteFee' | translate)}}</span>
                                              </label>
                                        <kendo-numerictextbox format="n0" [autoCorrect]="true" [min]="0" [max]="999999999999999999" [ngClass]="dataErr[0]['Document.Fee'] ? 'err' : ''" [ngModel]="dataGridItem.Fee" (ngModelChange)="dataGridItem.Fee = $event" [disabled]="true">
                                        </kendo-numerictextbox>
                                        <span class="err-text " *ngIf="dataErr[0]['Document.Fee'] ">{{dataErr[0]['Document.Fee']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12" *ngIf="flgSave == 2">
                                    <div [ngClass]="'control'">
                                        <label>
                                                <span [textContent]="'Code_OTP' | translate"></span>
                                                <span class="required">*</span>
                                                <span> :</span>
                                                <span class="red-status"> {{'Input_OTP' | translate}} </span>
                                            </label>
                                        <input [ngClass]="dataErr[0][ 'OTP'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.OTP" />
                                        <span class="err-text " *ngIf="dataErr[0][ 'OTP'] ">{{dataErr[0]['OTP']}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div *ngIf="dataGridItem?.Status ==2 ||dataGridItem?.Status == 3" class="content-document PayButton-div">
                                        <button class="btn-search" (click)="onOpenPayment()">
                                                    {{ 'UnprocessedDocument_Pay' | translate }}
                                                </button>
                                    </div>

                                    <div *ngIf="dataGridItem?.Status == 6 || dataGridItem?.Status == 4 ">
                                        <button class="btn-search-content btn-ticket-appointment" (click)="onOpenAppointment()">
                                                    {{ 'Appointment_Ticket' | translate }}
                                                </button>
                                    </div>

                                    <div *ngIf="dataGridItem?.Status == 6 || dataGridItem?.Status == 4">
                                        <button class="btn-search-content btn-cancel-document" (click)="onOpenCancelDocument()">
                                                    {{ 'Appointment_Cancel' | translate }}
                                                </button>
                                    </div>

                                    <div class="content-document button-div">
                                        <button class="btn-search" (click)="onContinue()" [hidden]="!UpdateFlg ||flgSave == 2">
                                                    {{ (!loadingScan ? 'UnprocessedDocument_Continue' : 'UnprocessedDocument_Scan')| translate }}
                                                </button>

                                        <button class="btn-search" (click)="onSaveGrid()" [hidden]="flgSave != 2">
                                                    {{ 'DocumentStatus_Complete' | translate }}
                                                </button>
                                    </div>
                                    <div *ngIf="dataGridItem?.Status == 8" class="PayButton-div">
                                        <button class="btn-delete" (click)="onOpenDeleteDocument()">{{ 'Delete_Document' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                        <!-- </div> -->
                    </ng-template>
                </kendo-panelbar-item>
            </kendo-panelbar>
        </div>
        <!-- <kendo-dialog *ngIf="isSuccess" class="content-document x-dialog-success window-center dialog-chat " [draggable]="true">
            <div class="employe-detail">
                <div class="x-close-div">
                    <div class="title-info-req">
                        <strong>{{'Confirm_Register_Portal'| translate}}</strong>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <div class="row">

                                <div class="col-md-12 col-12 text-center">
                                    <div [ngClass]="'control'">
                                        <label>
                                        <span [textContent]="'Confirm_Register_Portal_Detail' | translate">:</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12">
                                    <div class="btn-dialog-wrapper">
                                        <button class="btn-dialog btn-close-success" (click)="onCloseSuccess()">
                                        {{ 'Close_Box' | translate }}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label><strong> {{('Document_status'| translate)+': '}}</strong></label>
                <span *ngIf="dataGridItem?.Status == 8" class="err-text"> {{('The_document_was_canceled' | translate)}}</span>
            </div>
        </kendo-dialog> -->
        <div>
            <span *ngIf="dataGridItem?.Status == 1"><strong> {{'Reason' | translate}}</strong></span> <span *ngIf="dataGridItem?.Status == 1" class="err-text"> {{dataGridItem.Note}}</span>
            <label *ngIf="dataGridItem?.Status == 8" ><strong> {{('Document_status'| translate)+': '}}</strong></label>
            <span *ngIf="dataGridItem?.Status == 8" class="err-text"> {{('The_document_was_canceled' | translate)}}</span>
        </div>

        <app-horizontal-timeline [DocumentStatus]="DocumentStatusHozTimeline"></app-horizontal-timeline>
    </div>

    <kendo-dialog *ngIf="isPaying" class="content-document window-center dialog-chat " [draggable]="true" [ngClass]="{'x-dialog-payment': selectedPaymentMethod, 'x-dialog-payment-0': !selectedPaymentMethod}">
        <div class="employe-detail">
            <div class="x-close-div">
                <div class="title-info-req">
                    <span>{{'Paying_Register_Portal'| translate}}</span>
                </div>
                    <button type="button" class="xbutton-dialog close" aria-label="Close" [title]="'Close_Box' | translate"
        (click)="onClosePayingInfo()">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="row">

                        <div class="col-md-12 col-12">
                            <div [ngClass]="'control'">
                                <label class="mar-bot-20">
                                        <span [textContent]="'Paying_Register_Portal_Type' | translate">:</span>
                                    </label>
                                <div class="row div-method">
                                    <div [ngClass]="{'selected-payment': selectedPaymentMethod != null && item.ID == selectedPaymentMethod.ID}" class="method-border" *ngFor="let item of paymentMethod" (click)="choosePaymentMethod(item)">
                                        {{item.Name}}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="selectedPaymentMethod != null" class="col-md-12 col-12 div-icon">
                            <div [ngClass]="'control'">
                                <img [src]="getIcon()" alt="" class="icon-img">
                            </div>
                        </div>

                        <div class="col-md-12 col-12 payment-div">
                            <div [ngClass]="'control'">
                                <label>
                                        <span [textContent]="'Paying_Register_Portal_Total' | translate"></span>
                                        <span class="is-fee-dot">: </span>
                                        <span class="is-fee">{{numberWithCommas(dataGridItem.Fee)}}</span>
                                    </label>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="btn-dialog-wrapper">
                                <button class="btn-dialog btn-pay-success" (click)="onPay()">
                                        {{ 'DocumentStatus_Pay' | translate }}
                                    </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </kendo-dialog>

    <!-- Phiếu hẹn -->
    <kendo-dialog *ngIf="openAppointment" class="content-document x-dialog window-center " [draggable]="false">
        <div class="employe-detail">
            <div class="x-close-div">
                <button type="button" class="xbutton-dialog close" aria-label="Close" (click)="onCloseAppointment()">
                    <span aria-hidden="true">&times;</span>
                </button>  
                <div class="title-info-req">
                    <span [textContent]="'Appointment_BNG'  | translate"></span>
                </div>
                <div class="title-info-req">
                    <span [textContent]="'Appointment_SNV'  | translate"></span>
                </div>
                <div class="title-info-req appointment-label">
                    <span [textContent]="'Appointment_Card'  | translate"></span>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="row">

                        <div class="col-md-12 col-12">
                            <div class="orderidx">
                                {{dataGridItem.OrderIdx}}
                            </div>
                        </div>

                        <div class="col-md-12 col-12">
                            <span [textContent]="'Appointment_ReturnDate'  | translate"></span>
                            <div class="content-label">
                                {{dataGridItem.ReturnDate | date: "HH:mm dd/MM/yyyy" }}
                            </div>
                        </div>

                        <div class="col-md-12 col-12">
                            <span [textContent]="'Appointment_FullName'  | translate"></span>
                            <div class="content-label">
                                {{dataGridItem.FullName}}
                            </div>
                        </div>

                        <div class="col-md-12 col-12">
                            <span [textContent]="'Appointment_Gender' | translate"></span>
                            <div class="content-label">
                                {{getNameGender(dataGridItem.Gender)}}
                            </div>
                        </div>

                        <div class="col-md-12 col-12">
                            <span [textContent]="'Appointment_Address' | translate"></span>
                            <div class="content-label">
                                {{dataGridItem.Address}}
                            </div>
                        </div>

                        <div class="col-md-12 col-12">
                            <span [textContent]="'Appointment_Fee' | translate"></span>
                            <div class="content-label content-label-hr">
                                {{dataGridItem.Fee | number: 0:n0}}
                            </div>
                            <hr>
                        </div>



                        <div class="col-md-12 col-12">
                            <span [textContent]="'Appointment_Code' | translate"></span>
                            <div class="content-label ">
                                {{dataGridItem.Code}}
                            </div>

                        </div>

                        <div class="col-md-12 col-12 center-me">
                            <qr-code [value]="dataGridItem.Code" [size]="150"></qr-code>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </kendo-dialog>
    <!-- Hết phiếu hẹn -->

    <!-- Dialog Huỷ đăng ký/ Xóa hồ sơ -->
    <kendo-dialog *ngIf="openCancelDocument || openDeleteDocument " class="cancel-appointment x-dialog-cancel window-center " [draggable]="false">
        <div class="employe-detail">
            <div class="col-md-12 col-12  ">
                <div class="center-me cancel-label ">
                    <span [hidden]="!openCancelDocument" [textContent]="'CancelAppointment_CancelAppointment'  | translate"></span>
                    <span [hidden]="!openDeleteDocument" [textContent]="'DeleteDocument_DeleteDocument'  | translate"></span>
                </div>
                <div class="center-me">
                    <span [hidden]="!openCancelDocument" [textContent]="'CancelAppointment_Decision'  | translate"></span>
                    <span [hidden]="!openDeleteDocument" [textContent]="'DeleteDocument_Decision'  | translate"></span>
                </div>
                <div class="center-me areyousure-label">
                    <span [hidden]="!openCancelDocument" [textContent]="'CancelAppointment_AreYouSure'  | translate"></span>
                    <span [hidden]="!openDeleteDocument" [textContent]="'DeleteDocument_AreYouSure'  | translate"></span>
                </div>


            </div>

            <div class="col-md-12 col-12">
                <div class="row">

                    <div class="col-md-6 col-6">
                        <button [hidden]="!openCancelDocument" class="btn-search-content btn-cancel" (click)="onCloseCancelDocument()">
                            {{ 'Exit' | translate }}
                        </button>
                        <button [hidden]="!openDeleteDocument" class="btn-search-content btn-cancel" (click)="onCloseDeleteDocument()">
                            {{ 'Exit' | translate }}
                            </button>
                    </div>

                    <div class="col-md-6 col-6">
                        <button [hidden]="!openCancelDocument" class="btn-search-content btn-accept-cancel" (click)="onCancelDocument()">
                            {{ 'Confirm' | translate }}
                        </button>
                        <button [hidden]="!openDeleteDocument" class="btn-search-content btn-accept-cancel" (click)="onDeleteDocument()">
                            {{ 'Confirm' | translate }}
                            </button>
                    </div>

                </div>
            </div>

        </div>
    </kendo-dialog>
</section>