<article class="news-template-16">
  <div class="container-fluid">
    <div class="row">
      <div class="main-item">
        <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">

          <p class="uppercase">{{ postTittle }}</p>

        </div>
        <div class="row">

          <div class="col-sm-12 col-12 col-md-12 col-xl-12 top-news-list">

            <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="top-new-item">
              <div *ngFor="let item of topNews; let i = index"
              [routerLink]="['post-detail/' + item.UrlPath]"
                class="pointer">
                <section class="text-section">
                  <div class="icon-clock k-icon k-i-arrow-60-right"></div>
                  <p class="title" *ngIf="item.Title">{{ item.Title }}</p>
                  <p class="title" *ngIf="!item.Title"></p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width">
    <div class="news-template-16-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-16-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg"
      (onSave)="this.onLoadData($event)" (onChangetemp3)="this.changeTemp3($event)">
    </app-news-template-16-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
