<article class="news-template-19" (window:resize)="onResize($event)">
  <div class="container-fluid">
    <div class="row">
      <div class="main-item">
        <div class="row">
          <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
            <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
            <div class="menu" *ngIf="isShowMenu">
              <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{'Edit' | translate}}</div>
              </div>
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{'Delete' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">
            <div class="row">
              <div class="col-sm-12 col-12 col-md-6 news-title">
                <p class="uppercase">{{'New_Post' | translate}}</p>
              </div>
              <div class="col-sm-12 col-12 col-md-6">
                <div>
                  <div class="arrow-box">
                    <img class="arrow-icon-left" src="../../assets/images/arrow-left.png" alt="arrow-image" (click)="leftClick()"/>
                    <img class="arrow-icon" src="../../assets/images/arrow-right.png" alt="arrow-image" (click)="rightClick()"/>
                  </div>
                  <div class="title-right" id="title-right">              
                    <div class="category" id="category">
                      <div *ngFor="let item of categoriesListFiltered; let i = index" class="category-box">
                        <p class="category-name" [attr.id]="'category' + i" (click)="onSelectedCategory(i)">{{ item.Name }}</p>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
            
            
              
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div class="row" *ngIf="innerWidth>768">
              <div class="col-sm-12 col-12 col-md-6 col-xl-6 pointer top-new-first-item">
                <div *ngFor="let item of topNews; let i = index" [routerLink]="[this.postDetailRoute + '/' + item.UrlPath]" class="left-box">
                  <section *ngIf="i == 0" class="section-box">
                    <div class="row">
                      <div class="col-sm-12 col-12 col-md-12 col-xl-12">   
                        <figure [ngClass]="
                            item.ImageUrl ? 'img-figure' : 'img-figure-no-img'
                          ">
                          <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image-cover"
                            [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                          <img *ngIf="item.DefaultFlg == true" class="first-post-image-default" src="../../assets/images/default.png"
                            alt="post-image" />
                        </figure>
                      </div>
                      <div class="col-sm-12 col-12 col-md-12 col-xl-12">
                        
                        <p class="title-first" *ngIf="item.Title">
                          {{ item.Title }}
                        </p>
                        <p class="title-first" *ngIf="!item.Title"></p>
                        <div>
                          <div class="time-clock">        
                            <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd/MM/yyyy HH:mm' }}</p>
                            <p class="time-text" *ngIf="!item.CreateAt"></p>
                          </div>
                          <p class="new-detail">
                            {{ item.Summary}}
                          </p>
                        </div>
                        <!-- <div>
                          <button
                            class="btn-detail"
                            (click)="onDeleteGallery()"
                          >
                            <span class="detail-btn">{{ "Detail" | translate }}</span>
                            <img class="icon-arrow" src="../../assets/images/arrow-icon.png" alt="icon-arrow" />
                          </button>
                        </div> -->
                        
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <div class="col-sm-12 col-12 col-md-6 col-xl-6 top-new-item">
                <div class="post-list">
                  <div
                    [className]="
                      'col-sm-12' +
                      ' col-12' +
                      ' col-md-12' +
                      ' pointer top-new-item'
                    "
                    *ngFor="let item of otherNews; let i = index"
                    [routerLink]="[this.postDetailRoute + '/' + item.UrlPath]" routerLinkActive="router-link-active"
                  >
                    <section class="row top-part">
                      <div class="col-6">
                        <figure class="img-figure" [ngClass]="setH()">
                          <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-right-image" [src]="appService.apiRoot + item.ImageUrl" alt="post-image"/>
                          <img *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined" class="post-image-default" 
                            src="../../assets/images/default.png" alt="post-image"/>
                        </figure>
                      </div>
                      <div class="col-6 right-item-detail">
                        <p class="title-text" *ngIf="item.Title">
                          {{
                            item.Title.length > 100
                              ? item.Title.slice(0, 100) + "..."
                              : item.Title
                          }}
                        </p>
                        <p class="title" *ngIf="!item.Title"></p>
                        <div class="time-clock">        
                          <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd/MM/yyyy HH:mm' }}</p>
                          <p class="time-text" *ngIf="!item.CreateAt"></p>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="collumn" *ngIf="innerWidth<=768">
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 pointer top-new-first-item">
                <div *ngFor="let item of topNews; let i = index" [routerLink]="[this.postDetailRoute + '/' + item.UrlPath]">
                  <section *ngIf="i == 0" class="section-box">
                    <div class="row">
                      <div class="col-sm-12 col-12 col-md-12 col-xl-6">   
                        <figure [ngClass]="
                            item.ImageUrl ? 'img-figure' : 'img-figure-no-img'
                          ">
                          <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image-cover"
                            [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                          <img *ngIf="item.DefaultFlg == true" class="first-post-image-default" src="../../assets/images/default.png"
                            alt="post-image" />
                        </figure>
                      </div>
                      <div class="col-sm-12 col-12 col-md-12 col-xl-6">
                        <p class="title-first" *ngIf="item.Title">
                          {{ item.Title }}
                        </p>
                        <p class="title-first" *ngIf="!item.Title"></p>
                        <div class="time-clock">        
                          <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd/MM/yyyy HH:mm' }}</p>
                          <p class="time-text" *ngIf="!item.CreateAt"></p>
                        </div>
                        <div>
                          <p class="new-detail">
                            {{ item.Summary}}
                          </p>
                        </div>
                        <!-- <div>
                          <button
                            class="btn-detail"
                            (click)="onDeleteGallery()"
                          >
                            <span class="detail-btn">{{ "Detail" | translate }}</span>
                            <img class="icon-arrow" src="../../assets/images/arrow-icon.png" alt="icon-arrow" />
                          </button>
                        </div> -->
                        
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <div class="col-sm-12 col-12 col-md-12 col-xl-12 top-new-item">
                <div class="post-list">
                  <div
                    [className]="
                      'col-sm-12' +
                      ' col-12' +
                      ' col-md-12' +
                      ' pointer top-new-item'
                    "
                    *ngFor="let item of otherNews; let i = index"
                    [routerLink]="[this.postDetailRoute + '/' + item.UrlPath]" routerLinkActive="router-link-active"
                  >
                    <section class="top-part">
                      <figure class="img-figure" [ngClass]="setH()">
                        <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image" [src]="appService.apiRoot + item.ImageUrl" alt="post-image"/>
                        <img *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined" class="post-image-default" 
                          src="../../assets/images/default.png" alt="post-image"/>
                      </figure>
                      <!-- <div class="line"></div> -->
                      <p class="title-text" *ngIf="item.Title">
                        {{
                          item.Title.length > 100
                            ? item.Title.slice(0, 100) + "..."
                            : item.Title
                        }}
                      </p>
                      <p class="title" *ngIf="!item.Title"></p>
                      <div class="time-clock">        
                        <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd/MM/yyyy HH:mm' }}</p>
                        <p class="time-text" *ngIf="!item.CreateAt"></p>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
            </div> -->
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-19-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-19-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)" [selectedMenuID]="selectedCategoryId">
    </app-news-template-19-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div class="button-group">
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
