<div class="login-page">
  <div class="panel-login col-xl-4 col-lg-5 col-md-6 col-sm-10 col-10">
    <div class="sub-item">
      <img src="/assets/images/logo-sg-tourist.png" class="logo" alt="logo" />
    </div>
    <div [ngClass]="{'mb-3': true, 'login-failed-error': !loginFailed.isHide}">
      <div *ngIf="!loginFailed.isHide">
        {{ loginFailed.message }}
      </div>
    </div>
    
    <div class="sub-item">
      <div class="mb-2">
        <div
          class="text-input"
          [ngClass]="{'invalid': invalidField.UserName, 'focus': focus===0}"
        >
          <label class="input">
            <input
              class="input__field"
              [(ngModel)]="loginInfo.UserName"
              autocomplete="off"
              (keypress)="onKeyPress($event)"
              id="UserName"
              (keyup)="onKeyUpUserName()"
              type="text"
              (focus)="onFocus(0)" (focusout)="onFocus(-1)"
              placeholder=" "
            />
            <span class="input__label">{{ "UserName" | translate }}</span>
          </label>
          <div class="action">
            <i
              *ngIf="isShowCleanText"
              class="fa fa-times-circle-o"
              aria-hidden="true"
              (click)="cleanUserNameTxt()"
            ></i>
          </div>
        </div>
        <div class="error-text">
          {{ invalidField.UserName ? ("UserName_Required" | translate) : ""}}
        </div>
      </div>
      <div class="mb-2">
        <div
          class="text-input"
          [ngClass]="{'invalid': invalidField.Password, 'focus': focus===1}"
        >
          <label class="input">
            <input
              class="input__field"
              [type]="type"
              [(ngModel)]="loginInfo.Password"
              autocomplete="new-password"
              id="Password"
              readonly
              (focus)="onFocus(1)" (focusout)="onFocus(-1)"
              (keypress)="onKeyPress($event)"
              placeholder=" "
              #passwordInput
            />
            <span class="input__label">{{ "Password" | translate }}</span>
          </label>
          <div class="action" *ngIf="!isEdge">
            <button (click)="onShowPass()" class="btn-showpass">
              <i
                class="fa fa-eye-slash"
                aria-hidden="true"
                *ngIf="type == 'password'"
              ></i>
              <i
                class="fa fa-eye"
                aria-hidden="true"
                *ngIf="type == 'text'"
              ></i>
            </button>
          </div>
        </div>
        <div class="error-text">
          {{ invalidField.Password ? ("Password_Required" | translate) : ""}}
        </div>
      </div>
    </div>
    <div class="sub-item">
      <button (click)="onLogin()" class="login-button">
        <span *ngIf="!loading">{{ "Login_Button" | translate }}</span>
        <div *ngIf="loading" class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-center remember">
          <input type="checkbox" name="remember" id="remember" [(ngModel)]="remember"/>
          <label for="remember">{{ "Remember_Me" | translate }}</label>
        </div>
        <div class="forgot-pwd" [routerLink]="['/forgot-password']" routerLinkActive="router-link-active" >{{ "Forgot_Pass" | translate }}?</div>
      </div>
    </div>
    <div class="contact">
      {{ "Dont_Have_Account" | translate }}? 
        <a class="contact-link" [routerLink]="['/signup']" routerLinkActive="router-link-active">
          {{ "Contact" | translate }}
        </a>
    </div>
  </div>
</div>
