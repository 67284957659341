import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vision-mission',
  templateUrl: './vision-mission.component.html',
})
export class VisionMissionComponent implements OnInit {
  @Input() menuItem: any
  constructor() { }

  ngOnInit(): void {
  }

}
