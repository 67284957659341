import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
// import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
// import { NullInjector } from '@angular/core/src/di/injector';
import {
  FileRestrictions,
  SelectEvent,
  ClearEvent,
  RemoveEvent,
  FileInfo,
} from '@progress/kendo-angular-upload';
// import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-data-menu',
  templateUrl: './data-menu.component.html',
})
export class DataMenuComponent implements OnInit, OnDestroy {
  @Input() navMenu: any;
  @Output() closeDialogAddExcel = new EventEmitter();
  @Output() onCheckChange = new EventEmitter();

  user: any;
  popupClass = 'popup-width';
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;
  dataGrids: Array<any> = [];
  // dataGridSelectableSettings: SelectableSettings;
  dataGridSort = {
    allowUnsort: true,
    mode: 'multiple',
  };
  public dataGridFocus = {
    Name: true,
  };
  dataGridSortByField: SortDescriptor[] = [];

  // dataGridSkip = this.appConsts.pageSkip;
  // dataGridPageSize = this.appConsts.pageSize;
  dataGridSelection: number[] = [];
  dataGridItem: any;
  dataLangs = [];
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  selectedLangID = AppConsts.defaultLangID;
  currentLang: any;
  customCss = 0;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataGridState: State = {
    // skip: this.dataGridSkip,
    // take: this.dataGridSkip + this.dataGridPageSize,
    filter: {
      logic: 'and',
      filters: [],
    },
    group: [{ field: 'ParentName' }],
  };
  // dataGridGridDataResult: GridDataResult;

  dataItems: Array<{ Name: string; ID: string }>;
  dataItemsFilter: Array<{ Name: string; ID: string }>;

  public disabled = false;
  public enabledID = false;
  embed = '0';
  control: any;
  controlDefault = true;
  allowMulti = false;
  infoOpened = false;

  searchOption = {
    SearchText: '',
  };
  dataErr: any;

  pageName = '';
  roles: Array<{ Name: string; ID: string }>;
  rolesFilter: Array<{ Name: string; ID: string }>;
  dataMenu: any;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts,
    private activatedRoute: ActivatedRoute
  ) {
    this.getParam();
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.get4Type();
    this.getPageParent();
    this.getdataItemsParent();
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.getControl();
    this.setDefault();
    this.language.default();
    // this.setSelectableSettings();
    this.onReload();
    this.allData = this.allData.bind(this);
    this.GetAllLang();
    this.currentLang = this.language.get();
  }
  getParam() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.embed = params['embed'] ? params['embed'] : '0';
      if (params['embed'] && params['embed'] == '1') {
        // console.log("embed da nhan", this.embed);
        document.getElementById('homecontainer').style.display = 'none';
      }
    });
  }

  async getControl() {
    this.control = await this.appControls.getControls(
      this.user.RoleID,
      AppConsts.page.dataMenu
    );
    this.controlDefault = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    var id1,id2;
    id1 = this.dataGrids[event.previousIndex].ID;
    id2 = this.dataGrids[event.currentIndex].ID;
    this.UpdateOrderIdx(id1,event.previousIndex,event.currentIndex)

    moveItemInArray(this.dataGrids, event.previousIndex, event.currentIndex);

  }

  async UpdateOrderIdx(id1,idx1,idx2) {
    this.loading = true;
    const dataRequest = {
      ID: id1,
      OrderIdx:idx1,
      OrderIdxNew: idx2,
    };

    const result = await this.appService.doPOST('api/Menu/UpdateOrderIdx', dataRequest);
    if(result){
      this.getdataItems();
    }else {
      this.appSwal.showWarning(result.Msg, false);
    }

    this.loading = false;
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextRemove(e: any) {
    this.searchOption.SearchText = '';
    this.onSearch();
  }

  dataItemsHandleFilter(value) {
    this.dataItemsFilter = this.dataItems.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  parentHandleFilter(value) {
    this.parentPagesFilter = this.parentPages.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  async getdataItems() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      Langid: this.language.get(),
    };

    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result) {
      // this.dataItemsFilter = result.Data.slice();
      this.dataGrids = result.Data;
      this.dataMenu = result.Data;
      this.onCheckChange.emit(this.dataMenu);
      this.binddataItems();
      this.dataItems = [];
      result.Data.forEach((item) => {
        this.dataItems.push(item);
      });
    }

    this.loading = false;
  }
  check(item) {
    //  console.log(item);
    let checkedIndex = this.dataMenu.findIndex((e) => e.ID === item.ID);
    // this.dataMenu[checkedIndex].checked = !this.dataMenu[checkedIndex].checked;
    // this.onCheckChange.emit(this.dataMenu);
    if(this.dataMenu[checkedIndex].MenuFlg == true){
      // console.log('this.dataMenu[checkedIndex].MenuFlg',this.dataMenu[checkedIndex].MenuFlg);
      this.dataMenu[checkedIndex].MenuFlg = false;
      this.updateGridMenuFlg(this.dataMenu[checkedIndex].ID);
    }
    else{
      this.dataMenu[checkedIndex].MenuFlg = true;
      this.updateGridMenuFlg(this.dataMenu[checkedIndex].ID);
    }
    if (this.dataMenu[checkedIndex].MenuFlg === null) {
      this.dataMenu[checkedIndex].MenuFlg = true;
    } else {
      this.dataMenu[checkedIndex].MenuFlg =
        !this.dataMenu[checkedIndex].MenuFlg;
    }

    //console.log(checkedIndex)
    this.updateGrid(this.dataMenu[checkedIndex].ID);
    // this.navMenu = this.dataMenu.filter(x => x.MenuFlg == true);
    if (this.dataMenu[checkedIndex].MenuFlg === true) {
      // this.navMenu.shift(this.dataMenu[checkedIndex]);
      this.navMenu.splice(checkedIndex, 0, this.dataMenu[checkedIndex]);
    } else if (this.dataMenu[checkedIndex].MenuFlg === false) {
      var deleteMenu = this.navMenu.findIndex(
        (e) => e.ID == this.dataMenu[checkedIndex].ID
      );
      this.navMenu.splice(deleteMenu, 1);
    }
    //console.log(this.navMenu)
    // this.onCheckChange.emit(this.dataMenu[checkedIndex]);

    // for (let i = 0; i <= this.dataMenu; i++) {
    //   console.log("A");
    //   console.log(this.dataMenu[i]);
    //   if (this.dataMenu[i].MenuFlg == true) {
    //     var item = document.getElementById(this.dataMenu[i].ID) as HTMLInputElement | null;
    //     if (item != null) {
    //       item.checked = true;
    //     }
    //   }
    // }
    // console.log('2',item);
  }
  async getdataItemsParent() {
    this.loading = true;
    const dataRequest = {
      searchText: '',
      Langid: this.language.get(),
    };

    const result = await this.appService.doGET('api/Menu', dataRequest);
    if (result) {
      this.dataMenu = result.Data;
      this.dataItemsFilter = result.Data.slice();
      // this.check();
    }
    this.loading = false;
  }

  setDefault() {
    this.dataGridItem = {
      IsAdd: true,
      ID: this.guid.empty,
      ParentID: null,
      Name: null,
      MenuFlg: true,
      ButtonFlg: false,
      OrderIdx: 0,
      DelFlg: false,
      UrlPath: '',
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    this.dataGridSelection = [];
    this.disabled = false;
    this.enabledID = true;
  }
  // onGridPageChange(event: PageChangeEvent) {
  //   this.dataGridSkip = event.skip;
  //   this.binddataItems();
  // }
  ondataItemselectedKeysChange() {}

  binddataItems() {
    // this.dataGridGridDataResult = {
    //   data: orderBy(this.dataGrids, this.dataGridSortByField),
    //   total: this.dataGrids.length
    // };
    // this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  ondataItemsortChange(sort: SortDescriptor[]): void {
    this.dataGridSortByField = sort;
    this.binddataItems();
  }

  // public onGridDataStateChange(state: DataStateChangeEvent): void {
  //   this.dataGridSelection = [];
  //   this.dataGridState = state;
  //   this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  // }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getdataItems();
  }

  // async initDisplay() {
  //   const resultRole = await this.appService.doGET('api/Role', null);
  //   if (resultRole && resultRole.Status === 1) {
  //     this.roles = resultRole.Data;
  //     this.rolesFilter = this.roles.slice();
  //   }
  // }

  onSearch() {
    this.getdataItems();
  }

  onReload() {
    //this.searchOption.SearchText = '';
    this.getdataItemsParent();
    this.getdataItems();
  }

  onClearGrid() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    // this.setSelectableSettings();
  }

  onAddNewGrid() {
    this.setDefault();
    this.infoOpened = true;
  }

  onSaveGrid() {
    if (this.dataGridItem.IsAdd) {
      this.addGrid();
    } else {
      this.updateGrid();
    }
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataGridItem;
    var roles = temp.Roles;
    if (typeof temp.Roles == 'object') {
      roles = JSON.stringify(temp.Roles);
    }

    return {
      ID: temp.ID,
      Name: temp.Name,
      ParentID: temp.ParentID,
      PageID: temp.PageID,
      MenuFlg: temp.MenuFlg,
      HomeFlg: temp.HomeFlg,
      MobileFlg: temp.MobileFlg,
      HomeOrderIdx: temp.HomeOrderIdx,
      HomeDisplayType: temp.HomeDisplayType,
      ButtonFlg: temp.ButtonFlg,
      DelFlg: temp.DelFlg,
      OrderIdx: temp.OrderIdx,
      OrderIdxInPost: temp.OrderIdxInPost,
      WidthBanner: temp.WidthBanner,
      HeightBanner: temp.HeightBanner,
      UrlPath: temp.UrlPath,
      LangID: this.selectedLangID,
      Roles: roles,
    };
  }

  onCloseGrid(status: any) {
    this.disabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async onEditGrid(dataItem: any) {
    // this.selectedLangID = this.appConsts.defaultLangID;
    // this.language.set(this.selectedLangID);
    await this.getDataItemByID(dataItem.ID);
  }

  async getDataItemByID(id: any) {
    const dataRequest = {
      iD: id,
      langID: this.selectedLangID
    };

    const result = await this.appService.doGET('api/Menu', dataRequest);
    if (result && result.Status === 1) {
      this.dataGridItem = result.Data;
      this.dataGridItem.MenuID = result.Data.ID;

      if (typeof this.dataGridItem.Roles == 'string') {
        var arr = [];
        try {
          arr = JSON.parse(this.dataGridItem.Roles);
        } catch (e) {
          arr.push(this.dataGridItem.Roles);
        }
        this.dataGridItem.Roles = arr;
      }
      this.disabled = false;
      this.enabledID = false;
      this.infoOpened = true;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async addGrid() {
    // this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Menu', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onCloseInfo();
      this.onReload();
      // this.onAddNewGrid();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
        var count = 0;
        for (var prop in this.dataErr[0]) {
          count++;
        }
        this.customCss = count;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }
  async updateGrid(id?) {
    // this.appComponent.loading = true;
    var iD;
    var dataRequest;
    if (id) {
      iD = id;
      let checkedIndex = this.dataMenu.findIndex((e) => e.ID === id);
      dataRequest = this.dataMenu[checkedIndex];
    } else {
      iD = this.dataGridItem.ID;
      dataRequest = this.createDataRequest(null);
    }

    const result = await this.appService.doPUT('api/Menu', dataRequest, {iD: iD});
    if (result && result.Status === 1) {
      if (!id) {
        this.notification.showSuccess(result.Msg);
      }
      this.onCloseInfo();
      this.onReload();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
        var count = -1;
        for (var prop in this.dataErr[0]) {
          count++;
        }
        this.customCss = count;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }
  async updateGridMenuFlg(id?) {
    // this.appComponent.loading = true;
    var iD;
    var dataRequest;
    if (id) {
      iD = id;
      let checkedIndex = this.dataMenu.findIndex((e) => e.ID === id);
      dataRequest = this.dataMenu[checkedIndex];
    } else {
      iD = this.dataGridItem.ID;
      dataRequest = this.createDataRequest(null);
    }

    const result = await this.appService.doPUT('api/Menu/UpdateMenuFlg', dataRequest, {iD: iD});
    if (result && result.Status === 1) {
      if (!id) {
        this.notification.showSuccess(result.Msg);
      }
      this.onCloseInfo();
      this.onReload();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
        var count = -1;
        for (var prop in this.dataErr[0]) {
          count++;
        }
        this.customCss = count;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  checkErr() {
    if (this.customCss == 1) {
      return 'x-dialog-unit-contact-err-one';
    } else if (this.customCss == 2) {
      return 'x-dialog-unit-contact-err-two';
    } else if (this.customCss == 3) {
      return 'x-dialog-unit-contact-err-three';
    } else if (this.customCss == 4) {
      return 'x-dialog-unit-contact-err-four';
    } else {
      return 'x-dialog-unit-contact';
    }
  }

  async onDeleteGrid(dataItem: any) {
    // this.appComponent.loading = true;
    const dataRequest = {
      iD: dataItem.ID,
      // langID: this.selectedLangID
    };

    const option = await this.appSwal.showWarning(
      this.translate.instant('AreYouSure'),
      true
    );
    if (option) {
      const result = await this.appService.doDELETE('api/Menu', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        let checkedIndex = this.dataMenu.findIndex((e) => e.ID === dataItem.ID);
        var deleteMenu = this.navMenu.findIndex(
          (e) => e.ID == this.dataMenu[checkedIndex].ID
        );
        this.navMenu.splice(deleteMenu, 1);
        this.onReload();
        this.onCloseInfo();
        this.dataGridSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataGrids, {}).data,
    };

    return result;
  }

  onCloseInfo() {
    this.infoOpened = false;
    this.setDefault();
    // this.selectedLangID = this.appConsts.defaultLangID;
    // this.selectedLangID = localStorage.getItem('currentLanguage');
    this.language.set(this.currentLang);
  }

  onChangeFunction(e, dataItem) {
    if (e.id == 'Edit') {
      this.onEditGrid(dataItem);
    } else if (e.id == 'Delete') {
      this.onDeleteGrid(dataItem);
    }
  }
  onFunctionIconClick(dataItem) {
    this.getbtnFunctionData(dataItem);
  }

  getbtnFunctionData(dataItem) {
    this.btnFunctionData = [];
    this.btnMbFunctionData = [];
    //if (this.controlDefault || this.control.Edit) {
    var func1 = {
      text: this.translate.instant('Edit'),
      id: 'Edit',
      // icon: 'pencil',
    };
    this.btnFunctionData.push(func1);
    this.btnMbFunctionData.push(func1);

    //}

    // if (this.controlDefault || this.control.Delete) {
    var func3 = {
      text: this.translate.instant('Delete_Page'),
      id: 'Delete',
      // icon: 'delete',
    };
    this.btnFunctionData.push(func3);
    this.btnMbFunctionData.push(func3);

    // }
  }

  async GetAllLang() {
    this.loading = true;

    const result = await this.appService.doGET('api/Lang/GetAllLang', null);
    if (result) {
      this.dataLangs = result.Data;
      var viLang = this.dataLangs.findIndex((x) => x.ID == 'vi-VN');
      if (viLang != 0 && viLang != -1) {
        this.dataLangs.splice(viLang, 1);
        this.dataLangs.unshift({
          ID: 'vi-VN',
          Name: 'Tiếng Việt',
        });
      }
    }
    this.loading = false;
  }

  onChangeLang(langID) {
    // this.selectedLangID = langID;
    var tempItem = {
      IsAdd: true,
      ID: this.guid.empty,
      Address: '',
      Name: '',
      Description: '',
      Phone: '',
      UpdateAt: null,
      Type: null,
      UrlPath: '',
    };
    var errTemp = Object.assign({}, tempItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.getDataItemByID(this.dataGridItem.ID);
    // this.language.set(this.selectedLangID);
  }

  Types: Array<{ Name: string; ID: number }>;
  TypesFilter: Array<{ Name: string; ID: number }>;
  get4Type() {
    var a = [];
    a[0] = this.translate.instant('TypeHomepage0');
    a[1] = this.translate.instant('TypeHomepage1');
    a[2] = this.translate.instant('TypeHomepage2');
    a[3] = this.translate.instant('TypeHomepage3');
    a[4] = this.translate.instant('TypeHomepage4');
    this.Types = [];
    for (let i = 0; i < 5; i++) {
      this.Types.push({
        ID: i,
        Name: a[i],
      });
    }
    this.TypesFilter = this.Types.slice();
    this.loading = false;
  }

  parentPages: Array<{ Name: string; ID: string }>;
  parentPagesFilter: Array<{ Name: string; ID: string }>;

  async getPageParent() {
    const dataRequest = {
      searchText: ''
    };
    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result && result.Status === 1) {
      this.parentPages = [];
      this.parentPages.push({
        ID: null,
        Name: 'Root',
      });

      // result.Data.forEach(item => {
      //   if (!item.ParentID) {
      //     this.parentPages.push(item);
      //   }
      // });

      this.parentPagesFilter = result.Data; //this.parentPages.slice();
    }
  }
  parentPagesHandleFilter(value) {
    this.parentPagesFilter = this.parentPages.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  public dataMenuFocus = {
    Name: true,
  };
  menuNameChange(e: any) {
    if (e) {
      this.dataGridItem.UrlPath = this.cleanAccents(e);
    }
  }
  cleanAccents(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    str = str.replace(/[!&\/\\#,+()$~%.'":*?<>{} ]/g, '-');
    str = str.trim();
    return str;
  }

  rolesHandleFilter(value) {
    this.rolesFilter = this.roles.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  onCloseDialogAddExcel() {
    this.closeDialogAddExcel.emit();
  }
  onClickMenuFlg(item) {
    this.check(item);
  }
}
