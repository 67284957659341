import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { AppConsts } from 'src/app/services/app.consts';
import { Notification } from 'src/app/services/app.notification';

@Component({
  selector: 'app-enter-mail-form',
  templateUrl: './enter-mail-form.component.html',
  // styleUrls: ['./enter-mail-form.component.scss']
})
export class EnterMailFormComponent implements OnInit {

  public isShowCleanText = false;
  loading = false;
  isAlert = false;
  Email = '';
  public focus = {
    Email: false,
  };
  public requestFailed = {
    isHide: true,
    message: null,
  };
  public value: Date = new Date(2000, 2, 10);
  invalidField = {
    Email: {
      status: false,
      message: '',
    },
  };
  constructor(private title: Title,
    private meta: Meta,
    private translate: TranslateService,
    private appService: AppService,
    private router: Router,
    private notification: Notification) { }

  ngOnInit(): void {
  }
  onKeyPress(e: any) {
    if (e.target.id === 'email') {
      this.invalidField.Email.status = false;
    }
    if (e.keyCode === 13) {
      this.onForgotPassword();
    }
  }
  async onForgotPassword() {
    const isValid = this.checkValidate();
    var dataRequest = {
      Email: this.Email,
    };
    if (isValid) {
      this.loading = true;
      const result = await this.appService.doPOST(
        'api/User/SendSecureCode',
        dataRequest
      );
      this.loading = false;
      localStorage.setItem("AccountID", result.Data.AccountID)
      if (result.Status === 1) {
        this.router.navigate([AppConsts.page.forgotPassword + '/' + AppConsts.page.resetPassword]);
        this.notification.showSuccess("Mã xác thực đã được gửi về email của bạn.")
      } else {
        this.requestFailed = {
          isHide: false,
          message: result.Msg,
        };
        setTimeout(() => {
          this.requestFailed.isHide = true;
        }, 2000);
      }
    }
  }
  checkValidate(): boolean {
    let isValid = true;
    if (!this.Email || this.Email == '') {
      this.invalidField.Email.status = true;
      this.invalidField.Email.message =
        this.translate.instant('Email_Required');
      isValid = false;
    } else if (!this.validateEmail(this.Email)) {
      this.invalidField.Email.status = true;
      this.invalidField.Email.message = this.translate.instant(
        'Contact_Invalid_Email'
      );
      isValid = false;
    }
    return isValid;
  }
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }
  cleanEmailTxt() {
    this.Email = '';
    this.isShowCleanText = false;
  }
  onKeyUpUserName(event): void {
    this.checkemailLength();
  }

  checkemailLength(): void {
    if (this.Email.length === 0) {
      this.isShowCleanText = false;
    } else {
      this.isShowCleanText = true;
    }
  }
  onFocus(type) {
    if (type == 0) {
      this.focus.Email = true;
    }
  }
  onFocusOut(type) {
    if (type == 0) {
      this.focus.Email = false;
    }
  }

}
