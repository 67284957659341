<section class="layout80">
  <!-- <div class="row"
  cdkDropList
  [cdkDropListData]="templates"
  id="layoutList"
  class="layout-list"
  (cdkDropListDropped)="drop($event)">
    <div class="layout-block-left" cdkDrag [cdkDragData]="templates[0]">
      <app-news-template-1 *ngIf="templates[0] != null"
        [ID]="templates[0].ID"
        [isAdminFlg]="true"
        [infoFromMain]="templates[0]?.Info"
        [categoriesList]="categoriesList"
        [templateID]="templates[0]?.ID"
        [OrderIdx]="idx"
        [width]="templates[0]?.Width"
        (isReloadPageAfterSave)="onReload($event)"
      >
      </app-news-template-1>

      <app-news-template-2 *ngIf="templates[0] != null"
        [ID]="templates[0].ID"
        [isAdminFlg]="true"
        [infoFromMain]="templates[0]?.Info"
        [categoriesList]="categoriesList"
        [templateID]="templatetemplates[0]?.ID"
        [OrderIdx]="idx"
        (isReloadPageAfterSave)="onReload($event)"
        [newsDecorationIndex]="idx"
      >
      </app-news-template-2>
    </div>
    <div class="layout-block-right" cdkDrag [cdkDragData]="templates[1]">
      <app-news-template-1 *ngIf="templates[1] != null"
        [ID]="templates[1].ID"
        [isAdminFlg]="true"
        [infoFromMain]="templates[1]?.Info"
        [categoriesList]="categoriesList"
        [templateID]="templates[1]?.ID"
        [OrderIdx]="idx"
        [width]="templates[1]?.Width"
        (isReloadPageAfterSave)="onReload($event)"
      >
      </app-news-template-1>

      <app-news-template-2 *ngIf="templates[1] != null"
        [ID]="templates[1].ID"
        [isAdminFlg]="true"
        [infoFromMain]="templates[1]?.Info"
        [categoriesList]="categoriesList"
        [templateID]="templates[1]?.ID"
        [OrderIdx]="idx"
        (isReloadPageAfterSave)="onReload($event)"
        [newsDecorationIndex]="idx"
      >
      </app-news-template-2>
    </div>
  </div> -->
  <div
    cdkDropList
    class="layout-list"
    #layoutList="cdkDropList"
    id="{{ group.id }}"
    [cdkDropListData]="group.items"
    (cdkDropListDropped)="dropItem($event)"
    [cdkDropListConnectedTo]="getConnectedList()"
  >
    <div
      class="body-box"
      *ngFor="let item of group.items"
      cdkDrag
      [cdkDragData]="item"
    >
      <app-news-template-1
        [ID]="item.ID"
        [isAdminFlg]="true"
        [infoFromMain]="item?.Info"
        [categoriesList]="categoriesList"
        [templateID]="item?.ID"
        [OrderIdx]="i"
        [width]="item?.Width"
        (isReloadPageAfterSave)="onReload($event)"
      >
      </app-news-template-1>

      <app-news-template-2
        [ID]="item.ID"
        [isAdminFlg]="true"
        [infoFromMain]="item?.Info"
        [categoriesList]="categoriesList"
        [templateID]="item?.ID"
        [OrderIdx]="i"
        (isReloadPageAfterSave)="onReload($event)"
        [newsDecorationIndex]="i"
      >
      </app-news-template-2>
    </div>
  </div>
</section>
