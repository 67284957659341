<article class="news-template-21">
  <div class="container-fluid">
    <div class="row scroll-area">
      <div class="main-item">
        <div class="row">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <nav class="sidebar" [ngClass]="{ sidebarPushRight: isActive }">
              <div class="title-holder">
                <div class="list-title pointer" (click)="redirectToNotify()">
                  <span class="list-group-item">
                    {{ "Advertise" | translate }}
                  </span>
                </div>
                <div class="title-decorate"></div>
                <div class="title-bar" ></div>
              </div>
              <ul class="list-group">
                <div class="nested-menu">
                  <div class="list-submenu">
                    <a class="submenu" *ngFor="let newItem of topNews" [href]="postDetailPath + '/' +newItem.UrlPath">
                      <div class="sub-item">
                        <div class="item-title-icon">
                          <div class="item-icon ">
                            <img class="water-icon" src="../../assets/images/water-icon.png" alt="" />
                          </div>
                          <div class="item-title ">
  
                            {{ newItem.Title }}
                            <div *ngIf="newItem.NewFlg == true" class="item-title-news">Mới</div>
                          </div>
  
                        </div>
                        <div class="item-info">
                          <!-- <div *ngIf="newItem.Keywords">
                            <ng-container *ngFor="let item of newItem.Keywords">
                              <span class="item-keyword" >
                                {{ item }}; 
                              </span>
                            </ng-container>
                          </div> -->
                          <span class="item-time"> {{ newItem.CreateAt | date:'dd/MM/yyyy' }} </span>
                        </div>
                      </div>

                    </a>
                  </div>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
              <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
          </div> -->
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-21-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
          }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-21-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-21-dialog>
    <div *ngIf="dltFlg == true">
      <button class="btn-delete" (click)="onDeleteGallery()">
        <span>{{ "Delete" | translate }}</span>
      </button>
      <button class="btn-cancel" (click)="onClose($event)">
        <span>{{ "Cancel" | translate }}</span>
      </button>
    </div>
  </kendo-dialog>
</article>