<article class="organization-structure">
  <div class="row">
    <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
      <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
      <div class="menu" *ngIf="isShowMenu">
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6 director">
      <span *ngIf="director" [textContent]="director.Name" (click)="infoDialog(director)"></span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 col-6 sub-director">
      <span *ngIf="subDirector" [textContent]="subDirector.Name" (click)="infoDialog(subDirector)"></span>
    </div>
  </div>
  <div class="all-unit">
    <div *ngFor="let type of unitTypes; let i = index">
      <p [textContent]="type.Name"></p>
      <div *ngIf="units && units[i].length>0" class="unit">

          <div class="col-sm-4 col-md-3 col-xl-2 col-12" *ngFor="let unit of units[i]">
            <span [textContent]="unit.Name" (click)="infoDialog(unit)"></span>
          </div>

      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="infoOpened" [draggable]="false" class="info x-dialog window-center" [ngClass]="isEmployee?'dialog-employee':''">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req" [ngClass]="isEmployee ? 'employee' : ''">
          <span [textContent]="unitName  | translate"></span>
        </div>
      </div>
      <div *ngIf="!isEmployee">
        <div *ngFor="let acc of accounts" class="row info-list">
          <div class="col-md-4 col-3 avatar">
            <img *ngIf="!acc.AvatarUrl" src="../../../assets/images/default.png" alt="">
            <img *ngIf="acc.AvatarUrl" [src]="appService.apiRoot+acc.AvatarUrl" [alt]="acc.Name">
          </div>
          <div class="col-md-8 col-9 personal-info">
            <div class="list name row">
              <div class="col-md-4 col-4 title">
                <i class="fa fa-address-card" aria-hidden="true"></i>
                &nbsp; <span>Họ tên</span>
              </div>
              <div class="col-md-8 col-8 content">
                {{ acc.Name }}
              </div>
            </div>
            <div class="list position row">
              <div class="col-md-4 col-4 title">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
                &nbsp; <span>Chức vụ</span>
              </div>
              <div class="col-md-8 col-8 content">
                {{ acc.PositionName }}
              </div>
            </div>
            <div class="list contact row">
              <div class="col-md-4 col-4 title">
                <i class="fa fa-phone-square" aria-hidden="true"></i>
                &nbsp; <span>Liên lạc</span>
              </div>
              <div class="col-md-8 col-8 content">
                {{ acc.Phone }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isEmployee">
        <table class="employee-list">
          <thead>
            <tr>
              <th class="index" [width]="10">STT</th>
              <th [width]="150">Họ và tên</th>
              <th [width]="300">Chức vụ</th>
              <th [width]="auto">Số điện thoại</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let acc of accounts | slice: (page-1)*pageSize : (page-1)*pageSize+pageSize; let i = index">
              <td class="index">{{(page-1)*pageSize+i+1}}</td>
              <td>{{acc.Name}}</td>
              <td>{{acc.PositionName}}</td>
              <td>{{acc.Phone}}</td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="accounts.length>0" (pageChange)="handlePageChange()" class="paginator"
          [collectionSize]="accounts.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="maxSize"
          [boundaryLinks]="true" [ellipses]="false" [rotate]="true">
          <ng-template ngbPaginationFirst>
            <i class="fa fa-fast-backward" aria-hidden="true"></i>
          </ng-template>
          <ng-template ngbPaginationPrevious>
            <i class="fa fa-caret-left" aria-hidden="true"></i>
          </ng-template>
          <ng-template ngbPaginationNext>
            <i class="fa fa-caret-right" aria-hidden="true"></i>
          </ng-template>
          <ng-template ngbPaginationLast>
            <i class="fa fa-fast-forward" aria-hidden="true"></i>
          </ng-template>
        </ngb-pagination>
      </div>
    </div>
  </kendo-dialog>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-12-dialog close-div">
      <div class="title-info-req title-center">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-12-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-12-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
