<header-template1 *ngIf="isLoaded">
  <!-- Banner Top -->
  <div class="banner">
    <div class="banner-link mobile" (click)="reset()" [routerLink]="['']"></div>
    <div class="menu-icon-box">
      <mat-icon class="menu-icon" (click)="open()">menu</mat-icon>
    </div>
    <figure>
      <!-- <img class="img1" *ngIf="header[0]" [src]="appService.apiRoot + header[0].ImageUrl" alt="header[0].ImageUrl"> -->
      <nav>
        <!-- <a  *ngIf="headerItem.Info.Link" [ngClass]="headerItem.Info.Link?'':'disable-click'" [href]="headerItem.Info.Link"> -->
        <span>
          <img class="img1" *ngIf="headerItem.Image" src="{{appService.apiRoot + headerItem.Image[0].ImageUrl}}"
            alt="{{headerItem.Image[0].Title}}">
        </span>
        <label class="logo-container " *ngIf="headerItem.Image">
          <span *ngIf="isAdminFlg" class="btn-custom">
            <kendo-dropdownbutton class="btn-custom logo" [data]="btnHeader"
              [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
              <span class="material-icons " (click)="onClickFunction(headerItem.Image[0])">more_vert</span>
            </kendo-dropdownbutton>
          </span>
          <!-- *ngIf="editFlg && (controlDefault || control.Edit)" -->
        </label>
        <!-- </a> -->
      </nav>
      <!-- <img    class="img2 " *ngIf="header[1]" [src]="appService.apiRoot + header[1].ImageUrl" alt="header[1].ImageUrl"> -->
    </figure>
    <!-- <div class="language desktop">
        <nav>
          <a class="language-text" [ngClass]="isVi?'color-text-selected':''" (click)="changeLanguage(languages[0].culture)">Tiếng việt</a> |
          <a class="language-text" [ngClass]="isVi?'':'color-text-selected'" (click)="changeLanguage(languages[1].culture)">English</a>
        </nav>
      </div> -->
    <div class="name">
      <a [routerLink]="['/']">
        <hgroup>
          <span class="name-main">
            {{headerItem?.Info.Title}}
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-custom logo" [data]="btnHeader"
                [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                <span class="material-icons" (click)="onClickFunction(headerItem?.Info)">more_vert</span>
              </kendo-dropdownbutton>
            </span> <br>
          </span>

          <!-- <input class="main-name-input" *ngIf="editFlg" kendoTextBox [(ngModel)]="headerItem.Info.Title" type="text"> -->
          <span class="name-detail">
            <span class="material-icons star-5-left">star star star star star</span>
            {{headerItem?.Info.SubTitle}}
            <!-- <input class="detail-name-input" *ngIf="editFlg" kendoTextBox [(ngModel)]="headerItem.Info.SubTitle" type="text"> -->
            <span class="material-icons star-5-right">star star star star star</span>
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-custom logo" [data]="btnHeader"
                [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                <span class="material-icons" (click)="onClickFunction(headerItem?.Info)">more_vert</span>
              </kendo-dropdownbutton>
            </span>
          </span>

          <!-- *ngIf="editFlg && (controlDefault || control.Edit)" -->
        </hgroup>
      </a>
    </div>

    <div class="top-right-menu">
      <div class="menu">
        <ul class="menus">
          <li class="menu-item" *ngFor="let item of dataMenu">
            <a class="top-right-menu-text"
              [routerLink]="[item.PageID? parseURL(item.PageID): (postList +'/'+ item.Url)]">{{item.Name}}</a>
            &nbsp;
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-custom logo" [data]="btnHeader"
                [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                <span class="material-icons " (click)="onClickFunction(item)">more_vert</span>
              </kendo-dropdownbutton>
            </span>
          </li>
          <button *ngIf="isAdminFlg && dataMenu.length<3" class="btn-add" (click)="openAddMenu()">+</button>
        </ul>
      </div>
    </div>
    <div class="language mobile">
      <nav *ngIf="toprightmenus?.length > 0">
        <a *ngFor="let item of toprightmenus">{{item.Name}}</a>
      </nav>
      <kendo-multiselect [data]="menuFilter" [textField]="'Name'" [valueField]="'ID'"
        [(ngModel)]="headerItem.Info.MenuIDs" [valuePrimitive]="true" [filterable]="true"
        (filterChange)="rolesHandleFilter($event)">
      </kendo-multiselect>
    </div>
    <input type="text" class="search form-control desktop" (keydown.enter)="search(searchText?.value, true)"
      [placeholder]="'Search-Text' | translate" #searchText name="searchText" value="">
    <input type="text" class="search form-control mobile" *ngIf="isSearch"
      (keydown.enter)="search(searchText?.value, true)" [placeholder]="'Search-Text' | translate" #searchText
      name="searchText" value="">
    <button class="login-btn">Đăng nhập</button>
    <!-- <mat-icon class="search-icon" (click)="search(searchText?.value, false)">search</mat-icon> -->
    <div class="search-cover-shadow" *ngIf="isSearch" (click)="reset()"></div>
  </div>

  <!-- Thanh Menu -->
  <nav class="nav-menu-box">
    <ul class="nav-content">
      <div class="navbar-cover-shadow" (click)="open()" *ngIf="openFlg"></div>
      <div class="nav-menu" [ngClass]="openFlg?'navbar-display':'navbar-hidden'" #mother0>
        <div class="close-button-box mobile"><mat-icon (click)="open()" class="close-button">close</mat-icon></div>
        <li class="menu-obj level0 dropdown" *ngFor="let item of menus" #level0
          (click)="selectMenu($event, mother0,level0,'level0')" [id]="item.ID">
          <a class="item-link pading-0" [title]="item.Name" (click)="open()"
            [routerLink]="[item.PageID? parseURL(item.PageID): (postList +'/'+ item.UrlPath)]" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{item.Name}}
          </a>

          <!-- <span class="material-icons" (click)="openDialog(8,'Title_MeetingUnit')">more_vert</span> <br> -->
          <!-- <mat-icon *ngIf="item.Childrens.length>0 && !selectedMenuID.includes(item.ID)" class="button-expand mobile">add</mat-icon>
          <mat-icon *ngIf="item.Childrens.length>0 && selectedMenuID.includes(item.ID)" class="button-expand mobile">remove</mat-icon> -->
          <!-- <div *ngIf="item.Childrens.length>0" class="dropdown-menu" #mother1>
              Level 1
                <div class="menu-obj level1 dropdown-submenu"   *ngFor="let subtab01 of item.Childrens" #level1 (click)="selectMenu($event, mother1, level1, 'level1')" [id]="subtab01.ID">
                  <a class="dropdown-item pading-1" [ngClass]="subtab01.Childrens.length>0?'disable-click':null" (click)="open()" [routerLink]="[subtab01.PageID? parseURL(subtab01.PageID): (postList +'/'+ subtab01.Url)]">
                    {{subtab01.Name}}
                    <mat-icon *ngIf="subtab01.Childrens.length>0 && !selectedMenuID.includes(subtab01.ID) " class="button-expand  mobile">add</mat-icon>
                    <mat-icon *ngIf="subtab01.Childrens.length>0 &&  selectedMenuID.includes(subtab01.ID) " class="button-expand  mobile">remove</mat-icon>
                    <mat-icon *ngIf="subtab01.Childrens.length>0" class="play-arrow desktop-icon">play_arrow</mat-icon>
                  </a>
                      <div class="dropdown-menu" *ngIf="subtab01.Childrens.length>0 " #mother2>
                      Level 2
                        <div class="menu-obj level2 dropdown-submenu" *ngFor="let subtab02 of subtab01.Childrens" #level2 (click)="selectMenu($event, mother2,level2, 'level2')" [id]="subtab02.ID">
                          <a class="dropdown-item pading-2" aria-haspopup="true" [ngClass]="subtab02.Childrens.length>0?'disable-click':null" (click)="open()" [routerLink]="[subtab02.PageID? parseURL(subtab02.PageID): (postList +'/'+ subtab02.Url)]">
                            {{subtab02.Name}}
                            <mat-icon *ngIf="subtab02.Childrens.length>0 && !selectedMenuID.includes(subtab02.ID)" class="button-expand  mobile">add</mat-icon>
                            <mat-icon *ngIf="subtab02.Childrens.length>0 &&  selectedMenuID.includes(subtab02.ID) " class="button-expand  mobile">remove</mat-icon>
                            <mat-icon *ngIf="subtab02.Childrens.length>0" class="play-arrow desktop-icon">play_arrow</mat-icon>
                          </a>
                            <div class="dropdown-menu" *ngIf="subtab02.Childrens.length>0" #mother3>
                            Level 3
                              <div class="menu-obj level3 dropdown-submenu" *ngFor="let subtab03 of subtab02.Childrens"  #level3 (click)="selectMenu($event, mother3,level3, 'level3')" [id]="subtab03.ID">
                                <a class="dropdown-item pading-3" aria-haspopup="true" [ngClass]="subtab03.Childrens.length>0?'disable-click':null" (click)="open()" [routerLink]="[subtab03.PageID? parseURL(subtab03.PageID): (postList +'/'+ subtab03.Url)]">
                                  {{subtab03.Name}}
                                  <mat-icon *ngIf="subtab03.Childrens.length>0 && !selectedMenuID.includes(subtab03.ID)" class="button-expand  mobile">add</mat-icon>
                                  <mat-icon *ngIf="subtab03.Childrens.length>0 &&  selectedMenuID.includes(subtab03.ID)" class="button-expand  mobile">remove</mat-icon>
                                  <mat-icon *ngIf="subtab03.Childrens.length>0" class="play-arrow desktop-icon">play_arrow</mat-icon>
                                </a>
                                  <div class="dropdown-menu" *ngIf="subtab03.Childrens.length>0" #mother4>
                                Level 4
                                    <div class="menu-obj level4 dropdown-submenu" *ngFor="let subtab04 of subtab03.Childrens" #level4 (click)="selectMenu($event, mother4,level4, 'level4')" [id]="subtab04.ID">
                                      <a class="dropdown-item pading-4" aria-haspopup="true" (click)="open()" [routerLink]="[subtab04.PageID? parseURL(subtab04.PageID): (postList +'/'+ subtab04.Url)]">
                                        {{subtab04.Name}}
                                      </a>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </li>
        <!-- <li>
            <span  class="material-icons md-light"  (click)="openAddMenu()">add</span>
          </li> -->
      </div>
    </ul>
  </nav>
  <kendo-dialog *ngIf="addMenu" [draggable]="false" class="add-menu x-dialog window-center router-outlet-content">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span [textContent]="'Menu_List'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label [textContent]="'Menu' | translate"></label>
                <kendo-dropdownlist [data]="menuFilter" [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true"
                  [filterable]="true" (filterChange)="menuHandleFilter($event)" (valueChange)="onChangeMenu($event)"
                  [popupSettings]="{popupClass: popupClass, animate: true}">
                  <ng-template kendoComboBoxNoDataTemplate>{{'NoRecordsAvailable' | translate}}</ng-template>
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
                  <span aria-hidden="true">{{'Save' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
  <kendo-dialog *ngIf="editFlg" [draggable]="false" class="edit x-dialog window-center router-outlet-content">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()">
        </button>
        <div class="title-info-req">
          <span *ngIf="menuOpened" [textContent]="'Menu_List'  | translate"></span>
          <span *ngIf="infoOpened" [textContent]="'Header_Info_List'  | translate"></span>
          <span *ngIf="avatarUpload" [textContent]="'Upload_Avatar_Image'  | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div *ngIf="infoOpened" class="edit-info">
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Title' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="headerItem?.Info.Title" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'SubTitle' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="headerItem?.Info.SubTitle" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
            </div>
            <div *ngIf="menuOpened" class="edit-menu">
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Name' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Name" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12" *ngIf="isParentUrl">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Parent_Url' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="parentUrl" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Url">{{dataMenuItem.Url}}</span> -->
                </div>
              </div>
              <div class="col-md-12 col-12">
                <div [ngClass]="'control'">
                  <label>
                    <span [textContent]="'Url' | translate"></span>
                    <!-- <span class="required">(*)</span> -->
                  </label>
                  <input kendoTextBox [(ngModel)]="dataMenuItem.Url" [ngClass]="''" />
                  <!-- <span class="err-text" *ngIf="dataMenuItem.Url">{{dataMenuItem.Url}}</span> -->
                </div>
              </div>
            </div>
            <div *ngIf="avatarUpload" class="upload-image">
              <div [ngClass]="'control'">
                <kendo-upload [multiple]="false" [showFileList]="true" [saveUrl]="fileSaveUrl"
                  (success)="onSuccessAvatarUpload($event)" [accept]="['.png','.jpg','.jpeg']">
                  <kendo-upload-messages [headerStatusUploaded]="'FileUploaded' | translate"
                    [dropFilesHere]="'dropFilesHere' | translate" [select]="'UploadFile' | translate">
                  </kendo-upload-messages>
                </kendo-upload>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
                  <span aria-hidden="true">{{'Save' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
</header-template1>
