<section class=" row mx-0 customer-care">

  <div [style.width]="bodyWidth +'px'" class="auto">
    <div class="wrapper">
      <div class="row">

        <div class="col-md-3 col-12 categories-area">
          <div class="titile"> {{ "Hạng mục dịch vụ" | translate}}
          </div>
          <div class="round-checkbox form-row-checkbox">
            <div class="checkboxFour">
              <input type="checkbox" value
                id="checkboxFourInput" />
              <label for="checkboxFourInput"></label>
            </div>
            <div class="label-text">{{ "Gắn mới" | translate}}</div>
          </div>
          <div class="round-checkbox form-row-checkbox">
            <div class="checkboxFour1">
              <input type="checkbox1" value
                id="checkboxFourInput1" />
              <label for="checkboxFourInput1"></label>
            </div>
            <div class="label-text">{{ "Nâng đời, tái lập" | translate}}</div>
          </div>
          <div class="round-checkbox form-row-checkbox">
            <div class="checkboxFour2">
              <input type="checkbox2" value
                id="checkboxFourInput2" />
              <label for="checkboxFourInput2"></label>
            </div>
            <div class="label-text">{{ "Định mức" | translate}} </div>
          </div>
        </div>

        <div class="col-md-9 col-12 send-background">
          <h3 class="quiz-title">{{ "Thông tin khách hàng nộp hồ sơ" | translate
            }}</h3>

          <div class="row mx-0">
            <div class="col-md-12 col-12">

              <form class="quiz-form" [formGroup]="userForm">

                <fieldset class="quiz-form-fieldset">
                  <div class="button"></div>
                  <hr />
                  <div class="text-not-title">{{
                    "Khách hàng vui lòng điền đầy đủ các thông tin theo mẫu bên dưới.Lưu ý: Dấu (*) là các nội dung bắt buộc phải nhập vào."
                    | translate}}
                  </div>
                  <!-- Họ và Tên -->
                  <kendo-formfield>
                    <div>
                      <div class="data-title">{{ "Họ và tên khách hàng" |
                        translate}} <span class="required">
                          *</span>
                      </div>
                      <div class="quiz-input-item">
                        <input formControlName="AccountName"
                          [placeholder]="'Nhập tên khách hàng' | translate"
                          kendoTextBox required />
                        <div class="quiz-error"
                          *ngIf="!validForm && !userForm.get('AccountName').valid">
                          {{ "Contact_Error" | translate }}
                        </div>
                      </div>
                    </div>
                  </kendo-formfield>

                  <!-- phường xã  -->
                  <div class="row">
                    <div class="form-row">
                      <!-- quận huyện  -->
                      <div class="col-md-6 col-12">
                        <kendo-formfield>
                          <div class>
                            <div class="data-title">{{ "Quận/Huyện" |
                              translate}} <span class="required"> *</span>
                            </div>
                            <div class="quiz-input-item">
                              <kendo-combobox [data]="districtListFilter"
                                [placeholder]="'' | translate"
                                [textField]="'Name'" [valueField]="'ID'"
                                [valuePrimitive]="true"
                                [filterable]="true" required>

                              </kendo-combobox>
                              <div class="quiz-error"
                                *ngIf="!validForm && !userForm.get('LVID').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                      </div>
                      <div class="col-md-6 col-12">
                        <kendo-formfield>
                          <div class>
                            <div class="data-title">{{ "Phường/Xã" | translate}}
                              <span class="required"> *</span>
                            </div>
                            <div class="quiz-input-item">
                              <kendo-combobox [data]="wardListFilter"
                                formControlName="WardID"
                                [placeholder]="'' | translate"
                                [textField]="'Name'" [valueField]="'ID'"
                                [valuePrimitive]="true"
                                [filterable]="true" required>
                              </kendo-combobox>
                              <div class="quiz-error"
                                *ngIf="!validForm && !userForm.get('LVID').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                      </div>
                    </div>
                  </div>
                  <!-- số nhà  -->
                  <kendo-formfield>
                    <div>
                      <div class="data-title">{{ "Số nhà" | translate}} <span
                          class="required"> *</span>
                      </div>
                      <div class="quiz-input-item">
                        <input formControlName="AccountName"
                          [placeholder]="'Nhập số nhà' | translate" kendoTextBox
                          required />
                        <div class="quiz-error"
                          *ngIf="!validForm && !userForm.get('AccountName').valid">
                          {{ "Contact_Error" | translate }}
                        </div>
                      </div>
                    </div>
                  </kendo-formfield>
                  <kendo-formfield>
                    <div>
                      <div class="data-title">{{ "Đường" | translate}} <span
                          class="required"> *</span>
                      </div>
                      <div class="quiz-input-item">
                        <input formControlName="AccountName"
                          [placeholder]="'Nhập tên đường' | translate"
                          kendoTextBox
                          required />
                        <div class="quiz-error"
                          *ngIf="!validForm && !userForm.get('AccountName').valid">
                          {{ "Contact_Error" | translate }}
                        </div>
                      </div>
                    </div>
                  </kendo-formfield>
                  <div class="row">
                    <div class="form-row">
                      <!-- số điện thoại -->
                      <div class="col-md-6 col-12">
                        <kendo-formfield>
                          <div class>
                            <div class="data-title">{{ "Số điện thoại" |
                              translate}} <span class="required">
                                *</span></div>
                            <div class="quiz-input-item">
                              <input (keypress)="keyPress($event)"
                                formControlName="AccountPhone"
                                [placeholder]="'' | translate" kendoTextBox
                                type="text" maxlength="10" minlength="10"
                                required />
                              <div class="quiz-error"
                                *ngIf="!validForm && !userForm.get('AccountPhone').valid">
                                {{ "Contact_Error_Phone" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                      </div>
                      <!-- địa chỉ email  -->
                      <div class="col-md-6 col-12">
                        <kendo-formfield>
                          <div class>
                            <div class="data-title">{{ "Địa chỉ Email" |
                              translate}} <span class="required">
                                *</span></div>
                            <div class="quiz-input-item">
                              <input type="AccountEmail"
                                formControlName="AccountEmail"
                                [placeholder]="'' | translate"
                                kendoTextBox required />
                              <div class="quiz-error" *ngIf="
                                  !validForm &&
                                  (!userForm.get('AccountEmail').valid ||
                                    !validateMail(userForm.value.AccountEmail))
                                ">
                                {{ "Contact_Invalid_Email" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                      </div>
                    </div>
                  </div>
                  <!-- Mục đích sử dụng nước  -->
                  <kendo-formfield class="row">
                    <div>
                      <div class="data-title">{{ "Mục đích sử dụng nước" |
                        translate}} <span class="required">
                          *</span>
                      </div>
                      <div class="quiz-input-item">
                        <div class="row">
                          <div class="col-md-12 col-12 row">
                            <div class="col-md-1 col-12">
                              <input type="checkbox" class="checkboxx" />
                            </div>
                            <div class="col-md-11 col-12">
                              <div class="label-text1">{{ "Sinh hoạt tư gia" |
                                translate}} </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-12 row">
                            <div class="col-md-1 col-12">
                              <input type="checkbox" class="checkboxx" />
                            </div>
                            <div class="col-md-11 col-12">
                              <div class="label-text1">{{ "Sản xuất" | translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-12 col-12 row">
                            <div class="col-md-1 col-12">
                              <input type="checkbox" class="checkboxx" />
                            </div>
                            <div class="col-md-11 col-12">
                              <div class="label-text1">{{ "Kinh doanh dịch vụ" |
                                translate}} </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-12 row">
                            <div class="col-md-1 col-12">
                              <input type="checkbox" class="checkboxx" />
                            </div>
                            <div class="col-md-11 col-12">
                              <div class="label-text1">{{ "Hành chính" |
                                translate}} </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </kendo-formfield>
                  <!-- Hồ sơ giấy tờ  -->
                  <kendo-formfield>
                    <div class>
                      <div class="data-title">{{ "Hồ sơ giấy tờ" | translate}}
                        <span class="required">
                          *</span>
                      </div>
                      <label class="quiz-textarea-item">
                        <div *ngFor="let item of fileUrls">
                          <img class="image-upload"
                            [src]="getImageFullUrl(item)">
                        </div>
                        <kendo-upload [(ngModel)]="filesUpload"
                          (select)="onSelectFileToUpload($event)" required
                          (remove)="onRemoveFileDocToUpload()" [multiple]="true"
                          [showFileList]="false"
                          [saveUrl]="fileSaveUrl"
                          (success)="onSuccessFileToUpload($event)"
                          [accept]="['.jpeg','.jpg','.png','.doc','.docx','.pdf']"
                          [ngModelOptions]="{standalone: true}">
                          <kendo-upload-messages
                            [dropFilesHere]="'dropFilesHere' | translate"
                            [select]="'UploadFile' | translate"
                            [headerStatusUploaded]="'FileUploaded' | translate">
                          </kendo-upload-messages>
                          <div class="quiz-error"
                            *ngIf="!validForm && !userForm.get('FileUploaded').valid">
                            {{ "Contact_Error_Phone" | translate }}
                          </div>
                        </kendo-upload>
                        <div class="image-upload add-image add-image-text"
                          (click)="uploadfile()">
                          <button class="button-image">
                            {{ "Add_Image" | translate }}
                          </button>
                        </div>
                      </label>
                      <div class="text-image row">
                        <div>Gợi ý:</div>
                        <div>- Quyết định giao đất của cơ quan chức năng cho chủ
                          đầu tư xây dựng công trình</div>
                        <div>- Quyết định của cơ quan có thẩm quyền hoặc bản án
                          có hiệu lực thi hành của tòa án công
                          nhận quyền sở hữu,sử dụng, thừa kế nhà (kèm bản photo
                          chủ quyền nhà)</div>
                        <div>- Quyết định cấp số nhà của cơ quan thẩm quyền</div>
                        <div>- Giấy phép xây dựng (có số nhà)</div>
                        <div>- Hợp đồng thuê nhà của Nhà nước (có thời hạn lâu
                          dài)</div>
                        <div>- Giấy cấp nhà hoặc quyết định cấp nhà của cơ quan
                          có thẩm quyền</div>
                        <div>- Hợp đồng chuyển quyền sở hữu nhà lập tại cơ quan
                          công chứng</div>
                        <div>- Giấy xác nhận tạm trú tại địa chỉ gắn đồng hồ
                          nước của công an phường, xã (KT2) và xác
                          nhận thêm “không thuộc diện nhà thuê”</div>
                        <div>- Giấy xác nhận tạm trú tại địa chỉ gắn đồng hồ
                          nước của công an phường, xã (KT2) và xác
                          nhận thêm “không thuộc diện nhà thuê”</div>
                        <div>- Hộ khẩu diện KT3 còn thời hạn</div>
                        <div>- Hộ khẩu thường trú tại địa chỉ gắn ĐHN (Photo đầy
                          đủ nhân khẩu trong hộ để được cấp
                          định mức)</div>
                      </div>
                    </div>
                  </kendo-formfield>
                  <!-- Xác nhận  -->
                  <kendo-formfield>
                    <div class>
                      <div class="data-title">{{ "Mã xác nhận" | translate}}
                        <span class="required"> *</span>
                      </div>
                      <div class="quiz-input-item-captcha">
                        <div class="col-md-6 col-5">
                          <input formControlName="Capcha"
                            [placeholder]="'' | translate" kendoTextBox required />
                          <div class="quiz-error"
                            *ngIf="!validForm && !userForm.get('Capcha').valid">
                            {{ "Contact_Error" | translate }}
                          </div>
                        </div>
                        <div class="col-md-4 col-5 captcha-item"
                          id="captcha-item"></div>
                        <div class="col-md-2 col-2">
                          <div class="reload-captcha-btn"
                            (click)="createCaptcha()">
                            <img class="reload-icon"
                              src="../../assets/images/reload-captcha-icon.png"
                              alt>
                          </div>
                        </div>
                      </div>
                    </div>
                  </kendo-formfield>
                  <div class="form-row form-row-mb">
                    <div class="data-title"></div>
                    <div class="quiz-button-item">
                      <button type="submit" (click)="onSubmit()"
                        class="quiz-submit-button">
                        {{ "Gửi thông tin"| translate }}
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>
