<section class="router-outlet-content search-result">
    <div class="container-fluid" *ngIf="isLoaded==1">
      <div class="row">
        <section class="box col-md-8 col-lg-8 col-xs-12 col-sm-12 ">
          <ng-container *ngIf="menuFound">
            <header>
                <hgroup>
                    <h1 class="title-post wb">{{'Search_Result'| translate}}</h1>
                    <h1 class="group-header mb">{{'Search_Result'| translate}}</h1>
                </hgroup>
            </header>
            <ng-container *ngFor="let item of newListPage; index as i">
                <section class="wb">
                    <article class="post-part row">
                        <figure class="img-view col-md-5 col-lg-5 col-5">
                            <img class="image" *ngIf="!isVideo(item.ImageUrl)" [src]="getFullImageUrl(item.ImageUrl)" [alt]="item.Title" (error)="onImgError($event)" [routerLink]="[postDetail, item.UrlPath]">
                            <app-videojs *ngIf="isVideo(item.ImageUrl)" [url]="getFullImageUrl(item.ImageUrl)" [controls]="false" [zoom]="true" [bigPlayButton]="false"></app-videojs>
                        </figure>
                        <section class="content-view  col-md-7 col-lg-7 col-7" [routerLink]="[postDetail, item.UrlPath]">
                            <header>
                                <hgroup>
                                    <h3 class="post-title">
                                        {{onDotsString(item.Title, 120)}}
                                    </h3>
                                </hgroup>
                            </header>
                            <time class="post-date"> {{item.ApprovedAt | date:'HH:mm dd/MM/yyyy'}}</time>
                            <aside class="post-summary">{{item.Summary ? onDotsString(item.Summary, 220) : ''}}</aside>
                        </section>
                    </article>
                </section>

                <section class="mb">
                    <article class="post-part row mb-first" [routerLink]="[postDetail, item.UrlPath]"
                        *ngIf="i == 0">

                        <figure class="img-view-first col-md-12 col-lg-12 col-12">
                            <img class="image" *ngIf="!isVideo(item.ImageUrl)" [src]="getFullImageUrl(item.ImageUrl)" [alt]="item.Title" (error)="onImgError($event)">
                            <app-videojs *ngIf="isVideo(item.ImageUrl)" [url]="getFullImageUrl(item.ImageUrl)" [controls]="false" [zoom]="true" [bigPlayButton]="false"></app-videojs>
                        </figure>
                        <header class="content-view  col-md-12 col-lg-12 col-12">
                            <hgroup>
                                <h3 class="post-title title-first">
                                    {{onDotsString(item.Title, 40)}}
                                </h3>
                            </hgroup>
                        </header>
                    </article>
                    <article class="post-part row mb-second" [routerLink]="[postDetail, item.UrlPath]"
                       *ngIf="i != 0">

                        <figure class="img-view col-md-6 col-lg-6 col-6">
                            <img class="image" *ngIf="!isVideo(item.ImageUrl)" [src]="getFullImageUrl(item.ImageUrl)" [alt]="item.Title" (error)="onImgError($event)">
                            <app-videojs *ngIf="isVideo(item.ImageUrl)" [url]="getFullImageUrl(item.ImageUrl)" [controls]="false" [zoom]="true" [bigPlayButton]="false"></app-videojs>
                        </figure>
                        <header class="content-view  col-md-6 col-lg-6 col-6">
                            <hgroup>
                                <h3 class="post-title">
                                    {{onDotsString(item.Title, 55)}}
                                </h3>
                            </hgroup>
                        </header>
                    </article>
                    <hr *ngIf="i != news.length - 1 && i != 0">
                </section>
            </ng-container>
          </ng-container>
          <kendo-datapager *ngIf= " news.length > pageSize"
            [style.width.%]="100"
            [pageSize]="pageSize"
            [pageSizeValues]="false"
            [skip]="skip"
            [total]="total"
            [info]="false"
            (pageChange)="onPageChange($event)">
          </kendo-datapager>
        </section>
        <section class="col-md-4 col-lg-4 col-12">
            <app-most-viewed-posts [UrlPath]="UrlPath"></app-most-viewed-posts>
        </section>
      </div>
    </div>
    <div *ngIf="isLoaded==2" class="title">{{'Not-Found' | translate}}</div>
</section>
