import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-government-page',
  templateUrl: './government-page.component.html',
  // styleUrls: ['./government-page.component.scss']
})
export class GovernmentPageComponent implements OnInit {
  private queryParamsSubscription: Subscription;
  menuID: any;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.queryParamsSubscription = this.activatedRoute.params.subscribe(
      async (data) => {
        this.menuID = data['title'] ?? '';
      }
    );
  }

}
