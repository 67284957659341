import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { Router } from '@angular/router';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { Location } from '@angular/common';
import { MyServiceService } from '../my-service.service';

@Component({
  selector: 'app-news-template-43',
  templateUrl: './news-template-43.component.html',
  styleUrls: ['./news-template-43.component.scss']
})
export class NewsTemplate43Component implements OnInit {

  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  public pageSize = 12;
  public skip = 0;
  public pagedTopnews = [];
  public total = 0;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  @Input() width: any;
  galleryData: any;
  @Input() itemTemplate: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() tittleItem = new EventEmitter();
  @Output() urlItem = new EventEmitter();
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate1 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() isPreview: boolean;
  @Input() newsDecorationIndex: any;
  @Input() isMore: boolean = true;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  @Output() menuItem = new EventEmitter();
  @Output() menuItemchild = new EventEmitter();
  allDescription: any = [];
  layoutType: any = 
    {
      Program_Intro: 43,
      Program_Text: 44,
      Program_Timeline: 45,
      Program_Report: 46
    }
  public widthSView = '100%';
  public heightSView = '406px';
  defaultImg = '../../assets/images/default-image.png';
  innerWidth: any;
  //default templateType is 1 show all detail, change to 2 to hide time and viewMore
  @Input() templateType = 1;
  isViewAllList = false;
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private location: Location,
    private myService: MyServiceService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.topNumber = this.infoFromMain?.TopNumber;
    if (this.topNumber > 12) {
      this.isViewAllList = true
    }
    this.bootstrapNum = 12 / this.topNumber;
    if (this.topNumber > 3) {
      this.bootstrapNum = 3
    }
    this.getPostTittle();
    this.getTopNews();
    this.getProgram();
    this.galleryData = {
      ID: this.ID,
      OrderIdx: this.OrderIdx,
      infoFromMain: this.infoFromMain,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };
    // if (this.router.url == '/' + AppConsts.page.templatePage) {
    //   document.getElementById('template-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    // else {
    //   document.getElementById('main-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
    }
    if (this.infoFromMain.MenuID == '') {
      this.pagedTopnews = this.topNews;
    }




  }
  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }

  private pageData(): void {
    this.pagedTopnews = this.topNews.slice(
      this.skip,
      this.skip + this.pageSize
    );

    // if (this.pagedTopnews.length == 0) {
    //   this.pagedTopnews = [];
    //   for (let index = 0; index < this.topNumber; index++) {
    //     this.pagedTopnews.push({
    //       DefaultFlg: true,
    //       ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
    //       Title: 'Title',
    //       UrlPath: '',
    //       CreateAt: '2021-10-26T08:41:50.453',
    //     });
    //   }
    // }
  }
  async getTopNews() {

    //handle when get post by keyword
    if (this.infoFromMain.MenuID == "post-by-keyword") {
      const str = this.location.path();
      const keyword = decodeURIComponent(str.slice(str.indexOf('=') + 1));

      const result = await this.appService.doGET(
        'api/PortalPost/GetByKeyword',
        {
          keyword: keyword,
          langID: this.language.get(),
          topNum: this.infoFromMain.TopNumber,
        });
        if (result && result.Status === 1) {
          this.postTittle = 'Tin bài liên quan đến từ khóa "' + keyword + '"';
          this.viewMore = '';
          this.topNews = result.Data;
          this.pageData();
          this.total = result.Data.length;
        }
    } else {
      const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        menuID: this.infoFromMain.MenuID,
        topNum: this.infoFromMain.TopNumber,
      });
      if (result && result.Status === 1) {
        this.postTittle = result.Data.dataMenu.Name;
        this.viewMore = result.Data.dataMenu.UrlPath;
        this.topNews = result.Data.topNews;
        this.pageData();
        this.total = result.Data.topNews.length
      }
    }
  }
  async getProgram() {
    const result = await this.appService.doGET('api/Program/GetProgramPortal', {});
    if (result && result.Status === 1) {
      let programList = result.Data;

      programList = programList.map(p => {
        if (p.ImageUrls) {
          if (typeof(p.ImageUrls) == 'string') {
            p.ImageUrls = JSON.parse(p.ImageUrls);
            p.ImageUrls = p.ImageUrls.map(i => this.appService.apiRoot + i);
          }
        } else {
          p.ImageUrls = [];
        }

        if (p.FileUrls) {
          if (typeof(p.FileUrls) == 'string') {
            p.FileUrls = JSON.parse(p.FileUrls);
            p.FileUrls = p.FileUrls.map(i => this.appService.apiRoot + i);
          }
        } else {
          p.FileUrls = [];
        }
        return p;
      });
      console.log(programList);
      this.allDescription = programList.filter(p => p.TypeLayout == AppConsts.ProgramLayoutType.Program_Intro);
      this.allDescription = Object.values(this.allDescription.reduce((acc, cur) => {
        if (!(cur.Name in acc) || new Date(acc[cur.Name].CreateAt) < new Date(cur.CreateAt)) {
          acc[cur.Name] = cur;
        }
        return acc;
      }, {}));
    }
  }

  setH(){
    if(this.templateType == 2){
      return 'img-figure-template-type-2';
    }
    var a = document.querySelector('.post-list') as HTMLElement;
    if(a.getBoundingClientRect().width <= 808){
      return 'img-figure-2'
    }
    return ;
  }
  async viewMenu(item) {
    this.router.navigateByUrl(AppConsts.page.main + '/' + item.UrlPath);
    const data = await this.appService.doGET('api/Menu/GetParentAndChildrendByParentID', {
      ParentID: item.ParentID
    });
    // this.myService.sendMessage(data.Data[0]);
    // this.menuItem.emit(data.Data[0]);
    let menu = data.Data[0].Childrens.find(c => c.ID == item.MenuID);
    this.menuItemchild.emit(menu);
    // this.myService.sendMenuChild(menu);
    this.myService.sendMessage(menu);
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore() {
    // this.router.navigate([AppConsts.page.postList], { queryParams: { url: this.viewMore } });
    window.location.href =  this.appService.portalRoot + this.viewMore;
  }
  onGetTittleItem(e) {
    this.tittleItem.emit(e)

  }

  redirectToItem(url: string, item) {


    this.onGetTittleItem(item)
    if (url != null && url != undefined) {
      // this.router.navigate([AppConsts.page.galleryDialog], { queryParams: {} });

      // this.router.navigate([AppConsts.page.postDetail, url], { state: { postTittle: this.postTittle } });

      this.urlItem.emit(item);
    }
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  changeTemp1(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.getTopNews();
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate1.emit(newTemp);
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }
  redirectToDetail(url){
    // this.router.navigate(['/post-detail/'+url])
  }
  onRightClick(url){
    let fullURL = this.appService.portalRoot + 'post-detail/' + url;
    window.open(fullURL, '_blank');
    return false;
  }
  getDetailUrl(url){
    let fullURL = '/post-detail/' + url;
    return fullURL;
  }

  getDateOnly(item) {
    const date = new Date(item);
    return date.getDate();
  }

  getMonthOnly(item) {
    const date = new Date(item);
    return date.getMonth();
  }

  getTime(item) {
    const date = new Date(item);
    return date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
  }

}
