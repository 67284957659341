<section class="row mx-0 contact-container">
  <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
    <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
    <div class="menu" *ngIf="isShowMenu">
      <div class="menu-row-current" (click)="deleteComponent()">
        <div class="menu-item-3">{{'Delete' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="col-md-1"></div>
  <div class="col-md-10 col-12">
    <h3 class="contact-title px-2">{{ "Contact_Title" | translate }}</h3>
    <div class="row mx-0">
      <div class="col-md-7 col-12">
        <form class="contact-form" [formGroup]="userForm">
          <fieldset class="contact-form-fieldset">
            <kendo-formfield>
              <div class="contact-input-item">
                <input formControlName="AccountName" [placeholder]="'Contact_FullName' | translate" kendoTextBox
                  required />
                <div class="contact-error" *ngIf="!validForm && !userForm.get('AccountName').valid">
                  {{ "Contact_Error" | translate }}
                </div>
              </div>
            </kendo-formfield>
            <kendo-formfield>
              <div class="contact-input-item">
                <input type="AccountEmail" formControlName="AccountEmail" [placeholder]="'Contact_Email' | translate"
                  kendoTextBox required />
                <div class="contact-error" *ngIf="
                    !validForm &&
                    (!userForm.get('AccountEmail').valid ||
                      !validateMail(userForm.value.AccountEmail))
                  ">
                  {{ "Contact_Invalid_Email" | translate }}
                </div>
              </div>
            </kendo-formfield>
            <kendo-formfield>
              <div class="contact-input-item">
                <input (keypress)="keyPress($event)" formControlName="AccountPhone"
                  [placeholder]="'Contact_Phone' | translate" kendoTextBox type="text" maxlength="10" minlength="10"
                  required />
                <div class="contact-error" *ngIf="!validForm && !userForm.get('AccountPhone').valid">
                  {{ "Contact_Error_Phone" | translate }}
                </div>
              </div>
            </kendo-formfield>
            <kendo-formfield>
              <div class="contact-input-item">
                <input formControlName="AccountAddress" [placeholder]="'Contact_Address' | translate" kendoTextBox
                  required />
                <div class="contact-error" *ngIf="!validForm && !userForm.get('AccountAddress').valid">
                  {{ "Contact_Error" | translate }}
                </div>
              </div>
            </kendo-formfield>
            <kendo-formfield>
              <div class="contact-input-item">
                <kendo-combobox formControlName="LVID" [placeholder]="'Contact_Field' | translate" [data]="lvListFilter"
                  [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true" [filterable]="true"
                  (filterChange)="lvHandleFilter($event)" required>

                </kendo-combobox>
                <div class="contact-error" *ngIf="!validForm && !userForm.get('LVID').valid">
                  {{ "Contact_Error" | translate }}
                </div>
              </div>
            </kendo-formfield>

            <kendo-formfield>
              <div class="contact-input-item">
                <input formControlName="Name" [placeholder]="'Contact_Tille' | translate" kendoTextBox required />
                <div class="contact-error" *ngIf="!validForm && !!userForm.value.Name && !validateName(userForm.value.Name)">
                  {{ "Contact_Name_Error" | translate }}
                </div>
                <div class="contact-error" *ngIf="!validForm && !userForm.get('Name').valid">
                  {{ "Contact_Error" | translate }}
                </div>
              </div>
            </kendo-formfield>
            <kendo-formfield>
              <div class="contact-textarea-item">
                <textarea formControlName="Description" [placeholder]="'Contact_Content' | translate" kendoTextArea
                  required></textarea>
                <div class="contact-error" *ngIf="
                    !validForm &&
                    !!userForm.value.Description &&
                    !validateContent(userForm.value.Description)
                  ">
                  {{ "Contact_Content_Error" | translate }}
                </div>
                <div class="contact-error" *ngIf="!validForm && !userForm.get('Description').valid">
                  {{ "Contact_Error" | translate }}
                </div>
              </div>
            </kendo-formfield>
            <div class="contact-button-wrapper mb-4">
              <button type="submit" (click)="onSubmit()" class="contact-form-button">
                {{ "Contact_SendQuestion" | translate }}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="col-md-5 col-12">
        <div class="contact-info-item">
          <div class="contact-icon">
            <mat-icon>location_on</mat-icon>
          </div>
          <div class="contact-info">
            {{ infoContact.Address }}
          </div>
        </div>
        <div class="contact-info-item">
          <div class="contact-icon">
            <mat-icon>call</mat-icon>
          </div>
          <div class="contact-info">
            {{ infoContact.Phone }}
          </div>
        </div>
        <div class="contact-info-item">
          <div class="contact-icon">
            <mat-icon>mail</mat-icon>
          </div>
          <div class="contact-info">
            {{ infoContact.Email }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1"></div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
  class="router-outlet-content window-center dialog-info">
  <div class="news-template-12-dialog close-div">
    <div class="title-info-req">
      <strong *ngIf="editFlg == true && dltFlg == false">{{
        "Gallery_Scrollview_Edit" | translate
        }}</strong>
      <strong *ngIf="editFlg == false && dltFlg == false">{{
        "Gallery_Scrollview_Add" | translate
        }}</strong>
      <strong *ngIf="dltFlg == true">{{
        "Gallery_Scrollview_Delete" | translate
      }}</strong>
    </div>
    <div *ngIf="dltFlg == false" class="close-icon-div">
      <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
    </div>
  </div>
  <app-news-template-12-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
    (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
    [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
  </app-news-template-12-dialog>
  <div *ngIf="dltFlg == true">
    <div class="input-label">
      <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
    </div>
    <div>
      <button
        class="btn-delete"
        (click)="onDeleteGallery()"
      >
        <span>{{ "Delete" | translate }}</span>
      </button>
      <button
        class="btn-cancel"
        (click)="onClose($event)"
      >
        <span>{{ "Cancel" | translate }}</span>
      </button>
    </div>
  </div>
</kendo-dialog>
</section>