import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FileInfo,
  FileRestrictions,
  SelectEvent,
  SuccessEvent,
} from '@progress/kendo-angular-upload';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { AppLanguage } from 'src/app/services/app.language';
import { Notification } from 'src/app/services/app.notification';
import { AppSwal } from 'src/app/services/app.swal';
import { AppControls } from 'src/app/services/app.controls';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-user-info-form',
  templateUrl: './user-info-form.component.html',
})
export class UserInfoFormComponent implements OnInit {
  constructor(
    public appService: AppService,
    private translate: TranslateService,
    private language: AppLanguage,
    private notification: Notification,
    private appSwal: AppSwal,
    public appControls: AppControls,
    public intl: IntlService
  ) {}
  accountID: any;
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  imgUrl: any;
  imgUploadUrl: any;
  fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=admin&typeData=image&acceptExtensions=[".png",".jpg",".jpeg"]`;
  errorImg: any;
  user: any;
  checkNullSelect: boolean = false;
  checkNullDate: boolean = false;
  userForm = new FormGroup({
    avatarUrl: new FormControl(null),
    account: new FormControl('', Validators.required),
    email: new FormControl('', Validators.email),
    fullName: new FormControl('', Validators.required),
    gender: new FormControl(null, Validators.required),
    birthDay: new FormControl('', Validators.required),
  });
  userInfo: any = {
    avatarUrl: '',
    account: '',
    email: '',
    fullName: '',
    gender: '',
    birthDay: '',
  };
  validForm: boolean = true;
  dataErr: Array<any> = [];
  successToggle: boolean = false;
  validDate: boolean = true;

  public genders: Array<{ text: string; value: number }> = [
    {
      text: this.translate.instant('User_Gender'),
      value: null,
    },
    { text: this.translate.instant('Male_Gender'), value: 1 },
    { text: this.translate.instant('Female_Gender'), value: 2 },
    { text: this.translate.instant('Other_Gender'), value: 3 },
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.value;
  }
  ngOnInit(): void {
    this.getUser();
    this.imgUrl = this.user.FilePath;
    this.getUserAccount();
  }

  getUser(): any {
    this.user = this.appService.getUser();
  }

  createDataRequest(data) {
    const temp = data ? data : this.userForm.value;

    return {
      ID: this.user.AccountID,
      Username: temp.account,
      Name: temp.fullName,
      BirthDate: temp.birthDay
        ? this.intl.formatDate(new Date(temp.birthDay), 'yyyy-MM-ddT00:00:00')
        : null,
      Gender: temp.gender,
      Email: temp.email,
      AvatarUrl: this.imgUrl !== this.user.FilePath ? this.imgUploadUrl : null,
    };
  }

  async getUserAccount() {
    this.validDate = true;
    const dataRequest = {
      iD: this.user.AccountID,
    };
    this.dataErr = [];
    const result = await this.appService.doGET('api/Account', dataRequest);
    if (result && result.Status == 1) {
      this.accountID = result.Data.ID;
      this.userInfo.avatarUrl = result.Data.AvatarUrl;
      this.imgUploadUrl = this.userInfo.avatarUrl;
      this.imgUrl = this.appService.apiRoot + this.userInfo.avatarUrl;
      this.userInfo.account = this.user.UserName;
      this.userInfo.birthDay = new Date(result.Data.BirthDate);
      this.userInfo.email = result.Data.Email;
      this.userInfo.gender = result.Data.Gender;
      this.userInfo.fullName = result.Data.Name;
      if (result.Data.Gender) this.checkNullSelect = true;
      if (result.Data.BirthDate) this.checkNullDate = true;

      this.userForm.patchValue({
        ...this.userInfo,
      });
    } else console.log('Failed');
  }

  async onSubmit() {
    this.validForm =
      this.userForm.valid &&
      !!this.userForm.value.account.trim() &&
      !!this.userForm.value.fullName.trim() &&
      !!this.userForm.value.email.trim() &&
      this.validDate &&
      this.validateEmail();

    if (this.validForm) {
      this.dataErr = [];
      const iD = this.accountID ? this.accountID : this.user.AccountID;
      const dataRequest = this.createDataRequest(null);

      const result = await this.appService.doPUT('api/Account', dataRequest, {
        iD,
      });
      if (result && result.Status === 1 && result != null) {
        this.successToggle = true;
        this.user.AccountID = result.Data;
        this.getUserAccount();
      } else {
        if (!result.Msg) {
          this.dataErr = result.Data;
        } else {
          this.appSwal.showWarning(result.Msg, false);
        }
      }
    }
  }

  onCancel() {
    this.userForm.value.avatarUrl = null;
    this.getUserAccount();
  }

  handleError() {
    this.errorImg = 'assets/images/userlogo.png';
  }

  onSuccessAvatarUpload(e: SuccessEvent) {
    if (e.response && e.response.body && e.response.body.Status == 1) {
      if (e.response.body.Data) {
        let data = e.response.body.Data;
        this.imgUploadUrl = `/${data.DirMedia}${data.MediaNm}`;
        this.imgUrl = this.appService.apiRoot + this.imgUploadUrl;
        this.errorImg = '';
      } else {
        this.notification.showError('Tải tập tin thất bại!');
      }
    } else {
      this.imgUploadUrl = null;
      this.notification.showError('Tải tập tin thất bại!');
    }
  }

  onSuccessDialog() {
    this.successToggle = false;
  }

  checkSelectNull(e) {
    if (!!e) this.checkNullSelect = true;
    else this.checkNullSelect = false;
  }

  checkDateNull(e) {
    if (!!e) this.checkNullDate = true;
    else this.checkNullDate = false;
  }

  checkDateValid() {
    if (this.userForm.value.birthDay != null) {
      const x = new Date();
      if (this.userForm.value.birthDay <= x) this.validDate = true;
      else this.validDate = false;
    } else this.validDate = true;
  }

  validateEmail() {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.userForm.value.email
      )
    ) {
      return true;
    }
    return false;
  }
}
