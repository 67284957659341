<article class="intro-page">
  <div class="row">
    <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
      <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
      <div class="menu" *ngIf="isShowMenu">
        <div class="menu-row-current-border" (click)="editComponent()">
          <div class="menu-item-1">{{'Edit' | translate}}</div>
        </div>
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="background-img">
      <img src="../../assets/images/bg-intro-page.png" />
    </div>
    <div class=" col-xl-1 col-12"></div>
    <div class="col-sm-12 col-md-12 col-xl-10 col-12 content">
      <div class="row menu-bar">
        <div class="tab">
          <div class="tab-scroll" *ngFor="let item of pageList; let i = index">
            <button (click)="onClickItemTab(item, i)" [ngClass]="item.ID == menuID ? 'tab-active tab-item' : 'tab-item'"
              routerLinkActive="active">
              {{ item.Name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class=" col-xl-1 col-12"></div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width">
    <div class="reward-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-intro-page-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg"
      (onSave)="this.onLoadData($event)" (onChangetemp2)="this.changeTemp2($event)">
    </app-intro-page-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
