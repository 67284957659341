import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { AppConsts } from '../services/app.consts';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import videojs from 'video.js';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HeaderTemplate2Component } from '../header-template2/header-template2.component';
import { MyServiceService } from '../my-service.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  // styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  domain: string;
  extension: string[] = ['.mp4', '.ogg'];
  player: videojs.Player;
  isBrowser: any;
  //Info: any;
  postUrl: any;
  templates: any;
  titleItem: any;
  menuTittle: any;
  @Input() menuID: any;
  categoriesList: Array<any> = [];
  pageBody = [];
  isAdminFlg: boolean = false;
  @HostListener('window:resize', ['$event'])
  innerWidth: any;
  innerHeight: any;
  PartiesAndUnions: boolean = false;
  currentPath: string ='';
  isSpecialBackground: boolean = true;
  @Output() isTemplatePage = new EventEmitter();
  @ViewChild(HeaderTemplate2Component) header2;
  pageHeader: any;
  pageFooter: any;
  itemsHeader = ['2','4'];
  headerInfo: any;
  footerInfo: any;
  menuIDMenu: any;
  menuVl:any;
  selectedLangID = AppConsts.defaultLangID;
  selectedFont: any;
  fontList: Array<{ Name: string, ID: string }> = [];
  fontListFilter: Array<{ Name: string, ID: string }> = [];

  templateID: any;
  @Input() itemchild: any;
  bodyWidth = 1140;
  bodyWidthSetup = 1140;
  template31Height: any;
  subscription: any;
  childSubscription: any;
  menuIDSelected: string = '';
  currentURL: string = '';
  NEWS_19 = AppConsts.NewsTemplateNumber.NEWS_19;
  NEWS_20 = AppConsts.NewsTemplateNumber.NEWS_20;
  NEWS_21 = AppConsts.NewsTemplateNumber.NEWS_21;
  NEWS_22 = AppConsts.NewsTemplateNumber.NEWS_22;
  NEWS_23 = AppConsts.NewsTemplateNumber.NEWS_23;
  NEWS_24 = AppConsts.NewsTemplateNumber.NEWS_24;
  NEWS_25 = AppConsts.NewsTemplateNumber.NEWS_25;
  NEWS_26 = AppConsts.NewsTemplateNumber.NEWS_26;
  NEWS_27 = AppConsts.NewsTemplateNumber.NEWS_27;
  NEWS_28 = AppConsts.NewsTemplateNumber.NEWS_28;
  NEWS_29 = AppConsts.NewsTemplateNumber.NEWS_29;
  NEWS_30 = AppConsts.NewsTemplateNumber.NEWS_30;
  NEWS_31 = AppConsts.NewsTemplateNumber.NEWS_31;
  NEWS_32 = AppConsts.NewsTemplateNumber.NEWS_32;
  NEWS_33 = AppConsts.NewsTemplateNumber.NEWS_33;
  NEWS_34 = AppConsts.NewsTemplateNumber.NEWS_34;
  NEWS_35 = AppConsts.NewsTemplateNumber.NEWS_35;
  NEWS_36 = AppConsts.NewsTemplateNumber.NEWS_36;
  NEWS_37 = AppConsts.NewsTemplateNumber.NEWS_37;
  NEWS_38 = AppConsts.NewsTemplateNumber.NEWS_38;
  NEWS_39 = AppConsts.NewsTemplateNumber.NEWS_39;
  NEWS_41 = AppConsts.NewsTemplateNumber.NEWS_41;
  NEWS_40 = AppConsts.NewsTemplateNumber.NEWS_40;
  NEWS_42 = AppConsts.NewsTemplateNumber.NEWS_42;
  NEWS_43 = AppConsts.NewsTemplateNumber.NEWS_43;
  NEWS_45 = AppConsts.NewsTemplateNumber.NEWS_45;
  NEWS_44 = AppConsts.NewsTemplateNumber.NEWS_44;
  NEWS_46 = AppConsts.NewsTemplateNumber.NEWS_46;
  NEWS_47 = AppConsts.NewsTemplateNumber.NEWS_47;
  NEWS_48 = AppConsts.NewsTemplateNumber.NEWS_48;
  NEWS_49 = AppConsts.NewsTemplateNumber.NEWS_49;
  NEWS_50 = AppConsts.NewsTemplateNumber.NEWS_50;
  NEWS_51 = AppConsts.NewsTemplateNumber.NEWS_51;
  NEWS_52 = AppConsts.NewsTemplateNumber.NEWS_52;
  NEWS_53 = AppConsts.NewsTemplateNumber.NEWS_53;
  NEWS_54 = AppConsts.NewsTemplateNumber.NEWS_54;
  NEWS_55 = AppConsts.NewsTemplateNumber.NEWS_55;
  NEWS_56 = AppConsts.NewsTemplateNumber.NEWS_56;
  NEWS_57 = AppConsts.NewsTemplateNumber.NEWS_57;
  // CHUONG_TRINH_DU_AN = 'CHUONG-TRINH---DU-AN';
  // isProgramPage: boolean = false;
  // ctdaMenu: any;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private title: Title,
    private myService: MyServiceService,
    private location: Location,


    @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    // this.childSubscription = this.myService.getMenuChild.subscribe(message => {
    //   if(message) {
    //     this.menuID = message.ParentID;
    //     this.menuItemchild(message);
    //     this.getProgram();
    //   }
    // })
  }
  async ngOnInit(): Promise<void> {
    this.currentURL = this.location.path().replace("/", '');
    await this.menuItem();
    await this.getFontListFilter();
    this.getDataTemplate();
    // this.getProgramMenu();
    if (typeof window !== 'undefined'){
      this.domain = this.appService.apiRoot;
      this.title.setTitle(this.translate.instant('Title-Page'));
      await this.getHeader();
      await this.getFooter();
      //end
      await this.getContentByUrl();
      if(!this.menuID){
        await this.getPageInfo();
      }
      this.getContentWidth();
      await this.getTemplate();

      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      this.subscription = this.myService.currentMessage.subscribe(message =>{
        if(message){          
          if(message.ParentID){}
          this.menuID = message.ID;
          this.getTemplate();
          // if (message.Url == "Chuong-trinh-du-an-phong--chong-lao"
          // || message.Url == "Nha-ve-sinh-hoc-duong"
          // || message.Url == "Vi-su-song-tre-tho"
          // || message.Url == "Hat-mam-nhan-ai"
          // || message.Url == "Tu-van-suc-khoe"
          // || message.Url == "Tam-soat--chan-doan--cham-soc-benh-man-tinh-khong-lay"
          // || message.Url == "Mang-luoi-Cong-tac-vien-suc-khoe-cong-dong"
          // || message.Url == "Cuu-tro"
          // ) {
          //   this.getProgram();
          // }
          
          this.moveToTopOnly();
        }
      }
      );
    }


  }

  ngOnDestroy(): void {
    if (typeof window !== 'undefined'){
      this.subscription?.unsubscribe();
      // this.childSubscription.unsubscribe();
    }
  }
  async getContentByUrl(){
    let url = typeof window !== 'undefined' ? window.location.pathname : '';
    if(url != "/"){
      await this.getMenuByUrl(url);
    }
  }
  async getMenuByUrl(url) {
    const dataRequest = {
      url: url
    }
    const result = await this.appService.doGET('api/Menu/GetByUrl', dataRequest);
    if (result && result.Status === 1) {
      this.menuID = result.Data?.ID;
      this.menuIDSelected = result.Data?.ID;
    }
  }
  getUrl(e){
    this.postUrl = e.UrlPath ? e.UrlPath : '';
    if(this.postUrl){
      this.menuID = e.TempMenuID
      this.getTemplate();
    }

  }

  // async getProgramMenu() {
  //   const dataRequest = {
  //     url: this.CHUONG_TRINH_DU_AN
  //   };

  //   const resultMenu = await this.appService.doGET('api/Menu/GetByUrl', dataRequest);
  //   if (resultMenu && resultMenu.Status === 1) {
  //     this.ctdaMenu = resultMenu.Data;
  //   }
  // }

  async ngOnChanges(changes: SimpleChanges) {
    //this.getInfo();
    await this.onGetCategories();
    await this.getTemplate();
  }

  async getPageInfo() {
    const result = await this.appService.doGET('api/Menu/GetByName', { 'name': 'Trang chủ' });
    if (result && result.Status === 1) {
      this.menuID = result.Data?.ID;
    }
  }

  onReload(e) {
    this.getTemplate();
  }

  onOpenPartiesAndUnions(e) {
    this.PartiesAndUnions = false;
  }

  updateMeta(): void {

  }

  onImgError(event) {
    event.target.src = AppConsts.DEFAULT_IMAGE;
  }
  async getContentWidth(){
    const result = await this.appService.doGET('api/PortalDesign/GetWidth', {});
    if(result && result.Status === 1 && typeof window !== 'undefined'){
      this.bodyWidthSetup = Number(result.Data[0]);
      this.bodyWidth = this.bodyWidthSetup;
      if(this.bodyWidth > window.innerWidth){
        this.bodyWidth = ((window.innerWidth * 80)/100);
      }
    }
  }

  async getTemplate() {
    const result = await this.appService.doGET('api/PortalDesign/GetDesign', {
      menuID: this.menuID
    });
    if (result && result.Status === 1) {
      this.templates = result.Data;
      var bodys = [];
      for (let i = 0; i < this.templates.length; i++) {
        var item = this.templates[i];
        var groupId = item.GroupID;
        var layout = item.Layout;
        let index = bodys.findIndex(x => x.id == groupId && x.layout == layout);
        if (index != -1) {
          bodys[index].items.push(item)
        } else {
          var itemBody = {
            id: groupId,
            layout: layout,
            items: [item]
          }
          bodys.push(itemBody);
        }
      }
      this.pageBody = bodys;

    } else {
      // show popup error
    }
  }
  transferData(e){
     this.titleItem = e;


  }
  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/Search', {
      searchText: '',
      langID: this.language.get()
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data;
    }
  }

  // btnTemplate() {
  //   this.router.navigate([AppConsts.page.templatePage]);
  // };

  getConnectedList(): any[] {
    return this.pageBody.map(x => `${x.id}`);
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;

    if(this.bodyWidth > window.innerWidth){
      this.bodyWidth = ((window.innerWidth * 80)/100);
    }
    if(window.innerWidth >= this.bodyWidth){
      if(window.innerWidth > this.bodyWidthSetup){
        this.bodyWidth = this.bodyWidthSetup;
      }else {
        this.bodyWidth = ((window.innerWidth * 80)/100);
      }
    }
  }
  checkNumberIsRound(i){
    if(i % 2 == 0) return true;
    else return false;
  }
  //coding drap and drop header
  //start
  async getHeader() {
    const result = await this.appService.doGET('api/PortalHeader', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.headerInfo = result.Data.Data;
      this.pageHeader = [result.Data.HeaderType];


      // localStorage.setItem('pageHeader', JSON.stringify(this.pageHeader));
    }

  }
  async getFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.footerInfo = result.Data.Data;
      this.pageFooter = [result.Data.FooterType];
      //localStorage.setItem('pageFooter', JSON.stringify(this.pageFooter));
    }
  }
  onActivate(elementRef) {
    if (elementRef.tittle) {
      this.menuTittle = elementRef.tittle;
    } else {
      this.menuTittle = '';
    }
  }
  async menuItem(event?) {
    // this.isProgramPage = false;
    if (!event) {
      const result = await this.appService.doGET('api/Menu/GetParentByUrl', {
        url: this.currentURL
      });
      if (result && result.Status === 1) {
        if (result.Data.ParentID) {
          const data = await this.appService.doGET('api/Menu/GetParentAndChildrendByParentID', {
            ParentID: result.Data.ParentID
          });
          this.menuVl = data.Data[0];
        }
        else {
          this.menuVl = result.Data;
        }
      }
    } else {
      this.menuIDMenu = event.ID;
      this.menuVl = event;
    }
    if (this.menuVl && this.menuVl.Childrens) {
      let child = this.menuVl.Childrens.find(m => m.Url == this.currentURL);
      this.menuItemchild(child);
    }
  }
  menuItemchild(event) {
    if (event) {
      this.menuID = event.ID;
      this.itemchild = event;
      this.moveToTopOnly();
    }
  }
  moveToTop(event){
    this.menuVl = event;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getTemplateWith(group) {
    return group.layout;
  }
  onTransHeight(event){
    if(event){
      this.template31Height = event;

      let newTemplate23 = document.getElementById("newTemplate23") ;
      if(newTemplate23 != null){
        newTemplate23.style.height = this.template31Height == 'auto'? this.template31Height: this.template31Height -80 +'px';
      }

    }
  }
  //end
  moveToTopOnly(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async getDataTemplate() {
    const result = await this.appService.doGET('api/PortalDesign/SearchTemplate', {});
    if (result && result.Status === 1) {
      this.selectedFont = this.fontList.find((s) => s.ID == result.Data[0].FontID).ID;
      this.templateID = result.Data[0].ID;
      this.onSetFont();
    }
  }

  onSetFont() {
    const bodyElement = document.body;
    const fontName = this.fontList.find((s) => s.ID == this.selectedFont).Name;
    bodyElement.style.fontFamily = '"' + fontName + '"';
  }

  async getFontListFilter() {
    const result = await this.appService.doGET('api/PortalBody/GetFont', {});
    if (result && result.Status === 1) {
      this.fontList = result.Data;
      this.fontListFilter = this.fontList.slice();
    }
  }

  fontHandleFilter(value) {
    this.fontListFilter = this.fontList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}

