import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { TranslateService } from '@ngx-translate/core';
import { AppSwal } from 'src/app/services/app.swal';
import { AppGuid } from 'src/app/services/app.guid';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import {
  FileRestrictions,
  UploadsModule,
  SelectEvent,
  ClearEvent,
  RemoveEvent,
  FileInfo,
} from '@progress/kendo-angular-upload';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Notification } from '../services/app.notification';
import * as e from 'express';
import { SearchService } from '../services/search.service';
import { MyServiceService } from '../my-service.service';

@Component({
  selector: 'app-router-link',
  templateUrl: './router-link.component.html',
  // styleUrls: ['./router-link.component.scss'],
})
export class RouterLinkComponent implements OnInit, OnChanges {
  @Input() pageSelectedID = '';
  @Input() ID;
  @Input() templateID: any;
  @Input() info;
  @Input() galleryData: any;
  @Input() menuVl: any;
  @Input() itemchild: any;
  @Input() menuTittle: any;
  @Output() onSave = new EventEmitter();
  @Output() menuItem = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Input() menuID: any;
  templates: any;
  pageBody = [];
  itemmenu: any;
  dataFiles = [];
  folderParentage = [];
  infoFileOpened = false;
  public dataRouterLink = false;
  currentFolder = {
    ID: this.guid.empty,
    Name: '',
    ParentID: this.guid.empty,
    Url: '',
    Childrens: []
  };

  dataGalleryItem: any;
  itemmenuDad: any;
  dataGalleryItemtemp: any;
  loading = false;
  isFolderPopup = false;
  isImageChosen = false;
  isChoosingImage = false;
  index = -1;

  public NUM_PAGING_SKIP = 0;
  public NUM_PAGING_TAKE = 7;
  dataFileSkip = this.NUM_PAGING_SKIP;
  dataFilePageSize = this.NUM_PAGING_TAKE;
  public dataFileState: State = {
    skip: this.dataFileSkip,
    take: this.dataFileSkip + this.dataFilePageSize,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  currentUrl: any;
  currentPath: any;

  searchText: any;

  subscription: any;
  childScription: any;
  constructor(
    private router: Router,
    private appService: AppService,
    private authenticationService: AuthenticationService,
    private appSwal: AppSwal,
    private guid: AppGuid,
    public appControls: AppControls,
    public appUtils: AppUtils,
    private notification: Notification,
    private translate: TranslateService,
    private location: Location,
    private search: SearchService,
    private myService: MyServiceService,
    private activatedRoute: ActivatedRoute
  ) {
    this.childScription = this.myService.getMenuChild.subscribe(message => {
      this.itemmenu = message;
      if (message) {
        if (message.ParentID) {
          // Do something if ParentID exists
        }
        this.menuID = message.ID;
        // this.getTemplate();
      }
    }
    );
  }

  ngOnInit() {
           
    this.currentPath = this.location.path().replace("/", '');
    this.subscription = this.myService.currentMessage.subscribe(message => {
      this.itemmenu = message;
      if (message) {
        if (message.ParentID) {
          // Do something if ParentID exists
        }
        this.menuID = message.ID;
        // this.getTemplate();
      }
    }
    );
    this.getMenuItem(this.currentPath);
  }
  ngOnDestroy(): void {
    if (typeof window !== 'undefined'){
      this.subscription.unsubscribe();
      this.childScription.unsubscribe();
    }
  }

  onGetMenuItem(e): void {
    this.menuItem.emit(e);
  }

  ngOnChanges(changes: SimpleChanges) {   
    if(this.menuVl){
      if (this.menuVl.Url && (this.menuVl.Url.indexOf("https://") !== -1 || this.menuVl.Url == ".")) {
        return;
      }
      if (this.menuVl.UrlPath && (this.menuVl.UrlPath.indexOf("https://") !== -1 || this.menuVl.Url == ".")) {
        return;
      }
    }
    this.onGetMenuItem(this.currentFolder);
    if (this.menuVl && this.menuVl != undefined) {
      this.currentFolder = Object.assign({}, this.menuVl);
      this.dataRouterLink = true;

      // this.dataRouterLink[0] = this.currentFolder;
    }
    if (this.menuVl?.Name == 'Trang chủ' && this.currentFolder != undefined) {
      this.currentFolder['Name'] = '';
      this.dataRouterLink = false;
      this.router.navigate([AppConsts.page.main], { queryParams: {} });
      this.setFolder(this.menuVl);
      this.currentUrl = this.router.url;
      this.router.events.subscribe((val) => {
        this.currentUrl = this.location.path();
      });
    }
  }

  goRoot() {
    var tempFolder = {
      ID: this.menuVl.empty,
      Name: this.menuVl.empty,
      ParentID: this.menuVl.empty,
    };
    this.dataRouterLink = false;
    this.setFolder(tempFolder);
    this.itemchild = null;
  }

  goFolder() {
    this.itemchild = null;
    this.router.navigate([this.currentFolder.Url]);
  }

  async setFolder(menuVl) {
    if (menuVl != null && menuVl != undefined) {
      this.currentFolder.ID = menuVl.ID;
      this.currentFolder.Name = menuVl.Name;
      this.currentFolder.ParentID = menuVl.ParentID;

      if (menuVl.ID == this.guid.empty) {
        this.folderParentage = [];
      } else {
        var index = this.folderParentage.findIndex((x) => x.ID == menuVl.ID);
        if (index == -1) {
          this.folderParentage.push({
            ID: menuVl.ID,
            Name: menuVl.Name,
          });
        } else {
          var numbToRemove = this.folderParentage.length - index;
          for (let i = 0; i < numbToRemove; i++) {
            var temp = this.folderParentage.splice(index + 1, 1);
          }

          this.router.navigate([AppConsts.page.main], { queryParams: {} });
        }
      }
    }
  }

  checkRemove() {
    this.currentUrl = this.router.url;

    if (this.currentUrl.includes('/template-page')) {
      const indexOfFirst = this.currentUrl.indexOf('=');
      this.currentUrl = this.currentUrl.slice(indexOfFirst + 1);
      const indexOf2 = this.currentUrl.indexOf('&');
      var a = this.currentUrl.slice(0, indexOf2);

      var b = this.currentUrl.slice(this.currentUrl.indexOf('=') + 1);

      localStorage.setItem('userName', a);
      localStorage.setItem('tokenPortal', b);

      // remove and navigate to template-page
      this.router.navigate([AppConsts.page.templatePage], { queryParams: {} });
    }
    if (this.currentUrl.includes('/intro-page') || this.currentUrl === '/login' || this.currentUrl === '/settings/change-password' || this.currentUrl === '/user-info' || this.currentUrl === '/settings/support' || this.currentUrl === '/signup')
      return true;
    else return false;


  }


  // checkSearchPage() {
  //   this.router.events.subscribe((val) => {
  //     this.currentUrl = this.location.path();
  //   });
  //   if (this.currentUrl.includes(AppConsts.page.searchPage)) {
  //     this.searchText = this.search.getSearchText();
  //     return true;
  //   } else return false;
  // }

  async getMenuItem(url) {
    const result = await this.appService.doGET('api/Menu/GetParentByUrl', {
      url: url
    });
    if (result && result.Status === 1) {
      if (result.Data.ParentID) {
        const data = await this.appService.doGET('api/Menu/GetParentAndChildrendByParentID', {
          ParentID: result.Data.ParentID
        });            
        this.menuVl = data.Data[0];
      }        
      else {
        this.menuVl = result.Data;
      }
    }
  }

  checkPage(item) {
    this.itemmenuDad = item;
    if (!this.itemmenu || this.itemmenu.Name === '') {
      if (this.menuVl && this.currentPath === this.menuVl.UrlPath) {
        this.itemmenu = this.menuVl;       
      } else {
        let listChild = [];
        listChild = this.currentFolder.Childrens;
        var index = listChild.findIndex((x) => x.Url == this.currentPath);
        this.itemmenu = this.currentFolder.Childrens[index];
      }
    }
    return item.toLowerCase() != 'trang chủ' && item.toLowerCase() !== '';
  }

  // async getTemplate() {
  //   console.log('router-link.component.ts, getTemplate');
    
  //   const result = await this.appService.doGET('api/PortalDesign/GetDesign', {
  //     menuID: this.menuID
  //   });
  //   if (result && result.Status === 1) {
  //     this.templates = result.Data;
  //     var bodys = [];
  //     for (let i = 0; i < this.templates.length; i++) {
  //       var item = this.templates[i];
  //       var groupId = item.GroupID;
  //       var layout = item.Layout;
  //       let index = bodys.findIndex(x => x.id == groupId && x.layout == layout);
  //       if (index != -1) {
  //         bodys[index].items.push(item)
  //       } else {
  //         var itemBody = {
  //           id: groupId,
  //           layout: layout,
  //           items: [item]
  //         }
  //         bodys.push(itemBody);
  //         // if (layout < 100 && this.templates.findIndex(x => x.Layout == (100 - layout)) == -1) {
  //         //   if (layout < 50) {
  //         //     bodys.push({
  //         //       id: groupId + 100,
  //         //       layout: 100 - layout,
  //         //       items: []
  //         //     });
  //         //     bodys.push(itemBody);
  //         //   } else {
  //         //     bodys.push(itemBody);
  //         //     bodys.push({
  //         //       id: groupId + 100,
  //         //       layout: 100 - layout,
  //         //       items: []
  //         //     });
  //         //   }

  //         // } else {
  //         //   bodys.push(itemBody);
  //         // }
  //       }
  //     }
  //     this.pageBody = bodys;

  //   } else {
  //     // show popup error
  //   }
  // }
}
