import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { AppSwal } from 'src/app/services/app.swal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-template-21',
  templateUrl: './news-template-21.component.html',
  // styleUrls: ['./news-template-21.component.scss']
})
export class NewsTemplate21Component implements OnInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate6 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Input() isPreview: boolean;
  @Output() deleteBodyItem = new EventEmitter();
  @Output() changeTemplate2 = new EventEmitter();

  menuIntro: any;
  isActive = false;
  menuNews: any;
  menuEDocument: any;
  dataErr: any;
  menuIndex: any;
  subCategories: any;
  postDetailPath = AppConsts.page.postDetail;

  constructor(
    private router: Router,
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private appSwal: AppSwal,
    private eventEmitterService: EventEmitterService
  ) {}

  ngOnInit(): void {
    this.bootstrapNum = 12 / this.topNumber;
    if(typeof this.infoFromMain == 'string'){
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }  
    this.getPostTittle();
    this.getTopNews();
    //this.onGetSubcategories(this.infoFromMain.MenuID);
    this.galleryData = {
      ID: this.ID,
      OrderIdx: this.OrderIdx,
      infoFromMain: this.infoFromMain,
      GroupID: this.itemTemplate?.GroupID,
      Layout: this.itemTemplate?.Layout,
      GroupIndex: this.itemTemplate?.GroupIndex,
    };
    // console.log(this.infoFromMain)
    if (this.ID == null && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
          NewFlg: 'NewFlg',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
          NewFlg: 'NewFlg',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
          NewFlg: 'NewFlg',
        },
      ];
    }
    // console.log(`pageID from template: ${this.pageSelectedID}`);
  }
  async getTopNews() {

    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        // menuID: '038490DF-8F35-4B08-B5F2-942367AE77B3',
        menuID: this.infoFromMain.MenuID,
        topNum: this.infoFromMain.TopNumber,
      }
    );
    if (result && result.Status === 1) {
      this.postTittle = result.Data.dataMenu.Name;
      this.viewMore = result.Data.dataMenu.UrlPath;
      this.topNews = result.Data.topNews;
      
    }
    //console.log(this.topNews);
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }

  // changeSubcategories(newSubcategories: any) {
  //   if (typeof this.infoFromMain === "string") {
  //     this.infoFromMain = JSON.parse(this.infoFromMain);
  //   }
  //   this.infoFromMain.TopNumber = newSubcategories.Info.TopNumber;
  //   this.infoFromMain.MenuID = newSubcategories.Info.MenuID;
  //   this.getTopNews();
  //   // this.onGetSubcategories(newSubcategories);
  //   let newTemp = {subCategories: newSubcategories,
  //                 pageBodyIndex: this.pageBodyIndex,
  //                 layoutGroupIndex: this.layoutGroupIndex,
  //                 index: this.newsDecorationIndex};
  //   this.changeTemplate6.emit(newTemp);
  // }

  // async onGetSubcategories(newSubcategories: any) {
  //   if (!newSubcategories) {
  //     this.subCategories = this.categoriesList;
  //     return;
  //   }
  //   var dataRequest = {
  //     // parentID:  "7932cfd8-6169-4c06-92a9-195e9e363f13"
  //     langID: this.language.get(),
  //     parentID: newSubcategories,
  //   };
  //   const result = await this.appService.doGET(
  //     'api/PortalBody/GetMenuByParentID',
  //     dataRequest
  //   );
  //   if (result && result.Status === 1) {
  //     this.subCategories = result.Data;
  //   } else {
  //     if (!result.Msg) {
  //       this.dataErr = result.Data;
  //     } else {
  //       this.appSwal.showWarning(result.Msg, false);
  //     }
  //   }
  // }

  goToPost(item?, idx?) { 
    console.log("item.UrlPath", item.UrlPath);
    
    this.router.navigateByUrl(AppConsts.page.postDetail + '/' + item.UrlPath);
    return;
  }
  changeTemp2(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.getTopNews();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate2.emit(newTemp);
  }
  redirectToNotify(){
    this.router.navigate([AppConsts.page.notifyPage], { queryParams: { menuID: this.infoFromMain.MenuID }});
  }
}
