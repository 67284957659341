<section class="electronic-text">
  <div class="row">
    <div class="col-md-1 col-12"></div>
    <div class="col-md-10 col-12 router">
      <div class="row">
        <div class="col-md-4 col-12">
          <label class="field-name">
            <span [textContent]="'Keyword' | translate"></span>
          </label>
          <div [ngClass]="'control control-search'">
            <div class="search-place">
              <input
                [(ngModel)]="searchOption.searchText"
                (keypress)="onSearchKeyPress($event)"
                [placeholder]="'Electronic_Text_Search' | translate"
              />
              <i
                class="fa fa-times-circle-o"
                (click)="onRemoveSearchText()"
                *ngIf="searchOption.searchText !== ''"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <label class="field-name">
            <span [textContent]="'Unit' | translate"></span>
          </label>
          <div [ngClass]="'control control-search'">
            <div class="search-place dropdown">
              <kendo-combobox
                [data]="unitsFilter"
                [textField]="'Name'"
                [valueField]="'ID'"
                [valuePrimitive]="true"
                [filterable]="true"
                [(ngModel)]="searchOption.UnitPublishID"
                [placeholder]="'All' | translate"
                (filterChange)="unitsHandleFilter($event)"
                (valueChange)="onValueChange()"
              >
                <ng-template kendoDropDownListNoDataTemplate>{{
                  "NoRecordsAvailable" | translate
                }}</ng-template>
              </kendo-combobox>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-6">
          <label class="field-name">
            <span [textContent]="'Field' | translate"></span>
          </label>
          <div [ngClass]="'control control-search'">
            <div class="search-place dropdown">
              <kendo-combobox
                [data]="fieldsFilter"
                [textField]="'Title'"
                [valueField]="'ID'"
                [valuePrimitive]="true"
                [filterable]="true"
                [(ngModel)]="searchOption.OperationID"
                [placeholder]="'All' | translate"
                (filterChange)="fieldsHandleFilter($event)"
                (valueChange)="onValueChange()"
              >
                <ng-template kendoDropDownListNoDataTemplate>{{
                  "NoRecordsAvailable" | translate
                }}</ng-template>
              </kendo-combobox>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-12">
          <button class="btn-search" (click)="onSearch()">
            <span>{{ "Label_Btn_Search" | translate }}</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="document">
          <kendo-grid
            [data]="dataGridGridDataResult"
            [selectable]="dataGridSelectableSettings"
            (pageChange)="onGridPageChange($event)"
            (selectedKeysChange)="ondataItemselectedKeysChange()"
            [pageSize]="dataGridPageSize"
            [skip]="dataGridSkip"
            [pageable]="true"
            [kendoGridSelectBy]="'ID'"
            [selectedKeys]="dataGridSelection"
            [loading]="loading"
            [sortable]="dataGridSort"
            [sort]="dataGridSortByField"
            (sortChange)="ondataItemsortChange($event)"
            [filterable]="false"
            [groupable]="false"
            [group]="dataGridState.group"
            [filter]="dataGridState.filter"
            [height]="600"
            (dataStateChange)="onGridDataStateChange($event)"
          >
            <kendo-grid-column media="(max-width: 450px)">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <!-- template for mobile -->
                <dl>
                  <!-- <dt>{{'STT' | translate}}</dt>
                  <dd>{{ rowIndex + 1 }}</dd> -->
      
                  <dt>{{'Text_Name' | translate}}</dt>
                  <dd>{{ dataItem.Name }}</dd>
      
                  <dt>{{'Unit' | translate}}</dt>
                  <dd>{{ dataItem.UnitName }}</dd>
                  
                  <dt>{{'Field' | translate}}</dt>
                  <dd>{{ dataItem.OperationName }}</dd>

                  <dt>{{'Download' | translate}}</dt>
                  <dd><img
                    src="../../assets/images/Download.png"
                    class="img"
                    (click)="downloadItem(dataItem.Path)"
                  /></dd>
                </dl>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [width]="30"
              [field]="'STT'"
              [title]="'STT' | translate"
              [headerClass]="{ headerCommon: true }"
              media="(min-width: 450px)"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div class="index">{{ rowIndex + 1 }}</div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            media="(min-width: 450px)"
              [width]="150"
              [field]="'Name'"
              [title]="'Text_Name' | translate"
              [headerClass]="{ headerCommon: true }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <label class="text-name">
                  {{ dataItem.Name }}
                </label>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            media="(min-width: 450px)"
              [width]="80"
              [field]="'UnitName'"
              [title]="'Unit' | translate"
              [headerClass]="{ headerCommon: true }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <label>
                  {{ dataItem.UnitName }}
                </label>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            media="(min-width: 450px)"
              [width]="60"
              [field]="'OperationName'"
              [title]="'Field' | translate"
              [headerClass]="{ headerCommon: true }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <label>
                  {{ dataItem.OperationName }}
                </label>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            media="(min-width: 450px)"
              [width]="30"
              [field]="'Path'"
              [title]="'Download' | translate"
              [headerClass]="{ headerCommon: true }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <img
                  src="../../assets/images/Download.png"
                  class="img"
                  (click)="downloadItem(dataItem.Path)"
                />
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages
              [noRecords]="'NoRecordsAvailable' | translate"
              [pagerOf]="'pagerOf' | translate"
              [pagerItems]="'pagerItems' | translate"
            >
            </kendo-grid-messages>
          </kendo-grid>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-12"></div>
  </div>
</section>
