import { environment } from './../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output ,ViewChild} from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { AppService } from '../../services/app.service';
import { AppConsts } from '../../services/app.consts';
import { Router } from '@angular/router';
import { Notification } from '../../services/app.notification';
import { EventEmitterService } from '../../event-emitter.service';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { SelectEvent } from '@progress/kendo-angular-upload';
import { AppSwal } from '../../services/app.swal';
import { DomSanitizer } from '@angular/platform-browser';
import { log } from 'console';

@Component({
  selector: 'app-opend-dialog-video',
  templateUrl: './opend-dialog-video.component.html',
  // styleUrls: ['./opend-dialog-video.scss'],
})
export class OpendDialogVideoComponent implements OnInit {
  // @Output() srcURL = new EventEmitter();
  @Input() srcURL: any;
  @ViewChild('scrollview') scrollview;
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  mediaCommonTabs: any;
  selectedCommonID: string = '';
  mediaNews: any;
  mediaType: number = 0;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private appSwal: AppSwal,
    private sanitizer: DomSanitizer
  ) { }


  async ngOnInit(): Promise<void> {
    this.getVideo()
    console.log(this.srcURL)
  }

  getVideo(): any {
    let videos = this.srcURL;
    let videourl = videos.split('"').join('');
    let joinedString = videourl.toString();
    let joinedString1 = joinedString.replace('[', '').replace(']', '');
    let video;
    if (videos) {
      let videoUrl = 'https://www.youtube.com/embed/' + joinedString1;
      video = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
      return video;
    }
    return '';
  }

}

