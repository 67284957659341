import { Component, OnInit, Input } from '@angular/core';
import { AppConsts } from '../services/app.consts';
import { AppInfo } from '../services/app.info';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { Meta, Title } from '@angular/platform-browser';
import { AppSwal } from 'src/app/services/app.swal';
import { Notification } from '../services/app.notification';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppRouter } from '../app.router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  // styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isLoaded: boolean = false;
  isAddMenu = false;
  control: any;
  controlDefault = true;
  menuOpened = false;
  infoOpened = false;
  isParentUrl = false;
  @Input() isAdminFlg = true;
  user: any;
  footer: any;
  menu: any;
  menuFilter: any;
  menuSelected: any;
  parent: any;
  parentUrl: any;
  dataMenu: any;
  dataMenuItem: any;
  btnDataMenu: Array<any>;
  dataErr: any;
  routes = AppRouter.routes;
  postList: string;
  pageName = '';
  popupClass = "popup-width";
  constructor(private language: AppLanguage,
    private appService: AppService,
    private appInfo: AppInfo,
    private appSwal: AppSwal,
    private authenticationService: AuthenticationService,
    public appConsts: AppConsts,
    public appControls: AppControls,
    private notification: Notification,
    private translate: TranslateService,
    private meta: Meta) {
    this.appService.getUserName();
    this.user = this.appService.user;
    this.postList = AppConsts.page.postList;
  }

  async ngOnInit() {
    // this.getControl();
    this.setDefault();
    // this.getPageName();
    await this.getMenu();
    // this.getDataMenu();
    this.getFooter();
  }

  // async getPageName() {
  //   this.pageName = await this.appControls.getPageNamePortal();
  // }
  // async getControl() {
  //   this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.receiveDocument);
  //   this.controlDefault = false;
  // }

  onReload() {
    this.getMenu();
    this.getFooter();
  }
  // async getDataMenu() {
  //   const dataMenuResult = await this.appService.doGET('api/Portal/GetMenuNoChild', {'langID': this.language.get()});
  //   if(dataMenuResult && dataMenuResult.Status === 1){
  //     this.dataMenu = dataMenuResult.Data;
  //   }
  // }
  async getMenu() {
    const menuResult = await this.appService.doGET('api/Portal/GetMenuNoChild', { 'langID': this.language.get() });
    if (menuResult && menuResult.Status === 1) {
      this.menu = menuResult.Data;
      this.menuFilter = this.menu.slice();
    }
  }

  async getFooter() {
    const result = await this.appService.doGET('api/Portal/GetInfo', { 'langID': this.language.get() });
    if (result && result.Status === 1) {
      if (result.Data && result.Data.Info != null) {
        this.footer = result.Data;
        this.isLoaded = true;
        this.setInfoPage();
      } else {
        // chưa có thông tin footer thì hiển thị data mặc định
        this.footer = {
          "Info": {
            "Language": "vi-VN",
            "CopyRight": "",
            "Address": "Địa chỉ",
            "Phone": "(84-8) ",
            "Fax": "(84-8) ",
            "Email": "mail@gmail.com",
            "Title": "Tên công ty"
          }
        }
        this.isLoaded = true;
        this.setInfoPage();
      }

    }
    // console.log(this.footer);
    this.dataMenu = [];
    if (this.footer && this.footer.Info.MenuIDs) {
      JSON.parse(this.footer.Info.MenuIDs).forEach(e => {
        var item = this.menu.filter(m => m.ID == e.toLowerCase());
        if (item.length != 0) {
          this.dataMenu.push(item[0]);
        }
      });
    }

  }

  async updateFoorterInfo(lang?) {
    const info = this.footer.Info;
    const dataRequest = {
      Title: 'FooterInfo',
      LangID: lang,
      UrlPath: 'footer-info',
      MenuID: JSON.stringify(["127839b3-31b8-45ae-a1bd-654cd45a1be9"]),
      Summary: JSON.stringify({
        Title: info?.Title,
        Address: info?.Address,
        Phone: info?.Phone,
        Fax: info?.Fax,
        Email: info?.Email,
        Description: info?.Description,
        Language: lang,
        MenuIDs: info?.MenuIDs,
        TemplateID: info?.TemplateID
      }),
      ImageUrl: ''
    }
    const result = await this.appService.doPOST('api/Portal/UpdateFooterInfo', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    }
    else {
      if (!result.Msg) {
        this.dataErr = result.Data;
        // var count = 0;
        // for (var prop in this.dataErr[0]) {
        //   count++;
        // }
        // this.customCss = count;
      } else {
        // this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  setDefault() {
    this.dataMenuItem = {
      Name: '',
      ID: '',
      ParentID: "",
      OrderIndex: 0,
      Url: "",
      PageID: null,
      IsButton: false,
      Childrens: []
    }
    this.isParentUrl = false;
    this.parentUrl = null;
  }

  setInfoPage() {
    let Info = this.footer.Info;
    let iconUrl = '/favicon.ico';
    this.appInfo.setSchema(Info.SchemaType, Info.RootURL, Info.Phone, Info.Email,
      Info.Address, iconUrl, Info.Latitude, Info.Longtitude, Info.SchemaContext, Info.Name);
  }
  menuHandleFilter(value) {
    this.menuFilter = this.menu.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  onChangeMenu(e) {
    this.menuSelected = this.menu.filter(m => m.ID == e);
  }
  async onDeleteMenu(item) {
    // const dataRequest = {
    //   iD: item.ID,
    //   langID: this.language.get()
    // };

    const option = await this.appSwal.showWarning(this.translate.instant('DeleteMenu_AreYouSure'), true);
    if (option) {
      var index = JSON.parse(this.footer.Info.MenuIDs).indexOf(item.ID);
      JSON.parse(this.footer.Info.MenuIDs).splice(index, 1);
      this.updateFoorterInfo();
      this.onReload();
    }
  }
  async onEditMenu(item) {
    // const dataRequest = {
    //   iD: item.ID,
    //   langID: this.language.get()
    // };
    if (item.ID) {
      this.menuOpened = true;
      this.dataMenuItem = item;
      if (item.ParentID && item.ParentID != '') {
        this.isParentUrl = true;
        this.parent = this.menuFilter.filter(e => e.ID == item.ParentID);
        this.parentUrl = this.parent[0].UrlPath;
      }
    }
    else {
      this.infoOpened = true;
    }

    // const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    // if (option) {
    //   const result = await this.appService.doDELETE('api/Menu', dataRequest);
    //   if (result && result.Status === 1) {
    //     this.notification.showSuccess(result.Msg);
    //     this.onReload();
    //     this.onCloseAddMenu();
    //     // this.dataGridSelection = [];
    //     // this.allowMulti = false;
    //   } else {
    //     this.appSwal.showWarning(result.Msg, false);
    //   }
    // }
  }
  onOpenAddMenu() {
    this.setDefault();
    this.isAddMenu = true;
  }
  onCloseDialog() {
    this.setDefault();
    this.isAddMenu = false;
    this.menuOpened = false;
    this.infoOpened = false;
  }
  onDataMenuClick(item) {
    this.getBtnFunctionData(item);
  }
  getBtnFunctionData(item) {
    this.btnDataMenu = [
      {
        id: 'edit',
        text: this.translate.instant('Edit'),
        icon: 'edit',
        click: () => {
          this.onEditMenu(item);
        }
      }
    ]
    if (item.ID) {
      var func = {
        id: 'delete',
        text: this.translate.instant('Delete'),
        icon: 'delete',
        click: () => {
          this.onDeleteMenu(item);
        }
      }
      this.btnDataMenu.push(func);
    }
  }
  onSaveDialog(lang?) {
    if (this.isAddMenu) { this.addMenu(); } else if (this.menuOpened) { this.updateMenu(); } else { this.updateInfo(lang) }
  }
  addMenu() {
    if (this.menuSelected.length != 0) {
      this.dataMenuItem.ID = this.menuSelected[0].ID;
      this.dataMenuItem.Name = this.menuSelected[0].Name;
      this.dataMenuItem.OrderIndex = this.menuSelected[0].OrderIndex;
      this.dataMenuItem.PageID = this.menuSelected[0].PageID;
      this.dataMenuItem.ParentID = this.menuSelected[0].ParentID ? this.menuSelected[0].ParentID : '';
      this.dataMenuItem.Url = this.menuSelected[0].Url;
      let data = this.dataMenu.filter(d => d.ID == this.dataMenuItem.ID);
      if (data.length == 0) {
        this.dataMenu.push(this.dataMenuItem);
        JSON.parse(this.footer.Info.MenuIDs).push(this.dataMenuItem.ID);
        this.updateFoorterInfo();
      }
      else {
        this.notification.showError('Trùng chuyên mục');
      }
    }
    else {
      // this.appSwal.showWarning("Fail", false);
    }
    this.setDefault();
    this.isAddMenu = false;
  }
  updateMenu() {
    this.updateFoorterInfo();
    this.setDefault();
    this.menuOpened = false;
  }
  updateInfo(lang) {
    this.updateFoorterInfo(lang);
    this.setDefault();
    this.infoOpened = false;
  }
  parseURL(url) {
    if (url == AppConsts.RECEIVE_QUESTIONS || url == AppConsts.RECEIVE_DOCUMENT) {
      return AppConsts.page.portal;
    } else {
      return url;
    }
  }
}
