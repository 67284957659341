<section class="row mx-0 quiz-container">
  <!-- coding header -->
  <div class="header-area" *ngIf="isSearchTab">
    <div *ngFor="let item of pageHeader" class="header-box">
      <app-header-template1 *ngIf="item == '1'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
      </app-header-template1>
      <app-header-template2  *ngIf="item == '2'"
        (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
        (menuSearch)="menuSearch($event)">
      </app-header-template2>
      <app-header-template3 *ngIf="item == '3'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
      </app-header-template3>
      <app-header-template4  *ngIf="item == '4'"
        (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
        (menuSearch)="menuSearch($event)"  [templateID]="TemplateID" [pageSelectedID]="PageSelectedID">
      </app-header-template4>
    </div>
  </div>
  
  <!-- coding header -->
  <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
    <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
    <div class="menu" *ngIf="isShowMenu">
      <div class="menu-row-current" (click)="deleteComponent()">
        <div class="menu-item-3">{{'Delete' | translate}}</div>
      </div>
    </div>
  </div>
  <div [ngClass]="isSearchTab ? 'wrapper-area-flex' : 'wrapper-area' ">
    <div class="wrapper">
      <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab [title]="'Send_Quiz' | translate" [selected]="!isSearchTab">
          <ng-template kendoTabContent>
            <!-- <div class="col-md-1"></div> -->
            <div class="row">
              <div class="col-md-9 col-12 send-background">
                <h3 class="quiz-title">{{ "Quiz_Note_Title" | translate }}</h3>
                <div class="row mx-0">
                  <div class="col-md-12 col-12">
                    <form class="quiz-form" [formGroup]="userForm">
                      <fieldset class="quiz-form-fieldset">
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "Quiz_Field" | translate}} <span class="required"> *</span></div>
                            <div class="quiz-input-item">
                              <kendo-combobox formControlName="LVID" [placeholder]="'Contact_Field' | translate" [data]="lvListFilter"
                                [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true" [filterable]="true"
                                (filterChange)="lvHandleFilter($event)" required>
    
                              </kendo-combobox>
                              <div class="quiz-error" *ngIf="!validForm && !userForm.get('LVID').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "Quiz_FullName" | translate}} <span class="required"> *</span></div>
                            <div class="quiz-input-item">
                              <input formControlName="AccountName" [placeholder]="'Quiz_Input_FullName' | translate" kendoTextBox
                                required />
                              <div class="quiz-error" *ngIf="!validForm && !userForm.get('AccountName').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "DataQuestion_Email" | translate}} <span class="required"> *</span></div>
                            <div class="quiz-input-item">
                              <input type="AccountEmail" formControlName="AccountEmail" [placeholder]="'Quiz_Input_Email' | translate"
                                kendoTextBox required />
                              <div class="quiz-error" *ngIf="
                                  !validForm &&
                                  (!userForm.get('AccountEmail').valid ||
                                    !validateMail(userForm.value.AccountEmail))
                                ">
                                {{ "Contact_Invalid_Email" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "DataQuestion_Phone" | translate}} <span class="required"> *</span></div>
                            <div class="quiz-input-item">
                              <input (keypress)="keyPress($event)" formControlName="AccountPhone"
                                [placeholder]="'Quiz_Input_Phone' | translate" kendoTextBox type="text" maxlength="10" minlength="10"
                                required />
                              <div class="quiz-error" *ngIf="!validForm && !userForm.get('AccountPhone').valid">
                                {{ "Contact_Error_Phone" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                        <!-- <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "DataQuestion_Address" | translate}} <span class="required"> *</span></div>
                            <div class="quiz-input-item">
                              <input formControlName="AccountAddress" [placeholder]="'Contact_Address' | translate" kendoTextBox
                                required />
                              <div class="quiz-error" *ngIf="!validForm && !userForm.get('AccountAddress').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield> -->
                        
    
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "DataQuestion_Title" | translate}} <span class="required"> *</span></div>
                            <div class="quiz-input-item">
                              <input formControlName="Name" [placeholder]="'Quiz_Input_Title' | translate" kendoTextBox required />
                              <div class="quiz-error" *ngIf="!validForm && !userForm.get('Name').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "Quiz_Content" | translate}}<span class="required"> *</span> </div>
                            <div class="quiz-textarea-item">
                              <textarea formControlName="Description" [placeholder]="'Quiz_Input_Content' | translate" kendoTextArea
                                required></textarea>
                              <div class="quiz-error" *ngIf="
                                  !validForm &&
                                  !!userForm.value.Description &&
                                  !validateContent(userForm.value.Description)
                                ">
                                {{ "Contact_Content_Error" | translate }}
                              </div>
                              <div class="quiz-error" *ngIf="!validForm && !userForm.get('Description').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "Quiz_Image" | translate}} </div>
                            <label class="quiz-textarea-item">
                              <div *ngFor="let item of fileUrls">
                                <img class="image-upload" [src]="getImageFullUrl(item)">   
                              </div>
                                                    
                              <kendo-upload [(ngModel)]="filesUpload" (select)="onSelectFileToUpload($event)"
                                (remove)="onRemoveFileDocToUpload()" [restrictions]="myRestrictions" [multiple]="true"
                                [showFileList]="false" [saveUrl]="fileSaveUrl" (success)="onSuccessFileToUpload($event)"
                                [accept]="['.jpeg','.jpg','.png']"  [ngModelOptions]="{standalone: true}">
                                <kendo-upload-messages [dropFilesHere]="'dropFilesHere' | translate" [select]="'UploadFile' | translate"
                                  [headerStatusUploaded]="'FileUploaded' | translate">
                                </kendo-upload-messages>
                              </kendo-upload>
                              <div class="image-upload add-image">
                                <div class="add-image-text">{{ "Add_Image" | translate }}</div>
                              </div>
                            </label>
                          </div>
                        </kendo-formfield>
                        <kendo-formfield>
                          <div class="form-row">
                            <div class="data-title">{{ "Quiz_Capcha" | translate}} <span class="required"> *</span></div>
                            <div class="quiz-input-item-captcha">
                              <div class="col-md-5 col-5">
                                <input formControlName="Capcha" [placeholder]="'Quiz_Input_Capcha' | translate" kendoTextBox required />
                                <div class="quiz-error" *ngIf="!validForm && !userForm.get('Capcha').valid">
                                  {{ "Contact_Error" | translate }}
                                </div>
                              </div>
                              <div class="col-md-5 col-5 captcha-item" id="captcha-item"></div>
                              <div class="col-md-2 col-2">
                                <div class="reload-captcha-btn" (click)="createCaptcha()">
                                  <img class="reload-icon" src="../../assets/images/reload-captcha-icon.png" alt="">
                                </div>
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                        <div class="form-row">
                          <div class="data-title"></div>
                          <div class="quiz-button-item">
                            <button type="submit" (click)="onSubmit()" class="quiz-submit-button">
                              {{ "Quiz_Send" | translate }}
                            </button>
                            <button type="submit" (click)="onResetInput()" class="quiz-reset-button">
                              {{ "Quiz_Reset_Input" | translate }}
                            </button>
                          </div>
                        </div>
                        <!-- <div class="quiz-button-wrapper mb-4">
                          <button type="submit" (click)="onSubmit()" class="quiz-form-button">
                            {{ "Quiz_Send" | translate }}
                          </button>
                        </div> -->
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12 categories-area">
                <div *ngFor="let item of lvList" class="categories-box">
                  <img class="image-categories" [src]="getImageFullUrl(item.ImageUrl)">  
                  <div class="categories-text">{{ item.Name }}</div>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'View_Quiz' | translate" [selected]="isSearchTab">
          <ng-template kendoTabContent>
            <app-quiz-search></app-quiz-search>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
    
  </div>
  <!-- coding footer -->
  <div class="footer-area" *ngIf="isSearchTab">
    <div *ngFor="let item of pageFooter" class="header-box">
      <app-footer *ngIf="item == '1'"></app-footer>
      <app-footer2 *ngIf="item == '2'"></app-footer2>
    </div>
  </div>
  <!-- coding footer -->

  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
  class="router-outlet-content window-center dialog-info">
  <div class="news-template-12-dialog close-div">
    <div class="title-info-req">
      <strong *ngIf="editFlg == true && dltFlg == false">{{
        "Gallery_Scrollview_Edit" | translate
        }}</strong>
      <strong *ngIf="editFlg == false && dltFlg == false">{{
        "Gallery_Scrollview_Add" | translate
        }}</strong>
      <strong *ngIf="dltFlg == true">{{
        "Gallery_Scrollview_Delete" | translate
      }}</strong>
    </div>
    <div *ngIf="dltFlg == false" class="close-icon-div">
      <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
    </div>
  </div>
  <app-news-template-12-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
    (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
    [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
  </app-news-template-12-dialog>
  <div *ngIf="dltFlg == true">
    <div class="input-label">
      <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
    </div>
    <div>
      <button
        class="btn-delete"
        (click)="onDeleteGallery()"
      >
        <span>{{ "Delete" | translate }}</span>
      </button>
      <button
        class="btn-cancel"
        (click)="onClose($event)"
      >
        <span>{{ "Cancel" | translate }}</span>
      </button>
    </div>
  </div>
</kendo-dialog>
</section>