import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppControls } from 'src/app/services/app.controls';
import { Subscription } from 'rxjs/Rx';
import { AppLanguage } from '../../services/app.language';
import { ActivatedRoute } from '@angular/router';
import { AppConsts } from 'src/app/services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-receive-document',
  templateUrl: './receive-document.component.html',
  // styleUrls: ['./receive-document.component.css']
})
export class ReceiveDocumentComponent implements OnInit, OnDestroy {
  pageName = '';
  private queryParamsSubscription: Subscription;
  constructor(public appControls: AppControls,
    private language: AppLanguage,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    public appConsts: AppConsts,
    public translate: TranslateService,
    private meta: Meta) {
      this.language.default();
    
   }

  ngOnInit() {
    this.getPageName();
    

  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }
  async getPageName() {
    // this.pageName = await this.appControls.getPageNamePortal();
    this.updateMeta()
  
  }
  onTabSelect(e) {
  }

  updateMeta(): void{
    this.title.setTitle(this.pageName);
    var description =   this.pageName;
    var keywords =  this.pageName;
    var url = window.location.origin + "/" +AppConsts.page.receiveDocument;
    var title = this.pageName;
    var ogDescription = this.pageName;
    var image = '';

    this.meta.updateTag({name: 'description', content:description});
    this.meta.updateTag({name: 'keywords', content:keywords});
    this.meta.updateTag({name: 'og:url', content:url});
    this.meta.updateTag({name: 'og:title', content:title});
    this.meta.updateTag({name: 'og:image', content:image});
    this.meta.updateTag({name: 'og:description', content: ogDescription});

  }
}
