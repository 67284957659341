import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';

import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';

import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';

import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';

import { Breakpoints } from '@angular/cdk/layout';
import { any } from 'underscore';

@Component({
  selector: 'app-register-document',
  templateUrl: './register-document.component.html',
  // styleUrls: ['./register-document.component.css']
})
export class RegisterDocumentComponent implements OnInit, OnDestroy {
  customCss = 0;
  dataErr: any;
  @Input("infoOpened") infoOpened: boolean;
  @Input("FlgAdd") FlgAdd: boolean;
  @Output() showSuccess = new EventEmitter<any>();
  dataCvPro5s: any;
  dataGridItem: any;
  gridViewCvPro5s: any;
  loading = false;
  flgSave = false;
  flgApprove = false;
  flgDeny = false;
  dataCvPro5RegisterFiles: Array<{
    ID: any, UploadUrl: String, CVPro5ID: Number,
    RegisterDocumentID: any, DelFlg: Boolean, CreateAt: Date, UpdateAt: Date, FlgAdd: Boolean
  }> = [];
  // FlgAdd= new Array<Boolean>();

  tempdatadataCvPro5RegisterFile: any;
  registerState = 0;

  @Input("InputDataItem") InputDataItem: any;

  control: any;
  user: any;

  public sort: SortDescriptor[] = [
    {
      field: 'ID',
      dir: 'asc'
    }
  ];

  zeroCharPhone = false;

  fileSaveUrl: any;
  filesUpload: Array<FileInfo>;
  filesUploadName = "";
  allowInsertFile = true;
  prefixFee = 0;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';

  currentIndex: number;

  gender: Array<{ Name: string, ID: number }> = [];
  genderFilter: Array<{ Name: string, ID: number }> = [];
  nationality: Array<{ Name: string, ID: number }> = [];
  nationalityFilter: Array<{ Name: string, ID: number }> = [];
  CvPro5Groups: Array<{ Name: string, ID: number }> = [];
  CvPro5GroupsFilter: Array<{ Name: string, ID: number }> = [];

  codeToCheck = "";
  isSuccess = false;

  loadingScan = false;


  public ngaytest: Date = new Date();
  controlDefault = true;
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
   // this.language.default();
  this.language.default();
  }

  ngOnInit() {
    this.setDefault();
    // this.getControl();
    this.initDisplay();
    

  }
  ngOnDestroy(): void {
  }
  checkErr() {
    if (this.customCss == 1) {
      return "x-dialog-dialog-edit-err-one";
    } else if (this.customCss == 2) {
      return "x-dialog-dialog-edit-err-two";
    } else if (this.customCss == 3) {
      return "x-dialog-dialog-edit-err-three";
    } else if (this.customCss == 4) {
      return "x-dialog-dialog-edit-err-four";
    } else {
      return "x-dialog-dialog-edit-group"
    }
  }

  // onCloseInfo() {
  //   this.editOpened = false;
  //   this.setDefault();
  // }
  // async getControl() {
  //   this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.pro5RegisterDocument);
  //   this.controlDefault = false;
  // }

  // createDataRequest(data: any): any {

  //   const temp = data ? data : this.dataGridItem;
  //   return {

  //     ID: this.guid.empty,
  //     CvPro5GroupID: temp.CvPro5GroupID,
  //     FullName: temp.FullName,
  //      DateOfBirth: temp.DateOfBirth,
  //     PlaceOfBirth :temp.PlaceOfBirth,
  //     Gender: temp.Gender,
  //    IDCardNumber :temp.IDCardNumber,
  //     NationalID :temp.NationalID,
  //     Job: temp.Job,
  //     Adress:temp.Adress,
  //     Email : temp.Email,
  //     Phone:temp.Phone,
  //     OrderIdx: temp.OrderIdx,
  //     ReturnDate: temp.ReturnDate,
  //    Note:temp.Note,
  //     // LockFlg:null,
  //    DelFlg: false,
  //     // ApprovedAt = m.CV_PRO5_REGISTER_DOCUMENT.ApprovedAt,
  //     // ApprovedBy = m.CV_PRO5_REGISTER_DOCUMENT.ApprovedBy,
  //     // ApprovedFlg = m.CV_PRO5_REGISTER_DOCUMENT.ApprovedFlg,
  //     CvPro5GroupStepIDs: temp.CvPro5GroupStepIDs,
  //     Fee: temp.Fee,
  //   };
  // }
  setDefault() {
    this.dataCvPro5RegisterFiles = [];
    this.dataGridItem = {
      IsAdd: true,
      ID: this.guid.empty,
      CvPro5GroupID: null,
      FullName: '',
      DateOfBirth: null,
      PlaceOfBirth: '',
      Gender: null,
      IDCardNumber: '',
      NationalID: '',
      Job: '',
      Adress: '',
      Email: '',
      Phone: '',
      OrderIdx: null,
      ReturnDate: null,
      Note: '',
      LockFlg: null,
      DelFlg: false,
      // ApprovedAt = m.CV_PRO5_REGISTER_DOCUMENT.ApprovedAt,
      // ApprovedBy = m.CV_PRO5_REGISTER_DOCUMENT.ApprovedBy,
      // ApprovedFlg = m.CV_PRO5_REGISTER_DOCUMENT.ApprovedFlg,
      CvPro5GroupStepIDs: '',
      Fee: null,
      CvPro5ListID: '',
      langID: this.language.get()
      // CreateAt = m.CV_PRO5_REGISTER_DOCUMENT.CreateAt,
      // UpdateAt = m.CV_PRO5_REGISTER_DOCUMENT.UpdateAt,
      /*         ReturnDate = m.CV_PRO5_REGISTER_DOCUMENT.ReturnDate.Date*/
      /*  Status = m.CV_PRO5_REGISTER_DOCUMENT.ReturnDate.date < (DateTime.Now).Date*/

      //  GroupStepListID: null
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;

    // this.dataGridSelection = [];
    // this.disabled = false;
    // this.enabledID = true;

    this.filesUpload = [];
    this.filesUploadName = "";
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?personalID=guest&typeData=files`;
  }
  async initDisplay() {
    this.dataGridItem.CvPro5GroupID = this.InputDataItem.ID;
    this.dataGridItem.CvPro5ListID = this.InputDataItem.CvPro5ListID;
    this.prefixFee = this.InputDataItem.Fee
    this.dataGridItem.Fee = 0;
    this.dataGridItem.Name = this.InputDataItem.Name;
    var dataRequest = {
      langID: this.language.get()
    }
    const resultGender = await this.appService.doGET('api/RegisterDocument/GetEGender', null);
    if (resultGender && resultGender.Status === 1) {
      this.gender = resultGender.Data;
      this.genderFilter = this.gender.slice();
    }

    const resultNationality = await this.appService.doGET('api/RegisterDocument/Nationalities', dataRequest);
    if (resultNationality && resultNationality.Status === 1) {
      this.nationality = resultNationality.Data;
      this.nationalityFilter = this.nationality.slice();
    }

    const resultCvPro5Groups = await this.appService.doGET('api/RegisterDocument/CvPro5Groups', dataRequest);
    if (resultCvPro5Groups && resultCvPro5Groups.Status === 1) {
      this.CvPro5Groups = resultCvPro5Groups.Data;
      this.CvPro5GroupsFilter = this.CvPro5Groups.slice();
    }
    this.getDataItemByID(this.dataGridItem.CvPro5ListID);
  }
  async getDataItemByID(id: any) {

    const dataRequest = {
      CvPro5ListID: id,
      langID: this.language.get()

      //  langID: this.selectedLangID
    };
      const resultCvPro5s = await this.appService.doGET('api/RegisterDocument/getCvPro5s', dataRequest);
      if (resultCvPro5s && resultCvPro5s.Status === 1 && resultCvPro5s.Data != null) {
        this.dataCvPro5s = resultCvPro5s.Data;
        this.loadDataCvPro5s();
      }

    
  }
  genderHandleFilter(e) {
    this.genderFilter = this.gender.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  }
  nationalityHandleFilter(e) {
    this.nationalityFilter = this.nationality.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  }
  CvPro5GroupsHandleFilter(e: any) {

  }
  async CvPro5GroupsSelectionChange(e: any) {
    this.dataGridItem.CvPro5ListID = e.CvPro5ListID;
    const dataRequest = {
      CvPro5ListID: this.dataGridItem.CvPro5ListID,

      //  langID: this.selectedLangID
    };
    const resultCvPro5s = await this.appService.doGET('api/RegisterDocument/getCvPro5s', dataRequest);
    if (resultCvPro5s && resultCvPro5s.Status === 1 && resultCvPro5s.Data != null) {
      this.dataCvPro5s = resultCvPro5s.Data;
    }
    this.loading = true;
    this.loadDataCvPro5s();
    this.loading = false;

  }

  loadDataCvPro5s(): void {
    this.gridViewCvPro5s = {
      data: orderBy(this.dataCvPro5s, this.sort),
      total: this.dataCvPro5s.length
    };

  }


  //Upload Icon
  async onSelectFileToUpload(e: SelectEvent) {

    if (!e.files || e.files.length <= 0) {
      return;
    }
    const extension = e.files[0].extension.toLowerCase();
    this.allowInsertFile = true;
    try {
      const fileData = e.files[0]; // await this.file.readFile(e.files[0].rawFile);
      const maxMB = 30;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(
          this.translate.instant('Error_Size_Cannot_Be_Exceeded') + ` ${maxMB} MB`,
          false
        );
        return;
      }
    } catch {
      this.appSwal.showError(e);
    }
    // tslint:disable-next-line: max-line-length
    if (
      !extension ||
      (extension.toLowerCase() !== ".jpeg" &&
        extension.toLowerCase() !== ".jpg" &&
        extension.toLowerCase() !== ".png" &&
        extension.toLowerCase() !== ".pdf")
    ) {
      this.allowInsertFile = false;
      this.appSwal.showWarning(
        this.translate.instant('Error_Image_File_Extension'),
        false
      );
      return false;
    }
  }

  getUrlDownload(item) {
    let url = this.appService.apiRoot.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }

  async onSuccessFileToUpload(e: any) {


    if (!this.allowInsertFile) {
      return;
    }
    try {
      
      let i = 0;
      for (i = 0; i < this.dataCvPro5RegisterFiles.length; i++) {
        if (this.dataCvPro5s[this.currentIndex].ID == this.dataCvPro5RegisterFiles[i].CVPro5ID) {
          if (this.dataCvPro5RegisterFiles[i].UploadUrl == undefined) {
            this.dataCvPro5RegisterFiles[i].UploadUrl = '';
          }
          this.dataCvPro5RegisterFiles[i].UploadUrl = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
          this.dataCvPro5RegisterFiles[i].UpdateAt = new Date();
          break;
        }

      }

      if (i == this.dataCvPro5RegisterFiles.length) {
        this.dataCvPro5RegisterFiles.push({
          ID: this.guid.empty,
          UploadUrl: '' +
            `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`,
          CVPro5ID: this.dataCvPro5s[this.currentIndex].ID,
          RegisterDocumentID: this.dataGridItem.ID,
          DelFlg: false,
          CreateAt: new Date(),
          UpdateAt: null,
          FlgAdd: true
        },
        )
        //  this.FlgAdd[i] = true;

      }
      this.dataCvPro5s[this.currentIndex].Status = 1;
      this.dataCvPro5s[this.currentIndex].FileName = this.getFileName(`${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`);
      this.dataCvPro5s[this.currentIndex].UploadUrl = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.loading = true;
      this.loading = false;

      this.registerState = 0;
      

    } catch {
      this.appSwal.showError(e);
    }
  }

  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  onRemoveFileDocToUpload() {
    this.dataCvPro5RegisterFiles[this.currentIndex].UploadUrl = '';
    this.filesUploadName = "";
    this.filesUpload = [];
  }

  getRowIndex(rowIndex) {
    this.currentIndex = rowIndex;
  }

  onSaveGrid(state) {
      this.addGrid(state);
    
  }

  onDeleteGrid() {

  }
  async addGrid(state) {
    if(state == 0) {
      this.loadingScan = true;
    if ((!this.dataCvPro5RegisterFiles || this.dataCvPro5RegisterFiles.length < this.dataCvPro5s.length) && this.dataCvPro5s.length != 0) {
      this.loadingScan = false;
      this.appSwal.showWarning(this.translate.instant('Missing_Attachments'), false);
      return
    }
    var ID = this.guid.empty;
    for (var i = 0; i < this.dataCvPro5RegisterFiles.length; i++) {
      this.dataCvPro5RegisterFiles[i].RegisterDocumentID = ID;
    }
    this.dataGridItem.ID = ID;
    var dataRequest = {
      Document: Object.assign({}, this.dataGridItem),
      ListFiles: this.dataCvPro5RegisterFiles,
      OTP: ''
    }
    
    if (!dataRequest) {
      this.appSwal.showWarning(this.translate.instant('No_Data'), false);
      this.loadingScan = false;
      return
    };
    this.checkPhone(dataRequest.Document.Phone);
    if(dataRequest.Document.Phone) {
      dataRequest.Document.Phone = parseInt(dataRequest.Document.Phone.replace(/\s/g,''), 10).toString()
    }
    dataRequest.Document.ReturnDate = new Date();
    dataRequest.Document.ReturnDate.setDate(dataRequest.Document.ReturnDate.getDate() + 1);
    
    const result = await this.appService.doPOST('api/RegisterDocument/CheckStateOne', dataRequest);

    if (result && result.Status === 1) {
      this.registerState = 1;
      this.dataGridItem.Fee = result.Data.PayAmount;
      this.dataGridItem.ReturnDate = new Date(result.Data.ExpectedDate);
      this.parsePhone();
      var errTemp = Object.assign({}, this.dataGridItem);
      errTemp.Type = null;
      this.dataErr = [errTemp];
      this.loadingScan = false;
    } else {
      if (!result.Msg) {
        this.loadingScan = false;
        this.dataErr = result.Data;
      } else {
        this.loadingScan = false;
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.loadingScan = false;
    } else if(state == 1) {
      if (!this.dataCvPro5RegisterFiles || this.dataCvPro5RegisterFiles.length == 0 || this.dataCvPro5RegisterFiles.length < this.dataCvPro5s.length) {
        this.loadingScan = false;
        this.appSwal.showWarning(this.translate.instant('Missing_Attachments'), false);
        return
      }
      var ID = this.guid.empty;
      for (var i = 0; i < this.dataCvPro5RegisterFiles.length; i++) {
        this.dataCvPro5RegisterFiles[i].RegisterDocumentID = ID;
      }
      this.dataGridItem.ID = ID;
      const dataRequest = {
        Document: Object.assign({}, this.dataGridItem),
        ListFiles: this.dataCvPro5RegisterFiles,
        OTP: ''
      }
      
      if (!dataRequest) {
        this.loadingScan = false;
        this.appSwal.showWarning(this.translate.instant('No_Data'), false);
        return
      };
      this.checkPhone(dataRequest.Document.Phone);
      if(dataRequest.Document.Phone) {
        dataRequest.Document.Phone = parseInt(dataRequest.Document.Phone.replace(/\s/g,''), 10).toString()
      }
      const result = await this.appService.doPOST('api/RegisterDocument/CheckStateTwo', dataRequest);
  
      if (result && result.Status === 1) {
        this.registerState = 2;
        this.codeToCheck = result.Data;
        var errTemp = Object.assign({}, this.dataGridItem);
        errTemp.Type = null;
        this.dataErr = [errTemp];
        this.parsePhone();
      } else {
        if (!result.Msg) {
  
          this.dataErr = result.Data;
        } else {
          this.appSwal.showWarning(result.Msg, false);
        }
      }
    }else if(state == 2) {
      if (this.dataGridItem.OTP == '' || this.dataGridItem.OTP != this.codeToCheck) {
        this.loadingScan = false;
        this.appSwal.showWarning(this.translate.instant('Error_OTP'), false);
        return
      }
      if (!this.dataCvPro5RegisterFiles || this.dataCvPro5RegisterFiles.length == 0 || this.dataCvPro5RegisterFiles.length < this.dataCvPro5s.length) {
        this.loadingScan = false;
        this.appSwal.showWarning(this.translate.instant('Missing_Attachments'), false);
        return
      }
      var ID = this.guid.empty;
      for (var i = 0; i < this.dataCvPro5RegisterFiles.length; i++) {
        this.dataCvPro5RegisterFiles[i].RegisterDocumentID = ID;
      }
      this.dataGridItem.ID = ID;
      const dataRequest = {
        Document: Object.assign({}, this.dataGridItem),
        ListFiles: this.dataCvPro5RegisterFiles,
        OTP: this.dataGridItem.OTP
      }
      
      if (!dataRequest) {
        this.loadingScan = false;
        this.appSwal.showWarning(this.translate.instant('No_Data'), false);
        return
      };
      if(dataRequest.Document.Phone) {
        dataRequest.Document.Phone = parseInt(dataRequest.Document.Phone.replace(/\s/g,''), 10).toString()
      }
      const result = await this.appService.doPOST('api/RegisterDocument/Register', dataRequest);
  
      if (result && result.Status === 1) {
        this.registerState = 1;
        // this.dataGridItem = {};
        var errTemp = Object.assign({}, this.dataGridItem);
        errTemp.Type = null;
        this.dataErr = [errTemp];
        this.parsePhone();
        this.onShowSuccess();
      } else {
        if (!result.Msg) {
  
          this.dataErr = result.Data;
        } else {
          this.appSwal.showWarning(result.Msg, false);
        }
      }
    }
  }

  onShowSuccess() {
    this.showSuccess.emit(true)
  }

  onCloseSuccess() {
    this.isSuccess = false;
  }

  onAddNewGrid() {
    this.setDefault();

    this.loading = true;
    this.gridViewCvPro5s = [];
    this.loading = false;

    this.FlgAdd = true;
  }

  

  // createDataRequest(data: any): any {

  //   const temp = data ? data : this.dataGridItem;
  //   return {

  //     ID: temp.ID,
  //     Name: temp.Name,
  //     Description: temp.Description,
  //     OrderIdx: temp.OrderIdx,

  //    CvUnprocessedDocumentID: -1,
  //   };
  // }

  onCloseInfo() {
    this.infoOpened = false;


  }
  onReload() {
    if (!this.FlgAdd) {
      this.getDataItemByID(this.dataGridItem.CvPro5ListID);
    }
    this.isSuccess = false;
  }

  onAgree() {
    this.flgApprove = true;
  }
  onDeny() {
    this.flgDeny = true;
  }
  
  onCloseApprove() {
    this.flgApprove = false;
    this.flgDeny = false;
  }

  checkPhone(phone) {
    if(phone.substring(0,1) == '0') {
      this.zeroCharPhone = true
    } else {
      this.zeroCharPhone = false;
    }
  }

  parsePhone(){
    if(this.zeroCharPhone == true) {
      this.dataGridItem.Phone = this.dataGridItem.Phone? "0"+ parseInt( this.dataGridItem.Phone  ,10).toString() : "" ;
    }
  }


  onContinueGrid() {
    this.flgSave = true;
  }









}


