<div class="news-template-36-dialog row">
  <div class="col-md-12 col-12">
    <div class="row">
      <div class="col-md-12 col-12">
        <div [ngClass]="'control'">
          <label>
            <span
              [textContent]="'Gallery_Dialog_Top' | translate"
              class="input-label"
            ></span>
            <span class="required">(*)</span>
          </label>
          <kendo-numerictextbox
            [(ngModel)]="dataGalleryItemtemp.Info.TopNumber"
            [min]="1"
            [max]="1000"
            [format]="'n0'"
            [autoCorrect]="true"
            [ngClass]="dataErr[0]['Info.TopNumber'] ? 'err' : ''"
            class="input-box"
          >
          </kendo-numerictextbox>
          <span class="err-text" *ngIf="dataErr[0].Info.TopNumber == undefined"
            >Không được bỏ trống trường này</span
          >
          <span class="err-text" *ngIf="dataErr[0].Info.TopNumber <= 0"
            >Giá trị 1 - 1000</span
          >
          <!-- <span class="err-text" *ngIf="dataErr[0]['Info.TopNumber']">{{
            dataErr[0]["Info.TopNumber"]
          }}</span> -->
        </div>
      </div>

      <div class="col-md-12 col-12">
        <div class="btn-dialog-wrapper">
          <button
            *ngIf="controlDefault || control.Save"
            class="btn-dialog btn-save"
            (click)="onSaveGallery()"
          >
            <span>{{ "Save" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
