import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import { EventEmitterService } from '../event-emitter.service';

@Component({
  selector: 'app-layout80',
  templateUrl: './layout80.component.html',
  styleUrls: ['./layout80.component.scss'],
})
export class Layout80Component implements OnInit {
  @Input() templates: any;
  @Input() group: any;
  // @Input() ID: any;
  // @Input() infoFromMain: any;
  @Input() categoriesList: any;
  // @Input() templateID: any;
  // @Input() width: any;
  @Input() idx: any;
  // @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  // @Input() isAdminFlg: boolean = true;
  constructor(
    private eventEmitterService: EventEmitterService,
  ) {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      //localStorage.setItem('pageBody', JSON.stringify(this.templates));
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  dropItem(event: CdkDragDrop<string[]>)
  {
    this.eventEmitterService.onDropItem(event);
  }

  getConnectedList()
  {
    this.eventEmitterService.onGetConnectedList();
  }

  onReload(item) {}
}
