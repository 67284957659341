import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConsts } from 'src/app/services/app.consts';
import { Notification } from 'src/app/services/app.notification';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  // styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  public resetFailed = {
    isHide: true,
    message: null,
  };
  invalidField = {
    Password: false,
    CfPassword: false,
    SecureCode: false,
  };
  resetPasswordInfo = {
    Password: '',
    CfPassword: '',
    SecureCode: '',
  };
  public type = {
    inputPassword: 'password',
    inputCfPassword: 'password',
  };
  loading = false;

  public focus: number;
  constructor(
    private translate: TranslateService,
    private appService: AppService,
    private router: Router,
    private notification: Notification
  ) {}

  ngOnInit(): void {}
  checkValidate(): boolean {
    let isValid = true;
    if (
      !this.resetPasswordInfo.Password ||
      this.resetPasswordInfo.Password == ''
    ) {
      this.invalidField.Password = true;
      isValid = false;
    }
    if (
      !this.resetPasswordInfo.CfPassword ||
      this.resetPasswordInfo.CfPassword == ''
    ) {
      this.invalidField.CfPassword = true;
      isValid = false;
    }
    if (
      !this.resetPasswordInfo.SecureCode ||
      this.resetPasswordInfo.SecureCode == ''
    ) {
      this.invalidField.SecureCode = true;
      isValid = false;
    }
    return isValid;
  }

  async onSubmit() {
    const isValid = this.checkValidate();
    if (!isValid || this.loading) {
      return;
    } else {
      var dataRequest = {
        AccountID: localStorage.getItem("AccountID"),
        Password: this.resetPasswordInfo.Password,
        PasswordRepeat: this.resetPasswordInfo.CfPassword,
        SecureCode: this.resetPasswordInfo.SecureCode,
      };
      this.loading = true;
      const result = await this.appService.doPOST(
        'api/User/ForgotPassword',
        dataRequest
      );
      this.loading = false;
      if (result.Status === 1) {
        localStorage.removeItem("AccountID")
        this.router.navigate([
          AppConsts.page.login,
        ]);
        this.notification.showSuccess(
          'Thay đổi mật khẩu thành công.'
        );
      }
      else if (result.Status === 0) {
        this.resetFailed = {
          isHide: false,
          message: Object.values(result.Data[0])[0],
        };
        setTimeout(() => {
          this.resetFailed.isHide = true;
        }, 2000);
      }
       else {
        this.resetFailed = {
          isHide: false,
          message: result.Msg,
        };
        setTimeout(() => {
          this.resetFailed.isHide = true;
        }, 2000);
      }
    }
  }
  onShowPass(input) {
    if (input === 0) {
      if (this.type.inputPassword == 'password') {
        this.type.inputPassword = 'text';
      } else {
        this.type.inputPassword = 'password';
      }
    } else {
      if (this.type.inputCfPassword == 'password') {
        this.type.inputCfPassword = 'text';
      } else {
        this.type.inputCfPassword = 'password';
      }
    }
  }

  onKeyPress(e: any) {
    if (e.target.id === 'Password') {
      this.invalidField.Password = false;
    } else if (e.target.id === 'CfPassword') {
      this.invalidField.CfPassword = false;
    } else if (e.target.id === 'SecureCode') {
      this.invalidField.SecureCode = false;
    }
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  onFocus(type) {
    this.focus = type;
  }
}
