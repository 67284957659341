<section class="router-outlet-content data-menu">
  <div class="row admin">
    <!-- <div class="col-md-12 col-12">
         <div class="page-name">{{pageName}}</div>
         <hr>
      </div> -->
    <div class="col-md-12 col-12 filter">
      <div [ngClass]="'control control-search'">
        <!-- <label>
               <span [textContent]="'' | translate"></span>
            </label> -->
        <!-- <button kendoButton (click)="onSearch()" [primary]="true" [look]="'outline'"
               [ngClass]="'btn-search'">

            </button> -->
        <img class="img3" src="../../../assets/images/Bo_loc.png" />
        <input kendoTextBox [placeholder]="'Filter_By' | translate" [(ngModel)]="searchOption.SearchText"
          (keypress)="onSearchKeyPress($event)" (change)="onSearchTextChange($event)" />
        <button kendoButton (click)="onRemoveSearchText()" [primary]="true" [look]="'outline'" class="img4 btn-remove"
          [icon]="'close-outline'" *ngIf="searchOption.SearchText !== ''"></button>
      </div>
    </div>
  </div>
  <div class="row" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="panelbar-wrapper mb-data-menu">
      <div class="txt-break item-menu" [ngClass]="dataItem.MenuFlg ? 'active' : 'passive'" *ngFor="let dataItem of dataGrids" cdkDrag>
        <div class="row item-menu-drag">
          <div class="col-md-10 col-10">
            {{ "" | translate }} <span class="value">{{ dataItem.Name }}</span>
          </div>
          <div class="col-md-1 col-12">

            <img *ngIf="dataItem.MenuFlg" src="../../../assets/images/eye_sees.png"
              (click)="onClickMenuFlg(dataItem)" />
            <img *ngIf="!dataItem.MenuFlg" src="../../../assets/images/eye_does_not_see.png"
              (click)="onClickMenuFlg(dataItem)" />
          </div>
          <div class="col-md-1 col-2 mb-drbtn-func">
            <div class="btn-group g-btn g-btn-approve full-height mr-2" role="group">
              <kendo-dropdownbutton [popupSettings]="{
                  popupClass: customPopupClass,
                  animate: true,
                  appendTo: 'component'
                }" class="btn-custom" [data]="btnFunctionData" (itemClick)="onChangeFunction($event, dataItem)"
                (click)="onFunctionIconClick(dataItem)">
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </kendo-dropdownbutton>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12" [style.height.px]="102"></div>
      <div class="col-md-12 col-12">
        <div class="btn-addNew custom-btn-addNew">
          <img class="img5" (click)="onAddNewGrid()" src="../../../assets/images/AddNew1.png" />
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="infoOpened" class="data-menu x-dialog window-center data-menu-info dialog-chat"
    [draggable]="false" [ngClass]="checkErr()">
    <div class="employe-detail">
      <div class="x-close-div">
        <div class="title-info-req">
          <strong>{{ pageName }}</strong>
        </div>
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseInfo()"></button>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="pop-up-title">
                <label>
                  <span [textContent]="'Create_New_Page' | translate"></span>
                </label>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label>
                  <span [textContent]="'DATA_MENU_Name' | translate" class="input-label"></span>
                  <!-- <span class="required">(*)</span> -->
                </label>
                <input [ngClass]="dataErr[0]['Name'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Name"
                  (change)="menuNameChange(dataGridItem.Name)" class="input-box"/>
                <span class="err-text" *ngIf="dataErr[0]['Name']">{{
                  dataErr[0]["Name"]
                  }}</span>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label>
                  <span [textContent]="'DATA_MENU_Url_Path' | translate" class="input-label"></span>
                  <!-- <span class="required">(*)</span> -->
                </label>
                <input kendoTextBox [ngClass]="dataErr[0]['UrlPath'] ? 'err' : ''" [(ngModel)]="dataGridItem.UrlPath"
                  [focus]="dataMenuFocus.UrlPath" class="input-box"/>
              </div>
              <span class="err-text" *ngIf="dataErr[0]['UrlPath']">{{
                dataErr[0]["UrlPath"]
                }}</span>
            </div>
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label>
                  <span [textContent]="'DATA_MENU_Parent' | translate" class="input-label"></span>
                </label>
                <kendo-dropdownlist [data]="dataItemsFilter" [textField]="'Name'" [valueField]="'ID'"
                  [(ngModel)]="dataGridItem.ParentID" [valuePrimitive]="true" [filterable]="true"
                  (filterChange)="dataItemsHandleFilter($event)" class="input-box">
                  <ng-template kendoDropDownListNoDataTemplate>{{
                    "NoRecords" | translate
                    }}</ng-template>
                </kendo-dropdownlist>
              </div>
            </div>
            <!-- <div class="col-md-12 col-12">
                    <div [ngClass]="'control'">
                       <label>
                          <span [textContent]="'DATA_MENU_PageID' | translate"></span>
                       </label>
                       <kendo-combobox [data]="parentPagesFilter" [textField]="'Name'" [valueField]="'ID'"
                          [filterable]="true" (filterChange)="parentPagesHandleFilter($event)"
                          [(ngModel)]="dataGridItem.PageID" [valuePrimitive]="true"
                          [disabled]="selectedLangID != appConsts.defaultLangID">
                       </kendo-combobox>
                    </div>
                 </div> -->
            <!-- <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label>
                  <span [textContent]="'_User_Role' | translate"></span>
                </label>
                <kendo-multiselect [data]="rolesFilter" [textField]="'Name'" [valueField]="'ID'"
                  [(ngModel)]="dataGridItem.Roles" [valuePrimitive]="true" [filterable]="true"
                  (filterChange)="rolesHandleFilter($event)">
                </kendo-multiselect>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div [ngClass]="'control'">
                <label [textContent]="'DATA_MENU_OrderIdx' | translate"></label>
                <kendo-numerictextbox [(ngModel)]="dataGridItem.OrderIdx" [min]="0" [max]="1000" [format]="'n0'"
                  [autoCorrect]="true" >
                </kendo-numerictextbox>
              </div>
            </div> -->
            <div class="col-md-9 col-12"></div>
            <div class="col-md-12 col-12" *ngIf="
                'portal/post-list;chucnangnhiemvu'.indexOf(dataGridItem.PageID) >=
                0
              ">
              <div [ngClass]="'control'">
                <label [textContent]="'DATA_MENU_OrderIdx_Post' | translate"></label>
                <kendo-numerictextbox [(ngModel)]="dataGridItem.OrderIdxInPost" [min]="0" [max]="1000" [format]="'n0'"
                  [autoCorrect]="true" [disabled]="selectedLangID != appConsts.defaultLangID">
                </kendo-numerictextbox>
              </div>
            </div>

            <div class="col-md-6 col-12" *ngIf="
                dataGridItem.HomeDisplayType == 2 ||
                dataGridItem.HomeDisplayType == 3 ||
                dataGridItem.HomeDisplayType == 4
              ">
              <div [ngClass]="'control'">
                <label [textContent]="'WidthBanner' | translate"></label>
                <kendo-numerictextbox [(ngModel)]="dataGridItem.WidthBanner" [min]="0" [format]="'n'"
                  [disabled]="selectedLangID != appConsts.defaultLangID">
                </kendo-numerictextbox>
              </div>
            </div>
            <div class="col-md-6 col-12" *ngIf="
                dataGridItem.HomeDisplayType == 2 ||
                dataGridItem.HomeDisplayType == 3 ||
                dataGridItem.HomeDisplayType == 4
              ">
              <div [ngClass]="'control'">
                <label [textContent]="'HeightBanner' | translate"></label>
                <kendo-numerictextbox [(ngModel)]="dataGridItem.HeightBanner" [min]="0" [format]="'n'"
                  [disabled]="selectedLangID != appConsts.defaultLangID">
                </kendo-numerictextbox>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button class="btn-dialog btn-save" (click)="onSaveGrid()">
                  {{ "COMMON_SAVE" | translate }}
                </button>
                <button class="btn-dialog btn-delete" (click)="onDeleteGrid(dataGridItem)" *ngIf="
                    (controlDefault || control.Delete) &&
                    selectedLangID != appConsts.defaultLangID
                  ">
                  {{ "COMMON_REMOVE" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
</section>
