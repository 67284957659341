<article class="news-template-5" (window:resize)="onResize($event)">
  <div class="container-fluid" *ngIf="innerWidth>768">
    <div class="row">
      <div class="main-item">
        <div class="row">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12 intro-des">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <img class="image" [src]="this.imageSrc" [alt]="this.title" />
            <div class="intro-text">
              <p class="field-name">{{ this.title }}</p>
              <p class="letter">{{this.content}}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
              <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
          </div> -->
    </div>
  </div>
  <div class="container-fluid" *ngIf="innerWidth<=768">
    <div class="main-item">
      <div class="col-sm-12 col-12 col-md-12 col-xl-12 intro-des-mobile">
        <div *ngIf="isAdminFlg" class="vertical-dot">
          <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
          <div class="menu" *ngIf="isShowMenu">
            <div class="menu-row-current-border" (click)="editComponent()">
              <div class="menu-item-1">{{'Edit' | translate}}</div>
            </div>
            <div class="menu-row-current" (click)="deleteComponent()">
              <div class="menu-item-3">{{'Delete' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="image-box-mobile col-sm-12 col-12 col-md-12 col-xl-12">
          <img class="image-mobile" [src]="this.imageSrc" [alt]="this.title"/>
        </div>
        <div class="intro-text-mobile col-sm-12 col-12 col-md-12 col-xl-12">
          <p class="field-name-mobile">{{ this.title }}</p>
          <p class="letter-mobile">{{this.content}}</p>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-5-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-5-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" [imageSrc]="this.imageSrc" [Title]="this.title"
      [Description]="this.content" (onSave)="this.onSaveData()" (onChangetemp5)="this.changeTemp5($event)">
    </app-news-template-5-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
