<!-- <app-header-template2 [isAdminFlg]="false" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
  (menuSearch)="menuSearch($event)" *ngIf="!exceptionRoutes.includes(link)">
</app-header-template2> -->
<app-router-link [menuTittle]="menuTittle" [menuVl]="menuVl" [itemchild]="itemchild" *ngIf="!exceptionRoutes.includes(link)">
  [ItemTittle]="">
</app-router-link>
<!-- <app-main-page [menuID]="menuID" *ngIf="!isTemplatePage"></app-main-page> -->
<router-outlet (activate)="onActivate($event)" ></router-outlet>
<!-- <app-footer></app-footer> -->
<!-- <app-footer2 [isAdminFlg]="false" *ngIf=" !exceptionRoutes.includes(link)"></app-footer2> -->
