<article [ngClass]="templateType ==1 ? 'news-template-40' : 'news-template-40-without-time'">
    <div class="container-fluid padding">
      <div class="row">
        <div class="main-item">
          <div class="row">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title" *ngIf="templateType == 1">
              <p class="uppercase title-text">{{ postTittle }}</p>
              <div *ngIf="isMore" class="post-more">
                <div class="pointer" (click)="redirectToViewMore()">
                  {{ "View_More" | translate }}
                  <!-- <i class="fa fa-angle-right icon-right"></i> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 web-view">
              <div class="post-list">
                <div
                  [className]="                 
                    ' col-12' +
                    ' pointer top-new-item'
                  "
                >
                <div class="row" *ngIf="pagedTopnews && pagedTopnews.length && pagedTopnews.length > 0">
                  <div class="col-6">
                      <div class="post-container">
                        <figure
                        class="img-figure first-post"
                        [ngClass]="setH()"
                        >
                          <a [href]="getDetailUrl(pagedTopnews[0].UrlPath)" class="post-image post-image-radius">
                            <img
                              *ngIf="pagedTopnews[0].ImageUrl && !pagedTopnews[0].DefaultFlg"
                              class="post-image post-image-radius"
                              [src]="appService.apiRoot + pagedTopnews[0].ImageUrl"
                              alt="post-image"
                              />
      
                            <img
                              *ngIf="pagedTopnews[0].ImageUrl == this.LINK_DEFAULT_IMAGE || pagedTopnews[0].ImageUrl == null || pagedTopnews[0].ImageUrl == undefined"
                              class="post-image-default"
                              src="../../assets/images/default.png"
                              alt="post-image"
                            />
                          </a>
                        </figure>
                        <div class="title-first">
                          <p class="menu-name">{{ pagedTopnews[0].MenuName && pagedTopnews[0].MenuName.length > 0 ? pagedTopnews[0].MenuName : pagedTopnews[0].ParentMenuName }}</p>
                          <a [href]="getDetailUrl(pagedTopnews[0].UrlPath)">
                            <p class="title-name" *ngIf="pagedTopnews[0].Title">
                              {{
                                onDotsString(pagedTopnews[0].Title, 60)
                              }}
                            </p>
                          </a>
                          <p *ngIf="!pagedTopnews[0].Title"></p>
                        </div>
                      </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <div class="" *ngIf="pagedTopnews.length >= 2; else elseBlock2">
                          <div class="post-container">
                            <figure
                            class="img-figure"
                            [ngClass]="setH()"
                            >
                              <a [href]="getDetailUrl(pagedTopnews[1].UrlPath)" class="post-image post-image-radius">
                                <img
                                  *ngIf="pagedTopnews[1].ImageUrl && !pagedTopnews[1].DefaultFlg"
                                  class="post-image post-image-radius"
                                  [src]="appService.apiRoot + pagedTopnews[1].ImageUrl"
                                  alt="post-image"
                                  />
          
                                <img
                                  *ngIf="pagedTopnews[1].ImageUrl == this.LINK_DEFAULT_IMAGE || pagedTopnews[1].ImageUrl == null || pagedTopnews[1].ImageUrl == undefined"
                                  class="post-image-default"
                                  src="../../assets/images/default.png"
                                  alt="post-image"
                                />
                              </a>
                            </figure>
                            <div class="title-second">
                              <p class="menu-name">{{ pagedTopnews[1].MenuName && pagedTopnews[1].MenuName.length > 0 ? pagedTopnews[1].MenuName : pagedTopnews[1].ParentMenuName }}</p>
                              <a [href]="getDetailUrl(pagedTopnews[1].UrlPath)">
                                <p class="title-name" *ngIf="pagedTopnews[1].Title">
                                  {{
                                    onDotsString(pagedTopnews[1].Title, 60)
                                  }}
                                </p>
                              </a>
                              <p *ngIf="!pagedTopnews[1].Title"></p>
                            </div>
                          </div>
                        </div>
                        <ng-template #elseBlock2> </ng-template>
                      </div>
                      <div class="col-6">
                        <div class="" *ngIf="pagedTopnews.length >= 3; else elseBlock3">
                          <div class="post-container">
                            <figure
                            class="img-figure"
                            [ngClass]="setH()"
                            >
                              <a [href]="getDetailUrl(pagedTopnews[2].UrlPath)" class="post-image post-image-radius">
                                <img
                                  *ngIf="pagedTopnews[2].ImageUrl && !pagedTopnews[2].DefaultFlg"
                                  class="post-image post-image-radius"
                                  [src]="appService.apiRoot + pagedTopnews[2].ImageUrl"
                                  alt="post-image"
                                  />
          
                                <img
                                  *ngIf="pagedTopnews[2].ImageUrl == this.LINK_DEFAULT_IMAGE || pagedTopnews[2].ImageUrl == null || pagedTopnews[2].ImageUrl == undefined"
                                  class="post-image-default"
                                  src="../../assets/images/default.png"
                                  alt="post-image"
                                />
                              </a>
                            </figure>
                            <div class="title">
                              <p class="menu-name">{{ pagedTopnews[2].MenuName && pagedTopnews[2].MenuName.length > 0 ? pagedTopnews[2].MenuName : pagedTopnews[2].ParentMenuName }}</p>
                              <a [href]="getDetailUrl(pagedTopnews[2].UrlPath)">
                                <p class="title-name" *ngIf="pagedTopnews[2].Title">
                                  {{
                                    onDotsString(pagedTopnews[2].Title, 30)
                                  }}
                                </p>
                              </a>
                              <p *ngIf="!pagedTopnews[2].Title"></p>
                            </div>
                          </div>
                        </div>
                        <ng-template #elseBlock3> </ng-template>
                      </div>
                      <div class="col-6">
                        <div class="" *ngIf="pagedTopnews.length >= 4; else elseBlock4">
                          <div class="post-container">
                            <figure
                            class="img-figure"
                            [ngClass]="setH()"
                            >
                              <a [href]="getDetailUrl(pagedTopnews[3].UrlPath)" class="post-image post-image-radius">
                                <img
                                  *ngIf="pagedTopnews[0].ImageUrl && !pagedTopnews[3].DefaultFlg"
                                  class="post-image post-image-radius"
                                  [src]="appService.apiRoot + pagedTopnews[3].ImageUrl"
                                  alt="post-image"
                                  />
          
                                <img
                                  *ngIf="pagedTopnews[3].ImageUrl == this.LINK_DEFAULT_IMAGE || pagedTopnews[3].ImageUrl == null || pagedTopnews[3].ImageUrl == undefined"
                                  class="post-image-default"
                                  src="../../assets/images/default.png"
                                  alt="post-image"
                                />
                              </a>
                            </figure>
                            <div class="title">
                              <p class="menu-name">{{ pagedTopnews[3].MenuName && pagedTopnews[3].MenuName.length > 0 ? pagedTopnews[3].MenuName : pagedTopnews[3].ParentMenuName }}</p>
                              <a [href]="getDetailUrl(pagedTopnews[3].UrlPath)">
                                <p class="title-name" *ngIf="pagedTopnews[3].Title">
                                  {{
                                    onDotsString(pagedTopnews[3].Title, 30)
                                  }}
                                </p>
                              </a>
                              <p *ngIf="!pagedTopnews[3].Title"></p>
                            </div>
                          </div>
                        </div>
                        <ng-template #elseBlock4> </ng-template>
                      </div>

                    </div>
                  </div>
                </div>
                </div>
                <kendo-datapager
                  [style.width.%]="100"
                  [pageSize]="pageSize"
                  [skip]="skip"
                  [total]="total"
                  (pageChange)="onPageChange($event)"
                  *ngIf="isViewAllList"
                >
                </kendo-datapager>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 mobile-view">
              <div class="post-list">
                <div
                  [className]="                 
                    ' col-12' +
                    ' pointer top-new-item'
                  "
                >
                <div class="row" *ngFor="let item of pagedTopnews; index as i">
                  <div class="col-12" *ngIf="i < 2">
                      <div class="post-container">
                        <figure
                        class="img-figure first-post"
                        [ngClass]="setH()"
                        >
                          <a [href]="getDetailUrl(item.UrlPath)" class="post-image post-image-radius">
                            <img
                              *ngIf="item.ImageUrl && !item.DefaultFlg"
                              class="post-image post-image-radius"
                              [src]="appService.apiRoot + item.ImageUrl"
                              alt="post-image"
                              />
      
                            <img
                              *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined"
                              class="post-image-default"
                              src="../../assets/images/default.png"
                              alt="post-image"
                            />
                          </a>
                        </figure>
                        <div class="title-first">
                          <p class="menu-name">{{ item.MenuName && item.MenuName.length > 0 ? item.MenuName : item.ParentMenuName }}</p>
                          <a [href]="getDetailUrl(item.UrlPath)">
                            <p class="title-name" *ngIf="item.Title">
                              {{
                                item.Title
                              }}
                            </p>
                          </a>
                          <p *ngIf="!item.Title"></p>
                        </div>
                      </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <kendo-dialog
      *ngIf="manageDialog"
      (mousedown)="$event.stopPropagation()"
      class="router-outlet-content window-center dialog-info dialog-width"
    >
      <div class="news-template-40-dialog close-div">
        <div class="title-info-req">
          <strong *ngIf="editFlg == true && dltFlg == false">{{
            "Gallery_Scrollview_Edit" | translate
          }}</strong>
          <strong *ngIf="editFlg == false && dltFlg == false">{{
            "Gallery_Scrollview_Add" | translate
          }}</strong>
          <strong *ngIf="dltFlg == true">{{
            "Gallery_Scrollview_Delete" | translate
          }}</strong>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img
            class="close-icon"
            src="../../assets/images/close-button.png"
            alt=""
            (click)="onClose($event)"
          />
        </div>
      </div>
      <app-news-template-40-dialog
        *ngIf="editFlg == true"
        [request_AccountID]="request_AccountID"
        [HiddenTab]="HiddenTab"
        (galleryManageOpened)="onClose($event)"
        [galleryData]="galleryData"
        [editFlg]="editFlg"
        [templateID]="templateID"
        [pageSelectedID]="this.pageSelectedID"
        (onSave)="this.onSaveData()"
        (onChangetemp1)="this.changeTemp1($event)"
      >
      </app-news-template-40-dialog>
      <div *ngIf="dltFlg == true">
        <div class="input-label">
          <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
        </div>
        <div>
          <button
            class="btn-delete"
            (click)="onDeleteGallery()"
          >
            <span>{{ "Delete" | translate }}</span>
          </button>
          <button
            class="btn-cancel"
            (click)="onClose($event)"
          >
            <span>{{ "Cancel" | translate }}</span>
          </button>
        </div>
      </div>
    </kendo-dialog>
  </article>
  
