<div class="change-password-container">
  <h4 class="change-password-form-title">
    {{ "Change_Password" | translate }}
  </h4>
  <form class="change-password-form" [formGroup]="changePasswordForm">
    <fieldset>
      <kendo-formfield>
        <div class="input-item">
          <label class="input-item-label">{{
            "Old_Password" | translate
          }}</label>
          <input
            formControlName="oldPassword"
            type="password"
            kendoTextBox
            required
          />
          <div
            class="change-password-error"
            *ngIf="!validForm && !changePasswordForm.get('oldPassword').valid"
          >
            {{ "Contact_Error" | translate }}
          </div>
          <div class="change-password-error" *ngIf="oldPasswordFailed">
            {{ "Old_Password_Failed" | translate }}
          </div>
        </div>
      </kendo-formfield>

      <kendo-formfield>
        <div class="input-item">
          <label class="input-item-label">{{
            "New_Password" | translate
          }}</label>

          <input
            formControlName="newPassword"
            type="password"
            kendoTextBox
            required
          />
          <div
            class="change-password-error"
            *ngIf="!validForm && !changePasswordForm.get('newPassword').valid"
          >
            {{ "Contact_Error" | translate }}
          </div>
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="input-item">
          <label class="input-item-label">
            {{ "Confirm_New_Password" | translate }}</label
          >

          <input
            formControlName="confirmNewPassword"
            type="password"
            kendoTextBox
            required
          />
          <div
            class="change-password-error"
            *ngIf="
              !validForm && !changePasswordForm.get('confirmNewPassword').valid
            "
          >
            {{ "Contact_Error" | translate }}
          </div>
          <div class="change-password-error" *ngIf="newPasswordFailed">
            {{ "New_Password_Failed" | translate }}
          </div>
        </div>
      </kendo-formfield>
      <div class="button-wrapper">
        <button class="form-button quit-button" (click)="onCancel()">
          {{ "Cancel" | translate }}
        </button>
        <button class="form-button" (click)="onSubmit()">
          {{ "COMMON_SAVE" | translate }}
        </button>
      </div>
    </fieldset>
  </form>
</div>
<kendo-dialog *ngIf="successToggle" [minWidth]="250" [width]="543">
  <div class="success-container">
    <img class="success-icon" src="assets/images/successIcon.png" />
    <p class="success-title">
      {{ "Change_Password_Success_Title" | translate }}
    </p>
    <P class="success-msg">{{ "Change_Password_Success_Msg" | translate }} </P>
    <button kendoButton (click)="onSuccessDialog()">
      {{ "Understood" | translate }}
    </button>
  </div>
</kendo-dialog>
