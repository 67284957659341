<section class="post-detail">
  <div *ngIf="loading" class="loading">
    <img src="../../../assets/images/loading.gif" alt="loading">
  </div>
  <div class="row">
    <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
      <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
      <div class="menu" *ngIf="isShowMenu">
        <div class="menu-row-current-border" (click)="editComponent()">
          <div class="menu-item-1">{{'Edit' | translate}}</div>
        </div>
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
    
    <div *ngIf="!isIntroPage" class="col-md-1 col-12"></div>
    <div class="padding-body" [ngClass]="isIntroPage ? 'col-md-12 col-sm-12' : 'col-md-12 col-sm-12'">
      <div *ngIf="Data_Post" class="row">
        <div class="box col-md-12 col-lg-12 col-xs-12 col-sm-12">
          <!-- <div class="box col-md-8 col-lg-8 col-xs-12 col-sm-12"> -->
          <!-- <div class="page-name">{{pageName}}</div> -->
          <!-- <div class="subject">
            </div> -->

          <!-- <nav>
        <a
          class="categories-link"
          *ngFor="let item of Categories; let i = index"
        >
          <span class="categories" [routerLink]="[postList, item.Url]">
            {{ i > 0 ? " | " + item.Name : item.Name }}
          </span>
        </a>
      </nav> -->
          <article>
            <header>
              <div class="sapned">
                <span class="title-post">
                  {{ Data_Post.Title ? Data_Post.Title : "Không tìm thấy bài viết" }}
                </span>
              </div>
            </header>
            <section *ngIf="!isIntroPage" [hidden]="!Data_Post.PostTime" class="created-post">
              <!-- <span class="fa fa-clock-o"></span> -->
              <!-- <time>{{
            Data_Post.CreateAt
              | date
                : (flgVi
                    ? " EEEE, dd/MM/yyyy HH:mm:ss"
                    : " MM-dd-yyyy hh:mm:ss z")
          }}</time> -->

              <time>{{
                Data_Post.PostTime | date: (flgVi ? "dd/MM/yyyy" : " MM-dd-yyyy")
                }}</time>
            </section>

            <!-- <hr [hidden]="!Data_Post.ID" class="line-title" /> -->
            <section class="summary" [innerHTML]="
            (Data_Post.Summary ? Data_Post.Summary : '') | sanitizedHtml
          "></section>
            <!-- <figure class="img-present">
          <img
            *ngIf="!isVideo(Data_Post.ImageUrl)"
            [src]="getUrlDownload(Data_Post.ImageUrl)"
            [alt]="getFileName(Data_Post.ImageUrl)"
          />
          <app-videojs
            *ngIf="isVideo(Data_Post.ImageUrl)"
            [url]="getFullImageUrl(Data_Post.ImageUrl)"
          ></app-videojs>
        </figure> -->
            <section class="description" [innerHTML]="
            (Data_Post.Description ? Data_Post.Description : '') | sanitizedHtml
            "></section>
            <section *ngIf="Data_Post.FileUrls" class="col-md-12 col-12">
              <div class="view-pdf" *ngIf="!Data_Post.Summary && !Data_Post.Description; else fileContainer">
                <ngx-extended-pdf-viewer
                  [src]="getUrlDownload(Data_Post.FileUrls[0])"
                  [useBrowserLocale]="true"
                  [textLayer]="true"
                  [showToolbar]="true"
                  [pageViewMode]="'book'"
                  [zoom]="'page-fit'"
                  height="80vh"
                  ></ngx-extended-pdf-viewer>
              </div>
              <ng-template #fileContainer>
                <div class="file-container">
                  <label>
                    <span class="custom-text" [textContent]="'FileAttachment' | translate"></span>
                  </label>
  
                  <div class="file-div">
                    <a [href]="getUrlDownload(Data_Post.FileUrls[0])" [target]="_blank">
                      <span class="custom-text-file">{{ getFileName(Data_Post.FileUrls[0]) }}</span>
                    </a>
                  </div>
                </div>
              </ng-template>
            </section>
            <!-- <div>
             
                <img data-fancybox="gallery" src="https://lipsum.app/id/60/1600x1200" width="200" height="150" />
              
              <a data-fancybox="gallery" href="https://lipsum.app/id/61/1600x1200">
                <img src="https://lipsum.app/id/61/200x150" width="200" height="150" />
              </a>
              <a data-fancybox="gallery" href="https://lipsum.app/id/62/1600x1200">
                <img src="https://lipsum.app/id/62/200x150" width="200" height="150" />
              </a>
              <a data-fancybox="gallery" href="https://lipsum.app/id/63/1600x1200">
                <img src="https://lipsum.app/id/63/200x150" width="200" height="150" />
              </a>
              <a data-fancybox="gallery" href="https://lipsum.app/id/64/1600x1200">
                <img src="https://lipsum.app/id/64/200x150" width="200" height="150" />
              </a>
            </div> -->



            <!-- <nav class="reference-link-box" *ngIf="Data_Post.Reference">
                  <a class="reference-link" [href]="Data_Post.Reference">{{Data_Post.Reference}}</a>
                </nav> -->
            <!-- <button (click)="onSearch()" type="button" class="btn btn-danger">button</button> -->

            <div class="responsible-container" *ngIf="Data_Post.ResponsibleBy">
              <span class="responsible-title">{{'Responsible_By' | translate}}: </span>
                <span> {{Data_Post.ResponsibleBy}}</span>
            </div>

            <div class="keyword-container" *ngIf="Data_Post.Keywords != null && Data_Post.Keywords.length > 0">
              <span class="keyword-title">Từ khóa:</span>
              <div *ngFor="let item of Data_Post.Keywords">
                <span class="keywords" (click)="redirectToKeyword(item)"> {{item}}</span>
              </div>
            </div>
          </article>
        </div>
        <!-- <div class="col-md-4 col-lg-4 col-12">
      <aside>
        <app-most-viewed-posts
          [UrlPath]="UrlPath"
          type="related_Post"
        ></app-most-viewed-posts>
        <app-most-viewed-posts [UrlPath]="UrlPath"></app-most-viewed-posts>
      </aside>
    </div> -->
      </div>
    </div>
    <div *ngIf="!isIntroPage" class="col-md-1 col-12"></div>
    
  </div>

  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="post-detail-dialog close-div">
      <div class="title-info-req title-center">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-post-detail-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onLoadData($event)" (onChangetempPostDetailDialog)="this.handleChangeTempPostDetail($event)">
    </app-post-detail-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</section>
