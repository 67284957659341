import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { Router } from '@angular/router';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { SelectEvent } from '@progress/kendo-angular-upload';
import { AppSwal } from '../services/app.swal';
import { DomSanitizer } from '@angular/platform-browser';
import { Description, DescriptionStrategy, GalleryService, Image, PreviewConfig } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-news-template-33',
  templateUrl: './news-template-33.component.html',
  // styleUrls: ['./news-template-33.component.scss'],
})
export class NewsTemplate33Component implements OnInit, AfterViewInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  opendDialogImages =  false;
  public pageSize = 12;
  public skip = 0;
  public pagedTopnews = [];
  public total = 0;
  @Input() srcURL: any;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  @Input() width: any;
  galleryData: any;
  @Input() itemTemplate: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() tittleItem = new EventEmitter();
  @Output() urlItem = new EventEmitter();
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate1 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() isPreview: boolean;
  @Input() newsDecorationIndex: any;
  @Input() isMore: boolean = true;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  //default templateType is 1 show all detail, change to 2 to hide time and viewMore
  @Input() templateType = 1;
  isViewAllList = false;
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  mediaCommonTabs: any;
  selectedCommonID: string = '';
  mediaNews: any;
  numberImage: any;
  mediaType: number = 0;
  newArrUrl: any;
  typeFile: number = 1;
  imagesRect: Image[] = [new Image(
    0, 
    {
      img: ""
    }, 
    {
      img: ""
    }
  )];
  previewConfigFiveImages: PreviewConfig = {
    visible: true,
    number: 6
  };
  templateGalleryID : number = 33;
  customFullDescription: Description = {
    // ALWAYS_VISIBLE is mandatory to use customFullDescription
    strategy: DescriptionStrategy.ALWAYS_VISIBLE,
    imageText: '',
    customFullDescription: '',
    beforeTextDescription: '',
    style: {
      textColor: 'rgba(255,255,255)',
      marginTop: '3px',
      marginBottom: '0px',
      marginLeft: '5px',
      marginRight: '5px',
      position: 'absolute',
      bottom: '0px',
      height: 'fit-content',
    }
  };
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private appSwal: AppSwal,
    private sanitizer: DomSanitizer,
    private galleryService: GalleryService
  ) {}

  async ngOnInit(): Promise<void> {
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.topNumber = this.infoFromMain?.TopNumber;
    if (this.topNumber > 12) {
      this.isViewAllList = true;
    }
    this.bootstrapNum = 12 / this.topNumber;
    if (this.topNumber > 3) {
      this.bootstrapNum = 4;
    }
    // this.getPostTittle();
    // this.getTopNews();
    await this.getMediaCommon();
    if (this.mediaCommonTabs != undefined) {
      this.selectedCommonID = this.mediaCommonTabs[0].ID;
    }

    this.galleryData = {
      ID: this.ID,
      OrderIdx: this.OrderIdx,
      infoFromMain: this.infoFromMain,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };
    // if (this.router.url == '/' + AppConsts.page.templatePage) {
    //   document.getElementById('template-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    // else {
    //   document.getElementById('main-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    if (
      ((this.ID == null || this.ID == this.GUID_EMPTY) &&
        this.isAdminFlg == true) ||
      this.isPreview
    ) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
    }
    if (this.infoFromMain.MenuID == '') {
      this.pagedTopnews = this.topNews;
    }
  }
  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }

  private pageData(): void {
    this.pagedTopnews = this.topNews.slice(
      this.skip,
      this.skip + this.pageSize
    );
    // if (this.pagedTopnews.length == 0) {
    //   this.pagedTopnews = [];
    //   for (let index = 0; index < this.topNumber; index++) {
    //     this.pagedTopnews.push({
    //       DefaultFlg: true,
    //       ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
    //       Title: 'Title',
    //       UrlPath: '',
    //       CreateAt: '2021-10-26T08:41:50.453',
    //     });
    //   }
    // }
  }
  async getTopNews() {
    const result = await this.appService.doGET('api/PortalPost/GetByMenuID', {
      langID: this.language.get(),
      menuID: this.infoFromMain.MenuID,
      topNum: this.infoFromMain.TopNumber,
    });
    if (result && result.Status === 1) {
      this.postTittle = result.Data.dataMenu.Name;
      this.viewMore = result.Data.dataMenu.ID;
      this.topNews = result.Data.topNews;
      this.pageData();
      this.total = result.Data.topNews.length;
      // console.log(result.Data);
    }
  }
  getFullUrl(url: string) {
    return this.appService.apiRoot + url;
  }
  setH() {
    if (this.templateType == 2) {
      return 'img-figure-template-type-2';
    }
    var a = document.querySelector('.post-list') as HTMLElement;
    if (a.getBoundingClientRect().width <= 808) {
      return 'img-figure-2';
    }
    return ;
  }
  getFullUrlAPI(url) {
    let jsonObject = JSON.parse(url);
    this.numberImage = jsonObject.length;
    if(jsonObject && jsonObject[0].Url){
      return this.appService.apiRoot + jsonObject[0].Url;
    }
    return this.appService.apiRoot + jsonObject[0];
    
    // let newUrl = url.slice(1, url.length - 1).split(',');

    // this.newArrUrl = [];
    // for (let i = 0; i < newUrl.length; i++) {
    //   let item = newUrl[i].slice(1, newUrl[i].length - 1);

    //   this.newArrUrl.push(item);
    // }
    // this.numberImage = this.newArrUrl.length;
    // return this.appService.apiRoot + this.newArrUrl[0];
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore() {
    // this.router.navigate([AppConsts.page.postList], { queryParams: { url: this.viewMore } });
    this.router.navigate([AppConsts.page.postList + '/' + this.viewMore]);
  }
  onGetTittleItem(e) {
    this.tittleItem.emit(e);
  }

  redirectToItem(url: string, item) {
    this.onGetTittleItem(item);
    if (url != null && url != undefined) {
      // this.router.navigate([AppConsts.page.galleryDialog], { queryParams: {} });

      // this.router.navigate([AppConsts.page.postDetail, url], { state: { postTittle: this.postTittle } });

      this.urlItem.emit(item);
    }
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    console.log('index at template: ', {
      newsDecorationIndex: this.newsDecorationIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      pageBodyIndex: this.pageBodyIndex,
    });
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      index: this.newsDecorationIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      pageBodyIndex: this.pageBodyIndex,
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  changeTemp1(newTemp: any) {
    if (typeof this.infoFromMain === 'string') {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.getTopNews();
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate1.emit(newTemp);
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }
  redirectToDetail(url) {
    this.router.navigate(['/post-detail/' + url]);
  }
  onRightClick(url) {
    let fullURL = this.appService.portalRoot + 'post-detail/' + url;
    window.open(fullURL, '_blank');
    return false;
  }
  getDetailUrl(url) {
    let fullURL = '/post-detail/' + url;
    return fullURL;
  }
  public async onTabSelect(e: SelectEvent): Promise<void> {
    let selectedIndex = JSON.parse(JSON.stringify(e)).index;
    this.selectedCommonID = this.mediaCommonTabs[selectedIndex].ID;
    await this.getMediaSearch(this.selectedCommonID, this.mediaType);
  }
  async getMediaCommon() {
    const result = await this.appService.doGET(
      'api/Media/GetMediaFileCommon',
      {}
    );
    if (result && result.Status === 1) {
      this.mediaCommonTabs = result.Data;
      // console.log(result.Data);
      // this.postTittle = result.Data.dataMenu.Name;
      // this.viewMore = result.Data.dataMenu.ID;
      // this.topNews = result.Data.topNews;
      // this.pageData();
      // this.total = result.Data.topNews.length
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  async getMediaSearch(menuID, type) {
    const dataRequest = {
      searchText: '',
      menuID: menuID ? menuID : -1,
      //video type 0, album, type 1
      type: 1,
    };
    const result = await this.appService.doGET('api/Media/SearchMedia', dataRequest);
    if (result && result.Status === 1) {
      this.mediaNews = result.Data;
      this.numberImage = result.Data;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  getVideo(fileUrls) {
    let videos = JSON.parse(fileUrls);
    let video;
    if (videos) {
      let videoUrl = 'https://www.youtube.com/embed/' + videos[0];
      video = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
      return video;
    }
    return '';
  }
  openDialogImages(item) {
    this.srcURL = JSON.parse(item);
    if(this.srcURL != null){
      this.opendDialogImages = true;

      this.imagesRect= [];
      for(let i = 0; i < this.srcURL.length; i++){
        this.imagesRect.push(
          new Image(
            i, 
            {
              img: this.appService.apiRoot + (this.srcURL[i].Url != undefined ? this.srcURL[i].Url : this.srcURL[i]),
              description: this.srcURL[i].Title
            }, 
            {
              img: this.appService.apiRoot + (this.srcURL[i].Url != undefined ? this.srcURL[i].Url : this.srcURL[i]),
              description: this.srcURL[i].Title
            }
          )
        )
      }
      setTimeout(() => {
        this.openModalViaService(this.templateGalleryID, 0);
      }, 100); 
      document.body.classList.add('dialog-opened');
    }
  }
  openModalViaService(id: number | undefined, index: number) {
    //console.log('opening gallery with index ' + index);
    this.galleryService.openGallery(id, index);
  }
  onCloseDialogImages() {
    this.opendDialogImages = false;
  }
  ngAfterViewInit() {
    this.getMediaCommon().then(async () => {
      await this.getMediaSearch(this.mediaCommonTabs[0].ID, this.mediaType);
    });
  }
}
