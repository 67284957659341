<section class="library-text">
  <div class="row">

    <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
      <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
      <div class="menu" *ngIf="isShowMenu">
        <div class="menu-row-current-border" (click)="editComponent()">
          <div class="menu-item-1">{{'Edit' | translate}}</div>
        </div>
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-1 col-12"></div> -->
    <div class="col-md-12 col-12 router">
      <div class="row">
        <div class="col-md-6 col-12">
          <label class="field-name">
            <span [textContent]="'Keyword' | translate"></span>
          </label>
          <div [ngClass]="'control control-search'">
            <div class="search-place">
              <input [(ngModel)]="searchOption.searchText" (keypress)="onSearchKeyPress($event)"
                [placeholder]="'Library_Text_Search' | translate" />
              <i class="fa fa-times-circle-o" (click)="onRemoveSearchText()" *ngIf="searchOption.searchText !== ''"
                aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-12">
          <label class="field-name">
            <span [textContent]="'Office_PublishUnitName' | translate"></span>
          </label>
          <div [ngClass]="'control control-search'">
            <div class="search-place dropdown">
              <kendo-combobox [data]="unitListFilter" [textField]="'PublishUnitName'" [valueField]="'PublishUnitName'"
                [valuePrimitive]="true" [filterable]="true" [(ngModel)]="searchOption.unitName"
                [placeholder]="'All' | translate" (filterChange)="unitHandleFilter($event)"
                (valueChange)="onValueChange()">
                <ng-template kendoDropDownListNoDataTemplate>{{
                  "NoRecordsAvailable" | translate
                  }}</ng-template>
              </kendo-combobox>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-12">
          <label class="field-name">
            <span [textContent]="'Office_LV' | translate"></span>
          </label>
          <div [ngClass]="'control control-search'">
            <div class="search-place dropdown">
              <kendo-combobox [data]="lvListFilter" [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true"
                [filterable]="true" [(ngModel)]="searchOption.lvID" [placeholder]="'All' | translate"
                (filterChange)="lvHandleFilter($event)" (valueChange)="onValueChange()">
                <ng-template kendoDropDownListNoDataTemplate>{{
                  "NoRecordsAvailable" | translate
                  }}</ng-template>
              </kendo-combobox>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-12">
          <button class="btn-search" (click)="onSearch()">
            <span>{{ "Label_Btn_Search" | translate }}</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="wb-library-text">
          <kendo-grid [data]="dataGridGridDataResult" [selectable]="dataGridSelectableSettings"
            (pageChange)="onGridPageChange($event)" (selectedKeysChange)="ondataItemselectedKeysChange()"
            [pageSize]="dataGridPageSize" [skip]="dataGridSkip" [pageable]="true" [kendoGridSelectBy]="'ID'"
            [selectedKeys]="dataGridSelection" [loading]="loading" [sortable]="dataGridSort"
            [sort]="dataGridSortByField" (sortChange)="ondataItemsortChange($event)" [filterable]="false"
            [groupable]="false" [group]="dataGridState.group" [filter]="dataGridState.filter"
            (dataStateChange)="onGridDataStateChange($event)">


            <kendo-grid-column [width]="20" [field]="'STT'" [title]="'STT' | translate"
              [headerClass]="{ headerCommon: true }">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="index">{{rowIndex + 1}}</div>
              </ng-template>
            </kendo-grid-column>




            <kendo-grid-column [width]="200" [field]="'TextNumber'" [title]="'Office_Title' | translate"
              [headerClass]="{ headerCommon: true }">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-text-name">
                  <label (click)="getFullUrl(dataItem.FileUrls)" class="pointer">
                    {{ dataItem.Name }}
                  </label>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column [width]="100" [field]="'UnitName'" [title]="'Office_PublishUnitName' | translate"
              [headerClass]="{ headerCommon: true }">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-text">
                  <label>
                    {{ dataItem.PublishUnitName }}
                  </label>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column [width]="100" [field]="'LVID'" [title]="'Office_LV' | translate"
              [headerClass]="{ headerCommon: true }">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-text">
                  <label>
                    {{ appUtils.getNameByList(lvListFilter, dataItem.LVID)}}
                  </label>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column [width]="20" [field]="'FileUrls'" [title]="'Download' | translate"
              [headerClass]="{ headerCommon: true }">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="download-box">
                  <img src="../../assets/images/Download.png" class="img" (click)="downloadFile(dataItem.FileUrls, dataItem.Name)" />
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages [noRecords]="'NoRecordsAvailable' | translate" [pagerOf]="'pagerOf' | translate"
              [pagerItems]="'pagerItems' | translate">
            </kendo-grid-messages>
          </kendo-grid>
        </div>
        <div class="mb-library-text">
          <div class="txt-break item-menu" *ngFor="let dataItem of dataGrids">
            <div class="row">
              <div class="col-md-12 col-12">
                <strong> {{ "Office_Title" | translate }}: </strong>

                <span class="value">{{ dataItem.Name }}</span>
              </div>


            </div>
            <div class="col-md-12 col-12">
              <strong> {{ "Office_PublishUnitName" | translate }}: </strong>
              <span class="value">{{ dataItem.PublishUnitName }}</span>
            </div>
            <!-- <div class="col-md-12 col-12">
              <strong> {{ "Office_LV" | translate }}: </strong>
              <span class="value"> {{ appUtils.getNameByList(lvListFilter, dataItem.LVID)}}</span>
            </div> -->
            <div class="col-md-12 col-12">
              <strong> {{ "Download" | translate }}: </strong>
              <span class="value"><img src="../../assets/images/Download.png" class="img" (click)="downloadFile(dataItem.FileUrls, dataItem.Name)" /></span>
            </div>
          </div>
        </div>
      </div>

    </div>

  <!-- <div class="col-md-1 col-12"></div> -->
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width">
    <div class="library-text-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
          }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <!-- <app-news-template-12-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-12-dialog> -->
    <app-library-text-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp1)="this.changeTemp1($event)">
    </app-library-text-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"></span>
      </div>
      <div>
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</section>
