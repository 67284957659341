<article class="news-template-44" (window:resize)="onResize($event)">
    <div class="">
      <div class="row">
        <div class="main-item">
          <div class="row">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">
              <!-- <p class="uppercase title-text">{{ postTittle }}</p> -->
              <div *ngIf="isMore" class="uppercase post-more">
                <div class="pointer">
                  Chuyên gia
                  <!-- <i class="fa fa-angle-right icon-right"></i> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 web-view">
              <kendo-scrollview #scrollview [data]="sliderList" [arrows]="true" 
                [pageable]="true" (itemChanged)="onChange($event)"  
              >
                <ng-template let-item="item-child">
                  <div class="post-list">
                    <div
                      [className]="
                        'col-sm-6' +
                        ' col-12' +
                        ' col-md-2' +
                        ' pointer top-new-item'
                      "
                      *ngFor="let item of currentNews"
                    >
                      <div class="top-part">
                        <figure
                          class="img-figure"
                          [ngClass]="setH()"
                        >
                          <img
                            *ngIf="item.ImageUrl && !item.DefaultFlg"
                            class="post-image"
                            [src]="appService.apiRoot + item.ImageUrl"
                            alt="error-image"
                          />
      
                          <img
                            *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined"
                            class="post-image"
                            src="../../assets/images/default.png"
                            alt="post-image"
                          />
                        </figure>
      
                        <div class="title" *ngIf="item.Name">
                          {{
                            item.Name
                          }}
                        </div>
                        <p class="title" *ngIf="!item.Name"></p>
                        <p class="unit-name">{{onDotsString(item.UnitName, 110)}}</p>
                        <!-- <div class="dash-line"></div>
                        <div class="text-item">{{ item.Summary }}</div> -->
                      </div>         
                    </div>
                    <kendo-datapager
                      [style.width.%]="100"
                      [pageSize]="pageSize"
                      [skip]="skip"
                      [total]="total"
                      (pageChange)="onPageChange($event)"
                      *ngIf="isViewAllList"
                    >
                    </kendo-datapager>
                  </div>
                </ng-template>
                
            </kendo-scrollview>
            <div class="view-more">
                <div (click)="redirectToViewMore()" class="view-more-button">{{ "View_More" | translate }}</div>
            </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 mobile-view">
                  <div class="post-list">
                      <div class="top-part">
                        <figure
                          class="img-figure"
                          [ngClass]="setH()"
                        >
                          <img
                            *ngIf="oneNews[0].ImageUrl && !oneNews[0].DefaultFlg"
                            class="post-image"
                            [src]="appService.apiRoot + oneNews[0].ImageUrl"
                            alt="error-image"
                          />
      
                          <img
                            *ngIf="oneNews[0].ImageUrl == this.LINK_DEFAULT_IMAGE || oneNews[0].ImageUrl == null || oneNews[0].ImageUrl == undefined"
                            class="post-image"
                            src="../../assets/images/default.png"
                            alt="post-image"
                          />
                        </figure>
      
                        <div class="text-part">
                          <p class="title" *ngIf="oneNews[0].Name">
                            {{
                              oneNews[0].Name
                            }}
                          </p>
                          <p class="title" *ngIf="!oneNews[0].Name"></p>
                          <p class="unit-name">{{ oneNews[0].UnitName }}</p>
                        </div>
                        <!-- <div class="dash-line"></div>
                        <div class="text-item">{{ item.Summary }}</div> -->
                      </div>         
                      <div class="view-more">
                          <div (click)="redirectToViewMore()" class="view-more-button">{{ "View_More" | translate }}</div>
                      </div>
                    </div>
            </div>
            <!-- Mobile view -->
            <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12" *ngIf="innerWidth<=768">
              <kendo-scrollview #scrollview [data]="topNews" [arrows]="true" 
                [pageable]="true"   
                [width]="sliderWidth"
                [height]="sliderHeight"
              >
                <ng-template let-item="item">
                  <div class="post-list">
                    <div
                      [className]="
                        'col-sm-12' +
                        ' col-12' +
                        ' col-md-' +
                        bootstrapNum +
                        ' pointer top-new-item'
                      "
                      [routerLink]="['post-detail/' + item.UrlPath]"
                    >
                      <div class="top-part">
                        <figure
                          class="img-figure"
                          [ngClass]="setH()"
                        >
                          <img
                            *ngIf="item.ImageUrl && !item.DefaultFlg"
                            class="post-image"
                            [src]="appService.apiRoot + item.ImageUrl"
                            alt="error-image"
                          />
      
                          <img
                            *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined"
                            class="post-image-default"
                            src="../../assets/images/default.png"
                            alt="post-image"
                          />
                        </figure>
      
                        <p class="title" *ngIf="item.Title">
                          {{
                            item.Title.length > 100
                              ? item.Title.slice(0, 100) + "..."
                              : item.Title
                          }}
                        </p>
                        <p class="title" *ngIf="!item.Title"></p>
                        <div class="dash-line"></div>
                        <div class="text-item">{{ item.Summary }}</div>
                      </div>         
                    </div>
                    <kendo-datapager
                      [style.width.%]="100"
                      [pageSize]="pageSize"
                      [skip]="skip"
                      [total]="total"
                      (pageChange)="onPageChange($event)"
                      *ngIf="isViewAllList"
                    >
                    </kendo-datapager>
                  </div>
                </ng-template>
              </kendo-scrollview>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <kendo-dialog
      *ngIf="manageDialog"
      (mousedown)="$event.stopPropagation()"
      class="router-outlet-content window-center dialog-info dialog-width"
    >
      <div class="news-template-44-dialog close-div">
        <div class="title-info-req">
          <strong *ngIf="editFlg == true && dltFlg == false">{{
            "Gallery_Scrollview_Edit" | translate
          }}</strong>
          <strong *ngIf="editFlg == false && dltFlg == false">{{
            "Gallery_Scrollview_Add" | translate
          }}</strong>
          <strong *ngIf="dltFlg == true">{{
            "Gallery_Scrollview_Delete" | translate
          }}</strong>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img
            class="close-icon"
            src="../../assets/images/close-button.png"
            alt=""
            (click)="onClose($event)"
          />
        </div>
      </div>
      <div *ngIf="dltFlg == true">
        <div class="input-label">
          <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
        </div>
        <div>
          <button
            class="btn-delete"
            (click)="onDeleteGallery()"
          >
            <span>{{ "Delete" | translate }}</span>
          </button>
          <button
            class="btn-cancel"
            (click)="onClose($event)"
          >
            <span>{{ "Cancel" | translate }}</span>
          </button>
        </div>
      </div>
    </kendo-dialog>
  </article>
  