<form class="user-info-container row" [formGroup]="userForm">
  <div class="user-upload-img col-12 col-md-3">
    <div class="user-image">
      <img
        class="user-avatar"
        [src]="errorImg || imgUrl"
        (error)="handleError()"
      />
    </div>
    <div class="upload">
      <kendo-upload
        formControlName="avatarUrl"
        [multiple]="false"
        [saveUrl]="fileSaveUrl"
        [showFileList]="false"
        (success)="onSuccessAvatarUpload($event)"
        [accept]="['.png', '.jpg', '.jpeg']"
      >
        <kendo-upload-messages
          [headerStatusUploaded]="''"
          [dropFilesHere]="''"
          [select]="'Upload_Image' | translate"
        >
        </kendo-upload-messages>
      </kendo-upload>
    </div>
  </div>
  <div class="user-form-container col-12 col-md-9">
    <h4 class="user-form-title">{{ "Account_Info" | translate }}</h4>
    <div class="user-form">
      <fieldset>
        <kendo-formfield>
          <div class="user-input-item">
            <input
              formControlName="account"
              [placeholder]="'User_Account' | translate"
              kendoTextBox
              required
            />
            <div
              class="user-error"
              *ngIf="
                !validForm &&
                (!userForm.get('account').valid ||
                  !userForm.value.account.trim())
              "
            >
              {{ "Contact_Error" | translate }}
            </div>
            <div class="user-error" *ngIf="dataErr[0]?.Username ? true : false">
              {{ dataErr[0].Username }}
            </div>
          </div>
        </kendo-formfield>
        <kendo-formfield>
          <div class="user-input-item">
            <input
              type="email"
              formControlName="email"
              [placeholder]="'User_Email' | translate"
              kendoTextBox
              required
            />
            <div
              class="user-error"
              *ngIf="
                !validForm &&
                (!userForm.get('email').valid ||
                  !userForm.value.email.trim() ||
                  !validateEmail())
              "
            >
              {{ "Contact_Invalid_Email" | translate }}
            </div>
            <div class="user-error" *ngIf="dataErr[0]?.Email ? true : false">
              {{ dataErr[0].Email }}
            </div>
          </div>
        </kendo-formfield>
        <kendo-formfield>
          <div class="user-input-item">
            <input
              formControlName="fullName"
              [placeholder]="'User_FullName' | translate"
              kendoTextBox
              type="text"
              required
            />
            <div
              class="user-error"
              *ngIf="
                !validForm &&
                (!userForm.get('fullName').valid ||
                  !userForm.value.fullName.trim())
              "
            >
              {{ "Contact_Error" | translate }}
            </div>
            <div class="user-error" *ngIf="dataErr[0]?.Name ? true : false">
              {{ dataErr[0].Name }}
            </div>
          </div>
        </kendo-formfield>
        <kendo-formfield>
          <div
            [ngClass]="
              checkNullSelect
                ? 'user-input-item'
                : 'user-input-item select-item'
            "
          >
            <kendo-dropdownlist
              formControlName="gender"
              [data]="genders"
              [itemDisabled]="itemDisabled"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
              (valueChange)="checkSelectNull($event)"
              required
            >
            </kendo-dropdownlist>
            <div
              class="user-error"
              *ngIf="!validForm && !userForm.get('gender').valid"
            >
              {{ "Contact_Error" | translate }}
            </div>
            <div class="user-error" *ngIf="dataErr[0]?.Gender ? true : false">
              {{ dataErr[0].Gender }}
            </div>
          </div>
        </kendo-formfield>
        <kendo-formfield>
          <!-- <div
            [ngClass]="
              checkNullDate ? 'user-input-item' : 'user-input-item select-item'
            "
          > -->
            <kendo-datepicker
              formControlName="birthDay"  
              [placeholder]="'User_BirthDay' | translate"
              (valueChange)="checkDateNull($event); checkDateValid()"
              [format]="'FormatDate' | translate"
              [formatPlaceholder]="{
                year: 'year' | translate,
                month: 'month' | translate,
                day: 'day' | translate
              }"
            >
            </kendo-datepicker>
            <div class="user-error" *ngIf="!validForm && !validDate">
              {{ "Contact_Error_Date" | translate }}
            </div>
            <div
              class="user-error"
              *ngIf="!validForm && !userForm.get('birthDay').valid"
            >
              {{ "Contact_Error" | translate }}
            </div>
            <div
              class="user-error"
              *ngIf="dataErr[0]?.BirthDate ? true : false"
            >
              {{ dataErr[0].BirthDate }}
            </div>
          <!-- </div> -->
        </kendo-formfield>
        <div class="user-button-wrapper">
          <button class="user-form-button quit-button" (click)="onCancel()">
            {{ "Cancel" | translate }}
          </button>
          <button class="user-form-button" (click)="onSubmit()">
            {{ "COMMON_SAVE" | translate }}
          </button>
        </div>
      </fieldset>
    </div>
  </div>
</form>
<kendo-dialog *ngIf="successToggle" [minWidth]="250" [width]="543">
  <div class="success-container">
    <img class="success-icon" src="assets/images/successIcon.png" />
    <p class="success-title">{{ "User_Success_Title" | translate }}</p>
    <P class="success-msg"> {{ "User_Success_Msg" | translate }} </P>
    <button kendoButton (click)="onSuccessDialog()">
      {{ "Continue" | translate }}
    </button>
  </div>
</kendo-dialog>
