<section class="question-search ">
    <div  class="row">
        <div class="col-md-7 col-7 margin30">
            <div [ngClass]="'control control-search'">
                <input kendoTextBox class="search-place" [(ngModel)]="searchOption.searchText" [placeholder]="'InputSearchText'|translate" (keypress)="onSearchKeyPress($event)" />
                <button (click)="onRemoveSearchText();" [primary]="true" [look]="'outline'" type="button" class="xbutton-dialog close"
                  class="btn-remove btn-remove-replace-btn-search" *ngIf="searchOption.searchText !== ''">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="col-md-3 col-3 margin30">
            <button  class="btn-search" (click)="onSearch()"  (keypress)="onSearchKeyPress($event)" >
                <span>{{ 'Label_Btn_Search' | translate }}</span>
            </button>
        </div>

        <div class="col-md-12 col-12 margin-btt">
            <article>            
                <p class="total-question" *ngIf="questionSearch">{{ 'ShowAll' | translate }} {{ questionSearch.length}} {{ 'question' | translate }}</p>
            </article>                        
        </div> 

        <div class="col-md-12 col-12 list-question" *ngFor="let item of pagedQuestion ; let i = index" (click)=onCellClick(item)>
            <article>
                <hgroup>
                    <h3 class="title"><i class="fa fa-comments-o custom-color" aria-hidden="true"></i> {{item.Title}}</h3> 
                </hgroup> 
                <aside>
                    <div class="contents">
                       <span class="contents_label">  {{'ContentsQuestion' | translate}} </span>
                       <span>{{limitDisplay(item.Contents)}} </span></div>
                    <hr *ngIf="i < pagedQuestion.length -1" class="hr-style">
                </aside>                             
            </article>           
        </div>
        <kendo-datapager *ngIf= 'questionSearch.length > pageSize'
        [style.width.%]="100"
        [pageSize]="pageSize"        
        [pageSizeValues]="false"
        [skip]="skip"
        [total]="total"
        [info]="false"
        (pageChange)="onPageChange($event)"
      >
      </kendo-datapager>
    </div>
</section>

<!-- Dialog Chi tiết câu hỏi -->
<kendo-dialog *ngIf="openQuestionDetail " class="question-search-detail x-dialog window-center " [draggable]="false">
    <div class="employe-detail">
        <div class="x-close-div">
            <button type="button" class="xbutton-dialog close" aria-label="Close" (click)="onCloseQuestionDetail()">
                <span aria-hidden="true">&times;</span>
            </button>            
            <div class="title-info-req">
                <span [textContent]="'QuestionDetail'  | translate"></span>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-12">
                <article>
                    <hgroup>
                        <h2 class="title-question"><i class="fa fa-question-circle custom-color" aria-hidden="true"></i> {{'Question' | translate}} </h2> 
                        <h3 class="title">{{questionSearchtemp.Title}}</h3> 
                    </hgroup>
                    <aside>
                        <p class="contents">{{questionSearchtemp.Contents}}</p> 
                    </aside>                    
                    <hr>
                </article>
            </div>                   
            <div class="col-md-12 col-12">
                <article>
                    <hgroup>
                        <h2 class="title-question title-answer"><i class="fa fa-comments custom-color" aria-hidden="true"></i> {{'RecommendAnswer' | translate}} </h2> 
                    </hgroup>        
                    <aside>
                        <p class="contents">{{questionSearchtemp.FeedbackContent}}</p> 
                    </aside>                                
                    <aside class="file-container" *ngIf="questionSearchtemp.FileUrls.length !=  0"  >
                        <hgroup>
                            <h3 class="custom-text" [textContent]="'FileAttachment' | translate"></h3>
                        </hgroup>
                        <aside *ngFor="let file of questionSearchtemp.FileUrls; let i = index" >
                                <a [href]="getUrlDownload(file)" [target]="_blank">
                                    <p class="custom-text-file"  >{{ getFileName(file) }}</p>
                                </a>
                        </aside>
                    </aside>
                </article>                
            </div>
        </div>
     
    </div>    
</kendo-dialog>