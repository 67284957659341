import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import {
  SelectableSettings,
  PageChangeEvent,
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';

import { Observable } from 'rxjs';
import { SearchService } from '../services/search.service';
@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
})
export class SearchPageComponent implements OnInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: [];
  viewMore: string = '';
  isShowMenu = false;
  loading = false;
  manageDialog = false;
  searchText: any;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  galleryData: any;
  editFlg = false;
  @Input() OrderIdx: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() saveData = new EventEmitter();
  @Output() tittleItem = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() pageBodyIndex: any;
  public pageSizes = true;
  dataGridSortByField: SortDescriptor[] = [];

  ImageListPage = [];
  dataGridSkip = AppConsts.pageSkip;
  dataGridPageSize = AppConsts.pageSize;
  dataGridSelection: number[] = [];
  dataGridItem: any;
  dataLangs = [];
  dataGridGridDataResult: GridDataResult;
  public dataGridState: State = {
    skip: this.dataGridSkip,
    take: this.dataGridSkip + this.dataGridPageSize,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  pageSize = 24;
  skip = 0;
  total = 0;

  errorImg: any;
  pageHeader: any;
  pageFooter: any;

  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private route: ActivatedRoute,
    private search: SearchService
  ) {}
  searchOption = {
    SearchText: '',
  };
  async ngOnInit() {
    // this.searchText = document.querySelector(
    //   '.form-control'
    // ) as HTMLInputElement;
    // if (this.searchText) {
    //   this.searchText.addEventListener('keydown', (event) => {
    //     if (event.key === 'Enter') {
    //       this.onSearch();
    //     }
    //   });
    // }

    // this.onSearch();
    this.getHeader();
    this.getFooter();
    this.route.queryParams.subscribe((params) => {
      this.searchText = params.searchText;
      console.log('init', this.searchText);
      this.search.setSearchText(this.searchText);
    });
    this.onSearch();
  }
  onSearch() {
    if (this.searchText) {
      this.searchOption.SearchText = this.searchText;
    }
    if (this.searchOption) {
      this.getSearchPage();
    } else {
      this.searchOption = this.searchText;
    }
  }

  async getSearchPage() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      langID: this.language.get(),
    };
    const result = await this.appService.doGET(
      'api/PortalPost/GetSearchNews',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.postTittle = result.Data;
      this.skip = 0;
      this.total = result.Data.length;
      this.pageData();
      this.binddataItems();
    }
    this.loading = false;
  }

  binddataItems() {
    this.dataGridGridDataResult = {
      data: orderBy(this.postTittle, this.dataGridSortByField),
      total: this.postTittle.length,
    };

    this.dataGridGridDataResult = process(this.postTittle, this.dataGridState);
  }
  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextRemove(e: any) {
    this.searchOption.SearchText = '';
    this.onSearch();
  }
  onClose(e) {
    if (e == true) {
      this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.manageDialog = false;
  }
  onSaveData() {
    this.saveData.emit();
  }
  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }
  private pageData(): void {
    if (this.postTittle) {
      this.ImageListPage = this.postTittle.slice(
        this.skip,
        this.skip + this.pageSize
      );
    }
  }

  redirectToItem(url: string) {
    if (url != null && url != undefined) {
      this.router.navigate([AppConsts.page.postDetail + '/' + url]);
    }
  }
  async getHeader() {
    const result = await this.appService.doGET('api/PortalHeader', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageHeader = [result.Data.HeaderType];
    }
  }
  async getFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageFooter = [result.Data.FooterType];
    }
  }
}
