import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { AppLanguage } from 'src/app/services/app.language';
import { Notification } from 'src/app/services/app.notification';
import { AppSwal } from 'src/app/services/app.swal';
import { AppControls } from 'src/app/services/app.controls';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  userName: any;
  successToggle = false;
  validForm: boolean = true;
  oldPasswordFailed: boolean = false;
  newPasswordFailed: boolean = false;

  changePasswordForm = new FormGroup({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required),
    confirmNewPassword: new FormControl('', Validators.required),
  });

  constructor(
    private translate: TranslateService,
    public appService: AppService,
    private appSwal: AppSwal,
    public appControls: AppControls
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): any {
    this.userName = this.appService.getUserName();
  }

  checkConfirmPassword() {
    if (
      this.changePasswordForm.value.newPassword ===
      this.changePasswordForm.value.confirmNewPassword
    )
      return true;
    else return false;
  }

  async onSubmit() {
    this.oldPasswordFailed = false;
    this.validForm = this.changePasswordForm.valid;
    if (this.checkConfirmPassword()) this.newPasswordFailed = false;
    else this.newPasswordFailed = true;

    if (this.validForm && this.checkConfirmPassword()) {
      const dataRequest = {
        PasswordNew: this.changePasswordForm.value.newPassword,
        PasswordOld: this.changePasswordForm.value.oldPassword,
        UserName: this.userName,
      };

      const result = await this.appService.doPOST(
        'api/User/ChangePassword',
        dataRequest
      );
      if (result && result.Status === 1 && result != null) {
        this.successToggle = true;
        this.changePasswordForm.reset();
      } else {
        if (!result.Msg) {
          this.appSwal.showWarning(result.Data, false);
        } else {
          this.oldPasswordFailed = true;
        }
      }
    }
  }

  onCancel() {
    this.changePasswordForm.reset();
  }

  onSuccessDialog() {
    this.successToggle = false;
  }
}
