<header *ngIf="isLoaded">
  <!-- Banner Top -->
  <div class="banner">
    <figure>
      <!-- <img class="img1" *ngIf="header[0]" [src]="appService.apiRoot + header[0].ImageUrl" alt="header[0].ImageUrl"> -->
      <nav>
        <a  *ngIf="header[0]" [ngClass]="header[0].Link?'':'disable-click'" [href]="header[0].Link">
          <img class="img1" *ngIf="header[0]" [src]="appService.apiRoot + header[0].ImageUrl" alt="header[0].ImageUrl">
        </a>
      </nav>
      <img    class="img2 " *ngIf="header[1]" [src]="appService.apiRoot + header[1].ImageUrl" alt="header[1].ImageUrl">
    </figure> 
     
    <div class="banner-link mobile" (click)="reset()" [routerLink]="['']"></div>
    <div class="menu-icon-box">
      <mat-icon class="menu-icon" (click)="open()">menu</mat-icon>
    </div>
    <div class="language desktop">
      <nav>
        <a class="language-text" [ngClass]="isVi?'color-text-selected':''" (click)="changeLanguage(languages[0].culture)">Tiếng việt</a> |
        <a class="language-text" [ngClass]="isVi?'':'color-text-selected'" (click)="changeLanguage(languages[1].culture)">English</a>
      </nav>
    </div>
    <div class="name">
      <hgroup>
        <span class="name-main">{{'Name-Main'|translate}}</span> <br>
        <span class="name-detail">{{'Name-Detail'|translate}}</span>
      </hgroup>
    </div>
    <div class="language mobile">
      <nav>
        <a *ngIf="!isVi" (click)="changeLanguage(languages[0].culture)">VI</a>
        <a *ngIf="isVi" (click)="changeLanguage(languages[1].culture)">EN</a>
      </nav>
    </div>
    <input type="text" class="search form-control desktop" (keydown.enter)="search(searchText?.value, true)"  [placeholder]="'Search-Text' | translate" #searchText name="searchText" value="">
    <input type="text" class="search form-control mobile" *ngIf="isSearch"  (keydown.enter)="search(searchText?.value, true)"  [placeholder]="'Search-Text' | translate" #searchText name="searchText" value="">
    <mat-icon class="search-icon" (click)="search(searchText?.value, false)">search</mat-icon>
    <div class="search-cover-shadow" *ngIf="isSearch" (click)="reset()"></div>
  </div>

  <!-- Thanh Menu -->
  <nav class="nav-menu-box">
    <ul class="nav-content" >
      <div class="navbar-cover-shadow" (click)="open()" *ngIf="openFlg" ></div>
      <div class="nav-menu" [ngClass]="openFlg?'navbar-display':'navbar-hidden'"  #mother0>
      <div class="close-button-box mobile"><mat-icon (click)="open()" class="close-button">close</mat-icon></div>
      <li class="dropdown-home">
        <a [routerLink]="['/']"> <mat-icon class="home-icon desktop-icon" >home</mat-icon></a>
      </li>
      <li class="menu-obj level0 dropdown" *ngFor="let item of menus" #level0 (click)="selectMenu($event, mother0,level0,'level0')" [id]="item.ID">
        <a class="item-link pading-0" [title]="item.Name" [ngClass]="item.Childrens.length>0?'disable-click':null" (click)="open()" [routerLink]="[item.PageID? parseURL(item.PageID): (postList +'/'+ item.Url)]"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{item.Name}}
        </a>
        <mat-icon *ngIf="item.Childrens.length>0 && !selectedMenuID.includes(item.ID)" class="button-expand mobile">add</mat-icon>
        <mat-icon *ngIf="item.Childrens.length>0 && selectedMenuID.includes(item.ID)" class="button-expand mobile">remove</mat-icon>
          <div *ngIf="item.Childrens.length>0" class="dropdown-menu" #mother1>
            <!-- Level 1 -->
              <div class="menu-obj level1 dropdown-submenu"   *ngFor="let subtab01 of item.Childrens" #level1 (click)="selectMenu($event, mother1, level1, 'level1')" [id]="subtab01.ID">
                <a class="dropdown-item pading-1" [ngClass]="subtab01.Childrens.length>0?'disable-click':null" (click)="open()" [routerLink]="[subtab01.PageID? parseURL(subtab01.PageID): (postList +'/'+ subtab01.Url)]">
                  {{subtab01.Name}}
                  <mat-icon *ngIf="subtab01.Childrens.length>0 && !selectedMenuID.includes(subtab01.ID) " class="button-expand  mobile">add</mat-icon>
                  <mat-icon *ngIf="subtab01.Childrens.length>0 &&  selectedMenuID.includes(subtab01.ID) " class="button-expand  mobile">remove</mat-icon>
                  <mat-icon *ngIf="subtab01.Childrens.length>0" class="play-arrow desktop-icon">play_arrow</mat-icon>
                </a>
                    <div class="dropdown-menu" *ngIf="subtab01.Childrens.length>0 " #mother2>
                    <!-- Level 2 -->
                      <div class="menu-obj level2 dropdown-submenu" *ngFor="let subtab02 of subtab01.Childrens" #level2 (click)="selectMenu($event, mother2,level2, 'level2')" [id]="subtab02.ID">
                        <a class="dropdown-item pading-2" aria-haspopup="true" [ngClass]="subtab02.Childrens.length>0?'disable-click':null" (click)="open()" [routerLink]="[subtab02.PageID? parseURL(subtab02.PageID): (postList +'/'+ subtab02.Url)]">
                          {{subtab02.Name}}
                          <mat-icon *ngIf="subtab02.Childrens.length>0 && !selectedMenuID.includes(subtab02.ID)" class="button-expand  mobile">add</mat-icon>
                          <mat-icon *ngIf="subtab02.Childrens.length>0 &&  selectedMenuID.includes(subtab02.ID) " class="button-expand  mobile">remove</mat-icon>
                          <mat-icon *ngIf="subtab02.Childrens.length>0" class="play-arrow desktop-icon">play_arrow</mat-icon>
                        </a>
                          <div class="dropdown-menu" *ngIf="subtab02.Childrens.length>0" #mother3>
                          <!-- Level 3 -->
                            <div class="menu-obj level3 dropdown-submenu" *ngFor="let subtab03 of subtab02.Childrens"  #level3 (click)="selectMenu($event, mother3,level3, 'level3')" [id]="subtab03.ID">
                              <a class="dropdown-item pading-3" aria-haspopup="true" [ngClass]="subtab03.Childrens.length>0?'disable-click':null" (click)="open()" [routerLink]="[subtab03.PageID? parseURL(subtab03.PageID): (postList +'/'+ subtab03.Url)]">
                                {{subtab03.Name}}
                                <mat-icon *ngIf="subtab03.Childrens.length>0 && !selectedMenuID.includes(subtab03.ID)" class="button-expand  mobile">add</mat-icon>
                                <mat-icon *ngIf="subtab03.Childrens.length>0 &&  selectedMenuID.includes(subtab03.ID)" class="button-expand  mobile">remove</mat-icon>
                                <mat-icon *ngIf="subtab03.Childrens.length>0" class="play-arrow desktop-icon">play_arrow</mat-icon>
                              </a>
                                <div class="dropdown-menu" *ngIf="subtab03.Childrens.length>0" #mother4>
                               <!-- Level 4 -->
                                  <div class="menu-obj level4 dropdown-submenu" *ngFor="let subtab04 of subtab03.Childrens" #level4 (click)="selectMenu($event, mother4,level4, 'level4')" [id]="subtab04.ID">
                                    <a class="dropdown-item pading-4" aria-haspopup="true" (click)="open()" [routerLink]="[subtab04.PageID? parseURL(subtab04.PageID): (postList +'/'+ subtab04.Url)]">
                                      {{subtab04.Name}}
                                    </a>
                                  </div>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </li>
      </div>
    </ul>
  </nav>
</header>
