<article [ngClass]="templateType ==1 ? 'news-template-30' : 'news-template-30-without-time'"
  (window:resize)="onResize($event)" id="template30Width">
  <!-- <div class="background">
    <img src="../../assets/images/backround-library.png" class="background-library"> -->
  <div class="container-fluid" [ngClass]="isTemplatePage == true ? '': 'desktop-width'">
    <div class="row content-width"  [ngClass]="isTemplatePage == true ? 'content-width-mainpage' : 'content-width-media'"  [style.width.px]="template30Width">
      <div class="main-item">
        <div class="row padding-box">
          <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
            <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
            <div class="menu" *ngIf="isShowMenu">
              <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{'Edit' | translate}}</div>
              </div>
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{'Delete' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12 template-title" *ngIf="templateType == 1">
            <p>
              {{ "Library" | translate }}
            </p>
          </div>
          <div class="row">
            <div class=" col-lg-5 col-md-12 col-sm-12">
              <ng-container *ngFor="let item of pagedTopnews; let i = index">
                <div class="top-part row" *ngIf="i==0">
                  <div (click)="openDialogVideo(item)">
                    <figure class="post-img-first" [style.height.px]="innerWidth*0.15">
                      <a class="img-figure-first">
                        <ng-container *ngIf="item.FileUrls">
                          <div class="pointer width-full">
                            <div class="overlay"></div>
                            <section *ngIf="item.Type == 0" class="video-container no-pointer-events" [innerHTML]="
                            getVideoUrl(item.FileUrls) | sanitizedHtml
                            ">
                            </section>
                            <img *ngIf="item.Type == 1" class="img-first" [src]="getFullUrlAPI(item.FileUrls)"
                              alt="img-figure-first" />
                          </div>
                        </ng-container>
                        <img
                          *ngIf="item.FileUrls == this.LINK_DEFAULT_IMAGE || item.FileUrls == null || item.FileUrls == undefined"
                          class="post-image-first" src="../../assets/images/default.png" alt="post-image" />
                      </a>
                    </figure>
                    <div class="post-title">
                      <div class="title-first pointer " *ngIf="item.Name">
                        {{
                        item.Name
                        }}
                      </div>
                    </div>
                    <div class="title">
                      <div class="description-text pointer" *ngIf="item.Description">
                        {{
                        item.Description
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12">
              <div class="row">
                <ng-container *ngFor="let item of  pagedTopnews; let i = index">
                  <div class="top-part col-lg-4 col-md-4 col-sm-12 padding" *ngIf="i!=0">
                    <div class="flex">
                      <div (click)="openDialogVideo(item)">
                        <figure class="img-figure-other" [style.height.px]="innerWidth*0.085">
                          <ng-container *ngIf="item.FileUrls">
                            <div class="pointer width-full">
                              <div class="overlay"></div>
                              <section *ngIf="item.Type == 0" class="video-container" [innerHTML]="
                                getVideoUrl(item.FileUrls) | sanitizedHtml
                                ">
                              </section>
                              <img *ngIf="item.Type == 1" class="img-figure" [src]="getFullUrlAPI(item.FileUrls)"

                                alt="post-image-first" />
                            </div>
                          </ng-container>
                          <img
                            *ngIf="item.FileUrls == this.LINK_DEFAULT_IMAGE || item.FileUrls == null || item.FileUrls == undefined"
                            class="img-figure" src="../../assets/images/default.png" alt="post-image" />
                        </figure>
                        <div class="post-title">
                          <div class="title-other pointer " *ngIf="item.Name">
                            {{
                            item.Name
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <ks-modal-gallery [id]="30" [modalImages]="imagesRect" 
            [previewConfig]="previewConfigFiveImages" 
            [slideConfig]="{infinite: true, sidePreviews: {show: false}}"
            [currentImageConfig]="{
              loadingConfig: {enable: true, type: 2},
              description: customFullDescription
            }"
                              
          >
          </ks-modal-gallery>
        </div>
      </div>
    </div>
    <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
      class="router-outlet-content window-center dialog-info dialog-width">
      <div class="news-template-30-dialog close-div">
        <div class="title-info-req">
          <strong *ngIf="editFlg == true && dltFlg == false">{{
            "Gallery_Scrollview_Edit" | translate
            }}</strong>
          <strong *ngIf="editFlg == false && dltFlg == false">{{
            "Gallery_Scrollview_Add" | translate
            }}</strong>
          <strong *ngIf="dltFlg == true">{{
            "Gallery_Scrollview_Delete" | translate
            }}</strong>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
        </div>
      </div>
      <app-news-template-30-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID"
        [HiddenTab]="HiddenTab" (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg"
        [templateID]="templateID" [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()"
        (onChangetemp1)="this.changeTemp1($event)">
      </app-news-template-30-dialog>
      <div *ngIf="dltFlg == true">
        <div class="input-label">
          <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"></span>
        </div>
        <div>
          <button class="btn-delete" (click)="onDeleteGallery()">
            <span>{{ "Delete" | translate }}</span>
          </button>
          <button class="btn-cancel" (click)="onClose($event)">
            <span>{{ "Cancel" | translate }}</span>
          </button>
        </div>
      </div>
    </kendo-dialog>
    <!-- <kendo-dialog *ngIf="isShowDialogVideo" (mousedown)="$event.stopPropagation()"
      class=" router-outlet-content window-center dialog-info dialog-width-images dialog2 dialogimage2 koverley2">
      <div class="opend-30-dialog close-div2">
        <div class="close-icon-div">
          <img class="close-icon" src="../../assets/images/close.png" alt="" (click)="onCloseDialogVideo()" />
        </div>
      </div>
      <app-opend-30-dialog [srcURL]="srcURL">
      </app-opend-30-dialog>
    </kendo-dialog>
    <div *ngIf="isShowDialogVideo" class="col-md-12 col-12 center">
      <div *ngIf="srcURL.Type == 1 " class="image-list">
        <div *ngFor="let item of this.b ; let i = index">
          <img *ngIf="this.b.length>0" src="{{ this.appService.apiRoot + item }}" class="smallImg"/>
        </div>
      </div>
    </div> -->
    <div class="dialog-container" *ngIf="isShowDialogVideo">
      <kendo-dialog (mousedown)="$event.stopPropagation()" *ngIf="typeFile == 0"
        class=" router-outlet-content window-center dialog-info dialog-width-images dialog2 dialogimage2 koverley2">
        <div class="opend-dialog-images close-div2 ">

          <div class="close-icon-div">
            <img class="close-icon" src="../../assets/images/close.png" alt="" (click)="onCloseDialogVideo()" />
          </div>
        </div>
        <app-opend-dialog-images [srcURL]="srcURL" [typeFile]="typeFile">
        </app-opend-dialog-images>
      </kendo-dialog>
    </div>
  </div>
</article>