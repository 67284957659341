<div class="image-slider-a">
  <div>
    <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
      <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
      <div class="menu" *ngIf="isShowMenu">
        <!-- <div class="menu-row-current-border" (click)="addComponent()">
          <div class="menu-item-1">Thêm</div>
        </div> -->
        <div class="menu-row-current-border" (click)="editComponent()">
          <div class="menu-item-2">{{'Upload_Image' | translate}}</div>
        </div>
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
    <kendo-scrollview #scrollview [endless]="true" [data]="info" [width]="widthSView"
      [height]="heightSView" [pageable]="true">
      <ng-template let-item="item">
        <img *ngIf="info != undefined && info != null && info.length != 0" src="{{this.appService.apiRoot+item.ImageUrl}}" alt="{{item.Title}}" class="img-scrollview"
          draggable="false" (click)="getLink(item.DetailPath)" />
        <img *ngIf="info == undefined || info == null || info.length == 0" src="../../assets/images/default.png" alt=""
          draggable="false" class="img-scrollview"/>
      </ng-template>
    </kendo-scrollview>
    <div class="statistic">
      <div class="stat-box">
        <div class="stat-box-title">{{statBoxTitle}}</div>
        <div class="stat-box-info">
          <div class="stat-description">{{statDescription}}</div>
          <div class="stat-number">{{statNumber}}</div>
          <div class="stat-unit">{{statUnit}}</div>
        </div>
        <div class="hotline">
          <div class="hotline-title">Hotline</div>
          <div class="hotline-number">{{hotline}}</div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" [draggable]="false" class="router-outlet-content dialog-info window-center">
    <div class="employe-detail">
      <div class="slider-dialog close-div">
        <div class="title-info-req">
          <span *ngIf="dltFlg == false && isGallery == false" [textContent]="'Select_Images' | translate"></span>
          <span *ngIf="dltFlg == false && isGallery == true" [textContent]="'Gallery' | translate"></span>
          <span *ngIf="dltFlg == true" [textContent]="'Gallery_Scrollview_Delete' | translate"></span>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img *ngIf="isGallery == false" class="close-icon" src="../../assets/images/close-btn-black.png" alt="" (click)="onClose($event)" />
          <img *ngIf="isGallery == true" class="close-icon" src="../../assets/images/close-btn-black.png" alt="" (click)="onGoBack()" />
        </div>
      </div>
    </div>
    <app-archive *ngIf="dltFlg == false" [pageSelectedID]="pageSelectedID" [ID]="ID" [info]="info" [templateID]="templateID"
    [galleryData]="galleryData" (onSave)="onSave($event)" (addNew)="addNewBanner($event)"
    (deleteAll)="deleteAllBanner($event)" (isGallery)="isGalleryDialog($event)" [isGoBack]="isGallery">
  </app-archive>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</div>
