<article [ngClass]="
    templateType == 1 ? 'news-template-36' : 'news-template-36-without-time'
  ">
  <div class="container-fluid row">
    <div class="row">
      <div class="main-item">
        <div class="row">
          <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex' : ''">
            <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
            <div class="menu" *ngIf="isShowMenu">
              <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{ "Edit" | translate }}</div>
              </div>
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{ "Delete" | translate }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title" *ngIf="templateType == 1">
            <p class="uppercase title-text">
              {{ "Media_Radio" | translate }}
            </p>
            <div *ngIf="isMore" class="post-more">
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div class="wrapper">
              <kendo-tabstrip (tabSelect)="onTabSelect($event)" >
                <kendo-tabstrip-tab *ngFor="let tab of mediaCommonTabs; let i = index" [title]="tab.Name"
                  [selected]="i == 0 ? true : false">
                  <ng-template kendoTabContent>
                    <div class="content">
                      <div class="row d-grid">
                        <div class="radio-container wb"  *ngFor="let item of pagedTopnews; let j = index">
                          <div class="radio-order">{{getIndex(item.ID)}}</div>
                          <div class="media">
                            <img *ngIf="item.FileUrls" class="post-image-cover post-image"
                              src="../../assets/images/radio_image.png" alt="post-image" />
                          </div>
                          <div class="radio-info">
                            <div class="album-name">{{ item.Name}}</div>
                            <audio controls>
                              <source [src]="getAudioUrl(item.FileUrls)" type="audio/mpeg">
                            </audio>
                          </div>
                          <div class="author-name">{{ 'SAWACO'}}</div>
                          <img class="view-count-icon" src="../../assets/images/ic_baseline-remove-red-eye.svg" alt="">
                          <span class="view-count">{{item.CntNum ? item.CntNum : 0 | number:'1.0-0'}}</span>
                          <button class="download-radio" (click)="downloadFile(item.FileUrls, item.ID)">
                            <img class="download-radio-icon" src="../../assets/images/ic_sharp-download.svg" alt="">
                          </button>
                        </div>
                        <div class="mb">
                          <div class="txt-break item-menu radio-container" *ngFor="let item of pagedTopnews; let j = index">
                            <div class="row">
                              <div class="col-md-12 col-12">
                                <strong> {{ "Radio_title" | translate }}: </strong>
                
                                <span class="value">{{ item.Name }}</span>
                              </div>
                
                
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Create_by" | translate }}: </strong>
                              <span class="value">{{ 'SAWACO' }}</span>
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Download_Num" | translate }}: </strong>
                              <span class="value"> {{item.CntNum ? item.CntNum : 0 | number:'1.0-0'}}</span>
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Download" | translate }}: </strong>
                              <span class="value"><img src="../../assets/images/Download.png" class="img" (click)="downloadFile(item.FileUrls, item.ID)" /></span>
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Play" | translate }}: </strong>
                              <span class="value">                            
                                <audio controls>
                                  <source [src]="getAudioUrl(item.FileUrls)" type="audio/mpeg">
                                </audio>
                              </span>
                            </div>
                          </div>
                        </div>
                        <kendo-datapager *ngIf="pagedTopnews.length>0"
                          [style.width.%]="100"
                          [pageSize]="pageSize"
                          [skip]="skip"
                          [total]="total"
                          [info] = false
                          [pageSizeValues]= false
                          (pageChange)="onPageChange($event)">
                      </kendo-datapager>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>

              </kendo-tabstrip>
              <ks-modal-gallery [id]="36" [modalImages]="imagesRect" 
                [previewConfig]="previewConfigFiveImages" 
                [slideConfig]="{infinite: true, sidePreviews: {show: false}}"
                [currentImageConfig]="{loadingConfig: {enable: true, type: 2}}">
              </ks-modal-gallery>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
            </div> -->
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width">
    <div class="news-template-36-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
          }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-box">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-36-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp1)="this.changeTemp1($event)">
    </app-news-template-36-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"></span>
      </div>
      <div>
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
  <div class="dialog-container" *ngIf="opendDialogImages">
    <kendo-dialog (mousedown)="$event.stopPropagation()" *ngIf="typeFile == 0"
      class=" router-outlet-content window-center dialog-info dialog-width-images dialog2 dialogimage2 koverley2">
      <div class="opend-dialog-images close-div2 ">

        <div class="close-icon-div">
          <img class="close-icon" src="../../assets/images/close.png" alt="" (click)="onCloseDialogImages()" />
        </div>
      </div>

    </kendo-dialog>
  </div>

</article>
