import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { parse } from 'querystring';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { AppConsts } from '../services/app.consts';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
@Component({
  selector: 'app-images-slider',
  templateUrl: './images-slider.component.html',
  // styleUrls: ['./images-slider.component.scss']
  encapsulation: ViewEncapsulation.None,
})
export class ImagesSliderComponent implements OnInit, AfterViewInit {
  @Input() isAdminFlg;
  @Input() ID;
  @Input() itemTemplate;
  @Input() templateID;
  @Input() pageSelectedID;
  @Input() OrderIdx;
  @Input() banner;
  @Input() newsDecorationIndex: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  info: any = [];
  ImageUrl: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate0 = new EventEmitter();
  @Output() deleteBodyItem = new EventEmitter();
  @ViewChild('scrollview') scrollview;
  galleryData: any;
  isLoadedBannerRightSide = false;
  dataImageSView: Array<{ Title: string; Link: string; ImageUrl: string }> = [];
  isShowMenu = false;
  editFlg = false;
  sliderList = [];
  dltFlg = false;
  manageDialog = false;
  public widthSView = '100%';
  public heightSView = '406px';
  isGallery: boolean = false;
  isGoBack: boolean = false;
  private interval;
  currentSlide: any;
  firstSlide = 0;
  lastSlide = 5;
  index = 0;
  indexEvent = 1;
  gapSlide = 144;
  slideId: any;
  item: any;
  slide: any;
  slideWidth: any;
  activeArrowLeft = false;
  activeArrowRight = false;
  nextArrow: any;
  prevArrow: any;
  itemLength: any;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {
    // clearInterval(this.interval);
  }

  getLink(link) {
    // window.location.href = 'https://' + item.Link;
    // window.open(AppConsts.page.postDetail + '/' + link, '_blank');
  }

  getFullUrl(url: string) {
    // return this.appService.apiRoot + url;
  }

  enableChoice() {
    // this.isShowMenu = !this.isShowMenu;
  }

  // addComponent() {
  //   this.editFlg = false;
  //   this.manageDialog = true;
  //   this.isShowMenu = false;
  // }

  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }

  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }
  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      index: this.newsDecorationIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      pageBodyIndex: this.pageBodyIndex,
    });
  }

  addNewBanner(e) {
    this.isLoadedBannerRightSide = e;
  }

  deleteAllBanner(e) {
    this.isLoadedBannerRightSide = e;
  }
  isGalleryDialog(e) {
    this.isGallery = e;
  }

  onSave(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    let newTemp = {
      Info: e.Info,
      Saved: 1,
      pageBodyIndex: this.pageBodyIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      index: this.newsDecorationIndex,
    };
    this.changeTemplate0.emit(newTemp);
    this.editFlg = false;
    this.manageDialog = false;
  }

  onClose(e) {
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onGoBack() {
    this.isGallery = false;
  }

  arrowPrev() {
    this.checkArrow('prev');
    this.positionSlide('prev');
    this.checkItemActive('prev');
  }

  arrowNext() {
    this.checkArrow('next');
    this.positionSlide('next');
    this.checkItemActive('next');
  }

  positionSlide(status: string) {
    this.slide.style.transition = '.7s ease';
    if (status == 'next') {
      if (this.item.length % 2 == 0) {
        this.indexEvent += 2;
        this.evenNumber();
      } else {
        this.index++;
        this.oddNumber();
      }
    }
    if (status == 'prev') {
      if (this.item.length % 2 == 0) {
        this.indexEvent -= 2;
        this.evenNumber();
      } else {
        this.index--;
        this.oddNumber();
      }
    }
  }

  evenNumber() {
    this.slide.style.transform = `translateX(${
      ((-this.slideWidth - this.gapSlide) / 2) * this.indexEvent
    }px)`;
  }

  oddNumber() {
    this.slide.style.transform = `translateX(${
      (-this.slideWidth - this.gapSlide) * this.index
    }px)`;
  }

  checkItemActive(status: string) {
    let currPos = 0;
    for (let i = 0; i < this.item.length; i++) {
      if (this.item[i].classList.contains('active')) {
        this.item[i].classList.remove('active');
        currPos = i;
      }
    }
    if (status == 'next') {
      this.item[currPos + 1].classList.add('active');
    }
    if (status == 'prev') {
      this.item[currPos - 1].classList.add('active');
    }
  }

  checkArrow(name: any) {
    this.getItemSlide();
    if (name == 'next') {
      if (this.item[this.item.length - 2].classList.contains('active')) {
        this.activeArrowRight = true;
        if (this.activeArrowRight == true) {
          this.nextArrowStatus('hidden');
        }
      }
      if (this.activeArrowLeft == true) {
        this.prevArrowStatus('active');
      }
    } else if (name == 'prev') {
      if (this.item[1].classList.contains('active')) {
        this.activeArrowLeft = true;
        if (this.activeArrowLeft == true) {
          this.prevArrowStatus('hidden');
        }
      }
      if (this.activeArrowRight == true) {
        this.nextArrowStatus('active');
      }
    }
  }

  async ngOnInit() {
    // if (this.banner.Info.includes('ImageUrl')) {
    //   this.info = JSON.parse(this.banner.Info);
    //   this.isLoadedBannerRightSide = true;
    // }
    // this.isLoadedBannerRightSide = true;
    // this.galleryData = {
    //   OrderIdx: this.OrderIdx,
    //   GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
    //   Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
    //   GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    // };
    await this.getSlide();
  }

  initSlideList() {
    // this.currentSlide = [...this.items];
  }

  async getSlide() {
    const result = await this.appService.doGET(
      'api/PortalBody/GetCommon',
      {
        langID: this.language.get(),
        type: 2,
      }
    );
    console.log('Data -', result);
    this.currentSlide = result.Data;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getItemSlide();
      this.slide.style.gap = `0 ${this.gapSlide}px`;
      this.itemLength = this.item.length;
      this.slideWidth = this.item[this.index].clientWidth;
      if (this.itemLength % 2 == 0) {
        this.item[this.itemLength / 2].classList.add('active');
        this.slide.style.transform = `translateX(${
          (-this.slideWidth - this.gapSlide) / 2
        }px)`;
        if (this.itemLength == 2) {
          this.activeArrowRight = true;
          this.nextArrowStatus('hidden');
        }
      } else {
        if (this.itemLength == 1) {
          this.nextArrowStatus('hidden');
          this.prevArrowStatus('hidden');
          this.item[0].classList.add('active');
        }
        this.item[Number((this.itemLength / 2).toFixed(0)) - 1].classList.add(
          'active'
        );
      }
    }, 1000);
  }

  nextArrowStatus(status: string) {
    if (status == 'active') {
      this.nextArrow.style.pointerEvents = 'unset';
      this.nextArrow.style.visibility = 'visible';
    } else {
      this.nextArrow.style.pointerEvents = 'none';
      this.nextArrow.style.visibility = 'hidden';
    }
  }

  prevArrowStatus(status: string) {
    if (status == 'active') {
      this.prevArrow.style.pointerEvents = 'unset';
      this.prevArrow.style.visibility = 'visible';
    } else {
      this.prevArrow.style.pointerEvents = 'none';
      this.prevArrow.style.visibility = 'hidden';
    }
  }

  getItemSlide() {
    this.slide = document.querySelector('.slide');
    this.item = document.querySelectorAll('.item');
    this.nextArrow = document.querySelector('.next-arrow');
    this.prevArrow = document.querySelector('.prev-arrow');
  }

  public items: object[] = [
    {
      title: 'Giá nước sinh hoạt',
      description:
        'Các tiêu chuẩn nước sạch dùng trong sinh hoạt và sản xuất hàng ngày.',
    },
    {
      title: 'Những câu hỏi thường gặp',
      description:
        'Bộ câu hỏi thường gặp dành cho khách hàng tra cứu thông tin.',
    },
    {
      title: 'Giá nước sinh hoạt',
      description:
        'Thông báo về việc điều chỉnh giá Dịch vụ thoát nước và Xử lý nước thải năm 2023.',
    },
    {
      title: 'Hóa đơn điện tử',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Các quy trình thủ tục hành chính',
      description:
        'Quy trình thủ tục về: Gắn mới đồng hồ nước, Thay đồng hồ nước, Kiểm định đồng hồ nước và Cấp định mức nước sử dụng.',
    },
    {
      title: 'Câu hỏi 6',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Câu hỏi 7',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Câu hỏi 6',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Câu hỏi 7',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Giá nước sinh hoạt',
      description:
        'Các tiêu chuẩn nước sạch dùng trong sinh hoạt và sản xuất hàng ngày.',
    },
    {
      title: 'Những câu hỏi thường gặp',
      description:
        'Bộ câu hỏi thường gặp dành cho khách hàng tra cứu thông tin.',
    },
    {
      title: 'Giá nước sinh hoạt',
      description:
        'Thông báo về việc điều chỉnh giá Dịch vụ thoát nước và Xử lý nước thải năm 2023.',
    },
    {
      title: 'Hóa đơn điện tử',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Các quy trình thủ tục hành chính',
      description:
        'Quy trình thủ tục về: Gắn mới đồng hồ nước, Thay đồng hồ nước, Kiểm định đồng hồ nước và Cấp định mức nước sử dụng.',
    },
    {
      title: 'Câu hỏi 6',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Câu hỏi 7',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Câu hỏi 6',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
    {
      title: 'Câu hỏi 7',
      description:
        'Cung cấp dịch vụ tra cứu thông tin sử dụng nước của khách hàng và doanh nghiệp, hóa đơn điện tử.',
    },
  ];
}
