import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { AppConsts } from '../services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { Meta, Title } from '@angular/platform-browser';
import { PageChangeEvent } from '@progress/kendo-angular-pager';

@Component({
  selector: 'app-search-news',
  templateUrl: './search-news.component.html',
  // styleUrls: ['./search-news.component.scss']
})
export class SearchNewsComponent implements OnInit {
  news: {Title: string, ImageUrl: string, UrlPath: string}[]
  postDetail: string;
  queryParamsSubscription: Subscription;
  searchText: string;
  isLoaded:number = 0;
  extension: string[] = ['.mp4', '.ogg', 'webm'];
  pageSize = 10;
  skip = 0;
  total = 0;
  newListPage = [];
  menuFound = true;
  UrlPath: string = '';
  constructor(private appService: AppService,
    private language: AppLanguage,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,){
      this.language.default();
      this.postDetail = "/" + AppConsts.page.postDetail;
    }

  ngOnInit(): void {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(data => {
      this.searchText = data['search'] ? data['search']  : '';
      this.UrlPath = data['title'] ? data['title'] : '';
      this.updateMeta();
      this.getTopNews();
      
    });
    this.title.setTitle(this.translate.instant('Title-Page'));
  }
  async getTopNews(){
    const result = await this.appService.doGET('api/PortalPost/GetSearchNews',  {'langID': this.language.get(), 'searchText': this.searchText});
    if(result&& result.Status === 1){
      this.menuFound = true;
      this.news = result.Data;
      this.skip = 0;
      this.total = result.Data.length;
      this.pageData();
      if(result.Data.length>0){
        //data not null
        this.isLoaded = 1;
      }
      else{
        //data is null
        this.menuFound = false;
        this.isLoaded = 2;
      }
    }
  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  getFullImageUrl(url: string){
    return this.appService.apiRoot + url;
  }
  updateMeta(): void{

    if (this.searchText != null) {
    var description = this.searchText;
    var keywords = this.searchText;
    var url = window.location.origin + window.location.pathname+ "?search="+this.searchText;
    var title = this.searchText;
    var ogDescription = this.searchText;
    var image = "";
    this.meta.updateTag({name: 'description', content:description});
    this.meta.updateTag({name: 'keywords', content:keywords});
    this.meta.updateTag({name: 'og:url', content:url});
    this.meta.updateTag({name: 'og:title', content:title});
    this.meta.updateTag({name: 'og:image', content:image});
    this.meta.updateTag({name: 'og:description', content: ogDescription});
    }
  }
  isVideo(name: string) {
    let result = false;
    if (name) {
      this.extension.forEach((e) => {
        if (name.includes(e)) {
          result = true;
      //    this.setupVideo();
        }
      });
    }
    return result;
  }
  onImgError(event){
    event.target.src = AppConsts.DEFAULT_IMAGE;
  }
  onDotsString(string, maxValue) {
    var txtLength = 0;
    var orgString = string;
    if( orgString ){
      //dem ki tu hoa thuong
      var ascii = 0;
      var lastIdx = 0;
      for (let i = 0; i < orgString.length; i++) {
        ascii = orgString[i].charCodeAt(0);
        if (ascii >= 65 && ascii <= 90) {
          txtLength += 1.21875;
        } else {
          txtLength += 1;
        }
        lastIdx = i;
        if (txtLength >= maxValue) {
          break;
        }
      }
      if (txtLength <= maxValue && lastIdx == (orgString.length - 1)) {
        return string;
      }
      var idxSpace = orgString.lastIndexOf(" ", lastIdx);
      var newString = orgString.slice(0, idxSpace) + " ...";
      return newString;
  }
  }

  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }
  private pageData(): void {
    if (this.news){
      this.newListPage = this.news.slice(
        this.skip,
        this.skip + this.pageSize
      );
    }
  }
}
