import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppControls } from 'src/app/services/app.controls';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { AppSwal } from '../../services/app.swal';
import { AppGuid } from '../../services/app.guid';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConsts } from 'src/app/services/app.consts';
import { AppComponent } from '../../app.component';
import { Subscription } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router';
import { getLineAndCharacterOfPosition } from 'typescript';
import { Meta, Title } from '@angular/platform-browser';
import { ViewEncapsulation } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-pager';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  // styleUrls: ['./post-list.component.scss']
  encapsulation: ViewEncapsulation.None,
})
export class PostListComponent implements OnInit {
  pageName = '';
  UrlPath: string = '';
  postList: any[];
  outerWidth: any;
  menuName = '';
  menuFound = true;
  control: any;
  extension: string[] = ['.mp4', '.ogg', 'webm'];
  user: any;
  menuID: any;
  templates: any;
  categoriesList: Array<any> = [];
  controlDefault = true;
  flgVi = true;
  postDetail: string;
  private queryParamsSubscription: Subscription;
  urlDownload = this.appService.apiRoot;
  pageSize = 10;
  skip = 0;
  total = 0;
  postListPage = [];
  pageBody = [];
  isAdminFlg = false;
  isMore = false;
  pageHeader: any;
  pageFooter: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    private guid: AppGuid,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appComponent: AppComponent,
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {
    this.language.default();
    this.postDetail = '/' + AppConsts.page.postDetail;
  }

  ngOnInit(): void {
    this.outerWidth = window.outerWidth;
    this.flgVi = this.language.get() == 'vi-VN' ? true : false;
    this.getHeader();
    this.getFooter();
    this.queryParamsSubscription = this.activatedRoute.params.subscribe(
      async (data) => {
        this.menuID = data['title'] ?? '';
        await this.onGetCategories();
        await this.getTemplate();
      }
    );
    this.outerWidth = window.outerWidth;
  }

  async getTemplate() {
    const result = await this.appService.doGET('api/PortalDesign/GetDesign', {
      menuID: this.menuID
    });
    if (result && result.Status === 1) {
      this.templates = result.Data;
      var bodys = [];
      for (let i = 0; i < this.templates.length; i++) {
        var item = this.templates[i];
        var groupId = item.GroupID;
        var layout = item.Layout;
        let index = bodys.findIndex(
          (x) => x.id == groupId && x.layout == layout
        );
        if (index != -1) {
          bodys[index].items.push(item);
        } else {
          var itemBody = {
            id: groupId,
            layout: layout,
            items: [item],
          };
          bodys.push(itemBody);
          if (
            layout < 100 &&
            this.templates.findIndex((x) => x.Layout == 100 - layout) == -1
          ) {
            // không tồn tại layout nào chung hàng thì tự add thêm
            bodys.push({
              id: groupId + 100,
              layout: 100 - layout,
              items: [],
            });
          }
        }
      }
      this.pageBody = bodys;
    }
  }

  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/Search', {
      searchText: '',
      langID: this.language.get()
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data;
    }
  }

  onReload(e) {
    this.getTemplate();
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  onDotsString(string, maxValue) {
    if (string == null || string == undefined) {
      return '';
    }
    var width = this.outerWidth;
    //var width = this.outerWidth;
    var txtLength = 0;

    var orgString = string;
    if (orgString) {
      //dem ki tu hoa thuong
      var nA = 0;
      var na = 0;
      var ascii = 0;
      var lastIdx = 0;

      for (let i = 0; i < orgString.length; i++) {
        ascii = orgString[i].charCodeAt(0);
        if (ascii >= 65 && ascii <= 90) {
          txtLength += 1.21875;
        } else {
          txtLength += 1;
        }

        lastIdx = i;

        if (txtLength >= maxValue) {
          break;
        }
      }

      if (txtLength <= maxValue && lastIdx == orgString.length - 1) {
        return string;
      }

      var idxSpace = orgString.lastIndexOf(' ', lastIdx);
      var newString = orgString.slice(0, idxSpace) + ' ...';

      return newString;
    }
  }

  // async getPostList(url) {
  //   const dataRequest = {
  //     urlPath: url,
  //     langID: localStorage.getItem('culture'),
  //   };

  //   const result = await this.appService.doGET(
  //     'api/PortalPost/GetPostList',
  //     dataRequest
  //   );
  //   if (result && result.Data && result.Status === 1) {
  //     if (this.UrlPath != result.Data.Menu.UrlPath) {
  //       this.router.navigate([
  //         AppConsts.page.postList,
  //         result.Data.Menu.UrlPath,
  //       ]);

  //     } else {
  //       this.menuFound = true;
  //       this.menuName = result.Data.Menu.Name;
  //       this.postList = result.Data.PostList;
  //       this.total = result.Data.PostList.length;
  //       this.pageData();
  //       this.updateMeta();
  //       this.title.setTitle(result.Data.Menu.Name);
  //     }
  //   } else if (
  //     result &&
  //     result.Data &&
  //     result.Status === 0 &&
  //     result.Data == 'Error-Lang-Menu-404'
  //   ) {
  //     this.menuFound = false;
  //     this.title.setTitle('');
  //   }
  // }

  isVideo(name: string) {
    let result = false;
    if (name) {
      this.extension.forEach((e) => {
        if (name.includes(e)) {
          result = true;
          //    this.setupVideo();
        }
      });
    }
    return result;
  }

  getFullImageUrl(url: string) {
    // this.setupVideo();
    return this.appService.apiRoot + url;
  }

  getFileName(IconUrl) {
    var nameFile = '';
    if (IconUrl != '' && IconUrl != null) {
      var urlArr = IconUrl.split('/');
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != '' && nameFile != null) {
          var indexOfFirst = nameFile.indexOf('_');
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }
  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, '') + item;
    url = url.replace(/\"/g, '');
    return url;
  }

  updateMeta(): void {
    if (this.menuName != null) {
      var description = this.menuName;
      var keywords = this.menuName;
      var url =
        window.location.origin + window.location.pathname + '/' + this.UrlPath;
      var title = this.menuName;
      var ogDescription = this.menuName;
      var image = '';

      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ name: 'keywords', content: keywords });
      this.meta.updateTag({ name: 'og:url', content: url });
      this.meta.updateTag({ name: 'og:title', content: title });
      this.meta.updateTag({ name: 'og:image', content: image });
      this.meta.updateTag({ name: 'og:description', content: ogDescription });
    }
  }
  onImgError(event) {
    event.target.src = AppConsts.DEFAULT_IMAGE;
  }
  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }
  private pageData(): void {
    if (this.postList) {
      this.postListPage = this.postList.slice(
        this.skip,
        this.skip + this.pageSize
      );
    }
  }
  async getHeader() {
    const result = await this.appService.doGET('api/PortalHeader', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageHeader = [result.Data.HeaderType];
    }
  }
  async getFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageFooter = [result.Data.FooterType];
    }
  }
}
