import { Component, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  // styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  @ViewChild('passwordInput') passwordInput: any;
  public type = 'password';
  remember = false;
  loading = false;
  isEdge = false;
  loginInfo = {
    UserName: '',
    Password: '',
    Type: 0,
  };
  invalidField = {
    UserName: false,
    Password: false,
  };
  public focus: number;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

  OTPCode = '';
  OTPFlag = false;

  MsgError = '';
  redirectUrl = '';
  titlePostDetail = '';
  public changPassURL = '';
  public isShowCleanText = false;

  public loginFailed = {
    isHide: true,
    message: null,
  };
  constructor(
    private meta: Meta,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    private language: AppLanguage,
    private title: Title,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.onInit();
  }
  ngOnInit(): void {
    this.title.setTitle('Login Page');
    this.meta.updateTag({
      name: 'description',
      content: 'This is a Login Page',
    });
    this.checkUserNameLength();
    if (localStorage.getItem('userInfoPortal')) {
      this.authenticationService.doSignout();
    }
    this.getLoginInfo();
    this.isEdge = /msie\s|trident\/|edg\//i.test(window.navigator.userAgent);
  }

  async onInit() {
    this.activatedRoute.queryParams.subscribe(async (params: any) => {
      if (params && params.redirectUrl) {
        this.redirectUrl = params.redirectUrl;
        if (params.title) {
          this.titlePostDetail = params.title;
        }
        if (localStorage.getItem('userInfoPortal')) {
          this.authenticationService.doSignout();
        }
        this.changPassURL =
          this.appService.portalRoot +
          'forgot-password' +
          '?redirectUrl=' +
          this.redirectUrl;
      } else {
        this.changPassURL = this.appService.portalRoot + 'forgot-password';
      }
      if (params && params.userName && params.password) {
        this.loginInfo.UserName = params.userName;
        this.loginInfo.Password = params.password;
        this.loginInfo.Type = params.type;
        await this.onLogin();
      }
    });
  }

  getLoginInfo() {
    let cookies = this.parseCookie(document.cookie);
    this.loginInfo.UserName = cookies.my_username;
    this.loginInfo.Password = cookies.my_password;
    if (cookies.my_username) {
      this.remember = true;
    }
  }

  parseCookie(str: string) {
    return str
      .split(';')
      .map((v: any) => v.split('='))
      .reduce((acc: any, v) => {
        acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(
          v[1]?.trim()
        );
        return acc;
      }, {});
  }
  async onLogin() {
    const isValid = this.checkValidate();
    if (!isValid || this.loading) {
      return;
    }
    this.MsgError = '';
    this.loading = true;
    const result = await this.authenticationService.doSignIn(
      this.loginInfo.UserName,
      this.loginInfo.Password,
      this.loginInfo.Type
    );

    if (result.Status === 1) {
      if (result.Data && this.redirectUrl) {
        if (this.titlePostDetail) {
          this.redirectUrl =
            this.redirectUrl +
            '?title=' +
            this.titlePostDetail +
            '&userName=' +
            result.Data.UserName +
            '&token=' +
            result.Data.access_token +
            '&webFlg=1';
        } else {
          this.redirectUrl =
            this.redirectUrl +
            '?userName=' +
            result.Data.UserName +
            '&token=' +
            result.Data.access_token +
            '&webFlg=1';
        }
      }
      if (this.remember) {
        document.cookie = 'my_username=' + this.loginInfo.UserName + '; secure';
        document.cookie = 'my_password=' + this.loginInfo.Password + '; secure';
      } else {
        document.cookie =
          'my_username=" "; expires=' + new Date().toUTCString() + '; secure';
        document.cookie =
          'my_password=" "; expires=' + new Date().toUTCString() + '; secure';
      }
      window.location.href = this.redirectUrl;
    } else {
      this.loginFailed = {
        isHide: false,
        message: result.Msg,
      };
      setTimeout(() => {
        this.loginFailed.isHide = true;
      }, 2000);
    }
    this.loading = false;
  }
  checkValidate(): boolean {
    let isValid = true;
    if (!this.loginInfo.UserName || this.loginInfo.UserName == '') {
      this.invalidField.UserName = true;
      isValid = false;
    }
    if (!this.loginInfo.Password || this.loginInfo.Password == '') {
      this.invalidField.Password = true;
      isValid = false;
    }
    return isValid;
  }

  onKeyPress(e: any) {
    if (e.target.id === 'UserName') {
      this.invalidField.UserName = false;
    } else if (e.target.id === 'Password') {
      this.invalidField.Password = false;
    }
    if (e.keyCode === 13) {
      this.onLogin();
    }
  }
  onKeyUpUserName(): void {
    this.checkUserNameLength();
  }

  checkUserNameLength(): void {
    if (this.loginInfo.UserName.length === 0) {
      this.isShowCleanText = false;
    } else {
      this.isShowCleanText = true;
    }
  }

  onShowPass() {
    if (this.type == 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  cleanUserNameTxt() {
    this.loginInfo.UserName = '';
    this.isShowCleanText = false;
  }
  onFocus(type) {
    this.passwordInput.nativeElement.removeAttribute('readonly');
    this.focus = type;
  }
}
