<article class="org-tree">
  <div class="container-fluid main-item">
    <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
      <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
      <div class="menu" *ngIf="isShowMenu">
        <div class="menu-row-current-border" (click)="editComponent()">
          <div class="menu-item-1">{{'Edit' | translate}}</div>
        </div>
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
    <!-- <div style="width: 200px;">
      <div style="
        background: linear-gradient(#fff 0 0) padding-box, linear-gradient(to top, #006CB5, #00AEEF) border-box;
          color: #313149;
          border: 5px solid transparent;
          border-radius: 50%;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 106px;
        height: 106px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;">
        <img src="http://localhost:8080//Upload/Document/83749a83-e553-4ded-80cd-e86e92b24c27/20230316154509751_water-save.png" alt="" 
          style="height: 94px; width: 94px; border-radius: 50%; padding: 2px;
          " (error)="onImgError($event)">
      </div>
      <div>
        <div style="display: flex; justify-content: center; 
          margin-top: 20px; margin-bottom: 20px; font-family: Merriweather;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;">
            Name
        </div>
        <div style="font-family: Merriweather;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #FFFFFF;
          background: #0099DC;
          border-radius: 20px;
          height: 40px;
          width: 140px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;">
            Kiểm soát viên
        </div>
      </div>
    </div> -->

    <!-- <div style="display: flex;
    background: #FFFFFF;
    border: 1px solid #0099DC;
    width: 326px;">
      <div>
        <img src="http://localhost:8080//Upload/Document/cc6ada03-f2d0-48d4-9212-8db146a33e58/20230314090041754_Rectangle 926.png" alt=""
        style="width: 93px;
        height: 93px;
        margin: 5px;">
      </div>
      <div style="display: flex;
      justify-content: center;
      align-items: center;">Công ty TNHH MTV nước ngầm Sài Gòn</div>
    </div> -->
    <div class="row">
      <div class="unit-group" *ngFor="let unit of dataUnit">
        <div *ngIf="unit.Type != null && unit.Type != 4">
          <div class="unit-title" *ngIf="unit.Type == 0">
            <div>{{ "Org_Chart_Title" | translate}}</div>
            <div>{{ "Org_Chart_Title_2" | translate}}</div>
          </div>
          <div class="unit-title" *ngIf="unit.Type != 0">
            <div *ngIf="unit.ID == 'BTK'">{{ dvttNum }} {{ unit.Name}}</div>
            <div *ngIf="unit.ID != 'BTK'">{{ unit.Name}}</div>
          </div>
          <!-- add to chart -->
          <div *ngIf="unit.ID != 'BTK'" [id]="'chart'+ unit.ID" (click)="clickToGetName(unit.Type, $event)"></div>
          <div *ngIf="unit.ID == 'BTK'">
            <div *ngIf="innerWidth > 768" [id]="'chart'+ unit.ID" (click)="clickToGetName(unit.Type, $event)"></div>
            <div *ngIf="innerWidth <= 768">
              <div class="row margin-box">
                <div class="col-6 straight-line"></div>
                <div class="col-12">
                  <div class="row items-box-border">
                    <div class="col-3 center-box" *ngFor="let item of dataChartBTK">
                      <div class="item-blue-margin" [innerHTML]="item.Name" (click)="openDescriptionByID(item.ID)">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         
        <div *ngIf="unit.Type == 4">
          <div class="unit-title">
            <div *ngIf="unit.ID == '01'" class="margin-box-bottom">{{ pbnvNum }} {{ unit.Name}}</div>
            <div *ngIf="unit.ID != '01'">{{ unit.Name}}</div>
          </div>
          <div class="row margin-box">
            <div class="col-6 straight-line"></div>
            <div class="col-12">
              <div class="row items-box-border">
                <div class="col-3 center-box" *ngFor="let item of dataChartType4">
                  <div class="item-blue-margin" [innerHTML]="item.Name" (click)="openDescriptionByID(item.ID)">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>       
      </div>

        <!-- <div>SƠ ĐỒ TỔ CHỨC BỘ MÁY
          TỔNG CÔNG TY CẤP NƯỚC SÀI GÒN - TNHH MỘT THÀNH VIÊN</div>
        <div [id]="nameID"></div>

        <div>Ban Kiểm soát </div>
        <div [id]="nameID2"></div>

        <div>Hội đồng thành viên</div>
        <div [id]="nameID3"></div>

        <div>Ban Tổng giám đốc</div>
        <div [id]="nameID4"></div>

        <div>Ban Chuyên môn (thuộc HĐTV)</div>
        <div [id]="nameID5"></div>

        <div>15 Phòng, Ban nghiệp vụ</div>
        <div class="row">
          <div class="col-6 straight-line"></div>
          <div class="col-12">
            <div class="row items-box-border">
              <div class="col-3 center-box" *ngFor="let item of fifthteenDataTemp">
                <div class="item-blue">
                  {{ item.Name}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>Ban chức năng mục tiêu</div>
        <div [id]="nameID7"></div>

        <div>6 Đơn vị trực thuộc</div>
        <div [id]="nameID8"></div> -->

        <!-- <div>Các đơn vị thành viên</div>
        <div [id]="nameID9"></div>
      -->
        <div class="unit-title margin-title">
          CÁC ĐƠN VỊ THÀNH VIÊN
        </div>
        <div *ngFor="let unitOrg of unitByEUnitType; let i = index">
          <div class="center-box">
            <div class="item-header-unit-member" [ngClass]="unitOrg.UnitType == 0 ? 'no-margin item-blue' : 'item-blue' " [innerHTML]="unitOrg.Title">
              {{ unitOrg.Title }}
            </div>
          </div>
          <div class="col-6 straight-line" *ngIf="unitOrg.UnitType == 0"></div>
          <div class="row items-box no-padding" *ngIf="unitOrg.UnitType == 0">
            <div class="col-sm-12 col-md-2 center-box margin-items" *ngFor="let item of unitOrg.Data">
              <div class="box-blue-border no-margin" (click)="openDesByIDOfCommon(item.ID)">
                <div>
                  <img [src]="appService.apiRoot + item.ImageUrl" alt=""
                  class="img-small">
                </div>
                <div class="center-text text-0" [innerHTML]="item.Name"></div>
              </div>
            </div>
          </div>
          <div class="row items-box" *ngIf="unitOrg.UnitType == 1">
            <div class="col-sm-12 col-md-2 center-box margin-items" *ngFor="let item of unitOrg.Data">
              <div class="box-blue-border" (click)="openDesByIDOfCommon(item.ID)">
                <div>
                  <img [src]="appService.apiRoot + item.ImageUrl" alt=""
                  class="img-small">
                </div>
                <div class="center-text text-1" [innerHTML]="item.Name"></div>
              </div>
            </div>
          </div>
          <div class="row items-box" *ngIf="unitOrg.UnitType == 2">
            <div class="col-sm-12 col-md-2 center-box margin-items" *ngFor="let item of unitOrg.Data">
              <div class="box-blue-border-no-img" (click)="openDesByIDOfCommon(item.ID)">
                <div class="center-text text-2" [innerHTML]="item.Name"></div>
              </div>
            </div>
          </div>
          <div class="break-line" *ngIf="i != (unitByEUnitType.length - 1)"></div>
        </div>
       
        


    </div>
  </div>
  <kendo-dialog
    *ngIf="manageDialog"
    (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width"
  >
    <div class="org-tree-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
        }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
        }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close-button.png"
          alt=""
          (click)="onClose($event)"
        />
      </div>
    </div>
    <app-org-tree-dialog
      *ngIf="editFlg == true"
      [request_AccountID]="request_AccountID"
      [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)"
      [galleryData]="galleryData"
      [editFlg]="editFlg"
      [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID"
      (onSave)="this.onSaveData()"
      (onChangetemp1)="this.changeTemp1($event)"
    >
    </app-org-tree-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
  <!-- info dialog -->
  
  <kendo-dialog
    *ngIf="descriptionDialog"
    (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width"
  >
    <div class="opend-dialog-video close-div2 dialog2">
      <div class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close.png"
          alt=""
          (click)="onCloseDescriptionDialog()"
        />
      </div>
    </div>
    <div class="description-dialog close-div">
      <div class="title-info-req">
        <strong>{{ orgName }}</strong>
      </div>
    </div>
    <div [innerHTML]="orgDescription" class="description-text"></div>
    <div *ngFor="let file of orgFileUrls; let i = index">
      <div class="row ">
          <!-- Add new & Edit -->
          <div class="col-md-12 col-12   ">
              <div class="cbo-ddr-btn-add row-file">
                  <a [href]="getUrlDownload(file)" [target]="_blank">
                      <span>{{ getFileName(file) }}</span>
                  </a>
                  
              </div>
          </div>
      </div>
  </div>
  </kendo-dialog>
</article>
