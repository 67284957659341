<article class="news-template-49" (window:resize)="onResize($event)">
    <div class="container-fluid">
      <div class="row main-item">
        <div class="main-item">
          <div class="row">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">
              <div class="row">
                <div class="col-sm-12 col-12 col-md-6 news-title">
                  <p class="uppercase">{{categoryParent?.Name}}</p>
                </div>
                <div class="col-sm-12 col-12 col-md-12 menu-col">
                    <div class="menu-bar">
                      <div class="nav-scroll" *ngFor="let item of categoriesListFiltered; let i = index"
                        [ngClass]="item.Selected == true ? 'nav-scroll-selected' : ' '">
                        <p [ngClass]="
                            item.Selected == true ? 'nav-item-selected' : 'nav-item'
                          " (click)="onSelectedCategory(item,i)">
                          {{ item.Name }}
                        </p>
                      </div>
                    </div>
                </div>
              </div>
              
              
                
            </div>
            <div class="col-sm-12 col-12 col-md-12 col-xl-12">
              <div class="row" *ngIf="innerWidth>768">
                <div class="col-sm-12 col-12 col-md-12 col-xl-12 top-new-item">
                  <div class="post-list" *ngIf="selectedTab === 1 && postList.length > 0">
                    <div class="row">
                    <a
                      [className]="
                        'col-sm-12' +
                        ' col-6' +
                        ' col-md-3' +
                        ' pointer top-new-item'
                      "
                      *ngFor="let item of postList; let i = index"
                      [href]="this.postDetailRoute  + item.UrlPath" routerLinkActive="router-link-active"
                    >
                      <section class="row top-part">
                        <div class="col-12">
                          <figure class="img-figure" [ngClass]="setH()">
                            <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-right-image" [src]="appService.apiRoot + item.ImageUrl" alt="post-image"/>
                            <img *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined || item.ImageUrl == '' " class="post-image-default" 
                              src="../../assets/images/default.png" alt="post-image"/>
                          </figure>
                        </div>
                        <div class="col-12 right-item-detail">
                          <p class="title-text" *ngIf="item.Title && item.Title.length != 0 && item.Title.trim() != '' ">
                            {{
                              item.Title.length > 100
                                ? item.Title.slice(0, 100) + "..."
                                : item.Title
                            }}
                          </p>
                          <div class="time-clock">
                            <img class="clock" src="../../assets/images/clock-circle.svg" alt="">        
                            <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd/MM/yyyy' }}</p>
                            <p class="time-text" *ngIf="!item.CreateAt"></p>
                          </div>
                          <!-- <p class="summary" *ngIf="item.Summary && item.Summary.length != 0 && item.Summary.trim() != ''">
                            {{
                              item.Summary.length > 200
                                ? item.Summary.slice(0, 200) + "..."
                                : item.Summary
                            }}
                          </p> -->
                         
                        </div>
                      </section>
                    </a>
                  </div>
                  </div>
                  <div class="video-list" *ngIf="selectedTab === 2 && videoList.length > 0">
                    <div class="content">
                      <div class="row d-grid">
                          <div class="col-12"
                          *ngFor="let item of videoList; let j = index" (click)="openDialogVideo(item.FileUrls)">
                          <div class="pointer width-full">
                            <div class="overlay"></div>
                            <section  [innerHTML]="getVideo(item.FileUrls) | sanitizedHtml">
                            </section>
                          </div>
                          <div
                            (click)="openDialogVideo(item.FileUrls)"
                            class="pointer video-title"
                          >
                            {{ item.Name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="radio-list" *ngIf="selectedTab === 3 && radioList.length > 0">
                    <div class="content">
                      <div class="row">
                        <div class="radio-container wb" *ngFor="let item of radioList">
                          <div class="radio-order">{{ getIndex(item.ID) }}</div>
                          <div class="media">
                            <img *ngIf="item.FileUrls" class="post-image-cover post-image"
                              src="../../assets/images/logo-ytcc.png" alt="post-image" />
                          </div>
                          <div class="radio-info">
                            <div class="album-name">{{ item.Name }}</div>
                            <audio controls>
                              <source [src]="getAudioUrl(item.FileUrls)" type="audio/mpeg">
                            </audio>
                          </div>
                          <div class="author-name">{{ item.CreateBy}}</div>
                          <img class="view-count-icon" src="../../assets/images/ic_baseline-remove-red-eye.svg" alt="">
                          <span class="view-count">{{item.CntNum ? item.CntNum : 0 | number:'1.0-0'}}</span>
                          <button class="download-radio" (click)="downloadFile(item.FileUrls, item.ID)">
                            <img class="download-radio-icon" src="../../assets/images/ic_sharp-download.svg" alt="">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="collumn" *ngIf="innerWidth<=768">
                <div class="col-sm-12 col-12 col-md-4 col-xl-4 top-new-item">
                  <div class="post-list" *ngIf="selectedTab == 1 && postList.length > 0">
                    <a
                      [className]="
                        'col-sm-12' +
                        ' col-12' +
                        ' col-md-12' +
                        ' pointer top-new-item'
                      "
                      *ngFor="let item of postList; let i = index"
                      [href]="this.postDetailRoute  + item.UrlPath" routerLinkActive="router-link-active"
                    >
                      <section class="top-part">
                        <div class="col-12">
                          <figure class="img-figure" [ngClass]="setH()">
                            <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-right-image" [src]="appService.apiRoot + item.ImageUrl" alt="post-image"/>
                            <img *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined || item.ImageUrl == ''" class="post-image-default" 
                              src="../../assets/images/default.png" alt="post-image"/>
                          </figure>
                        </div>
                        <div class="col-12 right-item-detail">
                          <p class="title-text" *ngIf="item.Title">
                            {{
                              item.Title.length > 100
                                ? item.Title.slice(0, 100) + "..."
                                : item.Title
                            }}
                          </p>
                          <div class="time-clock">
                            <img class="clock" src="../../assets/images/clock-circle.svg" alt="">          
                            <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd/MM/yyyy' }}</p>
                            <p class="time-text" *ngIf="!item.CreateAt"></p>
                          </div>
                          <!-- <p class="summary" *ngIf="item.Summary">
                            {{
                              item.Summary.length > 200
                                ? item.Summary.slice(0, 200) + "..."
                                : item.Summary
                            }}
                          </p> -->
                         
                        </div>
                      </section>
                    </a>
                  </div>
                  <div class="video-list" *ngIf="selectedTab == 2 && videoList.length > 0">
                    <div class="content">
                      <div class="row d-grid">
                          <div class="col-12"
                          *ngFor="let item of videoList; let j = index" (click)="openDialogVideo(item.FileUrls)">
                          <div class="pointer width-full">
                            <div class="overlay"></div>
                            <section  [innerHTML]="getVideo(item.FileUrls) | sanitizedHtml">
                            </section>
                          </div>
                          <div
                            (click)="openDialogVideo(item.FileUrls)"
                            class="pointer video-title"
                          >
                            {{ item.Name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="radio-list" *ngIf="selectedTab === 3 && radioList.length > 0">
                    <div class="content">
                      <div class="row">
                        <div class="mb">
                          <div class="txt-break item-menu radio-container" *ngFor="let item of radioList; let j = index">
                            <div class="row">
                              <div class="col-md-12 col-12">
                                <strong> {{ "Radio_title" | translate }}: </strong>
                
                                <span class="value">{{ item.Name }}</span>
                              </div>
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Create_by" | translate }}: </strong>
                              <span class="value">{{ item.CreateBy }}</span>
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Download_Num" | translate }}: </strong>
                              <span class="value"> {{item.CntNum ? item.CntNum : 0 | number:'1.0-0'}}</span>
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Download" | translate }}: </strong>
                              <span class="value"><img src="../../assets/images/Download.png" class="img" (click)="downloadFile(item.FileUrls, item.ID)" /></span>
                            </div>
                            <div class="col-md-12 col-12">
                              <strong> {{ "Play" | translate }}: </strong>
                              <span class="value">                            
                                <audio controls>
                                  <source [src]="getAudioUrl(item.FileUrls)" type="audio/mpeg">
                                </audio>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                  <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
              </div> -->
      </div>
    </div>
    <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
      class="router-outlet-content window-center dialog-info">
      <div class="news-template-49-dialog close-div">
        <div class="title-info-req">
          <strong *ngIf="editFlg == true && dltFlg == false">{{
            "Gallery_Scrollview_Edit" | translate
            }}</strong>
          <strong *ngIf="editFlg == false && dltFlg == false">{{
            "Gallery_Scrollview_Add" | translate
            }}</strong>
          <strong *ngIf="dltFlg == true">{{
            "Gallery_Scrollview_Delete" | translate
          }}</strong>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
        </div>
      </div>
      <app-news-template-49-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
        (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
        [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)" [selectedMenuID]="selectedCategoryId">
      </app-news-template-49-dialog>
      <div *ngIf="dltFlg == true">
        <div class="input-label">
          <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
        </div>
        <div class="button-group">
          <button
            class="btn-delete"
            (click)="onDeleteGallery()"
          >
            <span>{{ "Delete" | translate }}</span>
          </button>
          <button
            class="btn-cancel"
            (click)="onClose($event)"
          >
            <span>{{ "Cancel" | translate }}</span>
          </button>
        </div>
      </div>
    </kendo-dialog>

    <kendo-dialog
    *ngIf="isShowDialogVideo"
    (mousedown)="$event.stopPropagation()"
    class="dialog2 router-outlet-content window-center dialog-info dialog-width-video koverley2"
  >
    <div class="opend-dialog-video close-div2 dialog2">
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close.png"
          alt=""
          (click)="onCloseDialogVideo()"
        />
      </div>
    </div>
    <app-opend-dialog-video [srcURL]="srcURL"> </app-opend-dialog-video>
  </kendo-dialog>
  </article>
  