import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { Router } from '@angular/router';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { PageChangeEvent } from '@progress/kendo-angular-pager';

@Component({
  selector: 'app-news-template-46',
  templateUrl: './news-template-46.component.html',
  // styleUrls: ['./news-template-46.component.scss']
})
export class NewsTemplate46Component implements OnInit {

  topNews: any;
  topNewsMb: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  public pageSize = 12;
  public skip = 0;
  public pagedTopnews = [];
  currentNews = [];
  currentNewsMb = [];
  public total = 0;
  public totalMb = 0;
  @Input() templateType = 1;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  @Input() width: any;
  galleryData: any;
  @Input() itemTemplate: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() tittleItem = new EventEmitter();
  @Output() urlItem = new EventEmitter();
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate1 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() isPreview: boolean;
  @Input() newsDecorationIndex: any;
  @Input() isMore: boolean = true;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  isViewAllList = false;
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  previousItem = 0;
  previousItemMb = 0;
  sliderWidth= "100%";
  sliderHeight = "215px";
  firstSlideIndex = 0;
  lastSlideIndex = 5;
  numberOfItemInSlide = 5;
  firstSlideIndexMb = 0;
  lastSlideIndexMb = 3;
  numberOfItemInSlideMb = 3;
  sliderList = [];
  sliderListMb = [];
  innerWidth: any;
  innerHeight: any;
  constructor(
    public appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.topNumber = this.infoFromMain?.TopNumber;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    if (this.topNumber > 12) {
      this.isViewAllList = true
    }
    this.bootstrapNum = 12 / this.topNumber;
    if (this.topNumber > 3) {
      this.bootstrapNum = 4
    }
    this.getPostTittle();
    this.getTopNews();
    this.galleryData = {
      ID: this.ID,
      OrderIdx: this.OrderIdx,
      infoFromMain: this.infoFromMain,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };
    // if (this.router.url == '/' + AppConsts.page.templatePage) {
    //   document.getElementById('template-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    // else {
    //   document.getElementById('main-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
    }
    if (this.infoFromMain.MenuID == '') {
      this.pagedTopnews = this.topNews;
      this.currentNews = this.topNews
      this.currentNewsMb = this.topNews
    }




  }
  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }

  public onPageChangeMb(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageDataMb();
  }

  private pageDataMb(): void {
    this.pagedTopnews = this.topNewsMb.slice(
      this.skip,
      this.skip + this.pageSize
    );
  }

  private pageData(): void {
    this.pagedTopnews = this.topNews.slice(
      this.skip,
      this.skip + this.pageSize
    );
  }
  async getTopNews() {
    const result = await this.appService.doGET(
      'api/Common/GetListCommon',
      {
        searchText: '',
        type: 6
      }
    );
    if (result && result.Status === 1) {
      // this.postTittle = result.Data.dataMenu.Name;
      this.viewMore = result.Data;
      this.topNews = result.Data;
      this.topNewsMb = result.Data;
      this.initSlideList();
      this.initSlideListMb();
      
      this.currentNews = this.topNews.slice(0, 5);
      this.currentNewsMb = this.topNewsMb.slice(0, 3);
      this.pageData();
      this.pageDataMb();
      this.total = result.Data.topNews?.length;
      this.totalMb = result.Data.topNews?.length;
    }
  }

  setH(){
    var a = document.querySelector('.post-list') as HTMLElement;
    if(a.getBoundingClientRect().width <= 808){
      return 'img-figure-2'
    }
    return ;
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore() {
    // this.router.navigate([AppConsts.page.postList], { queryParams: { url: this.viewMore } });
    this.router.navigate([AppConsts.page.postList + '/' + this.viewMore]);
  }
  onGetTittleItem(e) {
    this.tittleItem.emit(e)

  }

  redirectToItem(url: string, item) {


    this.onGetTittleItem(item)
    if (url != null && url != undefined) {
      // this.router.navigate([AppConsts.page.galleryDialog], { queryParams: {} });

      // this.router.navigate([AppConsts.page.postDetail, url], { state: { postTittle: this.postTittle } });

      this.urlItem.emit(item);
    }
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  changeTemp1(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.getTopNews();
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate1.emit(newTemp);
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }
  onChange(e){
    if (e.index > this.previousItem) {
      let step = e.index - this.previousItem;
      this.firstSlideIndex = this.firstSlideIndex + (this.numberOfItemInSlide * step);
      this.lastSlideIndex = this.lastSlideIndex + (this.numberOfItemInSlide * step);
      this.currentNews = this.topNews.slice(this.firstSlideIndex, this.lastSlideIndex);
    } else {
      let step = this.previousItem - e.index;
      this.firstSlideIndex = this.firstSlideIndex - (this.numberOfItemInSlide * step);
      this.lastSlideIndex = this.lastSlideIndex - (this.numberOfItemInSlide * step);
      this.currentNews = this.topNews.slice(this.firstSlideIndex, this.lastSlideIndex);
    }
    this.previousItem = e.index;
  }

  onChangeMb(e){
    if (e.index > this.previousItemMb) {
      let step = e.index - this.previousItemMb;
      this.firstSlideIndexMb = this.firstSlideIndexMb + (this.numberOfItemInSlideMb * step);
      this.lastSlideIndexMb = this.lastSlideIndex + (this.numberOfItemInSlideMb * step);
      this.currentNewsMb = this.topNewsMb.slice(this.firstSlideIndexMb, this.lastSlideIndexMb);
    } else {
      let step = this.previousItemMb - e.index;
      this.firstSlideIndexMb = this.firstSlideIndex - (this.numberOfItemInSlideMb * step);
      this.lastSlideIndexMb = this.lastSlideIndexMb - (this.numberOfItemInSlideMb * step);
      this.currentNewsMb = this.topNewsMb.slice(this.firstSlideIndexMb, this.lastSlideIndexMb);
    }
    console.log("🚀 ~ file: news-template-46.component.ts:312 ~ NewsTemplate46Component ~ onChangeMb ~ this.lastSlideIndexMb:", this.lastSlideIndexMb)
    console.log("🚀 ~ file: news-template-46.component.ts:311 ~ NewsTemplate46Component ~ onChangeMb ~  this.firstSlideIndexMb:",  this.firstSlideIndexMb)
    console.log("🚀 ~ file: news-template-46.component.ts:313 ~ NewsTemplate46Component ~ onChangeMb ~ this.currentNewsMb:", this.currentNewsMb)
    this.previousItemMb = e.index;
  }
  initSlideList(){
    let topNewsLen = this.topNews.length;
    let maxSlideNum = (topNewsLen % 5 == 0) ? (topNewsLen /5) : (Math.floor(topNewsLen/5) +1);

    for (let index = 0; index < maxSlideNum; index++) {
      this.sliderList.push(index);
    }
  }

  initSlideListMb(){
    let topNewsLen = this.topNewsMb.length;
    let maxSlideNum = (topNewsLen % 3 == 0) ? (topNewsLen /3) : (Math.floor(topNewsLen/3) +1);

    for (let index = 0; index < maxSlideNum; index++) {
      this.sliderListMb.push(index);
    }
  }
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }

}
