import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input,  TemplateRef, Output, EventEmitter,
   } from '@angular/core';
import { AppControls } from 'src/app/services/app.controls'
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { AppSwal } from '../../services/app.swal';
import { AppGuid } from '../../services/app.guid';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConsts } from 'src/app/services/app.consts';
import { AppComponent } from '../../app.component';
import { Subscription } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router'
import { getLineAndCharacterOfPosition } from 'typescript';
import videojs from 'video.js';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-most-viewed-posts',
  templateUrl: './most-viewed-posts.component.html',
 // styleUrls: ['./most-viewed-posts.component.scss']
})
export class MostViewedPostsComponent implements OnInit, OnChanges, OnDestroy {

  //@ViewChild('modal') customTemplate: TemplateRef<any>;
  pageName = '';
  // UrlVideo: string = '';
  Posts: any = [];
  @Input("UrlPath") UrlPath: any ='';
  @Input("type") type: any ='';
  @Output() updatePostDetail: EventEmitter<any> = new EventEmitter();
  control: any;
  user: any;
  count = 0;
  controlDefault = true;
  extension: string[] = ['.mp4', '.ogg', 'webm'];
  urlDownload = this.appService.apiRoot;
  postDetail: string;
  flgVi = true;
  innerWidth: any;

  // player: videojs.Player;

  //private queryParamsSubscription: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    private guid: AppGuid,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appComponent: AppComponent,
    private router: Router,
    public dialog: MatDialog,
    // private domSanitizer: DomSanitizer,
            ) {
    // this.getPageName();
    // this.authenticationService.getUser();
    // this.user = this.authenticationService.user;
    this.language.default();
    this.postDetail = "/"+ AppConsts.page.postDetail;

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.flgVi = this.language.get() == "vi-VN" ? true: false;

  }
  ngOnChanges(changes: SimpleChanges) {
    console.log("changes", changes);
    if(changes.UrlPath.firstChange == false){
      this.updatePostDetail.emit()
    }
    
    if(this.type=="related_Post"){
      this.getRelatedPost();
    }
    // else{
    //   this.getPostMostView();
    // }
  }
  ngOnDestroy(): void {
    // if (this.player) {
    //   this.player.dispose();
    // }
    // if (this.queryParamsSubscription) {
    //   this.queryParamsSubscription.unsubscribe();
    // }
  }
  async getPostMostView() {
    const dataRequest = {
      urlPath: this.UrlPath,
      langID:  localStorage.getItem("culture")
      //  langID: this.selectedLangID
    };
   const result = await this.appService.doGET('api/PortalPost/GetPostsMostView', dataRequest);
    if (result && result.Status === 1) {
      this.Posts = result.Data;
     // console.log("this.Posts",this.Posts)
    }
  }

  async getRelatedPost() {
    const dataRequest = {
      postUrl: this.UrlPath,
      langID:  localStorage.getItem("culture")
      //  langID: this.selectedLangID
    };
   const result = await this.appService.doGET('api/PortalPost/GetRelatedPosts', dataRequest);
    if (result && result.Status === 1) {
      this.Posts = result.Data;
     // console.log("this.Posts",this.Posts)
    }
  }
  // async getPageName() {
  //   this.pageName = await this.appControls.getPageName();
  // }
  // async getControl() {
  //   this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.postDetail);
  //   this.controlDefault = false;
  // }



  getFileName(IconUrl) {
    var nameFile = "";
    if (IconUrl != "" && IconUrl != null) {
      var urlArr = IconUrl.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }
  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }

  isVideo(name: string) {
    let result = false;
    if (name) {
      this.extension.forEach((e) => {
        if (name.includes(e)) {
          result = true;
      //    this.setupVideo();
        }
      });
    }
    return result;
  }

  getFullImageUrl(url: string) {
   // this.setupVideo();
    return this.appService.apiRoot + url;
  }
  onImgError(event){
    event.target.src = AppConsts.DEFAULT_IMAGE;
    // event.target.style.objectFit  =  'scale-down';
  }
  getDetailUrl(url){
    let fullURL = '/post-detail/' + url;
    return fullURL;
  }
}
