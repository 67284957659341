import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnInit {
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
    private router: Router,
  ) {}

  tabSelected: number = 0;
  domain: string;
  isBrowser: any;
  Info: any;
  isAdminFlg: boolean = false;
  pageList = [
    { title: 'VIDEO', route: 'videos' },
    { title: 'HÌNH ẢNH', route: 'images' },
  ];

  ngOnInit(): void {
    this.domain = this.appService.apiRoot;
    this.checkTabSelect();
    this.router.events.subscribe((val)=>{
      this.checkTabSelect();
    })
    this.checkTabSelect();
    this.title.setTitle(this.translate.instant('Title-Page'));
  }

  

  checkTabSelect() {
    
      this.pageList.map((item,index)=>{

        if(this.router.url.includes(item.route)) {
          this.tabSelected=index;
          return;
        }
      })
    
  }

  onClickItemTab(index) {
    this.tabSelected = index;
  }
}
