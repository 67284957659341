import { Component,ViewContainerRef, OnInit, OnDestroy,Input,Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../services/app.service';
import { AppLanguage } from '../../services/app.language';
import { AppSwal } from 'src/app/services/app.swal';
import { Notification } from '../../services/app.notification';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';
import { PageChangeEvent } from '@progress/kendo-angular-pager';

@Component({
  selector: 'app-question-search',
  templateUrl: './question-search.component.html',
  styleUrls: ['./question-search.component.scss']
})
export class QuestionSearchComponent implements OnInit, OnDestroy {
  user: any;
  control: any;
  controlDefault = true;
  allowMulti = false;
  pageName = '';
  questionSearch : any;
  questionSearchtemp : any;
  
  dataGridSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  dataGridSelection: number[] = [];
  loading = false;
  searchOption = {
    searchText: ''
  };
  openQuestionDetail = false ; 
  urlDownload = this.appService.apiRoot;

  public pageSize = 9;
  public skip = 0;
  public pagedQuestion = [];
  public total = 0;
  
  constructor( 
    private appService: AppService,
    private appSwal: AppSwal,
    private language: AppLanguage,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts
    ) 
    {
      this.authenticationService.getUser();
      this.user = this.authenticationService.user;   
      this.language.default();
     }

  ngOnInit() {
    // this.getControl();
    // this.getPageName();
    this.initDisplay();
    
  }
  ngOnDestroy(): void {
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageNamePortal();
  }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.questionSearch);
    this.controlDefault = false;
  }
  

  ondataItemselectedKeysChange() {

  }

 
  async initDisplay() {
    await this.getQuestionSearch();
    this.pageData();
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 ) {
      this.onSearch();
    }
  }
  async onSearch(){
    await this.getQuestionSearch();
    this.skip = 0;
    this.pageData();
  }
  async onRemoveSearchText() {
    this.searchOption.searchText = '';
    
    await this.getQuestionSearch();
    this.pageData();
  }

  async getQuestionSearch() {
    this.loading = true;
    const dataRequest = {
      searchText : this.searchOption.searchText
    };
    const result = await this.appService.doGET('api/QuestionSearch/Search', dataRequest);
    if (result && result.Data && result != null) {
      this.questionSearch = result.Data;
      this.total = this.questionSearch.length;
    }
    this.loading = false;
  }
  onCloseQuestionDetail(){
    this.openQuestionDetail = false ;
  }
  bindtemp(item) {
    this.questionSearchtemp = Object.assign({}, item)

    if (this.questionSearchtemp.FileUrls != "" && this.questionSearchtemp.FileUrls != null) {
      this.questionSearchtemp.FileUrls = this.questionSearchtemp ? JSON.parse(this.questionSearchtemp.FileUrls) : null;
    }
    else {
      this.questionSearchtemp.FileUrls = [];
    }
    
  }
  onCellClick(item) {
    this.bindtemp(item)
    this.openQuestionDetail = true ;  
  }

  limitDisplay(text){
    return text.slice(0, 200) + (text.length > 200 ? "..." : "");
  }

  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  
  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }

  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }

  private pageData(): void {
    if (this.questionSearch){
      this.pagedQuestion = this.questionSearch.slice(
        this.skip,
        this.skip + this.pageSize
      );
    }    
  }
  
}

