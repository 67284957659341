import { Component, OnInit, Input } from '@angular/core';
import { AppConsts } from '../services/app.consts';
import { AppInfo } from '../services/app.info';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { Meta, Title } from '@angular/platform-browser';
import { AppSwal } from 'src/app/services/app.swal';
import { Notification } from '../services/app.notification';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppRouter } from '../app.router';
import { AppUtils } from '../services/app.utils';
import { MyServiceService } from '../my-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer2',
  templateUrl: './footer2.component.html',
  // styleUrls: ['./footer2.component.scss']
})
export class Footer2Component implements OnInit {
  isLoaded: boolean = false;
  isAddMenu = false;
  control: any;
  controlDefault = true;
  menuOpened = false;
  infoOpened = false;
  isParentUrl = false;
  @Input() isAdminFlg = true;
  user: any;
  footer: any;
  footerInfo: any;
  viFooterInfo: any;
  enFooterInfo: any;
  field: any;
  menu: any;
  menuFilter: any;
  menuSelected: any;
  parent: any;
  parentUrl: any;
  dataMenu: any;
  dataMenuItem: any;
  btnDataMenu: Array<any>;
  dataErr: any;
  routes = AppRouter.routes;
  postList: string;
  pageName = '';
  socialLinks: any;
  tabID = 0;
  popupClass = 'popup-width';
  categoriesList: any;
  categoriesListFiltered: any;
  currentUrl: any;
  constructor(
    private language: AppLanguage,
    private appService: AppService,
    private appInfo: AppInfo,
    private appSwal: AppSwal,
    private authenticationService: AuthenticationService,
    public appConsts: AppConsts,
    public appControls: AppControls,
    private notification: Notification,
    private translate: TranslateService,
    private meta: Meta , 
    private appUtils: AppUtils,
    private myService: MyServiceService,
    private router: Router,
  ) {
    this.appService.getUserName();
    this.user = this.appService.user;
    this.postList = AppConsts.page.postList;
  }

  async ngOnInit() {
    // this.getControl();
    this.setDefault();
    // this.getPageName();
    await this.onGetCategoriesNoAuth();
    if (!this.language.get() || this.language.get() == AppConsts.VIETNAMESE) {
      this.tabID = 0;
    } else {
      this.tabID = 1;
    }
    // await this.getMenu();
    // this.getTemplate();
    // this.getDataMenu();
    this.getFooter();
    if (window.location.pathname.includes('template-page')) {
      this.getViFooter();
      this.getEnFooter();
    }
  }

  // async getPageName() {
  //   this.pageName = await this.appControls.getPageNamePortal();
  // }
  // async getControl() {
  //   this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.receiveDocument);
  //   this.controlDefault = false;
  // }

  onReload() {
    // window.location.reload();
    // this.getMenu();
    this.getFooter();
  }
  // async getDataMenu() {
  //   const dataMenuResult = await this.appService.doGET('api/Portal/GetMenuNoChild', {'langID': this.language.get()});
  //   if(dataMenuResult && dataMenuResult.Status === 1){
  //     this.dataMenu = dataMenuResult.Data;
  //   }
  // }
  async getMenu() {
    const menuResult = await this.appService.doGET(
      'api/Portal/GetMenuNoChild',
      { langID: this.language.get() }
    );
    if (menuResult && menuResult.Status === 1) {
      this.menu = menuResult.Data;
      this.menuFilter = this.menu.slice();
    }
  }

  async getFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: this.language.get()
    });

    if (result && result.Status === 1 && result.Data && result.Data.Data.Info != null) {

        this.footer = result.Data.Data;
        this.footerInfo = JSON.parse(this.footer.Info);
        this.footerInfo.MenuIDs =
          typeof this.footerInfo.MenuIDs === 'string'
            ? JSON.parse(this.footerInfo.MenuIDs)
            : this.footerInfo.MenuIDs;
        this.isLoaded = true;
        // this.setInfoPage();

    } else {
      // chưa có thông tin footer thì hiển thị data mặc định
      this.setDataFooterDefault();
      this.isLoaded = true;

      // this.setInfoPage();
    }
    this.dataMenu = [];
    // if (this.footerInfo?.MenuIDs) {
    //   this.footerInfo.MenuIDs.forEach((e) => {
    //     var item = this.menu.filter((m) => m.ID == e.toLowerCase());
    //     if (item.length != 0) {
    //       this.dataMenu.push(item[0]);
    //     }
    //   });
    // }
    const resultSocialLink = await this.appService.doGET('api/PortalBody/GetCommon', {
      langID: this.language.get() , 
      type: 2
    });

    if (resultSocialLink && resultSocialLink.Status === 1 && resultSocialLink.Data ) { 
      this.socialLinks = resultSocialLink.Data ; 
    }
  }

  async getViFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: 'vi-VN',
    });
    if (result && result.Status === 1) {
      if (result.Data && result.Data.Data.Info != null) {
        this.viFooterInfo = JSON.parse(result.Data.Data.Info);
      }
    }
  }

  async getEnFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: 'en-US',
    });
    if (result && result.Status === 1 && result.Data && result.Data.Data.Info != null) {
        this.enFooterInfo = JSON.parse(result.Data.Data.Info);
    } else {
      this.setDataFooterDefault();
    }
  }

  setDataFooterDefault() {
    this.footer = {
      Info: {
        Language: 'vi-VN',
        CopyRight: 'Copyright',
        Address: 'Địa chỉ',
        Phone: '(84-8) ',
        Fax: '(84-8) ',
        Email: 'mail@gmail.com',
        Title: 'Tên công ty',
        SocialLinks: []
      },
    };
    this.footerInfo = {
      Language: 'vi-VN',
      CopyRight: 'Copyright',
      Address: 'Địa chỉ',
      Phone: '(84-8) ',
      Fax: '(84-8) ',
      Email: 'mail@gmail.com',
      Title: 'Tên công ty',
      SocialLinks: []
    };
      this.footerInfo.MenuIDs = [];
  }

  createDataRequest(lang) {
    let info =
      lang == AppConsts.VIETNAMESE ? this.viFooterInfo : this.enFooterInfo;
    return {
      Title: 'FooterConfig',
      LangID: lang,
      UrlPath: 'footer-info',
      MenuID: JSON.stringify(['127839b3-31b8-45ae-a1bd-654cd45a1be9']),
      Summary: JSON.stringify({
        Title: info?.Title,
        Address: info?.Address,
        Phone: info?.Phone,
        Fax: info?.Fax,
        Email: info?.Email,
        Description: info?.Description,
        Language: lang,
        MenuIDs: JSON.stringify(this.dataMenu.map((item) => item.ID)),
        CopyRight: info?.CopyRight,
        TemplateID: info?.TemplateID
      }),
      ImageUrl: '',
    };
  }

  async updateFoorterInfo(lang?) {
    const dataRequest = this.createDataRequest(lang);
    const result = await this.appService.doPOST(
      'api/Portal/UpdateFooterInfo',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
        // var count = 0;
        // for (var prop in this.dataErr[0]) {
        //   count++;
        // }
        // this.customCss = count;
      } else {
        // this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  setDefault() {
    this.dataMenuItem = {
      Name: '',
      ID: '',
      ParentID: '',
      OrderIndex: 0,
      Url: '',
      PageID: null,
      IsButton: false,
      Childrens: [],
    };
    this.isParentUrl = false;
    this.parentUrl = null;
  }

  setInfoPage() {
    let Info = this.footerInfo;
    let iconUrl = '/favicon.ico';
    this.appInfo.setSchema(
      Info.SchemaType,
      Info.RootURL,
      Info.Phone,
      Info.Email,
      Info.Address,
      iconUrl,
      Info.Latitude,
      Info.Longtitude,
      Info.SchemaContext,
      Info.Name
    );
  }
  menuHandleFilter(value) {
    this.menuFilter = this.menu.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  onChangeMenu(e) {
    this.menuSelected = this.menu.filter((m) => m.ID == e);
  }
  async onDeleteMenu(item) {
    // const dataRequest = {
    //   iD: item.ID,
    //   langID: this.language.get()
    // };

    const option = await this.appSwal.showWarning(
      this.translate.instant('DeleteMenu_AreYouSure'),
      true
    );
    if (option) {
      var index = this.dataMenu.indexOf(item);
      this.dataMenu.splice(index, 1);
      this.updateFoorterInfo(this.language.get());
      this.onReload();
    }
  }
  async onEditMenu(item) {
    // const dataRequest = {
    //   iD: item.ID,
    //   langID: this.language.get()
    // };
    if (item.ID) {
      this.menuOpened = true;
      this.dataMenuItem = item;
      if (item.ParentID && item.ParentID != '') {
        this.isParentUrl = true;
        this.parent = this.menuFilter.filter((e) => e.ID == item.ParentID);
        this.parentUrl = this.parent[0].UrlPath;
      }
    } else {
      this.infoOpened = true;
      this.tabID =
        !this.language.get() || this.language.get() == AppConsts.VIETNAMESE
          ? 0
          : 1;
      setTimeout(() => {
        let kItem = document.getElementsByClassName('k-item')[this.tabID];
        if (kItem) {
          kItem.classList.add('active');
        }
      }, 10);
    }

    // const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    // if (option) {
    //   const result = await this.appService.doDELETE('api/Menu', dataRequest);
    //   if (result && result.Status === 1) {
    //     this.notification.showSuccess(result.Msg);
    //     this.onReload();
    //     this.onCloseAddMenu();
    //     // this.dataGridSelection = [];
    //     // this.allowMulti = false;
    //   } else {
    //     this.appSwal.showWarning(result.Msg, false);
    //   }
    // }
  }
  onTabChanged(e) {
    this.tabID = e.index;
    let tabActive = document.getElementsByClassName('k-item');
    for (let i = 0; i < tabActive.length; i++) {
      if (tabActive[i].classList.contains('active')) {
        tabActive[i].classList.remove('active');
      }
    }
    tabActive[e.index].classList.add('active');
  }
  onOpenAddMenu() {
    this.setDefault();
    this.isAddMenu = true;
  }
  onCloseDialog() {
    this.setDefault();
    this.isAddMenu = false;
    this.menuOpened = false;
    this.infoOpened = false;
  }
  onDataMenuClick(item) {
    this.getBtnFunctionData(item);
  }
  getBtnFunctionData(item) {
    if (item.ID) {
      this.btnDataMenu = [
        {
          id: 'delete',
          text: this.translate.instant('Delete'),
          icon: 'delete',
          click: () => {
            this.onDeleteMenu(item);
          },
        }
      ];
    } else {
      this.btnDataMenu = [
        {
          id: 'edit',
          text: this.translate.instant('Edit'),
          icon: 'edit',
          click: () => {
            this.onEditMenu(item);
          },
        },
      ];
    }
  }
  onSaveDialog(lang?) {
    if (this.isAddMenu) {
      this.addMenu();
    } else if (this.menuOpened) {
      this.updateMenu();
    } else {
      this.updateInfo(lang);
    }
  }
  addMenu() {
    if (this.menuSelected.length != 0) {
      this.dataMenuItem.ID = this.menuSelected[0].ID;
      this.dataMenuItem.Name = this.menuSelected[0].Name;
      this.dataMenuItem.OrderIndex = this.menuSelected[0].OrderIndex;
      this.dataMenuItem.PageID = this.menuSelected[0].PageID;
      this.dataMenuItem.ParentID = this.menuSelected[0].ParentID
        ? this.menuSelected[0].ParentID
        : '';
      this.dataMenuItem.Url = this.menuSelected[0].Url;
      let data = this.dataMenu.filter((d) => d.ID == this.dataMenuItem.ID);
      if (data.length == 0) {
        this.dataMenu.push(this.dataMenuItem);
        this.dataMenu.sort((l1, l2) => (l1.OrderIdx < l2.OrderIdx ? -1 : 1));
        // this.footerInfo.MenuIDs.push(this.dataMenuItem.ID);
        this.updateFoorterInfo(this.language.get());
      } else {
        this.notification.showError('Trùng chuyên mục');
      }
    } else {
      // this.appSwal.showWarning("Fail", false);
    }

    this.setDefault();
    this.isAddMenu = false;
  }
  updateMenu() {
    // this.updateFoorterInfo();
    this.setDefault();
    this.menuOpened = false;
  }
  updateInfo(lang) {
    this.updateFoorterInfo(lang);
    this.setDefault();
    this.infoOpened = false;
  }
  goToMenu(item) {
    var MenuTemp :any ;
    MenuTemp  = this.categoriesList.filter( (s) => s.ID.toLowerCase().indexOf(item.toLowerCase()) !== -1  )[0];
    this.currentUrl = this.router.url;
    let itemUrl = null;
    for(let i = 0; i < this.categoriesList.length; i++){
      if(this.categoriesList[i].ID == item){
        itemUrl = this.categoriesList[i].UrlPath;
        break;
      }
    }
    
    if (this.currentUrl != ''){
      if(itemUrl != "/"){
          this.router.navigateByUrl(AppConsts.page.main + '/' + itemUrl);
        } else {
          this.router.navigateByUrl(AppConsts.page.main + '/');
        }
    }
    this.myService.sendMessage(MenuTemp);
  }

  
  async onGetCategoriesNoAuth() {
    const result = await this.appService.doGET('api/Menu', {
      langID: this.language.get()
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data;
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }
  
}
