<div class="working-schedule">
  <h3 class="title">Lịch công tác tuần 46 (04/09/2022 đến 10/09/2022)</h3>
  <div class="schedule-table">
    <kendo-grid
      [data]="gridData"
      scrollable="none"
      [style]="{ width: '1040px' }"
    >
      <kendo-grid-column
        field="Day"
        title=""
        [style]="{ width: '146px' }"
        [class]="'cellCustomClass'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="day-container">
            {{ dataItem.Day }}
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="MorningSchedule"
        title="Sáng"
        [style]="{ width: '447px' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="working-container">
            <div class="working" *ngFor="let item of dataItem.MorningSchedule">
              {{ item }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="AfternoonSchedule" title="Chiều">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="working-container">
            <div
              class="working"
              *ngFor="let item of dataItem.AfternoonSchedule"
            >
              {{ item }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
