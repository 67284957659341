import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  currentSearchText: string;

  constructor() {}

  setSearchText(text: string) {
    this.currentSearchText = text;
    console.log('curr', this.currentSearchText);
  }

  getSearchText() {
    return this.currentSearchText;
  }
}
