import { Component, OnInit, OnDestroy, Input, TemplateRef, OnChanges, SimpleChanges, Output, EventEmitter, HostListener } from '@angular/core';
import { AppControls } from 'src/app/services/app.controls'
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { AppSwal } from '../../services/app.swal';
import { AppGuid } from '../../services/app.guid';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConsts } from 'src/app/services/app.consts';
import { AppComponent } from '../../app.component';
import { Subscription } from 'rxjs/Rx';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router'
import { getLineAndCharacterOfPosition } from 'typescript';
import { Meta, Title } from '@angular/platform-browser';
import { ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MyServiceService } from 'src/app/my-service.service';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  // styleUrls: ['./post-detail.component.scss']
  encapsulation: ViewEncapsulation.None,
  // providers: [MyServiceService],
})
export class PostDetailComponent implements OnInit, OnDestroy {
  @Input() menuVl: any;
  @Input() postUrl: any;
  @Input() intro: any;
  @Input() menuItem: any
  @Input() itemchild: any;
  titleItem: any;
  menuTittle: any;
  pageName = '';
  UrlPath: string = '';
  Data_Post: any = {};
  Categories: any = [];
  postList: string;
  control: any;
  user: any;
  loading = false
  tittle: any;
  controlDefault = true;
  flgVi = true;
  urlDownload = this.appService.apiRoot;
  extension: string[] = ['.mp4', '.ogg', 'webm'];
  topNews: any
  // player: videojs.Player;
  // customButton =
  // "<span class='most-view-zoom buttonZoom'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>";

  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  pageHeader: any;
  pageFooter: any;
  isPostInMainPage: boolean= true;
  currentUrl: any;
  bodyWidth = 1140;
  bodyWidthSetup = 1140;
  @Input() isAdminFlg: boolean = false;
  @Input() isIntroPage: boolean = false;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any;
  @Output() deleteBodyItem = new EventEmitter();

  menuSelect = '';
  textNews: any;

  @Input() infoFromMain: any;
  @Output() onChangeTempPostDetail = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  isShow: boolean;
  topPosToStartShowing = 100;
  private queryParamsSubscription: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    private guid: AppGuid,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appComponent: AppComponent,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private myService: MyServiceService,
    // private domSanitizer: DomSanitizer,

  ) {

    this.language.default();
    this.postList = "/" + AppConsts.page.postList;
    this.tittle = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state : null;
    this.myService.getMenuChild.subscribe(message => {
      // console.log(message);

      this.menuVl = message
    })

  }

  async ngOnInit(): Promise<void> {

    if (typeof window !== 'undefined'){
      this.flgVi = this.language.get() == "vi-VN" ? true : false;

      this.UrlPath = this.postUrl;
      //console.log(this.postUrl);
      this.checkPostMain();
      this.getHeader();
      this.getFooter();
      await this.getContentWidth();
      this.setWidth();
      this.queryParamsSubscription = this.activatedRoute.params.subscribe(data => {



        this.UrlPath = data['title'] ?? this.postUrl;
        if (typeof this.infoFromMain === "string") {
          this.infoFromMain = JSON.parse(this.infoFromMain);
        }
        this.getDataPostAndCategoriesByListID();
        window.scroll(0, 0);
        // this.appService.doPUT('api/DataPost/increaseView', null, { urlPath: this.UrlPath, langID: this.language.get() });

      });

      await this.updatePostDetail()
    }

  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  async updatePostDetail() {
    this.loading = true
    const dataRequest = {
      langID: this.language.get(),
      postUrl: this.UrlPath
    };
    const result = await this.appService.doGET("api/PortalPost/GetByPostUrl", dataRequest);
    if(result.Data && result.Status === 1){
      this.Data_Post = result.Data;
      this.Data_Post.FileUrls = this.Data_Post.FileUrls ? JSON.parse(this.Data_Post.FileUrls) : '';
      if(this.Data_Post.Description){
        this.Data_Post.Description = this.Data_Post.Description.replaceAll("oembed","iframe").replace("url","src");
        result.Data.Title ? this.title.setTitle(result.Data.Title) : this.UrlPath ? this.title.setTitle(this.UrlPath) : this.title.setTitle(this.translate.instant('Title-Page'));
        this.updateMeta();
      }
    }
    this.loading = false
  }
  async getDataPostAndCategoriesByListID() {
    var menuIDRequest = (this.menuItem && this.menuItem.ID) ? this.menuItem.ID : (this.infoFromMain?.MenuID) ;
    if (!menuIDRequest) {
      return;
    }
    await this.getTopNews(menuIDRequest);
    if (!this.topNews || this.topNews.length <= 0) {
      // console.log('Không có tin bài nào thuộc chuyên mục này');
      return;
    }
    const dataRequest = {
      postUrl: this.topNews[0].UrlPath,
      langID: this.language.get()
    };

    const result = await this.appService.doGET('api/PortalPost/GetByPostUrl', dataRequest);
    if (result && result.Data && result.Status === 1) {
      // so sánh nhận biết tin bài theo ngôn ngữ để search khi search sai ngôn ngữ urlpath
      if (this.language.get() != result.Data.langID) {
        // this.router.navigate([AppConsts.page.postDetail, result.Data.UrlPath]);

      }
      this.Data_Post = result.Data;
      if (this.Data_Post.FileUrls != null && this.Data_Post.FileUrls != undefined && this.Data_Post.FileUrls != "") {
        this.Data_Post.FileUrls = JSON.parse(result.Data.FileUrls)
      }
      else { this.Data_Post.FileUrls = null };

      if(this.Data_Post.Description){
        this.Data_Post.Description = this.Data_Post.Description.replaceAll("oembed","iframe").replace("url","src");
      }
      result.Data.Title ? this.title.setTitle(result.Data.Title) : this.UrlPath ? this.title.setTitle(this.UrlPath) : this.title.setTitle(this.translate.instant('Title-Page'));
      // console.log(this.Data_Post.FileUrls)
      this.updateMeta();
      // console.log(this.Data_Post.FileUrls)

      // const dataRequest2 = {
      //   listID: this.Data_Post?.MenuID,
      //   langID: this.language.get()
      // };
      // const result2 = await this.appService.doGET('api/DataPost/GetCategoriesByListID', dataRequest2);
      // if (result2 && result2.Status === 1) {
      //   this.Categories = result2.Data;
      // }
      // else {
      //   this.appSwal.showWarning(result2.Msg, false);
      //   return;
      // }
    }
    else {
      this.title.setTitle(this.translate.instant('Title-Page'));
      if (result.Msg) {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }
  getFileName(IconUrl) {
    var nameFile = "";
    if (IconUrl != "" && IconUrl != null) {
      var urlArr = IconUrl.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }


  isVideo(name: string) {
    let result = false;
    if (name) {
      this.extension.forEach((e) => {
        if (name.includes(e)) {
          result = true;
          //    this.setupVideo();
        }
      });
    }
    return result;
  }

  getFullImageUrl(url: string) {
    // this.setupVideo();
    return this.appService.apiRoot + url;
  }

  updateMeta(): void {
    if (typeof window !== 'undefined'){
      if (this.Data_Post != null) {
        var description = this.Data_Post.Summary ? this.Data_Post.Summary.replace(/<[^>]+>/g, '') : "";
        var keywords = this.Data_Post.Keywords;
        var url = window.location.origin + window.location.pathname;
        var title = this.Data_Post.Title;
        //let summary = document.createElement("DIV");
        var summary = this.Data_Post.Summary ? this.Data_Post.Summary.replace(/<[^>]+>/g, '') : "";
        var ogDescription = summary;
        var image = this.getUrlDownload(this.Data_Post.ImageUrl);

        this.meta.updateTag({ name: "title", content: title});
        this.meta.updateTag({ name: "description", content: description});

        //Facebook
        this.meta.updateTag({ property: "og:type", content: "website"});
        this.meta.updateTag({ property: "og:url", content: url});
        this.meta.updateTag({ property: "og:title", content: title});
        this.meta.updateTag({ property: "og:description", content: description});
        this.meta.updateTag({ property: "og:image", content: image});
        this.meta.updateTag({ property: 'og:image:secure_url', content: image});

        //Twitter
        this.meta.updateTag({ property: "twitter:card", content: "summary_large_image"});
        this.meta.updateTag({ property: "twitter:url", content: url});
        this.meta.updateTag({ property: "twitter:title", content: title});
        this.meta.updateTag({ property: "twitter:description", content: description});
        this.meta.updateTag({ property: "twitter:image", content: image});
      }
    }
  }

  async getTopNews(menuID) {
    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        menuID: menuID ? menuID : '',
        topNum: 2,
      }
    );
    if (result && result.Status === 1) {
      this.topNews = result.Data.topNews
    }
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  onLoadData(event) {
    this.infoFromMain.MenuID = (event && event.Info) ? event.Info.MenuID : '';
    this.getDataPostAndCategoriesByListID();
  }

  handleChangeTempPostDetail(newTemp: any) {

    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    // this.getTopNews();
    // this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.onChangeTempPostDetail.emit(newTemp);
  }
  async getHeader() {
    const result = await this.appService.doGET('api/PortalHeader', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageHeader = [result.Data.HeaderType];
    }
  }
  async getFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageFooter = [result.Data.FooterType];
    }
  }
  checkPostMain(){
    this.currentUrl = this.router.url;
    if(this.currentUrl != "/" && (this.currentUrl != ("/"+ AppConsts.page.templatePage))){
      this.isPostInMainPage = false;
    }
  }
  async getContentWidth(){
    const result = await this.appService.doGET('api/PortalDesign/GetWidth', {});
    if(result && result.Status === 1 && typeof window !== 'undefined'){
      this.bodyWidthSetup = Number(result.Data[0]);
      this.bodyWidth = this.bodyWidthSetup;
      if(this.bodyWidth > window.innerWidth){
        this.bodyWidth = ((window.innerWidth * 80)/100);
      }
    }
  }
  onResize(event) {


    if(this.bodyWidth > window.innerWidth){
      this.bodyWidth = ((window.innerWidth * 80)/100);
    }
    if(window.innerWidth >= this.bodyWidth){
      if(window.innerWidth > this.bodyWidthSetup){
        this.bodyWidth = this.bodyWidthSetup;
      }else {
        if(window.innerWidth <= 770){
          this.bodyWidth = ((window.innerWidth * 95)/100);
        } else {
          this.bodyWidth = ((window.innerWidth * 80)/100);
        }
      }
    }
    this.setWidth();
  }
  setWidth(){
    // let menuWidth = document.getElementById("content-body");
    // if(menuWidth){
    //   menuWidth.style.width = this.bodyWidth.toString() +"px";
    // }

  }


  menuItem1(event) {
    // console.log(event);

    // this.menuIDMenu = event.ID;
    this.menuVl = event;
  }
  menuItemchild(event) {
    // this.menuIDMenu = event.ID;
    this.itemchild = event;
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
   // TODO: Cross browsing
   gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
