import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { AppConsts } from 'src/app/services/app.consts';
import { Notification } from 'src/app/services/app.notification';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  // styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  
  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
    private appService: AppService,
    private router: Router,
    private notification: Notification
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Forgot Password');
    this.meta.updateTag({
      name: 'description',
      content: 'This is a Forgot Password Page',
    });
  }
}
