<div [ngClass]="{'mb-3': true, 'login-failed-error': !requestFailed.isHide}">
  <div *ngIf="!requestFailed.isHide">
    Email không tồn tại
  </div>
</div>
<div class="description mb-4">
  <h6>{{ "Forgot_Pass" | translate }}</h6>
  <span class="content">{{ "Forgot_Pass_Description" | translate }}</span>
</div>
<div class="sub-item">
  <div class="mb-2">
    <div
      class="text-input"
      [ngClass]="{ invalid: invalidField.Email.status, focus: focus.Email }"
    >
      <label class="input">
        <input
          class="input__field"
          [(ngModel)]="Email"
          autocomplete="off"
          (keypress)="onKeyPress($event)"
          id="email"
          (keyup)="onKeyUpUserName($event)"
          type="text"
          (focus)="onFocus(0)"
          (focusout)="onFocusOut(0)"
          placeholder=" "
        />
        <span class="input__label">Email</span>
      </label>
      <div class="action">
        <i
          *ngIf="isShowCleanText"
          class="fa fa-times-circle-o"
          aria-hidden="true"
          (click)="cleanEmailTxt()"
        ></i>
      </div>
    </div>
    <div class="error-text">
      {{ invalidField.Email.status ? invalidField.Email.message : "" }}
    </div>
    <div class="alert" *ngIf="isAlert">
      <img class="icon-alert" src="/assets/images/check_circle.png" alt="check-circle">
      <div class="description">{{"Forgot_Password_Message" | translate}}</div>
    </div>
  </div>
</div>

<div class="sub-item">
  <button (click)="onForgotPassword()" class="cf-forgot-password-button">
    <span *ngIf="!loading">{{ "Cf_Forgot_Pass" | translate }}</span>
    <div
      *ngIf="loading"
      class="spinner-border spinner-border-sm text-primary"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</div>