import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-template-10',
  templateUrl: './news-template-10.component.html',
})
export class NewsTemplate10Component implements OnInit {
  topNews: any = [];
  topNumber: number = 4;
  bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  galleryData: any;
  editFlg = false;
  @Input() OrderIdx: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() urlItem = new EventEmitter();
  @Output() saveData = new EventEmitter();
  @Output() tittleItem = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() isPreview: boolean;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.bootstrapNum = 12 / this.topNumber;
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.getPostTittle();
    this.getTopNews();
    this.galleryData = {
      OrderIdx: this.OrderIdx,
      infoFromMain: this.infoFromMain,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };
    if (this.ID == null && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
    }
  }
  async getTopNews() {
    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        menuID: this.infoFromMain.MenuID,
        topNum: this.infoFromMain.TopNumber,
        // topNum: 4,
      }
    );

    if (result && result.Status === 1) {
      this.postTittle = result.Data.dataMenu.Name;
      this.viewMore = result.Data.dataMenu.UrlPath;
      this.topNews = result.Data.topNews;
    }
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }

  onGetTittleItem(e) {
    this.tittleItem.emit(e);
  }

  redirectToItem(url: string, item) {
    this.onGetTittleItem(item);
    if (url != null && url != undefined) {
      // this.router.navigate([AppConsts.page.galleryDialog], { queryParams: {} });
      this.router.navigate([AppConsts.page.postDetail, url], {
        state: { postTittle: this.postTittle },
      });
      // this.urlItem.emit(item);
      // window.location.href = this.appService.portalRoot + 'post-detail/' + url;
    }
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  async deleteComponent() {
    this.isShowMenu = false;
    // this.topNews = new Array(3).fill({key: 'value'});
    // this.postTittle = this.translate.instant('Category_Name');
    // const dataRequest = {
    //   iD: this.templateID,
    //   itemID: this.ID,
    //   index: this.OrderIdx,
    // };
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }
  onClose(e) {
    //this.onReload();
    if (e == true) {
      this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.manageDialog = false;
  }

  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }

  onSaveData() {
    this.saveData.emit();
  }
}
