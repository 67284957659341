<article class="news-template-23">
  <div class="container-fluid">
    <div class="row scroll-area">
      <div class="main-item">
        <div class="row">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <div class="menu-row-current-border" (click)="editComponent()">
                  <div class="menu-item-1">{{'Edit' | translate}}</div>
                </div>
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <nav class="sidebar" [ngClass]="{ sidebarPushRight: isActive }">
              <ul class="list-group">
                <div class="nested-menu">
                  <div class="list-title">
                    <span class="list-group-items">
                      {{ "Most_View_Post" | translate }}
                    </span>
                  </div>
                  
                  <div class="list-submenu"  id="newTemplate23">
                    <a
                      class="submenu"
                      *ngFor="let newItem of mostViewed;let indexOfelement=index;"
                      [href]="postDetailPath + '/' +newItem.UrlPath"
                    >
                      <div>
                        <img *ngIf="newItem.Title == mostViewed[0].Title" [style.height.px]="innerWidth*0.12" src="{{ this.appService.apiRoot + newItem.ImageUrl }}" alt="{{ newItem.Title }}"
                        class="img-first" draggable="false" (click)="getLink(item.DetailPath)" />
                      </div>
                      <div class="sub-item">
                      <!-- <div *ngIf="newItem.Title != mostViewed[0].Title" class="sub-number">
                        {{indexOfelement +1}}
                      </div> -->
                      <div *ngIf="newItem.Title == mostViewed[0].Title" class="item-title-first">
                        {{ newItem.Title }}
                      </div>
                      <div *ngIf="newItem.Title != mostViewed[0].Title" class="item-title">
                        {{ newItem.Title }}
                      </div>
                    </div>
                    </a>
                  </div>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
              <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
          </div> -->
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-23-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-23-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-23-dialog>
    <div *ngIf="dltFlg == true">
      <button
        class="btn-delete"
        (click)="onDeleteGallery()"
      >
        <span>{{ "Delete" | translate }}</span>
      </button>
      <button
        class="btn-cancel"
        (click)="onClose($event)"
      >
        <span>{{ "Cancel" | translate }}</span>
      </button>
    </div>
  </kendo-dialog>
</article>
