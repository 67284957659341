import { Component, OnInit, OnDestroy, Input, TemplateRef, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { AppControls } from 'src/app/services/app.controls'
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { AppSwal } from '../../services/app.swal';
import { AppGuid } from '../../services/app.guid';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConsts } from 'src/app/services/app.consts';
import { AppComponent } from '../../app.component';
import { Subscription } from 'rxjs/Rx';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router'
import { getLineAndCharacterOfPosition } from 'typescript';
import { Meta, Title } from '@angular/platform-browser';
import { ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Fancybox  } from '@fancyapps/ui';

@Component({
  selector: 'app-pdetail',
  templateUrl: './pdetail.component.html',
  // styleUrls: ['./post-detail.component.scss']
  encapsulation: ViewEncapsulation.None
})
export class PDetailComponent implements OnInit {
 // @Input() menuVl: any;
  @Input() postUrl: any;
  @Input() intro: any;
  //@Input() menuItem: any;
  @Input() isIntroPage: boolean = false;
  pageName = '';
  UrlPath: string = '';
  Data_Post: any = {};
  Categories: any = [];
  postList: string;
  control: any;
  user: any;
  loading = false
  tittle: any;
  controlDefault = true;
  flgVi = true;
  urlDownload = this.appService.apiRoot;
  extension: string[] = ['.mp4', '.ogg', 'webm'];
  topNews: any;
  // player: videojs.Player;
  // customButton =
  // "<span class='most-view-zoom buttonZoom'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>";

  keyword: string[];
  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  pageHeader: any;
  pageFooter: any;
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any;
  @Output() deleteBodyItem = new EventEmitter();

  menuSelect = '';
  textNews: any;

  @Input() infoFromMain: any;
  @Output() onChangeTempPostDetail = new EventEmitter();
  menuIDMenu: any;
  menuVl:any;
  @Input() itemchild: any;
  @Input() bodyWidth = 1140;
  private queryParamsSubscription: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    private guid: AppGuid,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appComponent: AppComponent,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private location: Location,
    // private domSanitizer: DomSanitizer,
    private elRef: ElementRef
  ) {
    // this.getPageName();
    // this.authenticationService.getUser();
    // this.user = this.authenticationService.user;
    this.language.default();
    this.postList = "/" + AppConsts.page.postList;
    // localStorage.setItem("culture", 'vi-VN');
    // localStorage.setItem("culture", 'en-US');
    this.tittle = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state : null;
  }

  async ngOnInit(): Promise<void> {
    this.flgVi = this.language.get() == "vi-VN" ? true : false;

    this.UrlPath = this.postUrl;
    //console.log(this.postUrl);
    
    this.getHeader();
    this.getFooter();
    this.queryParamsSubscription = this.activatedRoute.params.subscribe(data => {
      this.UrlPath = data['title'] ?? this.postUrl;
      // this.isIntroPage = this.intro ?? false;
      //this.Data_Post = await this.appService.doGET('api/Document/GetDocument', '');
      if (typeof this.infoFromMain === "string") {
        this.infoFromMain = JSON.parse(this.infoFromMain);
      }
      this.getDataPostAndCategoriesByListID();
      window.scroll(0, 0);
      // this.appService.doPUT('api/DataPost/increaseView', null, { urlPath: this.UrlPath, langID: this.language.get() });
    
    });

    // await this.updatePostDetail()
    if (this.isIntroPage != true) {
      await this.updatePostDetail();
    }
    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      // Custom options
    });
  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }

  async updatePostDetail() {
    this.loading = true
    const dataRequest = {
      langID: this.language.get(),
      postUrl: this.UrlPath
    };
    const currentPath = this.location.path();
    const result = await this.appService.doGET("api/PortalPost/GetByPostUrl", dataRequest);
    
    this.loading = false;
    if(result.Data && result.Status === 1){
      this.Data_Post = result.Data;
      // console.log("data post",this.Data_Post);
      result.Data.Title ? this.title.setTitle(result.Data.Title) : this.UrlPath ? this.title.setTitle(this.UrlPath) : this.title.setTitle(this.translate.instant('Title-Page'));
      if (currentPath.slice(currentPath.length - 5) != '.html') {
        this.location.replaceState(currentPath + '.html');
      }
      if (currentPath.includes("&previewFlg")) {
        this.location.replaceState(currentPath.replace("&previewFlg", ""));
      }
      this.updateMeta();
      this.Data_Post.FileUrls = this.Data_Post.FileUrls ? JSON.parse(this.Data_Post.FileUrls) : null;

      if(this.Data_Post.Description){
        this.Data_Post.Description = this.Data_Post.Description.replace("<oembed url","<iframe src").replaceAll("/oembed","/iframe");
        this.Data_Post.Description = this.Data_Post.Description.replaceAll(`<img `,`<img data-fancybox="gallery"`);
      }   
    }
    
  }
  async getDataPostAndCategoriesByListID() {
    //var menuIDRequest = (this.menuItem && this.menuItem.ID) ? this.menuItem.ID : (this.infoFromMain?.MenuID) ;
    var menuIDRequest = this.infoFromMain?.MenuID ;
    const currentPath = this.location.path();
    if (!menuIDRequest) {
      return;
    }
    await this.getTopNews(menuIDRequest);
    if (!this.topNews || this.topNews.length <= 0) {
      console.log('Không có tin bài nào thuộc chuyên mục này');
      return;
    }
    const dataRequest = {
      postUrl: this.topNews[0].UrlPath,
      langID: this.language.get()
    };

    if (currentPath.slice(currentPath.length - 5) != '.html') {
      this.location.replaceState(currentPath + '.html');
    }
    const result = await this.appService.doGET('api/PortalPost/GetByPostUrl', dataRequest);
    if (result && result.Data && result.Status === 1) {
      // so sánh nhận biết tin bài theo ngôn ngữ để search khi search sai ngôn ngữ urlpath
      if (this.language.get() != result.Data.langID) {
        // this.router.navigate([AppConsts.page.postDetail, result.Data.UrlPath]);

      }
      this.Data_Post = result.Data;
      if (this.Data_Post.FileUrls != null && this.Data_Post.FileUrls != undefined && this.Data_Post.FileUrls != "") {
        this.Data_Post.FileUrls = JSON.parse(result.Data.FileUrls)
      }
      else { this.Data_Post.FileUrls = null };

      if(this.Data_Post.Description){
        this.Data_Post.Description = this.Data_Post.Description.replace("oembed url","iframe src").replaceAll("/oembed","/iframe");
        this.Data_Post.Description = this.Data_Post.Description.replaceAll(`<img `,`<img data-fancybox="gallery"`);
      }    
      result.Data.Title ? this.title.setTitle(result.Data.Title) : this.UrlPath ? this.title.setTitle(this.UrlPath) : this.title.setTitle(this.translate.instant('Title-Page'));
      this.updateMeta();
      
    }
    else {
      this.title.setTitle(this.translate.instant('Title-Page'));
      if (result.Msg) {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }
  getFileName(IconUrl) {
    var nameFile = "";
    if (IconUrl != "" && IconUrl != null) {
      var urlArr = IconUrl.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }


  isVideo(name: string) {
    let result = false;
    if (name) {
      this.extension.forEach((e) => {
        if (name.includes(e)) {
          result = true;
          //    this.setupVideo();
        }
      });
    }
    return result;
  }

  getFullImageUrl(url: string) {
    // this.setupVideo();
    return this.appService.apiRoot + url;
  }

  updateMeta(): void {
    if (this.Data_Post != null) {
      var description = this.Data_Post.Summary ? this.Data_Post.Summary.replace(/<[^>]+>/g, '') : "";
      var keywords = this.Data_Post.Keywords;
      // var url = window.location.origin + window.location.pathname;
      var url = this.Data_Post.UrlPath;
      var title = this.Data_Post.Title;
      //let summary = document.createElement("DIV");
      var summary = this.Data_Post.Summary ? this.Data_Post.Summary.replace(/<[^>]+>/g, '') : "";
      var ogDescription = summary;
      var image = this.getUrlDownload(this.Data_Post.ImageUrl);
      var author = "Cổng thông tin điện tử Sawaco";

      
      this.meta.updateTag({ name: "title", content: title});
      this.meta.updateTag({ name: "description", content: description});
      this.meta.updateTag({ name: "author", content: author})
      
      //Facebook
      this.meta.updateTag({ property: "og:type", content: "website"});
      this.meta.updateTag({ property: "og:url", content: url});
      this.meta.updateTag({ property: "og:title", content: title});
      this.meta.updateTag({ property: "og:description", content: description});
      this.meta.updateTag({ property: "og:image", content: image});
      this.meta.updateTag({ property: "og:image:alt", content: title});
      this.meta.updateTag({ property: 'og:image:secure_url', content: image});
      this.meta.updateTag({ property: "og:site_name", content: author});
      
      //Twitter
      this.meta.updateTag({ property: "twitter:card", content: "summary_large_image"});
      this.meta.updateTag({ property: "twitter:url", content: url});
      this.meta.updateTag({ property: "twitter:title", content: title});
      this.meta.updateTag({ property: "twitter:description", content: description});
      this.meta.updateTag({ property: "twitter:image", content: image});
    }
  }

  async getTopNews(menuID) {
    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        menuID: menuID,
        topNum: 2,
      }
    );
    if (result && result.Status === 1) {
      this.topNews = result.Data.topNews
    }
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  onLoadData(event) {
    this.infoFromMain.MenuID = (event && event.Info) ? event.Info.MenuID : '';
    this.getDataPostAndCategoriesByListID();
  }

  handleChangeTempPostDetail(newTemp: any) {

    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    // this.getTopNews();
    // this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.onChangeTempPostDetail.emit(newTemp);
  }
  async getHeader() {
    const result = await this.appService.doGET('api/PortalHeader', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageHeader = [result.Data.HeaderType];
    }
  }
  async getFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.pageFooter = [result.Data.FooterType];
    }
  }
  
  menuItem(event) {
    this.menuIDMenu = event.ID;
    this.menuVl = event;
  }
  menuItemchild(event) {
    this.menuIDMenu = event.ID;
    this.itemchild = event;
  }

  redirectToKeyword(keyword) {
    this.router.navigate(['/Tu-khoa/'], {
      queryParams: {keywords: keyword}
    });
  }
  zoomInAndOut(){
    document.getElementById("#parent").onclick
  }
}
