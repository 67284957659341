<article
  [ngClass]="
    templateType == 1 ? 'news-template-26' : 'news-template-26-without-time'
  "
>
  <div class="container-fluid row">
    <div class="row">
      <div class="main-item">
        <div class="row">
          <div
            *ngIf="isAdminFlg"
            class="vertical-dot"
            [ngClass]="isShowMenu ? 'd-flex' : ''"
          >
            <div
              class="three-dot-area k-icon k-i-more-vertical"
              (click)="enableChoice()"
            ></div>
            <div class="menu" *ngIf="isShowMenu">
              <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{ "Edit" | translate }}</div>
              </div>
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{ "Delete" | translate }}</div>
              </div>
            </div>
          </div>
          <div
            class="col-sm-12 col-12 col-md-12 col-xl-12 post-title"
            *ngIf="templateType == 1"
          >
            <p class="uppercase title-text">{{ "Media_Film" | translate }}</p>
            <div *ngIf="isMore" class="post-more">
              <!-- <div class="pointer" (click)="redirectToViewMore(item)">
                {{ "View_More" | translate }}
                <i class="fa fa-angle-right icon-right"></i>
              </div> -->
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div class="wrapper">
              <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                <kendo-tabstrip-tab
                  *ngFor="let tab of mediaCommonTabs; let i = index"
                  [title]="tab.Name"
                  [selected]="i == 0 ? true : false"
                >
                  <ng-template kendoTabContent>
                    <div class="content">
                      <div class="row d-grid">
                        <div
                          class="col-12"
                          *ngFor="let item of mediaNews; let j = index" (click)="openDialogVideo(item.FileUrls)"
                        >
                          <div class="pointer width-full">
                            <div class="overlay"></div>
                            <section  [innerHTML]="getVideo(item.FileUrls) | sanitizedHtml">
                              <!-- <figure class="media">
                                <iframe
                                  *ngIf="item.FileUrls"
                                  class="video-container"
                                  controls
                                  [src]="getVideo(item.FileUrls)"
                                  type="video/mp4"
                                  frameborder="0"
                                >
                                </iframe>
                              </figure> -->
                            </section>
                          </div>
                          <div
                            (click)="openDialogVideo(item.FileUrls)"
                            class="pointer video-title"
                          >
                            {{ item.Name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>
              </kendo-tabstrip>
            </div>
            <!-- <div class="post-list">
              <div
                [className]="
                  'col-sm-6' +
                  ' col-12' +
                  ' col-md-' +
                  bootstrapNum +
                  ' pointer top-new-item'
                "

                *ngFor="let item of  pagedTopnews"
                (click)="redirectToDetail(item.UrlPath)"
              >
                <div class="top-part">
                  <figure
                    class="img-figure"
                    [ngClass]="setH()"
                  >
                    <a [href]="getDetailUrl(item.UrlPath)" class="post-image">
                      <img
                        *ngIf="item.ImageUrl && !item.DefaultFlg"
                        class="post-image"
                        [src]="appService.apiRoot + item.ImageUrl"
                        alt="post-image"
                      />

                      <img
                        *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined"
                        class="post-image-default"
                        src="../../assets/images/default.png"
                        alt="post-image"
                      />
                    </a>
                  </figure>
                  <a [href]="getDetailUrl(item.UrlPath)" class="title">
                    <p class="title" *ngIf="item.Title">
                      {{
                        item.Title.length > 100
                          ? item.Title.slice(0, 100) + "..."
                          : item.Title
                      }}
                    </p>
                  </a>
                  <p class="title" *ngIf="!item.Title"></p>
                </div>
                <div class="time-clock" *ngIf="templateType == 1">
                  <img
                    class="icon-clock"
                    src="../../assets/images/icon-clock.png"
                    alt="icon-clock"
                  />
                  <p class="time-text" *ngIf="item.CreateAt">
                    {{ item.CreateAt | date: "dd-MM-yyyy" }}
                  </p>
                  <p class="time-text" *ngIf="!item.CreateAt"></p>
                </div>
              </div>
              <kendo-datapager
                [style.width.%]="100"
                [pageSize]="pageSize"
                [skip]="skip"
                [total]="total"
                (pageChange)="onPageChange($event)"
                *ngIf="isViewAllList"
              >
              </kendo-datapager>
            </div> -->
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
            </div> -->
    </div>
  </div>
  <kendo-dialog
    *ngIf="manageDialog"
    (mousedown)="$event.stopPropagation()"
    class="dialog1 router-outlet-content window-center dialog-info dialog-width"
  >
    <div class="dialog1 news-template-26-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
        }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
        }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close-button.png"
          alt=""
          (click)="onClose($event)"
        />
      </div>
    </div>
    <app-news-template-26-dialog
      *ngIf="editFlg == true"
      [request_AccountID]="request_AccountID"
      [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)"
      [galleryData]="galleryData"
      [editFlg]="editFlg"
      [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID"
      (onSave)="this.onSaveData()"
      (onChangetemp1)="this.changeTemp1($event)"
    >
    </app-news-template-26-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span
          [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"
        ></span>
      </div>
      <div>
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>

  <kendo-dialog
    *ngIf="isShowDialogVideo"
    (mousedown)="$event.stopPropagation()"
    class="dialog2 router-outlet-content window-center dialog-info dialog-width-video koverley2"
  >
    <div class="opend-dialog-video close-div2 dialog2">
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img
          class="close-icon"
          src="../../assets/images/close.png"
          alt=""
          (click)="onCloseDialogVideo()"
        />
      </div>
    </div>
    <app-opend-dialog-video [srcURL]="srcURL"> </app-opend-dialog-video>
  </kendo-dialog>
</article>
