
<article class="main-page" (window:resize)="onResize($event)">
  <div class="row">
    <div class="col-md-1 col-12"></div>
    <div class="col-md-10 col-12">
      <div class="container-fluid">
        <div class="body-list">
          <div class="layout-box" *ngFor="let group of pageBody, let bodyIndex = index" [style.width.%]="innerWidth>768 ? group.layout : 100                            ">
            <div class="layout-list" id={{group.id}}>
              <div class="body-box" [style.width.%]="100" *ngFor="let item of group.items, let i = index">

                <app-image-slider-home *ngIf="item?.FrameID == 0" [isAdminFlg]="isAdminFlg" [banner]="item">
                </app-image-slider-home>

                <app-news-template-1 *ngIf="item?.FrameID == 1" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-1>

                <app-news-template-2 *ngIf="item?.FrameID == 2" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-2>

                <app-news-template-3 *ngIf="item?.FrameID == 3" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-3>

                <app-news-template-4 *ngIf="item?.FrameID == 4" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-4>

                <app-news-template-5 *ngIf="item?.FrameID == 5" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-5>

                <app-news-template-6 *ngIf="item?.FrameID == 6" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-6>

                <app-news-template-a *ngIf="item?.FrameID == 7" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-a>

                <app-news-template-b *ngIf="item?.FrameID == 8" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-b>

                <app-news-template-c *ngIf="item?.FrameID == 9" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-c>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-12"></div>
  </div>
</article>

