import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
} from '@angular/core';
import { parse } from 'querystring';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { $ } from 'protractor';
@Component({
  selector: 'app-image-slider-a',
  templateUrl: './image-slider-a.component.html',
  // styleUrls: ['./image-slider-a.component.scss']
})
export class ImageSliderAComponent implements OnInit, AfterViewInit {
  @Input() isAdminFlg;
  @Input() ID;
  @Input() itemTemplate;
  @Input() templateID;
  @Input() pageSelectedID;
  @Input() OrderIdx;
  @Input() banner;
  @Input() newsDecorationIndex: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  info: any = [];
  ImageUrl: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate_1 = new EventEmitter();
  @Output() deleteBodyItem = new EventEmitter();
  @ViewChild('scrollview') scrollview;

  statBoxTitle = "Thống kê";
  statDescription = "Số doanh nghiệp trong khu công nghệ cao";
  statNumber = 120;
  statUnit = "Doanh nghiệp";
  hotline = "028.3.7360293";

  items: any[] = [
    { Title: 'E-Commerce item 1', ImageUrl: 'https://bit.ly/2VlsHZ8' },
  ];
  galleryData: any;
  isLoadedBannerRightSide = false;
  dataImageSView: Array<{ Title: string; Link: string; ImageUrl: string }> = [];
  isShowMenu = false;
  editFlg = false;
  dltFlg = false;
  manageDialog = false;
  isGallery: boolean = false;
  public widthSView = '100%';
  public heightSView = '445px';
  public paused = false;
  private interval;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService
  ) {}

  async ngOnInit() {
    if (this.banner.Info.includes('ImageUrl')) {
      this.info = JSON.parse(this.banner.Info);
      this.isLoadedBannerRightSide = true;
    }
    // else {
    //   this.info = null;
    // }
    this.isLoadedBannerRightSide = true;
    this.galleryData = {
      OrderIdx: this.OrderIdx,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };

    if (this.scrollview) {
      setInterval(() => {
        this.scrollview.next();
      }, 5000);
    }
  }

  async ngAfterViewInit() {
    this.interval = setInterval(() => {
      if (!this.paused) {
        this.scrollview.next();
      }
    }, 5000);
  }

  getLink(link) {
    // window.location.href = 'https://' + item.Link;
    window.open(link, '_blank');
  }

  getFullUrl(url: string) {
    return this.appService.apiRoot + url;
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }

  // addComponent() {
  //   this.editFlg = false;
  //   this.manageDialog = true;
  //   this.isShowMenu = false;
  // }

  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }

  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }
  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }
  addNewBanner(e) {
    this.isLoadedBannerRightSide = e;
  }

  deleteAllBanner(e) {
    this.isLoadedBannerRightSide = e;
  }

  onSave(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    let newTemp = {Info: e.Info,
      Saved: 1,
      pageBodyIndex: this.pageBodyIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      index: this.newsDecorationIndex};
    this.changeTemplate_1.emit(newTemp);
    this.editFlg = false;
    this.manageDialog = false;
  }

  onClose(e)
  {
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  isGalleryDialog(e){
    this.isGallery = e;
  }
  onGoBack(){
    this.isGallery = false;

  }
}
