<section class="quiz-search ">
    <div  class="row">
        <div class="col-md-10 col-8 margin30" *ngIf="!isQuestionDetail">
            <div [ngClass]="'control control-search'">
                <input kendoTextBox class="search-place" [(ngModel)]="searchOption.searchText" [placeholder]="'Quiz_Search_Label'|translate" (keypress)="onSearchKeyPress($event)" />
                <button (click)="onRemoveSearchText();" [primary]="true" [look]="'outline'" type="button" class="xbutton-dialog close"
                  class="btn-remove btn-remove-replace-btn-search" *ngIf="searchOption.searchText !== ''">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="col-md-2 col-4 margin30 float-right" *ngIf="!isQuestionDetail">
            <button  class="btn-search" (click)="onSearch()"  (keypress)="onSearchKeyPress($event)" >
                <span>{{ 'Quiz_View_More' | translate }}</span>
            </button>
        </div>
        <!-- Danh sach top 5 cau hoi cua moi linh vuc -->
        <div class="col-md-12 col-12 list-quiz" *ngIf="isAllCategories && !isQuestionDetail">
            <div *ngFor="let category of questions">
                <div class="break-line"></div>
                <div class="category-title1"> {{ category.categoryName}}</div>
                <div class="row question-row" *ngFor="let question of category.questions">
                    <div class="icon-box">
                        <img src="../../assets/images/question-water-icon.png" alt="water-icon" class="water-icon">
                    </div>                   
                    <div class="question-name" (click)=onCellClick(question.ID)>{{ question.Name}}</div>
                </div>
                <div class="view-more-box">
                    <button  class="btn-search btn-view-more" (click)="onViewMore(category.categoryID)"  (keypress)="onSearchKeyPress($event)" >
                        <span>{{ 'Quiz_View_More' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- Tat ca cau hoi cua linh vuc da chon -->
        <div class="col-md-12 col-12 list-quiz" *ngIf="!isAllCategories && !isQuestionDetail">
            <div class="category-title1">{{selectedCategoryName}}</div>
            <div class="row question-row"  *ngFor="let item of pagedQuestion ; let i = index">
                <div class="icon-box">
                    <img src="../../assets/images/question-water-icon.png" alt="water-icon" class="water-icon">
                </div>                   
                <div class="question-name"  (click)=onCellClick(item.ID)>{{ item.Name}}</div>
            </div>   
            <kendo-datapager *ngIf= 'questionSearch && questionSearch.length > pageSize'
                [style.width.%]="100"
                [pageSize]="pageSize"        
                [pageSizeValues]="false"
                [skip]="skip"
                [total]="total"
                [info]="false"
                (pageChange)="onPageChange($event)"
            >
            </kendo-datapager>                 
        </div>
        
        <!-- Chi tiet cau hoi va tra loi -->
        <div class="col-md-12 col-12 quiz-search-detail" *ngIf="isQuestionDetail">
            <div class="row">
                <p>{{ quizDetail.Name}}</p>        
            </div>
            <div class="row">
                <span>{{quizDetail.AccountName}} - {{quizDetail.CreateAt | date: "HH:mm"}} ngày {{quizDetail.CreateAt | date: "dd/MM/yyyy"}}</span>
            </div>
            <div class="row">
                <div class="break-line"></div>
                <div class="quiz-box">
                    <div class="icon-box">
                        <div class="question-icon-box"><img src="../../assets/images/icon-question.png" alt="" class="question-icon"></div>                       
                    </div>
                    <div class="question-box">
                        <div class="question-title">{{ 'Quiz_Question_Title' | translate }}</div>
                        <div class="question-detail">{{quizDetail.Description}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="break-line"></div>
                <div class="quiz-box">
                    <div class="icon-box">
                        <div class="answer-icon-box"><img src="../../assets/images/white-question-icon.png" alt="" class="white-question-icon"></div>                       
                    </div>
                    <div class="question-box">
                        <div class="question-title">{{ 'Quiz_Answer_Title' | translate }}</div>
                        <div class="question-detail">{{quizDetail.AnswerDescription}}</div>
                        <div class="file-attach">{{ 'Quiz_Answer_File_Attach' | translate }}</div>
                        <div *ngIf="filesAttach.length !== 0" >
                            <div *ngFor="let item of filesAttach">
                                <img src="../../assets/images/attach-file-icon.png" alt="" class="attach-file-icon">
                                <a [href]="urlDownload + item"></a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"></div>
        </div>
    </div>
</section>