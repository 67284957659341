import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  AfterViewInit,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from '../services/app.consts';
import { AppLanguage } from '../services/app.language';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AppService } from '../services/app.service';
import videojs from 'video.js';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { AppSwal } from 'src/app/services/app.swal';
import { Router } from '@angular/router';
import { EventEmitterService } from '../event-emitter.service';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
  CdkDropList,
  CdkDrag,
  CdkDragMove,
  CdkDragRelease,
} from '@angular/cdk/drag-drop';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-template-page',
  templateUrl: './template-page.component.html',
  //styleUrls: ['./template-page.component.scss'],
})
export class TemplatePageComponent implements OnInit, AfterViewInit {
  bannerRightSide: Array<{ Title: string; ImageUrl: string; UrlPath: string }>;
  @Output() dltTemplate = new EventEmitter();
  @Output() galleryManageOpened = new EventEmitter<boolean>();
  @ViewChild('content_embed') content_embed: any;

  LAYOUT_1 = 1;
  LAYOUT_2 = 2;
  LAYOUT_3 = 3;
  LAYOUT_4 = 4;
  LAYOUT_5 = 5;
  LAYOUT_6 = 6;
  urlSafe: SafeResourceUrl;
  url: any;
  PageSelectedID: any;
  domain: string;
  loading = false;
  topNews: Array<{
    Title: string;
    UpdateAt: string;
    CreateAt: string;
    ImageUrl: string;
    UrlPath: string;
  }>;
  categories: Array<{
    Name: string;
    ID: string;
    posts: {
      ID: string;
      Title: string;
      UpdateAt: string;
      ImageUrl: string;
      UrlPath: string;
    }[];
  }>;
  postDetail: string;
  isLoadedTopNews: boolean = false;
  isLoadedBannerRightSide: boolean = false;
  isLoadedNewsByCategory: boolean = false;
  isLoaded: boolean = false;
  extension: string[] = ['.mp4', '.ogg'];
  DialogAddExcel = false;
  player: videojs.Player;
  isBrowser: any;
  dataErr: any;
  menu: any;
  dataMenu: any;
  tabID = 1;
  headerInfo: any;
  footerInfo: any;
  templates: any;
  TemplateID: any;
  page: any;
  urlPreview: string;
  categoriesList = [];
  pageList: any;
  //isactive = false;
  headerNavMenu: any;
  dltFlg = false;
  dltType: string;
  dltIndex: number;
  pageTemp = { header: null, body: null, footer: null };
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  bodyWidth = 1140;

  selectedLangID = AppConsts.defaultLangID;
  selectedFont: any;
  fontList: Array<{ Name: string, ID: string }> = [];
  fontListFilter: Array<{ Name: string, ID: string }> = [];

  templateID: any;

  pageHeader: any;
  // pageBody = [{id: 0, items :['0', '2']}, {id: 1, items :['1', '3']}];
  pageBody = [];
  pageFooter = [];
  itemsHeader = ['1','5'];
  itemsHeader2 = ['2','4'];
  itemsLayout = [
    {
      LayoutID: this.LAYOUT_1,
      Value: [100],
    },
    {
      LayoutID: this.LAYOUT_3,
      Value: [69, 29],
    },
    {
      LayoutID: this.LAYOUT_5,
      Value: [39, 59],
    },
  ];
  itemsLayout2 = [
    {
      LayoutID: this.LAYOUT_2,
      Value: [79, 19],
    },
    {
      LayoutID: this.LAYOUT_4,
      Value: [49, 25, 24],
    },
    {
      LayoutID: this.LAYOUT_6,
      Value: [74, 24],
    }
  ];

  itemsBody = ['1', '3', '-2', '9', '-1', '13', '15','17','19','21','23','25','29',
    AppConsts.NewsTemplateNumber.NEWS_19,
    AppConsts.NewsTemplateNumber.NEWS_21,
    AppConsts.NewsTemplateNumber.NEWS_23,
    AppConsts.NewsTemplateNumber.NEWS_25,
    AppConsts.NewsTemplateNumber.NEWS_27,
    AppConsts.NewsTemplateNumber.NEWS_29,
    AppConsts.NewsTemplateNumber.NEWS_31,
    AppConsts.NewsTemplateNumber.NEWS_33,
    AppConsts.NewsTemplateNumber.NEWS_35,
    AppConsts.NewsTemplateNumber.NEWS_37,
    AppConsts.NewsTemplateNumber.NEWS_39,
    AppConsts.NewsTemplateNumber.NEWS_41,
    AppConsts.NewsTemplateNumber.NEWS_43,
    AppConsts.NewsTemplateNumber.NEWS_45,
    AppConsts.NewsTemplateNumber.NEWS_47,
    AppConsts.NewsTemplateNumber.NEWS_49,
    AppConsts.NewsTemplateNumber.NEWS_51,
    AppConsts.NewsTemplateNumber.NEWS_53,
    AppConsts.NewsTemplateNumber.NEWS_55,
    AppConsts.NewsTemplateNumber.NEWS_57,
  ];
  itemsBody2 = ['2', '4', '6', '8', '0', '14', '16','18','20','22','24','26','28',
    AppConsts.NewsTemplateNumber.NEWS_20,
    AppConsts.NewsTemplateNumber.NEWS_22,
    AppConsts.NewsTemplateNumber.NEWS_24,
    AppConsts.NewsTemplateNumber.NEWS_26,
    AppConsts.NewsTemplateNumber.NEWS_28,
    AppConsts.NewsTemplateNumber.NEWS_30,
    AppConsts.NewsTemplateNumber.NEWS_32,
    AppConsts.NewsTemplateNumber.NEWS_34,
    AppConsts.NewsTemplateNumber.NEWS_36,
    AppConsts.NewsTemplateNumber.NEWS_38,
    AppConsts.NewsTemplateNumber.NEWS_40,
    AppConsts.NewsTemplateNumber.NEWS_42,
    AppConsts.NewsTemplateNumber.NEWS_44,
    AppConsts.NewsTemplateNumber.NEWS_46,
    AppConsts.NewsTemplateNumber.NEWS_48,
    AppConsts.NewsTemplateNumber.NEWS_50,
    AppConsts.NewsTemplateNumber.NEWS_52,
    AppConsts.NewsTemplateNumber.NEWS_54,
    AppConsts.NewsTemplateNumber.NEWS_56,
  ];
  itemsFooter = ['2','3'];
  itemsFooter2 = ['2'];
  bodyParam : any;
  NEWS_19 = AppConsts.NewsTemplateNumber.NEWS_19;
  NEWS_20 = AppConsts.NewsTemplateNumber.NEWS_20;
  NEWS_21 = AppConsts.NewsTemplateNumber.NEWS_21;
  NEWS_22 = AppConsts.NewsTemplateNumber.NEWS_22;
  NEWS_23 = AppConsts.NewsTemplateNumber.NEWS_23;
  NEWS_24 = AppConsts.NewsTemplateNumber.NEWS_24;
  NEWS_25 = AppConsts.NewsTemplateNumber.NEWS_25;
  NEWS_26 = AppConsts.NewsTemplateNumber.NEWS_26;
  NEWS_27 = AppConsts.NewsTemplateNumber.NEWS_27;
  NEWS_28 = AppConsts.NewsTemplateNumber.NEWS_28;
  NEWS_29 = AppConsts.NewsTemplateNumber.NEWS_29;
  NEWS_30 = AppConsts.NewsTemplateNumber.NEWS_30;
  NEWS_31 = AppConsts.NewsTemplateNumber.NEWS_31;
  NEWS_32 = AppConsts.NewsTemplateNumber.NEWS_32;
  NEWS_33 = AppConsts.NewsTemplateNumber.NEWS_33;
  NEWS_34 = AppConsts.NewsTemplateNumber.NEWS_34;
  NEWS_35 = AppConsts.NewsTemplateNumber.NEWS_35;
  NEWS_36 = AppConsts.NewsTemplateNumber.NEWS_36;
  NEWS_37 = AppConsts.NewsTemplateNumber.NEWS_37;
  NEWS_38 = AppConsts.NewsTemplateNumber.NEWS_38;
  NEWS_39 = AppConsts.NewsTemplateNumber.NEWS_39;
  NEWS_41 = AppConsts.NewsTemplateNumber.NEWS_41;
  NEWS_40 = AppConsts.NewsTemplateNumber.NEWS_40;
  NEWS_42 = AppConsts.NewsTemplateNumber.NEWS_42;
  NEWS_43 = AppConsts.NewsTemplateNumber.NEWS_43;
  NEWS_45 = AppConsts.NewsTemplateNumber.NEWS_45;
  NEWS_44 = AppConsts.NewsTemplateNumber.NEWS_44;
  NEWS_46 = AppConsts.NewsTemplateNumber.NEWS_46;
  NEWS_47 = AppConsts.NewsTemplateNumber.NEWS_47;
  NEWS_48 = AppConsts.NewsTemplateNumber.NEWS_48;
  NEWS_49 = AppConsts.NewsTemplateNumber.NEWS_49;
  NEWS_50 = AppConsts.NewsTemplateNumber.NEWS_50;
  NEWS_51 = AppConsts.NewsTemplateNumber.NEWS_51;
  NEWS_52 = AppConsts.NewsTemplateNumber.NEWS_52;
  NEWS_53 = AppConsts.NewsTemplateNumber.NEWS_53;
  NEWS_54 = AppConsts.NewsTemplateNumber.NEWS_54;
  NEWS_55 = AppConsts.NewsTemplateNumber.NEWS_55;
  NEWS_56 = AppConsts.NewsTemplateNumber.NEWS_56;
  NEWS_57 = AppConsts.NewsTemplateNumber.NEWS_57;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private appSwal: AppSwal,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  ngAfterViewInit(): void {
    // document.getElementsByClassName("template-page").item[0].style.background = 'red';
    // document.getElementById("home-container").style.background = 'red';
    // this.spanTemplates();
  }

  async ngOnInit() {
    // this.getBannerRightSide();
    // this.getTopNews();
    this.domain = this.appService.apiRoot;
    this.postDetail = AppConsts.page.postDetail;
    this.title.setTitle(this.translate.instant('Title-Page'));
    await this.onInitData();
    this.onGetCategories();
    this.getTemplate();
    this.getdataItems();
    this.urlPreview = AppConsts.page.previewTemplatePage;

    // this.pageHeader = JSON.parse(localStorage.getItem('pageHeader'));
    // if (
    //   this.pageHeader === null||
    //   !this.itemsHeader.includes(this.pageHeader[0])
    // ) {
    //   this.pageHeader = [];
    // }
    // this.pageFooter = JSON.parse(localStorage.getItem('pageFooter'));
    // if (
    //   this.pageFooter === null ||
    //   !this.itemsFooter.includes(this.pageFooter[0])
    // ) {
    //   this.pageFooter = [];
    // }
    let kItem = document.getElementsByClassName('k-item')[this.tabID];
    if (kItem) {
      kItem.classList.add('active');
    }

    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.invokeDeleteBodyItem.subscribe(
          (index: any) => {
            this.dltBodyItem(index);
          }
        );
    }
    // document.getElementsByClassName('nav-item')[0].classList.add('active');
  }

  onDltBodyItem(event) {
    if (event) {
      this.dltBodyItem(event);

    }
  }

  async onInitData() {
    this.getHeader();
    this.getFooter();
    this.getFontListFilter();
    this.getDataTemplate();
  }

  updateTemplate(): void {
    // localStorage.setItem(
    //   'pagePreview',
    //   JSON.stringify({
    //     pageBody: this.pageBody,
    //     pageHeader: this.pageHeader,
    //     pageFooter: this.pageFooter,
    //   })
    // );
  }

  // async getBannerRightSide() {
  //   const result = await this.appService.doGET(
  //     'api/Portal/GetBannerRightSide',
  //     { langID: this.language.get() }
  //   );
  //   if (result && result.Status === 1) {
  //     this.bannerRightSide = result.Data;
  //     this.isLoadedBannerRightSide = true;
  //     this.isPageLoaded();
  //   }
  // }

  async getTemplate() {
    const result = await this.appService.doGET('api/PortalDesign', {
      templateID: null,
      menuID: this.PageSelectedID ? this.PageSelectedID : this.GUID_EMPTY,

    });
    if (result && result.Status === 1 && result.Data) {
      this.TemplateID = result.Data.TemplateID;
      this.templates = result.Data.TemplateDetails;

      var bodys = [];
      for (let i = 0; i < this.templates.length; i++) {
        var item = this.templates[i];
        var groupId = item.GroupID;
        var layout = item.Layout;
        let index = bodys.findIndex(
          (x) => x.id == groupId && x.layout == layout
        );
        if (index != -1) {
          bodys[index].items.push(item);
        } else {
          var itemBody = {
            id: groupId,
            layout: layout,
            items: [item],
          };
          bodys.push(itemBody);
          // if (
          //   layout < 100 &&
          //   this.templates.findIndex((x) => x.Layout == 100 - layout) == -1
          // ) {
            // không tồn tại layout nào chung hàng thì tự add thêm
          //   bodys.push({
          //     id: groupId + 100,
          //     layout: 100 - layout,
          //     items: [],
          //   });
          // }
        }
      }
      this.pageBody = bodys;
      

    }
    // console.log("pageBody before: ", this.pageBody)
    var tempBody = [];
    var tempItems = [];
    var totalLayout = 0;
    for (let i = 0; i < this.pageBody.length; i++) {
      if (totalLayout + this.pageBody[i].layout <= 100) {
        tempItems.push(this.pageBody[i]);
        totalLayout += this.pageBody[i].layout;
      }
      else {
        tempBody.push(tempItems);
        tempItems = [];
        tempItems.push(this.pageBody[i]);
        totalLayout = this.pageBody[i].layout;
      }
    }
    if (tempItems.length != 0) {
      tempBody.push(tempItems);
    }
    this.pageBody = tempBody;
    console.log("pageBody during: ", this.pageBody)
  }

  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/Search', {
      searchText: '',
      langID: this.language.get()
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data;
    }
  }

  async getTopNews() {
    const result = await this.appService.doGET('api/Portal/GetTopNews', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1) {
      this.topNews = result.Data.topNews;
      this.categories = result.Data.newsByCategory;
      this.isLoadedTopNews = true;
      this.isPageLoaded();
    }
  }

  isPageLoaded() {
    if (this.isLoadedBannerRightSide && this.isLoadedTopNews) {
      this.isLoaded = true;
    }
  }

  getFullUrl(url: string) {
    return this.appService.apiRoot + url;
  }

  getFileName(IconUrl) {
    var nameFile = '';
    if (IconUrl != '' && IconUrl != null) {
      var urlArr = IconUrl.split('/');
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != '' && nameFile != null) {
          var indexOfFirst = nameFile.indexOf('_');
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  isVideo(name: string) {
    let result = false;
    if (name) {
      this.extension.forEach((e) => {
        if (name.includes(e)) {
          result = true;
        }
      });
    }
    return result;
  }

  onDotsString(string, maxValue) {
    var txtLength = 0;
    var orgString = string;
    if (orgString) {
      //dem ki tu hoa thuong
      var ascii = 0;
      var lastIdx = 0;
      for (let i = 0; i < orgString.length; i++) {
        ascii = orgString[i].charCodeAt(0);
        if (ascii >= 65 && ascii <= 90) {
          txtLength += 1.21875;
        } else {
          txtLength += 1;
        }
        lastIdx = i;
        if (txtLength >= maxValue) {
          break;
        }
      }
      if (txtLength <= maxValue && lastIdx == orgString.length - 1) {
        return string;
      }
      var idxSpace = orgString.lastIndexOf(' ', lastIdx);
      var newString = orgString.slice(0, idxSpace) + ' ...';
      return newString;
    }
  }

  async getFooter() {
    const result = await this.appService.doGET('api/PortalFooter', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.footerInfo = result.Data.Data;
      this.pageFooter = [result.Data.FooterType];
      //localStorage.setItem('pageFooter', JSON.stringify(this.pageFooter));
    }
  }

  async getHeader() {
    const result = await this.appService.doGET('api/PortalHeader', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1 && result.Data) {
      this.headerInfo = result.Data.Data;
      this.pageHeader = [result.Data.HeaderType];
      // localStorage.setItem('pageHeader', JSON.stringify(this.pageHeader));
    }

  }

  updateMeta(): void {
    var description =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    var keywords =
      'department of external relations, department of external relations ho chi minh city, ministry of foreign affairs, songoaivu, so ngoai vu, ngoai vu, mofahcm, songoaivutphcm, songoaivutphcm.com, Sở Ngoại Vụ TP.HCM, hợp pháp hóa lãnh sự, chứng nhận lãnh sự, hợp pháp hóa giấy tờ, lãnh sự, hợp pháp hóa';
    var url = window.location.origin;
    var title = this.translate.instant('Title-Page');
    var ogDescription =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    var image = '';
    if (this.footerInfo != null) {
      description =
        this.translate.instant('ogDescription') +
        '\n' +
        this.translate.instant('Address') +
        this.footerInfo.Info.Address;
      ogDescription =
        this.translate.instant('ogDescription') +
        '\n' +
        this.translate.instant('Address') +
        this.footerInfo.Info.Address;
      image = this.appService.apiRoot + this.footerInfo.Image.ImageUrl;
    }
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'og:url', content: url });
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:image', content: image });
    this.meta.updateTag({ name: 'og:description', content: ogDescription });
  }
  onImgError(event) {
    event.target.src = AppConsts.DEFAULT_IMAGE;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      if (event.container.id == 'itemsLayout') {
        this.itemsLayout = [
          {
            LayoutID: this.LAYOUT_1,
            Value: [100],
          },
          {
            LayoutID: this.LAYOUT_3,
            Value: [69, 29],
          },
          {
            LayoutID: this.LAYOUT_1,
            Value: [39,59],
          },
        ];
        this.itemsLayout2 = [
          {
            LayoutID: this.LAYOUT_2,
            Value: [80, 20],
          },
          {
            LayoutID: this.LAYOUT_4,
            Value: [49, 25, 24],
          },
          {
            LayoutID: this.LAYOUT_6,
            Value: [74, 24],
          }
        ];
      } else if (event.container.id == 'itemsBody') {

        this.itemsBody = ['1', '3', '-2', '9', '-1', '13', '15','17','19','21','23','25',,
          this.NEWS_19,
          this.NEWS_21,
          this.NEWS_23,
          this.NEWS_25,
          this.NEWS_27,
          this.NEWS_29,
          this.NEWS_31,
          this.NEWS_33,
          this.NEWS_35,
          this.NEWS_37,
          this.NEWS_39,
          this.NEWS_41,
          this.NEWS_43,
          this.NEWS_45,
          this.NEWS_47,
          this.NEWS_49,
          this.NEWS_51,
          this.NEWS_53,
          this.NEWS_55,
          this.NEWS_57,
        ];
        this.itemsBody2 = ['2', '4', '6', '8', '0', '14', '16', '18','20','22','24','26','28','29',
          this.NEWS_20,
          this.NEWS_22,
          this.NEWS_24,
          this.NEWS_26,
          this.NEWS_28,
          this.NEWS_30,
          this.NEWS_32,
          this.NEWS_34,
          this.NEWS_36,
          this.NEWS_38,
          this.NEWS_40,
          this.NEWS_42,
          this.NEWS_44,
          this.NEWS_46,
          this.NEWS_48,
          this.NEWS_50,
          this.NEWS_52,
          this.NEWS_54,
          this.NEWS_56,
        ];
      }
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      //localStorage.setItem('pageBody', JSON.stringify(this.templates));
    } else if (this.correspond(event)) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.itemsHeader = ['1','3','5'];
      this.itemsHeader2 = ['2','4'];
      // this.itemsLayout = [{
      //   LayoutID: this.LAYOUT_1,
      //   Value: [100]
      // }];
      // this.itemsBody = ['Text', 'Number', 'Checkbox', 'Datepicker'];
      this.itemsFooter = ['2','3'];
      this.itemsFooter2 = ['2'];
      // localStorage.setItem('pageHeader', JSON.stringify(this.pageHeader));
      // localStorage.setItem('pageBody', JSON.stringify(this.templates));
      //localStorage.setItem('pageFooter', JSON.stringify(this.pageFooter));
      this.updateTemplate();
    }


  }

  dropItem(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      // let i = this.pageBody.findIndex(x => x.id == event.container.id)
      // for (let index = 0; index < this.pageBody[i].items.length; index++) {
      //   this.pageBody[i].items[index].Width = this.pageBody[i].layout[index];
      // }
    } else if (event.previousContainer.id == 'itemsBody') {
      for (let index = 0; index < this.pageBody.length; index++) {
        let i = this.pageBody[index].findIndex((x) => x.id == event.container.id);
        if (i != -1) {
          this.pageBody[index][i].items.splice(event.currentIndex, 0, {
            GroupIndex: i,
            GroupID: this.pageBody[index][i].id,
            Layout: this.pageBody[index][i].layout,
            ID: this.GUID_EMPTY,
            Info: '{"TopNumber":3,"MenuID":""}',
            OrderIdx: 1,
            MenuID: this.PageSelectedID,
            Width: 100,
            FrameID:
              event.previousIndex && this.itemsBody[event.previousIndex]
                ? this.itemsBody[event.previousIndex]
                : '1',
            LangID: this.language.get(),
            TemplateID: this.TemplateID,
            Type: 3 // frame
          });
        }
      }
    } else if (event.previousContainer.id == 'itemsBody2') {
      for (let index = 0; index < this.pageBody.length; index++) {
        let i = this.pageBody[index].findIndex((x) => x.id == event.container.id);
        if (i != -1) {
          this.pageBody[index][i].items.splice(event.currentIndex, 0, {
            GroupIndex: i,
            GroupID: this.pageBody[index][i].id,
            Layout: this.pageBody[index][i].layout,
            ID: this.GUID_EMPTY,
            Info: '{"TopNumber":3,"MenuID":""}',
            OrderIdx: 1,
            MenuID: this.PageSelectedID,
            Width: 100,
            FrameID:
              event.previousIndex && this.itemsBody2[event.previousIndex]
                ? this.itemsBody2[event.previousIndex]
                : '2',
            LangID: this.language.get(),
            TemplateID: this.TemplateID,
            Type: 3 // frame
          });
        }
      }
    } else{
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      for (let index = 0; index < this.pageBody.length; index++) {
        let i = this.pageBody[index].findIndex((x) => x.id == event.container.id);
        if (i != -1) {
          this.pageBody[index][i].items[event.currentIndex].GroupIndex = i;
          this.pageBody[index][i].items[event.currentIndex].GroupID = this.pageBody[index][i].id;
          this.pageBody[index][i].items[event.currentIndex].Layout = this.pageBody[index][i].layout;
        }
      }
    }
    this.updateTemplate();
  }

  getConnectedList(): any[] {
    let map = [];
    for (let index = 0; index < this.pageBody.length; index++) {
      map = map.concat(this.pageBody[index].map((x) => `${x.id}`));
    }
    return map;
  }

  dropGroup(event: CdkDragDrop<string[]>) {
    console.log("event dropGroup", event);

    if (event.previousContainer === event.container) {
      moveItemInArray(this.pageBody, event.previousIndex, event.currentIndex);
    } else if (event.previousContainer.id == 'itemsLayout') {
      // console.log(event)
      var tempLayout = [];
      for (
        let index = 0;
        index < this.itemsLayout[event.previousIndex].Value.length;
        index++
      ) {
        var map = this.getConnectedList();
        var tempID = map.length > 0 ? Math.max(...map) + 1 + index : 0 + index;
        tempLayout.push({
          id: tempID, // <--
          layout: this.itemsLayout[event.previousIndex].Value[index],
          items: [],
        });
      }
      this.pageBody.splice(event.currentIndex, 0, tempLayout);
    } else if (event.previousContainer.id == 'itemsLayout2') {
      // console.log(event)
      var tempLayout = [];
      for (
        let index = 0;
        index < this.itemsLayout2[event.previousIndex].Value.length;
        index++
      ) {
        var map = this.getConnectedList();
        var tempID = map.length > 0 ? Math.max(...map) + 1 + index : 0 + index;
        tempLayout.push({
          id: tempID, // <--
          layout: this.itemsLayout2[event.previousIndex].Value[index],
          items: [],
        });
      }
      this.pageBody.splice(event.currentIndex, 0, tempLayout);
    }
    console.log("this.pageBody dropGroup",this.pageBody);
  }

  onDlt(type: string, index: number) {
    this.dltFlg = true;
    this.dltType = type;
    this.dltIndex = index;
  }

  onDeleteGallery() {
    if (this.dltType == 'header') {
      this.pageHeader.splice(this.dltIndex, 1);
      // localStorage.setItem('pageHeader', JSON.stringify(this.pageHeader));
    }
    else if (this.dltType == 'body') {
      this.pageBody.splice(this.dltIndex, 1);
    }
    else if (this.dltType == 'footer') {
      this.pageFooter.splice(this.dltIndex, 1);
      //localStorage.setItem('pageFooter', JSON.stringify(this.pageFooter));
    }
    this.dltFlg = false;
  }

  onClose() {
    this.dltFlg = false;
  }

  dltHeader() {
    this.pageHeader.splice(0, 1);
    // localStorage.setItem('pageHeader', JSON.stringify(this.pageHeader));
  }

  dltBodyLayout(index: number) {
    this.pageBody.splice(index, 1);
  }

  dltBodyItem(index: any) {
    console.log("index at page: ", index);
    console.log(this.pageBody)
    if (!this.pageBody) {
      this.getTemplate();
    }
    if (index != undefined) {
      this.pageBody[index.pageBodyIndex][index.layoutGroupIndex].items.splice(index.index, 1);
      //localStorage.setItem('pageBody', JSON.stringify(this.templates));
      return;
    }
  }

  dltFooter() {
    this.pageFooter.splice(0, 1);
    //localStorage.setItem('pageFooter', JSON.stringify(this.pageFooter));
  }

  noReturnPredicate() {
    return false;
  }

    correspond(event) {
    // if (
    //   event.previousContainer.id === 'itemsBody' &&
    //   'pageLayout'.indexOf(event.container.id)
    // ) {
    //   return true;
    // }
    // if (
    //   event.previousContainer.id === 'itemsLayout' &&
    //   event.container.id === 'pageBody'
    // ) {
    //   return true;
    // }
    if (
      event.container.id === 'pageHeader' &&
      event.container.data?.length >= 1
    ) {
      return false;
    }
    if (
      event.container.id === 'pageFooter' &&
      event.container.data?.length >= 1
    ) {
      return false;
    }
    return true;
  }

  spanTemplates() {
    this.pageBody = [];
    for (let index = 0; index < this.templates.length; index = index + 2) {
      let temp = { id: index / 2, items: [this.templates[index]] };
      if (this.templates[index + 1]) {
        temp.items.push(this.templates[index + 1]);
      }
      this.pageBody.push(temp);
    }
  }

  onTabChanged(e) {
    this.tabID = e.index;

    let tabActive = document.getElementsByClassName('k-item');
    for (let i = 0; i < tabActive.length; i++) {
      if (tabActive[i].classList.contains('active')) {
        tabActive[i].classList.remove('active');
      }
    }
    tabActive[e.index].classList.add('active');

    if (e.index == 1) {
      // tab Chèn
    } else {
      // tab Trang
    }
    // call api
  }
  async getdataItems() {
    this.loading = true;
    const dataRequest = {
      searchText: '',
      Langid: this.language.get(),
    };

    this.loading = false;
  }
  closeDialogAddExcel() {
    document.body.classList.remove('disable-scroll');
    this.DialogAddExcel = false;
  }

  onCheckMenuChange(listDataItem) {
    this.pageList = listDataItem;
    console.log("this.PageSelectedID", this.PageSelectedID);

    if (!this.PageSelectedID) {
      if (this.pageList && this.pageList.length > 0) {
        this.pageList[0].Selected = true;
        this.PageSelectedID = this.pageList[0].ID;
        this.getTemplate();
      }
    }
  }
  navMenuHandle(navMenu) {
    this.headerNavMenu = navMenu;
  }

  onClickItemPage(item?, index?) {
    this.pageList.forEach((element) => {
      if (element.ID == item.ID) {
        element.Selected = true;
      } else {
        element.Selected = false;
      }
    });
    this.PageSelectedID = item.ID;
    this.getTemplate();
  }

  onReload() {
    this.getTemplate();
  }

  onSavePage() {
    this.pageTemp.header = this.pageHeader;
    this.pageTemp.body = this.pageBody;
    this.pageTemp.footer = this.pageFooter;
  }
  onPreview() {
    // localStorage.setItem(
    //   'pagePreview',
    //   JSON.stringify({
    //     pageBody: this.pageBody,
    //     pageHeader: this.pageHeader,
    //     pageFooter: this.pageFooter,
    //   })
    // );
    this.bodyParam = JSON.stringify({
      pageBody: this.pageBody,
      pageHeader: this.pageHeader,
      pageFooter: this.pageFooter,
      fontName: this.fontList.find((s) => s.ID == this.selectedFont).Name,
    });
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([AppConsts.page.previewTemplatePage])
    // );
    const url = this.appService.portalRoot + AppConsts.page.previewTemplatePage + '?bodyParam=' + encodeURIComponent(this.bodyParam);
    window.open(url, '_blank');
  }

  changeTemp_1(newTemp_1: any) {
    this.pageBody[newTemp_1.pageBodyIndex][newTemp_1.layoutGroupIndex].items[newTemp_1.index].Saved = newTemp_1.Saved;
    this.pageBody[newTemp_1.pageBodyIndex][newTemp_1.layoutGroupIndex].items[newTemp_1.index].Info = newTemp_1.Info;
  }

  changeTemp0(newTemp0: any) {
    // let tempInfo = JSON.parse(this.pageBody[newTemp0.pageBodyIndex].items[newTemp0.index].Info);
    // let tempInfo = this.pageBody[newTemp0.pageBodyIndex].items[newTemp0.index].Info;
    // tempInfo = newTemp0.Info;
    this.pageBody[newTemp0.pageBodyIndex][newTemp0.layoutGroupIndex].items[newTemp0.index].Saved = newTemp0.Saved;
    // let tempInfo = this.pageBody[newTemp0.pageBodyIndex].items[newTemp0.index]
    this.pageBody[newTemp0.pageBodyIndex][newTemp0.layoutGroupIndex].items[newTemp0.index].Info = newTemp0.Info;
    // this.pageBody[newTemp0.pageBodyIndex].items[newTemp0.index].Info = JSON.stringify(tempInfo);
  }

  changeTemp1(newTemp1: any) {
    let tempInfo = JSON.parse(this.pageBody[newTemp1.pageBodyIndex][newTemp1.layoutGroupIndex].items[newTemp1.index].Info);
    tempInfo.MenuID = newTemp1.Info.MenuID;
    tempInfo.TopNumber = newTemp1.Info.TopNumber;
    this.pageBody[newTemp1.pageBodyIndex][newTemp1.layoutGroupIndex].items[newTemp1.index].Info = JSON.stringify(tempInfo);
  }

  changeTemp2(newTemp2: any) {
    let tempInfo = JSON.parse(this.pageBody[newTemp2.pageBodyIndex][newTemp2.layoutGroupIndex].items[newTemp2.index].Info);
    //console.log("tempInfo", tempInfo);

    tempInfo.MenuID = newTemp2.Info.MenuID;
    tempInfo.TopNumber = newTemp2.Info.TopNumber;
    this.pageBody[newTemp2.pageBodyIndex][newTemp2.layoutGroupIndex].items[newTemp2.index].Info = JSON.stringify(tempInfo);
  }

  changeTemp3(newTemp3: any) {
    console.log('changeTemp3');
    console.log(newTemp3);

    let tempInfo = JSON.parse(this.pageBody[newTemp3.pageBodyIndex][newTemp3.layoutGroupIndex].items[newTemp3.index].Info);
    tempInfo.MenuID = newTemp3.Info.MenuID;
    tempInfo.TopNumber = newTemp3.Info.TopNumber;
    this.pageBody[newTemp3.pageBodyIndex][newTemp3.layoutGroupIndex].items[newTemp3.index].Info = JSON.stringify(tempInfo);
  }

  changeTemp4(newTemp4: any) {
    let tempInfo = JSON.parse(this.pageBody[newTemp4.pageBodyIndex][newTemp4.layoutGroupIndex].items[newTemp4.index].Info);
    tempInfo = newTemp4.Info;
    this.pageBody[newTemp4.pageBodyIndex][newTemp4.layoutGroupIndex].items[newTemp4.index].Saved = newTemp4.Saved;
    this.pageBody[newTemp4.pageBodyIndex][newTemp4.layoutGroupIndex].items[newTemp4.index].Info = JSON.stringify(tempInfo);
  }

  changeTemp5(newTemp5: any) {
    let tempInfo = JSON.parse(this.pageBody[newTemp5.pageBodyIndex][newTemp5.layoutGroupIndex].items[newTemp5.index].Info);
    tempInfo.Title = newTemp5.title;
    tempInfo.Description = newTemp5.description;
    tempInfo.ImageUrl = newTemp5.imageUrl;
    tempInfo.MenuID = newTemp5.MenuID;
    this.pageBody[newTemp5.pageBodyIndex][newTemp5.layoutGroupIndex].items[newTemp5.index].Info = JSON.stringify(tempInfo);
  }

  changeTemp6(newTemp6: any) {
    let tempInfo = JSON.parse(this.pageBody[newTemp6.pageBodyIndex][newTemp6.layoutGroupIndex].items[newTemp6.index].Info);
    tempInfo.MenuID = newTemp6.subCategories;
    this.pageBody[newTemp6.pageBodyIndex][newTemp6.layoutGroupIndex].items[newTemp6.index].Info = JSON.stringify(tempInfo);
  }

  changeTempA(newTempA: any) {
    let tempInfo = JSON.parse(this.pageBody[newTempA.pageBodyIndex][newTempA.layoutGroupIndex].items[newTempA.index].Info);
    tempInfo.Title = newTempA.title;
    tempInfo.Description = newTempA.description;
    tempInfo.ImageUrl = newTempA.imageUrl;
    tempInfo.MenuID = newTempA.MenuID;
    this.pageBody[newTempA.pageBodyIndex][newTempA.layoutGroupIndex].items[newTempA.index].Info = JSON.stringify(tempInfo);
  }

  changeTempB(newTempB: any) {
    let tempInfo = JSON.parse(this.pageBody[newTempB.pageBodyIndex][newTempB.layoutGroupIndex].items[newTempB.index].Info);
    tempInfo = newTempB.Info;
    this.pageBody[newTempB.pageBodyIndex][newTempB.layoutGroupIndex].items[newTempB.index].Info = JSON.stringify(tempInfo);
  }

  changeTempC(newTempC: any) {
    let tempInfo = JSON.parse(this.pageBody[newTempC.pageBodyIndex][newTempC.layoutGroupIndex].items[newTempC.index].Info);
    tempInfo = newTempC.Info;
    this.pageBody[newTempC.pageBodyIndex][newTempC.layoutGroupIndex].items[newTempC.index].Saved = newTempC.Saved;
    this.pageBody[newTempC.pageBodyIndex][newTempC.layoutGroupIndex].items[newTempC.index].Info = JSON.stringify(tempInfo);
  }

  changeTemp12(newTemp2: any) {
    let tempInfo = JSON.parse(this.pageBody[newTemp2.pageBodyIndex][newTemp2.layoutGroupIndex].items[newTemp2.index].Info);
    tempInfo.MenuID = newTemp2.Info.MenuID;
    tempInfo.TopNumber = newTemp2.Info.TopNumber;
    this.pageBody[newTemp2.pageBodyIndex][newTemp2.layoutGroupIndex].items[newTemp2.index].Info = JSON.stringify(tempInfo);
  }

  changeTempPostDetail(newTemp2: any) {
    let tempInfo = JSON.parse(this.pageBody[newTemp2.pageBodyIndex][newTemp2.layoutGroupIndex].items[newTemp2.index].Info);
    tempInfo.MenuID = newTemp2.Info.MenuID;
    tempInfo.TopNumber = newTemp2.Info.TopNumber;

    this.pageBody[newTemp2.pageBodyIndex][newTemp2.layoutGroupIndex].items[newTemp2.index].Info = JSON.stringify(tempInfo);
  }

  async onClickPublish() {
    let dataRequest = [];
    let order = 0;
    for (let GroupIndex = 0; GroupIndex < this.pageBody.length; GroupIndex++) {
      for (let layoutIndex = 0; layoutIndex < this.pageBody[GroupIndex].length; layoutIndex++) {
        for (
          let index = 0;
          index < this.pageBody[GroupIndex][layoutIndex].items.length;
          index++
        ) {
          let saveFlg = 0;
          let tempInfo;
          if (typeof this.pageBody[GroupIndex][layoutIndex].items[index].Info == 'string') {
            tempInfo = JSON.parse(this.pageBody[GroupIndex][layoutIndex].items[index].Info);
          }

          if (['6', '13', '14', '15', '16', '17', '18','19', '3','20','21','22','23','24','25','26','28','29',
              this.NEWS_19, this.NEWS_20, this.NEWS_21, this.NEWS_22, this.NEWS_23, this.NEWS_24, this.NEWS_25, this.NEWS_26, this.NEWS_27, this.NEWS_28, this.NEWS_29

              , this.NEWS_30, this.NEWS_31, this.NEWS_32, this.NEWS_33, this.NEWS_34, this.NEWS_35, this.NEWS_36, this.NEWS_37, this.NEWS_38, this.NEWS_39, this.NEWS_40,
              this.NEWS_41, this.NEWS_42, this.NEWS_43, this.NEWS_44, this.NEWS_45, this.NEWS_46, this.NEWS_47, this.NEWS_48, this.NEWS_49, this.NEWS_50, this.NEWS_51, 
              this.NEWS_52, this.NEWS_53, this.NEWS_54, this.NEWS_55, this.NEWS_56, this.NEWS_57
            ].includes(this.pageBody[GroupIndex][layoutIndex].items[index].FrameID)) {
            saveFlg = 1;
          }
          if (['0', '4', '9','-1'].includes(this.pageBody[GroupIndex][layoutIndex].items[index].FrameID)) {
            if (this.pageBody[GroupIndex][layoutIndex].items[index].Saved != undefined || this.pageBody[GroupIndex][layoutIndex].items[index].ID != this.GUID_EMPTY) {
              saveFlg = 1;
            }
          }
          else if (tempInfo != null && tempInfo.MenuID != null && tempInfo.MenuID != '') {
            saveFlg = 1;
          }
          // console.log('onClickPublish');
          // console.log(`saveFlg: ${saveFlg}`);
          // console.log(`this.pageBody[GroupIndex][layoutIndex].items[index]: ${this.pageBody[GroupIndex][layoutIndex].items[index]}`)
          if (saveFlg == 1) {
            let temp = this.pageBody[GroupIndex][layoutIndex].items[index];
            temp.GroupID = this.pageBody[GroupIndex][layoutIndex].id;
            temp.GroupIndex = GroupIndex;
            temp.OrderIdx = order;
            temp.Layout = this.pageBody[GroupIndex][layoutIndex].layout;
            dataRequest.push(temp);
            order++;
          }
        }
      }
    }
    
    var params = {
      TemplateDetails: JSON.stringify(dataRequest),
      menuID: this.PageSelectedID ? this.PageSelectedID : this.GUID_EMPTY,
      templateID: this.TemplateID ? this.TemplateID : this.GUID_EMPTY,
      selectedHeader: this.pageHeader[0] ? this.pageHeader[0] : "2",
      selectedfooter: this.pageFooter[0] ? this.pageFooter[0] : "1"
      //TemplateID: '5A543953-B04F-4307-A6B4-000000000000'
    }
    const result = await this.appService.doPOST('api/PortalDesign', params);
    if (result && result.Status === 1) {
      // this.notification.showSuccess(result.Msg);
      this.onReload();
      if (this.selectedFont) {
        this.updateFont();
      }
      // this.setDefault();
      this.galleryManageOpened.emit(true);
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }
  moveToTop(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  async getContentWidth(){
    const result = await this.appService.doGET('api/PortalDesign/GetWidth', {});
    if(result && result.Status === 1 && typeof window !== 'undefined'){
      this.bodyWidth = result.Data;
    }
  }

  async getFontListFilter() {
    const result = await this.appService.doGET('api/PortalBody/GetFont', {});
    if (result && result.Status === 1) {
      this.fontList = result.Data;
      this.fontListFilter = this.fontList.slice();
    }
  }

  fontHandleFilter(value) {
    this.fontListFilter = this.fontList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onValueChange() {
    this.onSetFont();
  }

  async getDataTemplate() {
    const result = await this.appService.doGET('api/PortalDesign/SearchTemplate', {});
    if (result && result.Status === 1) {
      // this.selectedFont = result.Data[0].FontID ? this.fontList.find((s) => s.ID == result.Data[0].FontID).ID : null
      this.templateID = result.Data[0].ID;
      this.onSetFont();
    }
  }

  async updateFont() {
    var params = {
      iD: this.templateID,
      FontID: this.selectedFont
    }
    const result = await this.appService.doPUT('api/PortalDesign/UpdateFont', null, params);
    if (result && result.Status === 1) {
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  onSetFont() {
    const bodyElement = document.body;
    if (this.selectedFont) {
      const fontName = this.fontList.find((s) => s.ID == this.selectedFont).Name;
      bodyElement.style.fontFamily = '"' + fontName + '"';
    }
  }
}
