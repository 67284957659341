import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Notification } from '../services/app.notification';
import { AppSwal } from 'src/app/services/app.swal';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectEvent } from '@progress/kendo-angular-upload';
import { AuthenticationService } from '../services/authentication.service';
import { AppConsts } from '../services/app.consts';
import {
  SelectableSettings,
  PageChangeEvent,
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from "@progress/kendo-data-query";

@Component({
  selector: 'app-water-bill-information',
  templateUrl: './water-bill-information.component.html',
  // styleUrls: ['./water-bill-information.component.scss']
})
export class WaterBillInformationComponent implements OnInit {
  public userForm: FormGroup = new FormGroup({
    AccountName: new FormControl(null, Validators.required),
    Name: new FormControl(null, Validators.required),
    AccountEmail: new FormControl(null, Validators.email),
    AccountPhone: new FormControl(null, Validators.required),
    // AccountAddress: new FormControl(null, Validators.required),
    Description: new FormControl(null, Validators.required),
    LVID: new FormControl(null, Validators.required),
    Capcha: new FormControl(null, Validators.required)
  });
  lvList: Array<{ Name: string, ID: string }> = [];
  private queryParamsSubscription: Subscription;

  lvListFilter: Array<{ Name: string, ID: string }> = [];
  @Input() isAdminFlg: boolean = false;
  infoContact: any = {};
  currentLang: any;
  validForm: boolean = true;
  user: any;
  dataGrids = [];
  dataGridGridDataResult: GridDataResult;
  isShowMenu = false;
  manageDialog = false;
  allowMulti = false;
  loading = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  bodyWidth = 1140;
  bodyWidthSetup = 1140;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any; //page thiết kế
  @Output() deleteBodyItem = new EventEmitter();
  filesUpload = [];
  dataItems: Array<{ Name: string; ID: string }>;
  dataItemsFilter: Array<{ Name: string; ID: string }>;
  monthFilter: Array<{ Name: string, ID: string }> = [];
  yearsListFilter: Array<{ Name: string, ID: string }> = [];
  allowInsertFile = true;
  fileUrls = [];
  dataGridSortByField: SortDescriptor[] = [];
  dataGridSelection: number[] = [];
  filesUploadName = "";
  public fileSaveUrl: any;
  userName: any;
  codeCapcha = "";
  charsArray =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  inputCapcha = '';
  pageHeader: any;
  pageFooter: any;
  isSearchTab: boolean = false;
  dataGridSelectableSettings: SelectableSettings;
  dataGridSkip = this.appConsts.pageSkip;
  dataGridPageSize = this.appConsts.pageSize;
  dataGridSort = {
    allowUnsort: true,
    mode: "multiple",
  };
  public dataGridState: State = {
    skip: this.dataGridSkip,
    take: this.dataGridSkip + this.dataGridPageSize,
    filter: {
      logic: "and",
      filters: [],
    },
    // group: [{ field: 'ParentName' }]
  };

  constructor(

    private appService: AppService,
    private translate: TranslateService,
    private language: AppLanguage,
    private notification: Notification,
    private appSwal: AppSwal,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    public appConsts: AppConsts,
  ) {
    this.userName = localStorage.getItem('userName');
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=${this.userName}&typeData=files&acceptExtensions=[".png",".jpg",".jpeg"]`;
  }
  searchOption = {
    searchText: '',
    type: 4,
    menuID: '',
    SearchText: "",


  };
  ngOnInit(): void {
   
    if (this.router.url.includes("/" + AppConsts.page.quizSearch)
      || this.router.url.includes("/" + AppConsts.page.quizCategorySearch)
    ) {
      this.isSearchTab = true;
    } else {
      this.isSearchTab = false;
    }
    this.searchOption.menuID = this.menuID;
    this.language.default();
    this.getInfo();
    this.getlvListFilter();
    if (!this.isSearchTab) {
      setTimeout(() => {
        this.createCaptcha();
      }, 100);
    }
    this.monthFilter = [
      {
        ID: "T1",
        Name: "Tháng 01"
      },
      {
        ID: "T2",
        Name: "Tháng 02"
      },
    ]
    this.yearsListFilter = [
      {
        ID: "Y2022",
        Name: "2022"
      },
      {
        ID: "Y2023",
        Name: "2023"
      },
    ]
    this.dataGrids = [
      {
        STT: "1",
        Year: "5/2023",
        SomeBills: "2517527",
        Date: "10/05/2023",
        Total: "256,032",
        Status: "2022",
        Notification: "2022",
      },

    ]
    this.getdataItems();
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  lvHandleFilter(value) {


    this.lvListFilter = this.lvList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async getlvListFilter() {

  }

  async getInfo() {
   
  }

  async postInfo() {
    var dataRequest = this.userForm.value;
    console.log("dataRequest", dataRequest);
    dataRequest["FileUrls"] = JSON.stringify(this.fileUrls);
    dataRequest["menuID"] = this.searchOption.menuID;
    console.log("dataRequest", dataRequest);
  
  }

  async onSubmit() {

   
       
  }
  onResetInput() {
    this.userForm.reset();
    this.fileUrls = [];
  }

  validateContent(Description: any) {
    return Description.length < 501;
  }

  validateMail(AccountEmail) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(AccountEmail);
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index": this.newsDecorationIndex,
      "layoutGroupIndex": this.layoutGroupIndex,
      "pageBodyIndex": this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  public onTabSelect(e: SelectEvent): void {
    let selectedIndex = JSON.parse(JSON.stringify(e)).index;
    if (selectedIndex == 1) {
      this.router.navigate([AppConsts.page.quizSearch],
        // {
        //   queryParams: { categoryId: categoryID },
        // }
      );
    }
    console.log(e);
  }
  //select file to upload
  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }
    const extension = e.files[0].extension.toLowerCase();
    this.allowInsertFile = true;
    try {
      const fileData = e.files[0]; // await this.file.readFile(e.files[0].rawFile);
      const maxMB = 30;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(
          `Kích thước tập tin không được lớn hơn ${maxMB} MB`,
          false
        );
        return;
      }
    } catch {
      this.appSwal.showError(e);
    }
    // tslint:disable-next-line: max-line-length
    if (
      !extension ||
      (extension.toLowerCase() !== ".jpeg" &&
        extension.toLowerCase() !== ".jpg" &&
        extension.toLowerCase() !== ".png")
    ) {
      this.allowInsertFile = false;
      this.appSwal.showWarning(
        "Vui lòng chọn tập tin có định dạng .jpeg, .jpg, .png",
        false
      );
      return false;
    }
  }


  onSuccessFileToUpload(e: any) {
    if (!this.allowInsertFile) {
      return;
    }
    try {
      if (this.fileUrls == undefined) {
        this.fileUrls = [];
      }
      this.fileUrls.push(
        `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`
      );
      console.log("filesUpload", this.filesUpload);
      console.log("fileUrls", this.fileUrls);
    } catch {
      this.appSwal.showError(e);
    }
  }
  onRemoveFileDocToUpload() {
    this.fileUrls = [];
    this.filesUploadName = "";
    this.filesUpload = [];
  }
  getImageFullUrl(url) {
    return this.appService.apiRoot + url;
  }
  createCaptcha() {
    let captchaID = document.getElementById('captcha-item');
    if(captchaID != null){
      captchaID.innerHTML = "";

      var lengthOtp = 5;
      var captcha = [];
      for (var i = 0; i < lengthOtp; i++) {
        var index = Math.floor(Math.random() * this.charsArray.length + 1);
        if (captcha.indexOf(this.charsArray[index]) == -1)
          captcha.push(this.charsArray[index]);
        else i--;
      }
      var canv = document.createElement("canvas");
      canv.id = "captcha";
      canv.width = 120;
      canv.height = 50;
      var ctx = canv.getContext("2d");
      ctx.font = "25px Road Rage";
      ctx.strokeText(captcha.join(""), 5, 30);
      this.codeCapcha = captcha.join("");
      document.getElementById("captcha-item").appendChild(canv);
    } 
    
  }

  validateCaptcha() {
    event.preventDefault();
    if (this.userForm.value.Capcha == this.codeCapcha)
      return true;
    return false;
  }
 
  binddataItems() {
    this.dataGridGridDataResult = {
      data: orderBy(this.dataGrids, this.dataGridSortByField),
      total: this.dataGrids.length,
    };

    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }
  public onGridDataStateChange(state: DataStateChangeEvent): void {
    this.dataGridSelection = [];
    this.dataGridState = state;
    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }
  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.dataGridState.skip = 0;
      this.dataGridSkip = 0;
      this.dataGridState.take = 10;
      this.onSearch();
      this.binddataItems();
    }
  }
  async getdataItems() {
    this.binddataItems();
  }
  onSearch() {
    this.getdataItems();
  }
  ondataItemsortChange(sort: SortDescriptor[]): void {
    this.dataGridSortByField = sort;
    this.binddataItems();
  }
  ondataItemselectedKeysChange() { }
  onGridPageChange(event: PageChangeEvent) {
    this.dataGridSkip = event.skip;
    this.binddataItems();
  }
  setSelectableSettings(): void {
    //this.allowMulti = !this.allowMulti;
    this.dataGridSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? "multiple" : "single",
    };
  }
}
