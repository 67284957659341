import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppConsts } from '../services/app.consts';
import { AppService } from '../services/app.service';
import { EventEmitter, Input, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  // styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isVi: boolean;
  menus: any[];
  openFlg: boolean = false;
  openMenuFlg: boolean = false;
  header: any;
  isLoaded: boolean = false;
  isMenuLoad: boolean = false;
  isHeaderLoad: boolean = false;
  searchNews: string;
  postList: string;
  isSearch: boolean = false;
  selectedMenuID: any = [];
  //lastselectedMenuID : any;
  languages: Array<any> = [
    {
      id: 0,
      culture: AppConsts.VIETNAMESE,
      text: 'Tiếng Việt',
    },
    {
      id: 1,
      culture: AppConsts.ENGLISH,
      text: 'English',
    },
  ];
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private router: Router
  ) {
    this.postList = AppConsts.page.postList;
  }

  ngOnInit(): void {
    if (!this.language.get() || this.language.get() == AppConsts.VIETNAMESE) {
      this.isVi = true;
    } else {
      this.isVi = false;
    }
    this.getMenu();
    this.getHeader();
    this.searchNews = AppConsts.page.searchNews;
  }
  async getMenu() {
    const result = await this.appService.doGET('api/Portal/GetNavigationBarData', { 'langID': this.language.get() });
    if (result && result.Status === 1) {
      this.menus = result.Data;
      this.isMenuLoad = true;
      this.isPageLoaded();
    }
  }

  changeLanguage(language: string) {
    if (language != this.language.get()) {
      this.isVi = !this.isVi;
      this.language.set(language);
      window.location.reload();
    }
  }

  async getHeader() {
    const result = await this.appService.doGET('api/Portal/GetHeader', { 'langID': this.language.get() });
    if (result && result.Status === 1) {
      this.header = result.Data;
      // console.log(this.header[0].ImageUrl)
      // appService.apiRoot + header[0].ImageUrl
      this.isHeaderLoad = true;
      this.isPageLoaded();
    }
  }

  isPageLoaded() {
    if (this.isHeaderLoad && this.isMenuLoad) {
      this.isLoaded = true;
    }
  }
  search(text: string, isEnter) {
    if (window.innerWidth < 576) {
      this.isSearch = !this.isSearch;
      if (isEnter) {
        this.router.navigate([this.searchNews], { queryParams: { search: text } });
      }
    } else {
      this.router.navigate([this.searchNews], { queryParams: { search: text } });
    }
  }
  open() {
    if (window.innerWidth < 576) {
      this.openFlg = !this.openFlg;

      if (this.openFlg) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    }
  }

  selectMenu(event, mother, currentTag, currentLevel) {
    //chỉ áp dụng cho màn hình < 576px
    if (window.innerWidth < 576) {
      //refresh data
      if (currentTag.classList.contains(currentLevel) == true
        && this.selectedMenuID.indexOf(currentTag.id) == -1) {
        var menuObj = mother.getElementsByClassName("menu-obj");
        for (var i = 0; i < menuObj.length; i++) {
          if (menuObj[i].id != currentTag.id) {
            var obj = menuObj[i].children;
            if (this.selectedMenuID != null && this.selectedMenuID.indexOf(menuObj[i].id) != -1) {
              var removeIndex = this.selectedMenuID.findIndex(item => item == menuObj[i].id);
              this.selectedMenuID.splice(removeIndex, 1);
            }
            for (var j = 0; j < obj.length; j++) {
              if (obj[j].classList.contains("dropdown-menu") == true) {
                obj[j].classList.add("close-menu");
                obj[j].classList.remove("open-menu");
              }
            }
          }
        }
      }

      //Lấy list element con của thẻ vừa click
      var childs = currentTag.children;
      var menu;
      for (var i = 0; i < childs.length; i++) {
        if (childs[i].classList.contains("dropdown-menu") == true) {
          menu = childs[i];
        }
      }

      // kiểm tra nếu thẻ đang click là none thì mở thẻ đang click, ngược lại thì đóng
      if (typeof menu != undefined && menu != null && (menu.classList.contains("open-menu") == false)) {
        menu.classList.remove("close-menu");
        menu.classList.add("open-menu");
        if (this.selectedMenuID.indexOf(currentTag.id) === -1) {
          this.selectedMenuID.push(currentTag.id);
        }
      } else {
        if (typeof menu != undefined && menu != null) {
          menu.classList.remove("open-menu");
          menu.classList.add("close-menu");
          if (this.selectedMenuID.indexOf(currentTag.id) != -1) {
            var removeIndex = this.selectedMenuID.findIndex(item => item == currentTag.id);
            this.selectedMenuID.splice(removeIndex, 1);
          }
        }
      }
    }

    event.stopPropagation();
  }

  goToMenu(item) {
    var k = item;
  }
  geturl(url) {
    var k = url;
  }

  parseURL(url) {
    if (url == AppConsts.RECEIVE_QUESTIONS || url == AppConsts.RECEIVE_DOCUMENT) {
      return AppConsts.page.portal;
    } else {
      return url;
    }
  }
  reset() {
    this.isSearch = false;
  }
}
