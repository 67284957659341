import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
import * as FileSaver from 'file-saver';

import {
  SelectableSettings,
  PageChangeEvent,
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppComponent } from '../app.component';
import { AppConsts } from 'src/app/services/app.consts';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { browser } from 'protractor';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppUtils } from '../services/app.utils';
import * as JSZip from 'jszip';

@Component({
  selector: 'app-library-text',
  templateUrl: './library-text.component.html',
  // styleUrls: ['./library-text.component.scss']
})
export class LibraryTextComponent implements OnInit, OnDestroy {
  user: any;
  folderParentage = [];
  currentFolder = {
    ID: this.guid.empty,
    Name: 'Đảng và đoàn thể',
    ParentID: this.guid.empty,
  };
  emptyGuid = this.guid.empty;
  @Input() isAdminFlg: boolean = true;
  request_RewardID: any;
  popupClass = 'popup-width';
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;
  dataGrids :any;
  dataGridSelectableSettings: SelectableSettings;
  dataGridSort = {
    allowUnsort: true,
    mode: 'multiple',
  };

  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any;
  @Output() deleteBodyItem = new EventEmitter();


  public dataGridFocus = {
    Name: true,
  };
  dataGridSortByField: SortDescriptor[] = [];

  dataGridSkip = AppConsts.pageSkip;
  dataGridPageSize = AppConsts.pageSize;
  dataGridSelection: number[] = [];
  dataGridItem: any;
  dataLangs = [];
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

  selectedLangID = AppConsts.defaultLangID;
  currentLang: any;
  customCss = 0;
  public Type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataGridState: State = {
    skip: this.dataGridSkip,
    take: this.dataGridSkip + this.dataGridPageSize,

    filter: {
      logic: 'and',
      filters: [],
    },
    // group: [{ field: 'ParentName' }]
  };
  dataGridGridDataResult: GridDataResult;

  dataItems: any;
  dataItemsFilter: Array<{ Name: string; ID: string }>;
  units: Array<{ Name: string; ID: string }> = [];
  unitsFilter: Array<{ Name: string; ID: string }> = [];
  categories: Array<{ Title: string; ID: string }> = [];
  categoriesFilter: Array<{ Title: string; ID: string }> = [];
  lvList: Array<{ Name: string, ID: string }> = [];
  unitList: Array<{ PublishUnitName: string, ID: string }> = [];
  lvListFilter: Array<{ Name: string, ID: string }> = [];
  unitListFilter: Array<{ PublishUnitName: string, ID: string }> = [];
  menu: Array<{ Name: string, ID: string }>;
  menuFilter: Array<{ Name: string, ID: string }>;
  public enabled = true;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  languageName = '';
  infoOpened = false;
  ManageRewardOpened = false;
  urlDownload = this.appService.apiRoot;
  searchOption = {
    searchText: '',
    type: 1,
    UnitPublishID: '',
    FileTypeID: -1,
    OperationID: this.GUID_EMPTY,
    lvID:-1,
    menuID:'',
    unitName: '',
  };
  dataErr: any;
  private queryParamsSubscription: Subscription;

  pageName = '';
  defaultType: any;
  typeSearch: any;
  element4: any;
  @Input() infoFromMain: any;
  topNumber: number = 3;
  @Output() changeTemplate1 = new EventEmitter();
  ID: any;
  @Input() OrderIdx: any;
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    // private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    // private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public appConsts: AppConsts
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getLanguageName();
  }

  ngOnDestroy(): void { }

  ngOnInit() {
    // this.queryParamsSubscription = this.activatedRoute.params.subscribe(
    //   async (data) => {
    //     this.searchOption.menuID = data['submenu'] ?? '';
    //   }
    // );
    // this.searchOption.menuID = this.menuID;
    if (typeof this.infoFromMain === "string") {
      this.searchOption.menuID = JSON.parse(this.infoFromMain).MenuID;
    }
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.getdataItems();
    this.getlvListFilter();
    this.getUnitListFilter();
    this.galleryData = {
      ID: this.ID,
      OrderIdx: this.OrderIdx,
      infoFromMain: this.infoFromMain,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };
    // this.getMenuListFilter();
    // this.getUnits();
    // this.onReload();
    // setTimeout(() => {
    //   this.removeIcon();
    //   this.addIcon();
    // }, 100);


    this.allData = this.allData.bind(this);
    // this.getPageName();
    this.currentLang = this.language.get();
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    this.control = await this.appControls.getControls(
      this.user.RoleID,
      AppConsts.page.libraryText
    );
    this.controlDefault = false;
  }

  getLanguageName() {
    let lang = this.language.get();
    if (lang == null || lang == undefined) {
      lang = 'vi-VN';
    }
    this.languageName = lang;
  }

  setSelectableSettings(): void {
    //this.allowMulti = !this.allowMulti;
    this.dataGridSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single',
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onValueChange() {
    this.onSearch();
  }
  async getdataItems() {
    this.loading = true;
    const dataRequest = {
      langID : this.selectedLangID,
      menuID: this.searchOption.menuID ? this.searchOption.menuID : this.GUID_EMPTY,
      searchText:this.searchOption.searchText,
      lVID:this.searchOption.lvID,
      unitName: this.searchOption.unitName ? this.searchOption.unitName : '',
    }
    this.dataGrids = [];
    const result = await this.appService.doGET('api/PortalOffice/GetByMenuID', dataRequest);
    if (result && result.Status === 1) {
      this.dataItems = result.Data;
      this.dataGrids =  this.dataItems.slice();
      for (var i = 0; i < this.dataGrids.length; i++) {
        this.dataGrids[i].STT = i + 1;
      }
      this.dataGridState.skip = 0;
      this.dataGridSkip = 0;
      this.binddataItems();
    }
    this.loading = false;
  }

  getDateFormat(date: any) {
    let dateTime = new Date(date);
    let month = dateTime.getMonth() + 1
    return (
      dateTime.getDate() +
      '-' +
      month +
      '-' +
      dateTime.getFullYear()
    );
  }

  async getCategories() {
    const result = await this.appService.doGET('api/CvPro5', null);
    // console.log(result);
    if (result && result.Status === 1) {
      this.categories = this.categoriesFilter = result.Data;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async getUnits() {
    const dataRequest = {};
    const result = await this.appService.doGET('api/UnitDocument', dataRequest);
    // console.log(result);
    if (result && result.Status === 1) {
      this.units = this.unitsFilter = result.Data;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async getlvListFilter() {

    const dataRequest = {
      langID: this.selectedLangID,
      type: 1,
    };

    const resultCatolories = await this.appService.doGET('api/PortalBody/GetCommon', dataRequest);
    if (resultCatolories && resultCatolories.Status === 1) {
      this.lvList = resultCatolories.Data;
      // console.log('this.lvList',this.lvList);
      this.lvListFilter = this.lvList.slice();
    }
  }
  async getUnitListFilter() {

    const dataRequest = {};

    const resultCatolories = await this.appService.doGET('api/PortalOffice/GetAllPublishUnit', dataRequest);
    if (resultCatolories && resultCatolories.Status === 1) {
      this.unitList = resultCatolories.Data;
      // console.log('this.unitList',this.unitList);

      this.unitListFilter = this.unitList.slice();
    }
  }
  // async getMenuListFilter() {

  //   const dataRequest = {
  //     langID: this.selectedLangID
  //   };


  //   const resultCatolories = await this.appService.doGET('api/Menu', dataRequest);
  //   if (resultCatolories && resultCatolories.Status === 1) {
  //     this.menu = resultCatolories.Data;
  //     this.menuFilter = this.menu.slice();
  //   }
  // }

  handleFilter(value) {
    this.units = this.units.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  lvHandleFilter(value) {
    this.lvListFilter = this.lvList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  unitHandleFilter(value) {
    this.unitListFilter = this.unitList.filter((s) => s.PublishUnitName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  setDefault() {
    this.dataGridItem = {
      IsAdd: true,
      searchText: '',
      type: 2,
      UnitPublishID: '',
      FileTypeID: -1,
      OperationID: this.GUID_EMPTY,
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    this.dataGridSelection = [];
    this.enabled = true;
    this.enabledID = true;
  }

  onGridPageChange(event: PageChangeEvent) {
    this.dataGridSkip = event.skip;
    this.binddataItems();
  }

  ondataItemselectedKeysChange() { }

  binddataItems() {
    this.dataGridGridDataResult = {
      data: orderBy(this.dataGrids, this.dataGridSortByField),
      total: this.dataGrids.length,
    };
    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  ondataItemsortChange(sort: SortDescriptor[]): void {
    this.dataGridSortByField = sort;
    this.binddataItems();
  }

  public onGridDataStateChange(state: DataStateChangeEvent): void {
    this.dataGridSelection = [];
    this.dataGridState = state;
    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  onRemoveSearchText() {
    this.searchOption.searchText = '';
    this.getdataItems();
  }

  onSearch() {
    this.getdataItems();
  }

  async onReload() {
    this.getdataItems();
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, '') + item;
    url = url.replace(/\"/g, '');
    return url;
  }

  download(path, filename) {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  }
  onStartedDownload(id) {
    // console.log(`Started downloading: ${id}`);
  }

  onFailed(error) {
    // console.log(`Download failed: ${error}`);
  }
  async downloadFile(list, libraryName){
    var arr = list ? JSON.parse(list): [];
    // if(arr.length > 0){
    //   arr.forEach(element => {
    //     this.downloadItem(element)
    //   });
    // }
    if(arr.length == 1){
      this.downloadItem(arr[0])
    }
    if(arr.length > 1){
      var zipName = this.cleanAccents(libraryName);
      var zip = new JSZip();
      for(let url of arr){
        let fileContent = await this.downloadMuptipleItem(url);
        let fileName = this.getFileName(url);
        zip.file(fileName, fileContent);
      }
      zip.generateAsync({
        type: "blob"
      })
      .then(function(content) {
        saveAs(content, zipName +".zip");
      });
    }
  }
  async downloadItem(url) {
    const dataRequest = {
      url: url,
    };

    const result = await this.appService.doDownload(
      'api/PortalOffice/Download',
      dataRequest
    );
    if (result) {
      var nameFile = this.getFileName(url);
      FileSaver.saveAs(result, nameFile);
    }
  }
  async downloadMuptipleItem(url) {
    const dataRequest = {
      url: url,
    };

    const result = await this.appService.doDownload(
      'api/PortalOffice/Download',
      dataRequest
    );
    if (result) {
      return result;
    }
  }

  getFileName(fileUrls) {
    var nameFile = 'temp.txt';
    if (fileUrls != '' && fileUrls != null) {
      var urlArr = fileUrls.split('/');
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != '' && nameFile != null) {
          var indexOfFirst = nameFile.indexOf('_');
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataGridItem;

    return {
      searchText: temp.searchText,
      UnitPublishID: temp.UnitPublishID ?? '',
      FileTypeID: temp.FileTypeID ?? -1,
      type: temp.type,
      OperationID: temp.OperationID ?? this.GUID_EMPTY,
    };
  }

  onCloseGrid(status: any) {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataGrids, {}).data,
    };

    return result;
  }

  unitsHandleFilter(value) {
    this.unitsFilter = this.units.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  categoriesHandleFilter(value) {
    this.categoriesFilter = this.categories.filter(
      (s) => s.Title.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  // menuHandleFilter(value) {
  //   this.menuFilter = this.menu.filter(
  //     (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   );
  // }


  removeIcon() {
    var element = document.querySelector('.k-pager-nav')?.firstElementChild;
    var element2 = document.querySelector('.k-pager-last')?.firstElementChild;
    var element3 = document.querySelector('.k-i-arrow-w')?.parentElement;
    var element4 = document.querySelector(
      '.k-pager-numbers li'
    )?.firstElementChild;

    //element3.remove();
    element.classList.remove('k-i-seek-w');
    element2.classList.remove('k-i-seek-e');
    // console.log(element4.innerHTML);
    //Kiểm tra nếu page1 thì ẩn
    if (element4?.innerHTML == ' 1 ') {
      // console.log(element3);
      // console.log(element4);
      element3.classList.add('check-element');
    } else {
      element3.classList.add('k-i-arrow-w');
    }
  }
  //Add class icon
  addIcon() {
    var element = document.querySelector('.k-pager-nav').firstElementChild;
    var element2 = document.querySelector('.k-pager-last').firstElementChild;
    element.classList.add('k-i-arrow-seek-left');
    element2.classList.add('k-i-arrow-seek-right');
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  changeTemp1(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.topNumber = this.infoFromMain?.TopNumber;
    //save menuid to temp of publish
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    //save menuid to temp to get data after save
    this.searchOption.menuID = newTemp.Info.MenuID;
    // this.getTopNews();
    // this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate1.emit(newTemp);
    this.getdataItems();
  }
  getFullUrl(list) {
    var arr = list ? JSON.parse(list): [];
    if(arr.length > 0){
      arr.forEach(element => {
        window.open(this.appService.apiRoot + element);
      });
    }

  }
  // async getTopNews() {
  //   const result = await this.appService.doGET(
  //     'api/PortalPost/GetByMenuID',
  //     {
  //       langID: this.language.get(),
  //       menuID: this.infoFromMain.MenuID,
  //       topNum: this.infoFromMain.TopNumber,
  //     }
  //   );
  //   if (result && result.Status === 1) {
  //     this.postTittle = result.Data.dataMenu.Name;
  //     this.viewMore = result.Data.dataMenu.ID;
  //     this.topNews = result.Data.topNews;
  //     this.pageData();
  //     this.total = result.Data.topNews.length
  //   }
  // }
  cleanAccents(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    str = str.replace(/[!&\/\\#,+()$~%.'":*?<>{} ]/g, '-');
    str = str.trim();
    return str;
  }
}
