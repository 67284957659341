
<article class="payment-result is-front router-outlet-content">
  <header>
    <div class="banner">
      <figure>
        <!-- <img class="img1" *ngIf="header[0]" [src]="appService.apiRoot + header[0].ImageUrl" alt="header[0].ImageUrl"> -->
        <nav>
          <a  >
            <img *ngIf="status == 1" class="img3"  src="../../../assets/images/img-success.png">
            <img *ngIf="status != 1" class="img3"  src="../../../assets/images/error.png">
          </a>
        </nav>
      </figure>
      <div class="payment-status">
        <span  *ngIf="status == 1">{{'Pay_Success'|translate}}</span>
        <span  *ngIf="status != 1">{{'Pay_Fail'|translate}}</span>
      </div>
    </div>
  </header>
  
  <div class="payment-msg">
    <span>
      {{msg}}
    </span>
  </div>
  <div class="payment-back">
    <button type="button" class="btn btn-primary btn-back" (click)="goBack()">{{'Go_Back_MP'|translate}}</button>
  </div>
</article>


