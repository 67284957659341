<section class="submit-document router-outlet-content ">
  <div class="row admin">
    <div class="col-md-12 col-12">
      <div class="page-name">{{'PORTAL_Document_List' | translate}}</div>
    </div>
    <!-- <div class="col-md-12 col-12 custom-document" *ngFor="let item of dataGrids">
      <span class="k-icon k-i-file-txt red-icon"></span><span (click)="onEditGrid(item)">{{item.Name}}</span>
    </div>
    <div class="col-md-12 col-12 fl">
      
    </div> -->
    <div class="col-md-12 col-12 " *ngFor="let item of pagedDocuments ; let i = index">
      <div class="document-item">
        <span class="k-icon k-i-file-txt red-icon"></span><span class="is-pointer" (click)="onEditGrid(item)">{{item.Name}}</span>
      </div>
      
  </div>
  <kendo-datapager *ngIf= 'pagedDocuments.length > pageSize'
  [style.width.%]="100"
  [pageSize]="pageSize"        
  [pageSizeValues]="false"
  [skip]="skip"
  [total]="total"
  [info]="false"
  (pageChange)="onPageChange($event)"
>
</kendo-datapager>
  </div>

</section>

<kendo-dialog *ngIf="infoOpened" class="submit-document x-dialog window-center dialog-chat "
  [draggable]="true" [ngClass]="{'x-dialog-register-document': filesNum > 4, 'x-dialog-register-document-3': filesNum == 3, 'x-dialog-register-document-2': filesNum == 2, 'x-dialog-register-document-1': filesNum == 1, 'x-dialog-register-document-0': filesNum == 0}">
  <div class="employe-detail">
    <div class="x-close-div">

      <button type="button" class="xbutton-dialog close" aria-label="Close" [title]="'Close_Box' | translate"
        (click)="onCloseInfo()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row">
      <app-register-document (showSuccess)="onShowSuccess($event)" [InputDataItem]="dataGridItem" [FlgAdd]="true"
        [infoOpened]="infoOpened"></app-register-document>
    </div>
  </div>
</kendo-dialog>
<kendo-dialog *ngIf="isSuccess" class="submit-document   x-dialog-success window-center dialog-chat "
  [draggable]="true">
  <div class="employe-detail">
    <div class="x-close-div">
      <div class="title-info-req">
        <span>{{'Confirm_Register_Portal'| translate}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="row">

          <div class="col-md-12 col-12 text-center">
            <div [ngClass]="'control'">
              <label>
                <span [textContent]="'Confirm_Register_Portal_Detail' | translate">:</span>
              </label>
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="btn-dialog-wrapper">

              <button class="btn-dialog btn-close-success" (click)="onCloseSuccess()">
                {{ 'Close_Box' | translate }}
              </button>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</kendo-dialog>