<article class="search-page">
  <div class="row">
    <!-- header -->
    <div class="col-12 col-md-12">
      <div *ngFor="let item of pageHeader" class="header-box">
        <app-header-template1 *ngIf="item == '1'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
        </app-header-template1>
        <app-header-template2 *ngIf="item == '2'" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
          (menuSearch)="menuSearch($event)">
        </app-header-template2>
        <app-header-template3 *ngIf="item == '3'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
        </app-header-template3>
        <app-header-template4 *ngIf="item == '4'" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
          (menuSearch)="menuSearch($event)" [templateID]="TemplateID" [pageSelectedID]="PageSelectedID">
        </app-header-template4>
        <app-header-template5 *ngIf="item == '5'" (menuItem)="menuItem1($event)" (menuItemchild)="menuItemchild($event)"
          (menuSearch)="menuSearch($event)" [templateID]="TemplateID" [pageSelectedID]="PageSelectedID"
          [bodyWidth]="bodyWidth">
        </app-header-template5>
      </div>
    </div>




    <!-- header -->
    <div class="col-md-1 col-12"></div>
    <div class="col-md-10 col-12">
      <div class="container-fluid">
        <div *ngIf="loading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="search-result-container" *ngIf="!loading">
          <div class="search-result-summary" *ngIf="ImageListPage.length == 0">
            {{ "Not_Found" | translate }}
            <span class="search-text">{{ searchText }}</span>
            {{ "In_Any_Documents" | translate }}
          </div>
          <div class="search-result-summary" *ngIf="ImageListPage.length > 0">
            {{ "Found" | translate }} {{ ImageListPage.length }}
            {{ "Result_About" | translate }}
            <span class="search-text">{{ searchText }}</span>
          </div>
          <div class="search-result-list" *ngIf="ImageListPage.length > 0">
            <section
              *ngFor="let item of ImageListPage; let i = index"
              class="search-result-item"
            >
              <div
                class="search-result-image"
                (click)="redirectToItem(item.UrlPath)"
              >
                <img
                  [src]="appService.apiRoot + item.ImageUrl"
                  alt="hinh-anh"
                />
              </div>
              <div class="search-result-info">
                <h3 (click)="redirectToItem(item.UrlPath)">{{ item.Title }}</h3>
                <p [innerHTML]="item.Summary"></p>
              </div>
            </section>
          </div>
        </div>
      </div>

      <kendo-datapager
        [style.width.%]="100"
        [pageSize]="pageSize"
        [pageSizeValues]="false"
        [skip]="skip"
        [total]="total"
        (pageChange)="onPageChange($event)"
        *ngIf="ImageListPage.length > 0 && !loading"
      >
        <kendo-datapager-messages
          [of]="'of' | translate"
          [items]="'Item' | translate"
        >
        </kendo-datapager-messages>
      </kendo-datapager>
    </div>
    <div class="col-md-1 col-12"></div>
    <!-- footer -->
    <div style="width: 100%;">
      <div *ngFor="let item of pageFooter" class="header-box">
        <app-footer *ngIf="item == '1'"></app-footer>
        <app-footer2 *ngIf="item == '2'"></app-footer2>
        <app-footer3 *ngIf="item == '3'" (isMoveToTop)="moveToTop()" [isAdminFlg]="false"></app-footer3>
      </div>
    </div>
    <!-- footer -->
  </div>
</article>
