<section class="post-detail" (window:resize)="onResize($event)">
  <div class="row">
    <!-- header -->
    <div class="col-12 col-md-12" *ngIf="!isPostInMainPage && !isIntroPage">
      <div *ngFor="let item of pageHeader" class="header-box">
        <app-header-template1 *ngIf="item == '1'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
        </app-header-template1>
        <app-header-template2 *ngIf="item == '2'" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
          (menuSearch)="menuSearch($event)">
        </app-header-template2>
        <app-header-template3 *ngIf="item == '3'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
        </app-header-template3>
        <app-header-template4 *ngIf="item == '4'" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
          (menuSearch)="menuSearch($event)" [templateID]="TemplateID" [pageSelectedID]="PageSelectedID">
        </app-header-template4>
        <app-header-template5 *ngIf="item == '5'" (menuItem)="menuItem1($event)" (menuItemchild)="menuItemchild($event)"
          (menuSearch)="menuSearch($event)" [templateID]="TemplateID" [pageSelectedID]="PageSelectedID"
          [bodyWidth]="bodyWidth">
        </app-header-template5>
      </div>
    </div>
    <div class="row fixed-item" #scrollMe>
      <div class="main-item">
        <div class="row">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12"> 
            <div class="row">
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 top-new-item">
                <button class="back-to-top" *ngIf="isShow" (click)="gotoTop()">
                  <img class="post-image" src="../../assets/images/shift_9797492.png" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    



    <!-- header -->
    <div class="row margin-box detail-body" *ngIf="!isIntroPage">
      <app-router-link [menuTittle]="menuTittle" [menuVl]="menuVl" [itemchild]="itemchild">
        [ItemTittle]="">
      </app-router-link>
      <div class="col-12 col-md-8">
        <app-pdetail [infoFromMain]="this.infoFromMain" (onChangeTempPostDetail)="handleChangeTempPostDetail($event)"
          [isIntroPage]="this.isIntroPage" [isAdminFlg]="this.isAdminFlg"></app-pdetail>
      </div>
      <div class="col-12 col-md-4">
        <aside>
          <app-most-viewed-posts [UrlPath]="UrlPath" type="related_Post"
            (updatePostDetail)="updatePostDetail()"></app-most-viewed-posts>
        </aside>
      </div>
    </div>
    <div class="row margin-box detail-body-full" *ngIf="isIntroPage">
      <div class="col-12 col-md-12">
        <app-pdetail [infoFromMain]="this.infoFromMain" (onChangeTempPostDetail)="handleChangeTempPostDetail($event)"
          [isIntroPage]="this.isIntroPage" [isAdminFlg]="this.isAdminFlg"></app-pdetail>
      </div>
      <!-- <div class="col-12 col-md-4">
      <aside>
        <app-most-viewed-posts [UrlPath]="UrlPath" type="related_Post" (updatePostDetail)="updatePostDetail()"></app-most-viewed-posts>
      </aside>
    </div> -->
    </div>
    <!-- footer -->
    <div *ngIf="!isPostInMainPage && !isIntroPage" style="width: 100%;">
      <div *ngFor="let item of pageFooter" class="header-box">
        <app-footer *ngIf="item == '1'"></app-footer>
        <app-footer2 *ngIf="item == '2'" [isAdminFlg]="false"></app-footer2>
        <app-footer3 *ngIf="item == '3'" (isMoveToTop)="moveToTop()" [isAdminFlg]="false"></app-footer3>
      </div>
    </div>
    <!-- footer -->
  </div>
</section>