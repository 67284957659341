import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { parse } from 'querystring';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { $ } from 'protractor';
import { AppConsts } from '../services/app.consts';
@Component({
  selector: 'app-news-template-38',
  templateUrl: './news-template-38.component.html',
  // styleUrls: ['./news-template-38.component.scss']
})
export class NewsTemplate38Component implements OnInit, AfterViewInit  {
  @Input() isAdminFlg;
  @Input() ID;
  @Input() itemTemplate;
  @Input() templateID;
  @Input() pageSelectedID;
  @Input() OrderIdx;
  @Input() banner;
  @Input() newsDecorationIndex: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  info: any = [];
  ImageUrl: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate0 = new EventEmitter();
  @Output() deleteBodyItem = new EventEmitter();
  @ViewChild('scrollview') scrollview;
  galleryData: any;
  isLoadedBannerRightSide = false;
  dataImageSView: Array<{ Title: string; Link: string; ImageUrl: string }> = [];
  isShowMenu = false;
  editFlg = false;
  dltFlg = false;
  manageDialog = false;
  public widthSView = '100%';
  public heightSView = '406px';
  isGallery: boolean = false;
  isGoBack: boolean = false;
  private interval;
  innerWidth: any;
  innerHeight: any;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService
  ) { }

  async ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    if (this.banner.Info.includes('ImageUrl')) {
      this.info = JSON.parse(this.banner.Info);
      this.isLoadedBannerRightSide = true;
    }
    this.isLoadedBannerRightSide = true;
    this.galleryData = {
      OrderIdx: this.OrderIdx,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };
    if(window.innerWidth < 770){
      this.heightSView = "300px";
    }
    // if (this.scrollview) {
    //   setInterval(() => {
    //     this.scrollview.next();
    //   }, 5000);
    // }
  }
  public ngAfterViewInit(): void {
    this.interval = setInterval(() => {
      this.scrollview.next();
    }, 5000);
  }
  public ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getLink(link) {
    // window.location.href = 'https://' + item.Link;
    window.open(AppConsts.page.postDetail + '/' +link, '_blank');
  }

  getFullUrl(url: string) {
    return this.appService.apiRoot + url;
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }

  // addComponent() {
  //   this.editFlg = false;
  //   this.manageDialog = true;
  //   this.isShowMenu = false;
  // }

  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }

  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }
  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  addNewBanner(e) {
    this.isLoadedBannerRightSide = e;
  }

  deleteAllBanner(e) {
    this.isLoadedBannerRightSide = e;
  }
  isGalleryDialog(e){
    this.isGallery = e;
  }

  onSave(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    let newTemp = {
      Info: e.Info,
      Saved: 1,
      pageBodyIndex: this.pageBodyIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      index: this.newsDecorationIndex
    };
    this.changeTemplate0.emit(newTemp);
    this.editFlg = false;
    this.manageDialog = false;
  }

  onClose(e) {
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onGoBack(){
    this.isGallery = false;

  }
  onResize(event) {
    if(window.innerWidth >= 1360){
      this.heightSView = "406px";
    } else {
      this.heightSView = "300px";
    }
    this.heightSView = "300px";
  }
}
