import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';

import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';

import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-template-41-dialog',
  templateUrl: '../news-template-41-dialog/news-template-41-dialog.component.html',
  // styleUrls: ['../news-template-41-dialog.component.scss']
})
export class NewsTemplate41DialogComponent implements OnInit {

  @Input() request_AccountID;
  @Output() galleryManageOpened = new EventEmitter<boolean>();
  @Input() galleryData: any;
  @Input() editFlg: boolean;
  @Input() pageSelectedID = "";
  @Input() itemTemplate: any;
  @Output() onSave = new EventEmitter();
  @Output() onChangetemp1 = new EventEmitter();
  user: any;
  popupClass = "popup-width";
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;


  dataLangs = [];

  myRestrictionsImage: FileRestrictions = {
    allowedExtensions: [".jpg", ".jpeg", ".png"],
  };

  myRestrictionsFile: FileRestrictions = {
    allowedExtensions: [".doc", ".docx", ".pdf"],
  };

  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  dataItems: Array<{ Name: string, ID: string }>;
  dataItemsFilter: Array<{ Name: string, ID: string }>;


  public disabled = false;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  infoOpened = false;

  searchOption = {
    SearchText: ''
  };
  dataErr: any;
  pageName = '';
  filesUploadAvatar: "";
  filesUploadName = "";
  filesUpload: Array<FileInfo>;
  urlDownload = this.appService.apiRoot;
  public fileSaveUrl: any;
  dataGalleryItem: any;
  dataGalleryItemtemp: any;
  public uploadSaveAvatar = "";
  IsVietnameseTab = true;

  Personalelection: number[] = [];
  Personal: any;
  allowInsertFile = true;
  @Input() HiddenTab;
  //selectedLangID = this.appConsts.defaultLangID;
  dataResultOutput = {
    ID: '',
    SttResultFromUser: true
  }
  categoriesList: Array<any> = [];
  categoriesListFiltered: Array<any> = [];
  @Input() templateID: "";

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts,
    private activatedRoute: ActivatedRoute
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    // this.getControl();
    this.setDefault();
    this.language.default();
    this.getAccoundID();
    // this.getPageName();
    this.GetAllLang();
    this.onGetCategories();
    if(this.editFlg == true){
      this.dataGalleryItemtemp.ID = this.galleryData.ID;
      this.dataGalleryItemtemp.Info.MenuID = this.galleryData.infoFromMain.MenuID;
      this.dataGalleryItemtemp.Info.TopNumber = this.galleryData.infoFromMain.TopNumber;
      this.dataGalleryItemtemp.OrderIdx = this.galleryData.OrderIdx;
      this.dataGalleryItemtemp.Layout = this.galleryData.Layout;
      this.dataGalleryItemtemp.GroupID = this.galleryData.GroupID;
      this.dataGalleryItemtemp.GroupIndex = this.galleryData.GroupIndex;
    }

  }

  getAccoundID() {
    this.activatedRoute.queryParams.subscribe(async (params: any) => {
      if (params && params.accountID) {
        this.request_AccountID = params.accountID;
      }

      //this.getMeetingPeriod();
    });
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.galleryDialog);
    //this.controlDefault = false;
    //set true for dev
    this.controlDefault = true;
  }



  setDefault() {
    this.dataGalleryItem = {
      ID: this.guid.empty,
      TemplateID: '1',
      PageID: this.pageSelectedID,
      // Name: null,
      OrderIdx: null,
      Info: {
        TopNumber: null,
        MenuID: null
      },
      Width: 0
    };
    this.dataGalleryItemtemp = Object.assign({}, this.dataGalleryItem);

    var errTemp = Object.assign({}, this.dataGalleryItemtemp);
    this.dataErr = [errTemp];

    this.disabled = false;
    this.enabledID = true;
    // this.filesUploadAvatar = "";
    // this.filesUploadName = "";
    // this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=${this.user.UserName}&typeData=files&acceptExtensions=[".png",".jpg",".jpeg",".gif"]`;

  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    //this.getMeetingPeriod();
  }


  // async getMeetingPeriod() {
  //   this.loading = true;

  //   // this.getFilter();

  //   const dataRequest = {
  //     iD: this.request_AccountID
  //   };

  //   const resultPersonal = await this.appService.doGET("api/MeetingGroup", dataRequest);
  //   if (resultPersonal && resultPersonal.Status === 1) {

  //     this.dataGalleryItem = resultPersonal.Data;
  //     this.dataGalleryItem.StartDate = this.dataGalleryItem.StartDate ? new Date(this.dataGalleryItem.StartDate) : null;
  //     this.dataGalleryItem.EndDate = this.dataGalleryItem.EndDate ? new Date(this.dataGalleryItem.EndDate) : null;

  //     this.bindtemp(this.dataGalleryItem);

  //     // this.uploadSaveAvatar = this.dataGalleryItemtemp.AvatarUrl;
  //     // this.dataGalleryItemtemp.AvatarUrl = this.dataGalleryItemtemp.AvatarUrl ? this.appService.apiRoot + this.dataGalleryItemtemp.AvatarUrl : this.user.FilePath;
  //   }
  //   this.loading = false;
  //   this.checkSelectionID();
  // }

  bindtemp(item) {
    this.dataGalleryItemtemp = Object.assign({}, item);
  }

  // checkSelectionID() {

  //   for (let i = this.Personalelection.length - 1; i >= 0; i--) {
  //     const selectedItem = this.Personal.find((item) => {
  //       return item.ID === this.Personalelection[i];
  //     });
  //     if (!selectedItem) {
  //       this.Personalelection.splice(i, 1);
  //     }
  //   }
  // }

  onReload() {
    //this.getMeetingPeriod();
    // location.reload();
    this.onSave.emit(true)
  }



  async onSaveGallery() {
    // if (this.dataGalleryItemtemp.ID == this.guid.empty || this.dataGalleryItemtemp.ID == null) {
    //   await this.addGallery();
    // }
    // else {
    //   await this.editGallery();
    // }
    if (this.dataErr[0].Info.MenuID != undefined && this.dataErr[0].Info.TopNumber != undefined && this.dataErr[0].Info.TopNumber > 0) {
      const temp = this.dataGalleryItemtemp;
      if (!temp.Info ||  temp.Info.MenuID == null || temp.Info.MenuID == "") {
        return;
      }
      this.galleryData.infoFromMain.TopNumber = this.dataGalleryItemtemp.Info.TopNumber;
      this.galleryData.infoFromMain.MenuID = this.dataGalleryItemtemp.Info.MenuID;
      this.onChangetemp1.emit(temp);
      this.galleryManageOpened.emit(true);
    }
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataGalleryItemtemp;

    return {
      ID: temp.ID ? temp.ID : this.guid.empty,
      TemplateID: temp.TemplateID ? temp.TemplateID : null,
      PageID: temp.PageID ? temp.PageID : null,
      //Name: temp.Name ? temp.Name : null,
      OrderIdx: temp.OrderIdx ? temp.OrderIdx : null,
      Info: temp.Info ? JSON.stringify(temp.Info) : null,
      Width: temp.Width ? temp.Width : 100,
      GroupID: temp.GroupID ? temp.GroupID : 0,
      GroupIndex: temp.GroupIndex ? temp.GroupIndex : 0,
      Layout: temp.Layout ? temp.Layout : 100,
    };
  }

  async addGallery() {
    // this.appComponent.loading = true;
    const temp = this.dataGalleryItemtemp;
    if (!temp.Info ||  temp.Info.MenuID == null || temp.Info.MenuID == "") {
      return;
    }
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPOST('api/Template', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.galleryManageOpened.emit(true);
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }
  async editGallery() {
    // this.appComponent.loading = true;
    const temp = this.dataGalleryItemtemp;
    if (!temp.Info ||  temp.Info.MenuID == null || temp.Info.MenuID == "") {
      return;
    }
    this.galleryManageOpened.emit(true);
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/Template', dataRequest, { iD: dataRequest.ID });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.galleryManageOpened.emit(true);
      // document.getElementById('body-'+this.galleryData.OrderIdx.toString()).style.width = dataRequest.Width+'%';
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }


  async onDeletePersonal() {
    // this.appComponent.loading = true;
    const dataRequest = {
      iD: this.request_AccountID
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doDELETE('api/MeetingGroup', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }


  async GetAllLang() {
    this.loading = true;

    const result = await this.appService.doGET('api/Lang/GetAllLang', null);
    if (result) {
      this.dataLangs = result.Data;
      var viLang = this.dataLangs.findIndex(x => x.ID == "vi-VN");
      if (viLang != 0 && viLang != -1) {
        this.dataLangs.splice(viLang, 1);
        this.dataLangs.unshift({
          ID: "vi-VN",
          Name: "Tiếng Việt"
        });
      }
    }
    this.loading = false;
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }



  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/Search', {
      searchText: '',
      langID: this.language.get()
    });
    if (result && result.Status === 1) {
      this.categoriesList = this.categoriesListFiltered = result.Data;
      this.categoriesListFiltered.push({ID: "post-by-keyword", Name: "Tin bài theo từ khóa"})
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  handleFilter(value) {
    this.categoriesListFiltered = this.categoriesList.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

}
