import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-achivement-item',
  templateUrl: './achivement-item.component.html',
  // styleUrls: ['./achivement-item.component.scss']
})
export class AchivementItemComponent implements OnInit {
  @Input() ImageUrl: string;
  @Input() Name: string;
  constructor(private appService: AppService,) {}

  ngOnInit(): void {
    this.getImagePath()
  }
  getImagePath() {
    this.ImageUrl = this.appService.apiRoot + this.ImageUrl
  }
}
