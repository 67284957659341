import { Component, OnInit, OnDestroy,Input, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../services/app.service';
//import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
// import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../../services/app.notification';
// import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
// import { NullInjector } from '@angular/core/src/di/injector';
// import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
// import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';
// import * as moment from 'moment';
// import { equalSegments } from '@angular/router/src/url_tree';
import { equal } from 'assert';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-search-document',
  templateUrl: './search-document.component.html',
// styleUrls: ['./search-document.component.css']
})
export class SearchDocumentComponent implements OnInit, OnDestroy {

  user: any;
  dataGridItemID: any;
  popupClass = "popup-width";
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;
  dataGrids = [];
  GroupSteps: any;
  // dataGridSelectableSettings: SelectableSettings;
  // dataGridSort = {
  //   allowUnsort: true,
  //   mode: 'multiple'
  // };
  // public dataGridFocus = {
  //   Name: true
  // };
  // dataGridSortByField: SortDescriptor[] = [];

  // dataGridSkip = this.appConsts.pageSkip;
  // dataGridPageSize = this.appConsts.pageSize;
  // dataGridSelection: number[] = [];
  //public dataGridItem: any;
  dataLangs = [];

  selectedLangID = AppConsts.defaultLangID;
  customCss = 0;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  // public dataGridState: State = {
  //   skip: this.dataGridSkip,
  //   take: this.dataGridSkip + this.dataGridPageSize,
  //   filter: {
  //     logic: 'and',
  //     filters: []
  //   }
  // };

TempCode: string ='';
Code: string ='';
TempPasswrord: string ='';
Password: string ='';
searchFlg: boolean = false;
countDuplicateKeys: number = 0;
  // CvPro5Groups: Array<{ Name: string, ID: number }> = [];
  // CvPro5GroupsFilter: Array<{ Name: string, ID: number }> = [];
  // CvPro5Group: { Name: string, ID: number } =
  //     {Name: this.translate.instant('PaymentDocument_All'),
  // ID: null};
  // SearchTextTemp: string ='';
  // SearchText: string ='';
  // FromDate: Date;
  // ToDate: Date ;
  // StatusPayments: Array<{ Name: string, Status: boolean }> = [
  //   {Name: this.translate.instant('Success'),
  //   Status: true},
  //   {Name: this.translate.instant('Fail'),
  //   Status: false},
  // ];
  // StatusPaymentsFilter:  Array<{ Name: string, Status: boolean }> = [
  //   {Name: this.translate.instant('Success'),
  //   Status: true},
  //   {Name: this.translate.instant('Fail'),
  //   Status: false},
  // ];
  // StatusPayment: { Name: string, Status: boolean };  
  //   {Name: this.translate.instant('PaymentDocument_Status'),
  // Status: null};


  // public langID: string = "vi-VN";
  // public xlang: string = "en-US";

  public disabled = false;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  editOpened = false;
  public infoOpened = false;
  updateProgressOpened = false;
  searchOption = {
    SearchText: ''
  };
  dataErr: any;

  pageName = '';



  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
   public appConsts: AppConsts
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    

  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    // this.getControl();
    this.language.default();
    this.setDefault();
   // this.language.default();

    // this.setSelectableSettings();
   // this.onReload();
    this.initDisplay();
   // this.allData = this.allData.bind(this);
    // this.getPageName();
  //  this.GetAllLang();
    // localStorage.setItem("xlangID",this. langID);
    // this.xlang = localStorage.getItem("xlangID");
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageNamePortal();
  }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.receiveDocument);
    this.controlDefault = false;
  }

  // setSelectableSettings(): void {

  //   //this.allowMulti = !this.allowMulti;
  //   this.dataGridSelectableSettings = {
  //     checkboxOnly: false,
  //     mode: this.allowMulti ? 'multiple' : 'single'
  //   };
  // }

  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.SearchTextTemp) {
    //   this.SearchText = this.SearchTextTemp;
    //  this.onSearch();
    // }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextRemove(e: any) {
    this.searchOption.SearchText = '';
    this.onSearch();
  }


//   async getdataItems() {
//     this.loading = true;
//     const dataRequest = {
//       searchText: this.searchOption.SearchText
//     };
//     const result = await this.appService.doGET('api/CvPro5RegisterDocument/SearchPaymentHistory', dataRequest);
// console.log("result",result);


//     if (result) {
   
//     }

//   }

  setDefault() {
    // this.dataGridItem = {
      
    //   IsAdd: true,
    //   ID: -1,
    //   Name: '',
    //   Description: '',
    //   LangID:'vi-VN',
    //   OrderIdx: '',
    //  CvSearchDocumentID: -1

    // };
    // var errTemp = Object.assign({}, this.dataGridItem);
    // errTemp.Type = null;
    // this.dataErr = [errTemp];
    this.customCss = 0;
 //   this.dataGridSelection = [];
    this.disabled = false;
    this.enabledID = true;
  }

  // onGridPageChange(event: PageChangeEvent) {
  //   this.dataGridSkip = event.skip;
  //   this.binddataItems();
  // }

  ondataItemselectedKeysChange() {

  }

  // binddataItems() {
  //   this.dataGridGridDataResult = {
  //     data: orderBy(this.dataGrids, this.dataGridSortByField),
  //     total: this.dataGrids.length
  //   };

  //   this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  // }

  // ondataItemsortChange(sort: SortDescriptor[]): void {
  //   this.dataGridSortByField = sort;
  //   this.binddataItems();
  // }

  // public onGridDataStateChange(state: DataStateChangeEvent): void {
  //   this.dataGridSelection = [];
  //   this.dataGridState = state;
  //   this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  // }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    // this.SearchTextTemp = '';

  }

  async initDisplay() {
    
    // const resultCvPro5Groups = await this.appService.doGET('api/CvPro5RegisterDocument/CvPro5Groups', null);
    // if (resultCvPro5Groups && resultCvPro5Groups.Status === 1) {
    //   this.CvPro5Groups = resultCvPro5Groups.Data;
    //   this.CvPro5GroupsFilter = this.CvPro5Groups.slice();
    // }
    
  }

  

async onSearch() {
  
   if( this.Code == this.TempCode && this.Password == this.TempPasswrord){
    this.countDuplicateKeys += 1;
  }
   this.Code = this.TempCode;
   this.Password = this.TempPasswrord; 

   if(!this.Code || !this.Password) {
    this.appSwal.showWarning(this.translate.instant('Error_Code_And_Password_Empty'), false);
  return;
  }
  const dataRequest = {
    code: this.Code? this.Code: '',
    password: this.Password ? this.Password: '',
    langID: this.language.get()
  };

  const result = await this.appService.doGET('api/CvPro5RegisterDocument/Lookup', dataRequest);
if(!result.Data == null || result.Status != 1) {
  this.appSwal.showWarning(this.translate.instant('No_Data'), false);
  return;
}
   this.searchFlg = true;
  }

  onReload() {
    //this.searchOption.SearchText = '';
  //  this.getdataItems();
  }

  onCloseLookup(){
  this.searchFlg = false;
  }

  onClearGrid() {
    this.setDefault();
  }

  // onAllowSelectMulti() {
  //   this.setSelectableSettings();
  // }

  // onAddNewGrid() {
  //   this.setDefault();
  //   this.editOpened = true;
  // }

  // onSaveGrid() {
  //   // if (this.dataGridItem.IsAdd) 
  //   // { this.addGrid(); } else { 
  //     this.updateGrid(); //}
  // }

  // createDataRequest(data: any): any {

  //   const temp = data ? data : this.dataGridItem;
  //   return {
      
  //     ID: temp.ID,
  //     Name: temp.Name,
  //     Description: temp.Description,
  //     OrderIdx: temp.OrderIdx,

  //    LangID: this.selectedLangID,
  //    CvSearchDocumentID: -1,
  //   };
  // }

  onCloseGrid(status: any) {
    this.disabled = true;
    this.enabledID = false;
    this.editOpened = true;
    
  }

  async onEditGrid(dataItem: any) {
    this.dataGridItemID = dataItem.ID;
    this.editOpened = true;
  //  await this.getDataItemByID(dataItem.ID)

  }

  // async  onInforGrid(dataItem: any) {
  //   this.dataGridItemID = dataItem.ID;
  //   this.infoOpened = true;
  // }

  // async  onUpdateProgress(dataItem: any) {
  //   this.editOpened = false;
  // //  this.dataGridItem = dataItem;
  //   this.updateProgressOpened = true;
  //   this.infoOpened = true;
  // }
  // async getDataItemByID(id: any) {

  //   const dataRequest = {
  //     iD: id,
  //   //  langID: this.selectedLangID
  //   };

  //   const result = await this.appService.doGET('api/CvPro5RegisterDocument', dataRequest);
  //   if (result && result.Status === 1) {

  //     this.dataGridItem = result.Data;
  //     for( var i =0; i<this.dataGridItem.length; i++ ) {
  //     if (this.dataGridItem[i].DateOfBirth != undefined && this.dataGridItem[i].DateOfBirth != null) {
  //       this.dataGridItem[i].DateOfBirth2 = new Date(this.dataGridItem[i].DateOfBirth); 
  //     }       
  //   }
  //     this.dataGridItem.SearchDocumentID = result.Data.ID; 
  //     console.log("dataGridItem", this.dataGridItem); 
  //     this.disabled = false;
  //     this.enabledID = false;
  //     this.editOpened = true;

  //   } else {
  //     this.appSwal.showWarning(result.Msg, false);
  //   }
  // }

  // async addGrid() {
  //   this.appComponent.loading = true;
  //   const dataRequest = this.createDataRequest(null);
  //   const result = await this.appService.doPOST('api/CvPro5RegisterDocument', dataRequest);
  //   if (result && result.Status === 1) {
  //     this.notification.showSuccess(result.Msg);
  //     this.onReload();
  //     this.onAddNewGrid();
  //   } else {
  //     if (!result.Msg) {
  //     this.dataErr = result.Data;
  //     var count = 0;
  //     for (var prop in this.dataErr[0]) {
  //       count++;
  //     }
  //     this.customCss = count;
  //   }  else {
  //       this.appSwal.showWarning(result.Msg, false);
  //     }
  //   }
  //   this.appComponent.loading = false;
  // }

  // async updateGrid() {
  //   this.appComponent.loading = true;
  //   const iD = this.dataGridItem.ID;
  //   const dataRequest = this.createDataRequest(null);

  //   const result = await this.appService.doPUT('api/CvPro5RegisterDocument', dataRequest, { iD });
  //   if (result && result.Status === 1) {
  //     this.notification.showSuccess(result.Msg);
  //     this.onReload();
  //     this.onCloseInfo();
  //   } else {
  //     if (!result.Msg){
  //       this.dataErr = result.Data;
  //       var count = -1;
  //       for (var prop in this.dataErr[0]) {
  //         count++;
  //       }
  //       this.customCss = count;
  //     } else {
  //       this.appSwal.showWarning(result.Msg, false);
  //     }
  //   }
  //   this.appComponent.loading = false;
  // }

  // checkErr() {
  //   if(this.customCss == 1) {
  //     return "x-dialog-search-document-err-one";
  //   } else if(this.customCss == 2) {
  //     return "x-dialog-search-document-err-two";
  //   }else if(this.customCss == 3) {
  //     return "x-dialog-search-document-err-three";
  //   }else if(this.customCss == 4) {
  //     return "x-dialog-search-document-err-four";
  //   } else {
  //     return "x-dialog-search-document-group"
  //   }
  // }
  // async onDeleteGrid(dataItem: any) {
  //   this.appComponent.loading = true;
  //   const dataRequest = {
  //     iD: dataItem.ID,
  //     langID: this.selectedLangID
  //   };

  //   const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
  //   if (option) {
  //     const result = await this.appService.doDELETE('api/CvPro5RegisterDocument', dataRequest);
  //     if (result && result.Status === 1) {
  //       this.notification.showSuccess(result.Msg);
  //       this.onReload();
  //       this.onCloseInfo();
  //       this.dataGridSelection = [];
  //       this.allowMulti = false;
  //     } else {
  //       this.appSwal.showWarning(result.Msg, false);
  //     }
  //   }
  //   this.appComponent.loading = false;
  // }

  // public allData(): ExcelExportData {
  //   const result: ExcelExportData = {
  //     data: process(this.dataGrids, {}).data
  //   };

  //   return result;
  // }

  onCloseInfo() {
    // this.editOpened = false;
    // this.infoOpened = false;
    // this.updateProgressOpened = false;
    // this.loading = true;

    this.onReload();
    // this.selectedLangID = this.appConsts.defaultLangID;
    // this.language.set(this.selectedLangID);
    // this.loading = false;
  }

 


  // async GetAllLang() {
  //   this.loading = true;

  //   const result = await this.appService.doGET('api/Lang/GetAllLang', null);
  //   if (result) {
  //     this.dataLangs = result.Data;
  //     this.dataLangs.unshift({ ID: "vi-VN", Name: "Tiếng Việt" });
  //     for (let i = 1; i < this.dataLangs.length; i++) {
  //       if (this.dataLangs[i].ID == "vi-VN") {
  //         this.dataLangs.splice(i, 1);
  //       }
  //     }
  //     this.loading = false;
  //   }
  // }

  // onChangeLang(langID) {
  //   this.selectedLangID = langID;

  //   var tempItem = {
  //     IsAdd: true,
  //     ID: null,
  //     Name: '',
  //     Description: '',
  //     OrderIdx: '',
  //    CvSearchDocumentID: null,
  //    Type: null
  //   };
  //   var errTemp = Object.assign({}, tempItem);
  //   errTemp.Type = null;
  //   this.dataErr = [errTemp];
    
  //  // this.getDataItemByID(this.dataGridItem.ID);
  //   this.language.set(this.selectedLangID);
  // }
//   onCloseProgress(){this.updateProgressOpened = false}

//   getTotalRowExistbyListID(listID, DataList) {
//    const lisArray =[]= JSON.parse(listID);
//     const List = DataList.filter((s) => lisArray.indexOf(s.ID) !== -1);
//  return List.length;
//   }

  // CvPro5GroupsHandleFilter(e:any) {
  //   this.CvPro5GroupsFilter = this.CvPro5Groups.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  // }

async  CvPro5GroupsSelectionChange(e: any) {
    // console.log(e);
    // this.dataGridItem.CvPro5ListID =e.CvPro5ListID;

  }

  // StatusPaymentsHandleFilter(e:any) {
  //   this.StatusPaymentsFilter = this.StatusPayments.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  // }
  
async  StatusPaymentsSelectionChange(e: any) {
  // console.log(e);
  // this.dataGridItem.CvPro5ListID =e.CvPro5ListID;

}
// public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => {
//   return {
//       ID: this.CvPro5Group.ID,
//       Name: this.CvPro5Group.Name
//   };
// }));
}
