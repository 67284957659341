<div class="news-template-48-dialog">
    <div class="col-md-12 col-12">
      <div class="row">
        <div class="col-md-12 col-12">
          <div [ngClass]="'control'">
            <label>
              <span [textContent]="'Gallery_Dialog_Name' | translate" class="input-label"></span>
              <span class="required">(*)</span>
            </label>
            <kendo-combobox
              [ngClass]="dataErr[0]['Name'] ? 'err' : ''"
              class="font-size input-box"
              [data]="categoriesListFiltered"
              [textField]="'Name'"
              [valueField]="'ID'"
              [filterable]="true"
              (filterChange)="handleFilter($event)"
              [(ngModel)]="dataGalleryItemtemp.Info.MenuID"
              [valuePrimitive]="true"
            >
              <ng-template kendoDropDownListNoDataTemplate>{{'NoRecordsAvailable' | translate}}</ng-template>
            </kendo-combobox>
            <span class="err-text" *ngIf="dataErr[0].Info.MenuID == undefined">Không được bỏ trống trường này</span>
          </div>
        </div>
  
        <div class="col-md-12 col-12">
          <div [ngClass]="'control'">
            <label>
              <span [textContent]="'Gallery_Dialog_Top' | translate" class="input-label"></span>
              <span class="required">(*)</span>
            </label>
            <kendo-numerictextbox
              [(ngModel)]="dataGalleryItemtemp.Info.TopNumber"
              [min]="1"
              [max]="1000"
              [format]="'n0'"
              [autoCorrect]="true"
              [ngClass]="dataErr[0]['Info.TopNumber'] ? 'err' : ''"
              class="input-box"
            >
            </kendo-numerictextbox>
            <span class="err-text" *ngIf="dataErr[0].Info.TopNumber == undefined">Không được bỏ trống trường này</span>
            <span class="err-text" *ngIf="dataErr[0].Info.TopNumber <= 0">Giá trị 1 - 1000</span>
          </div>
        </div>
  
        <div class="col-md-12 col-12">
          <div class="btn-dialog-wrapper">
            <button
              *ngIf="controlDefault || control.Save"
              class="btn-dialog btn-save"
              (click)="onSaveGallery()"
            >
              <span>{{ "Save" | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  