import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppConsts } from '../../services/app.consts';
import { AppInfo } from '../../services/app.info';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguage } from '../../services/app.language';
import { AppService } from '../../services/app.service';
import { Meta, Title } from '@angular/platform-browser';
import { AppSwal } from 'src/app/services/app.swal';
import { Notification } from '../../services/app.notification';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppRouter } from '../../app.router';

@Component({
  selector: 'app-organization-structure',
  templateUrl: './organization-structure.component.html',
  // styleUrls: ['./organization-structure.component.scss']
})
export class OrganizationStructureComponent implements OnInit {
  @ViewChild('prev') prev;
  postList: any;
  allUnits: any;
  director: any;
  subDirector: any;
  unitTypes: any;
  units: Array<any>;
  allAccounts: any;
  accounts: any;
  unitName: any;
  infoOpened = false;
  isEmployee = true;
  page = 1;
  pageSize = 10;
  maxSize = 4;
  lastPage: any;

  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any;
  @Output() deleteBodyItem = new EventEmitter();
  constructor(private language: AppLanguage,
    private appService: AppService,
    private appInfo: AppInfo,
    private appSwal: AppSwal,
    private authenticationService: AuthenticationService,
    public appConsts: AppConsts,
    public appControls: AppControls,
    private notification: Notification,
    private translate: TranslateService,
    private meta: Meta) {
    this.postList = AppConsts.page.postList;
  }

  ngOnInit(): void {
    this.getAllUnits();
  }

  async getAllUnits() {
    var params = {
      langID: this.language.get()
    }
    const result = await this.appService.doGET('api/PortalBody/GetUnits', params);
    this.allUnits = result.Data;
    this.director = this.allUnits.find(e => e.ParentID == "" || e.ParentID == null);
    this.subDirector = this.allUnits.find(e => e.ParentID == this.director.ID);
    this.unitTypes = this.allUnits.filter(e => e.ParentID == this.subDirector.ID);
    this.units = [];
    for (let i = 0; i < this.unitTypes.length; i++) {
      this.units.push(this.allUnits.filter(e => e.ParentID == this.unitTypes[i].ID));
    }

  }

  async getAccount(unitID) {
    this.accounts = [];
    const result = await this.appService.doGET('api/PortalBody/GetUserByUnit', { 
      'langID': this.language.get(),
      'unitID': unitID 
    });
    if (result && result.Data) {
      this.accounts = result.Data;
    }
    this.lastPage = (this.accounts.length - this.accounts.length % 10) / 10 + 1;
    setTimeout(() => { if (this.isEmployee) this.handlePageChange() }, 1);
  }

  handlePageChange() {
    let pageItem = document.getElementsByClassName('page-item');
    if (pageItem.length > 0) {
      let prevItem = pageItem.item(1);
      let nextItem = pageItem.item(pageItem.length - 2);
      if (prevItem.classList.contains('disabled')) {
        prevItem.classList.add('hide');
      }
      else {
        prevItem.classList.remove('hide');
      }
      if (nextItem.classList.contains('disabled')) {
        nextItem.classList.add('hide');
      }
      else {
        nextItem.classList.remove('hide');
      }
    }
  }

  infoDialog(info) {
    console.log(info);

    if (localStorage.getItem('tokenPortal')) {
      this.infoOpened = true;
      this.isEmployee = (info.ParentID == '' || info.ParentID == null || info.ParentID == '0') ? false : true;
      this.unitName = info.Name;
      this.getAccount(info.ID);
    }
  }
  onCloseDialog() {
    this.infoOpened = false;
  }

  enableChoice() {

    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
}
