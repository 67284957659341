<section class="row mx-0 water-bill-information">

  <div [style.width]="bodyWidth +'px'" class="auto">
    <div class="wrapper">

      <!-- <div class="col-md-1"></div> -->
      <div class="row">
        <div class="col-md-3 col-12 categories-area">
          <div class="titile"> {{ "Hạng mục dịch vụ" | translate}}
          </div>
       
          <div class="round-checkbox form-row-checkbox">
              <div class="checkboxFour">
                  <input type="checkbox" value=""
                      id="checkboxFourInput"  />
                  <label for="checkboxFourInput"></label>
              </div>
              <div class="label-text">{{ "Tra cứu thông tin tiền nước"
                  |
                  translate}} </div>
          </div>
          <div class="round-checkbox form-row-checkbox">
              <div class="checkboxFour1">
                  <input type="checkbox1" value=""
                      id="checkboxFourInput1"  />
                  <label for="checkboxFourInput1"></label>
              </div>
              <div class="label-text">{{
                  "Tra cứu kết quả giải quyết hồ sơ" |
                  translate}} </div>
          </div>
            <div class="round-checkbox form-row-checkbox">
              <div class="checkboxFour2">
                  <input type="checkbox2" value=""
                      id="checkboxFourInput2"  />
                  <label for="checkboxFourInput2"></label>
              </div>
              <div class="label-text">{{
                  "Tra cứu kết quả giải quyết khiếu nại" |
                  translate}}</div>
          </div>
      </div>
        <div class="col-md-9 col-12 send-background">
          <h3 class="quiz-title">{{ "Thông tin tiền nước" | translate }}</h3>

          <div class="row mx-0">
            <div class="col-md-12 col-12">

              <form class="quiz-form" [formGroup]="userForm">

                <fieldset class="quiz-form-fieldset">
                  <div class="button"></div>
                  <hr />
                  <div class="row">
                    <div class="row">
                      <div class="col-md-5 col-12">
                        <kendo-formfield>
                          <div>
                            <div class="data-title">{{ "Số sổ đăng bộ" |
                              translate}}
                            </div>
                            <div class="quiz-input-item">
                              <input formControlName="AccountName"
                                [placeholder]="'Ví dụ: 1324xxxx' | translate"
                                kendoTextBox required />
                              <div class="quiz-error"
                                *ngIf="!validForm && !userForm.get('AccountName').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                      </div>
                      <div class="col-md-2 col-12">
                        <kendo-formfield>
                          <div class>
                            <div class="data-title">{{ "Tháng" | translate}}
                            </div>
                            <div class="quiz-input-item">
                              <kendo-combobox formControlName="LVID"
                                [placeholder]="'' | translate"
                                [data]="monthFilter" [textField]="'Name'"
                                [valueField]="'ID'" [valuePrimitive]="true"
                                [filterable]="true"
                                (filterChange)="lvHandleFilter($event)" required>
                              </kendo-combobox>
                              <div class="quiz-error"
                                *ngIf="!validForm && !userForm.get('LVID').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                      </div>
                      <div class="col-md-2 col-12">
                        <kendo-formfield>
                          <div class>
                            <div class="data-title">{{ "Năm" | translate}}
                            </div>
                            <div class="quiz-input-item">
                              <kendo-combobox formControlName="LVID"
                                [placeholder]="'' | translate"
                                [data]="yearsListFilter" [textField]="'Name'"
                                [valueField]="'ID'" [valuePrimitive]="true"
                                [filterable]="true"
                                (filterChange)="lvHandleFilter($event)" required>
                              </kendo-combobox>
                              <div class="quiz-error"
                                *ngIf="!validForm && !userForm.get('LVID').valid">
                                {{ "Contact_Error" | translate }}
                              </div>
                            </div>
                          </div>
                        </kendo-formfield>
                      </div>

                      <div class="col-md-3 col-12">
                        <div class="form-row form-row-mb">
                          <div class="data-title"></div>
                          <div class="quiz-button-item">
                            <button type="submit" (click)="onSubmit()"
                              class="quiz-submit-button">
                              {{ "Tra cứu"| translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-header">{{ "Thông tin hóa đơn" | translate }}</div>
                  <div class="row">
                    <div class="col-md-12 col-12">
                      <strong class="text-header-2">{{ "Tên khách hàng:" |
                        translate }}</strong>
                      <span class="text-header-3">{{ "Nguyễn Thanh Tú Nhật" |
                        translate }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-12">
                      <strong class="text-header-2">{{ "Địa chỉ:" | translate }}</strong>
                      <span class="text-header-3">{{
                        "12/1 Nguyễn Thị Minh Khai, phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh"
                        | translate }}</span>
                    </div>
                  </div>
                  <div *ngIf="dataGrids.length > 0"  class="row padding-top">
                    <div class="col-md-12 col-12">
                      <div class="panelbar-wrapper wb-water-bill-information">
                        <kendo-grid [data]="dataGridGridDataResult"
                          (pageChange)="onGridPageChange($event)"
                          (selectedKeysChange)="ondataItemselectedKeysChange()"
                          [pageSize]="dataGridPageSize" [skip]="dataGridSkip"
                          [pageable]="true"
                          [loading]="loading"
                          [filterable]="false" [groupable]="false"
                          [group]="dataGridState.group"
                          [filter]="dataGridState.filter"
                          (dataStateChange)="onGridDataStateChange($event)">
                          <kendo-grid-column [field]="'STT'" [width]="15"
                            [title]="'STT' | translate" class="g-col-checked">
                          </kendo-grid-column>
                          <kendo-grid-column [field]="'Year'" [width]="30"
                            [title]="'Kỳ/Năm' | translate">
                          </kendo-grid-column>
                          <kendo-grid-column [field]="'SomeBills'" [width]="50"
                            [title]="'Số hóa đơn' | translate">
                          </kendo-grid-column>
                          <kendo-grid-column [field]="'Date'"
                            [width]="40" [title]="'Ngày hóa đơn' | translate">
                          </kendo-grid-column>
                          <kendo-grid-column [field]="'Total'" [width]="50"
                            [title]="'Tổng tiền nước' | translate">
                          </kendo-grid-column>

                          <kendo-grid-column [field]="'Status'" [width]="40"
                            [title]="'Trạng thái' | translate">
                          </kendo-grid-column>
                          <kendo-grid-column [field]="'Notification'" [width]="60"
                            [title]="'Giấy thông báo và hóa đơn' | translate">
                          </kendo-grid-column>

                          <kendo-grid-messages
                            [noRecords]="'NoRecords' | translate"></kendo-grid-messages>
                        </kendo-grid>
                      </div>
                    </div>

                    <div  class="panelbar-wrapper  mb-water-bill-information ">
                      <div class="txt-break item-menu"
                        *ngFor="let dataItem of dataGrids">
                        <div class="row">
                          <div class="col-md-12 col-10">
                            <strong> {{ "STT" | translate }}: </strong>
                            <span class="value">{{ dataItem.STT }}</span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Tên" | translate }}: </strong>
                            <span class="value">{{ dataItem.Year }}</span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Kỳ/Năm" | translate }}: </strong>
                            <span class="value">{{ dataItem.SomeBills }}</span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Số hóa đơn" | translate }}: </strong>
                            <span class="value">{{ dataItem.Date }}</span>
                          </div>

                          <div class="col-md-12 col-12">
                            <strong> {{ "Tổng tiền nước" | translate }}:
                            </strong>
                            <span class="value">{{ dataItem.Total }}</span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Trạng thái" | translate }}: </strong>
                            <span class="value">{{ dataItem.Status }}</span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Giấy thông báo và hóa đơn" | translate
                              }}: </strong>
                            <span class="value">{{ dataItem.Notification }}</span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- nếu null thì hiển thị  -->
                  <div *ngIf="dataGrids.length == 0"  class="center" >
                    <img class="image-null" src="../../assets/images/image1.jpg" />
                    <div class="text-null">Hiện chưa có dữ liệu</div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</section>
