<div class="login-page">
  <div class="panel-login col-xl-4 col-lg-5 col-md-6 col-sm-10 col-10">
    <div class="sub-item">
      <img src="/assets/images/logo-sg-tourist.png" class="logo" alt="logo" />
    </div>
    <div class="description mb-4">
      <h6>{{ "Signup_Account" | translate }}</h6>
      <span class="content">{{ "Signup_Account_Description" | translate }}</span>
    </div>
  </div>
</div>
