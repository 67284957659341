import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConsts {
  public static readonly ROOT_URL = 'http://localhots:4000/';
  public static readonly TELEPHONE = '(84-8) 38224224';
  public static readonly NAME_PAGE = 'Trang Chủ';
  public static readonly ADDRESS = '6 Alexandre De Rhodes, Quận 1, TPHCM';
  public static readonly Email = 'Trang Chủ';
  public static readonly IMAGE_ICON = 'favicon.ico';
  public static readonly TITLE = 'Sở Ngoại Vụ Thành Phố Hồ Chí Minh';
  public static readonly SCHEMA_CONTEXT = 'https://schema.org';
  public static readonly SCHEMA_TYPE = 'GovernmentOffice';
  public static readonly LATITUDE = 10.8501;
  public static readonly LONGTITUDE = 106.7651;
  public static readonly pageSkip = 0;
  public static readonly pageSize = 10;
  public pageSkip = 0;
  public pageSize = 10;
  public defaultLangID = 'vi-VN';
  public static defaultLangID = 'vi-VN';
  public static readonly VIETNAMESE = 'vi-VN';
  public static readonly ENGLISH = 'en-US';
  public static readonly DEFAULT_IMAGE = '/assets/images/default-image.png';
  public static page = {
    login: 'login',
    signup: 'signup',
    main: '',
    initial: 'initial',
    enterCode: 'code',
    resetPassword: 'reset-password',
    about: 'about',
    postDetail: 'post-detail',
    postList: 'post-page/post-list',
    postPage: 'post-page',
    error: 'error',
    searchNews: 'search-news',
    receiveQuestions: 'receive-questions',
    questionSearch: 'question/question-search',
    questionInfo: 'question/question-Info',
    portal: 'portal',
    receiveDocument: 'receive-document',
    registerDocument: 'document/register-document',
    submitDocument: 'document/submit-document',
    searchDocument: 'document/search-document',
    paymentResult: 'document/payment-result',
    forgotPassword: 'forgot-password',
    galleryDialog: 'news-template-1-dialog',
    templatePage: 'template-page',
    previewTemplatePage: 'preview-template-page',
    introPage: 'intro-page',
    dataMenu: 'data-menu',
    partiesAndUnions: 'parties-and-unions',
    contact: 'contact',
    field: 'field',
    userInfo: 'user-info',
    library: 'library',
    images: 'images',
    settings: 'settings',
    changePassword: 'change-password',
    support: 'support',
    videos: 'videos',
    workingSchedule: 'working-schedule',
    electronicText: 'electronic-text',
    memberUnit: 'member-unit',
    libraryText: 'library-text',
    governmentPage: 'government-page',
    searchPage: 'search-page',
    quizPage: "quiz-answer",
    quizSearch: "quiz-search",
    quizCategorySearch: "quiz-category-search",
    customerCare: "customer-care",
    waterBillInformation:"water-bill-information",
    fileSettlemen:"file-settlemen",
    notifyPage: "notify-page"
  };
  public static readonly RECEIVE_QUESTIONS =
    'portal/question/receive-questions';
  public static readonly RECEIVE_DOCUMENT = 'portal/receive-document';
  public static readonly RECEIVE_DATA_MENU = 'template-page/data/data-menu';
  public static readonly PATH_COMPANY_INTRO = 'company-intro';
  // menu Giới thiệu
  public static readonly TAM_NHIN_SU_MENH = 'Tam-nhin-su-menh';
  public static readonly LINH_VUC = 'Linh-vuc';
  public static readonly CO_CAU_TO_CHUC = 'Co-cau-to-chuc';
  public static readonly LICH_SU_HINH_THANH = 'Lich-su-hinh-thanh';
  public static readonly GIOI_THIEU_CHUNG_VE_CONG_TY =
    'Gioi-thieu-chung-ve-cong-ty';
  public static readonly GIAI_THUONG_VA_THANH_TUU = 'Giai-thuong-va-thanh-tuu';
  // menu Đảng và đoàn thể
  public static readonly DANG_BO_TONG_CONG_TY = 'Dang-bo-Tong-cong-ty';
  public static readonly CONG_DOAN = 'Cong-doan';
  public static readonly DOAN_THANH_NIEN = 'Doan-thanh-nien';
  // menu Văn bản điện tử
  public static readonly VAN_BAN_MOI = 'Van-ban-moi';
  public static readonly VAN_BAN_CHI_DAO_DIEU_HANH = 'Van-ban-chi-dao-dieu-hanh';
  public static readonly BIEU_MAU = 'Bieu-mau';
  public static readonly QUY_TRINH_THU_TUC_HANH_CHINH_ISO = 'Quy-trinh-thu-tuc-hanh-chinh-ISO';
  public static readonly HUONG_DAN_NGHIEP_VU_VAN_PHONG = 'Huong-dan-nghiep-vu-van-phong';

  //Enum form API
  public static ETemplateDetailType =
    {
        HEADER : 0,
        FOOTER : 1,
        LIEN_HE : 2,
        FRAME : 3,
    }
    public static readonly SIZE_COVER = 'Tự động (Theo bố cục)';
    public static readonly SIZE_FILL = 'Đầy đủ';
  public static readonly SIZE_ADJUST = 'Tùy chỉnh';
  public static readonly LINK_DEFAULT_IMAGE = "../../assets/images/default.png";
  public static readonly POST_DETAIL_ROUTE = "post-detail/";
  public static nodeType = {
    rectangleWithShadow : 1,
    circle: 2,
    rectangle: 3,
    rectangleWithHTML: 4
  }
  public static chartTypeInfo = {
    SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_VUONG_CO_BONG : 0,
    SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_VUONG_KHONG_CO_BONG : 1,
    SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_TRON : 2,
    SO_DO_THANG_TU_TRAI_SANG_PHAI_NUT_TRON : 3,
    SO_DO_KHOI_DEU : 4,
    SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_VUONG_KHONG_CO_BONG_CO_ANH: 5
  }

  public static NewsTemplateNumber = {
    NEWS_19: "27",
    NEWS_20: "28",
    NEWS_21: "29",
    NEWS_22: "30",
    NEWS_23: "31",
    NEWS_24: "32",
    NEWS_25: "33",
    NEWS_26: "34",
    NEWS_27: "35",
    NEWS_28: "36",
    NEWS_29: "37",
    NEWS_30: "38",
    NEWS_31: "39",
    NEWS_32: "40",
    NEWS_33: "41",
    NEWS_34: "42",
    NEWS_35: "43",
    NEWS_36: "44",
    NEWS_37: "45",
    NEWS_38: "46",
    NEWS_39: "47",
    NEWS_40: "48",
    NEWS_41: "49",
    NEWS_42: "50",
    NEWS_43: "51",
    NEWS_45: "53",
    NEWS_44: "52",
    NEWS_46: "54",
    NEWS_47: "55",
    NEWS_48: "56",
    NEWS_49: "57",
    NEWS_50: "58",
    NEWS_51: "59",
    NEWS_52: "60",
    NEWS_53: "61",
    NEWS_54: "62",
    NEWS_55: "63",
    NEWS_56: "64",
    NEWS_57: "65",
  }
  
  public static ProgramLayoutType  = {
    Program_Intro: 43,
    Program_Text: 44,
    Program_Timeline: 45,
    Program_Report: 46
  }
}
