import {Router, ActivatedRoute, Params} from '@angular/router';
import {OnInit, Component} from '@angular/core';
import { AppService } from '../../services/app.service';
import { AppConsts } from "../../services/app.consts";
import { AppComponent } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  // styleUrls: ['./payment-result.component.css']
})
export class PaymentResultComponent implements OnInit {
  msg = "";
  loading = false;
  status = 0;
  fee = 0;
  code = '';
  password = '';
  
  constructor(private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private appComponent: AppComponent,
    private title: Title,
    private translate: TranslateService,) { }

  ngOnInit() {
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.msg = params['msg'];
      
      this.status = params['status'];
      this.fee = params['fee'];
      this.code = params['code'];
      this.password = params['password'];
    });
    this.title.setTitle(this.translate.instant('Payment_Result'));
  }  
  goBack() {
    //window.location.href = window.location.origin + "/#/" + AppConsts.page.receiveDocument;
    window.location.href =  AppConsts.page.main;
  }
}
