<div class="images-slider">
  <div *ngIf="isAdminFlg" class="container-fluid p-0">
    <div class="row position-relative m-0 banner-container">
      <div class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex' : ''">
        <div class="three-dot-area" (click)="enableChoice()">
          <img
            class="three-dot"
            src="../../assets/images/three_dot_vertical.png"
            alt=""
          />
        </div>
        <div class="menu" *ngIf="isShowMenu">
          <div class="menu-row-current-border" (click)="editComponent()">
            <div class="menu-item-2">{{ "Upload_Image" | translate }}</div>
          </div>
          <div class="menu-row-current" (click)="deleteComponent()">
            <div class="menu-item-3">{{ "Delete" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">
    <!-- <p class="uppercase title-text">{{ postTittle }}</p> -->
    <div class="uppercase post-more">
      <div class="primary-title">
        {{ "Dịch vụ của" | translate }}
        <span>SAWACO</span>
        <!-- <i class="fa fa-angle-right icon-right"></i> -->
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-12 col-md-12 col-xl-12 sub-post-title">
    <div class="post-more">
      <div class="sub-title">
        <p>
          “SAWACO là công ty cung cấp nước sạch, an toàn, liên tục cho siêu đô
          thị Thành phố Hồ Chí Minh và các khu vực lân cận – luôn sẵn sàng đổi
          mới, sáng tạo để đem tới cho khách hàng lợi ích thiết thực từ sản phẩm
          đạt chuẩn và dịch vụ đồng bộ”
        </p>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-12 col-md-12 col-xl-12">
    <div class="item-list">
      <div class="arrow">
        <div class="prev-arrow" (click)="arrowPrev()">
          <img src="../../assets/images/arrow-prev.svg" alt="ICON" />
        </div>
        <div class="next-arrow" (click)="arrowNext()">
          <img src="../../assets/images/arrow-next.svg" alt="ICON" />
        </div>
      </div>
      <div class="slide">
        <div class="item" *ngFor="let item of currentSlide">
          <div class="image">
            <img
              *ngIf="item.ImageUrl"
              class="post-image"
              [src]="appService.apiRoot + item.ImageUrl"
              alt="error-image"
            />
            <img
              *ngIf="
                item.ImageUrl == this.LINK_DEFAULT_IMAGE ||
                item.ImageUrl == null ||
                item.ImageUrl == undefined ||
                item.ImageUrl == ''
              "
              class="post-image"
              src="../../assets/images/image-water.svg"
              alt="error-image"
            />
          </div>
          <div class="title">
            <h4>{{ item.Name }}</h4>
          </div>
          <div class="text-item">
            <p>{{ item.Description }}</p>
          </div>
          <div class="btn">
            <a [href]="item.LinkUrl" class="btn-detail">Chi tiết</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <kendo-dialog
    *ngIf="manageDialog"
    (mousedown)="$event.stopPropagation()"
    [ngClass]="
      isGallery == false
        ? 'router-outlet-content dialog-info window-center'
        : 'router-outlet-content-gallery dialog-info window-center'
    "
  >
    <div
      [ngClass]="isGallery == false ? 'employe-detail-mb' : 'employe-detail'"
    >
      <div class="slider-dialog close-div">
        <div class="title-info-req">
          <span
            *ngIf="dltFlg == false && isGallery == false"
            [textContent]="'Select_Images' | translate"
          ></span>
          <span
            *ngIf="dltFlg == false && isGallery == true"
            [textContent]="'Gallery' | translate"
          ></span>
          <span
            *ngIf="dltFlg == true"
            [textContent]="'Gallery_Scrollview_Delete' | translate"
          ></span>
        </div>
        <div *ngIf="dltFlg == false" class="close-icon-div">
          <img
            *ngIf="isGallery == false"
            class="close-icon"
            src="../../assets/images/close-btn-black.png"
            alt=""
            (click)="onClose($event)"
          />
          <img
            *ngIf="isGallery == true"
            class="close-icon"
            src="../../assets/images/close-btn-black.png"
            alt=""
            (click)="onGoBack()"
          />
        </div>
      </div>
    </div>
    <app-archive
      *ngIf="dltFlg == false"
      [pageSelectedID]="pageSelectedID"
      [ID]="ID"
      [info]="info"
      [templateID]="templateID"
      [galleryData]="galleryData"
      (onSave)="onSave($event)"
      (addNew)="addNewBanner($event)"
      (deleteAll)="deleteAllBanner($event)"
      (isGallery)="isGalleryDialog($event)"
      [isGoBack]="isGallery"
    >
    </app-archive>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span
          [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"
        ></span>
      </div>
      <div>
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog> -->
</div>
