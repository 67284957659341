<article class="news-template-12" (window:resize)="onResize($event)">
  <div class="container-fluid">
    <div class="row">
      <div class="main-item">
        <div class="row">
          <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
            <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
            <div class="menu" *ngIf="isShowMenu">
              <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{'Edit' | translate}}</div>
              </div>
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{'Delete' | translate}}</div>
              </div>
            </div>
          </div>

          <!-- <div class="col-sm-1 col-1 col-md-1 col-xl-1"></div> -->
          <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-title">           
            <div class="uppercase-black">{{ postTittle }}</div>
            <div class="row">
              <div *ngFor="let item of topNews; let i = index" [routerLink]="['post-detail/' + item.UrlPath]"
                  routerLinkActive="router-link-active" [ngClass]="checkIsRoundNum(i) == true ? 'intro-des': 'intro-des-reserve' ">
                <div class="center-img pointer">
                  <a [href]="getDetailUrl(item.UrlPath)" 
                    [ngClass]="isErrImg == false 
                    ? 'col-sm-12 col-12 col-md-3 col-xl-3 image-circle'
                    :'col-sm-12 col-12 col-md-3 col-xl-3 image-circle-no-border' ">
                    <img [ngClass]="isErrImg == false 
                      ? 'col-sm-12 col-12 col-md-3 col-xl-3 image-circle'
                      :'col-sm-12 col-12 col-md-3 col-xl-3 image-circle-no-border' "
                      *ngIf="item.ImageUrl != '../../assets/images/default.png'" 
                      [src]="getFullUrlAPI(item.ImageUrl)" [alt]="this.title" 
                      (error)="onImgError($event)"
                    />
                  </a>
                  
                  <img class="col-sm-12 col-12 col-md-3 col-xl-3 image-circle-no-border" *ngIf="item.ImageUrl == '../../assets/images/default.png'" [src]="item.ImageUrl" [alt]="this.title" />
                </div>
                
                <div class="col-sm-12 col-12 col-md-9 col-xl-9 intro-text">
                  <a [href]="getDetailUrl(item.UrlPath)" class="field-detail">
                    <div class="field-name pointer">{{ item.Title }}</div>
                    <div class="letter pointer">{{item.Summary}}</div>
                  </a>
                </div>
                <!-- <div *ngIf="checkIsRoundNum(i) == false" class="center-img">
                  <img class="col-sm-12 col-12 col-md-3 col-xl-3 image-circle" [src]="getFullUrlAPI(item.ImageUrl)" [alt]="this.title" />
                </div> -->
              </div>
            </div>  
          </div>
          <!-- <div class="col-sm-1 col-1 col-md-1 col-xl-1"></div> -->
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
            </div> -->
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info dialog-width">
    <div class="news-template-12-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-12-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-12-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
