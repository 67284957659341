import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-working-schedule',
  templateUrl: './working-schedule.component.html',
})
export class WorkingScheduleComponent implements OnInit {
  public gridData = [
    {
      Day: 'Thứ 2 (04/09)',
      MorningSchedule: [
        '08:30 Chào cờ đầu tuần _ Văn phòng chính phủ đầu tư',
        '09:30 Họp thống kê kết quả điều hành thông tin',
        '10:30 Học lớp cảm tình Đảng',
      ],
      AfternoonSchedule: [
        '13:30 Phó Giám đốc học lớp CVVV',
        '14:30 Họp về chương trình công tác của P.XTTT ( phòng họp số 4 )',
      ],
      Name: 'Việc 1',
    },
    {
      Day: 'Thứ 3 (05/09)',
      MorningSchedule: [
        '08:30 Chào cờ đầu tuần _ Văn phòng chính phủ đầu tư',
        '09:30 Họp thống kê kết quả điều hành thông tin',
        '10:30 Học lớp cảm tình Đảng',
      ],
      AfternoonSchedule: [
        '13:30 Phó Giám đốc học lớp CVVV',
        '14:30 Họp về chương trình công tác của P.XTTT ( phòng họp số 4 )',
        '15:30 Họp về Đề án thành lập Đội PCCC chuyên ngành (A.Hiệp P.TCKT, VP, Đội BV, BQLCDA)_ phòng 1',
      ],
      Name: 'Việc 2',
    },

    {
      Day: 'Thứ 4 (06/09)',
      MorningSchedule: ['10:30 Học lớp cảm tình Đảng'],
      AfternoonSchedule: [
        '13:30 Phó Giám đốc học lớp CVVV',
        '14:30 Họp về chương trình công tác của P.XTTT ( phòng họp số 4 )',
        '15:30 Họp về Đề án thành lập Đội PCCC chuyên ngành (A.Hiệp P.TCKT, VP, Đội BV, BQLCDA)_ phòng 1',
      ],
      Name: 'Việc 3',
    },

    {
      Day: 'Thứ 5 (07/09)',
      MorningSchedule: [],
      AfternoonSchedule: [],
      Name: 'Việc 4',
    },

    {
      Day: 'Thứ 6 (08/09)',
      MorningSchedule: [],
      AfternoonSchedule: [],
      Name: 'Việc 5',
    },

    {
      Day: 'Thứ 7 (09/09)',
      MorningSchedule: [],
      AfternoonSchedule: [],
      Name: 'Việc 6',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
