import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppConsts } from '../services/app.consts';
import { AppService } from '../services/app.service';
import { EventEmitter, Input, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { Router } from '@angular/router';
import { AppGuid } from 'src/app/services/app.guid';
import { TranslateService } from '@ngx-translate/core';
import { AppSwal } from '../services/app.swal';
import { Notification } from '../services/app.notification';
import { AuthenticationService } from '../services/authentication.service';
import { SuccessEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-header-template1',
  templateUrl: './header-template1.component.html',
  // styleUrls: ['./header-template1.component.scss']
})
export class HeaderTemplate1Component implements OnInit {
  @Input() isAdminFlg = true;
  @Output() navMenu = new EventEmitter();
  isVi: boolean;
  // menus: any[];
  user: any;
  toprightmenus: any;
  openFlg: boolean = false;
  openMenuFlg: boolean = false;
  header: any;
  isLoaded: boolean = false;
  isMenuLoad: boolean = false;
  isHeaderLoad: boolean = false;
  searchNews: string;
  postList: string;
  isSearch:boolean = false;
  selectedMenuID : any = [];
  editFlg = false;
  headerItem: any;
  menuOpened = false;
  infoOpened = false;
  avatarUpload = false;
  isParentUrl = false;
  parent: any;
  parentUrl: any;
  menuSelected: any;
  dataMenu: any;
  dataMenuItem: any;
  addMenu = false;
  btnHeader: any;
  fileSaveUrl: any;
  imgUrl: any;
  Types: Array<{ Name: string, ID: number }>;
  TypesFilter: Array<{ Name: string, ID: number }>;
  parentPages: Array<{ Name: string, ID: string }>;
  parentPagesFilter: Array<{ Name: string, ID: string }>;
  @Input() menu: any;
  menus: Array<{ Name: string, ID: string, MenuFlg: boolean }>;
  menuFilter: Array<{ Name: string, ID: string }>;
  customCss = 0;
  dataErr: any;
  popupClass = "popup-width";
  //lastselectedMenuID : any;
  languages: Array<any> = [
    {
      id: 0,
      culture: AppConsts.VIETNAMESE,
      text: 'Tiếng Việt',
    },
    {
      id: 1,
      culture: AppConsts.ENGLISH,
      text: 'English',
    },
  ];
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private router: Router,
    private appSwal: AppSwal,
    private authenticationService: AuthenticationService,
    private notification: Notification,
    private guid: AppGuid,
    private translate: TranslateService,

  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.postList = AppConsts.page.postList;
    // this.getHeaderInfo();

    // this.setDefaultHeaderItem();
  }

  async ngOnInit() {
    this.get4Type();
    this.setDefaultHeaderItem();
    this.getPageParent();
    if (!this.language.get() || this.language.get() == AppConsts.VIETNAMESE) {
      this.isVi = true;
    } else {
      this.isVi = false;
    }
    await this.getTopRightMenu();
    this.getMenu();
    // this.searchNews = AppConsts.page.searchNews;
    this.getHeader();
  }
  async getMenu() {
    const dataRequest = {
      searchText: "",
      Langid: this.language.get()
    };

    const result = await this.appService.doGET('api/Menu', dataRequest);
    if (result && result.Status === 1) {
      let menuChecked = (result.Data && result.Data.length > 0) ? result.Data.filter(x => x.MenuFlg == true) : []
      this.menus = menuChecked;
      this.navMenu.emit(this.menus);
      this.isMenuLoad = true;
      this.isPageLoaded();
    }
    // this.navMenu.emit(this.menus);
    // this.isMenuLoad = true;
    // this.isPageLoaded();
    // }
  }

  changeLanguage(language: string) {
    if (language != this.language.get()) {
      this.isVi = !this.isVi;
      this.language.set(language);
      window.location.reload();
    }
  }

  onReload() {
    this.getMenu();
    this.getHeader();
  }

  async getHeader(){
    const result = await this.appService.doGET('api/Portal/GetInfoHeader', {'langID': this.language.get()});
    if(result && result.Status === 1){
      this.headerItem = result.Data;
      // console.log('*****headerItem \n' + this.headerItem.Info)
      // appService.apiRoot + header[0].ImageUrl
      // if(typeof this.headerItem.Info == 'string') {
      //   var arr = [];
      //   try {
      //     arr = JSON.parse(this.headerItem.Info);
      //   } catch(e) {
      //       arr.push(this.headerItem.Info);
      //   }
      //   this.headerItem.Roles = arr;
      // console.log('*****headerItem 2 \n' + this.headerItem)

      // }
      this.dataMenu = [];
      this.headerItem.Info.MenuIDs.forEach(e => {
        var item = this.toprightmenus.filter(m => m.ID.toLowerCase() == e.toLowerCase());
        if(item.length!=0) {
          this.dataMenu.push(item[0]);
        }
      });
      this.isHeaderLoad = true;
      this.isPageLoaded();
    }
  }

  isPageLoaded() {
    if (this.isHeaderLoad && this.isMenuLoad) {
      this.isLoaded = true;
    }
  }
  search(text: string, isEnter) {
    if(window.innerWidth < 576) {
      this.isSearch = !this.isSearch;
      if(isEnter){
        this.router.navigate([this.searchNews], { queryParams: { search: text } });
      }
    } else{
      this.router.navigate([this.searchNews], { queryParams: { search: text } });
    }
  }
  open() {
    if(window.innerWidth < 576){
      this.openFlg = !this.openFlg;

      if (this.openFlg) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    }
  }

  selectMenu(event, mother, currentTag, currentLevel) {
    //chỉ áp dụng cho màn hình < 576px
    if (window.innerWidth < 576) {
      //refresh data
      if(currentTag.classList.contains(currentLevel) == true
        && this.selectedMenuID.indexOf(currentTag.id) == -1) {
        var menuObj = mother.getElementsByClassName("menu-obj");
        for (var i = 0; i < menuObj.length; i++) {
          if(menuObj[i].id != currentTag.id) {
            var obj = menuObj[i].children;
            if (this.selectedMenuID != null && this.selectedMenuID.indexOf(menuObj[i].id) != -1) {
              var removeIndex = this.selectedMenuID.findIndex(item => item == menuObj[i].id);
              this.selectedMenuID.splice(removeIndex, 1);
            }
            for(var j = 0;j<obj.length; j++){
              if(obj[j].classList.contains("dropdown-menu") == true){
                obj[j].classList.add("close-menu");
                obj[j].classList.remove("open-menu");
              }
            }
          }
        }
      }

      //Lấy list element con của thẻ vừa click
      var childs = currentTag.children;
      var menu;
      for(var i=0;i<childs.length;i++){
        if(childs[i].classList.contains("dropdown-menu") == true){
          menu = childs[i];
        }
      }

      // kiểm tra nếu thẻ đang click là none thì mở thẻ đang click, ngược lại thì đóng
      if (typeof menu != undefined && menu != null && (menu.classList.contains("open-menu") == false)) {
        menu.classList.remove("close-menu");
        menu.classList.add("open-menu");
        if (this.selectedMenuID.indexOf(currentTag.id) === -1) {
          this.selectedMenuID.push(currentTag.id);
        }
      } else {
        if(typeof menu != undefined && menu != null) {
          menu.classList.remove("open-menu");
          menu.classList.add("close-menu");
          if (this.selectedMenuID.indexOf(currentTag.id) != -1) {
            var removeIndex = this.selectedMenuID.findIndex(item => item == currentTag.id);
            this.selectedMenuID.splice(removeIndex, 1);
          }
        }
      }
    }

    event.stopPropagation();
  }

  goToMenu(item) {
    var k = item;
  }
  geturl(url) {
    var k = url;
  }

  parseURL(url) {
    if(url == AppConsts.RECEIVE_QUESTIONS || url == AppConsts.RECEIVE_DOCUMENT){
      return AppConsts.page.portal;
    } else {
      return url;
    }
  }
  reset(){
    this.isSearch = false;
  }
  onClickFunction(item) {
    // console.log(item);
    this.getBtnFunctionData(item);
  }
  getBtnFunctionData(item) {
    this.btnHeader = [
      {
        id: 'edit',
        text: this.translate.instant('Edit'),
        icon: 'edit',
        click: () => {
          this.openEdit(item);
        }
      }
    ]
    // if(item.ID || item.ImageUrl) {
    if(item.ID) {
      var func = {
        id: 'delete',
        text: this.translate.instant('Delete'),
        icon: 'delete',
        click: () => {
          this.openDelete(item);
        }
      }
      this.btnHeader.push(func);
    }
  }
  openEdit(item){
    this.editFlg = true;
    if(item.ID) {
      this.menuOpened = true;
      this.dataMenuItem = item;
      if(item.ParentID && item.ParentID!='') {
        this.isParentUrl = true;
        this.parent = this.menuFilter.filter(e => e.ID == item.ParentID);
        this.parentUrl = this.parent[0].UrlPath;
      }
    }
    else if(item.ImageUrl) {
      this.avatarUpload = true;
    }
    else {
      this.infoOpened = true;
    }
  }
  async openDelete(item) {
    const option = await this.appSwal.showWarning(this.translate.instant('DeleteMenu_AreYouSure'), true);
    if (option) {
      var index = this.headerItem.Info.MenuIDs.indexOf(item.ID);
      this.headerItem.Info.MenuIDs.splice(index, 1);
      this.onUpdateHeaderInfo();
      this.onReload();
    }

  }
  getHeaderInfo(){
    // this.headerItem = {
    //   MainName : 'Tổng công ty công nghiệp sài gòn' ,

    // DetailName : 'Saigon industry corporation'
    // }
  }
  setDefaultHeaderItem() {
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=admin&typeData=image&acceptExtensions=[".png",".jpg",".jpeg"]`;
    this.headerItem = {
      Image: [],
     Info: {
      Title: "",
      SubTitle: "",
    Link: '',
    MenuIDs: []}
    };
    this.dataMenuItem = {
      Name: '',
      ID: '',
      ParentID: "",
      OrderIndex: 0,
      Url: "",
      PageID: null,
      IsButton: false,
      Childrens: []
    }
    this.isParentUrl = false;
    this.parentUrl = null;
    var errTemp = Object.assign({}, this.headerItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    // this.dataGridSelection = [];
    // this.disabled = false;
    // this.enabledID = true;
  }
  openAddMenu(){
    this.addMenu = true
  }
  get4Type() {
    var a = [];
    a[0] = this.translate.instant('TypeHomepage0');
    a[1] = this.translate.instant('TypeHomepage1');
    a[2] = this.translate.instant('TypeHomepage2');
    a[3] = this.translate.instant('TypeHomepage3');
    a[4] = this.translate.instant('TypeHomepage4');
    this.Types = [];
    for (let i = 0; i < 5; i++) {
      this.Types.push({
        ID: i,
        Name: a[i]
      });
    }
    this.TypesFilter = this.Types.slice();
    // this.loading = false;
  }
  async getPageParent() {
    const dataRequest = {
      searchText: '',
      Langid: this.language.get()
    };
    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result && result.Status === 1) {
      this.parentPages = [];
      this.parentPages.push({
        ID: null,
        Name: 'Root'
      });

      // result.Data.forEach(item => {
      //   if (!item.ParentID) {
      //     this.parentPages.push(item);
      //   }
      // });

      this.parentPagesFilter = result.Data; //this.parentPages.slice();
    }
  }
  checkErr() {
    if(this.customCss == 1) {
      return "x-dialog-unit-contact-err-one";
    } else if(this.customCss == 2) {
      return "x-dialog-unit-contact-err-two";
    }else if(this.customCss == 3) {
      return "x-dialog-unit-contact-err-three";
    }else if(this.customCss == 4) {
      return "x-dialog-unit-contact-err-four";
    } else {
      return "x-dialog-unit-contact"
    }
  }
  public dataMenuFocus = {
    Name: true
  };
  // onCloseAddMenu(){
  //   this.addMenu = false
  // }
  async onUpdateHeaderInfo() {
    const dataRequest = this.createDataRequest();
    const result = await this.appService.doPOST('api/Portal/UpdateHeaderInfo', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  async onUpdateHeaderAvatar(imgUrl) {
    const dataRequest = {
      Title: 'Header Image',
      Type: 2,
      ImageUrl: imgUrl
    }
    const result = await this.appService.doPOST('api/Portal/UpdateHeaderImage', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  async getTopRightMenu() {
    const result = await this.appService.doGET('api/Portal/GetMenuNoChild', {'langID': this.language.get()});
    if (result && result.Status === 1) {
      this.toprightmenus = result.Data;
      this.menuFilter = this.toprightmenus.slice();
      // this.isMenuLoad = true;
      // this.isPageLoaded();
      // console.log('************** toprightmenus \n' + this.headerItem.Info.MenuIDs)

      // for(var i=0; i < this.headerItem.Info?.MenuIDs.length; i++){
      //   this.toprightmenus.push(this.menus.find(x => x.ID == this.headerItem.Info?.MenuIDs[i].toLowerCase()))
      //   var a = this.menus.find((x) => {
      //     return x.ID == this.headerItem.Info?.MenuIDs[i].toLowerCase()
      //   });
      //   console.log(a)
      //   if(a != undefined ) {this.toprightmenus.push(a)}

      //    = this.headerItem.Info?.MenuIDs.filter((s) => s.ID.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      // }
      // console.table(this.toprightmenus)

    }
  }
  menuHandleFilter(value) {
    this.menuFilter = this.toprightmenus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  onChangeMenu(e) {
    this.menuSelected = this.toprightmenus.filter(m => m.ID == e);
  }
  onSuccessAvatarUpload(e: SuccessEvent) {
    if (e.response && e.response.body && e.response.body.Status == 1) {
      if (e.response.body.Data) {
        let data = e.response.body.Data;
        this.imgUrl = `/${data.DirMedia}${data.MediaNm}`;
        // this.imgUrl = `/Upload/Media/${e.files[0].name}`;
      } else {
        this.notification.showError('Tải tập tin thất bại!');
      }
    } else {
      this.notification.showError('Tải tập tin thất bại!');
    }
  }
  onSaveDialog() {
    if(this.editFlg) {
      if(this.avatarUpload) { this.onSaveAvatar(); } else { this.onSaveHeader(); }
    } else { this.onSaveMenu() }
  }
  async onSaveHeader() {
    this.onUpdateHeaderInfo();
    this.onReload();
    this.editFlg = false;
    this.menuOpened = false;
    this.infoOpened = false;
  }
  onSaveMenu() {
    if(this.menuSelected.length!=0) {
      this.dataMenuItem.ID = this.menuSelected[0].ID;
      this.dataMenuItem.Name = this.menuSelected[0].Name;
      this.dataMenuItem.OrderIndex = this.menuSelected[0].OrderIndex;
      this.dataMenuItem.PageID = this.menuSelected[0].PageID;
      this.dataMenuItem.ParentID = this.menuSelected[0].ParentID ? this.menuSelected[0].ParentID : '';
      this.dataMenuItem.Url = this.menuSelected[0].Url;
      let data = this.dataMenu.filter(d => d.ID == this.dataMenuItem.ID);
      if(data.length==0) {
        this.dataMenu.push(this.dataMenuItem);
        this.headerItem.Info.MenuIDs.push(this.dataMenuItem.ID);
        this.onUpdateHeaderInfo();
        this.onReload();
      }
      else {
        this.notification.showError('Trùng chuyên mục');
      }
    }
    else {
      // this.appSwal.showWarning("Fail", false);
    }
    this.editFlg = false;
    this.addMenu = false;
  }
  onSaveAvatar() {
    this.onUpdateHeaderAvatar(this.imgUrl);
    this.onReload();
    this.editFlg = false;
    this.avatarUpload = false;
  }
  createDataRequest() {

    const temp = this.headerItem;
    var MenuIDs = temp.MenuIDs;
    if(typeof temp.MenuIDs == 'object') {
      MenuIDs  = JSON.stringify(temp.MenuIDs);
    }
    return {
      // ID: temp.ID,
      Language: temp.Info.Language,
      Title: temp.Info.Title,
      SubTitle: temp.Info.SubTitle,
      MenuIDs: temp.Info.MenuIDs,
      Link: temp.Info.Link,
    };
  }
  onCloseDialog() {
    this.onReload();
    this.editFlg = false;
    this.addMenu = false;
    this.menuOpened = false;
    this.infoOpened = false;
    this.avatarUpload = false;
  }
}
