import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { AppLanguage } from 'src/app/services/app.language';
import { Notification } from 'src/app/services/app.notification';
import { AppSwal } from 'src/app/services/app.swal';
import { AppControls } from 'src/app/services/app.controls';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
})
export class SettingsPageComponent implements OnInit {
  isAdminFlg: boolean = false;

  constructor(
    public appService: AppService,
    private translate: TranslateService,
    private language: AppLanguage,
    private notification: Notification,
    private appSwal: AppSwal,
    public appControls: AppControls
  ) {}

  ngOnInit(): void {}
}
