<div class="opend-dialog-images ">
  <div class="col-md-12 col-12">
    <div class="container" *ngIf="typeFile == 1" id="imageContainer">
      <ks-modal-gallery [id]="133" [modalImages]="imagesRect" 
        [previewConfig]="previewConfigFiveImages" 
        [slideConfig]="{infinite: true, sidePreviews: {show: false}}"
        [currentImageConfig]="{loadingConfig: {enable: true, type: 2}, description: customFullDescription}">
      </ks-modal-gallery>
      <!-- <div class="slideshow-container">
        <div *ngFor="let item of srcURL; let i = index" class="mySlides" 
          style="justify-content: center; height: -webkit-fill-available;">
          <img [src]="item ? appService.apiRoot + item : '../../assets/images/default.png'" [alt]="item?.Name"
            style="object-fit: contain" />
        </div>
      </div>

      <div>
        <img class="prev" src="../../assets/images/prev.png" alt="" (click)="plusSlides(-1)" />
      </div>

      <div>
        <img class="next" src="../../assets/images/next.png" alt="" (click)="plusSlides(1)" />
      </div>

      <div>
        <div class="padingimg title-right" id="title-right">
          <div class="category" id="category">
            <div *ngFor="let item of srcURL; let i = index" class="category-box" id="BannerBackGround">
              <img [src]="item ? appService.apiRoot + item : '../../assets/images/default.png'" [alt]="item?.Name"
                [attr.id]="'category' + i" (click)="currentSlide(i)" class="img show demo pointer category-name" 
                [style.width.px]="imageWidth"/>
            </div>
          </div>
        </div>
      </div> -->
      


      <!-- <div class="padingimg">
        <div>
          <div class="column" *ngFor="let item of srcURL; let i = index">
            <img [src]="item ? appService.apiRoot + item : '../../assets/images/default.png'" [alt]="item?.Name"
              (click)="currentSlide(i)" class="img show demo pointer" style="width: 100%" />
          </div>
        </div>
      </div> -->
    </div>
    <div class="containter" *ngIf="typeFile == 0">
      <div>
        <section class="video-container" [innerHTML]="
        getVideoUrl(srcURL) | sanitizedHtml
        ">
        </section>
      </div>
    </div>
  </div>
