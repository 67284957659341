import {
  Component,
  OnInit, EventEmitter, Input, Output, HostListener, SimpleChanges
} from '@angular/core';
import { AppConsts } from '../services/app.consts';
import { AppService } from '../services/app.service';
import { AppLanguage } from '../services/app.language';
import { Router } from '@angular/router';
import { AppGuid } from 'src/app/services/app.guid';
import { TranslateService } from '@ngx-translate/core';
import { AppSwal } from '../services/app.swal';
import { Notification } from '../services/app.notification';
import { AuthenticationService } from '../services/authentication.service';
import { SuccessEvent } from '@progress/kendo-angular-upload';
import { AppComponent } from '../app.component';
import { json } from 'express';
import { MyServiceService } from '../my-service.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-header-template5',
  templateUrl: './header-template5.component.html',
  // styleUrls: ['./header-template5.component.scss']
})
export class HeaderTemplate5Component implements OnInit {
  @Input() isAdminFlg = false;
  @Input() templateID;
  @Input() pageSelectedID;
  @Output() navMenu = new EventEmitter();
  @Output() partiesAndUnions = new EventEmitter<boolean>();
  @Output() menuItem = new EventEmitter();
  @Output() menuItemchild = new EventEmitter();
  @Output() menuSearch = new EventEmitter();
  subMenuItem: any;
  isVi: boolean;
  isCollapsed = false;
  // menus: any[];
  loading = false;
  currentUrl: any;
  user: any;
  toprightmenus: any;
  openFlg: boolean = false;
  openMenuFlg: boolean = false;
  header: any;
  isLoaded: boolean = false;
  isMenuLoad: boolean = false;
  isHeaderLoad: boolean = false;
  isLogged: boolean = false;
  searchNews: string;
  postList: string;
  isSearch: boolean = false;
  editFlg = false;
  infoOpened = false;
  // avatarUpload = false;
  isParentUrl = false;
  parent: any;
  parentUrl: any;

  message: any;
  addMenu = false;
  btnHeader: any;
  fileSaveUrl: any;
  searchText: any = '';
  imgUrl: any;
  lastIdx = 0;
  lastID = '';
  Types: Array<{ Name: string; ID: number }>;
  TypesFilter: Array<{ Name: string; ID: number }>;
  parentPages: Array<{ Name: string; ID: string }>;
  parentPagesFilter: Array<{ Name: string; ID: string }>;
  @Input() menu: any;
  @Input() bodyWidth: number = 1140;
  bodyWidthSetup: number = 1140;
  menus: any;
  menuFilter: Array<{ Name: string; ID: string }>;
  menuNews: any;
  menuIntro: any;
  menuEDocument: any;
  menuGovernment: any;
  customCss = 0;
  dataErr: any;
  popupClass = 'popup-width';
  menuIndex: any;
  currentMenu: any;
  currentMenuchild: any;
  languages: Array<any> = [
    {
      id: 'lang-vi',
      culture: AppConsts.VIETNAMESE,
      viText: 'Tiếng Việt',
      enText: 'Vietnamese',
      img: '../../assets/images/lang-vn.png',
    },
    {
      id: 'lang-en',
      culture: AppConsts.ENGLISH,
      viText: 'Tiếng Anh',
      enText: 'English',
      img: '../../assets/images/lang-en.png',
    },
  ];
  child: any;

  //Dual Lang
  dataLangs = [];
  selectedLangID = AppConsts.defaultLangID;
  TemplateDetailsInfo: any;
  imgHeight: number;
  isShowSearchBox: boolean = false;
  innerWidth: any;
  showSubMenu: boolean;
  activeMenuId: number = -1;
  urlLogo = this.appService.apiRoot + "/Upload/Menu/logo_mobile.png" ;
  CHUONG_TRINH_DU_AN = 'CHUONG-TRINH---DU-AN';
  @Input() menuIDSelected: string;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private router: Router,
    private appSwal: AppSwal,
    private authenticationService: AuthenticationService,
    private notification: Notification,
    private guid: AppGuid,
    private translate: TranslateService,
    // private appComponent: AppComponent ,
    public appConsts: AppConsts,
    private myService: MyServiceService
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.postList = AppConsts.page.postList;
  }

  ngOnInit() {
    if (typeof window !== 'undefined'){
      this.innerWidth = window.innerWidth;
      if (this.language.get() == 'vi-VN') {
        this.isVi = true;
      } else {
        this.isVi = false;
      }
      this.init();
      this.getContentWidth();
    }

    // this.route =  this.router.events.subscribe(
    //   (event: any) => {
    //     if (event instanceof NavigationEnd) {
    //        this.pageName = this.router.url
    //     }
    //   }
    // );

  }

  async init() {
    this.setDefaultHeaderItem();
    // this.getPageParent();
    await this.getMenu();
    await this.getHeader();
    this.GetAllLang();
    this.checkIsLogged();
    setTimeout(() => {
      //document.getElementById('BannerBackGround').style.backgroundImage= "url("+this.appService.apiRoot + this.TemplateDetailsInfo.BackGroundUrl+")" ;
      let img = document.getElementById("BannerBackGround");
      this.imgHeight = img.clientHeight;
      let headerImg = document.getElementById("HeaderBackgroundImg")
      if (headerImg) {
        //headerImg.style.height = this.imgHeight+"px";
      }

    }, 500);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.focusNavbarByUrl();
  }

  sendMessage(item) {
    this.myService.sendMessage(item);
  }
  checkIsLogged() {
    if (localStorage.getItem('userInfoPortal')) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }
  }
  async getMenu() {
    const dataRequest = {
      langID: this.language.get(),
    };

    const result = await this.appService.doGET(
      'api/PortalHeader/GetNavigationBar',
      dataRequest
    );
    if (result && result.Status === 1) {
      let menuChecked =
        result.Data && result.Data.length > 0
          ? result.Data.filter((menu) => menu.MenuFlg == true && (!menu.ParentID || menu.ParentID != '')) : [];
      this.menus = menuChecked;
      this.menuNews = this.getMenuNews();
      this.menuIntro = this.getMenuIntro();
      this.menuEDocument = this.getMenuEDocument();
      this.menuGovernment = this.getMenuGovernment();
      this.navMenu.emit(this.menus);
      this.isMenuLoad = true;
      this.isPageLoaded();
    }
    // this.navMenu.emit(this.menus);
    // this.isMenuLoad = true;
    // this.isPageLoaded();
    // }
  }

  changeLanguage(language: string) {
    if (language != this.language.get()) {
      this.isVi = !this.isVi;
      this.language.set(language);
      window.location.reload();
    }
  }

  onReload() {
    this.getHeader();
  }
  onOpenPartiesAndUnions(e) {
    this.partiesAndUnions.emit(true);
  }
  onGetMenuItem(item, index) {
    if(this.innerWidth >= 576){
      let itemLink = document.getElementsByClassName('item-link');
      for (let i = 0; i < itemLink.length; i++) {
        if (itemLink[i].classList.contains('focus')) {
          itemLink[i].classList.remove('focus');
          break;
        }
      }
      itemLink[index].classList.add('focus');
    }
    if (this.currentMenu && (this.currentMenu.Url.indexOf("https://") !== -1 || this.currentMenu.Url == ".")) {
      this.menuItem.emit(item);
      return;
    }
    // this.myService.sendMenuChild(item)
    this.menuItem.emit(item);
  }
  onGetMenuItemchild(item, index) {
    // if(this.innerWidth >= 576){
    //   let itemLink = document.getElementsByClassName('dropdown-item');
    //   for (let i = 0; i < itemLink.length; i++) {
    //     if (itemLink[i].classList.contains('focus')) {
    //       itemLink[i].classList.remove('focus');
    //       break;
    //     }
    //   }
    //   itemLink[index].classList.add('focus');
    // }
    if (this.currentMenuchild && (this.currentMenuchild.Url.indexOf("https://") !== -1 || this.currentMenuchild.Url == ".")) {
      this.menuItemchild.emit(item);
      return;
    }
    this.menuItemchild.emit(item);
    // this.myService.sendMenuChild(item);
    this.myService.sendMessage(item);
  }

  async getHeader() {
    const dataRequest = {
      langID: this.selectedLangID
    };
    const result = await this.appService.doGET('api/PortalHeader', dataRequest);
    if (result && result.Status === 1 && result.Data) {
      this.TemplateDetailsInfo = JSON.parse(result.Data.Data.Info);
      // if(document.getElementById('BannerBackGround') !== null){
      //   document.getElementById('BannerBackGround').style.backgroundImage= "url("+this.appService.apiRoot + this.TemplateDetailsInfo.BackGroundUrl+")" ;
      // }


      if (this.TemplateDetailsInfo == null) {
        this.setDefaultHeaderItem();
      }
    } else {
      this.setDefaultHeaderItem();
    }
    this.isHeaderLoad = true;
    this.isPageLoaded();
  }

  isPageLoaded() {
    if (this.isHeaderLoad && this.isMenuLoad) {
      this.isLoaded = true;
    }
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }
  onSearch() {
    if (!this.searchText) {
      return;
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.navigate([AppConsts.page.searchPage], {
        queryParams: { searchText: this.searchText },
      });
    }
  }

  // search(text: string, isEnter) {
  //   if (window.innerWidth < 576) {
  //     this.isSearch = !this.isSearch;
  //     if (isEnter) {
  //       this.router.navigate([this.searchNews], {
  //         queryParams: { search: text },
  //       });
  //     }
  //   } else {
  //     this.router.navigate([this.searchNews], {
  //       queryParams: { search: text },
  //     });
  //   }
  // }
  open() {
    if (window.innerWidth < 576) {
      this.openFlg = !this.openFlg;

      if (this.openFlg) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
      var dropdownSubmenu = document.getElementsByClassName('dropdown-submenu');
      for (let i = 0; i < dropdownSubmenu.length; i++) {
        if (dropdownSubmenu[i].classList.contains('show')) {
          dropdownSubmenu[i].classList.remove('show');
          break;
        }
      }
    }
  }

  selectMenu(event, mother, currentTag, currentLevel, item, index) {
    this.onGetMenuItem(item, index);
    if (this.currentMenu && (this.currentMenu.Url.indexOf("https://") !== -1 || this.currentMenu.Url == ".")) {
      return;
    }
    if (window.innerWidth < 576) {
      if (!item.Childrens?.length) this.open();
      var menu = document.getElementById(index.toString());
      if (index == this.lastIdx) {
        if (menu?.classList.contains('show')) {
          menu?.classList.remove('show');
        }
        else {
          menu?.classList.add('show');
        }
      }
      else {
        var dropdown = document.getElementById(this.lastID);
        dropdown?.getElementsByClassName('dropdown-submenu')[0]?.classList.remove('show');
        menu?.classList.add('show');
      }
      this.lastID = item.ID;
      this.lastIdx = index;
    }

  }

  goToRoot() {
    let itemLink = document.getElementsByClassName('item-link');
    for (let i = 0; i < itemLink.length; i++) {
      if (itemLink[i].classList.contains('focus')) {
        itemLink[i].classList.remove('focus');
        break;
      }
    }
    itemLink[0].classList.add('focus');
    this.goToMenu(this.menus[0]);
  }

  goToMenu(item) {
    // if (item && item.Childrens && item.Childrens.length > 0) {
    //   this.goToSubMenu(item.Childrens[0]);
    //   return;
    // }
    if(item.Url.indexOf("https://") !== -1){
      this.currentMenu = item;
      window.open(item.Url);
      return;
    } if (item.Url == ".") {
      this.currentMenu = item;
      return;
    } else{
      // for Mobile Navbar
      this.isCollapsed = false;
      this.activeMenuId = -1;

      this.currentUrl = this.router.url;
      if (this.currentUrl != '') {
        if(item.Url != "/"){
          this.router.navigateByUrl(AppConsts.page.main + '/' + item.Url );
          //window.history.pushState("", "", "/" + item.Url);
        } else {
          //window.history.pushState("", "", "/");
          this.router.navigateByUrl(AppConsts.page.main);

        }
      }
      this.menuItem.emit(item);
      this.sendMessage(item)
    }

    // if (window.innerWidth > 576 || (window.innerWidth < 576 && !item.Childrens.length)) {
    //   this.menuItemchild.emit({});
    //   setTimeout(() => {
    //     if (this.menuNews != null && item.ParentID == this.menuNews.ID) {
    //       this.router.navigateByUrl(AppConsts.page.postList + '/' + item.ID);
    //       return;
    //     }


    //     if (item.Url == AppConsts.page.contact) {
    //       this.router.navigateByUrl(AppConsts.page.contact + '/' + item.ID);
    //       return;
    //     }
    //     if (this.menuGovernment != null && item.ID == this.menuGovernment.ID) {
    //       this.router.navigateByUrl(
    //         AppConsts.page.governmentPage + '/' + item.Childrens[0].ID
    //       );
    //       return;
    //     }
    //     if (item.ParentID != null && item.ParentID != '') {
    //       return;
    //     }
    //     this.router.navigateByUrl(item.Url)
    //   }, 10);
    // }
  }

  goToSubMenu(child?, idx?, item?) {
    this.onGetMenuItem(item, idx);
    let index = item.Childrens.findIndex(i => i == child);
    this.onGetMenuItemchild(child, index);

    // for Mobile Navbar
    if(child.Url.indexOf("https://") !== -1){
      this.currentMenu = child;
      window.open(child.Url);
      return;
    } else {
      this.isCollapsed = false;
      this.activeMenuId = -1;

      this.currentUrl = this.router.url;
      if (this.currentUrl != '' && child.Url != "/") {
        this.router.navigateByUrl(AppConsts.page.main + '/'+ child.Url);
        //window.history.pushState("", "", "/" + child.Url);
      }
      // this.myService.sendMenuChild(child)
      // this.sendMessage(child);
      event.stopPropagation();
      event.preventDefault();
    }


    // setTimeout(() => {
    //   if (child) {
    //     if (window.innerWidth < 576) {
    //       this.open();
    //     }
    //     this.menuItemchild.emit(child);
    //     if (this.menuIntro != null && child.ParentID == this.menuIntro.ID) {
    //       this.menuIndex = idx;
    //       if (this.router.url.includes(AppConsts.page.introPage) == true) {
    //         // gửi event lên local storage
    //         localStorage.setItem('CurrentMenuID', child.ID);
    //         var event = document.createEvent('Event');
    //         event.initEvent('introStorage', true, true);
    //         window.dispatchEvent(event);
    //       }
    //       return;
    //     }
    //     if (this.menuNews != null && child.ParentID == this.menuNews.ID) {
    //       this.router.navigateByUrl(AppConsts.page.postList + '/' + child.ID);
    //       return;
    //     }
    //     if (
    //       (this.menuGovernment != null &&
    //       child.ParentID == this.menuGovernment.ID)
    //     ) {
    //       if (child.Url == AppConsts.page.libraryText) {

    //         this.router.navigateByUrl(AppConsts.page.libraryText + '/' + child.ID );
    //         return;
    //       }
    //       this.router.navigateByUrl(
    //         AppConsts.page.governmentPage + '/' + child.ID
    //       );
    //       return;
    //     }
    //     if (
    //       this.menuEDocument != null &&
    //       (child.ParentID == this.menuEDocument.ID ||
    //         child.ID == this.menuEDocument.ID)
    //     ) {
    //       // if (this.router.url.includes(AppConsts.page.electronicText) == true) {
    //       //   // gửi event lên local storage
    //       //   localStorage.setItem('CurrentMenuID', child.ID);
    //       //   var event = document.createEvent('Event');
    //       //   event.initEvent('eDocumentStorage', true, true);
    //       //   window.dispatchEvent(event);

    //       //   return;
    //       // }
    //       if (child.Url == AppConsts.page.libraryText) {

    //         this.router.navigateByUrl(AppConsts.page.libraryText + '/' + child.ID );
    //         return;
    //       }
    //     }
    //   } else {
    //   }
    // }, 10);
  }

  getMenuGovernment() {
    if (this.menus) {
      var menuNews = this.menus.filter(
        (x) => x.Url == AppConsts.page.governmentPage
      );
      if (menuNews != null && menuNews.length > 0) {
        return menuNews[0];
      }
    }
    return null;
  }

  getMenuNews() {
    if (this.menus) {
      var menuNews = this.menus.filter((x) => x.Url == AppConsts.page.postPage);
      if (menuNews != null && menuNews.length > 0) {
        return menuNews[0];
      }
    }
    return null;
  }

  getMenuIntro() {
    if (this.menus) {
      var menuIntro = this.menus.filter(
        (x) => x.Url == AppConsts.page.introPage
      );
      if (menuIntro != null && menuIntro.length > 0) {
        return menuIntro[0];
      }
    }
    return null;
  }

  getMenuEDocument() {
    if (this.menus) {
      var menuEDocument = this.menus.filter(
        (x) => x.Url == AppConsts.page.electronicText
      );
      if (menuEDocument != null && menuEDocument.length > 0) {
        return menuEDocument[0];
      }
    }
    return null;
  }

  parseURL(item) {
    if (this.menuNews != null && item.ParentID == this.menuNews.ID) {
      return AppConsts.page.postList + '/' + item.ID;
    }
    return item.Url;
  }
  reset() {
    this.isSearch = false;
  }
  onClickFunction(item) {
    this.getBtnFunctionData(item);
  }
  getBtnFunctionData(item) {
    this.btnHeader = [
      {
        id: 'edit',
        text: this.translate.instant('Edit'),
        icon: 'edit',
        click: () => {
          this.openEdit();
        },
      },
    ];
    // if (item.ID) {
    //   var func = {
    //     id: 'delete',
    //     text: this.translate.instant('Delete'),
    //     icon: 'delete',
    //     click: () => {
    //       this.openDelete(item);
    //     },
    //   };
    //   this.btnHeader.push(func);
    // }
  }
  openEdit() {
    this.editFlg = true;
    // this.avatarUpload = true;
    this.infoOpened = true;

  }

  setDefaultHeaderItem() {
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=admin&typeData=image&acceptExtensions=[".png",".jpg",".jpeg"]`;
    this.TemplateDetailsInfo = {
      Title: '',
      SubTitle: '',
      Image: ''
    };

    this.isParentUrl = false;
    this.parentUrl = null;
    var errTemp = Object.assign({}, this.TemplateDetailsInfo);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;

  }
  openAddMenu() {
    this.addMenu = true;
  }
  get4Type() {
    var a = [];
    a[0] = this.translate.instant('TypeHomepage0');
    a[1] = this.translate.instant('TypeHomepage1');
    a[2] = this.translate.instant('TypeHomepage2');
    a[3] = this.translate.instant('TypeHomepage3');
    a[4] = this.translate.instant('TypeHomepage4');
    this.Types = [];
    for (let i = 0; i < 5; i++) {
      this.Types.push({
        ID: i,
        Name: a[i],
      });
    }
    this.TypesFilter = this.Types.slice();
    // this.loading = false;
  }
  async getPageParent() {
    const dataRequest = {
      searchText: ''
    };
    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result && result.Status === 1) {
      this.parentPages = [];
      this.parentPages.push({
        ID: null,
        Name: 'Root',
      });

      // result.Data.forEach(item => {
      //   if (!item.ParentID) {
      //     this.parentPages.push(item);
      //   }
      // });

      this.parentPagesFilter = result.Data; //this.parentPages.slice();
    }
  }
  checkErr() {
    if (this.customCss == 1) {
      return 'x-dialog-unit-contact-err-one';
    } else if (this.customCss == 2) {
      return 'x-dialog-unit-contact-err-two';
    } else if (this.customCss == 3) {
      return 'x-dialog-unit-contact-err-three';
    } else if (this.customCss == 4) {
      return 'x-dialog-unit-contact-err-four';
    } else {
      return 'x-dialog-unit-contact';
    }
  }
  public dataMenuFocus = {
    Name: true,
  };
  // onCloseAddMenu(){
  //   this.addMenu = false
  // }
  onTabChanged(e) {
    this.selectedLangID = this.dataLangs[e.index].ID;
    this.getHeader();
  }

  async onAddHeaderInfo() {
    const dataRequest = this.createDataRequest();
    const result = await this.appService.doPOST('api/TemplateDetail', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.GetAllLang();
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async onUpdateHeaderInfo() {
    const iD = null;
    const dataRequest = this.createDataRequest();
    const result = await this.appService.doPUT('api/TemplateDetail', dataRequest, iD);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.GetAllLang();
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }


  onSuccessAvatarUpload(e: SuccessEvent) {
    if (e.response && e.response.body && e.response.body.Status == 1) {
      if (e.response.body.Data) {
        let data = e.response.body.Data;
        this.imgUrl = `/${data.DirMedia}${data.MediaNm}`;
        // this.imgUrl = `/Upload/Media/${e.files[0].name}`;
      } else {
        this.notification.showError('Tải tập tin thất bại!');
      }
    } else {
      this.notification.showError('Tải tập tin thất bại!');
    }
  }


  async onSaveHeader() {
    this.onAddHeaderInfo();

    this.editFlg = false;
    this.infoOpened = false;
  }
  createDataRequest() {

    this.TemplateDetailsInfo = {
      Title: this.TemplateDetailsInfo.Title,
      SubTitle: this.TemplateDetailsInfo.SubTitle,
      Image: this.TemplateDetailsInfo.Image
    };

    var TemplateDetails = {
      TemplateID: this.templateID,
      LangID: this.selectedLangID,
      Info: JSON.stringify(this.TemplateDetailsInfo),
      Type: 0,
      FrameID: 0
    }

    return {
      TemplateID: this.templateID,
      MenuID: this.pageSelectedID,
      TemplateDetails: JSON.stringify(TemplateDetails)

      // return {
      //   TemplateID  : this.templateID ,
      //   LangID : this.selectedLangID ,
      //   Info : JSON.stringify(this.TemplateDetailsInfo) ,
      //   FrameID : 2 ,
      //   Type : 0 ,
    };

  }
  onCloseDialog() {
    // this.onReload();
    this.editFlg = false;
    this.addMenu = false;
    this.infoOpened = false;
    // this.avatarUpload = false;
  }
  async onSignout() {
    if (this.loading) return;
    this.loading = true;
    await this.authenticationService.doSignout();
    this.loading = false;
    this.ngOnInit();
    this.router.navigate([`/${AppConsts.page.login}`], { queryParams: {} });
  }

  async GetAllLang() {
    this.loading = true;
    const result = await this.appService.doGET('api/PortalHeader/GetLang', null);
    if (result) {
      this.dataLangs = result.Data;
      if (this.TemplateDetailsInfo.Title == '') {
        this.dataLangs = this.dataLangs.filter(x => x.ID == AppConsts.defaultLangID);

      }
    }
    this.loading = false;
  }
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    let tracker = event.target;
    let scrollTop = tracker['scrollingElement'].scrollTop;
    let navMenu = document.getElementById("nav-menu-box");
    if (scrollTop >= this.imgHeight) {
      navMenu.style.position = "fixed";
    } else {
      if (navMenu && navMenu.style) {
        navMenu.style.position = "relative";
      }
    }
    //console.log(tracker['scrollingElement'].scrollTop, tracker['scrollingElement'].scrollHeight, tracker['scrollingElement'].clientHeight);
  }
  showSearchBox() {
    this.isShowSearchBox = !this.isShowSearchBox;
  }
  setWidth() {
    let menuWidth = document.getElementById("menu-width");
    if (menuWidth) {
      menuWidth.style.width = this.bodyWidth.toString() + "px";
    }

  }
  onResize(event) {

    this.innerWidth = window.innerWidth;
    if (this.bodyWidth > window.innerWidth) {
      this.bodyWidth = ((window.innerWidth * 80) / 100);
    }
    if (window.innerWidth >= this.bodyWidth) {
      if (window.innerWidth > this.bodyWidthSetup) {
        this.bodyWidth = this.bodyWidthSetup;
      } else {
        this.bodyWidth = ((window.innerWidth * 80) / 100);
      }
    }
    this.setWidth();
  }
  async getContentWidth() {
    const result = await this.appService.doGET('api/PortalDesign/GetWidth', {});
    if (result && result.Status === 1 && typeof window !== 'undefined') {
      this.bodyWidthSetup = Number(result.Data[0]);
      this.bodyWidth = this.bodyWidthSetup;
      if (this.bodyWidth > window.innerWidth) {
        this.bodyWidth = ((window.innerWidth * 80) / 100);
      }
    }
  }

  toggleSubMenu(): void {
    this.showSubMenu = !this.showSubMenu;
  }

  toggleChildVisibility(menuId: number) {
    if (this.activeMenuId === menuId) {
      this.activeMenuId = -1; // Close the active menu if clicked again
    } else {
      this.activeMenuId = menuId; // Set the clicked menu as the active menu
    }
  }
  focusNavbarByUrl(){
    let indexSelected = null;

    if(this.menuIDSelected != '' && this.menus && this.menus.length != 0){
      for(let i = 0; i < this.menus.length; i++){
        if(this.menus[i]?.ID == this.menuIDSelected){
          indexSelected = i;
          break;
        }
        if(this.menus[i] && this.menus[i].Childrens.length != 0){
          for(let j = 0; j < this.menus[i].Childrens.length; j++){
            if(this.menus[i].Childrens[j].ID == this.menuIDSelected){
              indexSelected = i;
              break;
            }
          }
        }
      }
    }

    if(indexSelected != null){
      let itemLink = document.getElementsByClassName('item-link');
      if(itemLink){
        for (let i = 0; i < itemLink.length; i++) {
          if (itemLink[i].classList.contains('focus')) {
            itemLink[i].classList.remove('focus');
            break;
          }
        }
        itemLink[indexSelected]?.classList.add('focus');
      }
    }
  }
}
