import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppService } from '../services/app.service';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { AppConsts } from '../services/app.consts';
import { Router } from '@angular/router';
import { MyServiceService } from '../my-service.service';

@Component({
  selector: 'app-news-template-48',
  templateUrl: './news-template-48.component.html',
  // styleUrls: ['./news-template-48.component.scss']
})
export class NewsTemplate48Component implements OnInit {

  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
    private myService: MyServiceService,
    private router: Router,
  ) { }
  domain: string;
  isBrowser: any;
  Info: any;
  tabItem: any;
  tabIndex: any;
  pageList = [];
  topNews: any;
  tabId: any;

  TAB_GENERAL_URL = AppConsts.GIOI_THIEU_CHUNG_VE_CONG_TY;
  TAB_VISION_MISSION_URL = AppConsts.TAM_NHIN_SU_MENH;
  TAB_HISTORY_URL = AppConsts.LICH_SU_HINH_THANH;
  TAB_ORGANIZATION_STRUCTURE_URL = AppConsts.CO_CAU_TO_CHUC;
  TAB_FIELD_URL = AppConsts.LINH_VUC;
  TAB_REWARD_AND_ACHIVEMENT_URL = AppConsts.GIAI_THUONG_VA_THANH_TUU;

  PATH_INTRO_COMPANY = '';
  TAM_NHIN_SU_MENH = '';

  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  categoryParent: any;
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any; //page thiết kế
  @Output() deleteBodyItem = new EventEmitter();
  menuSelect = '';
  textNews: any;

  @Input() infoFromMain: any;
  @Output() changeTemplate2 = new EventEmitter();

  templates: any;
  pageBody = [];
  currentUrl: any;
  async ngOnInit() {
    //console.log('menuID' + this.menuID);
    localStorage.setItem('introTabSelected', this.menuID);
    
    this.domain = this.appService.apiRoot;
    this.title.setTitle(this.translate.instant('Title-Page'));
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }

    await this.getMenu();
    await this.onGetCategories();
    this.tabItem = history.state.item ?? this.pageList[0];
    this.tabIndex = history.state.idx ?? 0;
    this.tabId = this.tabItem ? this.tabItem.UrlPath : this.TAB_GENERAL_URL;
  }

  ngOnDestroy() {
    //localStorage.removeItem('introTabSelected');
  }

  ngAfterViewInit(): void {
    // let tabItem = document.getElementsByClassName('tab-item');
    // tabItem[this.tabIndex].classList.add('tab-active');
    // let tabDes = document.getElementsByClassName('tab-des');
    // tabDes[0].setAttribute("style", "display:block");
  }

  async getMenu() {
    const result = await this.appService.doGET('api/PortalBody/GetMenuByParentID', {
      langID: this.language.get(),
      parentID: this.infoFromMain ? this.infoFromMain.MenuID : ''
    });
    if (result && result.Status === 1) {
      this.pageList = result.Data;
      this.pageList.forEach((item) => {
        item.Name = item.Name.toUpperCase();
      });
      this.pageList.sort((l1, l2) => (l1.OrderIdx < l2.OrderIdx ? -1 : 1));
      var menuSelected = localStorage.getItem('introTabSelected');
      //console.log(this.pageList.filter(x => x.ID == menuSelected).length);
      
      if (this.pageList && this.pageList.length > 0) {
        if (this.pageList.filter(x => x.ID == menuSelected).length > 0) {
          this.menuID = menuSelected;
        } else {
          this.menuID = this.pageList[0]?.ID;
        }
      }
      
      // this.menuID = menuSelected ? menuSelected : ((!this.menuID && this.pageList && this.pageList.length > 0) ? this.pageList[0]?.ID : '');
      // console.log('getMenu selectedTab');
      // console.log(this.menuID);
      
      
      // this.getTemplate();
    }
    // this.navMenu.emit(this.menus);
    // this.isMenuLoad = true;
    // this.isPageLoaded();
    // }
  }
  async getInfo() {
    const result = await this.appService.doGET('api/Portal/GetInfo', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1) {
      this.Info = result.Data;
    }
    this.updateMeta();
  }


  async getTopNews(menuID) {
    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        menuID: menuID,
        topNum: 2,
      }
    );
    if (result && result.Status === 1) {
      this.topNews = result.Data.topNews;
    }
  }

  updateMeta(): void {
    var description =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    var keywords =
      ' department of external relations, department of external relations ho chi minh city, ministry of foreign affairs, songoaivu, so ngoai vu, ngoai vu, mofahcm, songoaivutphcm, songoaivutphcm.com, Sở Ngoại Vụ TP.HCM, hợp pháp hóa lãnh sự, chứng nhận lãnh sự, hợp pháp hóa giấy tờ, lãnh sự, hợp pháp hóa';
    var url = window.location.origin;
    var title = this.translate.instant('Title-Page');
    var ogDescription =
      this.translate.instant('ogDescription') +
      '\n' +
      this.translate.instant('Detail-Address');
    var image = '';
    if (this.Info != null) {
      description =
        this.translate.instant('ogDescription') +
        '\n' +
        this.translate.instant('Address') +
        this.Info.Info.Address;
      ogDescription =
        this.translate.instant('ogDescription') +
        '\n' +
        this.translate.instant('Address') +
        this.Info.Info.Address;
      image = this.appService.apiRoot + this.Info.Image[0]?.ImageUrl;
    }
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'og:url', content: url });
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:image', content: image });
    this.meta.updateTag({ name: 'og:description', content: ogDescription });
  }

  

  onClickItemTab(item?, index?) {
    // console.log('click tab intro');
    // console.log("onClickItemTab",item);
     this.currentUrl = this.router.url;
     
     if (this.currentUrl != ''){
      if(item.UrlPath != "/"){
        this.router.navigateByUrl(AppConsts.page.main + '/' + item.UrlPath );
      } else {
        this.router.navigateByUrl(AppConsts.page.main + '/');
      }
    }
    this.menuID = item?.ID;
    localStorage.setItem('introTabSelected', this.menuID);
    this.sendMessage(item);

    // let tabDes = document.getElementsByClassName('tab-des');
    // for (let i = 0; i < tabDes.length; i++) {
    //   tabDes[i].setAttribute("style", "display:none")
    // }
    // tabDes[index].setAttribute("style", "display:block")
  }
  filterItem(tabId) {
    return this.pageList.find((item) => item.UrlPath==tabId)
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  onLoadData(event) {
    // console.log('intropage onloadData');
    // console.log(event);
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }  
    this.infoFromMain.MenuID = (event && event.Info) ? event.Info.MenuID : '';
    this.getMenu();
  }

  changeTemp2(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    // this.getTopNews();
    // this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate2.emit(newTemp);
  }

  sendMessage(item) {
    this.myService.sendMessage(item);
  }

  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/SearchMenuByParent', {
      menuID: this.infoFromMain.MenuID
    });
    if (result && result.Status === 1) {
      // this.categoriesList = result.Data?.Menus;
      // this.categoriesListFiltered = result.Data?.Menus;
      this.categoryParent = result.Data?.MenuItem;
      
    } 
  }

  getImageFullUrl(url) {
    return this.appService.apiRoot + url;
  }

}
