<div class="news-template-a-dialog row">
  <div class="col-md-12 col-12">
    <div class="row">

      <label [ngClass]="dataItemtemp.ImageUrl != this.defaultImg ? 'vnpt-upload-img' : 'vnpt-upload-img vnpt-upload-img-default'" [title]="'Upload' | translate">
        <img class="preview" [ngClass]="IsVietnameseTab ? 'pointer' : '' "
        *ngIf="getFileType(dataItemtemp.ImageUrl) == 'jpg' || getFileType(dataItemtemp.ImageUrl) == 'jpeg' || getFileType(dataItemtemp.ImageUrl) == 'png' "
        [src]="dataItemtemp.ImageUrl != this.defaultImg ? appService.apiRoot + dataItemtemp.ImageUrl : dataItemtemp.ImageUrl " >
        <kendo-upload
          [disabled]="!IsVietnameseTab"
          [restrictions]="myRestrictionsImage"
          [accept]="['.png', '.jpg', '.jpeg', '.mp4', '.mkv']"
          [(ngModel)]="filesUploadImage"
          (select)="onSelectImageUpload($event)"
          [multiple]="false"
          [showFileList]="false"
          [saveUrl]="avatarSaveUrl"
          (success)="onSuccessImageUpload($event)"
        >
          <kendo-upload-messages
            [headerStatusUploaded]="'FileUploaded' | translate"
            [dropFilesHere]="'dropFilesHere' | translate"
            [select]="'UploadFile' | translate"
          >
          </kendo-upload-messages>
        </kendo-upload>
      </label>


      <div class="col-md-12 col-12">
        <div [ngClass]="'control'">
          <label>
            <span [textContent]="'Title'" class="input-label"></span>
            <span class="required">(*)</span>
          </label>
          <textarea class="tempA-title input-box" id="tempA-title" [(ngModel)]="tempAtitle">{{ this.Title }}</textarea>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div [ngClass]="'control'">
          <label>
            <span [textContent]="'Description'" class="input-label"></span>
            <span class="required">(*)</span>
          </label>
          <textarea class="tempA-des input-box" id="tempA-des" [(ngModel)]="tempAdes">{{ this.Description }}</textarea>
        </div>
      </div>

      <div class="col-md-12 col-12">
        <div class="btn-dialog-wrapper">
          <button *ngIf="controlDefault || control.Save " class="btn-dialog btn-save" (click)="onSaveGallery()">
              <span>{{'Save' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
