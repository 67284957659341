<section class="search-document router-outlet-content ">
    <!-- <div class="row admin">
    </div> -->
    <div class="col-md-12 col-12">
        <div class="row col-md-10 col-xs-12 col-sm-12">

            <div class="col-md-3  col-xs-4 col-sm-4 lineSpace">
                <div [ngClass]="'control'">
                    <label>
                            <span [textContent]="'UnprocessedDocument_Code' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                    <input  kendoTextBox [(ngModel)]="TempCode" [placeholder]="'SearchDocument_EnterCode' |translate" />
                    <!-- <span class="err-text " *ngIf="dataErr[0]['Document.Code'] ">{{dataErr[0]['Document.Code']}}</span> -->
                </div>
            </div>
            <div class="col-md-3  col-xs-4 col-sm-4 lineSpace">
                <div [ngClass]="'control'">
                    <label>
                            <span [textContent]="'UnprocessedDocument_Password' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                    <input type="password" kendoTextBox [(ngModel)]="TempPasswrord" [placeholder]="'SearchDocument_EnterPassword' |translate" />
                </div>
                <!-- <span class="err-text " *ngIf="dataErr[0]['Document.Code'] ">{{dataErr[0]['Document.Code']}}</span> -->

            </div>
            <div class="col-md-2 col-xs-4 col-sm-4">
                <div class="button-div">

                    <button class="btn-search" (click)="onSearch()" >
                        {{ 'UnprocessedDocument_Lookup' | translate }}
                    </button>

                </div>
            </div>

        </div>

    </div>
    <app-content-document *ngIf="searchFlg" (closeContentDocumentComponent)="onCloseLookup()" [searchFlg]="searchFlg" [Code]="Code" [Password]="Password" [countDuplicateKeys]="countDuplicateKeys"></app-content-document>
</section>