import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';

import { AppGuid } from 'src/app/services/app.guid';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';

import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';
import { PageChangeEvent } from '@progress/kendo-angular-pager';

@Component({
  selector: 'app-submit-document',
  templateUrl: './submit-document.component.html',
  //  styleUrls: ['./submit-document.component.scss']
})
export class SubmitDocumentComponent implements OnInit, OnDestroy {

  user: any;
  popupClass = "popup-width";
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;
  dataGrids = [];
  filesNum = 0;
  public dataGridFocus = {
    Name: true
  };
  public pageSize = 9;
  public skip = 0;
  public pagedDocuments = [];
  public total = 0;
  dataGridSortByField: SortDescriptor[] = [];
  isSuccess = false;

  dataGridSelection: number[] = [];
  dataGridItem: any;
  dataLangs = [];


  customCss = 0;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;


  dataItems: Array<{ Name: string, ID: string }>;
  dataItemsFilter: Array<{ Name: string, ID: string }>;

  


  public disabled = false;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  infoOpened = false;

  searchOption = {
    SearchText: ''
  };
  dataErr: any;

  pageName = '';



  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    // this.getControl();
    this.setDefault();
    //this.language.default();
    this.language.default();
    this.onReload();
    this.initDisplay();
    this.allData = this.allData.bind(this);
    // this.getPageName();
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageNamePortal();
  }
  // async getControl() {
  //   this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.unitContact);
  //   this.controlDefault = false;
  // }

  

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextRemove(e: any) {
    this.searchOption.SearchText = '';
    this.onSearch();
  }

  dataItemsHandleFilter(value) {
    this.dataItemsFilter = this.dataItems.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async getdataItems() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      langID : this.language.get()
    };

    const result = await this.appService.doGET('api/RegisterDocument/Search', dataRequest);
    if (result && result.Status == 1) {
      this.dataGrids = result.Data.filter(x => x.Name != null);
      this.total = this.dataGrids.length;
      this.pageData();
    }
    this.loading = false;
  }

  setDefault() {
    this.dataGridItem = {
      IsAdd: true,
      ID: this.guid.empty,
      Address: '',
      Name: '',
      Description: '',
      Phone: '',
      UpdateAt: null
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    this.dataGridSelection = [];
    this.disabled = false;
    this.enabledID = true;
  }


  

 


  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {



  }



  onSearch() {
    this.getdataItems();
  }

  onReload() {
    //this.searchOption.SearchText = '';
    this.getdataItems();
  }

  onClearGrid() {
    this.setDefault();
  }


  onAddNewGrid() {
    this.setDefault();
    this.infoOpened = true;
  }


  createDataRequest(data) {

    const temp = data ? data : this.dataGridItem;
    return {
      ID: temp.ID,
      Name: temp.Name,
      Address: temp.Address,
      Description: temp.Description,
      Phone: temp.Phone,
      UpdateAt: temp.UpdateAt,
      Type: temp.Type,
      LangID: this.language.get(),
      UnitContactID: null
    };
  }

  onCloseGrid(status: any) {
    this.disabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async onEditGrid(dataItem: any) {

    await this.getDataItemByID(dataItem.ID)
  }

  async getDataItemByID(id: any) {

    const dataRequest = {
      iD: id,
      langID: this.language.get()
    };

    const result = await this.appService.doGET('api/RegisterDocument', dataRequest);
    if (result && result.Status === 1) {
      this.dataGridItem = result.Data;
      this.dataGridItem.UnitContactID = result.Data.ID;
      this.disabled = false;
      this.enabledID = false;
      this.infoOpened = true;
      if(this.dataGridItem.CvPro5ListID) {
        const dataRequest2 = {
          CvPro5ListID: this.dataGridItem.CvPro5ListID,
          langID: this.language.get()
    
          //  langID: this.selectedLangID
        };
          const resultCvPro5s = await this.appService.doGET('api/RegisterDocument/getCvPro5s', dataRequest2);
          if (resultCvPro5s && resultCvPro5s.Status === 1 && resultCvPro5s.Data != null) {
            this.filesNum = resultCvPro5s.Data.length;
          }
      }
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }


  onShowSuccess(val) {
    this.onCloseInfo();
    this.isSuccess = true;
  }

  resizeHeight(data) {
    var k = data;
  }

  onCloseSuccess() {
    this.isSuccess = false;
  }


  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataGrids, {}).data
    };

    return result;
  }

  onCloseInfo() {
    this.infoOpened = false;
    this.setDefault();
   // this.language.set(this.selectedLangID);
   
  }

  

  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }

  private pageData(): void {
    if (this.dataGrids){
      this.pagedDocuments = this.dataGrids.slice(
        this.skip,
        this.skip + this.pageSize
      );
    }    
  }

}
