import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppConsts } from '../services/app.consts';
import { AppService } from '../services/app.service';
import { EventEmitter, Input, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { Router } from '@angular/router';
import { AppGuid } from 'src/app/services/app.guid';
import { TranslateService } from '@ngx-translate/core';
import { AppSwal } from '../services/app.swal';
import { Notification } from '../services/app.notification';
import { AuthenticationService } from '../services/authentication.service';
import { SuccessEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-header-template3',
  templateUrl: './header-template3.component.html',
  // styleUrls: ['./header-template3.component.scss']
})
export class HeaderTemplate3Component implements OnInit {
  [x: string]: any;
  @Input() isAdminFlg = true;
  @Output() navMenu = new EventEmitter();
  @Output() partiesAndUnions = new EventEmitter<boolean>();
  @Output() menuItem = new EventEmitter();
  @Output() menuItemchild = new EventEmitter();
  @Output() menuSearch = new EventEmitter();
  subMenuItem: any;
  isVi: boolean;
  // menus: any[];
  user: any;
  toprightmenus: any;
  openFlg: boolean = false;
  openMenuFlg: boolean = false;
  header: any;
  isLoaded: boolean = false;
  isMenuLoad: boolean = false;
  isHeaderLoad: boolean = false;
  isLogged: boolean = false;
  searchNews: string;
  postList: string;
  isSearch: boolean = false;
  selectedMenuID: any = [];
  editFlg = false;
  headerInfo: any;
  viHeaderInfo: any;
  enHeaderInfo: any;
  infoOpened = false;
  avatarUpload = false;
  isParentUrl = false;
  parent: any;
  parentUrl: any;
  menuSelected: any;
  dataMenu: any;
  dataMenuItem: any;
  addMenu = false;
  btnHeader: any;
  fileSaveUrl: any;
  searchText: any;
  imgUrl: any;
  tabID = 0;
  Types: Array<{ Name: string; ID: number }>;
  TypesFilter: Array<{ Name: string; ID: number }>;
  parentPages: Array<{ Name: string; ID: string }>;
  parentPagesFilter: Array<{ Name: string; ID: string }>;
  @Input() menu: any;
  menus: any;
  menuFilter: Array<{ Name: string; ID: string }>;
  subMenus = [
    {
      ID: "1",
      IsButton: false,
      MenuFlg: false,
      Name: "Site map",
      OrderIdx: 0,
      PageID: null,
      ParentID: "",
      Url: ""
    },
    {
      ID: "2",
      IsButton: false,
      MenuFlg: false,
      Name: "Thư điện tử",
      OrderIdx: 0,
      PageID: null,
      ParentID: "",
      Url: ""
    },
    {
      ID: "3",
      IsButton: false,
      MenuFlg: false,
      Name: "Tuyển dụng",
      OrderIdx: 0,
      PageID: null,
      ParentID: "",
      Url: ""
    },
  ]
  menuNews: any;
  menuIntro: any;
  menuEDocument: any;
  menuGovernment: any;
  customCss = 0;
  dataErr: any;
  popupClass = 'popup-width';
  //lastselectedMenuID : any;
  menuIndex: any;
  languages: Array<any> = [
    {
      id: 'lang-vi',
      culture: AppConsts.VIETNAMESE,
      viText: 'Tiếng Việt',
      enText: 'Vietnamese',
      img: '../../assets/images/lang-vn.png',
    },
    {
      id: 'lang-en',
      culture: AppConsts.ENGLISH,
      viText: 'Tiếng Anh',
      enText: 'English',
      img: '../../assets/images/lang-en.png',
    },
  ];
  child: any;

  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private router: Router,
    private appSwal: AppSwal,
    private authenticationService: AuthenticationService,
    private notification: Notification,
    private guid: AppGuid,
    private translate: TranslateService
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.postList = AppConsts.page.postList;
    // this.getHeaderInfo();

    // this.setDefaultHeaderItem();
  }

  async ngOnInit() {
    this.get4Type();
    this.setDefaultHeaderItem();
    this.getPageParent();
    if (!this.language.get() || this.language.get() == AppConsts.VIETNAMESE) {
      this.isVi = true;
      this.tabID = 0;
    } else {
      this.isVi = false;
      this.tabID = 1;
    }
    this.getMenu();
    // this.searchNews = AppConsts.page.searchNews;
    this.getHeader();
    this.getViHeader();
    this.getEnHeader();
    this.checkIsLogged();
  }

  checkIsLogged() {
    if (localStorage.getItem('userInfoPortal')) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }
  }
  async getMenu() {
    const dataRequest = {
      searchText: '',
      Langid: this.language.get(),
    };

    const result = await this.appService.doGET(
      'api/Portal/GetNavigationBarData',
      dataRequest
    );
    if (result && result.Status === 1) {
      let menuChecked =
        result.Data && result.Data.length > 0
          ? result.Data.filter(
            (menu) =>
              menu.MenuFlg == true && (!menu.ParentID || menu.ParentID != '')
          )
          : [];
      let home = menuChecked.findIndex(menu => menu.ID == '5fbcabe4-081b-4c39-860c-30b055d594af');
      if (home != -1) {
        menuChecked.splice(home, 1);
      }
      this.menus = menuChecked;
      this.menuNews = this.getMenuNews();
      this.menuIntro = this.getMenuIntro();
      this.menuEDocument = this.getMenuEDocument();
      this.menuGovernment = this.getMenuGovernment();
      this.navMenu.emit(this.menus);
      this.isMenuLoad = true;
      this.isPageLoaded();
    }
    // this.navMenu.emit(this.menus);
    // this.isMenuLoad = true;
    // this.isPageLoaded();
    // }
  }

  changeLanguage(language: string) {
    if (language != this.language.get()) {
      this.isVi = !this.isVi;
      this.language.set(language);
      window.location.reload();
    }
  }

  onReload() {
    // window.location.reload();
    // this.getMenu();
    this.getHeader();
  }
  onOpenPartiesAndUnions(e) {
    this.partiesAndUnions.emit(true);
  }
  onGetMenuItem(item, index) {
    let itemLink = document.getElementsByClassName('item-link');
    for (let i = 0; i < itemLink.length; i++) {
      if (itemLink[i].classList.contains('focus')) {
        itemLink[i].classList.remove('focus');
        break;
      }
    }
    itemLink[index].classList.add('focus');

    // if (!item.ParentID || item.ParentID == '') {
    //   let path = window.location.pathname.split('/')[1];
    //   console.log(path);
    // }
    // console.log(item, window.location.pathname);

    this.menuItem.emit(item);
  }
  async getHeader(lang?) {
    const dataRequest = { langID: lang ?? this.language.get() };
    const result = await this.appService.doGET('api/Portal/GetInfoHeader', dataRequest);
    if (result && result.Status === 1) {
      this.headerInfo = result.Data;
      this.isHeaderLoad = true;
      this.isPageLoaded();
    }
  }
  async getViHeader() {
    const dataRequest = { langID: 'vi-VN' };
    const result = await this.appService.doGET('api/Portal/GetInfoHeader', dataRequest);
    if (result && result.Status === 1) {
      this.viHeaderInfo = result.Data;
    }
  }

  async getEnHeader() {
    const dataRequest = { langID: 'en-US' };
    const result = await this.appService.doGET('api/Portal/GetInfoHeader', dataRequest);
    if (result && result.Status === 1) {
      this.enHeaderInfo = result.Data;
    }
  }

  isPageLoaded() {
    if (this.isHeaderLoad && this.isMenuLoad) {
      this.isLoaded = true;
    }
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }
  onSearch() {
    if (!this.searchText) {
      return;
    }
    else {

      this.router.navigate([AppConsts.page.searchPage], { queryParams: { searchText: this.searchText } });
      // console.log('1', this.searchText);
      this.searchText = '';
    }
  }

  // search(text: string, isEnter) {
  //   if (window.innerWidth < 576) {
  //     this.isSearch = !this.isSearch;
  //     if (isEnter) {
  //       this.router.navigate([this.searchNews], {
  //         queryParams: { search: text },
  //       });
  //     }
  //   } else {
  //     this.router.navigate([this.searchNews], {
  //       queryParams: { search: text },
  //     });
  //   }
  // }
  open() {
    if (window.innerWidth < 576) {
      this.openFlg = !this.openFlg;

      if (this.openFlg) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    }
  }

  selectMenu(event, mother, currentTag, currentLevel, item, index) {
    this.onGetMenuItem(item, index);

    event.stopPropagation();
    event.preventDefault();
    // console.log('event',event);
    // console.log('mother',mother);
    // console.log('currentTag',currentTag);
    // console.log('currentLevel',currentLevel);
  }

  goToRoot() {
    this.menuItem.emit({});
    let itemLink = document.getElementsByClassName('item-link');
    for (let i = 0; i < itemLink.length; i++) {
      if (itemLink[i].classList.contains('focus')) {
        itemLink[i].classList.remove('focus');
        break;
      }
    }
    itemLink[0].classList.add('focus');
    this.router.navigateByUrl('/');
  }

  goToMenu(item) {
    this.menuItemchild.emit({});
    setTimeout(() => {
      if (this.menuNews != null && item.ParentID == this.menuNews.ID) {
        this.router.navigateByUrl(AppConsts.page.postList + '/' + item.ID);
        return;
      }
      if (this.menuGovernment != null && item.ID == this.menuGovernment.ID) {
        this.router.navigateByUrl(
          AppConsts.page.governmentPage + '/' + item.Childrens[0].ID
        );
        return;
      }
      if (item.ParentID != null && item.ParentID != '') {
        return;
      }
      this.router.navigateByUrl(item.Url);
    }, 10);
  }

  goToSubMenu(child?, idx?) {
    setTimeout(() => {
      if (child) {
        this.menuItemchild.emit(child);
        if (this.menuIntro != null && child.ParentID == this.menuIntro.ID) {
          this.menuIndex = idx;
          if (this.router.url.includes(AppConsts.page.introPage) == true) {
            // gửi event lên local storage
            localStorage.setItem('CurrentMenuID', child.ID);
            var event = document.createEvent('Event');
            event.initEvent('introStorage', true, true);
            window.dispatchEvent(event);
          }
          this.router.navigateByUrl(AppConsts.page.introPage, {
            state: { item: child, idx },
          });
          return;
        }
        if (this.menuNews != null && child.ParentID == this.menuNews.ID) {
          this.router.navigateByUrl(AppConsts.page.postList + '/' + child.ID);
          return;
        }
        if (
          this.menuGovernment != null && child.ParentID == this.menuGovernment.ID
        ) {
          if (child.Url == AppConsts.page.libraryText) {
            this.router.navigateByUrl(AppConsts.page.libraryText + '/' + child.ID);
            return;
          }
          this.router.navigateByUrl(
            AppConsts.page.governmentPage + '/' + child.ID
          );
          return;
        }
        if (
          this.menuEDocument != null && (child.ParentID == this.menuEDocument.ID || child.ID == this.menuEDocument.ID)
        ) {
          if (this.router.url.includes(AppConsts.page.electronicText) == true) {
            // gửi event lên local storage
            localStorage.setItem('CurrentMenuID', child.ID);
            var event = document.createEvent('Event');
            event.initEvent('eDocumentStorage', true, true);
            window.dispatchEvent(event);
          }
          this.router.navigateByUrl(AppConsts.page.electronicText, {
            state: { child },
          });
          return;
        }

      } else {

      }

    }, 10);
  }

  getMenuGovernment() {
    if (this.menus) {
      var menuNews = this.menus.filter(
        (x) => x.Url == AppConsts.page.governmentPage
      );
      if (menuNews != null && menuNews.length > 0) {
        return menuNews[0];
      }
    }
    return null;
  }

  getMenuNews() {
    if (this.menus) {
      var menuNews = this.menus.filter((x) => x.Url == AppConsts.page.postPage);
      if (menuNews != null && menuNews.length > 0) {
        return menuNews[0];
      }
    }
    return null;
  }

  getMenuIntro() {
    if (this.menus) {
      var menuIntro = this.menus.filter(
        (x) => x.Url == AppConsts.page.introPage
      );
      if (menuIntro != null && menuIntro.length > 0) {
        return menuIntro[0];
      }
    }
    return null;
  }

  getMenuEDocument() {
    if (this.menus) {
      var menuEDocument = this.menus.filter(
        (x) => x.Url == AppConsts.page.electronicText
      );
      if (menuEDocument != null && menuEDocument.length > 0) {
        return menuEDocument[0];
      }
    }
    return null;
  }

  parseURL(item) {
    if (this.menuNews != null && item.ParentID == this.menuNews.ID) {
      return AppConsts.page.postList + '/' + item.ID;
    }
    return item.Url;
  }
  reset() {
    this.isSearch = false;
  }
  onClickFunction(item) {
    // console.log(item);
    this.getBtnFunctionData(item);
  }
  getBtnFunctionData(item) {
    this.btnHeader = [
      {
        id: 'edit',
        text: this.translate.instant('Edit'),
        icon: 'edit',
        click: () => {
          this.openEdit(item);
        },
      },
    ];
    // if(item.ID || item.ImageUrl) {
    if (item.ID) {
      var func = {
        id: 'delete',
        text: this.translate.instant('Delete'),
        icon: 'delete',
        click: () => {
          this.openDelete(item);
        },
      };
      this.btnHeader.push(func);
    }
  }
  openEdit(item) {
    this.editFlg = true;
    if (item.ImageUrl) {
      this.avatarUpload = true;
    } else {
      this.infoOpened = true;
      this.tabID = (!this.language.get() || this.language.get() == AppConsts.VIETNAMESE) ? 0 : 1;
      setTimeout(() => {
        let kItem = document.getElementsByClassName('k-item')[this.tabID];
        if (kItem) {
          kItem.classList.add('active');
        }
      }, 10);
    }
  }
  async openDelete(item) {
    const option = await this.appSwal.showWarning(
      this.translate.instant('DeleteMenu_AreYouSure'),
      true
    );
    if (option) {
      var index = this.headerInfo.Info.MenuIDs.indexOf(item.ID);
      this.headerInfo.Info.MenuIDs.splice(index, 1);
      this.onUpdateHeaderInfo();
      this.onReload();
    }
  }
  getHeaderInfo() {
    // this.headerInfo = {
    //   MainName : 'Tổng công ty công nghiệp sài gòn' ,
    // DetailName : 'Saigon industry corporation'
    // }
  }
  setDefaultHeaderItem() {
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=admin&typeData=image&acceptExtensions=[".png",".jpg",".jpeg"]`;
    this.headerInfo = {
      Image: [],
      Info: {
        Title: '',
        SubTitle: '',
        Link: '',
        MenuIDs: [],
      },
    };
    this.dataMenuItem = {
      Name: '',
      ID: '',
      ParentID: '',
      OrderIndex: 0,
      Url: '',
      PageID: null,
      IsButton: false,
      Childrens: [],
    };
    this.isParentUrl = false;
    this.parentUrl = null;
    var errTemp = Object.assign({}, this.headerInfo);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    // this.dataGridSelection = [];
    // this.disabled = false;
    // this.enabledID = true;
  }
  openAddMenu() {
    this.addMenu = true;
  }
  get4Type() {
    var a = [];
    a[0] = this.translate.instant('TypeHomepage0');
    a[1] = this.translate.instant('TypeHomepage1');
    a[2] = this.translate.instant('TypeHomepage2');
    a[3] = this.translate.instant('TypeHomepage3');
    a[4] = this.translate.instant('TypeHomepage4');
    this.Types = [];
    for (let i = 0; i < 5; i++) {
      this.Types.push({
        ID: i,
        Name: a[i],
      });
    }
    this.TypesFilter = this.Types.slice();
    // this.loading = false;
  }
  async getPageParent() {
    const dataRequest = {
      searchText: '',
      Langid: this.language.get(),
    };
    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result && result.Status === 1) {
      this.parentPages = [];
      this.parentPages.push({
        ID: null,
        Name: 'Root',
      });

      // result.Data.forEach(item => {
      //   if (!item.ParentID) {
      //     this.parentPages.push(item);
      //   }
      // });

      this.parentPagesFilter = result.Data; //this.parentPages.slice();
    }
  }
  checkErr() {
    if (this.customCss == 1) {
      return 'x-dialog-unit-contact-err-one';
    } else if (this.customCss == 2) {
      return 'x-dialog-unit-contact-err-two';
    } else if (this.customCss == 3) {
      return 'x-dialog-unit-contact-err-three';
    } else if (this.customCss == 4) {
      return 'x-dialog-unit-contact-err-four';
    } else {
      return 'x-dialog-unit-contact';
    }
  }
  public dataMenuFocus = {
    Name: true,
  };
  // onCloseAddMenu(){
  //   this.addMenu = false
  // }
  onTabChanged(e) {
    this.tabID = e.index;
    let tabActive = document.getElementsByClassName('k-item');
    for (let i = 0; i < tabActive.length; i++) {
      if (tabActive[i].classList.contains('active')) {
        tabActive[i].classList.remove('active');
      }
    }
    tabActive[e.index].classList.add('active');

  }
  async onUpdateHeaderInfo(lang?) {
    this.headerInfo.Language = lang;
    const dataRequest = this.createDataRequest(lang);
    const result = await this.appService.doPOST(
      'api/Portal/UpdateHeaderInfo',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  async onUpdateHeaderAvatar(imgUrl) {
    const dataRequest = {
      Title: 'Header Image',
      Type: 2,
      ImageUrl: imgUrl,
    };
    const result = await this.appService.doPOST(
      'api/Portal/UpdateHeaderImage',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  async getTopRightMenu() {
    const result = await this.appService.doGET('api/Portal/GetMenuNoChild', {
      langID: this.language.get(),
    });
    if (result && result.Status === 1) {
      this.toprightmenus = result.Data;
      this.menuFilter = this.toprightmenus.slice();

    }
  }
  menuHandleFilter(value) {
    this.menuFilter = this.toprightmenus.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  onChangeMenu(e) {
    this.menuSelected = this.toprightmenus.filter((m) => m.ID == e);
  }
  onSuccessAvatarUpload(e: SuccessEvent) {
    if (e.response && e.response.body && e.response.body.Status == 1) {
      if (e.response.body.Data) {
        let data = e.response.body.Data;
        this.imgUrl = `/${data.DirMedia}${data.MediaNm}`;
        // this.imgUrl = `/Upload/Media/${e.files[0].name}`;
      } else {
        this.notification.showError('Tải tập tin thất bại!');
      }
    } else {
      this.notification.showError('Tải tập tin thất bại!');
    }
  }
  onSaveDialog(lang?) {
    if (this.editFlg) {
      if (this.avatarUpload) {
        this.onSaveAvatar();
      } else {
        this.onSaveHeader(lang);
      }
    } else {
      this.onSaveMenu();
    }
  }
  async onSaveHeader(lang) {
    this.onUpdateHeaderInfo(lang);
    this.onReload();
    this.editFlg = false;
    this.infoOpened = false;
  }
  onSaveMenu() {
    if (this.menuSelected.length != 0) {
      this.dataMenuItem.ID = this.menuSelected[0].ID;
      this.dataMenuItem.Name = this.menuSelected[0].Name;
      this.dataMenuItem.OrderIndex = this.menuSelected[0].OrderIndex;
      this.dataMenuItem.PageID = this.menuSelected[0].PageID;
      this.dataMenuItem.ParentID = this.menuSelected[0].ParentID
        ? this.menuSelected[0].ParentID
        : '';
      this.dataMenuItem.Url = this.menuSelected[0].Url;
      let data = this.dataMenu.filter((d) => d.ID == this.dataMenuItem.ID);
      if (data.length == 0) {
        this.dataMenu.push(this.dataMenuItem);
        this.headerInfo.Info.MenuIDs.push(this.dataMenuItem.ID);
        this.onUpdateHeaderInfo();
        this.onReload();
      } else {
        this.notification.showError('Trùng chuyên mục');
      }
    } else {
      // this.appSwal.showWarning("Fail", false);
    }
    this.editFlg = false;
    this.addMenu = false;
  }
  onSaveAvatar() {
    this.onUpdateHeaderAvatar(this.imgUrl);
    this.onReload();
    this.editFlg = false;
    this.avatarUpload = false;
  }
  createDataRequest(lang?) {
    let temp = lang == AppConsts.VIETNAMESE ? this.viHeaderInfo : this.enHeaderInfo;
    var MenuIDs = temp.MenuIDs;
    if (typeof temp.MenuIDs == 'object') {
      MenuIDs = JSON.stringify(temp.MenuIDs);
    }
    return {
      // ID: temp.ID,
      Language: temp.Info.Language,
      Title: temp.Info.Title,
      SubTitle: temp.Info.SubTitle,
      MenuIDs: temp.Info.MenuIDs,
      Link: temp.Info.Link,
    };
  }
  onCloseDialog() {
    // this.onReload();
    this.editFlg = false;
    this.addMenu = false;
    this.infoOpened = false;
    this.avatarUpload = false;
  }
  async onSignout() {
    await this.authenticationService.doSignout();
    this.ngOnInit();
    window.location.href = '/login';
  }
}
