import { Component, EventEmitter, HostListener, Input, OnInit, Output, ElementRef, Renderer2, Inject } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { Router } from '@angular/router';
import { MyServiceService } from '../my-service.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-news-template-47',
  templateUrl: './news-template-47.component.html',
  // styleUrls: ['./news-template-47.component.scss']
})
export class NewsTemplate47Component implements OnInit {

windowScrolled: boolean;

  constructor(@Inject(DOCUMENT) private document: Document,
  private appService: AppService,
  private language: AppLanguage,
  private translate: TranslateService,
  private notification: Notification,
  private appComponent: AppComponent,
  private eventEmitterService: EventEmitterService,
  private router: Router,
  private el: ElementRef, 
  private renderer: Renderer2,
  private myService: MyServiceService) {}

  topNews: any;
  // topNumber: number = 3;
  // bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  openedEmail: boolean = false;
  openedHotline: boolean = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @HostListener('window:resize', ['$event'])
  innerWidth: any;
  innerHeight: any;
  @Input() OrderIdx: any;
  @Input() isPreview;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate2 = new EventEmitter();
  @Output() saveData = new EventEmitter();
  @Output() tittleItem = new EventEmitter();
  // @Output() urlItem = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  postDetailRoute = AppConsts.POST_DETAIL_ROUTE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  isShowButton: boolean = true;
  
  ngOnInit(): void {
    this.scrollToBottom();
    // this.bootstrapNum = 12 / this.topNumber;
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.getPostTittle();
    this.getTopNews();
    this.innerWidth = window.innerWidth;
    if(this.innerWidth && this.innerWidth <=768){
      this.isShowButton = false;
    }
    this.innerHeight = window.innerHeight;
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate ? this.itemTemplate.GroupID : 0,
      "Layout": this.itemTemplate ? this.itemTemplate.Layout : 100,
      "GroupIndex": this.itemTemplate ? this.itemTemplate.GroupIndex : 0
    }
    if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: '../../assets/images/default.png',
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
    }
  }
  async getTopNews() {
    const result = await this.appService.doGET(
      'api/PortalBody/GetCommon',
      {
        langID: this.language.get(),
        type: 2,
      }
    );

    if (result && result.Status === 1) {
      this.topNews = result.Data;
    }
  }

  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }

  onGetTittleItem(e) {
    this.tittleItem.emit(e);
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  changeTemp2(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    // this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.getTopNews();
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate2.emit(newTemp);
  }

  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }

  onSaveData() {
    this.saveData.emit();
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }
  redirectToDetail(itemLink){

    if (itemLink.Name == 'Radio') {
      itemLink.ID = '04a70364-3d2e-4e78-801f-28e6256d1c1e';
      this.router.navigateByUrl(AppConsts.page.main + '/');
      this.myService.sendMessage(itemLink);
    } 
    else if(itemLink.LinkUrl == null || itemLink.LinkUrl == undefined){
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      window.open(itemLink.LinkUrl, '_blank');
    }
    //this.router.navigate(['/post-detail/'+url])
  }
  onRightClick(url){
    let fullURL = this.appService.portalRoot + 'post-detail/' + url;
    window.open(fullURL, '_blank');
    return false;
  }
  getDetailUrl(url){
    let fullURL = '/post-detail/' + url;
    return fullURL;
  }

  scrollToBottom(): void {
    try {
      this.renderer.setProperty(this.el.nativeElement.querySelector('#scrollMe'), 'scrollTop', this.el.nativeElement.querySelector('#scrollMe').scrollHeight);
    } catch(err) { }
  }

  handleHotline() {
    this.openedEmail = false;
    if (this.openedHotline == true) {
      this.openedHotline = false;
    } else {
      this.openedHotline = true;
    }
  }

  handleEmail() {
    this.openedHotline = false;
    if (this.openedEmail == true) {
      this.openedEmail = false;
    } else {
      this.openedEmail = true;
    }
  }

  onBlur() {
    this.openedHotline = false;
    this.openedEmail = false;
  }

  isShow: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
