<section class="post-detail2 content">
  <div *ngIf="loading" class="loading">
    <img src="../../../assets/images/loading.gif" alt="loading">
  </div>
  <div class="row">
    <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
      <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
      <div class="menu" *ngIf="isShowMenu">
        <div class="menu-row-current-border" (click)="editComponent()">
          <div class="menu-item-1">{{'Edit' | translate}}</div>
        </div>
        <div class="menu-row-current" (click)="deleteComponent()">
          <div class="menu-item-3">{{'Delete' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="margin-template">
      <div *ngIf="Data_Post" class="row">
        <div class="box col-md-12 col-lg-12 col-xs-12 col-sm-12">
          <article>
            <div class="row">
              <div *ngIf="isIntroPage" class="col-lg-12 col-md-12 col-sm-12" style="padding-right: 14px;">
                <div [style.width]="innerWidth>768 ? (bodyWidth +'px') : 100+'%'">
                  <header>
                    <div class="sapned">
                      <span class="title-post">
                        {{ Data_Post.Title ? Data_Post.Title : "" }}
                      </span>
                    </div>
                  </header>
                  <section *ngIf="!isIntroPage" [hidden]="!Data_Post.PostTime" class="created-post">
                    <time>{{
                      Data_Post.PostTime | date: (flgVi ? "dd/MM/yyyy" : " MM-dd-yyyy")
                      }}</time>
                  </section>
                  <section class="summary" [innerHTML]="(Data_Post.Summary ? Data_Post.Summary : '') | sanitizedHtml">
                  </section>
                  <section class="description"
                    [innerHTML]="(Data_Post.Description ? Data_Post.Description : '') | sanitizedHtml"></section>
                </div>
              </div>
              <!-- <app-router-link *ngIf="!isIntroPage" [menuTittle]="menuTittle" [menuVl]="menuVl" [itemchild]="itemchild">
                [ItemTittle]="">
              </app-router-link> -->
              <div *ngIf="!isIntroPage" class="col-lg-8 col-md-8 col-sm-12" style="padding-right: 14px;">
                <header>
                  <div class="sapned">
                    <span class="title-post">
                      {{ Data_Post.Title ? Data_Post.Title : "" }}
                    </span>
                  </div>
                </header>
                <section *ngIf="!isIntroPage" [hidden]="!Data_Post.PostTime" class="created-post">
                  <time>{{
                    Data_Post.PostTime | date: (flgVi ? "dd/MM/yyyy" : " MM-dd-yyyy")
                    }}</time>
                </section>
                <section class="summary" [innerHTML]="(Data_Post.Summary ? Data_Post.Summary : '') | sanitizedHtml">
                </section>
                <section class="description"
                  [innerHTML]="(Data_Post.Description ? Data_Post.Description : '') | sanitizedHtml"></section>
              </div>
              <div *ngIf="!isIntroPage" class="col-lg-4 col-md-4 col-sm-12" style="padding-left: 14px;">
                <aside >
                  <app-most-viewed-posts [UrlPath]="UrlPath" type="related_Post"
                    (updatePostDetail)="updatePostDetail()"></app-most-viewed-posts>
                </aside>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>


    <!-- footer -->
    <div *ngFor="let item of pageFooter" class="header-box">
      <app-footer *ngIf="item == '1'"></app-footer>
      <app-footer2 *ngIf="item == '2'"></app-footer2>
    </div>
    <!-- footer -->
  </div>

  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="post-detail-dialog close-div">
      <div class="title-info-req title-center">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
          }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-post-detail-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onLoadData($event)"
      (onChangetempPostDetailDialog)="this.handleChangeTempPostDetail($event)">
    </app-post-detail-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate"></span>
      </div>
      <div>
        <button class="btn-delete" (click)="onDeleteGallery()">
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button class="btn-cancel" (click)="onClose($event)">
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</section>