<article class="news-template-b">
  <div class="container-fluid">
    <div class="row">
      <div class="main-item">
        <div class="row no-wrap">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
              <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
              <div class="menu" *ngIf="isShowMenu">
                <!-- <div class="menu-row-current-border" (click)="saveComponent()">
                  <div class="menu-item-1">{{'Save' | translate}}</div>
                </div> -->
                <div class="menu-row-current" (click)="deleteComponent()">
                  <div class="menu-item-3">{{'Delete' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="web-link">
              <div class="title">{{'Web_Link' | translate}}</div>
              <div class="drop-menu">
                <button (click)="myFunction()" class="dropbtn">
                  {{ curChoice }}
                  <div class="k-icon k-i-arrow-chevron-down"></div>
                </button>
                <div id="myDropdown" class="dropdown-content">
                  <input kendoTextBox class="drop-item-search" id="search-box" (keyup)="onSearchKeyPress()" />
                  <div class="drop-item-list">
                    <div *ngFor="let item of webData; let i = index">
                      <button class="drop-item" (click)="onClickItem(item, i)">
                        {{ item.Name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-b-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)">
      </div>
    </div>
    <app-news-template-b-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()">
    </app-news-template-b-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
