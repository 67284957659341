import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { Router } from '@angular/router';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { AppSwal } from '../services/app.swal';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import videojs from 'video.js';
import { log } from 'console';
import { Action, AdvancedLayout, Description, DescriptionStrategy, GalleryService, Image, ImageModalEvent, PlainGalleryConfig, PlainGalleryStrategy, PreviewConfig } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-news-template-30',
  templateUrl: './news-template-30.component.html',
  styleUrls: ['./news-template-30.component.scss'],
})
export class NewsTemplate30Component implements OnInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  isShowDialogVideo = false;
  opendDialogImages = false;
  newArrUrl: any;
  numberImage: any;
  manageDialog = false;
  public pageSize = 12;
  public skip = 0;
  public pagedTopnews = [];
  public total = 0;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  @Input() width: any;
  galleryData: any;
  @Input() itemTemplate: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() tittleItem = new EventEmitter();
  @Output() urlItem = new EventEmitter();
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate1 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() isPreview: boolean;
  @Input() newsDecorationIndex: any;
  @Input() isMore: boolean = true;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  @Input() srcURL: any;
  @Input() video: any;
  @Input() videos: any;
  b = [];
  bodyWidthSetup = 1140;

  //default templateType is 1 show all detail, change to 2 to hide time and viewMore
  @Input() templateType = 1;
  isViewAllList = false;
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  mediaCommonTabs: any;
  loadApiDone = false;
  bodyWidth = 1140;

  link: any;
  isTemplatePage = false;
  imgWidth: any;
  imgHeight: any;
  typeFile: number = 0;
  innerWidth: any;
  innerHeight: any;
  template30Width: any;
  imagesRect: Image[] = [new Image(
    0, 
    {
      img: ""
    }, 
    {
      img: ""
    }
  )];
  previewConfigFiveImages: PreviewConfig = {
    visible: true,
    number: 6
  };
  templateGalleryID : number = 30;
  customFullDescription: Description = {
    // ALWAYS_VISIBLE is mandatory to use customFullDescription
    strategy: DescriptionStrategy.ALWAYS_VISIBLE,
    imageText: '',
    customFullDescription: '',
    beforeTextDescription: '',
    style: {
      textColor: 'rgba(255,255,255)',
      marginTop: '3px',
      marginBottom: '0px',
      marginLeft: '5px',
      marginRight: '5px',
      position: 'absolute',
      bottom: '0px',
      height: 'fit-content',
    }
  };
  customPlainGalleryRowDescConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private appSwal: AppSwal,
    private sanitizer: DomSanitizer,
    private location: Location,
    private galleryService: GalleryService

  ) { }

  async ngOnInit(): Promise<void> {
    if (typeof window !== 'undefined'){
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      this.infoFromMain = JSON.parse(this.infoFromMain);
      this.topNumber = this.infoFromMain?.TopNumber;
      if (this.topNumber > 12) {
        this.isViewAllList = true
      }
      this.bootstrapNum = 12 / this.topNumber;
      if (this.topNumber > 3) {
        this.bootstrapNum = 4
      }
      this.link = this.location.path();
      if (this.link.includes('/template-page')) {
        this.isTemplatePage = true;
      } else {
        this.isTemplatePage = false;
      }
      this.router.events.subscribe((val) => {
  
      });
      this.getPostTittle();
      await this.getContentWidth();
      this.imgWidth = this.bodyWidth*0.14;
      this.imgHeight = this.imgWidth * 0.8;
      if(this.bodyWidth <= 570){
        this.imgWidth = this.bodyWidth;
      }
      // this.getTopNews();
      this.searchHotMediaShow();
      if(window.innerWidth <= 770){
        this.bodyWidth = window.innerWidth;
      }
      this.galleryData = {
        ID: this.ID,
        OrderIdx: this.OrderIdx,
        infoFromMain: this.infoFromMain,
        GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
        Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
        GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
      };
      // if (this.router.url == '/' + AppConsts.page.templatePage) {
      //   document.getElementById('template-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
      // }
      // else {
      //   document.getElementById('main-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
      // }
      if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
        this.topNews = [
          {
            DefaultFlg: true,
            FileUrls: AppConsts.LINK_DEFAULT_IMAGE,
            Title: 'Title',
            Summary: 'Summary',
            UrlPath: '',
            CreateAt: '2021-10-26T08:41:50.453',
            Description: 'Description',
          },
          {
            DefaultFlg: true,
            FileUrls: AppConsts.LINK_DEFAULT_IMAGE,
            Title: 'Title',
            Summary: 'Summary',
            UrlPath: '',
            CreateAt: '2021-10-26T08:41:50.453',
            Description: 'Description',
          },
          {
            DefaultFlg: true,
            FileUrls: AppConsts.LINK_DEFAULT_IMAGE,
            Title: 'Title',
            Summary: 'Summary',
            UrlPath: '',
            CreateAt: '2021-10-26T08:41:50.453',
            Description: 'Description',
          },
        ];
      }
      if (this.infoFromMain.MenuID == '') {
        this.pagedTopnews = this.topNews;
      }
      let template30Width = document.getElementById("template30Width");
      if(template30Width){
        this.template30Width = template30Width.clientWidth;
      }
    }


  }
  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }

  private pageData(): void {
    this.pagedTopnews = this.topNews.slice(
      this.skip,
      this.skip + this.pageSize
    );
    // if (this.pagedTopnews.length == 0) {
    //   this.pagedTopnews = [];
    //   for (let index = 0; index < this.topNumber; index++) {
    //     this.pagedTopnews.push({
    //       DefaultFlg: true,
    //       FileUrls: AppConsts.LINK_DEFAULT_IMAGE,
    //       Title: 'Title',
    //       UrlPath: '',
    //       CreateAt: '2021-10-26T08:41:50.453',
    //     });
    //   }
    // }
  }
  // async updateMediaDetail() {
  //   this.loadApiDone = true
  //   const dataRequest = {
  //     langID: this.language.get(),
  //   };
  //   const result = await this.appService.doGET("api/Media/GetByPostUrl", dataRequest);
  //   if(result.Data && result.Status === 1){
  //     this.Data_Media = result.Data;
  //     this.Data_Media.FileUrls = this.Data_Media.FileUrls ? JSON.parse(this.Data_Media.FileUrls) : '';
  //     if(this.Data_Media.Description){
  //       this.Data_Media.Description = this.Data_Media.Description.replaceAll("oembed","iframe").replace("url","src");
  //     }   
  //   }
  //   this.loadApiDone = false
  // }

  async searchHotMediaShow() {
    const result = await this.appService.doGET('api/Media/SearchHotMediaShow', {
      topNum: this.infoFromMain.TopNumber,

    });
    if (result && result.Status === 1) {
      this.pagedTopnews = result.Data;
      this.loadApiDone = true;
      this.numberImage = result.Data;

    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  setH() {
    if (this.templateType == 2) {
      return 'img-figure-template-type-2';
    }
    var a = document.querySelector('.post-list') as HTMLElement;
    if (a.getBoundingClientRect().width <= 808) {
      return 'img-figure-2'
    }
    return;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore() {
    this.router.navigate([AppConsts.page.postList + '/' + this.viewMore]);
  }
  onGetTittleItem(e) {
    this.tittleItem.emit(e)

  }

  redirectToItem(url: string, item) {


    this.onGetTittleItem(item)
    if (url != null && url != undefined) {

      this.urlItem.emit(item);
    }
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    console.log("index at template: ", { newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex });
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index": this.newsDecorationIndex,
      "layoutGroupIndex": this.layoutGroupIndex,
      "pageBodyIndex": this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    // this.innerHeight = event.target.innerHeight;

    if(this.bodyWidth > window.innerWidth){
      this.bodyWidth = ((window.innerWidth * 80)/100);
    } 
    if(window.innerWidth >= this.bodyWidth){
      if(window.innerWidth > this.bodyWidthSetup){
        this.bodyWidth = this.bodyWidthSetup;
      }else {
        this.bodyWidth = ((window.innerWidth * 80)/100);
      }
    }
    
    this.imgWidth = this.bodyWidth*0.14;
    this.imgHeight = this.bodyWidth*0.075;
    if(this.bodyWidth <= 570){
      this.imgWidth = this.bodyWidth;
    }
    let template30Width = document.getElementById("template30Width");
    if(template30Width){
      this.template30Width = template30Width.clientWidth;
    }
  }
  changeTemp1(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    //this.getTopNews();
    this.searchHotMediaShow()
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate1.emit(newTemp);
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }

  getDetailUrl(url) {
    let fullURL = '/post-detail/' + url;
    return fullURL;
  }

  getImage(FileUrls) {
    var firstImg = "";
    var file = JSON.parse(FileUrls);
    if (file && file.length !== 0) {
      firstImg = file[0]
    }
    return this.appService.apiRoot + firstImg;
  }



  getVideoUrl(FileUrls) {
    if (this.loadApiDone == true) {
      let video = "";
      let videos = JSON.parse(FileUrls);
      if (videos && videos.length !== 0) {
        video = videos[0];
        let videoUrl = `<figure style=" height: 100%; width: 100%"><iframe src="https://www.youtube.com/embed/` + video + '" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></figure>';
        return videoUrl;
      }
      return this.appService.apiRoot + video;
    }
  }

  getFullUrlAPI(url) {
    let jsonObject = JSON.parse(url);
    this.numberImage = jsonObject.length;
    if(jsonObject && jsonObject[0].Url){
      return this.appService.apiRoot + jsonObject[0].Url;
    }
    return this.appService.apiRoot + jsonObject[0];
    
    // let newUrl = url.slice(1, url.length - 1).split(',');

    // this.newArrUrl = [];
    // for (let i = 0; i < newUrl.length; i++) {
    //   let item = newUrl[i].slice(1, newUrl[i].length - 1);

    //   this.newArrUrl.push(item);
    // }
    // this.numberImage = this.newArrUrl.length;
    // return this.appService.apiRoot + this.newArrUrl[0];
  }



  async getContentWidth() {
    const result = await this.appService.doGET('api/PortalDesign/GetWidth', {});
    if (result && result.Status === 1 && typeof window !== 'undefined') {
      this.bodyWidthSetup = result.Data;
      this.bodyWidth = result.Data;
    }
  }




  openDialogVideo(item) {
    this.srcURL = JSON.parse(item.FileUrls);
    if(this.srcURL != null){
      this.typeFile = item.Type;    
      this.isShowDialogVideo = true;
      if(this.typeFile == 1){
        this.imagesRect= [];
        for(let i = 0; i < this.srcURL.length; i++){
          this.imagesRect.push(
            new Image(
              i, 
              {
                img: this.appService.apiRoot + (this.srcURL[i].Url != undefined ? this.srcURL[i].Url : this.srcURL[i]),
                description: this.srcURL[i].Title
              }, 
              {
                img: this.appService.apiRoot + (this.srcURL[i].Url != undefined ? this.srcURL[i].Url : this.srcURL[i]),
                description:  this.srcURL[i].Title
              }
            )
          )
        }
        setTimeout(() => {
          this.openModalViaService(this.templateGalleryID, 0);
        }, 100); 
      }
      
      document.body.classList.add('30-dialog-opened');
    } 
  }
  onCloseDialogVideo() {
    this.isShowDialogVideo = false;
    console.log('isShowDialogVideo',this.isShowDialogVideo);
    
  }
  openModalViaService(id: number | undefined, index: number) {
    //console.log('opening gallery with index ' + index);
    this.galleryService.openGallery(id, index);
  }
  onVisibleIndex(event: ImageModalEvent) {
    console.log('onVisibleIndex action: ' + Action[event.action]);
    console.log('onVisibleIndex result:' + event.result);
    console.log("typeof event.result",typeof event.result);
    
    let imageIndex = typeof event.result == 'number' ? event.result - 1: 0;
    // if(this.srcURL && this.srcURL.length != 0){
    //   this.customFullDescription.customFullDescription = this.srcURL[imageIndex].Title;
    //   console.log("this.customFullDescription.customFullDescription", this.customFullDescription);
    //   setTimeout(() => {
    //     console.log('image updated',  this.imagesRect[imageIndex]);
    //     this.galleryService.updateGallery(30, imageIndex, this.imagesRect[imageIndex]);
    //   }, 500);
      
    // }
    
  }
  onImageLoaded(event: ImageModalEvent) {
    // angular-modal-gallery will emit this event if it will load successfully input images
    console.log('onImageLoaded action: ' + Action[event.action]);
    console.log('onImageLoaded result:' + event.result);
  }
}

