import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';

import {
  FileRestrictions,
  SelectEvent,
  ClearEvent,
  RemoveEvent,
  FileInfo,
} from '@progress/kendo-angular-upload';

import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-template-27-dialog',
  templateUrl: './news-template-27-dialog.component.html',
  // styleUrls: ['./news-template-27-dialog.component.scss']
})
export class NewsTemplate27DialogComponent implements OnInit {
  @Input() request_AccountID;
  @Output() galleryManageOpened = new EventEmitter<boolean>();
  @Output() onChangetemp2 = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Input() galleryData: any;
  @Input() editFlg: boolean;
  @Input() pageSelectedID = '';
  @Input() itemTemplate: any;
  user: any;
  popupClass = 'popup-width';
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;

  dataLangs = [];

  myRestrictionsImage: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png'],
  };

  myRestrictionsFile: FileRestrictions = {
    allowedExtensions: ['.doc', '.docx', '.pdf'],
  };

  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  dataItems: Array<{ Name: string; ID: string }>;
  dataItemsFilter: Array<{ Name: string; ID: string }>;

  public disabled = false;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  infoOpened = false;

  searchOption = {
    SearchText: '',
  };
  dataErr: any;
  pageName = '';
  filesUploadAvatar: '';
  filesUploadName = '';
  filesUpload: Array<FileInfo>;
  urlDownload = this.appService.apiRoot;
  public fileSaveUrl: any;
  dataGalleryItem: any;
  dataGalleryItemtemp: any;
  public uploadSaveAvatar = '';
  IsVietnameseTab = true;

  Personalelection: number[] = [];
  Personal: any;
  allowInsertFile = true;
  @Input() HiddenTab;
  //selectedLangID = this.appConsts.defaultLangID;
  dataResultOutput = {
    ID: '',
    SttResultFromUser: true,
  };
  categoriesList: Array<any> = [];
  categoriesListFiltered: Array<any> = [];
  @Input() templateID: '';

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts,
    private activatedRoute: ActivatedRoute
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    // this.getControl();
    this.setDefault();
    this.language.default();
    this.getAccoundID();
    // this.getPageName();
    this.GetAllLang();
    this.onGetCategories();
    // console.log(this.galleryData)
    if (this.editFlg == true) {
      this.dataGalleryItemtemp.ID = this.galleryData.ID;
      this.dataGalleryItemtemp.Info.MenuID =
        this.galleryData.infoFromMain.MenuID;
      this.dataGalleryItemtemp.Info.TopNumber =
        this.galleryData.infoFromMain.TopNumber;
      this.dataGalleryItemtemp.OrderIdx = this.galleryData.OrderIdx;
      this.dataGalleryItemtemp.GroupID = this.galleryData.GroupID;
      this.dataGalleryItemtemp.GroupIndex = this.galleryData.GroupIndex;
      this.dataGalleryItemtemp.Layout = this.galleryData.Layout;
    }
  }

  getAccoundID() {
    this.activatedRoute.queryParams.subscribe(async (params: any) => {
      if (params && params.accountID) {
        this.request_AccountID = params.accountID;
      }

      //this.getMeetingPeriod();
    });
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    this.control = await this.appControls.getControls(
      this.user.RoleID,
      AppConsts.page.galleryDialog
    );
    //this.controlDefault = false;
    //set true for dev
    this.controlDefault = true;
  }

  setDefault() {
    this.dataGalleryItem = {
      ID: this.guid.empty,
      TemplateID: '2',
      PageID: this.pageSelectedID,
      // Name: null,
      OrderIdx: null,
      Info: {
        TopNumber: null,
        MenuID: null,
      },
      Width: this.dataGalleryItem ? this.dataGalleryItem.Width : 100,
    };
    this.dataGalleryItemtemp = Object.assign({}, this.dataGalleryItem);

    var errTemp = Object.assign({}, this.dataGalleryItemtemp);
    this.dataErr = [errTemp];

    this.disabled = false;
    this.enabledID = true;
    // this.filesUploadAvatar = "";
    // this.filesUploadName = "";
    // this.fileSaveUrl = `${this.appService.apiRoot}api/Upload?dirName=${this.user.UserName}&typeData=files&acceptExtensions=[".png",".jpg",".jpeg",".gif"]`;
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
  }

  bindtemp(item) {
    this.dataGalleryItemtemp = Object.assign({}, item);
  }

  onReload() {
    //this.getMeetingPeriod();
    // location.reload();
    this.onSave.emit(true);
  }

  async onSaveGallery() {
    // if (this.dataGalleryItemtemp.ID == this.guid.empty || this.dataGalleryItemtemp.ID == null) {
    //   await this.addGallery();
    // }
    // else {
    //   await this.editGallery();
    // }
    if (
      this.dataErr[0].Info.MenuID != undefined &&
      this.dataErr[0].Info.TopNumber != undefined &&
      this.dataErr[0].Info.TopNumber > 0
    ) {
      const temp = this.dataGalleryItemtemp;
      if (!temp.Info || temp.Info.MenuID == null || temp.Info.MenuID == '') {
        return;
      }
      this.galleryData.infoFromMain.TopNumber =
        this.dataGalleryItemtemp.Info.TopNumber;
      this.galleryData.infoFromMain.MenuID =
        this.dataGalleryItemtemp.Info.MenuID;
      this.onChangetemp2.emit(temp);
      this.galleryManageOpened.emit(true);
    }
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataGalleryItemtemp;
    return {
      ID: temp.ID ? temp.ID : this.guid.empty,
      FrameID: 2,
      LangID: this.language.get(),
      TemplateID: temp.TemplateID ? temp.TemplateID : null,
      Type: 3, // frame
      PageID: temp.PageID ? temp.PageID : null,
      //Name: temp.Name ? temp.Name : null,
      OrderIdx: temp.OrderIdx ? temp.OrderIdx : null,
      Info: temp.Info ? JSON.stringify(temp.Info) : null,
      Width: temp.Width ? temp.Width : 100,
      GroupID: temp.GroupID ? temp.GroupID : 0,
      GroupIndex: temp.GroupIndex ? temp.GroupIndex : 0,
      Layout: temp.Layout ? temp.Layout : 100,
    };
  }

  async GetAllLang() {
    this.loading = true;

    const result = await this.appService.doGET('api/Lang/GetAllLang', null);
    if (result) {
      this.dataLangs = result.Data;
      var viLang = this.dataLangs.findIndex((x) => x.ID == 'vi-VN');
      if (viLang != 0 && viLang != -1) {
        this.dataLangs.splice(viLang, 1);
        this.dataLangs.unshift({
          ID: 'vi-VN',
          Name: 'Tiếng Việt',
        });
      }
    }
    this.loading = false;
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, '') + item;
    url = url.replace(/\"/g, '');
    return url;
  }

  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/Search', {
      searchText: '',
      langID: this.language.get(),
    });
    if (result && result.Status === 1) {
      this.categoriesList = this.categoriesListFiltered = result.Data;
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  handleFilter(value) {
    this.categoriesListFiltered = this.categoriesList.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
