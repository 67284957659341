<div class="row">
  <div *ngIf="isAdminFlg" class="vertical-dot d-flex">
    <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
    <div class="menu" *ngIf="isShowMenu">
      <div class="menu-row-current" (click)="deleteComponent()">
        <div class="menu-item-3">{{'Delete' | translate}}</div>
      </div>
    </div>
  </div>
  <!-- <div class="col-md-1 col-12"></div> -->
  <div class="col-md-12 col-12">
    <div class="member-unit">
      <div *ngIf="loading" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!loading && dataArr.length == 0">
        <div>Không có dữ liệu</div>
      </div>

      <div *ngIf="!loading && dataArr.length > 0" class="image-row">
        <div class="image-column">
          <div *ngFor="let item of dataIdx1" class="image-item">
            <a [href]="item.LinkUrl" target="_blank" class="image-container">
              <img [src]="appService.apiRoot + item.ImageUrl" />
              <div class="image-overlay">
                <h3 class="lb-name">{{ item.Name }}</h3>
              </div>
            </a>
          </div>
        </div>
        <div class="image-column">
          <div *ngFor="let item of dataIdx2" class="image-item">
            <a [href]="item.LinkUrl" target="_blank" class="image-container">
              <img [src]="appService.apiRoot + item.ImageUrl" />
              <div class="image-overlay">
                <h3 class="lb-name">{{ item.Name }}</h3>
              </div>
            </a>
          </div>
        </div>
        <div class="image-column">
          <div *ngFor="let item of dataIdx3" class="image-item">
            <a [href]="item.LinkUrl" target="_blank" class="image-container">
              <img [src]="appService.apiRoot + item.ImageUrl" />
              <div class="image-overlay">
                <h3 class="lb-name">{{ item.Name }}</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-md-1 col-12"></div> -->
</div>
<kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-12-dialog close-div">
      <div class="title-info-req title-center">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-12-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-12-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div>
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>