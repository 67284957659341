<section class="horizontal-timeline router-outlet-content ">
    <div class="col-md-12 col-12">
        <ul class="timeline-wrap">

            <!-- <div [ngStyle]="{backgroundColor: getColor()}" class="horizontal-timeline timeline"></div> -->
            <div [ngClass]="setClass()"></div>
            <li (click)="a = !a" [ngClass]="{'marker':true,
            'mfirst':true,
            'timeline-icon two':DocumentStatus == 1, 
            'timeline-icon one':DocumentStatus != 1}">
                <!-- <i class="fa fa-pencil"></i> -->
                <br>
                <br>
                <br>
                <h5 class="style-char">{{'DocumentStatus_DocumentRegistered'| translate}}</h5>
            </li>
            <li (click)="b = !b" [ngClass]="{'marker':true,
            'm2':true,
            'timeline-icon two':DocumentStatus == 2 || DocumentStatus == 3, 
            'timeline-icon one':DocumentStatus != 2 && DocumentStatus != 3}">
                <!-- <i class="fa fa-usd"></i> -->
                <br>
                <br>
                <br>
                <h5 class="style-char">{{( DocumentStatus == 1 ? 'DocumentStatus_WattingProgress'  : DocumentStatus == 2 ? 'DocumentStatus_Denied' :'DocumentStatus_Approved'  )| translate}}</h5>
            </li>
            <li (click)="c = !c" [ngClass]="{'marker':true,
            'm3':true,
            'timeline-icon two':DocumentStatus == 4, 
            'timeline-icon one':DocumentStatus != 4}">
                <!-- <i class="fa fa-list"></i> -->
                <br>
                <br>
                <br>
                <h5 class="style-char"> {{'DocumentStatus_Paid'| translate}}</h5>
            </li>
            <li (click)="d = !d" [ngClass]="{'marker':true,
            'mlast':true,
            'timeline-icon two':DocumentStatus == 5, 
            'timeline-icon one':DocumentStatus != 5}">
                <!-- <i class="fa fa-check"></i> -->
                <br>
                <br>
                <br>
                <h5 class="style-char">{{'DocumentStatus_Completed'| translate}}</h5>
            </li>
        </ul>
    </div>
</section>