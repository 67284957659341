import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { AppLanguage } from 'src/app/services/app.language';
import { Notification } from 'src/app/services/app.notification';
import { AppSwal } from 'src/app/services/app.swal';
import { AppControls } from 'src/app/services/app.controls';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-member-unit',
  templateUrl: './member-unit.component.html',
})
export class MemberUnitComponent implements OnInit {
  dataArr: any = [];
  dataIdx1: any;
  dataIdx2: any;
  dataIdx3: any;
  idx1: number = 0;
  idx2: number = 0;
  loading: boolean = true;

  isShowMenu = false;
  manageDialog = false;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex = 1;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Input() menuID: any;
  @Output() deleteBodyItem = new EventEmitter();

  constructor(
    public appService: AppService,
    private translate: TranslateService,
    private language: AppLanguage,
    private notification: Notification,
    private appSwal: AppSwal,
    public appControls: AppControls
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    var dataRequest = {
      langID: this.language.get(),
      type: 3
    }
    const result = await this.appService.doGET('api/PortalBody/GetCommon', dataRequest);
    if (result && result.Status === 1 && result != null) {
      this.dataArr = result.Data;
      this.handleData(this.dataArr);
    } else {
      if (!result.Msg) {
        this.appSwal.showWarning(result.Data, false);
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.loading = false;
  }

  handleData(data: Array<any>) {
    if (data.length > 0) {
      this.idx1 = Math.ceil(data.length / 3);
      this.dataIdx1 = data.slice(0, this.idx1);
      this.idx2 = Math.ceil((data.length - this.idx1) / 2);
      this.dataIdx2 = data.slice(this.idx1, this.idx1 + this.idx2);
      this.dataIdx3 = data.slice(this.idx1 + this.idx2);
    }
  }

  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
}
