import { Component, OnInit, OnDestroy, Input,Output, OnChanges, SimpleChanges, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../services/app.service';

// import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { dateFieldName, IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../../services/app.notification';
// import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
// import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
// import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';
// import * as moment from 'moment';
import { Breakpoints } from '@angular/cdk/layout';
import { any } from 'underscore';
import { PanelBarExpandMode } from "@progress/kendo-angular-layout";
import { QRCodeModule } from "angular2-qrcode";
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';


@Component({
  selector: 'app-content-document',
  templateUrl: './content-document.component.html',
  // styleUrls: ['./content-document.component.css']
})
export class ContentDocumentComponent implements OnInit, OnChanges, OnDestroy {
  customCss = 0;
  dataErr: any;
  @Input() Code: string ='';
  @Input() Password : string = '';
   @Input() countDuplicateKeys: number = 0;
  @Input()searchFlg: string;
  @Output() closeContentDocumentComponent = new EventEmitter<boolean>();
  LookupFlg = false;
  tempCode : string = '';
  // flag update
 UpdateFlg = false;
 isSuccess = false;
 loadingScan = false;
  public kendoPanelBarExpandMode: any = PanelBarExpandMode;

  dataGridItem: any;
  dataCvPro5s: any;
  gridViewCvPro5s: any =[];
  loading = false;
  flgSave = 0;
  flgApprove = false;
  flgDeny = false;
  DocumentStatusHozTimeline: number = 6;
  CodeOTP: string = '';
  dataCvPro5RegisterFiles: Array<{ID: any, UploadUrl: String, CVPro5ID: Number,
  RegisterDocumentID: any, DelFlg:Boolean, CreateAt:Date, UpdateAt:Date, FlgAdd:Boolean}> = [];

  openAppointment = false ; 
  openCancelDocument = false ; 
  openDeleteDocument = false ; 

  tempdatadataCvPro5RegisterFile: any;

  control: any;
  user: any;

  public sort: SortDescriptor[] = [
    {
      field: 'ID',
      dir: 'asc'
    }
  ];

  fileSaveUrl: any;
  filesUpload: Array<FileInfo>;
  filesUploadName = "";
  allowInsertFile = true;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';

  currentIndex: number;

  gender: Array<{ Name: string, ID: number }> = [];
  genderFilter: Array<{ Name: string, ID: number }> = [];
  nationality: Array<{ Name: string, ID: number }> = [];
  nationalityFilter: Array<{ Name: string, ID: number }> = [];
 CvPro5Groups: Array<{ Name: string, ID: number }> = [];
 CvPro5GroupsFilter: Array<{ Name: string, ID: number }> = [];

  public ngaytest: Date = new Date();
  controlDefault = true;
  
  public maxBirthDay = new Date();
  isPaying = false;
  paymentMethod = [];
  selectedPaymentMethod: any;

  PaymentType = {
    VNPAY: "",
    VNPTPAY: ""
  }
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
   // this.language.default();
  this.language.default();

  }

  async ngOnInit() {
    this.setDefault();
    this.initDisplay();
    await this.getConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onReload();
  }

  ngOnDestroy(): void {

  }

  async getConfig(){
    const result = await this.appService.doGET('api/Config/PaymentType', null);
    if (result && result.Status === 1 && result.Data != null) {
      this.PaymentType = JSON.parse(result.Data);
      localStorage.setItem('VNPAYID', this.PaymentType.VNPAY);
      localStorage.setItem('VNPTPAYID', this.PaymentType.VNPTPAY);      
    } 
  }

  checkErr() {
    if (this.customCss == 1) {
      return "x-dialog-content-document-err-one";
    } else if (this.customCss == 2) {
      return "x-dialog-content-document-err-two";
    } else if (this.customCss == 3) {
      return "x-dialog-content-document-err-three";
    } else if (this.customCss == 4) {
      return "x-dialog-content-document-err-four";
    } else {
      return "x-dialog-content-document-group"
    }
  }

  setDefault() {
    this.dataCvPro5RegisterFiles = [];
    this.dataGridItem = {
      IsAdd: true,
      ID: this.guid.empty,
      CvPro5GroupID: null,
      FullName: '',
       DateOfBirth: null,
      PlaceOfBirth :'',
      Gender: null,
     IDCardNumber :'',
      NationalID :'',
      Job: '',
      Address:'',
      Email : '',
      Phone:'',
      OrderIdx: null,
      ReturnDate: null,
      Note:'',
      LockFlg:null,
      DelFlg: false,
      CvPro5GroupStepIDs:'',
      Fee: null,
      CvPro5ListID: '',
      CvPro5GroupName: ''
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    this.flgSave = 0;
    this.filesUpload = [];
    this.filesUploadName = "";
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?personalID=${this.user.UserName}&typeData=files`;
  }
  async initDisplay() {
    const resultGender = await this.appService.doGET('api/Enum/GetEGender', null);
    if (resultGender && resultGender.Status === 1) {
      this.gender = resultGender.Data;
      this.genderFilter = this.gender.slice();
    }
    const langID =   this.language.get();
    const resultNationality = await this.appService.doGET('api/CvPro5RegisterDocument/Nationalities', {langID});
    if (resultNationality && resultNationality.Status === 1) {
      this.nationality = resultNationality.Data;
      this.nationalityFilter = this.nationality.slice();
    }

    const resultCvPro5Groups = await this.appService.doGET('api/CvPro5RegisterDocument/CvPro5Groups', {langID});
    if (resultCvPro5Groups && resultCvPro5Groups.Status === 1) {
      this.CvPro5Groups = resultCvPro5Groups.Data;
      this.CvPro5GroupsFilter = this.CvPro5Groups.slice();
    }

  //  this.getDataItemByCodeAndPassword(this.Code, this.Password);
  }
  async getDataItemByCodeAndPassword(Code: string, Password: string) {
this.flgSave = 0;
    const dataRequest = {
      code: Code,
      password: Password ? Password: '',
      langID: this.language.get()
    };

    const result = await this.appService.doGET('api/CvPro5RegisterDocument/Lookup', dataRequest);
    if (result && result.Status === 1 && result.Data != null) {
      this.tempCode = this.Code;
      this.dataGridItem = result.Data;
      if(this.dataGridItem.Status == null || this.dataGridItem.Status == 0 || this.dataGridItem.Status == 1)
      {       
        this.UpdateFlg = true;
      }
      else this.UpdateFlg = false;

      // let currentDate  = (moment(new Date())).format('YYYY-MM-DD');
      this.dataGridItem.Phone = this.dataGridItem.Phone? "0"+ parseInt( this.dataGridItem.Phone  ,10).toString() : ""  ;

      this.dataGridItem.DateOfBirth = this.dataGridItem.DateOfBirth ? new Date(this.dataGridItem.DateOfBirth) : null;
      this.dataGridItem.ReturnDate = this.dataGridItem.ReturnDate ? new Date(this.dataGridItem.ReturnDate) : null;
      if (!this.dataGridItem.Fee) {
        this.dataGridItem.Fee = this.dataGridItem.RefefenceFee ? this.dataGridItem.RefefenceFee : null;
      }
      this.dataGridItem.UnprocessedDocumentID = result.Data.ID;
      // this.disabled = false;
      // this.enabledID = false;
      const dataRequest2 = {
        CvPro5ListID: this.dataGridItem.CvPro5ListID,
       registerDocumentID: this.dataGridItem.ID,
       langID: this.language.get()
        //  langID: this.selectedLangID
      };
      const resultCvPro5s = await this.appService.doGET('api/CvPro5RegisterDocument/getCvPro5sExtend', dataRequest2);
      if (resultCvPro5s && resultCvPro5s.Status === 1 && resultCvPro5s.Data != null) {
        this.dataCvPro5s = resultCvPro5s.Data;

        const dataRequest3 = {
          RegisterDocumentID: this.dataGridItem.ID,

          //  langID: this.selectedLangID
        };
        const resultCvPro5ReFile = await this.appService.doGET('api/CvPro5RegisterDocument/getRegisterFile', dataRequest3);
        if (resultCvPro5ReFile && resultCvPro5ReFile.Status === 1 && resultCvPro5ReFile.Data != null) {
          this.dataCvPro5RegisterFiles = resultCvPro5ReFile.Data;

          for (var i = 0; i < this.dataCvPro5s.length; i++) {
            for (var j = 0; j < this.dataCvPro5RegisterFiles.length; j++) {
              if (this.dataCvPro5s[i].ID == this.dataCvPro5RegisterFiles[j].CVPro5ID) {
                this.dataCvPro5s[i].Status = 1;
                break;
              }
            }
          }
        }
     //   this.loadDataCvPro5s();
      }

    } else {
     //  this.closeContentDocumentComponent.emit(true);
  
     // this.appSwal.showWarning(result? result.Msg : this.translate.instant('No_Data'), false);
     this.appSwal.showWarning(this.translate.instant('No_Data'), false);
    
    }
    // Set document status  for hoz timeline
  this.setDocumentStatusHoz();
  }

   setDocumentStatusHoz() :void {
  // ++ Default statusHoz = 6 : Cancel Document 
    // ++ Sent document/ UnApproved
    if(this.dataGridItem.Status  == null || this.dataGridItem.Status  == 0) {
      this.DocumentStatusHozTimeline = 1;
          }
            // ++ Denied
          else if(this.dataGridItem.Status  == 1) {
            this.DocumentStatusHozTimeline = 2;
          }
           // ++ Approved/ Paid Fail
          else if(this.dataGridItem.Status  == 2 || this.dataGridItem.Status  == 3) {
            this.DocumentStatusHozTimeline = 3;
          }
           // ++ Paid success/ Cancel Appointment/ Appointment
           else if(this.dataGridItem.Status  == 4 || this.dataGridItem.Status  == 5 || this.dataGridItem.Status  == 6) {
            this.DocumentStatusHozTimeline = 4;
          }
             // ++ Signed
          else if(this.dataGridItem.Status  == 7 ) {
            this.DocumentStatusHozTimeline = 5;
          }
  }

  genderHandleFilter(e) {
    this.genderFilter = this.gender.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  }
  getNameGender(e){
    this.genderFilter = this.gender.filter((s) => s.ID == e);
    if (this.genderFilter != null){
      return this.genderFilter[0].Name ;
    }
    else return null ;
  
  }
  nationalityHandleFilter(e) {
    this.nationalityFilter = this.nationality.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  }
  CvPro5GroupsHandleFilter(e:any) {
    this.CvPro5GroupsFilter = this.CvPro5Groups.filter((s) => s.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1);
  }
 async  CvPro5GroupsSelectionChange(e: any) {
    this.dataGridItem.CvPro5ListID =e.CvPro5ListID;
    const dataRequest = {
      CvPro5ListID: this.dataGridItem.CvPro5ListID,
      langID: this.language.get()
     // registerDocumentID: this.dataGridItemID,

      //  langID: this.selectedLangID
    };
    const resultCvPro5s = await this.appService.doGET('api/CvPro5RegisterDocument/getCvPro5s', dataRequest);
    if (resultCvPro5s && resultCvPro5s.Status === 1 && resultCvPro5s.Data != null) {
      this.dataCvPro5s = resultCvPro5s.Data;

  //    this.loading = true;
  //    this.loadDataCvPro5s();
   //   this.loading = false;
  
    } else{
      this.dataCvPro5s =[];
      // this.loading = true;
      // this.gridViewCvPro5s =[];
      // this.loading = false;
    }
  }

  // loadDataCvPro5s(): void {
  //   this.gridViewCvPro5s = {
  //     data: orderBy(this.dataCvPro5s, this.sort),
  //     total: this.dataCvPro5s.length
  //   };

  // }


  //Upload Icon
  async onSelectFileToUpload(e: SelectEvent,rowIndex) {

    this.currentIndex = rowIndex;
    if (!e.files || e.files.length <= 0) {
      return;
    }
    const extension = e.files[0].extension.toLowerCase();
    this.allowInsertFile = true;
    try {
      const fileData = e.files[0]; // await this.file.readFile(e.files[0].rawFile);
      const maxMB = 30;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(
          this.translate.instant('Error_Size_Cannot_Be_Exceeded') +` ${maxMB} MB`,
          false
        );
        return;
      }
    } catch {
      this.appSwal.showError(e);
    }
    // tslint:disable-next-line: max-line-length
    if (
      !extension ||
      (extension.toLowerCase() !== ".jpeg" &&
        extension.toLowerCase() !== ".jpg" &&
        extension.toLowerCase() !== ".png" &&
        extension.toLowerCase() !== ".pdf")
    ) {
      this.allowInsertFile = false;
      this.appSwal.showWarning(
        this.translate.instant('Error_Image_File_Extension'),
        false
      );
      return false;
    }
    this.dataCvPro5s[this.currentIndex].Status = 1;
  }

  onSuccessFileToUpload(e: any) {

    if (!this.allowInsertFile) {
      return;
    }
    try {
      let  i =0;
      for( i = 0; i< this.dataCvPro5RegisterFiles.length; i++) {
        if (this.dataCvPro5s[this.currentIndex].ID == this.dataCvPro5RegisterFiles[i].CVPro5ID) {
          if (this.dataCvPro5RegisterFiles[i].UploadUrl == undefined) {
            this.dataCvPro5RegisterFiles[i].UploadUrl = '';
          }
          this.dataCvPro5RegisterFiles[i].UploadUrl = 
            `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
            this.dataCvPro5RegisterFiles[i].UpdateAt = new Date();
            break;
        }

      }

      if(i == this.dataCvPro5RegisterFiles.length  ){
        this.dataCvPro5RegisterFiles.push( {
          ID: this.guid.empty,
          UploadUrl:'' +
         `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`,
         CVPro5ID: this.dataCvPro5s[this.currentIndex].ID,
         RegisterDocumentID: this.dataGridItem.ID,
         DelFlg: false,
         CreateAt: new Date(),
         UpdateAt: null,
         FlgAdd: true
        },
        )
      //  this.FlgAdd[i] = true;
    
      }
      this.dataCvPro5s[this.currentIndex].UploadUrl=
      `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      
    //   this.loading = true;
    //   this.loadDataCvPro5s();
    //  this.loading = false;
      

    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileDocToUpload() {
    this.dataCvPro5RegisterFiles[this.currentIndex].UploadUrl = '';
    this.filesUploadName = "";
    this.filesUpload = [];
  }

  getRowIndex(rowIndex) {
    this.currentIndex = rowIndex;
  }

  onSaveGrid() {

      this.updateGrid(); 
   this.flgSave = 0;
  }
  
  // onDeleteGrid(){

  // }
//   async addGrid() {
//     this.appComponent.loading = true;
//     if(!this.dataCvPro5RegisterFiles || this.dataCvPro5RegisterFiles.length == 0 || this.dataCvPro5RegisterFiles.length < this.dataCvPro5s.length) {

//       this.appSwal.showWarning(this.translate.instant('Missing_Attachments'), false);
//     return
//     }
//     console.log("datagirdItemAdd",this.dataGridItem);
  
//   // const dataRequest = this.createDataRequest(null);
//   var ID;

//     for(var i =0; i < this.dataCvPro5RegisterFiles.length; i++) {
//       ID = this.guid.create();
//       this.dataCvPro5RegisterFiles[i].RegisterDocumentID = ID;
//     } 
//     this.dataGridItem.Phone  = parseInt( this.dataGridItem.Phone  ,10);
//       const dataRequest = {
//         Document: this.dataGridItem,
//         ListFiles: this.dataCvPro5RegisterFiles
//       }
//       console.log("dataRequest",dataRequest);
 
//       if(!dataRequest){ 
//        this.appSwal.showWarning(this.translate.instant('No_Data'), false);
//        return};
 
//    const result = await this.appService.doPOST('api/CvPro5RegisterDocument/AddDocument', dataRequest);
//    console.log("result", result);
//     if (result && result.Status === 1) {
//       this.notification.showSuccess(result.Msg);
//       this.dataGridItem ={};
//       var errTemp = Object.assign({}, this.dataGridItem);
//       errTemp.Type = null;
//       this.dataErr = [errTemp];
      
//       this.onReload();
//       this.onAddNewGrid();
//     } else {
//       if (!result.Msg) {
//       this.dataErr = result.Data;
//       var count = 0;
//       for (var prop in this.dataErr[0]) {
//         count++;
//       }
//       this.customCss = count;
//     }  else {
//         this.appSwal.showWarning(result.Msg, false);
//       }
//     }
//     this.appComponent.loading = false;
  
// }

  // onAddNewGrid() {
  //   this.setDefault();

  //   this.loading = true; 
  //   this.gridViewCvPro5s = [];
  //   this.loading = false;

  //   this.FlgAdd = true;
  //   this.editOpened = true;
  // }

  async updateGrid() {
    const dataRequest0 = {
      Document: this.dataGridItem,
      ListFiles: this.dataCvPro5RegisterFiles,
      OTP: '',
    }
    
    // const dataRequest = this.createDataRequest(null);
    if (!dataRequest0) {
      this.appSwal.showWarning(this.translate.instant('No_Data'), false);
      //this.appComponent.loading = false;
      return
    };
    
    //const result0 = await this.appService.doPOST('api/RegisterDocument/CheckStateOne', dataRequest0);


   // this.appComponent.loading = true;

    if(!this.dataCvPro5RegisterFiles || this.dataCvPro5RegisterFiles.length == 0 || this.dataCvPro5RegisterFiles.length < this.dataCvPro5s.length) {

      this.appSwal.showWarning(this.translate.instant('Missing_Attachments'), false);
     // this.appComponent.loading = false;
    return
    }
    const iD = this.dataGridItem.ID;
    this.dataGridItem.Phone  = parseInt( this.dataGridItem.Phone  ,10);
    const dataRequest = {
      Document: this.dataGridItem,
      ListFiles: this.dataCvPro5RegisterFiles,
      OTP: this.CodeOTP
    }
    if(!dataRequest){ 
      this.appSwal.showWarning(this.translate.instant('No_Data'), false);
      return};

      // check validate Data before PUT
      const resultcheck = await this.appService.doPUT('api/CvPro5RegisterDocument/checkValidateDocument', dataRequest,null);
      if (resultcheck && resultcheck.Status === 1) {
        this.clearErrMsg();
    }
    else {
      if (!resultcheck.Msg){
        this.flgSave = this.flgSave - 1;
        this.dataErr = resultcheck.Data;
        var count = -1;
        for (var prop in this.dataErr[0]) {
          count++;
        }
        this.customCss = count;
      } else {
        this.appSwal.showWarning(resultcheck.Msg, false);
      }
    //  this.appComponent.loading = false;
      return;
    }
    // const dataRequest = this.createDataRequest(null);
// check otp match
if (this.dataGridItem.OTP == '' || this.dataGridItem.OTP == null || this.dataGridItem.OTP != this.CodeOTP) {
  
  this.appSwal.showWarning(this.translate.instant('Error_OTP'), false);
 // this.appComponent.loading = false;
  return
}
// PUT data
    const result = await this.appService.doPUT('api/CvPro5RegisterDocument', dataRequest, { iD });

    if (result && result.Status === 1) {
    //  this.updateDataRegisterFiles();
      this.notification.showSuccess(result.Msg);
      
      this.dataGridItem ={};
      var errTemp = Object.assign({}, this.dataGridItem);
      errTemp.Type = null;
      this.dataErr = [errTemp];
      this.isSuccess = true;
     this.onReload();
     // this.onCloseInfo();
    } else {
      if (!result.Msg){
        this.dataErr = result.Data;
        var count = -1;
        for (var prop in this.dataErr[0]) {
          count++;
        }
        this.customCss = count;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }

  //  this.appComponent.loading = false;

  }

  // createDataRequest(data: any): any {

  //   const temp = data ? data : this.dataGridItem;
  //   return {
      
  //     ID: temp.ID,
  //     Name: temp.Name,
  //     Description: temp.Description,
  //     OrderIdx: temp.OrderIdx,

  //    CvUnprocessedDocumentID: -1,
  //   };
  // }

  // onCloseInfo() {
  //   this.editOpened = false;
  //   this.infoOpened = false;
    

  // }
  onReload(){
    this.getDataItemByCodeAndPassword(this.Code, this.Password);
 
  }
  onRefresh(){
    this.dataGridItem =[];
    this.Code =  '';
    this.Password = '';
    this.DocumentStatusHozTimeline = 5;
    this.dataCvPro5s =[];
    // this.loading = true;
    // this.loadDataCvPro5s();
    // this.loading = false;
  }
//  updateDataRegisterFiles(){
//     var iD;
// var result;
// var dataRegisterFile;
// console.log("show data",this.dataCvPro5RegisterFiles);
//     if(this.dataCvPro5RegisterFiles) {
//       for(var i =0; i< this.dataCvPro5RegisterFiles.length; i++) {
  
//           dataRegisterFile =this.dataCvPro5RegisterFiles[i]


//         console.log("datarequest",dataRegisterFile);
//         if(this.dataCvPro5RegisterFiles[i]) {
//         if ( this.dataCvPro5RegisterFiles[i].FlgAdd != true) {
//           iD = this.dataCvPro5RegisterFiles[i].ID;
//           console.log("cùng dataRequest[i]",dataRegisterFile);
//            this.appService.doPUT('api/CvPro5RegisterDocument/RegisterFile', dataRegisterFile, { iD });
//         }
//         else {
//           this.dataCvPro5RegisterFiles[i].FlgAdd = false;
//           console.log("khac dataRequest[i]",dataRegisterFile);
//              this.appService.doPOST('api/CvPro5RegisterDocument/RegisterFile', dataRegisterFile);
//         }
//       }
//       }
//     } 
//   }

//   onAgree() {
// this.flgApprove = true;
//   }
//   onDeny() {
//     this.flgDeny = true; 
//   }
  // async onConfirm() {
  //   if(this.flgApprove) {
  //     this.dataGridItem.ApprovedFlg = 1;}
  //     else  { this.dataGridItem.ApprovedFlg = 0;}
    
 
    
  //   if(!this.dataCvPro5RegisterFiles || this.dataCvPro5RegisterFiles.length == 0 || this.dataCvPro5RegisterFiles.length < this.dataCvPro5s.length)  {

  //     this.appSwal.showWarning(this.translate.instant('Missing_Attachments'), false);
  //     return
  //     }
  //     this.appComponent.loading = true;
  //     const iD = this.dataGridItem.ID;
  //     this.dataGridItem.Phone  = parseInt( this.dataGridItem.Phone  ,10);
  //     const dataRequest = {
  //       Document: this.dataGridItem,
  //       ListFiles: this.dataCvPro5RegisterFiles
  //     }
      
  //     if(!dataRequest) {
  //       this.appSwal.showWarning(this.translate.instant('No_Data'), false);
  //       return
  //     };
  //     // const dataRequest = this.createDataRequest(null);
  
  //     const result = await this.appService.doPUT('api/CvPro5RegisterDocument/ApproveDocument', dataRequest, { iD });
  //    // this.updateDataRegisterFiles();
  //     if (result && result.Status === 1) {
  //       this.notification.showSuccess(result.Msg);
        
  //       this.dataGridItem ={};
  //       var errTemp = Object.assign({}, this.dataGridItem);
  //       errTemp.Type = null;
  //       this.dataErr = [errTemp];

  //       this.flgApprove = false;
  //       this.flgDeny = false;
  //       this.appComponent.loading = false;
  //        this.closeDialogAdd.emit(true);
  //   //   this.onReload();
  //   //    this.onCloseInfo();
  //     } else {
  //       if (!result.Msg){
  //         this.dataErr = result.Data;
  //         var count = -1;
  //         for (var prop in this.dataErr[0]) {
  //           count++;
  //         }
  //         this.customCss = count;
          
  //       } else {
  //         this.appSwal.showWarning(result.Msg, false);
  //     return;
  //       }
  //     }

  //     }
  onCloseApprove(){
        this.flgApprove = false;
        this.flgDeny = false;
      }

    async  onContinue(){
     // this.appComponent.loading = true;
        if( this.flgSave != 2) {
          this.flgSave = this.flgSave + 1 ;}

    
            const dataRequest = {
              Document: this.dataGridItem,
              ListFiles: this.dataCvPro5RegisterFiles,
              OTP: this.CodeOTP
            }
                    // check validate data, get so ky tu, get ngay hen khi continue
            if( this.flgSave == 1) {
              this.loadingScan  = true;
                    //  set example returnDate to validate;
              this.dataGridItem.ReturnDate = new Date( new Date().getTime() + (1000 * 60 * 60 * 24))

              const result = await this.appService.doPOST('api/RegisterDocument/CheckStateOne', dataRequest);
              if (result && result.Status === 1) {

                this.dataGridItem.Fee = result.Data.PayAmount;
               // this.dataGridItem.ReturnDate = new Date(new Date().getTime()+((this.dataGridItem.NumberDay ? this.dataGridItem.NumberDay :0) *24*60*60*1000));
                this.dataGridItem.ReturnDate  =  new Date( result.Data.ExpectedDate);
            
                this.clearErrMsg();
                this.loadingScan = false;
              } else {
                if (!result.Msg) {

                  this.dataErr = result.Data;
                } else {
             
                  this.appSwal.showWarning(result.Msg, false);
                }
                this.loadingScan = false;
                this.flgSave = this.flgSave - 1;
              }
            }
            else {
        // check validate data 
            const result = await this.appService.doPUT('api/CvPro5RegisterDocument/checkValidateDocument', dataRequest,null);
            if (result && result.Status === 1) {
              this.clearErrMsg();
          }
          else {
            if (!result.Msg){
              this.flgSave = this.flgSave - 1;
              this.dataErr = result.Data;
              var count = -1;
              for (var prop in this.dataErr[0]) {
                count++;
              }
              this.customCss = count;
            } else {
              this.appSwal.showWarning(result.Msg, false);
            }
       //     this.appComponent.loading = false;
            return;
          }
        }
        // send OTP to customer
          if(this.flgSave == 2)
          {
            const dataRequest = {
              Document: this.dataGridItem,
              ListFiles: this.dataCvPro5RegisterFiles,
              OTP: ''
            }
            
            // const dataRequest = this.createDataRequest(null);
            if (!dataRequest) {
              this.appSwal.showWarning(this.translate.instant('No_Data'), false);
           //   this.appComponent.loading = false;
              return
            };
            const result = await this.appService.doPOST('api/RegisterDocument/CheckStateTwo', dataRequest);
            if (result && result.Status === 1) {
              this.CodeOTP = result.Data;

           //  console.log(result.Data);
            }
            else {
              if (!result.Msg) {
        
                this.dataErr = result.Data;
              } else {
                this.appSwal.showWarning(result.Msg, false);
              }
            }
        }
      //  this.appComponent.loading = false;
      }

  onOpenAppointment(){
    this.openAppointment = true;
    }
    
  onCloseAppointment(){
    this.openAppointment = false ; 
  }
  onOpenCancelDocument(){
    this.openCancelDocument = true ;
  }
  onOpenDeleteDocument(){
    this.openDeleteDocument = true ;
  }
  onCloseCancelDocument(){
    this.openCancelDocument = false ;
  }
  onCloseDeleteDocument(){

    this.openDeleteDocument = false;
  }

  async onCancelDocument(){
    this.loading = true ;
    const id = this.dataGridItem.ID ; 
    const dataRequest = {
      code: this.Code,
      password: this.Password,
    }
    const result = await this.appService.doPUT('api/CvPro5RegisterDocument/CancelDocument', dataRequest, { id });
    if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
    }

    this.loading = false ;
    this.onCloseCancelDocument();
    this.getDataItemByCodeAndPassword(this.Code, this.Password);
 
  }
    

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 47 && charCode < 58) {
      return true;
    }
    return false;
  }

  getFileName(IconUrl) {
    var nameFile = "";
    if (IconUrl != "" && IconUrl != null) {
      var urlArr = IconUrl.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  getUrlDownload(item) {
    let url = this.appService.apiRoot.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }


      onCloseSuccess() {
        this.isSuccess = false;
      }

      
      onOpenPayment() {
        this.isPaying = true;
        this.getPaymentMethod();
      }

      getIcon() {
        var k = this.appService.apiRoot + this.selectedPaymentMethod.IconUrl;
        return k;
      }
      
onClosePayingInfo() {
        this.isPaying = false;
        this.selectedPaymentMethod = null;
      }

      
      async getPaymentMethod() {

        const dataRequest = {
          searchText: '',
          langID: this.language.get()
        };
    
        const result = await this.appService.doGET('api/Payment/Search', dataRequest);
        if (result && result.Status === 1) {
          this.paymentMethod = result.Data;
    
        } else {
          this.appSwal.showWarning(result.Msg, false);
        }
      }

      choosePaymentMethod(item) {
        this.selectedPaymentMethod = item;
      }

      async onPay() {
        if(this.selectedPaymentMethod == null || this.selectedPaymentMethod == undefined) {
          this.appSwal.showWarning(this.translate.instant('Choose_Payment_Method'), false);
        }
        else if(this.selectedPaymentMethod.ID == localStorage.getItem('VNPAYID')) {
          const dataRequest = {
            strId: this.dataGridItem.ID
          };
          const result = await this.appService.doGET('api/Payment/GetURLPaymentVNPay', dataRequest);
          if (result && result.Status === 1) {
            window.parent.location.href = result.Data.URL;
          }else {
            this.appSwal.showWarning(result.Msg, false);
          }
        } else if(this.selectedPaymentMethod.ID == localStorage.getItem('VNPTPAYID')) {
          const dataRequest = {
            strId: this.dataGridItem.ID
          };
          const result = await this.appService.doGET('api/Payment/GetURLPaymentVNPTPay', dataRequest);
          if (result && result.Status === 1) {
            window.parent.location.href = result.Data.URL;
          } else {
            this.appSwal.showWarning(result.Msg, false);
          }
        } else {
          this.appSwal.showWarning(this.translate.instant('UnSpported_Payment_Method'), false);
        }
      }

      numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

      async onDeleteDocument() {
     //   this.appComponent.loading = true;
        const dataRequest = {
          iD: this.dataGridItem.ID
        };
    
       // const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
   
          const result = await this.appService.doDELETE('api/CvPro5RegisterDocument', dataRequest);
          if (result && result.Status === 1) {
            this.notification.showSuccess(result.Msg);
           //  this.closeContentDocumentComponent.emit(true);
           this.onRefresh();

           
          } else {
            this.appSwal.showWarning(result.Msg, false);
          }

        this.onCloseDeleteDocument();
      //  this.appComponent.loading = false;
      }

      clearErrMsg(){

        var errTemp = Object.assign({}, this.dataGridItem);
        errTemp.Type = null;
        this.dataErr = [errTemp];
      }

  }


