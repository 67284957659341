import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';
import {
  SelectableSettings,
  PageChangeEvent,
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  State,
  process,
  SortDescriptor,
  orderBy,
} from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppComponent } from '../app.component';
import { AppConsts } from 'src/app/services/app.consts';
import { CategoryAxisNotesLabelComponentGenerated } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  // styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit, OnDestroy {
  user: any;
  folderParentage = [];
  currentFolder = {
    ID: this.guid.empty,
    Name: 'Đảng và đoàn thể',
    ParentID: this.guid.empty,
  };
  emptyGuid = this.guid.empty;
  isAdminFlg: boolean = false;
  pageBody = [];
  templates: any;
  @Input() menuID: any;
  request_RewardID: any;
  popupClass = 'popup-width';
  btnFunctionData: Array<any> = [];
  btnMbFunctionData: Array<any> = [];
  loading = false;
  dataGrids = [];
  dataGridSelectableSettings: SelectableSettings;
  dataGridSort = {
    allowUnsort: true,
    mode: 'multiple',
  };
  public dataGridFocus = {
    Name: true,
  };
  dataGridSortByField: SortDescriptor[] = [];

  dataGridSkip = AppConsts.pageSkip;
  dataGridPageSize = AppConsts.pageSize;
  dataGridSelection: number[] = [];
  dataGridItem: any;
  dataLangs = [];
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

  selectedLangID = AppConsts.defaultLangID;
  currentLang: any;
  customCss = 0;
  public Type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  // dataTest = [
  //   {
  //     STT: 1,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 2,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 3,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 4,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 5,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 6,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 7,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 8,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 9,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 10,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  //   {
  //     STT: 11,
  //     theLoai: 'Hướng dẫn',
  //     soVanBan: '61-HD/BTGTU',
  //     trichYeu:
  //       'Hướng dẫn 61 – HD/BTGTU ngày 21 tháng 9 năm 2022 của Ban Tuyên giáo Thành ủy TPHCM về Công tác tuyên truyền quý IV năm 2022',
  //     ngayBanHanh: '21-09-2022',
  //     coQuanBanHanh: 'Ban Tuyên giáo Thành ủy TPHCM',
  //   },
  // ];

  public dataGridState: State = {
    skip: this.dataGridSkip,
    take: this.dataGridSkip + this.dataGridPageSize,

    filter: {
      logic: 'and',
      filters: [],
    },
    // group: [{ field: 'ParentName' }]
  };
  dataGridGridDataResult: GridDataResult;

  dataItems: Array<{ Name: string; ID: string }>;
  dataItemsFilter: Array<{ Name: string; ID: string }>;
  unit: Array<{ Name: string; ID: string }> = [];
  unitFilter: Array<{ Name: string; ID: string }> = [];
  types: Array<{ Name: string; ID: string }> = [];
  typesFilter: Array<{ Name: string; ID: string }> = [];
  public enabled = true;
  public enabledID = false;
  control: any;
  controlDefault = true;
  allowMulti = false;
  infoOpened = false;
  ManageRewardOpened = false;

  searchOption = {
    SearchText: '',
    periodYearID: null,
    periodID: null,
    // tabID: 0,
    Type: 0,
  };
  dataErr: any;

  pageName = '';
  roles: Array<{ Name: string; ID: string }>;
  rolesFilter: Array<{ Name: string; ID: string }>;
  defaultType: any;
  typeSearch: any;
  titleTypeFilter: Array<{ ID: number; Name: string }> = [
    { ID: 0, Name: 'Cá nhân' },
    { ID: 1, Name: 'Tập thể' },
  ];

  titleType: Array<{ ID: number; Name: string }> = [
    { ID: 0, Name: 'Cá nhân' },
    { ID: 1, Name: 'Tập thể' },
  ];

  titleTypeFilterYear: Array<{ ID: number; Name: string }> = [
    { ID: 1, Name: 'Năm hiện tại' },
    { ID: 2, Name: '2 năm liên tiếp' },
    { ID: 3, Name: '3 năm liên tiếp' },
    { ID: 4, Name: '4 năm liên tiếp' },
    { ID: 5, Name: '5 năm liên tiếp' },
  ];

  titleTypeYear: Array<{ ID: number; Name: string }> = [
    { ID: 1, Name: 'Năm hiện tại' },
    { ID: 2, Name: '2 năm liên tiếp' },
    { ID: 3, Name: '3 năm liên tiếp' },
    { ID: 4, Name: '4 năm liên tiếp' },
    { ID: 5, Name: '5 năm liên tiếp' },
  ];

  titleTypeFilterClassify: Array<{ ID: number; Name: string }> = [
    { ID: 0, Name: 'Phần trăm' },
    { ID: 1, Name: 'Số lượng' },
  ];
  titleTypetitleTypeFilterClassify: Array<{ ID: number; Name: string }> = [
    { ID: 0, Name: 'Phần trăm' },
    { ID: 1, Name: 'Số lượng' },
  ];

  years: Array<{ Name: string; ID: string }> = [];
  yearsFilter: Array<{ Name: string; ID: string }> = [];
  periods: Array<{ Name: string; ID: string }> = [];
  periodsFilter: Array<{ Name: string; ID: string }> = [];
  rewardName: Array<{ Name: string; ID: string; Type: number }> = [];
  rewardNameFilter: Array<{ Name: string; ID: string }> = [];
  rewardMethod: Array<{ Name: string; ID: string }> = [];
  rewardMethodFilter: Array<{ Name: string; ID: string }> = [];
  element4: any;
  categoriesList = [];
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    // private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    // private appUtils: AppUtils,
    private appComponent: AppComponent,
    // private domSanitizer: DomSanitizer,
    public appConsts: AppConsts
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    // this.get4Type();
    // this.getPageParent();
    // this.getEmulationType()
  }

  ngOnDestroy(): void { }

  ngOnInit() {
    // this.getControl();
    // this.setDefault();
    this.language.default();
    // this.setSelectableSettings();
    // this.initDisplay();
    // this.getYears();
    // this.getPeriods(this.searchOption.periodYearID);
    // this.getdataItems();
    // this.getRewardMethod();
    // this.getRewardName();
    this.onReload();
    this.getTemplate();
    this.onGetCategories();
    setTimeout(() => {
      // this.removeIcon();
      // this.addIcon();

    }, 100);

    this.allData = this.allData.bind(this);
    // this.getPageName();
    // this.GetAllLang();
    this.currentLang = this.language.get();
  }

  async getEmulationType() {
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      type: 5,
      // langID : localStorage.getItem('currentLanguage')
    };
    const result = await this.appService.doPOST(
      'api/CommonMenu/Search',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.types = result.Data;
      this.typesFilter = this.types.slice();
      // this.searchOption.Type = this.typesFilter[0] ? this.typesFilter[0].ID : null
    }
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    // this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.rewardLevelUp);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    //this.allowMulti = !this.allowMulti;
    this.dataGridSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single',
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearchTextRemove(e: any) {
    this.searchOption.SearchText = '';
    this.onSearch();
  }

  dataItemsHandleFilter(value) {
    this.dataItemsFilter = this.dataItems.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  parentHandleFilter(value) {
    this.parentPagesFilter = this.parentPages.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  async getdataItems() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      periodYearID: this.searchOption.periodYearID,
      periodID: this.searchOption.periodID,
      // type: this.searchOption.type,
      // tabID: this.searchOption.tabID
    };
    // this.dataGrids = this.dataTest;
    // console.log(this.dataGrids);
    const result = await this.appService.doGET(
      'api/RewardConfig/Search',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.dataItemsFilter = result.Data.slice();
      this.dataGrids = result.Data.map((temp) => {
        return {
          ID: temp.ID,
          LimitValueName: temp.LimitValueName,
          PeriodName: temp.PeriodName,
          PeriodYearName: temp.PeriodYearName,
          RewardMethodNameNext: temp.RewardMethodNameNext,
          RewardMethodName: temp.RewardMethodName,
          RewardNameNext: temp.RewardNameNext,
          // YearNum: temp.YearNum,
          // YearText: this.getNumberYear(temp.YearNum),
          Type: temp.Type,
          TypeText: this.getNumberType(temp.Type),
        };
      });
      this.binddataItems();
      this.dataItems = [];

      result.Data.forEach((item) => {
        this.dataItems.push(item);
      });
      for (var i = 0; i < this.dataGrids.length; i++) {
        this.dataGrids[i].STT = i + 1;
      }
    }
    this.loading = false;
  }

  // async getPageInfo() {
  //   const result = await this.appService.doGET('api/Menu/GetByName', { 'name': 'Trang chủ' });
  //   if (result && result.Status === 1) {
  //     this.menuID = result.Data?.ID;
  //   }
  // }

  async getTemplate() {
    const result = await this.appService.doGET('api/PortalDesign/GetDesign', {
      menuID: this.menuID  ? this.menuID : this.GUID_EMPTY
    });
    if (result && result.Status === 1) {
      this.templates = result.Data;
      var bodys = [];
      for (let i = 0; i < this.templates.length; i++) {
        var item = this.templates[i];
        var groupId = item.GroupID;
        var layout = item.Layout;
        let index = bodys.findIndex(x => x.id == groupId && x.layout == layout);
        if (index != -1) {
          bodys[index].items.push(item)
        } else {
          var itemBody = {
            id: groupId,
            layout: layout,
            items: [item]
          }
          // bodys.push(itemBody);
          if (layout < 100 && this.templates.findIndex(x => x.Layout == (100 - layout)) == -1) { // không tồn tại layout nào chung hàng thì tự add thêm
            if (layout < 50) {
              bodys.push({
                id: groupId + 100,
                layout: 100 - layout,
                items: []
              });
              bodys.push(itemBody);
            } else {
              bodys.push(itemBody);
              bodys.push({
                id: groupId + 100,
                layout: 100 - layout,
                items: []
              });
            }

          } else {
            bodys.push(itemBody);
          }
        }
      }
      this.pageBody = bodys;


    } else {
      // show popup error
    }
  }

  getConnectedList(): any[] {
    return this.pageBody.map(x => `${x.id}`);
  }

  setDefault() {
    this.dataGridItem = {
      IsAdd: true,
      RewardMethodID: null,
      // OrderIdx:null,
      // RewardMethodID: null,
      PeriodYearID: null,
      PeriodID: null,
      RewardMethodNextID: null,
      // YearNum: 1,
      LimitType: 0,
      Type: 0,
      LimitValue: null,
      // Type: this.searchOption.type != null ? this.searchOption.type : null
    };
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
    this.dataGridSelection = [];
    this.enabled = true;
    this.enabledID = true;
  }

  onGridPageChange(event: PageChangeEvent) {
    this.dataGridSkip = event.skip;
    this.binddataItems();
  }

  ondataItemselectedKeysChange() { }

  binddataItems() {
    this.dataGridGridDataResult = {
      data: orderBy(this.dataGrids, this.dataGridSortByField),
      total: this.dataGrids.length,
    };
    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  ondataItemsortChange(sort: SortDescriptor[]): void {
    this.dataGridSortByField = sort;
    this.binddataItems();
  }

  public onGridDataStateChange(state: DataStateChangeEvent): void {
    this.dataGridSelection = [];
    this.dataGridState = state;
    this.dataGridGridDataResult = process(this.dataGrids, this.dataGridState);
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getdataItems();
  }

  async initDisplay() {
    // this.getUnit()
  }

  onSearch() {
    this.getdataItems();
  }

  async onReload() {
    // await this.getEmulationType()
    //this.searchOption.SearchText = '';
    //  this.getdataItemsParent();
    // this.getdataItems();
    // this.removeIcon();
  }

  onClearGrid() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  async onAddNewGrid() {
    // this.infoOpened = true;
    const body = document.body;
    const scrollY = body.style.top;

    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    // this.onFilterRewardName(this.dataGridItem.Type);
    this.setDefault();
    this.getRewardName();
    this.getRewardMethod();
    this.getPeriods(this.dataGridItem.PeriodYearID);

    this.infoOpened = true;
    document.body.classList.add('disable-scroll');
    // if(this.searchOption.Type != null){
    //   this.getUnit()}
  }

  onSaveGrid() {
    if (this.dataGridItem.IsAdd) {
      this.addGrid();
    } else {
      this.updateGrid();
    }
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataGridItem;
    // var UnitIDs  = temp.UnitIDs;
    // if(typeof temp.UnitIDs == 'object') {
    //   UnitIDs  = JSON.stringify(temp.UnitIDs);
    // }

    return {
      ID: temp.ID,
      Name: temp.Name,
      PeriodID: temp.PeriodID,
      PeriodYearID: temp.PeriodYearID,
      // RewardMethodID: temp.RewardMethodID,
      RewardMethodID: temp.RewardMethodID,
      // OrderIdx: temp.OrderIdx,
      RewardMethodNextID: temp.RewardMethodNextID,
      // YearNum: temp.YearNum,
      LimitValue: temp.LimitValue,
      Type: temp.Type,
      LimitType: temp.LimitType,
    };
  }

  onCloseGrid(status: any) {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async onEditGrid(dataItem: any) {
    const body = document.body;
    const scrollY = body.style.top;

    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    this.enabled = false;
    this.getYears();
    this.getPeriods(this.dataGridItem.PeriodYearID);

    this.getRewardMethod();
    // this.onFilterRewardName(this.dataGridItem.Type)
    this.selectedLangID = AppConsts.defaultLangID;
    this.enabled = true;
    document.body.classList.add('disable-scroll');
    // if(this.searchOption.Type != null){
    //   this.getUnit()}
    // this.language.set(this.selectedLangID);
    await this.getDataItemByID(dataItem.ID);
    await this.getRewardName();
  }

  async getDataItemByID(id: any) {
    // var oldSearch = this.searchOption.SearchText
    const dataRequest = {
      iD: id,
      // langID: this.selectedLangID
    };

    const result = await this.appService.doGET('api/RewardConfig', dataRequest);
    if (result && result.Status === 1) {
      this.dataGridItem = result.Data;
      // this.getUnit()
      // this.getdataItemsParent()
      // this.searchOption.SearchText = this.dataGridItem.Type;
      this.dataGridItem.ID = result.Data.ID;
      if (typeof this.dataGridItem.UnitIDs == 'string') {
        var arr = [];
        try {
          arr = JSON.parse(this.dataGridItem.UnitIDs);
        } catch (e) {
          arr.push(this.dataGridItem.UnitIDs);
        }
        this.dataGridItem.UnitIDs = arr;
      }
      this.enabled = false;
      this.enabledID = false;
      this.infoOpened = true;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async addGrid() {
    // this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST(
      'api/RewardConfig',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.getdataItems();
      // this.onAddNewGrid();
      this.onCloseInfo();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
        var count = 0;
        for (var prop in this.dataErr[0]) {
          count++;
        }
        this.customCss = count;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }
  async updateGrid() {
    // this.appComponent.loading = true;
    const iD = this.dataGridItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT(
      'api/RewardConfig',
      dataRequest,
      { iD }
    );
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onCloseInfo();
      this.getdataItems();
    } else {
      if (!result.Msg) {
        this.dataErr = result.Data;
        var count = -1;
        for (var prop in this.dataErr[0]) {
          count++;
        }
        this.customCss = count;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  // checkErr() {
  //   if(this.customCss == 1) {
  //     return "x-dialog-unit-contact-err-one";
  //   } else if(this.customCss == 2) {
  //     return "x-dialog-unit-contact-err-two";
  //   }else if(this.customCss == 3) {
  //     return "x-dialog-unit-contact-err-three";
  //   }else if(this.customCss == 4) {
  //     return "x-dialog-unit-contact-err-four";
  //   } else {
  //     return "x-dialog-unit-contact"
  //   }
  // }

  async onDeleteGrid(dataItem: any) {
    // this.appComponent.loading = true;
    const dataRequest = {
      iD: dataItem.ID,
      // langID: this.selectedLangID
    };

    const option = await this.appSwal.showWarning(
      this.translate.instant('AreYouSure'),
      true
    );
    if (option) {
      const result = await this.appService.doDELETE(
        'api/RewardConfig',
        dataRequest
      );
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.getdataItems();
        this.onCloseInfo();
        this.dataGridSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    // this.appComponent.loading = false;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataGrids, {}).data,
    };

    return result;
  }

  onCloseInfo() {
    this.infoOpened = false;
    this.setDefault();
    document.body.classList.remove('disable-scroll');
    this.selectedLangID = AppConsts.defaultLangID;
    this.enabled = true;
    // this.selectedLangID = localStorage.getItem('currentLanguage');
    this.language.set(this.currentLang);
  }
  async onCloseDialog() {
    this.setDefault();
    this.ManageRewardOpened = false;
    document.body.classList.remove('disable-scroll');
  }

  onChangeFunction(e, dataItem) {
    if (e.id == 'Edit') {
      this.onEditGrid(dataItem);
    } else if (e.id == 'Delete') {
      this.onDeleteGrid(dataItem);
    } else if (e.id == 'Approve') {
      this.onApprove(dataItem);
    }
  }
  onFunctionIconClick(dataItem) {
    this.getbtnFunctionData(dataItem);
  }

  getbtnFunctionData(dataItem) {
    this.btnFunctionData = [];
    this.btnMbFunctionData = [];
    if (this.controlDefault || this.control.Edit) {
      var func1 = {
        text: this.translate.instant('Edit'),
        id: 'Edit',
        icon: 'pencil',
      };
      this.btnFunctionData.push(func1);
      this.btnMbFunctionData.push(func1);
    }

    if (this.controlDefault || this.control.Delete) {
      var func3 = {
        text: this.translate.instant('Delete'),
        id: 'Delete',
        icon: 'delete',
      };
      this.btnFunctionData.push(func3);
      this.btnMbFunctionData.push(func3);
    }
  }

  async GetAllLang() {
    this.loading = true;

    const result = await this.appService.doGET('api/Lang/GetAllLang', null);
    if (result) {
      this.dataLangs = result.Data;
      var viLang = this.dataLangs.findIndex((x) => x.ID == 'vi-VN');
      if (viLang != 0 && viLang != -1) {
        this.dataLangs.splice(viLang, 1);
        this.dataLangs.unshift({
          ID: 'vi-VN',
          Name: 'Tiếng Việt',
        });
      }
    }
    this.loading = false;
  }

  onChangeLang(langID) {
    this.selectedLangID = langID;
    var tempItem = {
      IsAdd: true,
      ID: this.guid.empty,
      Address: '',
      Name: '',
      Description: '',
      Phone: '',
      UpdateAt: null,
      TypeID: null,
      UrlPath: '',
    };
    var errTemp = Object.assign({}, tempItem);
    errTemp.TypeID = null;
    this.dataErr = [errTemp];
    this.getDataItemByID(this.dataGridItem.ID);
    // this.language.set(this.selectedLangID);
  }

  Types: Array<{ Name: string; ID: number }>;
  TypesFilter: Array<{ Name: string; ID: number }>;
  get4Type() {
    var a = [];
    a[0] = this.translate.instant('TypeHomepage0');
    a[1] = this.translate.instant('TypeHomepage1');
    a[2] = this.translate.instant('TypeHomepage2');
    a[3] = this.translate.instant('TypeHomepage3');
    a[4] = this.translate.instant('TypeHomepage4');
    this.Types = [];
    for (let i = 0; i < 5; i++) {
      this.Types.push({
        ID: i,
        Name: a[i],
      });
    }
    this.TypesFilter = this.Types.slice();
    this.loading = false;
  }

  parentPages: Array<{ Name: string; ID: string }>;
  parentPagesFilter: Array<{ Name: string; ID: string }>;

  async getPageParent() {
    const dataRequest = {
      searchText: '',
      Langid: this.language.get(),
    };
    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result && result.Status === 1) {
      this.parentPages = [];
      this.parentPages.push({
        ID: null,
        Name: 'Root',
      });

      // result.Data.forEach(item => {
      //   if (!item.ParentID) {
      //     this.parentPages.push(item);
      //   }
      // });

      this.parentPagesFilter = result.Data; //this.parentPages.slice();
    }
  }
  parentPagesHandleFilter(value) {
    this.parentPagesFilter = this.parentPages.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  public dataMenuFocus = {
    Name: true,
  };

  rolesHandleFilter(value) {
    this.rolesFilter = this.roles.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  async getUnit() {
    const resultCatolories = await this.appService.doGET('api/Unit/GetByType', {
      unitTypeID:
        this.dataGridItem.TypeID == undefined ? null : this.dataGridItem.TypeID,
    });
    if (resultCatolories && resultCatolories.Status === 1) {
      this.unit = resultCatolories.Data;
      this.unitFilter = this.unit.slice();
    }
  }
  unitHandleFilter(value) {
    this.unitFilter = this.unit.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  typeHandleFilter(value) {
    this.typesFilter = this.types.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  onChangeType(e: any) {
    this.getdataItems();
    // this.getUnit()
    // if(this.searchOption.Type == undefined){
    //   this.searchOption.Type =   null}
  }
  clearTypeSearch() {
    // this.searchOption.Type = null;
  }

  cmHandleFilter(value) {
    this.titleTypeFilter = this.titleType.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.titleTypeFilterClassify = this.titleTypeFilterClassify.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.titleTypeFilterYear = this.titleTypeYear.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  async getYears() {
    const result = await this.appService.doGET('api/PeriodYear/Search', {
      searchText: '',
    });
    if (result && result.Status === 1) {
      this.years = result.Data;
      this.yearsFilter = this.years.slice();
    }
  }
  yearsHandleFilter(value) {
    this.yearsFilter = this.years.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  async getPeriods(periodYear) {
    const dataRequest = {
      searchText: '',
      PeriodYearID: periodYear,
    };
    const result = await this.appService.doGET(
      'api/Period/Search',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.periods = result.Data;
      this.periodsFilter = this.periods.slice();
    }
  }
  periodsHandleFilter(value) {
    this.periodsFilter = this.periods.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  async onChangeYear(e: any) {
    this.searchOption.periodYearID = this.searchOption.periodYearID
      ? this.searchOption.periodYearID
      : null;
    this.getPeriods(this.searchOption.periodYearID);
  }
  async onChangePeriod(e: any) {
    this.searchOption.periodID = this.searchOption.periodID
      ? this.searchOption.periodID
      : null;

    // this.getPeriods()
  }
  // onTabChanged(e) {
  //   this.searchOption.tabID = e.index ;
  //   this.getdataItems()
  // }
  onChangeYearDialog() {
    this.dataGridItem.PeriodYearID = this.dataGridItem.PeriodYearID
      ? this.dataGridItem.PeriodYearID
      : null;
    this.dataGridItem.PeriodID = this.dataGridItem.PeriodID
      ? null
      : this.dataGridItem.PeriodID;
    this.getPeriods(this.dataGridItem.PeriodYearID);
  }
  async getRewardName() {
    const dataRequest = {
      searchText: '',
      type: this.dataGridItem.Type,
    };
    const result = await this.appService.doGET(
      'api/RewardMethod/Search',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.rewardName = result.Data;
      this.rewardNameFilter = this.rewardName.slice();
    }
  }

  RewardNameHandleFilter(value) {
    this.rewardNameFilter = this.rewardName.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  RewardNameNextHandleFilter(value) {
    this.rewardMethodFilter = this.rewardMethod.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  onchangeTypeDialog(e: any) {
    this.getRewardName();
    this.getRewardMethod();

    this.dataGridItem.RewardMethodID = this.dataGridItem.RewardMethodID
      ? null
      : this.dataGridItem.RewardMethodID;
    this.dataGridItem.RewardMethodNextID = this.dataGridItem.RewardMethodNextID
      ? null
      : this.dataGridItem.RewardMethodNextID;
  }

  onchangeType(e: any) {
    this.getRewardName();
    this.dataGridItem.RewardMethodID = this.dataGridItem.RewardMethodID
      ? null
      : this.dataGridItem.RewardMethodID;
    this.dataGridItem.LimitValue = this.dataGridItem.LimitValue
      ? null
      : this.dataGridItem.LimitValue;
    this.dataGridItem.RewardMethodNextID = this.dataGridItem.RewardMethodNextID
      ? null
      : this.dataGridItem.RewardMethodNextID;
    // this.dataGridItem.YearNum = this.dataGridItem.YearNum ? null : this.dataGridItem.YearNum
    this.dataGridItem.LimitType = this.dataGridItem.LimitType
      ? null
      : this.dataGridItem.LimitType;
    this.dataGridItem.Type = this.dataGridItem.Type
      ? null
      : this.dataGridItem.Type;
  }
  async getRewardMethod() {
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      type: this.dataGridItem.Type,
      langID: localStorage.getItem('currentLanguage'),
    };
    const result = await this.appService.doGET(
      'api/RewardMethod/Search',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.rewardMethod = result.Data;
      this.rewardMethodFilter = this.rewardMethod.slice();
    }
  }
  RewardMethodHandleFilter(value) {
    this.rewardNameFilter = this.rewardMethod.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  async getDenyReason(item: any) {
    // var oldSearch = this.searchOption.SearchText
    const dataRequest = {
      RewardRegistID: item.ID,
      status: item.StatusID,
      // langID: this.selectedLangID
    };

    const result = await this.appService.doGET(
      'api/RewardComment',
      dataRequest
    );
    if (result && result.Status === 1) {
      // this.dataGridItem = result.Data;

      this.appSwal.showWarning(
        'Lý do từ chối: ' + result.Data.Desctiption,
        false
      );
      // this.enabled = false;
      // this.enabledID = false;
      // this.warn = true;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  async onApprove(item: any) {
    const dataRequest = {
      ID: item.ID,
    };
    const result = await this.appService.doPOST(
      'api/RewardRegist/Approved',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.getdataItems();
      // this.onAddNewGrid();
      // this.onCloseInfo();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async onPass(item: any) {
    const dataRequest = {
      ID: item.ID,
    };
    const result = await this.appService.doPOST(
      'api/RewardRegist/Passed',
      dataRequest
    );
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.getdataItems();
      // this.onAddNewGrid();
      // this.onCloseInfo();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }
  onFail(item: any) {
    this.request_RewardID = item.ID;
    this.ManageRewardOpened = true;
  }
  onReject(item: any) {
    this.request_RewardID = item.ID;
    this.ManageRewardOpened = true;
  }
  getNumberYear(num: number) {
    if (num && this.titleTypeFilterYear[num - 1].Name != undefined)
      return this.titleTypeFilterYear[num - 1].Name;
    return 'Năm hiện tại';
  }
  getNumberType(ID: number) {
    if (ID && this.titleTypeFilter[ID].Name != undefined)
      return this.titleTypeFilter[ID].Name;
    return 'Cá nhân';
  }
  // Remove class Icon
   removeIcon() {
    var element = document.querySelector(".k-pager-nav").firstElementChild;
    var element2 = document.querySelector(".k-pager-last").firstElementChild;
    var element3 = document.querySelector(".k-i-arrow-w").parentElement;
    var element4 = document.querySelector(".k-pager-numbers li").firstElementChild;

   //element3.remove();
    element.classList.remove("k-i-seek-w");
    element2.classList.remove("k-i-seek-e");
    // console.log(element4.innerHTML);
    //Kiểm tra nếu page1 thì ẩn
    if(element4.innerHTML == ' 1 ')
    {
    // console.log(element3);
    // console.log(element4);
      element3.classList.add("check-element");
    }else
    {
      element3.classList.add("k-i-arrow-w");
    }
  }
//Add class icon
   addIcon() {
    var element = document.querySelector(".k-pager-nav").firstElementChild;
    var element2 = document.querySelector(".k-pager-last").firstElementChild;
    element.classList.add("k-i-arrow-seek-left");
    element2.classList.add("k-i-arrow-seek-right");
  }
  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/Search', {
      searchText: '',
      langID: this.language.get()
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data;
    }
  }
}
