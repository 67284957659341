<section class=" question-info router-outlet-content">
    <div class="col-md-8 col-12 col-sm-12 ">
        <div class="shadow p-3 mb-5 bg-white rounded employe-detail">
            <div class="x-close-div">
                <div class="title-info-req">
                    <strong>{{'DataQuestion_QuestionInfo'| translate}}</strong>
                </div>
            </div>
            <div class="col-md-12 col-12 ">
                <div class="row">

                    <div class="col-md-12 col-12">
                        <div [ngClass]="'control'">
                            <label>
                        <span [textContent]="'DataQuestion_FullName' | translate"></span>
                        <span class="required">*</span>
                        <span> :</span>
                    </label>
                            <input [ngClass]="dataErr[0]['FullName'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.FullName" />
                            <span class="err-text " *ngIf="dataErr[0]['FullName'] ">{{dataErr[0]['FullName']}}</span>
                        </div>
                    </div>

                    <div class="col-md-12 col-12">
                        <div [ngClass]="'control'">
                            <label>
                        <span [textContent]="'DataQuestion_Address' | translate"></span>
                        <span> :</span>
                    </label>
                            <textarea kendoTextArea [ngClass]="dataErr[0]['Address'] ? 'err' : ''" rows="2" [(ngModel)]="dataGridItem.Address"></textarea>
                            <span class="err-text " *ngIf="dataErr[0]['Address'] ">{{dataErr[0]['Address']}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 col-12">
                        <div [ngClass]="'control'">
                            <label>
                        <span [textContent]="'DataQuestion_Phone' | translate"></span>
                        <span> :</span>
                    </label>
                            <input (keypress)="numberOnly($event)" maxlength="12" [ngClass]="dataErr[0]['Phone'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Phone" />
                            <span class="err-text " *ngIf="dataErr[0]['Phone'] ">{{dataErr[0]['Phone']}}</span>
                        </div>
                    </div>

                    <div class="col-md-12 col-12">
                        <div [ngClass]="'control'">
                            <label>
                        <span [textContent]="'DataQuestion_Email' | translate"></span>
                        <span> :</span>
                    </label>
                            <input [ngClass]="dataErr[0]['Email'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Email" />
                            <span class="err-text " *ngIf="dataErr[0]['Email'] ">{{dataErr[0]['Email']}}</span>
                        </div>
                    </div>

                    <div class="col-md-12 col-12">
                        <div [ngClass]="'control'">
                            <label>
                        <span [textContent]="'DataQuestion_Title' | translate"></span>
                        <span class="required">*</span>
                        <span> :</span>
                    </label>
                            <input [ngClass]="dataErr[0]['Title'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Title" />
                            <span class="err-text " *ngIf="dataErr[0]['Title'] ">{{dataErr[0]['Title']
                        }}</span>
                        </div>
                    </div>

                    <div class="col-md-12 col-12">
                        <div [ngClass]="'control'">
                            <label>
                        <span [textContent]="'DataQuestion_Contents' | translate"></span>
                        <span class="required">*</span>
                        <span> :</span>
                    </label>
                            <textarea kendoTextArea [ngClass]="dataErr[0]['Contents'] ? 'err' : ''" rows="9" [(ngModel)]="dataGridItem.Contents"></textarea>
                            <span class="err-text " *ngIf="dataErr[0]['Contents'] ">{{dataErr[0]['Contents']}}</span>
                        </div>
                    </div>

                    <div class="col-md-12 col-12">
                        <div class="button-div">

                            <button class="btn-send" (click)="onSendQuestion()">
        {{ 'DataQuestion_SendQuestion' | translate }}
      </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>