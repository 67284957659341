import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';
import { Router } from '@angular/router';
import { Console } from 'console';
import * as FileSaver from 'file-saver';
import { AppSwal } from '../services/app.swal';

@Component({
  selector: 'app-news-template-49',
  templateUrl: './news-template-49.component.html',
  // styleUrls: ['./news-template-49.component.scss']
})
export class NewsTemplate49Component implements OnInit {

  topNews: any;
  postList: any;
  videoList: any;
  radioList: any;
  // topNumber: number = 3;
  bootstrapNum: number = 4;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  @Input() srcURL: any;
  galleryData: any;
  editFlg = false;
  dltFlg = false;
  @HostListener('window:resize', ['$event'])
  innerWidth: any;
  innerHeight: any;
  @Input() OrderIdx: any;
  @Input() isPreview;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate2 = new EventEmitter();
  @Output() saveData = new EventEmitter();
  @Output() tittleItem = new EventEmitter();
  // @Output() urlItem = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() layoutGroupIndex: any;
  @Input() pageBodyIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  postDetailRoute = AppConsts.POST_DETAIL_ROUTE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  categoriesListFiltered: any;
  categoryParent: any;
  widthScroll = "100%";
  heightScroll = "25px";
  activeIndex = 0;
  scrollPercent: number = 0;
  previousIndex: number = 0;
  selectedCategoryId: string;
  selectedTab: number;
  PageSelectedID: any;
  isShowDialogVideo = false;
  public index = 0;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private appSwal: AppSwal,
  ) { }

  async ngOnInit(): Promise<void> {
    if(typeof this.infoFromMain == 'string'){
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    await this.onGetCategories();
    this.getPostTittle();   
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate ? this.itemTemplate.GroupID : 0,
      "Layout": this.itemTemplate ? this.itemTemplate.Layout : 100,
      "GroupIndex": this.itemTemplate ? this.itemTemplate.GroupIndex : 0
    }
    if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Tiêu đề',
          Summary: 'Tóm tắt',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
       
    }
    
    this.categoriesListFiltered = [
      {
        ID: 1,
        Name: "Bài viết liên quan"
      },
      {
        ID: 2,
        Name: "Video"
      },
      {
        ID: 3,
        Name: "Radio"
      }
    ]
    
    if(this.categoryParent){
      this.categoriesListFiltered[0].Selected = true;
      this.selectedTab = 1;
      await this.getTopNews(this.categoryParent.ID);
    }
  }
  async getTopNews(ID?) {
    if(typeof this.infoFromMain == 'string'){
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    switch (this.selectedTab) {
      case 2:
        const resultVideo = await this.appService.doGET(
          'api/PortalPost/GetVideoByMenu',
          {
            menuID: ID ? ID : this.infoFromMain.MenuID,
          }
        );
        if (resultVideo && resultVideo.Status === 1) {
          this.videoList = resultVideo.Data;
        }
        break;
      case 3:
        const resultRadio = await this.appService.doGET(
          'api/PortalPost/GetRadioByMenu',
          {
            menuID: ID ? ID : this.infoFromMain.MenuID,
          }
        );
        if (resultRadio && resultRadio.Status === 1) {
          this.radioList = resultRadio.Data;
        }
        break;
      default:
        const result = await this.appService.doGET(
          'api/PortalPost/GetByMenuID',
          {
            langID: this.language.get(),
            menuID: ID ? ID : this.infoFromMain.MenuID,
            topNum: this.infoFromMain.TopNumber,
          }
        );
        if (result && result.Status === 1) {
          this.postList = result.Data.topNews;
        }
        break;
    }
    
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }

  onGetTittleItem(e) {
    this.tittleItem.emit(e);
  }



  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }



  onDeleteGallery() {
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }

  changeTemp2(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    // this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.onGetCategories();
    this.getTopNews(this.infoFromMain.MenuID);
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate2.emit(newTemp);
  }

  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }

  onSaveData() {
    this.saveData.emit();
  }

  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }
  setH(){
    var a = document.querySelector('.post-list') as HTMLElement;
    if(a.getBoundingClientRect().width <= 808){
      return 'img-figure-2'
    }
    return ;
  }

  async onGetCategories() {
    const result = await this.appService.doGET('api/Menu/SearchMenuByParent', {
      menuID: this.infoFromMain.MenuID
    });
    if (result && result.Status === 1) {
      this.categoriesList = result.Data?.Menus;
      // this.categoriesListFiltered = result.Data?.Menus;
      this.categoryParent = result.Data?.MenuItem;
      
    } 
  }

  async onSelectedCategory(item?, index?){
    //set color
    let categoryId = "category"+ index;
    let previousCategoryId = "category" + this.previousIndex;
    if(this.previousIndex != -1 && document.getElementById(previousCategoryId) != null){
      document.getElementById(previousCategoryId).style.color = "#3E3E3E";
    }
    this.categoriesListFiltered.forEach((element) => {
      if (element.ID == item.ID) {
        element.Selected = true;
      } else {
        element.Selected = false;
      }
    });
    this.PageSelectedID = item.ID;
    
    this.previousIndex = index;
    //get category id
    this.selectedCategoryId = this.categoryParent.ID;
    this.selectedTab = this.categoriesListFiltered[index].ID;
    await this.getTopNews(this.selectedCategoryId);
  }


  openDialogVideo(item) {
    this.srcURL = item;
    this.isShowDialogVideo = true;
    document.body.classList.add('dialog-opened');
  }

  onCloseDialogVideo() {
    this.isShowDialogVideo = false;
  }

  getVideo(fileUrls) {
    if (fileUrls) {
      let videos = JSON.parse(fileUrls);
      if (videos) {
        let videoUrl = `<figure style=" height: 100%; width: 100%"><iframe src="https://www.youtube.com/embed/` + videos[0] + '" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></figure>';
        return videoUrl;
      }
      return '';
    }
  }

  getAudioUrl(url){
    let audio = JSON.parse(url);
    if (audio) {
      let audioUrl = this.appService.apiRoot + audio;
      return audioUrl;
    }
    return '';
  }

  getIndex(id){
    this.index = this.radioList.map(object => object.ID).indexOf(id);
    this.index ++;
    if (this.index < 10) {
      return "0" + this.index;
    }
    else {
      return this.index;
    }
  }

  downloadFile(list, id){
    var arr = list ? JSON.parse(list): [];
    if(arr.length > 0){
      arr.forEach(element => {
        this.downloadItem(element);
        this.countDownload(id)
      });
    }
  }

  async downloadItem(urls){
    const dataRequest = {
      url: urls,
    };

    const result = await this.appService.doDownload(
      'api/PortalOffice/Download',
      dataRequest
    );
    if (result) {
      var nameFile = this.getFileName(urls);
      FileSaver.saveAs(result, nameFile);
    }
  }

  getFileName(fileUrls) {
    var nameFile = 'temp.txt';
    if (fileUrls != '' && fileUrls != null) {
      var urlArr = fileUrls.split('/');
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != '' && nameFile != null) {
          var indexOfFirst = nameFile.indexOf('_');
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  async countDownload(id) {
    const iD = id
    const result = await this.appService.doPOST('api/Media/CountDownloadRadio', {
      ID: id
    });
    if (result && result.Status === 1) {
      this.onReloadDownload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async onReloadDownload() {
    this.selectedTab = 3;
    this.getTopNews();
  }

}
