import { Component, EventEmitter, Input, OnInit, Output,ViewEncapsulation, ViewChild } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from '../services/app.consts';
import { Router } from '@angular/router';
import { Notification } from '../services/app.notification';
import { EventEmitterService } from '../event-emitter.service';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
// import { DiagramComponent, TreeInfo } from '@syncfusion/ej2-angular-diagrams';
// import {
//     NodeModel, ConnectorModel, DiagramTools, Diagram, DataBinding, ComplexHierarchicalTree,
//     SnapConstraints, SnapSettingsModel, LayoutModel, LayoutOrientation
// } from '@syncfusion/ej2-diagrams';
// import { DataManager } from '@syncfusion/ej2-data';
import { ChangeEventArgs as NumericChangeEventArgs } from '@syncfusion/ej2-inputs';

import {
  Diagram, ConnectorModel, Node, DataBinding, HierarchicalTree, TreeInfo,
  } from '@syncfusion/ej2-diagrams';
  Diagram.Inject(DataBinding, HierarchicalTree);
  import {
  DataManager,
  Query
  } from '@syncfusion/ej2-data';
import { DiagramConstraints, DiagramTools, NodeConstraints,ConnectorConstraints, NodeModel, Rect, IClickEventArgs } from '@syncfusion/ej2-angular-diagrams';
Diagram.Inject(DataBinding);
export interface DataInfo {
  data: string;
}
export interface EmployeeInfo {
  Name: string;
  color: string;
}

@Component({
  selector: 'app-org-tree',
  templateUrl: './org-tree.component.html',
  // styleUrls: ['./org-tree.component.scss'],
})
export class OrgTreeComponent implements OnInit {
  @ViewChild('diagram')
  //public diagram: DiagramComponent;
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = '';
  viewMore: string = '';
  isShowMenu = false;
  manageDialog = false;
  descriptionDialog = false;
  public pageSize = 12;
  public skip = 0;
  public pagedTopnews = [];
  public total = 0;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = '';
  @Input() width: any;
  galleryData: any;
  @Input() itemTemplate: any;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() tittleItem = new EventEmitter();
  @Output() urlItem = new EventEmitter();
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() changeTemplate1 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() isPreview: boolean;
  @Input() newsDecorationIndex: any;
  @Input() isMore: boolean = true;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  isViewAllList = false;
  LINK_DEFAULT_IMAGE = AppConsts.LINK_DEFAULT_IMAGE;
  public GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  nameID = "element";
  nameID2 = "element2";
  nameID3 = "element3";
  nameID4 = "element4";
  nameID5 = "element5";
  nameID6 = "element6";
  nameID7 = "element7";
  nameID8 = "element8";
  nameID9 = "element9";
  nameID10 = "element10";
  isFirstNode
  defaultNodeType = AppConsts.nodeType.rectangleWithShadow;
  // tempNodeType: NodeModel;
  // tempData: any;
  dataUnit: any;
  unitChildMembers: any;
  unitType: Array<{ Name: string, ID: string }> = [];
  rootUnitID: string = '';
  fifthteenDataTemp = [
    {
      ID: '1',
      Name: 'VP tổng cty'
    },
    {
      ID: '2',
      Name: 'VP tổng cty'
    },
    {
      ID: '3',
      Name: 'VP tổng cty'
    },
    {
      ID: '4',
      Name: 'VP tổng cty'
    },
    {
      ID: '5',
      Name: 'VP tổng cty'
    },
    {
      ID: '6',
      Name: 'VP tổng cty'
    },
    {
      ID: '7',
      Name: 'VP tổng cty'
    },
  ]
  data: object[ ] = [
    {
        Id: 1,
        Name: "General Manager",
        //hideRoot: true
        Role: "Manager"
    },
    {
        Id: 2,
        Name: "Assistant Manager",
        Team: 1,
        ImageUrl: 'http://localhost:8080//Upload/Document/cc6ada03-f2d0-48d4-9212-8db146a33e58/20230314090041754_Rectangle 926.png'
    },
    {
        Id: 3,
        Name: "Human Resource Manager",
        Team: 1,
        ImageUrl: 'http://localhost:8080//Upload/Document/cc6ada03-f2d0-48d4-9212-8db146a33e58/20230314090041754_Rectangle 926.png',
        Role: "Resource"
    },
    {
        Id: 4,
        Name: "Design Manager",
        Team: 1
    },
    {
        Id: 5,
        Name: "Operation Manager",
        Team: 1
    },
    {
        Id: 6,
        Name: "Marketing Manager",
        Team: 1
    }
  ];
  dataUserOfUnit: any;
  dataChart: any;
  //chart info
  layoutType:any;
  chartOrientation: any;
  chartType: any;
  nodeType: any;
  unitByEUnitType: any;
  dataChartType4: any;
  dataChartBTK: any;
  pbnvNum: number = 1;
  dvttNum: number = 1;
  innerWidth: number;
  clickedName: string = '';
  orgDescription: any;
  orgName: any;
  orgFileUrls: any;
  urlDownload = this.appService.apiRoot;

  items: DataManager = new DataManager(this.data as JSON[], new Query().take(7));
  diagram: Diagram = new Diagram({
    width: '100%',
    height: '530px',
    constraints: DiagramConstraints.None, 
    snapSettings: {
        constraints: 0
    },//Uses layout to auto-arrange nodes on the diagram page
    layout: {
      //Sets layout type
      type: 'OrganizationalChart',
      // define the getLayoutInfo
      getLayoutInfo: (node: Node, options: TreeInfo) => {
        if (node.data['Name'] === 'General Manager') {
            options.assistants.push(options.children[0]);
            options.children.splice(0, 1);
        }
        if (!options.hasSubTree) {
            options.type = 'Center';
            options.orientation = 'Horizontal';
        }
      }
    },//Initializes the node template.
    dataSourceSettings: {
      id: 'Id',
      parentId: 'Team',
      dataManager: this.items
    },
    //Sets the default properties for nodes and connectors
    getNodeDefaults: (obj: Node, diagram: Diagram) => {
      obj.width = 387;
      obj.height = 82;
      obj.borderColor = 'None';
      obj.style=  {
        fill: 'white',
        strokeColor: '#0099DC'
      },
      obj.borderWidth = 1;
      obj.shape = {
        type: 'Basic',
        shape: 'Rectangle',
        cornerRadius: 10,
      }
      obj.constraints = NodeConstraints.Default | NodeConstraints.Shadow,
      obj.shadow = {
        angle: 50,
        opacity: 0.8,
        distance: 9,
        color: '#0099DC',
      }
      obj.annotations = [{
        content: obj.data['Name'],
        style: {
          color: '#015293',
          fontFamily:  'Myriad Pro, sans-serif',
          fontSize: 21,
          bold: true
        }
      }];
      return obj;
    },
    getConnectorDefaults: (connector: ConnectorModel, diagram: Diagram) => {
      connector.style = {
        strokeColor: '#000000',
        strokeWidth: 1,
        strokeDashArray: '2,2'
      };
      connector.targetDecorator.style.fill = '#6BA5D7';
      connector.targetDecorator.style.strokeColor = '#6BA5D7';
      connector.targetDecorator.shape = 'None';
      connector.type = 'Orthogonal';
      return connector;
    }
  });
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private notification: Notification,
    private eventEmitterService: EventEmitterService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.infoFromMain = JSON.parse(this.infoFromMain);
    this.topNumber = this.infoFromMain?.TopNumber;
    this.innerWidth = window.innerWidth;
    if (this.topNumber > 12) {
      this.isViewAllList = true
    }
    this.bootstrapNum = 12 / this.topNumber;
    if (this.topNumber > 3) {
      this.bootstrapNum = 4
    }
    this.getPostTittle();
    this.getTopNews();
    await this.getRootUnitID();  
    await this.getDataUnitRoot(this.rootUnitID);
    await this.getUnitChildInfo();
    await this.getUnitTypeFilter();
    await this.getUnitByEUnitType();

    this.galleryData = {
      ID: this.ID,
      OrderIdx: this.OrderIdx,
      infoFromMain: this.infoFromMain,
      GroupID: this.itemTemplate ? this.itemTemplate.GroupID : 0,
      Layout: this.itemTemplate ? this.itemTemplate.Layout : 100,
      GroupIndex: this.itemTemplate ? this.itemTemplate.GroupIndex : 0,
    };
    // if (this.router.url == '/' + AppConsts.page.templatePage) {
    //   document.getElementById('template-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    // else {
    //   document.getElementById('main-body-' + this.galleryData.OrderIdx).style.width = this.galleryData.Width + '%';
    // }
    if ((this.ID == null || this.ID == this.GUID_EMPTY) && this.isAdminFlg == true || this.isPreview) {
      this.topNews = [
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
        {
          DefaultFlg: true,
          ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
          Title: 'Title',
          UrlPath: '',
          CreateAt: '2021-10-26T08:41:50.453',
        },
      ];
    }
    if (this.infoFromMain.MenuID == '') {
      this.pagedTopnews = this.topNews;
    }
    setTimeout(async () => {
      // this.changeDataOfDataManager(this.dataUnit);
      // await this.setDiagramsOptions('OrganizationalChart','Horizontal','Center', AppConsts.nodeType.rectangleWithShadow);
      // this.diagram.appendTo('#chart0');


      for(let i = 0; i <= this.dataUnit.length; i++){
        if(i == 0){
          await this.changeDataOfDataManager(this.dataUnit);
          this.setChartInfoByType(this.dataUnit[i].Type);
          await this.setDiagramsOptions(this.layoutType,this.chartOrientation,this.chartType, this.nodeType);
          this.diagram.layout.verticalSpacing = 80;
          this.diagram.appendTo('#chart'+this.dataUnit[i].ID);
          if(this.diagram.element != null){
            this.diagram.fitToPage();
          } 
        } else {
          if(this.dataUnit[i] && this.dataUnit[i].ID){
            await this.getUserOfUnit(this.dataUnit[i].ID, this.dataUnit[i].Type);
            await this.changeDataOfDataManager(this.dataChart);
            if(this.dataUnit[i].Type != 4){
              this.setChartInfoByType(this.dataUnit[i].Type);
              await this.setDiagramsOptions(this.layoutType,this.chartOrientation,this.chartType, this.nodeType, this.dataUnit[i].ID);
              this.diagram.layout.verticalSpacing = 80;
              this.diagram.appendTo('#chart'+this.dataUnit[i].ID);
              if(this.diagram.element != null){
                this.diagram.fitToPage();
              } 
            }
            
          }                  
        }
      }       
      // this.data = [
      //   {
      //     "ID": "Ban-chuyen-mon",
      //     "Name": "BAN CHUYÊN MÔN",
      //     "ParentID": "bna-tong-giam-doc",
      //     "hideRoot": true,
      //     "Type": 1
      //   },
      //   {
      //     "ID": "Ban-kiem-toan-noi-bo",
      //     "Name": "Ban kiểm toán nội bộ",
      //     "ParentID": "Ban-chuyen-mon",
      //     "hideRoot": false,
      //     "Type": 1
      //   }
      // ]
      // this.items = new DataManager(this.data as JSON[]);
      // console.log("this.items ban chuyen mon", this.items);
      
      // this.setChartInfoByType(1);
      // await this.setDiagramsOptions(this.layoutType,this.chartOrientation,this.chartType, this.nodeType);
      // this.diagram.appendTo('#chart'+'Ban-chuyen-mon');
      
      
     
      // await this.setDiagramsOptions('HierarchicalTree','LeftToRight', '', AppConsts.nodeType.circle);
      // this.diagram.appendTo('#'+this.nameID3);
      // await this.setDiagramsOptions('HierarchicalTree','LeftToRight', '', AppConsts.nodeType.circle);
      // this.diagram.appendTo('#'+this.nameID4);
      // await this.setDiagramsOptions('OrganizationalChart','Horizontal','Center', AppConsts.nodeType.rectangle);
      // this.diagram.appendTo('#'+this.nameID5);
      
      // await this.setDiagramsOptions('OrganizationalChart','Horizontal','Center', AppConsts.nodeType.rectangle);
      // this.diagram.appendTo('#'+this.nameID7);
      // await this.setDiagramsOptions('OrganizationalChart','Horizontal','Center', AppConsts.nodeType.rectangle);
      // this.diagram.appendTo('#'+this.nameID8);
      // await this.setDiagramsOptions('OrganizationalChart','Horizontal','Center', AppConsts.nodeType.rectangleWithHTML);
      // this.diagram.appendTo('#'+this.nameID9);
  
  }, 100);
  }


  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.pageData();
  }

  private pageData(): void {
    this.pagedTopnews = this.topNews.slice(
      this.skip,
      this.skip + this.pageSize
    );
    // if (this.pagedTopnews.length == 0) {
    //   this.pagedTopnews = [];
    //   for (let index = 0; index < this.topNumber; index++) {
    //     this.pagedTopnews.push({
    //       DefaultFlg: true,
    //       ImageUrl: AppConsts.LINK_DEFAULT_IMAGE,
    //       Title: 'Title',
    //       UrlPath: '',
    //       CreateAt: '2021-10-26T08:41:50.453',
    //     });
    //   }
    // }
  }
  async getTopNews() {
    const result = await this.appService.doGET(
      'api/PortalPost/GetByMenuID',
      {
        langID: this.language.get(),
        menuID: this.infoFromMain.MenuID,
        topNum: this.infoFromMain.TopNumber,
      }
    );
    if (result && result.Status === 1) {
      this.postTittle = result.Data.dataMenu.Name;
      this.viewMore = result.Data.dataMenu.ID;
      this.topNews = result.Data.topNews;
      this.pageData();
      this.total = result.Data.topNews.length
    }
  }

  setH(){
    var a = document.querySelector('.post-list') as HTMLElement;
    if(a.getBoundingClientRect().width <= 808){
      return 'img-figure-2'
    }
    return ;
  }
  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;
  }
  redirectToViewMore() {
    // this.router.navigate([AppConsts.page.postList], { queryParams: { url: this.viewMore } });
    this.router.navigate([AppConsts.page.postList + '/' + this.viewMore]);
  }
  onGetTittleItem(e) {
    this.tittleItem.emit(e)

  }

  redirectToItem(url: string, item) {


    this.onGetTittleItem(item)
    if (url != null && url != undefined) {
      // this.router.navigate([AppConsts.page.galleryDialog], { queryParams: {} });

      // this.router.navigate([AppConsts.page.postDetail, url], { state: { postTittle: this.postTittle } });

      this.urlItem.emit(item);
    }
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
  }

  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });
  }

  onClose(e) {
    //this.onReload();
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true);
    }
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  changeTemp1(newTemp: any) {
    if (typeof this.infoFromMain === "string") {
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.TopNumber = newTemp.Info.TopNumber;
    this.topNumber = this.infoFromMain?.TopNumber;
    this.infoFromMain.MenuID = newTemp.Info.MenuID;
    this.getTopNews();
    this.getPostTittle();
    newTemp.pageBodyIndex = this.pageBodyIndex;
    newTemp.layoutGroupIndex = this.layoutGroupIndex;
    newTemp.index = this.newsDecorationIndex;
    this.changeTemplate1.emit(newTemp);
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(
      (x) => x.ID === this.infoFromMain.MenuID
    );
  }
  onImgError(event){
    event.target.src = AppConsts.DEFAULT_IMAGE;
  }
  async setDiagramsOptions(layoutType, chartOrientation, chartType, nodeType = this.defaultNodeType, nodeID = ''){
    this.diagram = new Diagram({
      width: '100%',
      height: 
        nodeType == AppConsts.nodeType.rectangle 
        ? '320px'
        : (nodeType == AppConsts.nodeType.circle && chartOrientation != 'LeftToRight')?'350px': '580px',
      //constraints: DiagramConstraints.None, 
      constraints: DiagramConstraints.None | DiagramConstraints.Zoom,
      snapSettings: {
          constraints: 0
      },//Uses layout to auto-arrange nodes on the diagram page
      layout: this.getLayoutType(layoutType, chartType, chartOrientation)
      ,//Initializes the node template.
      dataSourceSettings: {
        id: 'ID',
        parentId: 'ParentID',
        dataManager: this.items
      },
      //Sets the default properties for nodes and connectors     
      getNodeDefaults: (obj: Node, diagram: Diagram) => {
        obj.width = this.getNodeWidth(nodeType, chartOrientation, obj.data['ParentID'], nodeID);
        obj.height = this.getNodeHeight(nodeType, chartOrientation, obj.data['hideRoot'], nodeID);
        obj.borderColor = 'None';
        obj.style=  {
          fill: (nodeType == AppConsts.nodeType.rectangle || nodeType == AppConsts.nodeType.rectangleWithHTML) && obj.data['hideRoot'] != true ? '#0099DC':'white',
          strokeColor: obj.data['hideRoot'] == true ? '#ffffff': '#0099DC'
        },
        obj.borderWidth = 1;
        obj.shape = this.getNodeShapeInfo(nodeType, chartOrientation, obj.data['ImageUrl'], obj.data['hideRoot'], obj.data['ParentID'], obj.data['Name'], obj.data['PositionName'], nodeID);
        obj.constraints = nodeType == AppConsts.nodeType.rectangleWithShadow ? (NodeConstraints.Default | NodeConstraints.Shadow): NodeConstraints.Default ,
        obj.shadow = nodeType == AppConsts.nodeType.rectangleWithShadow ? {
          angle: 50,
          opacity: 0.8,
          distance: 9,
          color: '#0099DC',
        } : {}
        obj.annotations = this.getAnnotations(nodeType, obj.data['hideRoot'], obj.data['Name'], nodeID);
        return obj;
      },
      getConnectorDefaults: (connector: ConnectorModel, diagram: Diagram) => {
        connector.style = 
          nodeType == AppConsts.nodeType.circle && chartOrientation == 'LeftToRight'
            ? {
              strokeColor: '#000000',
              strokeWidth: 1,
            } 
            : {
              strokeColor: '#000000',
              strokeWidth: 1,
              strokeDashArray: '2,2'
            };
        connector.targetDecorator.style.fill  =  '#6BA5D7';
        connector.targetDecorator.style.strokeColor  =  '#6BA5D7';
        connector.targetDecorator.shape = 'None';
        connector.type = 'Orthogonal';
        return connector;
      }
    });
    
    // let node: NodeModel ={
    //   id: "start",
    //   height: 50
    // }
    // this.diagram.nodes = [node]
    
  }
  getLayoutType(layoutType, chartType, chartOrientation){
    let result = {};
    if(layoutType == 'OrganizationalChart'){
      result = {
        //Sets layout type
        type: layoutType,
        // define the getLayoutInfo
        getLayoutInfo: (node: Node, options: TreeInfo) => {     
          if (node.data['AssistantID'] && node.data['AssistantID'] !== null) {
            options.assistants.push(options.children[0]);
            options.assistants.push(options.children[0]);
            options.children.splice(0, 1);           
          }
          if (!options.hasSubTree) {
            options.type = chartType;
            options.orientation = chartOrientation;
          }
        }
      }
    }
    if(layoutType == 'HierarchicalTree'){
      result = {
        type: layoutType,
        orientation: chartOrientation
      }
    }
    return result;
  }
  getNodeShapeInfo(nodeType, chartOrientation, imageSource, hideRoot, isChild, nameInNode?, roleInNode?, nodeID  = ''){
    let result = {};
    if(nodeType == AppConsts.nodeType.circle){
      if(hideRoot == true){
        result = {}
      }
      else{
        if(chartOrientation == 'LeftToRight' && !isChild){
          result = {
            type: 'HTML',
            content: `<div style="`
            + (chartOrientation == 'LeftToRight' && isChild ? `width: auto;display: flex;`: `width: 275px;display: block;`)
            +`">
            <div style="background: linear-gradient(#fff 0 0) padding-box, linear-gradient(to top, #006CB5, #00AEEF) border-box;
            color: #313149;
            border: 5px solid transparent;
            border-radius: 50%;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              width: 150px;
              height: 150px;
              display: flex;
              justify-content: center;
              align-items: center;`
              + (chartOrientation == 'LeftToRight' && isChild ? ``: `margin: auto;`) +`
              ">
              <img src="`+ this.appService.apiRoot + imageSource+`" alt="" 
                style="height: 124px; width: 124px; border-radius: 50%; object-fit:contain;" (error)="onImgError($event)">
            </div>
            <div>
              <div style="display: flex; justify-content: center; 
                margin-top: 20px; margin-bottom: 20px;
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                text-transform: uppercase;">
                  ` + nameInNode + `
              </div>
              <div style="
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
                background: #0099DC;
                border-radius: 20px;
                height: 40px;
                width: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                text-align: center;
                padding: 0px 10px;">
                  ` + roleInNode + `
              </div>
            </div>
          </div>`,
            scale: 'None'
          }
        }
        else {
          result = {
            type: 'HTML',
            content: `<div style="`
            + (chartOrientation == 'LeftToRight' && isChild ? `width: auto;display: flex;`: `width: 212px;display: block;`)
            +`">
            <div style="background: linear-gradient(#fff 0 0) padding-box, linear-gradient(to top, #006CB5, #00AEEF) border-box;
            color: #313149;
            border: 5px solid transparent;
            border-radius: 50%;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              width: 106px;
              height: 106px;
              display: flex;
              justify-content: center;
              align-items: center;`
              + (chartOrientation == 'LeftToRight' && isChild ? ``: `margin: auto;`) +`
              ">
              <img src="`+ this.appService.apiRoot + imageSource+`" alt="" 
                style="height: 86px; width: 86px; border-radius: 50%; object-fit:contain;" (error)="onImgError($event)">
            </div>
            <div>
              <div style="display: flex; justify-content: center; 
                margin-top: 20px; margin-bottom: 20px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                text-transform: uppercase;">
                  ` + nameInNode + `
              </div>
              <div style="
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #FFFFFF;
                background: #0099DC;
                border-radius: 20px;
                height: 40px;
                width: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                text-align: center;
                padding: 0px 10px;">
                  ` + roleInNode + `
              </div>
            </div>
          </div>`,
            scale: 'None'
          }
        }
      }      
    } 
    else if(nodeType == AppConsts.nodeType.rectangle){
      if (hideRoot == true){
        result = {}
      } 
      else if(isChild){
        result = {
          type: 'HTML',
          content: `
          <div style="display: flex;
            background: #0099DC;
            width: ` + (nodeID == 'BTK'? '218px': '326px') +`;
            height: ` + (nodeID == 'BTK'? '174px': '94px') +`">
              
              <div style="display: flex;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #FFFFFF;
                width: 100%;">`+nameInNode +`
              </div>
          </div>
          `
        }
      }
      else {
        result = {
          type: 'Basic',
          shape: 'Rectangle'
        }
      }     
    }
    else if(nodeType == AppConsts.nodeType.rectangleWithHTML){
      if(isChild){
        result = {
          type: 'HTML',
          content: `
          <div style="display: flex;
            background: #FFFFFF;
            border: 1px solid #0099DC;
            width: 326px;">
              <div>
                <img src="`+ this.appService.apiRoot + imageSource + `" alt=""
                style="width: 93px;
                height: 93px;
                margin: 5px;
                object-fit:contain;">
              </div>
              <div style="display: flex;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #454545;"
                >`+nameInNode +`
              </div>
          </div>
          `
        }
      }
      else {
        result = {
          type: 'Basic',
          shape: 'Rectangle'
        }
      }      
    }
    else{
      result = {
        type: 'Basic',
        shape: 'Rectangle',
        cornerRadius: 10,
      }
    }
    return result
  }
  getNodeWidth(nodeType, chartOrientation, isChild, nodeID){
    switch(nodeType){
      case AppConsts.nodeType.rectangleWithShadow:
        return 367;
      case AppConsts.nodeType.circle:
        return chartOrientation == 'LeftToRight' ? (isChild ? 400: 275) : 208;
      case AppConsts.nodeType.rectangle:
        if(nodeID == 'BTK'){
          return 228;
        }
        return 326;
      case AppConsts.nodeType.rectangleWithHTML:
        return 326;
      default:
        return 387;
    }
  }
  getNodeHeight(nodeType, chartOrientation, hideRoot = false, nodeID){
    switch(nodeType){
      case AppConsts.nodeType.rectangleWithShadow:
        return 82;
      case AppConsts.nodeType.circle:
        return chartOrientation == 'LeftToRight' ? 112: (hideRoot == true ? 0: 212);
      case AppConsts.nodeType.rectangle:
        return hideRoot == true ? 0: (nodeID == 'BTK' ? 174: 98);
      case AppConsts.nodeType.rectangleWithHTML:
        return 98;
      default:
        return 82;
    }
  }
  getAnnotations(nodeType, hideRoot, name, nodeID){
    let result = []
    switch(nodeType){
      case AppConsts.nodeType.rectangleWithShadow:
        return result = 
          [{
            content: hideRoot == true ? '': name,
            style: {
              color: '#015293',
              fontFamily:  'Myriad Pro, sans-serif',
              fontSize: 21,
              bold: true
            }
          }]
        ;
      case AppConsts.nodeType.circle:
        return result = 
        [{
          
        }];
      case AppConsts.nodeType.rectangle:
        if(nodeID == 'BTK'){
          return result = 
          [{
            content: hideRoot == true ? '': name,
            style: {
              color: '#ffffff',
              fontFamily:  'Myriad Pro, sans-serif',
              fontSize: 18,
              bold: true
            },
            margin: {
              left: 30,
              right: 30
            },
            textAlign: 'Justify'
          }];
        }
        return result = 
        [{
          content: hideRoot == true ? '': name,
          style: {
            color: '#ffffff',
            fontFamily:  'Myriad Pro, sans-serif',
            fontSize: 18,
            bold: true
          }
        }];
      case AppConsts.nodeType.rectangleWithHTML:
        return result = 
        [{
          content: hideRoot == true ? '': name,
          style: {
            color: '#ffffff',
            fontFamily:  'Myriad Pro, sans-serif',
            fontSize: 16,
            bold: true
          }
        }];
      default:
        return result = 
        [{
          content: hideRoot == true ? '': name,
          style: {
            color: '#015293',
            fontFamily:  'Myriad Pro, sans-serif',
            fontSize: 21,
            bold: true
          }
        }];
    }
  }
  async getDataUnitRoot(unitID) {
    const result = await this.appService.doGET(
      'api/Unit/GetUnits',
      {
        unitID: unitID,
      }
    );
    if (result && result.Status === 1) {
      this.dataUnit = result.Data.Units;
    }
  }
  changeDataOfDataManager(data){
    this.items = new DataManager(data as JSON[]);
    //console.log("this.items", this.items);
    
  }
  async getUserOfUnit(unitID, layoutType) {
    const result = await this.appService.doGET(
      'api/Unit/GetDataOfUnit',
      {
        unitID: unitID,
        layoutType: layoutType
      }
    );
    if (result && result.Status === 1) {
      if(layoutType == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_TRON
        || layoutType == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TRAI_SANG_PHAI_NUT_TRON
       ){
        this.dataChart = result.Data.Users;
      } else {
        if(layoutType == AppConsts.chartTypeInfo.SO_DO_KHOI_DEU){
          this.dataChartType4 = result.Data.Units;
          if(unitID == '01'){
            this.pbnvNum = result.Data.Units ? result.Data.Units.length : 1;
          }
        }
        this.dataChart = result.Data.Units;
        if(unitID == 'BTK'){
          this.dataChartBTK = result.Data.Units;
          
          this.dvttNum = result.Data.Units ? result.Data.Units.length - 1 : 1;
        }
      }
      if(result.Data.ChartType != null && result.Data.ChartType == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_TRON){
        this.dataChart.push({
          ID: result.Data.Users ? result.Data.Users[0].ParentID: '',
          hideRoot: true
        })
      }
      //console.log("unitID , this.dataChart", unitID, this.dataChart);
      
    }
  }
  setChartInfoByType(type){

    if(type == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_VUONG_CO_BONG){
      this.layoutType = 'OrganizationalChart';
      this.chartOrientation = 'Horizontal';
      this.chartType = 'Center';
      this.nodeType = AppConsts.nodeType.rectangleWithShadow;
    }
    else if(type == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_VUONG_KHONG_CO_BONG){
      this.layoutType = 'OrganizationalChart';
      this.chartOrientation = 'Horizontal';
      this.chartType = 'Center';
      this.nodeType = AppConsts.nodeType.rectangle;
    }
    else if(type == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_VUONG_KHONG_CO_BONG_CO_ANH){
      this.layoutType = 'HierarchicalTree';
      this.chartOrientation = 'LeftToRight';
      this.chartType = '';
      this.nodeType = AppConsts.nodeType.rectangleWithHTML;
    }
    else if(type == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TREN_XUONG_DUOI_NUT_TRON){
      this.layoutType = 'OrganizationalChart';
      this.chartOrientation = 'Horizontal';
      this.chartType = 'Center';
      this.nodeType = AppConsts.nodeType.circle;
    }
    else if(type == AppConsts.chartTypeInfo.SO_DO_THANG_TU_TRAI_SANG_PHAI_NUT_TRON){
      this.layoutType = 'HierarchicalTree';
      this.chartOrientation = 'LeftToRight';
      this.chartType = '';
      this.nodeType = AppConsts.nodeType.circle;
    }
    else {
      this.layoutType = 'OrganizationalChart';
      this.chartOrientation = 'Horizontal';
      this.chartType = 'Center';
      this.nodeType = AppConsts.nodeType.rectangleWithShadow;
    }
  }
  
  async getUnitChildInfo() {
    const result = await this.appService.doGET(
      'api/PortalBody/GetUnitMembers',
      {
        langID: this.language.get(),
      }
    );
    if (result && result.Status === 1) {
      this.unitChildMembers = result.Data;
    }
  }
  getFullImageUrl(url: string) {
    return this.appService.apiRoot + url;
  }
  async getUnitTypeFilter() {
    const result = await this.appService.doGET('api/Enum/GetEUnitType', null);
    if (result && result.Status === 1) {
      this.unitType = result.Data;
      //this.unitTypeFilter = this.unitType.slice();
    }
  }
  async getRootUnitID(){
    const result = await this.appService.doGET('api/Unit/GetRootUnitID', {});
    if(result && result.Status === 1){
      this.rootUnitID = result.Data;
    }
  }
  async getUnitByEUnitType(){
    const result = await this.appService.doGET('api/Unit/GetUnitByEUnitType', null);
    if(result && result.Status === 1){
      this.unitByEUnitType = result.Data;
      this.unitByEUnitType.forEach(element => {
        element.Title = element.Title.replace(/<br>/g,'')
      });
    }
  }
  getUnitOrgName(unitType){
    this.unitType.forEach(r => {
      if(r.ID == unitType){
        return r.Name
      }
      else return ''; 
    })
  }
  clickToGetName(unitType, args: PointerEvent): void {
    //except click on circle chart
    this.clickedName = "";
    if(unitType != 2 && unitType != 3){
      // Obtains the mouse button clicked
      //var element = args.target as Element;
      if (args.target instanceof Element){
        //click on node
        let elementID = (args.target as Element).id;
        //check _content for permit only chart with shadow
        if(elementID){
          if(elementID.indexOf("_content") !== -1){
            let element = document.getElementById(elementID);
            if(element){
              this.clickedName = element.getAttribute('aria-label');
            }
          }       
        }
        else {
          let innerItem = (args.target as Element);
          //click on text 
          let textID = (args.target.parentElement as Element).id;         
          let textSelected = document.getElementById(textID);         
          if(textSelected){           
            let fullText = '';
            textSelected.childNodes.forEach(tspan => {
              fullText += tspan.textContent + ' ';
            });
            this.clickedName = fullText.trim();
          } else {
            //check click on node or not
            let innerText = innerItem.innerHTML;
            innerText = innerText.replace(/<br>/g,'')
            this.clickedName = innerText;
          }
        } 
      }
      if(this.clickedName != ""){
        this.onOpenDescriptionDialog();
      }
     
    }
  }
  async onOpenDescriptionDialog(){
    this.descriptionDialog = true;
    await this.getOrgDescription();
  }
  onCloseDescriptionDialog(){
    this.descriptionDialog = false;
    this.orgName = '';
    this.orgDescription = '';
  }
  async getOrgDescription() {
    const result = await this.appService.doGET('api/Unit/GetDescription',
    {
      unitName: this.clickedName
    });
    if (result && result.Status === 1) {
      this.orgName = result.Data.Name;
      if(this.orgName != ''){
        this.orgName = this.orgName.replace(/<br>/g,'')
      }
      this.orgDescription = result.Data.Description;
      this.orgFileUrls = result.Data.FileUrls? JSON.parse(result.Data.FileUrls): [];
    }
  }
  openDescriptionByID(ID){
    this.descriptionDialog = true;
    this.getOrgDescriptionByID(ID);
  }
  async getOrgDescriptionByID(ID) {
    const result = await this.appService.doGET('api/Unit/GetDescriptionByID',
    {
      iD: ID
    });
    if (result && result.Status === 1) {
      this.orgName = result.Data.Name;
      if(this.orgName != ''){
        this.orgName = this.orgName.replace(/<br>/g,'')
      }
      this.orgDescription = result.Data.Description;
      this.orgFileUrls = result.Data.FileUrls? JSON.parse(result.Data.FileUrls): [];
    }
  }
  openDesByIDOfCommon(ID){
    this.descriptionDialog = true;
    this.getOrgDesByIDOfCommon(ID);
  }
  async getOrgDesByIDOfCommon(ID) {
    const result = await this.appService.doGET('api/Unit/GetDescriptionByIDOfCommon',
    {
      iD: ID
    });
    if (result && result.Status === 1) {
      this.orgName = result.Data.Name;
      if(this.orgName != ''){
        this.orgName = this.orgName.replace(/<br>/g,'')
      }
      this.orgDescription = result.Data.Description;
      this.orgFileUrls = result.Data.FileUrls? JSON.parse(result.Data.FileUrls): [];
    }
  }
  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, "");
    return url;
  }
  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }
}
