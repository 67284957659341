<div class="col-md-12 col-12 register-document">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="title-info-req">
                <span>{{dataGridItem.Name}}</span>
            </div>
        </div>
        <br>
        <div class="col-md-12 col-12">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_FullName' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <input [ngClass]="dataErr[0][ 'Document.FullName'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.FullName" [disabled]="registerState == 2"/>
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.FullName'] ">{{dataErr[0]['Document.FullName']
                            }}</span>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_DateOfBirth' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <kendo-datepicker [placeholder]="''" #dateModel="ngModel" [(ngModel)]="dataGridItem.DateOfBirth" [format]="'FormatDate' | translate" [ngClass]="dataErr[0][ 'Document.DateOfBirth'] ? 'err' : ''" [formatPlaceholder]="{
                            year: 'year' | translate, month: 'month'| translate , day: 'day' | translate,
                            hour: 'hour' | translate, minute: 'minute' | translate, second: 'second'
                            | translate }" [disabled]="registerState == 2">
                            <kendo-datepicker-messages [today]="'today' | translate" [toggle]="'toggle' | translate">
                            </kendo-datepicker-messages>
                        </kendo-datepicker>
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.DateOfBirth'] ">{{dataErr[0]['Document.DateOfBirth']}}</span>
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_PlaceOfBirth' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <input [ngClass]="dataErr[0][ 'Document.PlaceOfBirth'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.PlaceOfBirth" [disabled]="registerState == 2"/>
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.PlaceOfBirth'] ">{{dataErr[0]['Document.PlaceOfBirth']}}</span>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_Gender' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <kendo-combobox [disabled]="registerState == 2" [ngClass]="dataErr[0]['Document.Gender'] ? 'err' : ''" [data]="genderFilter" [textField]="'Name'" [valueField]="'ID'" [(ngModel)]="dataGridItem.Gender" [valuePrimitive]="true" [filterable]="true" (filterChange)="genderHandleFilter($event)">
                            <ng-template kendoDropDownListNoDataTemplate>{{'NoRecords' | translate}}</ng-template>
                        </kendo-combobox>
                        <!-- <input [ngClass]="dataErr[0][ 'Document.Gender'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Gender" /> -->
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.Gender'] ">{{dataErr[0]['Document.Gender']}}</span>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_IDCardNumber' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <input [ngClass]="dataErr[0][ 'Document.IDCardNumber'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.IDCardNumber" [disabled]="registerState == 2"/>
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.IDCardNumber'] ">{{dataErr[0]['Document.IDCardNumber']}}</span>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_Nationality' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <kendo-combobox [disabled]="registerState == 2" [ngClass]="dataErr[0]['Document.NationalID'] ? 'err' : ''" [data]="nationalityFilter" [textField]="'Name'" [valueField]="'ID'" [(ngModel)]="dataGridItem.NationalID" [valuePrimitive]="true" [filterable]="true" (filterChange)="nationalityHandleFilter($event)">
                            <ng-template kendoDropDownListNoDataTemplate>{{'NoRecords' | translate}}</ng-template>
                        </kendo-combobox>
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.NationalID'] ">{{dataErr[0]['Document.NationalID']}}</span>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_Job' | translate"></span>
                            <span> :</span>
                        </label>
                        <input [disabled]="registerState == 2" [ngClass]="dataErr[0][ 'Document.Job'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Job" />
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.Job'] ">{{dataErr[0]['Document.Job']}}</span>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'Cv_PRO5_Company' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <input [disabled]="registerState == 2" [ngClass]="dataErr[0][ 'Document.Address'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Address" />
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.Address'] ">{{dataErr[0]['Document.Address']
                            }}</span>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'Cv_PRO5_Email' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <input [disabled]="registerState == 2" [ngClass]="dataErr[0][ 'Document.Email'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Email" />
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.Email'] ">{{dataErr[0]['Document.Email']}}</span>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_Phone' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                        </label>
                        <input [disabled]="registerState == 2" [ngClass]="dataErr[0][ 'Document.Phone'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Phone" />
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.Phone'] ">{{dataErr[0]['Document.Phone']}}</span>
                    </div>
                </div>


                <div class="col-md-12 col-12 is-display-flex">
                    <div [ngClass]="'control'" class="is-display-grid">
                        <label>
                            <span [textContent]="'UnprocessedDocument_TypesOfPaper' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                            <span class="red-text">{{'File_Upload_Rule' | translate}}</span>
                        </label>
                        <div class="scoll-x">
                            <table>
                                <colgroup>
                                    <col span="1" class="column-tb-1">
                                    <col span="1" class="column-tb-2">
                                    <col span="1" class="column-tb-3">
                                    <col span="1" class="column-tb-4">
                                    <col span="1" class="column-tb-5">
                                 </colgroup>
                                 <tbody>
                                    <tr>
                                        <th class="text-center">{{'OrderIndx' | translate}}</th>
                                        <th>{{'Cv_PRO5_Name' | translate}}</th>
                                        <th>{{'UnprocessedDocument_Attachments' | translate}}</th>
                                        <th>{{'Upload_File' | translate}}</th>
                                        
                                        <th>{{'UnprocessedDocument_Status' | translate}}</th>
                                      </tr>
                                      <tr *ngFor="let item of dataCvPro5s ; let i = index">
                                        <td class="text-center">{{i + 1}}</td>
                                        <td>{{item.Name}}</td>
                                        <td class="break-word">
                                            <a [href]="getUrlDownload(item.UploadUrl)" [target]="_blank">{{item.FileName}}</a></td>
                                        <td><label>
                                            <!-- <input type="file"style="display: none;" > -->
                                            <kendo-upload [disabled]="registerState == 2" hidden="true" [accept]="['.png','.jpg','.jpeg', '.pdf']"
                                                [(ngModel)]="filesUpload" (select)="onSelectFileToUpload($event)"
                                                (remove)="onRemoveFileDocToUpload()" [multiple]="true"
                                                [showFileList]="false" [saveUrl]="fileSaveUrl"
                                                (success)="onSuccessFileToUpload($event)">
                                                <kendo-upload-messages [headerStatusUploaded]="'FileUploaded' | translate"
                                                    [dropFilesHere]="'dropFilesHere' | translate"
                                                    [select]="'UploadFile' | translate">
                                                </kendo-upload-messages>
                                            </kendo-upload>
                                            <a [ngClass]="registerState == 2 ? 'disable-link' : 'is-link'" (click)="getRowIndex(i)"> {{'Upload_File' | translate}}</a>
                                        </label>
                                    </td>
                                        <td>
                                            <ng-container *ngIf="item.Status == 1; else elseBlock">
                                                <div class="dialog-edit icon-format">
                                                    <img class="dialog-edit icon-format" src="../../../assets/images/check2.png" alt="ImageChecked">
        
                                                </div>
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                <div class="dialog-edit icon-format">
                                                    <img class="dialog-edit icon-format" src="../../../assets/images/remove.png" alt="ImageUnChecked">
                                                </div>
                                            </ng-template>
                                    </td>
                                      </tr>
                                 </tbody>
                                
                              </table>
                        </div>
                        
                        <!-- <input [ngClass]="dataErr[0][ 'Document.ReturnDate'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.ReturnDate" /> -->
                        <!-- <span class="err-text " *ngIf="dataErr[0][ 'Document.ReturnDate'] ">{{dataErr[0]['Document.ReturnDate']}}</span> -->
                    </div>
                </div>

                <div class="col-md-12 col-12" *ngIf="registerState == 1 || registerState == 2">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_ReturnDate' | translate"></span>
                            <span> :</span>
                        </label>
                        <kendo-datetimepicker [placeholder]="''" #dateModel="ngModel" [(ngModel)]="dataGridItem.ReturnDate" [format]="'dateTimeFormat' | translate" [ngClass]="dataErr[0][ 'Document.ReturnDate'] ? 'err' : ''" [disabled]="true" [formatPlaceholder]="{
                            year: 'year' | translate, month: 'month'| translate , day: 'day' | translate,
                            hour: 'hour' | translate, minute: 'minute' | translate, second: 'second'
                            | translate }">
                            <kendo-datetimepicker-messages [today]="'today' | translate" [toggle]="'toggle' | translate" [dateTab]="'date' | translate" [dateTabLabel]="'dateView' | translate" [timeTab]="'time' | translate" [timeTabLabel]="'timeView' | translate" [now]="'now' | translate"
                                [nowLabel]="'now' | translate" [accept]="'accept' | translate" [acceptLabel]="'selectTime' | translate" [cancel]="'cancel' | translate" [cancelLabel]="'cancelTime' | translate">
                            </kendo-datetimepicker-messages>
                        </kendo-datetimepicker>
                        <!-- <input [ngClass]="dataErr[0][ 'Document.ReturnDate'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.ReturnDate" /> -->
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.ReturnDate'] ">{{dataErr[0]['Document.ReturnDate']}}</span>
                    </div>
                </div>
                <div class="col-md-12 col-12" *ngIf="registerState == 1 || registerState == 2">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'UnprocessedDocument_Fee' | translate"></span>
                            <span> :</span>
                            <span class="red-text"> {{'Change_By_Type' | translate}} </span>
                        </label>
                        <kendo-numerictextbox [disabled]="true" format="n0" [autoCorrect]="true" [min]="0" [ngClass]="dataErr[0]['Document.Fee'] ? 'err' : ''" [ngModel]="dataGridItem.Fee" (ngModelChange)="dataGridItem.Fee = $event">
                        </kendo-numerictextbox>
                        <!-- <input [ngClass]="dataErr[0][ 'Document.Fee'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.Fee" /> -->
                        <span class="err-text " *ngIf="dataErr[0][ 'Document.Fee'] ">{{dataErr[0]['Document.Fee']}}</span>
                    </div>
                </div>
                <div class="col-md-12 col-12" *ngIf="registerState == 2">
                    <div [ngClass]="'control'">
                        <label>
                            <span [textContent]="'Code_OTP' | translate"></span>
                            <span class="required">*</span>
                            <span> :</span>
                            <span class="red-text"> {{'Input_OTP' | translate}} </span>
                        </label>
                        <input [ngClass]="dataErr[0][ 'OTP'] ? 'err' : ''" kendoTextBox [(ngModel)]="dataGridItem.OTP" />
                        <span class="err-text " *ngIf="dataErr[0][ 'OTP'] ">{{dataErr[0]['OTP']}}</span>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="btn-dialog-wrapper">

                        <button class="btn-dialog btn-regist-cv" (click)="onSaveGrid(registerState)" *ngIf=" registerState != 2 && loadingScan != true">
                            {{ 'Continue' | translate }}
                        </button>

                        <button class="btn-dialog btn-scanning-img" *ngIf="loadingScan">
                            {{ 'Scanning_Image' | translate }}
                        </button>
                        <button class="btn-dialog btn-regist-cv" (click)="onSaveGrid(registerState)" *ngIf=" registerState == 2  && loadingScan != true">
                            {{ 'CV_Complete' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>