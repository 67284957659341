<article class="main-page" (window:resize)="onResize($event)">
  <div class="row main-row">
<div>    <!-- coding header -->
  <div *ngFor="let item of pageHeader" class="header-box">
    <app-header-template1 *ngIf="item == '1'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
    </app-header-template1>
    <app-header-template2 *ngIf="item == '2'" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
      (menuSearch)="menuSearch($event)" [menuIDSelected]="menuIDSelected" [isAdminFlg]="false">
    </app-header-template2>
    <app-header-template3 *ngIf="item == '3'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
    </app-header-template3>
    <app-header-template4 *ngIf="item == '4'" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
      (menuSearch)="menuSearch($event)" [templateID]="TemplateID" [pageSelectedID]="PageSelectedID">
    </app-header-template4>
    <app-header-template5 *ngIf="item == '5'" (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
      (menuSearch)="menuSearch($event)" [templateID]="TemplateID" [pageSelectedID]="PageSelectedID"
      [bodyWidth]="bodyWidth" [menuIDSelected]="menuIDSelected">
    </app-header-template5>
  </div>
  <!-- coding header -->
  <div *ngFor="let group of pageBody; let bodyIndex = index">
    <div *ngFor="let item of group.items; let i = index">
      <app-image-slider-home *ngIf="item?.FrameID == 0" [isAdminFlg]="isAdminFlg" [banner]="item">
      </app-image-slider-home>
    </div>
  </div>
  <div class="col-md-12 col-12">
    <div class="container-fluid">
      <!-- </div> -->
      <!-- <div *ngFor="let template of templates; let i = index" [id]="'main-body-'+i">
        <div class="background-gray">
          <app-news-template-1 *ngIf="template.TemplateID == 1"  [isAdminFlg]="isAdminFlg" [infoFromMain]="template.Info"
            [categoriesList]="categoriesList" [templateID]="template.ID" [OrderIdx]="i"
            (isReloadPageAfterSave)="onReload($event)" [width]="template.Width">
          </app-news-template-1>
          <app-news-template-2 *ngIf="template.TemplateID == 2"  [isAdminFlg]="isAdminFlg" [infoFromMain]="template.Info"
            [categoriesList]="categoriesList" [templateID]="template.ID" [OrderIdx]="i"
            (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i" [width]="template.Width">
          </app-news-template-2>
        </div>
      </div> -->

      <div class="body-list">
        <div class="router-box">
          <app-router-link [menuTittle]="menuTittle" [menuVl]="menuVl" [itemchild]="itemchild">
            [ItemTittle]="">
          </app-router-link>
        </div>
        <div [ngClass]="{  'layout-box': group.layout == 100 ,
            'layout-box-left': ((group.layout >= 49 && group.layout < 100 && group.layout != 59) || group.layout == 39),
            'layout-box-right': ((group.layout < 49 && group.layout != 25 && group.layout != 39) || group.layout == 59),
            'layout-box-center':  group.layout == 25
          }" *ngFor="let group of pageBody; let bodyIndex = index"
          [style.width]="innerWidth>768 ? group.layout + '%' : 100+'%'">
          <div class="layout-list" id="{{ group.id }}">
            <!-- <app-router-link [menuTittle]="menuTittle" [menuVl]="menuVl" [itemchild]="itemchild">
              [ItemTittle]="">
            </app-router-link> -->
            <div
              [ngClass]="isSpecialBackground == true ? (checkNumberIsRound(i) == true ? 'body-box' : 'body-box-gray') : 'body-box'"
              [style.width.%]="100" *ngFor="let item of group.items; let i = index">
              <div class="body-item">
                <app-news-template-1 *ngIf="item?.FrameID == 1" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-1>

                <app-news-template-2 *ngIf="item?.FrameID == 2" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-2>

                <app-news-template-3 *ngIf="item?.FrameID == 3" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-3>

                <app-news-template-4 *ngIf="item?.FrameID == 4" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-4>

                <app-news-template-5 *ngIf="item?.FrameID == 5" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-5>

                <app-news-template-6 *ngIf="item?.FrameID == 6" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-6>

                <app-news-template-a *ngIf="item?.FrameID == 7" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-a>

                <app-news-template-b *ngIf="item?.FrameID == 8" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-b>

                <app-news-template-c *ngIf="item?.FrameID == 9" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-c>

                <app-news-template-10 *ngIf="item?.FrameID == 10" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-10>
                <app-news-template-11 *ngIf="item?.FrameID == 11" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-11>
                <app-news-template-12 *ngIf="item?.FrameID == -2" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-12>

                <!-- văn bản -->
                <app-library-text *ngIf="item?.FrameID == 13" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-library-text>

                <!-- chi tiết tin bài -->
                <app-post-detail *ngIf="item?.FrameID == 15" [postUrl]="postUrl" [isAdminFlg]="false"
                  [infoFromMain]="item" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  [isIntroPage]="true">
                </app-post-detail>

                <!-- cây sơ đồ cơ cấu tổ chức -->
                <app-organization-structure *ngIf="item?.FrameID == 16" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-organization-structure>

                <!--  -->
                <app-image-slider-a *ngIf="item?.FrameID == -1" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [banner]="item"
                  (saveData)="this.onReload()">
                </app-image-slider-a>

                <!-- Liên hệ -->
                <app-contact *ngIf="item?.FrameID == 14" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [banner]="item"
                  (saveData)="this.onReload()">
                </app-contact>


                <!-- template đơn vị thành viên-->
                <app-member-unit *ngIf="item?.FrameID == 17" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-member-unit>

                <!-- template giải thưởng và thành tựu-->
                <app-reward-and-achivement *ngIf="item?.FrameID == 18" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-reward-and-achivement>

                <!-- template giới thiệu-->
                <app-intro-page *ngIf="item?.FrameID == 19" [ID]="item?.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                  [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)"
                  [newsDecorationIndex]="i" [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item"
                  [layoutGroupIndex]="groupIndex" (saveData)="this.onReload()"
                  (changeTemplate2)="this.changeTemp12($event)" [menuID]="menuID"
                  (deleteBodyItem)="onDltBodyItem($event)">
                </app-intro-page>

                <!-- template tin tức -->
                <app-news-template-13 *ngIf="item?.FrameID == 20" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-13>
                <!-- template dịch vụ khách hàng -->
                <app-news-template-14 *ngIf="item?.FrameID == 21" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-14>
                <!-- template sơ đồ tổ chức -->
                <app-org-tree *ngIf="item?.FrameID == 23" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-org-tree>
                <!-- template tin mới nhiều chuyên mục -->
                <app-news-template-19 *ngIf="item?.FrameID == NEWS_19" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-19>
                <!-- template dịch vụ -->
                <app-news-template-20 *ngIf="item?.FrameID == NEWS_20" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-20>
                <app-image-slider-title *ngIf="item?.FrameID == NEWS_21" [isAdminFlg]="isAdminFlg" [banner]="item">
                </app-image-slider-title>
                <!-- template 1 without time -->
                <app-news-template-1 *ngIf="item?.FrameID == NEWS_22" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)" [templateType]="2">
                </app-news-template-1>
                <!-- template advertise -->
                <app-news-template-21 *ngIf="item?.FrameID == NEWS_23" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-21>
                <!-- Hỏi đáp -->
                <app-quiz *ngIf="item?.FrameID == NEWS_24" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [banner]="item"
                  (saveData)="this.onReload()">
                </app-quiz>
                <app-image-list *ngIf="item?.FrameID == NEWS_25" [isAdminFlg]="isAdminFlg" [banner]="item">
                </app-image-list>
                <app-images-slider *ngIf="item?.FrameID == NEWS_26" [isAdminFlg]="isAdminFlg" [banner]="item">
                </app-images-slider>
                <app-news-template-22 *ngIf="item?.FrameID == NEWS_27" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-22>
                <!-- template most view -->
                <app-news-template-23 *ngIf="item?.FrameID == NEWS_28" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-23>
                <!-- template news vertical -->
                <app-news-template-24 *ngIf="item?.FrameID == NEWS_29" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-24>
                <!-- template news 2/3 -->
                <app-news-template-25 *ngIf="item?.FrameID == NEWS_30" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-25>
                <app-news-template-26 *ngIf="item?.FrameID == NEWS_31" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-26>
                <!-- template dịch vụ 2 -->
                <app-news-template-27 *ngIf="item?.FrameID == NEWS_32" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-27>
                <app-news-template-28 *ngIf="item?.FrameID == NEWS_33" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-28>
                <!-- template tin mới nhiều chuyên mục -->
                <app-news-template-29 *ngIf="item?.FrameID == NEWS_34" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-29>
                <app-news-template-30 *ngIf="item?.FrameID == NEWS_35" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-30>
                <app-news-template-31 *ngIf="item?.FrameID == NEWS_36" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (transHeight)="onTransHeight($event)">
                </app-news-template-31>
                <!-- lien ket mang xa hoi -->
                <app-news-template-32 *ngIf="item?.FrameID == NEWS_37" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
                </app-news-template-32>
                <app-news-template-33 *ngIf="item?.FrameID == NEWS_38" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-news-template-33>
                <app-news-template-34 *ngIf="item?.FrameID == NEWS_39" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)" (menuItem)="menuItem($event)">
                </app-news-template-34>
                <app-image-slider-customer-service *ngIf="item?.FrameID == NEWS_41" [isAdminFlg]="isAdminFlg"
                  [banner]="item">
                </app-image-slider-customer-service>

                <app-customer-care *ngIf="item?.FrameID == NEWS_40" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-customer-care>
                <!-- Thông tin tiền nước  -->
                <app-water-bill-information *ngIf="item?.FrameID == NEWS_41" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                  [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                  [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                  [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
                  (urlItem)="getUrl($event)">
                </app-water-bill-information>
                <!-- Lien he co thong tin -->
                <app-news-template-35 *ngIf="item?.FrameID == NEWS_42" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
                [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
              </app-news-template-35>
              <!-- Thu vien radio -->
              <app-news-template-36 *ngIf="item?.FrameID == NEWS_43" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
              (urlItem)="getUrl($event)">
            </app-news-template-36>
              <!-- Slider banner -->
              <app-news-template-38 *ngIf="item?.FrameID == NEWS_45" [isAdminFlg]="isAdminFlg" [banner]="item">
              </app-news-template-38>
            <!-- template tro ve dau trang -->
            <app-news-template-37 *ngIf="item?.FrameID == NEWS_44" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
            [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
            [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
            [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
          </app-news-template-37>
            <!-- template tin tuc gom tin con  -->
            <app-news-template-39 *ngIf="item?.FrameID == NEWS_46" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-39>
            <!-- template chuyen de truyen thong -->
            <app-news-template-40 *ngIf="item?.FrameID == NEWS_47" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-40>
            <!-- template gioi thieu o trang chu -->
            <app-news-template-41 *ngIf="item?.FrameID == NEWS_48" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-41>
            <!-- template don vi thanh vien -->
            <app-news-template-42 *ngIf="item?.FrameID == NEWS_49" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-42>
            <!-- template chuong trinh - du an -->
            <app-news-template-43 *ngIf="item?.FrameID == NEWS_50" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()"
              (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)">
            </app-news-template-43>
            <!-- template chuyen gia-->
            <app-news-template-44 *ngIf="item?.FrameID == NEWS_51" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-44>
            <!-- Trang chuyen gia-->
            <app-news-template-45 *ngIf="item?.FrameID == NEWS_52" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-45>
            <!-- Trang chi hoi thanh vien-->
            <app-news-template-46 *ngIf="item?.FrameID == NEWS_53" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-46>
            <!-- Email và SĐT-->
            <app-news-template-47 *ngIf="item?.FrameID == NEWS_54" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-47>
            <!-- Trang chuyên đề truyền thông -->
            <app-news-template-48 *ngIf="item?.FrameID == NEWS_55" [ID]="item?.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
              [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)"
              [newsDecorationIndex]="i" [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item"
              [layoutGroupIndex]="groupIndex" (saveData)="this.onReload()"
              (changeTemplate2)="this.changeTemp12($event)" [menuID]="menuID"
              (deleteBodyItem)="onDltBodyItem($event)">
            </app-news-template-48>
            <!-- Chi tiết chuyên đề truyền thông -->
            <app-news-template-49 *ngIf="item?.FrameID == NEWS_56" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-49>
            <app-news-template-50 *ngIf="item?.FrameID == NEWS_57" [ID]="item.ID" [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
              [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
              [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" (saveData)="this.onReload()">
            </app-news-template-50>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="layout-box" [style.width]="'100%'" *ngIf="isProgramPage">
          <div class="layout-list" id="ctda">
            <div class="body-box" [style.width.%]="100">
              <div class="body-item">
                <app-news-template-ctda [title]="programTitle" [programList]="programListFilter"
                [description]="description" [process]="process" [studentList]="studentList" [reportList]="reportList" [activityList]="activityList">
                </app-news-template-ctda>
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div></div>
    <!-- coding footer -->
    <div *ngFor="let item of pageFooter" class="header-box">
      <app-footer *ngIf="item == '1'"></app-footer>
      <app-footer2 *ngIf="item == '2'" [isAdminFlg]="false"></app-footer2>
      <app-footer3 *ngIf="item == '3'" [bodyWidth]="bodyWidth" (menuItemchild)="menuItemchild($event)"
        [isAdminFlg]="false"></app-footer3>
    </div>
    <!-- coding footer -->
  </div>
</article>
