<header-template3 *ngIf="isLoaded">
  <!-- Banner Top -->
  <div class="banner">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 desktop">
        <div class="row">
          <div class="col-md-1 col-12"></div>
          <div class="col-sm-12 col-md-2 col-12 logo-container">
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-custom edit-logo" [data]="btnHeader" [popupSettings]="{
                    popupClass: popupClass,
                    animate: true,
                    appendTo: 'component'
                  }">
                <span class="material-icons" (click)="onClickFunction(headerInfo.Image[0])">more_vert</span>
              </kendo-dropdownbutton>
            </span>
            <div *ngIf="headerInfo.Image">
              <img class="img1" src="{{ appService.apiRoot + headerInfo.Image[0].ImageUrl }}"
                alt="{{ headerInfo.Image[0].Title }}" />
            </div>
            <div *ngIf="!headerInfo.Image">
              <img class="img1" src="../../assets/images/default-image.png" alt="header" />
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-12 name">
            <hgroup>
              <div class="name-main">
                <a [routerLink]="['/']" (click)="goToRoot()"> {{ headerInfo?.Info.Title }}</a>
                <span *ngIf="isAdminFlg" class="btn-custom">
                  <kendo-dropdownbutton class="btn-custom edit-logo" [data]="btnHeader" [popupSettings]="{
                        popupClass: popupClass,
                        animate: true,
                        appendTo: 'component'
                      }">
                    <span class="material-icons" (click)="onClickFunction(headerInfo?.Info)">more_vert</span>
                  </kendo-dropdownbutton>
                </span>
              </div>
              <br />
              <div class="name-detail">
                <a [routerLink]="['/']" (click)="goToRoot()"> {{ headerInfo?.Info.SubTitle }}</a>
              </div>
            </hgroup>
          </div>
          <div class="col-md-3 col-12 sub-menu">
            <ul class="nav-content">
              <div class="nav-menu" [ngClass]="openFlg ? 'navbar-display' : 'navbar-hidden'" #mother0>
                <li class="menu-obj level0 dropdown" *ngFor="let item of subMenus; let i = index" [id]="item.ID">
                  <a class="sub-item-link pading-0" [title]="item.Name" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </div>
          <div class="col-md-2 col-12">
            <img
              src="../../assets/images/school-technology-and-science-fair-banner-design-template-a4b29fc9007bea196b5fefd93caa7e6b_screen 1.png"
              alt="banner">
          </div>
          <div class="col-md-1 col-12"></div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 mobile">
        <div class="row">
          <div class="col-md-1 col-12"></div>
          <div class="col-sm-12 col-md-1 col-12 logo-container">
            <span *ngIf="isAdminFlg" class="btn-custom">
              <kendo-dropdownbutton class="btn-custom edit-logo" [data]="btnHeader" [popupSettings]="{
                  popupClass: popupClass,
                  animate: true,
                  appendTo: 'component'
                }">
                <span class="material-icons" (click)="onClickFunction(headerInfo.Image[0])">more_vert</span>
              </kendo-dropdownbutton>
            </span>
            <div *ngIf="headerInfo.Image">
              <img class="img1" src="{{ appService.apiRoot + headerInfo.Image[0].ImageUrl }}"
                alt="{{ headerInfo.Image[0].Title }}" />
            </div>
            <div *ngIf="!headerInfo.Image">
              <img class="img1" src="../../assets/images/default-image.png" alt="header" />
            </div>
          </div>
          <div class="col-sm-12 col-md-8 col-12 name">
            <hgroup>
              <div class="name-main">
                <a [routerLink]="['/']" (click)="goToRoot()"> {{ headerInfo?.Info.Title }}</a>
                <span *ngIf="isAdminFlg" class="btn-custom">
                  <kendo-dropdownbutton class="btn-custom edit-logo" [data]="btnHeader" [popupSettings]="{
                      popupClass: popupClass,
                      animate: true,
                      appendTo: 'component'
                    }">
                    <span class="material-icons" (click)="onClickFunction(headerInfo?.Info)">more_vert</span>
                  </kendo-dropdownbutton>
                </span>
                <br />
              </div>

              <div class="name-detail">
                <a [routerLink]="['/']" (click)="goToRoot()"> {{ headerInfo?.Info.SubTitle }}</a>
                <!-- <span *ngIf="isAdminFlg" class="btn-custom">
                  <kendo-dropdownbutton class="btn-custom edit-logo" [data]="btnHeader"
                    [popupSettings]="{popupClass: popupClass, animate: true, appendTo: 'component'}">
                    <span class="material-icons" (click)="onClickFunction(headerInfo?.Info)">more_vert</span>
                  </kendo-dropdownbutton>
                </span> -->
              </div>
            </hgroup>
          </div>
          <div *ngIf="isLogged" class="col-sm-6 col-md-1 col-12 user">
            <!-- <button class="login-btn">Đăng nhập</button> -->
            <div class="change-btn">
              <img src="../../assets/images/flower-icon.png" />
            </div>
            <div class="dropdown">
              <div class="avatar">
                <img src="../../assets/images/avatar.png" />
              </div>
              <div class="dropdown-bg">
                <div class="dropdown-content">
                  <a [routerLink]="['/user-info']" routerLinkActive="active" class="user-info">
                    <div class="avatar">
                      <img src="../../assets/images/avatar.png" />
                    </div>
                    <div class="col">
                      <div class="role">{{user.FullName}}</div>
                      <button class="btn-edit-info">
                        {{ "Edit" | translate }}
                      </button>
                    </div>
                  </a>
                  <a [routerLink]="['/settings']" routerLinkActive="active"><i class="fa fa-cog"></i> {{ "Settings" |
                    translate }}</a>
                  <a (click)="onSignout()"><i class="fa fa-sign-out"></i> {{ "Logout" | translate }}</a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isLogged" class="col-md-1 col-12 login">
            <!-- <button class="login-btn">Đăng nhập</button> -->
            <button class="login-btn" [routerLink]="['/login']" routerLinkActive="active">
              {{ "Login" | translate }}
            </button>
          </div>
          <div class="col-md-1 col-12"></div>
        </div>
      </div>
    </div>

  </div>

  <!-- Thanh Menu -->
  <nav class="nav-menu-box">
    <div class="row">
      <div class="col-md-1 col-12"></div>
      <div class="col-md-10 col-12">
        <div class="row">
          <div class="col-md-10">
            <ul class="nav-content">
              <div class="navbar-cover-shadow" (click)="open()" *ngIf="openFlg"></div>
              <div class="nav-menu" [ngClass]="openFlg ? 'navbar-display' : 'navbar-hidden'" #mother0>
                <div class="close-button-box mobile">
                  <mat-icon (click)="open()" class="close-button">close</mat-icon>
                </div>
                <li class="menu-obj level0 dropdown">
                  <a class="item-link pading-0" (click)="goToRoot()" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <span class="k-icon k-i-home"></span>
                  </a>
                </li>
                <li class="menu-obj level0 dropdown" *ngFor="let item of menus; let i = index" #level0
                  (click)="selectMenu($event, mother0, level0, 'level0',item, i+1)" [id]="item.ID">
                  <a class="item-link pading-0" [title]="item.Name" (click)="goToMenu(item)" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </div>
          <div class="col-md-2 login-area">
            <div class="search">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            &nbsp;
            <a class="item-link login">
              {{ "Login" | translate }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-1 col-12"></div>
    </div>
  </nav>
  <kendo-dialog *ngIf="addMenu" [draggable]="false" class="add-menu x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()"></button>
        <div class="title-info-req">
          <span [textContent]="'Menu_List' | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-12 col-12">
              <div [ngClass]="'control'">
                <label [textContent]="'Menu' | translate"></label>
                <kendo-dropdownlist [data]="menuFilter" [textField]="'Name'" [valueField]="'ID'" [valuePrimitive]="true"
                  [filterable]="true" (filterChange)="menuHandleFilter($event)" (valueChange)="onChangeMenu($event)"
                  [popupSettings]="{ popupClass: popupClass, animate: true }">
                  <ng-template kendoComboBoxNoDataTemplate>{{
                    "NoRecordsAvailable" | translate
                    }}</ng-template>
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
                  <span aria-hidden="true">{{ "Save" | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
  <kendo-dialog *ngIf="editFlg && infoOpened" [draggable]="false" class="edit x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()"></button>
        <div class="title-info-req">
          <span [textContent]="'Header_Info_List' | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <kendo-tabstrip #tabstrip (tabSelect)="onTabChanged($event)" class="portal-tabstrip">
            <kendo-tabstrip-tab class="officetabstrip" [selected]="tabID == 0" [ngClass]="tabID == 0 ? '' : 'active'"
              class="active" [title]="language.get() == 'vi-VN' ? 'Tiếng việt' : 'Vietnamese'">
              <ng-template kendoTabContent>
                <div class="row">
                  <div class="edit-info">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Title' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viHeaderInfo?.Info.Title" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'SubTitle' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="viHeaderInfo?.Info.SubTitle" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-12">
                    <div class="btn-dialog-wrapper">
                      <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog('vi-VN')">
                        <span aria-hidden="true">{{ "Save" | translate }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab class="officetabstrip" [selected]="tabID == 1" [ngClass]="tabID == 1 ? '' : 'active'"
              class="active" [title]="language.get() == 'vi-VN' ? 'Tiếng anh' : 'English'">
              <ng-template kendoTabContent>
                <div class="row">
                  <div class="edit-info">
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'Title' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enHeaderInfo?.Info.Title" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div [ngClass]="'control'">
                        <label>
                          <span [textContent]="'SubTitle' | translate"></span>
                          <!-- <span class="required">(*)</span> -->
                        </label>
                        <input kendoTextBox [(ngModel)]="enHeaderInfo?.Info.SubTitle" [ngClass]="''" />
                        <!-- <span class="err-text" *ngIf="dataMenuItem.Name">{{dataMenuItem.Name}}</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-12">
                    <div class="btn-dialog-wrapper">
                      <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog('en-US')">
                        <span aria-hidden="true">{{ "Save" | translate }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </kendo-dialog>
  <kendo-dialog *ngIf="editFlg && avatarUpload" [draggable]="false" class="edit x-dialog window-center">
    <div class="employe-detail">
      <div class="x-close-div">
        <button kendoButton class="xbutton-dialog" [icon]="'close'" [title]="'Close_Box' | translate"
          (click)="onCloseDialog()"></button>
        <div class="title-info-req">
          <span [textContent]="'Upload_Avatar_Image' | translate"></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row">
            <div *ngIf="avatarUpload" class="upload-image">
              <div [ngClass]="'control'">
                <kendo-upload [multiple]="false" [showFileList]="true" [saveUrl]="fileSaveUrl"
                  (success)="onSuccessAvatarUpload($event)" [accept]="['.png', '.jpg', '.jpeg']">
                  <kendo-upload-messages [headerStatusUploaded]="'FileUploaded' | translate"
                    [dropFilesHere]="'dropFilesHere' | translate" [select]="'UploadFile' | translate">
                  </kendo-upload-messages>
                </kendo-upload>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="btn-dialog-wrapper">
                <button type="button" class="btn-dialog btn-save" (click)="onSaveDialog()">
                  <span aria-hidden="true">{{ "Save" | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
</header-template3>
