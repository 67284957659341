<section class="row mx-0 file-settlemen">
    <div [style.width]="bodyWidth +'px'" class="auto">
        <div class="wrapper">

            <!-- <div class="col-md-1"></div> -->
            <div class="row">
                <div class="col-md-3 col-12 categories-area">
                    <div class="titile"> {{ "Hạng mục dịch vụ" | translate}}
                    </div>
                 
                    <div class="round-checkbox form-row-checkbox">
                        <div class="checkboxFour">
                            <input type="checkbox" value=""
                                id="checkboxFourInput"  />
                            <label for="checkboxFourInput"></label>
                        </div>
                        <div class="label-text">{{ "Tra cứu thông tin tiền nước"
                            |
                            translate}} </div>
                    </div>
                    <div class="round-checkbox form-row-checkbox">
                        <div class="checkboxFour1">
                            <input type="checkbox1" value=""
                                id="checkboxFourInput1"  />
                            <label for="checkboxFourInput1"></label>
                        </div>
                        <div class="label-text">{{
                            "Tra cứu kết quả giải quyết hồ sơ" |
                            translate}} </div>
                    </div>
                      <div class="round-checkbox form-row-checkbox">
                        <div class="checkboxFour2">
                            <input type="checkbox2" value=""
                                id="checkboxFourInput2"  />
                            <label for="checkboxFourInput2"></label>
                        </div>
                        <div class="label-text">{{
                            "Tra cứu kết quả giải quyết khiếu nại" |
                            translate}}</div>
                    </div>
                </div>
                <div class="col-md-9 col-12 send-background">
                    <h3 class="quiz-title">{{ "Giải quyết hồ sơ" | translate }}</h3>

                    <div class="row mx-0">
                        <div class="col-md-12 col-12">

                            <form class="quiz-form" [formGroup]="userForm">

                                <fieldset class="quiz-form-fieldset">
                                    <div class="button"></div>
                                    <hr />
                                    <div class="row">
                                        <div class="row">
                                            <div class="col-md-4 col-12">
                                                <kendo-formfield>
                                                    <div>
                                                        <div class="data-title">{{
                                                            "Mã hồ sơ" |
                                                            translate}}
                                                        </div>
                                                        <div
                                                            class="quiz-input-item">
                                                            <input
                                                                formControlName="AccountName"
                                                                [placeholder]="'' | translate"
                                                                kendoTextBox
                                                                required />
                                                            <div
                                                                class="quiz-error"
                                                                *ngIf="!validForm && !userForm.get('AccountName').valid">
                                                                {{
                                                                "Contact_Error"
                                                                | translate }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </kendo-formfield>
                                            </div>
                                            <div class="col-md-2 col-12">
                                                <kendo-formfield>
                                                    <div>
                                                        <div class="data-title">{{
                                                            "Mã bảo mật" |
                                                            translate}}
                                                        </div>
                                                        <div
                                                            class="quiz-input-item">
                                                            <input
                                                                formControlName="AccountName"
                                                                [placeholder]="'' | translate"
                                                                kendoTextBox
                                                                required />
                                                            <div
                                                                class="quiz-error"
                                                                *ngIf="!validForm && !userForm.get('AccountName').valid">
                                                                {{
                                                                "Contact_Error"
                                                                | translate }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </kendo-formfield>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <kendo-formfield>
                                                    <div class>
                                                        <div class="data-title">{{
                                                            "Mã xác nhận" |
                                                            translate}} <span
                                                                class="required">
                                                                *</span>
                                                        </div>
                                                        <div
                                                            class="quiz-input-item-captcha">
                                                            <div
                                                                class="col-md-5 col-5">
                                                                <input
                                                                    formControlName="Capcha"
                                                                    [placeholder]="'' | translate"
                                                                    kendoTextBox
                                                                    required />
                                                                <div
                                                                    class="quiz-error"
                                                                    *ngIf="!validForm && !userForm.get('Capcha').valid">
                                                                    {{
                                                                    "Contact_Error"
                                                                    | translate
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-md-5 col-5 captcha-item"
                                                                id="captcha-item"></div>
                                                            <div
                                                                class="col-md-3 col-2">
                                                                <div
                                                                    class="reload-captcha-btn"
                                                                    (click)="createCaptcha()">
                                                                    <img
                                                                        class="reload-icon"
                                                                        src="../../assets/images/reload-captcha-icon.png"
                                                                        alt>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </kendo-formfield>
                                            </div>

                                            <div class="col-md-2 col-12">
                                                <div
                                                    class="form-row form-row-mb">
                                                    <div class="data-title"></div>
                                                    <div
                                                        class="quiz-button-item">
                                                        <button type="submit"
                                                            (click)="onSubmit()"
                                                            class="quiz-submit-button">
                                                            {{ "Tra cứu"|
                                                            translate }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bodder-settlemen">
                                        <div class="margin-bodder">
                                            <div class="text-header">{{
                                                "Thông tin hóa đơn" |
                                                translate}}</div>
                                            <div
                                                class="wb-water-bill-information">
                                                <div class="row">
                                                    <div
                                                        class=" row col-md-12 col-12">
                                                        <div
                                                            class="col-md-3 col-12 text-header-2">{{"Mã hồ sơ:"
                                                            | translate }}</div>
                                                        <div
                                                            class="col-md-9 col-12 text-header-3">{{"HS028431"
                                                            | translate }}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class=" row col-md-12 col-12">
                                                        <div
                                                            class="col-md-3 col-12 text-header-2">{{"Tên người nộp:"
                                                            | translate }}</div>
                                                        <div
                                                            class="col-md-9 col-12 text-header-3">{{"Nguyễn Hà Cẩm Tú"
                                                            | translate }}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class=" row col-md-12 col-12">
                                                        <div
                                                            class="col-md-3 col-12 text-header-2">{{"Lĩnh vực:"
                                                            | translate }}</div>
                                                        <div
                                                            class="col-md-9 col-12 text-header-3">{{"Cấp thoát nước"
                                                            | translate }}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class=" row col-md-12 col-12">
                                                        <div
                                                            class="col-md-3 col-12 text-header-2">{{
                                                            "Nội dung :" |
                                                            translate }}</div>
                                                        <div
                                                            class="col-md-9 col-12 text-header-3">{{"Noi_dung"
                                                            | translate }}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class=" row col-md-12 col-12">
                                                        <div
                                                            class="col-md-3 col-12 text-header-2">{{"Tệp tin đính kèm:"
                                                            | translate }}</div>
                                                        <div
                                                            class="col-md-9 col-12 text-file">{{"Hosocaphanmucnuoc.doc"
                                                            | translate }}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class=" row col-md-12 col-12">
                                                        <div
                                                            class="col-md-3 col-12 text-header-2">{{"Kết quả:"
                                                            | translate }}</div>
                                                        <div
                                                            class="col-md-9 col-12 text-result">{{"Đã trả kết quả"
                                                            | translate }}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div
                                                class="panelbar-wrapper  mb-water-bill-information ">
                                                <div class="txt-break item-menu">
                                                    <div class="row">
                                                        <div
                                                            class="col-md-12 col-10">
                                                            <strong
                                                                class=" text-header-2">{{"Mã hồ sơ:"
                                                                | translate }}</strong>
                                                            <span
                                                                class="text-header-3">{{"HS028431"
                                                                | translate }}</span>
                                                        </div>
                                                        <div
                                                            class="col-md-12 col-12">
                                                            <strong
                                                                class=" text-header-2">{{"Tên người nộp:"
                                                                | translate }}</strong>
                                                            <span
                                                                class="text-header-3">{{"Nguyễn Hà Cẩm Tú"
                                                                | translate }}</span>
                                                        </div>
                                                        <div
                                                            class="col-md-12 col-12">
                                                            <strong
                                                                class=" text-header-2">{{"Lĩnh vực:"
                                                                | translate }}</strong>
                                                            <span
                                                                class="text-header-3">{{"Cấp thoát nước"
                                                                | translate }}</span>
                                                        </div>
                                                        <div
                                                            class="col-md-12 col-12">
                                                            <strong
                                                                class=" text-header-2">{{"Nội dung :"
                                                                | translate }}
                                                            </strong>
                                                            <span
                                                                class="text-header-3">{{"Noi_dung"
                                                                | translate }}</span>
                                                        </div>

                                                        <div
                                                            class="col-md-12 col-12">
                                                            <strong
                                                                class=" text-header-2">{{"Tệp tin đính kèm:"
                                                                | translate }}
                                                            </strong>
                                                            <span
                                                                class="text-file">{{"Hosocaphanmucnuoc.doc"
                                                                | translate }}</span>
                                                        </div>
                                                        <div
                                                            class="col-md-12 col-12">
                                                            <strong
                                                                class=" text-header-2">{{"Kết quả:"
                                                                | translate }}</strong>
                                                            <span
                                                                class="text-result">{{"Đã trả kết quả"
                                                                | translate }}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
