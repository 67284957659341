<article class="news-template-ctda" (window:resize)="onResize($event)">
  <div class="container-fluid">
    <div class="row main-item">
      <div class="main-item">
        <div class="row">
          <div class="col-sm-12 col-12 col-md-12 col-xl-12">
            <div class="row">
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 menu-title">
                <span class="uppercase"> {{ title }} </span>
                <hr />
              </div>
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 description" *ngIf="description">
                <div class="row">
                  <div class="col-sm-12 col-12 col-md-6 col-xl-6 image web-view">
                    <img class="main-img" [src]="description?.ImageUrls[0] ? description?.ImageUrls[0] : defaultImg" alt="img">
                    <img class="sub-img-1" [src]="description?.ImageUrls[1] ? description?.ImageUrls[1] : defaultImg" alt="img">
                    <img class="sub-img-2" [src]="description?.ImageUrls[2] ? description?.ImageUrls[2] : defaultImg" alt="img">
                  </div>
                  <div class="col-sm-12 col-12 col-md-6 col-xl-6 image mobile-view">
                    <kendo-scrollview #scrollview  *ngIf="description?.ImageUrls.length>0" [data]="description?.ImageUrls" [arrows]="false" [pageable]="true" [endless]="true"
                    [style.height.px]="innerWidth*0.6">
                      <ng-template let-item="item">
                        <img [src]="item" alt="{{ item.Name }}" class="img-scrollview" draggable="false" [style.height.px]="innerWidth*0.6" />
                      </ng-template>
                    </kendo-scrollview>
                    <img *ngIf="description?.ImageUrls.length==0" src="../../assets/images/default.png" alt="" draggable="false"
                      class="img-scrollview" />
                  </div>
                  <div class="col-sm-12 col-12 col-md-6 col-xl-6 content">
                    <p [innerHTML]="description?.Description">
                      {{ description?.Description }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 process" *ngIf="process">
                <div class="title">
                  <span class="uppercase">Quy trình xét tuyển</span>
                </div>
                <p class="content" [innerHTML]="process?.Description">
                  {{ process?.Description }}
                </p>
              </div>
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 student-list" *ngIf="studentList.length > 0">
                <div class="title">
                  <span class="uppercase">Danh sách những học sinh được nhận học bổng</span>
                </div>
                <div class="content">
                  <div *ngFor="let student of studentList; let i = index"
                    class="student-content" [ngClass]="{ 'content-border': i != studentList?.length-1 }">
                    <div class="student-title" (click)="studentInfo(i)">
                      <div class="arrow">
                        <i class="fa fa-angle-up" *ngIf="studentIndex.includes(i)" aria-hidden="true"></i>
                        <i class="fa fa-angle-down" *ngIf="!studentIndex.includes(i)" aria-hidden="true"></i>
                      </div>
                      <div class="name">
                        {{ student?.Title }}
                      </div>
                    </div>
                    <div class="student-info row" *ngIf="studentIndex.includes(i)">
                      <div class="col-sm-12 col-12 col-md-4 col-xl-4 info-image">
                        <img *ngIf="student?.ImageUrls[0]" [src]="student?.ImageUrls[0]" alt="img">
                      </div>
                      <div class="col-sm-12 col-12 col-md-8 col-xl-8 info-content">
                        <p [innerHTML]="student?.Description">
                          {{ student?.Description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 report" *ngIf="reportList.length > 0">
                <div class="title">
                  <span class="uppercase">Báo cáo minh bạch tài chính</span>
                </div>
                <div class="content row">
                  <a *ngFor="let report of reportList; let i = index"
                    class="col-sm-12 col-12 col-md-3 col-xl-3 report-content" [href]="report?.FileUrls[0]" target="_blank">
                    <div class="row">
                      <div class="col-sm-6 col-6 col-md-12 col-xl-12 image">
                        <img [src]="report?.ImageUrls[0]" alt="img">
                      </div>
                      <div class="col-sm-6 col-6 col-md-12 col-xl-12 item-title">
                        <span>
                          {{
                            report?.Title.length > (innerWidth > 768 ? 100 : 65)
                              ? (report?.Title.slice(0, (innerWidth > 768 ? 100 : 65)) + "...")
                              : report?.Title
                          }}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 activities" *ngIf="activityList.length > 0">
                <div class="title">
                  <span class="uppercase">TIN TỨC HOẠT ĐỘNG</span>
                </div>
                <div class="content row">
                  <a *ngFor="let activity of activityList; let i = index"
                    class="col-sm-12 col-12 col-md-3 col-xl-3 activity-content"
                    [href]="this.postDetailRoute + activity?.UrlPath">
                    <div class="row">
                      <div class="col-sm-6 col-6 col-md-12 col-xl-12 image">
                        <img [src]="activity?.ImageUrl" [alt]="activity?.Title">
                      </div>
                      <div class="col-sm-6 col-6 col-md-12 col-xl-12">
                        <div class="item-title">
                          <span>
                            {{
                              activity?.Title.length > (innerWidth > 768 ? 100 : 65)
                                ? (activity?.Title.slice(0, (innerWidth > 768 ? 100 : 65)) + "...")
                                : activity?.Title
                            }}
                          </span>
                        </div>
                        <div class="time-clock">
                          <img class="clock" src="../../assets/images/clock-circle.svg" alt="">
                          <p class="time-text" *ngIf="activity?.CreateAt">{{ activity?.CreateAt | date:'dd-MM-yyyy' }}</p>
                          <p class="time-text" *ngIf="!activity?.CreateAt"></p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <!-- <div class="collumn" *ngIf="innerWidth<=768">
              <div class="col-sm-12 col-12 col-md-4 col-xl-4 top-new-item">
                <div class="post-list" *ngIf="selectedTab == 1 && postList.length > 0">
                  <a
                    [className]="
                      'col-sm-12' +
                      ' col-12' +
                      ' col-md-12' +
                      ' pointer top-new-item'
                    "
                    *ngFor="let item of postList; let i = index"
                    [href]="this.postDetailRoute  + item.UrlPath" routerLinkActive="router-link-active"
                  >
                    <section class="top-part">
                      <div class="col-12">
                        <figure class="img-figure" [ngClass]="setH()">
                          <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-right-image" [src]="appService.apiRoot + item.ImageUrl" alt="post-image"/>
                          <img *ngIf="item.ImageUrl == this.LINK_DEFAULT_IMAGE || item.ImageUrl == null || item.ImageUrl == undefined || item.ImageUrl == ''" class="post-image-default"
                            src="../../assets/images/default.png" alt="post-image"/>
                        </figure>
                      </div>
                      <div class="col-12 right-item-detail">
                        <p class="title-text" *ngIf="item.Title">
                          {{
                            item.Title.length > 100
                              ? item.Title.slice(0, 100) + "..."
                              : item.Title
                          }}
                        </p>
                        <div class="time-clock">
                          <img class="clock" src="../../assets/images/clock-circle.svg" alt="">
                          <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd/MM/yyyy' }}</p>
                          <p class="time-text" *ngIf="!item.CreateAt"></p>
                        </div>

                      </div>
                    </section>
                  </a>
                </div>
                <div class="video-list" *ngIf="selectedTab == 2 && videoList.length > 0">
                  <div class="content">
                    <div class="row d-grid">
                        <div class="col-12"
                        *ngFor="let item of videoList; let j = index" (click)="openDialogVideo(item.FileUrls)">
                        <div class="pointer width-full">
                          <div class="overlay"></div>
                          <section  [innerHTML]="getVideo(item.FileUrls) | sanitizedHtml">
                          </section>
                        </div>
                        <div
                          (click)="openDialogVideo(item.FileUrls)"
                          class="pointer video-title"
                        >
                          {{ item.Name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="radio-list" *ngIf="selectedTab === 3 && radioList.length > 0">
                  <div class="content">
                    <div class="row">
                      <div class="mb">
                        <div class="txt-break item-menu radio-container" *ngFor="let item of radioList; let j = index">
                          <div class="row">
                            <div class="col-md-12 col-12">
                              <strong> {{ "Radio_title" | translate }}: </strong>

                              <span class="value">{{ item.Name }}</span>
                            </div>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Create_by" | translate }}: </strong>
                            <span class="value">{{ item.CreateBy }}</span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Download_Num" | translate }}: </strong>
                            <span class="value"> {{item.CntNum ? item.CntNum : 0 | number:'1.0-0'}}</span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Download" | translate }}: </strong>
                            <span class="value"><img src="../../assets/images/Download.png" class="img" (click)="downloadFile(item.FileUrls, item.ID)" /></span>
                          </div>
                          <div class="col-md-12 col-12">
                            <strong> {{ "Play" | translate }}: </strong>
                            <span class="value">
                              <audio controls>
                                <source [src]="getAudioUrl(item.FileUrls)" type="audio/mpeg">
                              </audio>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</article>
