<article class="news-template-32" (window:resize)="onResize($event)" *ngIf="isShow">
  <div class="container-fluid">
    <div class="row fixed-item" #scrollMe>
      <div class="main-item">
        <div class="row">
          <div *ngIf="isAdminFlg" class="vertical-dot" [ngClass]="isShowMenu ? 'd-flex':''">
            <div class="three-dot-area k-icon k-i-more-vertical" (click)="enableChoice()"></div>
            <div class="menu" *ngIf="isShowMenu">
              <!-- <div class="menu-row-current-border" (click)="editComponent()">
                <div class="menu-item-1">{{'Edit' | translate}}</div>
              </div> -->
              <div class="menu-row-current" (click)="deleteComponent()">
                <div class="menu-item-3">{{'Delete' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12 col-md-12 col-xl-12"> 
            <div class="row">
              <!-- <div class="col-sm-12 col-12 col-md-12 pointer top-new-item">
                <div *ngFor="let item of topNews; let i = index"     (click)="redirectToDetail(item.UrlPath)">
                  <section *ngIf="i == 0" class="section-box">
                    <div [ngClass]="
                    item.ImageUrl ? 'img-figure' : 'img-figure-no-img'
                  ">
                  <a [href]="getDetailUrl(item.UrlPath)" class="post-image-cover">
                    <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image"
                    [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                    <img *ngIf="item.DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                      alt="post-image" />
                  </a>
                  
                </div>                         

                  </section>
                </div>
              </div> -->
              <div class="col-sm-12 col-12 col-md-12 col-xl-12 top-new-item">
                <div *ngFor="let item of topNews; let i = index" class="pointer"
                 (click)="redirectToDetail(item)" routerLinkActive="router-link-active">
                  <section class="text-section" *ngIf="i!=3">
                    <div [ngClass]="
                    item.ImageUrl ? 'img-figure' : 'img-figure-no-img'
                  ">
                  <a  class="post-image-cover" *ngIf="item.LinkUrl == null">
                    <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image"
                    [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                    <img *ngIf="item.DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                      alt="post-image" />
                  </a>
                  <a class="post-image-cover" *ngIf="item.LinkUrl != null">
                    <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image"
                    [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                    <img *ngIf="item.DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                      alt="post-image" />
                  </a>
                </div>    
              </section>
                  <section class="text-section" *ngIf="i==3" >
                    <div [ngClass]="
                    item.ImageUrl ? 'img-figure' : 'img-figure-no-img'
                  ">
                  <a class="post-image-cover"  >
                    <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image"
                    [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                    <img *ngIf="item.DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                      alt="post-image" />
                  </a>
                
                </div>    
                  </section>
                </div>
              </div>
            </div>
          
            <!-- <div class="collumn" *ngIf="innerWidth<=768">
              <div class="col-sm-12 col-12 col-md-12 pointer top-new-item">
                <div *ngFor="let item of topNews; let i = index" (click)="redirectToDetail(item.UrlPath)"
                  routerLinkActive="router-link-active">
                  <section *ngIf="i == 0">
                    <figure [ngClass]="
                        item.ImageUrl ? 'img-figure' : 'img-figure-no-img'
                      ">
                      <a [href]="getDetailUrl(item.UrlPath)" class="post-image">
                        <img *ngIf="item.ImageUrl && !item.DefaultFlg" class="post-image"
                          [src]="getFullUrlAPI(item.ImageUrl)" alt="post-image" />
                      </a>
                      
                      <img *ngIf="item.DefaultFlg == true" class="post-image" src="../../assets/images/default.png"
                        alt="post-image" />
                    </figure>
                    <a [href]="getDetailUrl(item.UrlPath)" class="title-first">
                      <p class="title-first" *ngIf="item.Title">
                        {{ item.Title }}
                      </p>
                    </a>
                    <p class="title-first" *ngIf="!item.Title"></p>
                    <div>
                      <p class="new-detail">
                        {{ item.Summary}}
                      </p>
                    </div>
                     <div class="time-clock">
                                          <img class="icon-clock" src="../../assets/images/icon-clock.png" alt="icon-clock">
                                          <p class="time-text" *ngIf="item.CreateAt">{{ item.CreateAt | date:'dd-MM-yyyy' }}</p>
                                          <p class="time-text" *ngIf="!item.CreateAt"></p>
                                      </div> 
                  </section>
                </div>
              </div>

              <div class="col-sm-12 col-12 col-md-12 pd-l-12 top-new-item">
                <div *ngFor="let item of topNews; let i = index" class="pointer"
                 (click)="redirectToDetail(item.UrlPath)"  routerLinkActive="router-link-active">
                  <section *ngIf="i != 0" class="text-section">
                    <div class="time-clock">
                      <img class="icon-clock" src="../../assets/images/square-news-template.png" alt="icon-clock" />
                    </div>
                    <a [href]="getDetailUrl(item.UrlPath)" class="title">
                      <p class="title" *ngIf="item.Title">{{ item.Title }}</p>
                    </a>                   
                    <p class="title" *ngIf="!item.Title"></p>
                  </section>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- <div class="col-sm-12 col-12 col-md-12 col-xl-12 post-more">
                <p class="pointer" (click)="onAddNewGallery()">Thêm mới tin bài +</p>
            </div> -->
    </div>
  </div>
  <kendo-dialog *ngIf="manageDialog" (mousedown)="$event.stopPropagation()"
    class="router-outlet-content window-center dialog-info">
    <div class="news-template-32-dialog close-div">
      <div class="title-info-req">
        <strong *ngIf="editFlg == true && dltFlg == false">{{
          "Gallery_Scrollview_Edit" | translate
          }}</strong>
        <strong *ngIf="editFlg == false && dltFlg == false">{{
          "Gallery_Scrollview_Add" | translate
          }}</strong>
        <strong *ngIf="dltFlg == true">{{
          "Gallery_Scrollview_Delete" | translate
        }}</strong>
      </div>
      <div *ngIf="dltFlg == false" class="close-icon-div">
        <img class="close-icon" src="../../assets/images/close-button.png" alt="" (click)="onClose($event)" />
      </div>
    </div>
    <app-news-template-32-dialog *ngIf="editFlg == true" [request_AccountID]="request_AccountID" [HiddenTab]="HiddenTab"
      (galleryManageOpened)="onClose($event)" [galleryData]="galleryData" [editFlg]="editFlg" [templateID]="templateID"
      [pageSelectedID]="this.pageSelectedID" (onSave)="this.onSaveData()" (onChangetemp2)="this.changeTemp2($event)">
    </app-news-template-32-dialog>
    <div *ngIf="dltFlg == true">
      <div class="input-label">
        <span [textContent]="'Gallery_Dialog_Delete_Confirm' | translate" ></span>
      </div>
      <div class="button-group">
        <button
          class="btn-delete"
          (click)="onDeleteGallery()"
        >
          <span>{{ "Delete" | translate }}</span>
        </button>
        <button
          class="btn-cancel"
          (click)="onClose($event)"
        >
          <span>{{ "Cancel" | translate }}</span>
        </button>
      </div>
    </div>
  </kendo-dialog>
</article>
