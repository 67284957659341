import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-intro',
  templateUrl: './general-intro.component.html',
})
export class GeneralIntroComponent implements OnInit {
  @Input() menuItem: any
  constructor() { }

  ngOnInit(): void {
  }

}
