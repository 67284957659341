<div class="support-container">
  <h4 class="support-title">{{ "Support" | translate }}</h4>
  <p class="support-content">
    Mọi yêu cầu về hỗ trợ vui lòng liên hệ bộ phận kỹ thuật. Chúng tôi sẽ phản
    hồi yêu cầu sớm nhất có thể . Xin cám ơn.
  </p>
  <div class="support-item">
    <span class="support-item-icon">
      <img src="assets/images/phone.png" />
    </span>
    <span class="support-item-title">19005678</span>
  </div>
  <div class="support-item">
    <span class="support-item-icon">
      <img src="assets/images/mail.png" />
    </span>
    <span class="support-item-title">info@saigontourist.com.vn</span>
  </div>
</div>
