import { Component,ViewContainerRef, OnInit, OnDestroy,Input,Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';

// import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
// import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
// import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
// import { NullInjector } from '@angular/core/src/di/injector';
// import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
//import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from 'src/app/services/app.consts';
// import { ItemChange } from '@progress/kendo-angular-charts/dist/es2015/common/collection.service';
//import { setLines } from '@angular/material';


@Component({
  selector: 'app-question-info',
  templateUrl: './question-info.component.html'
  // styleUrls: ['./question-info.component.scss']
})
export class QuestionInfoComponent implements OnInit, OnDestroy {
  customCss = 0;
  dataErr: any;
  @Input() dataGridItem: any;
  // @Output() infoOpened = new EventEmitter<boolean>();
  // @Output() updateProgressOpened = new EventEmitter<boolean>();
  control: any;
  user: any;
  checkListItem:any;
  controlDefault = true;
  // dataSteps = [];
  // dataProgressItem:any;
  // GroupStepListName=[];
  // GroupStepCheckedList= [];

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    public appConsts: AppConsts) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
   // this.language.default();
   this.language.default();
  }

  ngOnInit() {
    // this.getControl();
    this.setDefault()
    // this.GetCVsteps();

  }
  ngOnDestroy(): void {
  }
  checkErr() {
    if(this.customCss == 1) {
      return "x-dialog-question-info-err-one";
    } else if(this.customCss == 2) {
      return "x-dialog-question-info-err-two";
    }else if(this.customCss == 3) {
      return "x-dialog-question-info-err-three";
    }else if(this.customCss == 4) {
      return "x-dialog-question-info-err-four";
    } else {
      return "x-dialog-question-info-group"
    }
  }

  // onCloseInfo() {
  //   this.updateProgressOpened = false;
  //   this.setDefault();
  // }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID, AppConsts.page.receiveQuestions);
 this.controlDefault = false;
  }

  setlist(item,check) {

    var ListItem = {
     ID: item.ID,
     Name: item.Name,
    checked: check
    };
    // this.GroupStepCheckedList.push(ListItem)
    // var errTemp = Object.assign({}, this.dataGridItem);
    // errTemp.Type = null;
    // this.dataErr = [errTemp];
    // this.customCss = 0;
    // this.dataGridSelection = [];
    // this.disabled = false;
    // this.enabledID = true;
  }
  setDefault(){
    this.dataGridItem = {
      IsAdd: true,
      ID: this.guid.empty,
  
      FullName: '',
      Address: '',
      Phone :'',
      Email: '',
      Title: '',
      Contents :'',
    }
    var errTemp = Object.assign({}, this.dataGridItem);
    errTemp.Type = null;
    this.dataErr = [errTemp];
    this.customCss = 0;
  }
 
  changeStatus(e,item){
    // console.log(this.GroupStepCheckedList)
    // var temp = this.GroupStepCheckedList.find(x => x.ID == item.ID)
    // if(temp.checked){
    //   this.GroupStepCheckedList.find(x => x.ID == item.ID).checked = false;
    // }
    // else{this.GroupStepCheckedList.find(x => x.ID == item.ID).checked = true}
      
  }

 async onSendQuestion(){
//  this.appComponent.loading = true;
   
  
  // const dataRequest = this.createDataRequest(null);


    if(this.dataGridItem.Phone) {this.dataGridItem.Phone  = parseInt( this.dataGridItem.Phone  ,10).toString()};
      const dataRequest =this.dataGridItem;
 
      if(!dataRequest){ 
       this.appSwal.showWarning(this.translate.instant('No_Data'), false);
       return};
 
   const result = await this.appService.doPOST('api/Question/AddQuestion', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.dataGridItem ={};
      var errTemp = Object.assign({}, this.dataGridItem);
      errTemp.Type = null;
      this.dataErr = [errTemp];
      
      // this.onReload();
      // this.onAddNewGrid();
    } else {
      if (!result.Msg) {
      this.dataErr = result.Data;
      var count = 0;
      for (var prop in this.dataErr[0]) {
        count++;
      }
      this.customCss = count;
    }  else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  //  this.appComponent.loading = false;
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 47 && charCode < 58) {
      return true;
    }
    return false;
  }
}
