import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { AppConsts } from '../services/app.consts';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-embed-page',
  templateUrl: './embed-page.component.html',
  // styleUrls: ['./embed-page.component.scss']
})
export class EmbedPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('content_embed') content_embed: any;
  urlSafe: SafeResourceUrl;
  url: any;
  preURL: string;
  queryParamsSubscription: Subscription;
  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private language: AppLanguage) {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
      (data) => {
        this.preURL = data['title'] ? data['title'] : '';
        this.getEmbedPageUrl(this.preURL);
      });
  }

  ngOnInit(): void {
    if (this.content_embed) {
      if (this.url == AppConsts.RECEIVE_QUESTIONS) {
        this.content_embed.nativeElement.style.height = "1700px";
      }
      else {
        this.content_embed.nativeElement.style.height = "700px";
      }
    }
    this.url = this.appService.clientRoot + '#/' + this.url + '?langID=' + this.language.get();
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    // var height = this.content_embed.nativeElement.offsetParent.clientHeight;
    // this.content_embed.nativeElement.style.height = height + "px";
  }

  async getEmbedPageUrl(urlPath: string) {
    const result = await this.appService.doGET('api/Portal/GetEmbedPageUrl', { 'langID': this.language.get(), 'urlPath': urlPath });
    if (result && result.Status === 1) {
      this.url = result.Data[0].PageID;
      this.ngOnInit();
    }
  }
}
