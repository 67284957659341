import { Component, EventEmitter, Input, OnInit, AfterViewInit, Output } from '@angular/core';
import { AppLanguage } from '../services/app.language';
import { AppService } from '../services/app.service';
import { AppConsts } from "../services/app.consts";
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../services/app.notification';
import { AppComponent } from '../app.component';
import { EventEmitterService } from '../event-emitter.service';

@Component({
  selector: 'app-news-template-35',
  templateUrl: './news-template-35.component.html',
  // styleUrls: ['./news-template-35.component.scss']
})
export class NewsTemplate35Component implements OnInit, AfterViewInit {
  topNews: any;
  topNumber: number = 3;
  bootstrapNum: number;
  postTittle: string = "";
  viewMore: string = "";
  isShowMenu = false;
  manageDialog = false;
  @Input() ID: any;
  @Input() infoFromMain: any;
  @Input() categoriesList: any;
  @Input() templateID: any;
  @Input() pageSelectedID = "";
  galleryData: any;
  isGallery: boolean = false;
  editFlg = false;
  dltFlg = false;
  @Input() OrderIdx: any;
  @Output() isReloadPageAfterSave = new EventEmitter<boolean>();
  @Output() saveData = new EventEmitter();
  @Output() changeTemplate4 = new EventEmitter();
  @Input() isAdminFlg: boolean = true;
  @Input() newsDecorationIndex: any;
  @Input() itemTemplate: any;
  @Input() pageBodyIndex: any;
  @Input() layoutGroupIndex: any;
  @Output() deleteBodyItem = new EventEmitter();
  imageUrl: any;
  imageHeight: number;
  imageWidth: number;
  imageSize: string;
  info: any;
  isImageChosen = true;
  constructor(
    private appService: AppService,
    private language: AppLanguage,
    private translate: TranslateService,
    private notification: Notification,
    private appComponent: AppComponent,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit(): void {

    this.bootstrapNum = 12 / this.topNumber;


    // this.infoFromMain = JSON.parse(this.infoFromMain);
    // this.infoFromMain = this.infoFromMain ? JSON.parse(this.infoFromMain.slice(1, -1)) : null;
    if (typeof this.infoFromMain == 'string') {
      if (this.infoFromMain[0] == '[') {
        this.infoFromMain = this.infoFromMain.slice(1, -1);
      }
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.infoFromMain.imageHeight = this.infoFromMain.imageHeight ? this.infoFromMain.imageHeight : null;
    this.setImageAttribute();
    this.imageUrl = this.infoFromMain.ImageUrl;

    if (!this.infoFromMain) {
      this.infoFromMain = null;
      this.isImageChosen = false; 
    }
    // console.log(`this.templateID: ${this.templateID}`);
    this.galleryData = {
      "ID": this.ID,
      "OrderIdx": this.OrderIdx,
      "infoFromMain": this.infoFromMain,
      "GroupID": this.itemTemplate.GroupID,
      "Layout": this.itemTemplate.Layout,
      "GroupIndex": this.itemTemplate.GroupIndex
    }

    this.info = [this.infoFromMain];
    // if (this.ID == null && this.isAdminFlg == true) {
    //   this.topNews = [
    //     {
    //       DefaultFlg: true,
    //       ImageUrl: "../../assets/images/default.png",
    //       Title: "Title",
    //       UrlPath: "",
    //       CreateAt: '2021-10-26T08:41:50.453'
    //     },
    //     {
    //       DefaultFlg: true,
    //       ImageUrl: "../../assets/images/default.png",
    //       Title: "Title",
    //       UrlPath: "",
    //       CreateAt: '2021-10-26T08:41:50.453'
    //     },
    //     {
    //       DefaultFlg: true,
    //       ImageUrl: "../../assets/images/default.png",
    //       Title: "Title",
    //       UrlPath: "",isGalleryDialog
    //       CreateAt: '2021-10-26T08:41:50.453'
    //     }

    //   ]
    // }

    // console.log(`pageID from template: ${this.pageSelectedID}`);
    // console.log('item template 2: ');
    // console.log(this.itemTemplate);


  }

  ngAfterViewInit():void {

  }

  getFullUrlAPI(url: string) {
    return this.appService.apiRoot + url;
  }
  getFullUrlPortal(url: string) {
    if (!url.includes('../')) {
      return this.appService.portalRoot + url;
    }
    return url;

  }
  isGalleryDialog(e){
    this.isGallery = e;
  }
  redirectToViewMore(url: string) {
    return this.appService.portalRoot + 'post-list/' + url;
  }
  enableChoice() {
    this.isShowMenu = !this.isShowMenu;
  }
  editComponent() {
    if (typeof this.infoFromMain == 'string') {
      if (this.infoFromMain[0] == '[') {
        this.infoFromMain = this.infoFromMain.slice(1, -1);
      }
      this.infoFromMain = JSON.parse(this.infoFromMain);
    }
    this.info = [this.infoFromMain];
    this.editFlg = true;
    this.manageDialog = true;
    this.isShowMenu = false;
  }
  deleteComponent() {
    this.isShowMenu = false;
    this.manageDialog = true;
    this.dltFlg = true;
    // this.topNews = new Array(3).fill({key: 'value'});
    // this.postTittle = this.translate.instant('Category_Name');
    // const dataRequest = {
    //   iD: this.ID,
    //   itemID: this.ID,
    //   index: this.OrderIdx
    // };
    // const result = await this.appService.doDELETE('api/Template', dataRequest);
    // if (result && result.Status === 1) {
    //   this.notification.showSuccess(result.Msg);
    //   this.isReloadPageAfterSave.emit(true)
    //   location.reload();
    // } else {
    //   this.notification.showSuccess(result.Msg);
    // }

  }
  onSave(e) {
    //this.onReload();
    // console.log('onSave');
    // console.log(e);
    if (e == true) {
      // this.isReloadPageAfterSave.emit(true)
    }
    e.Info = JSON.parse(e.Info)
    this.infoFromMain = (e.Info && e.Info.length > 0) ? e.Info[0] : null;
    this.imageUrl = e.Info[0].ImageUrl;
    //this.imageHeight = e.Info[0].imageHeight;
    this.setImageAttribute();

    let newTemp = {Info: e.Info,
      Saved: 1,
      pageBodyIndex: this.pageBodyIndex,
      layoutGroupIndex: this.layoutGroupIndex,
      index: this.newsDecorationIndex
    };
    this.changeTemplate4.emit(newTemp);
    this.editFlg = false;
    this.manageDialog = false;


  }
  onDeleteGallery() {
    console.log("index at template: ", {newsDecorationIndex: this.newsDecorationIndex, layoutGroupIndex: this.layoutGroupIndex, pageBodyIndex: this.pageBodyIndex});
    // this.eventEmitterService.onDltBodyItem(this.newsDecorationIndex, this.layoutGroupIndex, this.pageBodyIndex);
    this.deleteBodyItem.emit({
      "index":this.newsDecorationIndex,
      "layoutGroupIndex":this.layoutGroupIndex,
      "pageBodyIndex":this.pageBodyIndex
    });

  }
  onClose(e) {
    this.editFlg = false;
    this.dltFlg = false;
    this.manageDialog = false;
  }
  onAddNewGallery() {
    this.manageDialog = true;
  }

  getPostTittle() {
    this.postTittle = this.categoriesList.filter(x => x.ID === this.infoFromMain.MenuID);
  }

  openLink(link) {
    window.open(link, '_blank').focus();
  }

  onSaveData() {
    this.saveData.emit();
  }
  onGoBack(){
    this.isGallery = false;

  }
  setImageAttribute(){
    this.imageHeight = this.infoFromMain.Height ? this.infoFromMain.Height : this.infoFromMain.imageHeight;
    this.imageWidth = this.infoFromMain.Width ? this.infoFromMain.Width : '100%';
    if(this.infoFromMain.Size){
      switch(this.infoFromMain.Size){
        case AppConsts.SIZE_COVER:
          this.imageSize =  this.infoFromMain.Size ? "cover" : null;
          break;
        case AppConsts.SIZE_FILL:
          this.imageSize =  this.infoFromMain.Size ? "contain" : null;
          break;
        case AppConsts.SIZE_ADJUST:
          this.imageSize =  this.infoFromMain.Size ? "fill" : null;
          break;
        default:
          this.imageSize = null;
          break;
      }
    }
  }
}

