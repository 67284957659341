import { CustomerCareComponent } from './customer-care/customer-care.component';
import { Routes } from '@angular/router';
import { EmbedPageComponent } from './embed-page/embed-page.component';
import { MainPageComponent } from './main-page/main-page.component';
import { PostDetailComponent } from './post/post-detail/post-detail.component';
import { SearchNewsComponent } from './search-news/search-news.component';
import { AppConsts } from './services/app.consts';
import { AuthGuard } from './services/auth.guard';
import { PostListComponent } from './post/post-list/post-list.component';
import { PostPageComponent } from './post/post-page/post-page.component';
import { ReceiveQuestionsComponent } from './question/receive-questions/receive-questions.component';
import { SearchDocumentComponent } from './document/search/search-document/search-document.component';
import { ReceiveDocumentComponent } from './document/receive-document/receive-document.component';
import { SubmitDocumentComponent } from './document/submit-document/submit-document.component';
import { RegisterDocumentComponent } from './document/register-document/register-document.component';
import { PaymentResultComponent } from './document/payment-result/payment-result.component';
import { TemplatePageComponent } from './template-page/template-page.component';
import { IntroPageComponent } from './intro-page/intro-page.component';
import { DataMenuComponent } from './data-menu/data-menu.component';
import { ContactComponent } from './contact/contact.component';
import { FieldComponent } from './field/field.component';
import { PartiesAndUnionsComponent } from './parties-and-unions/parties-and-unions.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ForgotPasswordPageComponent } from './login-page/forgot-password-page/forgot-password-page.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { LibraryComponent } from './library/library.component';
import { VideosComponent } from './videos/videos.component';
import { ImagesComponent } from './images/images.component';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { ChangePasswordComponent } from './settings-page/change-password/change-password.component';
import { SupportComponent } from './settings-page/support/support.component';
import { WorkingScheduleComponent } from './working-schedule/working-schedule.component';
import { ElectronicTextComponent } from './electronic-text/electronic-text.component';
import { MemberUnitComponent } from './member-unit/member-unit.component';
import { LibraryTextComponent } from './library-text/library-text.component';
import { GovernmentPageComponent } from './government-page/government-page.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { OrganizationStructureComponent } from './intro-page/organization-structure/organization-structure.component';
import { HistoryComponent } from './intro-page/history/history.component';
import { GeneralIntroComponent } from './intro-page/general-intro/general-intro.component';
import { VisionMissionComponent } from './intro-page/vision-mission/vision-mission.component';
import { RewardAndAchivementComponent } from './intro-page/reward-and-achivement/reward-and-achivement.component';
import { EnterMailFormComponent } from './login-page/forgot-password-page/enter-mail-form/enter-mail-form.component';
import { ResetPasswordFormComponent } from './login-page/forgot-password-page/reset-password-form/reset-password-form.component';
import { PreviewTemplatePageComponent } from './preview-template-page/preview-template-page.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizSearchComponent } from './quiz/quiz-search/quiz-search.component';
import { NewsTemplate27Component } from './news-template-27/news-template-27.component';
import { WaterBillInformationComponent } from './water-bill-information/water-bill-information.component';
import { FileSettlemenComponent } from './file-settlemen/file-settlemen.component';
import { NotifyPageComponent } from './notify-page/notify-page.component';


export class AppRouter {
  public static routes: Routes = [
    //Trang chủ
    { path: AppConsts.page.main, component: MainPageComponent },
    { path: AppConsts.page.login, component: LoginPageComponent },
    {
      path: AppConsts.page.signup,
      component: SignupPageComponent,
    },
    // Xem chi tiết tin bài
    {
      path: AppConsts.page.postDetail + '/:title',
      component: PostDetailComponent,
      //  canActivate: [AuthGuard],
    },
    // Xem tin bài chuyên mục
    {
      path: AppConsts.page.postList + '/:title',
      component: PostListComponent,
      //  canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.postList,
      component: PostListComponent,
      //  canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.postPage,
      component: PostPageComponent,
      //  canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.templatePage,
      component: TemplatePageComponent,
      // canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.previewTemplatePage,
      component: PreviewTemplatePageComponent,
      //  canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.introPage,
      component: IntroPageComponent,
      children: [
        {
          path: '',
          redirectTo: AppConsts.GIOI_THIEU_CHUNG_VE_CONG_TY,
          pathMatch: 'full',
        },
        {
          path: AppConsts.GIOI_THIEU_CHUNG_VE_CONG_TY,
          component: GeneralIntroComponent, // child route component that the router renders
        },
        {
          path: AppConsts.TAM_NHIN_SU_MENH,
          component: VisionMissionComponent, // child route component that the router renders
        },
        {
          path: AppConsts.LICH_SU_HINH_THANH,
          component: HistoryComponent, // child route component that the router renders
        },
        {
          path: AppConsts.CO_CAU_TO_CHUC,
          component: OrganizationStructureComponent, // child route component that the router renders
        },
        {
          path: AppConsts.LINH_VUC,
          component: FieldComponent, // child route component that the router renders
        },
        {
          path: AppConsts.GIAI_THUONG_VA_THANH_TUU,
          component: RewardAndAchivementComponent, // child route component that the router renders
        },
      ],
      //  canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.dataMenu,
      component: DataMenuComponent,
      //  canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.field,
      component: FieldComponent,
      //  canActivate: [AuthGuard],
    },
    {
      path: AppConsts.page.partiesAndUnions,
      component: PartiesAndUnionsComponent,
      //  canActivate: [AuthGuard],
    },
    //kết quả tìm kiếm
    { path: AppConsts.page.searchNews, component: SearchNewsComponent },
    //Tiếp nhận ý kiến, câu hỏi - Tiếp nhận hồ sơ trực tuyến
    {
      path: AppConsts.page.receiveQuestions,
      component: ReceiveQuestionsComponent,
    },
    {
      path: AppConsts.page.receiveDocument,
      component: ReceiveDocumentComponent,
    },
    { path: AppConsts.page.submitDocument, component: SubmitDocumentComponent },
    {
      path: AppConsts.page.registerDocument,
      component: RegisterDocumentComponent,
    },
    {
      path: AppConsts.page.field,
      component: RegisterDocumentComponent,
    },
    {
      path: AppConsts.page.library,
      component: LibraryComponent,
      children: [
        {
          path: '',
          redirectTo: AppConsts.page.videos,
          pathMatch: 'full',
        },
        {
          path: AppConsts.page.videos,
          component: VideosComponent, // child route component that the router renders
        },
        {
          path: AppConsts.page.images,
          component: ImagesComponent, // child route component that the router renders
        },
      ],
    },
    {
      path: AppConsts.page.images,
      component: ImagesComponent,
    },
    {
      path: AppConsts.page.videos,
      component: VideosComponent,
    },

    { path: AppConsts.page.paymentResult, component: PaymentResultComponent },
    { path: AppConsts.page.portal, component: EmbedPageComponent },
    //User info
    { path: AppConsts.page.userInfo, component: UserInfoComponent },
    //Contact
    { path: AppConsts.page.contact, component: ContactComponent },
    {
      path: AppConsts.page.contact + '/:submenu',
      component: ContactComponent,
    },
    {
      path: AppConsts.page.electronicText,
      component: ElectronicTextComponent,
    },
    {
      path: AppConsts.page.electronicText + '/:submenu',
      component: ElectronicTextComponent,
    },
    {
      path: AppConsts.page.libraryText ,
      component: LibraryTextComponent,
    },
    {
      path: AppConsts.page.libraryText + '/:submenu',
      component: LibraryTextComponent,
    },
    //Settings
    {
      path: AppConsts.page.settings,
      component: SettingsPageComponent,
      children: [
        {
          path: '',
          redirectTo: AppConsts.page.changePassword,
          pathMatch: 'full',
        },
        {
          path: AppConsts.page.changePassword,
          component: ChangePasswordComponent, // child route component that the router renders
        },
        {
          path: AppConsts.page.support,
          component: SupportComponent, // child route component that the router renders
        },
      ],
    },
    {
      path: AppConsts.page.forgotPassword,
      component: ForgotPasswordPageComponent,
      children: [
        {
          path: '',
          redirectTo: AppConsts.page.initial,
          pathMatch: 'full',
        },
        {
          path: AppConsts.page.initial,
          component: EnterMailFormComponent, // child route component that the router renders
        },
        {
          path: AppConsts.page.resetPassword,
          component: ResetPasswordFormComponent, // child route component that the router renders
        },
      ],
    },
    {
      path: AppConsts.page.workingSchedule,
      component: WorkingScheduleComponent,
    },
    {
      path: AppConsts.page.memberUnit,
      component: MemberUnitComponent,
    },
    {
      path: AppConsts.page.governmentPage,
      component: GovernmentPageComponent,
    },
    {
      path: AppConsts.page.governmentPage + '/:title',
      component: GovernmentPageComponent,
    },
    {
      path: AppConsts.page.searchPage,
      component: SearchPageComponent,
    },
    {
      path: AppConsts.page.quizSearch,
      component: QuizComponent,
    },
    {
      path: AppConsts.page.quizCategorySearch,
      component: QuizComponent,
    },
    {
      path: AppConsts.page.customerCare,
      component: CustomerCareComponent,
    },
    {
      path: AppConsts.page.waterBillInformation,
      component: WaterBillInformationComponent,
    },
    {
      path: AppConsts.page.fileSettlemen,
      component: FileSettlemenComponent,
    },
    { path: AppConsts.page.quizPage, component: QuizComponent },
    {
      path: AppConsts.page.notifyPage,
      component: NotifyPageComponent,
    },
    //default route, luôn luôn ở cuối
    { path: '**',  component: MainPageComponent },
  ];
}
