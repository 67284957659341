<article class="preview-page" (window:resize)="onResize($event)">
  <!-- coding header -->
  <div *ngFor="let item of pageHeader" class="header-box">
    <app-header-template1 *ngIf="item == '1'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
    </app-header-template1>
    <app-header-template2 *ngIf="item == '2'" [isAdminFlg]="true" (menuItem)="menuItem($event)"
      (menuItemchild)="menuItemchild($event)" (menuSearch)="menuSearch($event)">
    </app-header-template2>
    <app-header-template3 *ngIf="item == '3'" (navMenu)="navMenuHandle($event)" [menu]="pageList">
    </app-header-template3>
    <app-header-template4 *ngIf="item == '4'" [isAdminFlg]="true" (menuItem)="menuItem($event)"
      (menuItemchild)="menuItemchild($event)" (menuSearch)="menuSearch($event)" [templateID]="TemplateID"
      [pageSelectedID]="PageSelectedID">
    </app-header-template4>

    <app-header-template5  *ngIf="item == '5'" [isAdminFlg]="true"
    (menuItem)="menuItem($event)" (menuItemchild)="menuItemchild($event)"
    (menuSearch)="menuSearch($event)"  [templateID]="TemplateID" [pageSelectedID]="PageSelectedID"  [bodyWidth]="bodyWidth">
  </app-header-template5>

  </div>
  <!-- coding header -->
  <div class="row main-row">
    <div *ngFor="let group of pageBody; let bodyIndex = index">
      <div *ngFor="let item of group.items; let i = index">
        <app-image-slider-home *ngIf="item?.FrameID == 0" [isAdminFlg]="isAdminFlg" [banner]="item">
        </app-image-slider-home>
      </div>
    </div>
    <div class="col-md-12 col-12">
      <div class="container-fluid">
        <div class="body-list">
          <div *ngFor="let group of pageBody; let bodyIndex = index">
            <div class="layout-list" id="{{ group.id }}">
              <div [ngClass]="{  'layout-box': groupItem.layout == 100 ,
                'layout-box-left': ( groupItem.layout >= 49 &&  groupItem.layout < 100) ,
                'layout-box-right': ( groupItem.layout < 49 &&  groupItem.layout != 25),
                'layout-box-center':   groupItem.layout == 25
              }" [style.width]="innerWidth>768 ? (bodyWidth * groupItem.layout)/100+'px' : 100+'%'"
                *ngFor="let groupItem of group; let i = index">
                <div *ngFor="let item of groupItem.items; let j = index">
                  <app-news-template-1 *ngIf="item?.FrameID == 1" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-1>

                  <app-news-template-2 *ngIf="item?.FrameID == 2" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-2>

                  <app-news-template-3 *ngIf="item?.FrameID == 3" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-3>

                  <app-news-template-4 *ngIf="item?.FrameID == 4" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-4>

                  <app-news-template-5 *ngIf="item?.FrameID == 5" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-5>

                  <app-news-template-6 *ngIf="item?.FrameID == 6" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-6>

                  <app-news-template-a *ngIf="item?.FrameID == 7" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-a>

                  <app-news-template-b *ngIf="item?.FrameID == 8" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-b>

                  <app-news-template-c *ngIf="item?.FrameID == 9" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-c>

                  <app-news-template-10 *ngIf="item?.FrameID == 10" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-10>
                  <app-news-template-11 *ngIf="item?.FrameID == 11" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-11>
                  <app-news-template-12 *ngIf="item?.FrameID == -2" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-12>

                  <!-- template giới thiệu-->
                  <app-intro-page *ngIf="item?.FrameID == 19" [ID]="item?.ID" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)"
                    [newsDecorationIndex]="i" [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item"
                    [layoutGroupIndex]="groupIndex" (saveData)="this.onReload()"
                    (changeTemplate2)="this.changeTemp12($event)" (deleteBodyItem)="onDltBodyItem($event)">
                  </app-intro-page>

                  <!-- template tin tức -->
                  <app-news-template-13 *ngIf="item?.FrameID == 20" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-13>
                  <!-- template dịch vụ khách hàng -->
                  <app-news-template-14 *ngIf="item?.FrameID == 21" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-14>

                  <!-- template sơ đồ tổ chức -->
                  <app-org-tree *ngIf="item?.FrameID == 23" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                    [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                    [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-org-tree>
                  <!-- template tin mới nhiều chuyên mục -->
                  <app-news-template-19 *ngIf="item?.FrameID == NEWS_19" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-19>
                  <!-- template dịch vụ -->
                  <app-news-template-20 *ngIf="item?.FrameID == NEWS_20" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-20>

                  <!-- image slider title -->
                  <app-image-slider-title *ngIf="item?.FrameID == NEWS_21" [isAdminFlg]="isAdminFlg" [banner]="item">
                  </app-image-slider-title>
                  <!-- template 1 without time -->
                  <app-news-template-1 *ngIf="item?.FrameID == NEWS_22" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview"
                    [templateType]="2">
                  </app-news-template-1>
                  <!-- template advertise -->
                  <app-news-template-21 *ngIf="item?.FrameID == NEWS_23" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-21>
                  <!-- Hỏi đáp -->
                  <app-quiz *ngIf="item?.FrameID == NEWS_24" [menuID]="menuID" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.TemplateID"
                    [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)" [newsDecorationIndex]="i"
                    [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item" [banner]="item"
                    (saveData)="this.onReload()">
                  </app-quiz>
                  <!-- image list -->
                  <app-image-list *ngIf="item?.FrameID == NEWS_25" [isAdminFlg]="isAdminFlg" [banner]="item">
                  </app-image-list>
                  <!-- images slider -->
                  <app-images-slider *ngIf="item?.FrameID == NEWS_26" [isAdminFlg]="isAdminFlg" [banner]="item">
                  </app-images-slider>
                  <app-news-template-22 *ngIf="item?.FrameID == NEWS_27" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-22>
                  <!-- template most view -->
                  <app-news-template-23 *ngIf="item?.FrameID == NEWS_28" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-23>
                  <!-- template news vertical -->
                  <app-news-template-24 *ngIf="item?.FrameID == NEWS_29" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-24>
                  <!-- template news 2/3 -->
                  <app-news-template-25 *ngIf="item?.FrameID == NEWS_30" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-25>
                  <!-- media -->
                  <app-news-template-26 *ngIf="item?.FrameID == NEWS_31" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-26>
                  <!-- template dịch vụ 2 -->
                  <app-news-template-27 *ngIf="item?.FrameID == NEWS_32" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-27>
                  <app-news-template-28 *ngIf="item?.FrameID == NEWS_33" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-28>
                  <!-- template tin mới nhiều chuyên mục -->
                  <app-news-template-29 *ngIf="item?.FrameID == NEWS_34" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-29>
                  <app-news-template-30 *ngIf="item?.FrameID == NEWS_35" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-30>
                  <app-news-template-31 *ngIf="item?.FrameID == NEWS_36" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-31>
                  <app-news-template-32 *ngIf="item?.FrameID == NEWS_37" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-32>
                  <!-- media -->
                  <app-news-template-33 *ngIf="item?.FrameID == NEWS_38" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-news-template-33>

              <app-news-template-34
              *ngIf="item?.FrameID == NEWS_39"
              [isAdminFlg]="isAdminFlg"
              [infoFromMain]="item?.Info"
              [categoriesList]="categoriesList"
              [templateID]="item?.FrameID"
              [OrderIdx]="item?.OrderIdx"
              [newsDecorationIndex]="i"
              [itemTemplate]="item"
              [isPreview]="isPreview"
            >
            </app-news-template-34>
            <app-image-slider-customer-service
            *ngIf="item?.FrameID == NEWS_41"
            [isAdminFlg]="isAdminFlg"
            [banner]="item"
          >
          </app-image-slider-customer-service>

                  <app-customer-care *ngIf="item?.FrameID == NEWS_40" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-customer-care>
                  <!-- Thông tin tiền nước  -->
                  <app-water-bill-information *ngIf="item?.FrameID == NEWS_41" [isAdminFlg]="isAdminFlg"
                    [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                    [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                  </app-water-bill-information>
                  <!-- Lien he co thong tin -->
                  <app-news-template-35 *ngIf="item?.FrameID == NEWS_42" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                  [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                  [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
                </app-news-template-35>
                <!-- Thu vien radio -->
                <app-news-template-36 *ngIf="item?.FrameID == NEWS_43" [isAdminFlg]="isAdminFlg"
                [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-36>
               <!-- slider banner -->
               <app-news-template-38 *ngIf="item?.FrameID == NEWS_45" [isAdminFlg]="isAdminFlg" [banner]="item">
              </app-news-template-38>
              <!-- template tin tuc gom tin con -->
              <app-news-template-39 *ngIf="item?.FrameID == NEWS_46" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-39>
              <!-- template chuyen de truyen thong -->
              <app-news-template-40 *ngIf="item?.FrameID == NEWS_47" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-40>
              <!-- template gioi thieu o trang chu -->
              <app-news-template-41 *ngIf="item?.FrameID == NEWS_48" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-41>
              <!-- template don vi thanh vien -->
              <app-news-template-42 *ngIf="item?.FrameID == NEWS_49" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-42>
              <!-- template chuong trinh - du an -->
              <app-news-template-43 *ngIf="item?.FrameID == NEWS_50" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-43>
              <!-- template chuyen gia -->
              <app-news-template-44 *ngIf="item?.FrameID == NEWS_51" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-44> 
              <!-- trang chuyen gia -->
              <app-news-template-45 *ngIf="item?.FrameID == NEWS_52" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-45>   
              <!-- template chi hoi thanh vien -->
              <app-news-template-46 *ngIf="item?.FrameID == NEWS_53" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-46>    
              <!-- Email và SĐT -->
              <app-news-template-47 *ngIf="item?.FrameID == NEWS_54" [isAdminFlg]="isAdminFlg" [infoFromMain]="item?.Info"
                [categoriesList]="categoriesList" [templateID]="item?.FrameID" [OrderIdx]="item?.OrderIdx"
                [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-47>         
              <!-- Trang Chuyên đề truyền thông -->
              <app-news-template-48 *ngIf="item?.FrameID == NEWS_55" [ID]="item?.ID" [isAdminFlg]="isAdminFlg"
                [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                [pageBodyIndex]="bodyIndex" [OrderIdx]="item?.OrderIdx" (isReloadPageAfterSave)="onReload($event)"
                [newsDecorationIndex]="i" [pageSelectedID]="this.PageSelectedID" [itemTemplate]="item"
                [layoutGroupIndex]="groupIndex" (saveData)="this.onReload()"
                (changeTemplate2)="this.changeTemp12($event)" (deleteBodyItem)="onDltBodyItem($event)">
              </app-news-template-48>  
              <!-- Chi tiết CĐ truyền thông -->
              <app-news-template-49 *ngIf="item?.FrameID == NEWS_56" [isAdminFlg]="isAdminFlg"
                [infoFromMain]="item?.Info" [categoriesList]="categoriesList" [templateID]="item?.FrameID"
                [OrderIdx]="item?.OrderIdx" [newsDecorationIndex]="i" [itemTemplate]="item" [isPreview]="isPreview">
              </app-news-template-49>                          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="footer-box">
    <app-footer2></app-footer2>
  </div> -->
  <div *ngFor="let item of pageFooter" class="footer-box">
    <app-footer [isAdminFlg]="false" *ngIf="item == '1'"></app-footer>
    <app-footer2 [isAdminFlg]="false" *ngIf="item == '2' || item?.toLowerCase() == 'footer'"></app-footer2>
    <app-footer3 [isAdminFlg]="false" *ngIf="item == '3' || item?.toLowerCase() == 'footer'" [bodyWidth]="bodyWidth"
      (isMoveToTop)="moveToTop()"></app-footer3>
  </div>
</article>
