<section class="receive-document router-outlet-content ">
    <div class="row admin">
        <div class="col-md-12 col-12">
            <div class="page-name">{{pageName}}</div>
        </div>
        <div class="col-md-12 col-12">
            <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                <kendo-tabstrip-tab [title]="'PORTAL_Document_Lookup_By_Code' | translate" [selected]="true">
                    <ng-template kendoTabContent>
                        <app-search-document></app-search-document>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab [title]="'PORTAL_Submit_Online_Document' | translate">
                    <ng-template kendoTabContent>
                        <app-submit-document></app-submit-document>
                    </ng-template>
                </kendo-tabstrip-tab>
            </kendo-tabstrip>
        </div>
    </div>
</section>
