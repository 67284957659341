import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppControls } from '../services/app.controls';
import { AppLanguage } from '../services/app.language';
import { Notification } from '../services/app.notification';
import { AppService } from '../services/app.service';
import { AppSwal } from '../services/app.swal';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
})
export class UserInfoComponent implements OnInit {
  isAdminFlg: boolean = false;
  user: any;

  constructor(
    private appService: AppService,
    private translate: TranslateService,
    private language: AppLanguage,
    private notification: Notification,
    private appSwal: AppSwal,
    public appControls: AppControls,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.language.default();
  }
}
